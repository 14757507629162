import schema from './schema';
import columns from './columns';

import logo from './public/assets/logo.png';
export default {
  APP_TITLE: 'Kgest',
  APP_LOGO: logo,
  APP_CHANGELOG: '/changelog.json',
  APP_FEATURE_SET: '/src/apps/kgest/public/featureSet.json',
  DEFAULT_FROM_EMAIL: 'info@keix.com',
  BOT_NAME: 'keix_bot',
  GRAPHQL_ENDPOINT:
    import.meta.env.VITE_GRAPHQL_ENDPOINT ??
    'https://dev.graphql.keix.com/graphql',
  GRAPHQL_WS_ENDPOINT:
    import.meta.env.VITE_GRAPHQL_WS_ENDPOINT ??
    'wss://dev.graphql.keix.com/graphql',
  GRAPHQL_INTROSPECTION: schema,
  GRAPHQL_REGISTRY: 'https://dev.graphql-registry.keix.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBvo3m2gTnigFjhA9j2Sykt-uw8mHAol2Q',
  COLUMNS_CUSTOMIZERS: columns,
  DEFAULT_QUOTATION_VENDOR: {
    type: 'company',
    name: 'Marketing Research Systems Srl.',
    id:
      import.meta.env.DEFAULT_QUOTATION_VENDOR_ID ??
      '0184ce39-123e-4215-911c-da1bd71fb48b',
  },
};
