import React, { useMemo } from 'react';
import { Table } from 'src/components/table';
import type { Company, Ref } from 'src/apps/athena/gql-types';
import { AppColumn, FilterKind } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import {
  getPhoneNumber,
  getPecAddress,
  getEmailAddress,
  getCertificate,
} from 'src/utils/accessors';
import { useTranslation } from 'react-i18next';
import { useCrumbs } from 'src/context/CrumbsContext';
import QuickLookDrawer, { useQuickLook } from '../components/QuickLookDrawer';
import {
  OmnibarSource,
  useOmnibarPreferredSourceKey,
} from 'src/context/OmnibarContext';
import { SEARCH_QUERY, SEARCH_QUERY_BY_VAT } from 'src/graphql/companies';
import { get } from 'lodash';
import { MUTATIONS } from 'src/apps/athena/graphql/crm';
import { useQueryClient } from '@tanstack/react-query';

export const omnibarSource: OmnibarSource = {
  key: 'company',
  kind: 'company',
  icon: 'briefcase',
  title: 'Companies by name',
  query: SEARCH_QUERY,
  dataKey: 'companiesSuggester',
  valueRenderer: (t: Company) => t.name,
};

export const omnibarSourceByVat: OmnibarSource = {
  key: 'companyByVatCode',
  kind: 'company',
  icon: 'briefcase',
  title: 'Companies by vat code',
  query: SEARCH_QUERY_BY_VAT,
  dataKey: 'companiesSuggester',
  valueRenderer: (t: Company) => t.name,
};

export default function CompaniesPage() {
  const { t } = useTranslation('companies');
  const { quickLookRef } = useQuickLook();
  useOmnibarPreferredSourceKey('company');
  useCrumbs([
    { href: '/companies', text: t('Companies', { ns: 'translation' }) },
  ]);

  const columns = useMemo<AppColumn<Company>[]>(
    () => [
      {
        id: 'name',
        Header: t('Name'),
        width: 200,
        accessor: 'name',
        filter: {
          id: 'name',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'vat',
        Header: t('Vat ID'),
        width: 150,
        accessor: 'vatId',
        filter: {
          id: 'vatId',
          kind: FilterKind.TEXT,
        },
      },

      {
        id: 'phoneNumber',
        Header: t('Phone Number'),
        width: 200,
        isVisible: true,
        accessor: getPhoneNumber,
        filter: {
          id: 'phoneNumbers.number',
          kind: FilterKind.TEXT,
        },
      },

      {
        id: 'emailAddress',
        Header: t('Email Address'),
        width: 180,
        accessor: getEmailAddress,
        filter: {
          id: 'emailAddresses.emailAddress',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'certificates',
        Header: t('Certificates'),
        width: 200,
        accessor: getCertificate((ref: Ref) => {
          quickLookRef({ kind: 'certificate', ref });
        }),
        filter: {
          id: 'certificates.name',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'pec',
        Header: t('PEC'),
        width: 180,
        isVisible: false,
        accessor: getPecAddress,
        filter: {
          id: 'pec',
          kind: FilterKind.TEXT,
        },
      },
    ],
    []
  );

  const queryClient = useQueryClient();
  const { table, dialogs, ref } = usePaginatedTableQuery(
    'query($filters: JSON, $cursor: JSON) { companies(filters: $filters, cursor: $cursor) { pageInfo { total endCursor } items { id name vatId emailAddresses { id emailAddress } certificates { id name } phoneNumbers { id countryCode number } } } }',
    'companies',
    'company',
    {
      columns,
      removeCommand: {
        query: MUTATIONS.REMOVE_COMPANY,
        handler: (evt, ctx) => {
          if (evt.type === 'COMPANY_REMOVED') {
            ctx.complete();
            ctx.showSuccessNotification({
              message: t('Company Removed'),
            });
          }
        },
        options: {
          onSettled: () => queryClient.invalidateQueries('companies'),
        },
      },
    }
  );

  return (
    <div
      className="card flex-grow flex flex-column p0 overflow-hidden"
      ref={ref}
    >
      <Table className="flex-grow" {...table} />
      {dialogs}
    </div>
  );
}
