import {
  Button,
  Classes,
  Dialog,
  EditableText,
  HTMLSelect,
  Icon,
  IconName,
  Intent,
  NonIdealState,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import classNames from 'classnames';
import css from 'csz';
import { Form, Formik } from 'formik';
import { compact, concat, isEmpty, uniq } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Query,
  Question,
  Survey,
  SurveyQuestion,
  SurveyTemplateKeyword,
  SurveyTemplateKeywordInput,
} from 'src/apps/athena/gql-types';
import { Accordion } from 'src/components/Accordion';
import { EditableTextField } from 'src/components/BlueprintFields';
import { QuestionCard } from 'src/components/surveys/QuestionCard';
import { MUTATIONS, QUERY } from 'src/graphql/surveys';
import { SendCommandHook, useSendCommand } from 'src/utils/events';
import { useGraphQLFetch } from 'src/utils/graphql';
import { v5 } from 'uuid';
import { useGetSurveyQuestions } from '../Survey/SurveyPage';

export enum QuestionKeywordType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  STRING_ARRAY = 'STRING_ARRAY',
}

export default function SurveyTemplateKeywordDialog(p: {
  isOpen: boolean;
  onClose: () => void;
  surveyTemplate: Survey;
}) {
  const id = p?.surveyTemplate?.id;

  const fetch = useGraphQLFetch();

  const { data, isLoading } = useQuery<Pick<Query, 'surveyTemplateKeywords'>>(
    ['surveyTemplateKeywords', id],
    () => fetch(QUERY.GET_TEMPLATE_KEYWORDS, { surveyTemplateId: id }),
    {
      enabled: p?.surveyTemplate?.id != null,
    }
  );

  const surveyTemplateKeywords = data?.surveyTemplateKeywords?.items ?? [];

  return (
    <Dialog
      isOpen={p.isOpen}
      title={
        p.surveyTemplate != null
          ? `${p.surveyTemplate?.name} - Keywords`
          : 'Keywords'
      }
      icon="key"
      onClose={p.onClose}
      isCloseButtonShown={false}
      className={className}
    >
      <div
        className={classNames(
          Classes.DIALOG_BODY,
          'flex flex-col overflow-auto'
        )}
      >
        {p.surveyTemplate || isLoading ? (
          <KeywordsQuestionsList
            templateKeywords={surveyTemplateKeywords}
            surveyTemplate={p.surveyTemplate}
            placeholder={'Insert question here...'}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </Dialog>
  );
}

export function KeywordsQuestionsList(p: {
  templateKeywords: SurveyTemplateKeyword[];
  surveyTemplate: Survey;
  placeholder?: string;
}) {
  const questions = getKeywordsFromSurveyTemplate(
    p.surveyTemplate?.questions ?? [],
    p.surveyTemplate?.id
  );

  return (
    <>
      {!isEmpty(questions) ? (
        <div className="question-keywords">
          {questions.map((question) => (
            <KeywordsQuestion
              key={question.id}
              question={question}
              templateKeywords={p.templateKeywords}
              surveyTemplate={p.surveyTemplate}
            />
          ))}
        </div>
      ) : (
        <div>
          <NonIdealState
            icon="key"
            title="No Keywords"
            description="Survey Template doesn't contain any keywords"
          />
        </div>
      )}
    </>
  );
}

function KeywordQuestion(p: {
  question: QuestionWithKeywords;
  templateKeywords: SurveyTemplateKeyword[];
  surveyTemplate: Survey;
  keyword: string;
}) {
  const [[{ isLoading }, sendCommand], errorMessage] =
    useSetQuestionTextTemplateKeyword();

  const templateKeyword = p.templateKeywords.find(
    (d) => d.keyword === p.keyword
  );
  const questionText = templateKeyword?.questionText ?? '';
  const questionType =
    templateKeyword?.options?.type ?? QuestionKeywordType.STRING;
  const [value, setValue] = useState(questionText);
  const [type, setType] = useState<QuestionKeywordType>(questionType);
  const isChanged = questionText !== value || questionType !== type;
  const isEmptyValue = isEmpty(value);
  const isAdded = !isEmpty(templateKeyword?.questionText);

  let icon: IconName = 'blank';
  let intent: Intent = Intent.NONE;
  if (isChanged) {
    icon = 'edit';
    intent = Intent.PRIMARY;
  } else if (isAdded) {
    intent = Intent.SUCCESS;
    icon = 'tick';
  } else if (isEmptyValue) {
    icon = 'warning-sign';
    intent = Intent.WARNING;
  }

  return (
    <div className="border-t px-2 py-0.5 flex items-center group hover:bg-gray-50">
      <Tag icon={icon} intent={intent} minimal>
        {p.keyword}
      </Tag>

      <EditableText
        className="mx-3 px-1 flex-grow"
        value={value}
        maxLines={1}
        intent={isEmptyValue ? intent : Intent.NONE}
        onChange={(e) => setValue(e)}
        placeholder="Inserisci la domanda da porre al utente per questo campo..."
      />
      <HTMLSelect
        minimal
        value={type}
        onChange={(e) => setType(e.target.value as QuestionKeywordType)}
      >
        <option value={QuestionKeywordType.STRING}>String</option>
        <option value={QuestionKeywordType.NUMBER}>Number</option>
        <option value={QuestionKeywordType.DATE}>Date</option>
        <option value={QuestionKeywordType.PHONE_NUMBER}>Phone Number</option>
        <option value={QuestionKeywordType.EMAIL}>Email</option>
        <option value={QuestionKeywordType.STRING_ARRAY}>String Array</option>
      </HTMLSelect>
      <Button
        icon="tick"
        className={classNames('ml-4 mr-2', { invisible: !isChanged })}
        small
        loading={isLoading}
        disabled={isLoading}
        text="Salva"
        intent={Intent.PRIMARY}
        onClick={() => {
          sendCommand({
            id: v5(p.keyword, p.surveyTemplate.id),
            data: {
              keyword: p.keyword,
              questionText: value,
              surveyTemplateId: p.surveyTemplate.id,
              options: {
                type,
              },
            },
          });
        }}
      />
    </div>
  );
}

function KeywordsQuestion(p: {
  question: QuestionWithKeywords;
  templateKeywords: SurveyTemplateKeyword[];
  surveyTemplate: Survey;
}) {
  return (
    <div className="bg-white border rounded-sm mb-3">
      <QuestionCard variablesField={p.question.field} question={p.question} />
      {p.question.keywords.map((k) => (
        <KeywordQuestion {...p} keyword={k} key={k} />
      ))}
    </div>
  );
}

export interface QuestionWithKeywords extends Question {
  keywords: string[];
  field: string;
}

export function getKeywordsFromSurveyTemplate(
  surveyQuestions: SurveyQuestion[],
  surveyId: string
): QuestionWithKeywords[] {
  const { data } = useGetSurveyQuestions(surveyId, surveyQuestions);

  const questions = data?.getQuestions ?? [];
  return compact(
    questions.map((question, index) => {
      let optionsVariables: string[];
      if (question.options) {
        optionsVariables = question.options.flatMap((option) =>
          getVariableFromString(option.body)
        );
      }
      const keywords = concat(
        getVariableFromString(question.body),
        optionsVariables
      );
      if (!isEmpty(keywords)) {
        return {
          ...question,
          keywords,
          field: `questions.${index}`,
        };
      }
    })
  );
}

export function getVariableFromString(text: string) {
  const matches = new RegExp(/(?:{{)(\w+)(?:}})/g).exec(text);
  if (matches?.length === 2) {
    return matches[1];
  }
  return [];
  // return text.match(/(?:{{)\w+(?=}})/g) || [];
}

export function useSetQuestionTextTemplateKeyword(): [
  SendCommandHook<{ id: string; data: SurveyTemplateKeywordInput }>,
  string
] {
  const [errorMessage, setErrorMessage] = useState('');
  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(
    () => queryClient.invalidateQueries(['surveyTemplateKeywords']),
    []
  );
  const sendCommand = useSendCommand(
    MUTATIONS.SET_QUESTION_TEXT_TO_TEMPLATE_KEYWORD,
    (e: { data: { reason?: string }; type: string }, ctx) => {
      if (e.type === 'QUESTION_TEXT_TO_TEMPLATE_KEYWORD_SET') {
        ctx.showSuccessNotification({
          message: 'Question Successfully Saved!',
        });
        ctx.complete();
      } else if (e.type === 'FAILED_TO_SET_QUESTION_TEXT_TO_TEMPLATE_KEYWORD') {
        ctx.showFailureNotification({
          message: e.data.reason,
        });
        ctx.complete();
        setErrorMessage(e.data.reason);
      } else {
        ctx.showFailureNotification({
          message: 'Something went wrong!',
        });
        ctx.complete();
        setErrorMessage(e.data.reason);
      }
    },
    {
      variablesBuilder: ({
        id,
        ...data
      }: {
        id: string;
        data: SurveyTemplateKeywordInput;
      }) => {
        return { id, ...data };
      },
      onSettled: invalidateQuery,
    }
  );
  return [sendCommand, errorMessage];
}

const className = css`
  width: 60vw;
  height: 80vh;
  .${Classes.DIALOG_BODY} {
    position: relative;
    margin: 10px;
  }
`;
