import { LibraryBlock } from '@keix/workflow-types';
import numpy from './blocks/numpy';
import base from './blocks/base';
import survey from './blocks/survey';
import chart from './blocks/chart';

export const library: LibraryBlock[] = [
  ...base,
  ...survey,
  {
    name: 'random',
    uri: 'python.random.random',
    code: `from random import random`,
    arguments: [],
    returnType: 'python.float',
    style: {
      icon: 'random',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },
  {
    name: 'ceil',
    uri: 'python.math.ceil',
    code: `from math import ceil`,
    arguments: [
      {
        name: 'in',
        type: 'python.float',
        isPort: true,
      },
    ],
    returnType: 'python.float',
    style: {
      icon: 'array-numeric',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },
  {
    name: 'floor',
    uri: 'python.math.floor',
    code: `from math import floor`,
    arguments: [
      {
        name: 'in',
        type: 'python.float',
        isPort: true,
      },
    ],
    returnType: 'python.float',
    style: {
      icon: 'array-numeric',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },
  {
    name: 'round',
    uri: 'python.round',
    code: `# Bultin function in Python`,
    arguments: [
      {
        name: 'in',
        type: 'python.float',
        isPort: true,
      },
      {
        name: 'digits',
        type: 'python.float',
        defaultValue: 2,
      },
    ],
    returnType: 'python.float',
    style: {
      icon: 'array-numeric',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },
  {
    name: 'seed',
    uri: 'python.random.seed',
    code: `from random import seed`,
    arguments: [],
    style: {
      icon: 'random',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },
  {
    name: 'randrange',
    uri: 'python.random.randrange',
    code: `from random import randrange`,
    returnType: 'python.float',
    arguments: [
      { name: 'start', optional: true, type: 'python.int' },
      { name: 'stop', type: 'python.int' },
      { name: 'step', optional: true, type: 'python.int' },
    ],
    style: {
      icon: 'random',
      bg: {
        color: 'red',
        opacity: 500,
      },
    },
  },

  {
    name: 'time',
    uri: 'python.time.time',
    arguments: [],
    returnType: 'python.float',
    style: {
      icon: 'console',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: `from time import time`,
  },
  {
    name: 'date',
    uri: 'datetime.date',
    arguments: [
      { name: 'year', type: 'python.int' },
      { name: 'month', type: 'python.int' },
      { name: 'day', type: 'python.int' },
    ],
    returnType: 'python.datetime.date',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: `from datetime import date`,
  },
  {
    name: 'today',
    uri: 'datetime.date.today',
    arguments: [],
    returnType: 'python.datetime.date',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: `from datetime import date
today = date.today`,
  },
  {
    name: 'now',
    uri: 'datetime.datetime.now',
    arguments: [],
    returnType: 'python.datetime.datetime',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: `from datetime import datetime
now = datetime.now`,
  },

  {
    name: 'time_ns',
    uri: 'python.time.time_ns',
    arguments: [],
    returnType: 'python.float',
    style: {
      icon: 'console',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: `from time import time_ns`,
  },
  {
    name: 'isoformat',
    uri: 'datetime.date.isoformat',
    arguments: [{ name: 'self', isPort: true, type: 'python.datetime.date' }],
    returnType: 'python.string',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: '',
  },
  {
    name: 'fromisoformat',
    uri: 'datetime.date.fromisoformat',
    arguments: [
      { name: 'self', isPort: true, type: 'date' },
      { name: 'date', type: 'string' },
    ],
    returnType: 'string',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: 'from datetime import date',
  },
  {
    name: 'ctime',
    uri: 'datetime.date.ctime',
    arguments: [{ name: 'self', isPort: true, type: 'datetime.date' }],
    returnType: 'string',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: '',
  },
  {
    name: 'strftime',
    uri: 'datetime.date.strftime',
    arguments: [
      { name: 'self', isPort: true, type: 'datetime.date' },
      { name: 'format', type: 'string' },
    ],
    returnType: 'string',
    style: {
      icon: 'calendar',
      bg: {
        color: 'green',
        opacity: 500,
      },
    },
    code: '',
  },
  {
    name: 'log',
    uri: 'python.print',
    arguments: [{ name: 'in', isPort: true, type: 'any' }],
    style: {
      icon: 'console',
      bg: {
        color: 'blue',
        opacity: 500,
      },
    },
    code: `
def log(*args):
    print(args)
`,
  },
  // {
  //   name: 'Series',
  //   uri: 'pandas.series',
  //   arguments: [{ name: 'data', type: 'any' }],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: 'from pandas import Series',
  //   returnType: 'pandas.Series',
  // },
  // {
  //   name: 'abs',
  //   uri: 'pandas.series.abs',
  //   arguments: [{ name: 'self', type: 'any' }],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'pandas.Series',
  // },
  // {
  //   name: 'mean',
  //   uri: 'pandas.series.mean',
  //   arguments: [{ name: 'self', isPort: true, type: 'any' }],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'python.float',
  // },
  // {
  //   name: 'round',
  //   uri: 'pandas.series.round',
  //   arguments: [{ name: 'self', isPort: true, type: 'any' }],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'python.float',
  // },

  // {
  //   name: 'read_csv',
  //   uri: 'pandas.dataframe.read_csv',
  //   arguments: [{ name: 'filepath_or_buffer', type: 'string' }],
  //   style: {
  //     icon: 'pivot-table',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: 'from pandas import read_csv',
  //   returnType: 'pandas.DataFrame',
  // },
  // {
  //   name: 'concat',
  //   uri: 'pandas.dataframe.concat',
  //   arguments: [
  //     { name: 'a', type: 'pandas.DataFrame' },
  //     { name: 'b', type: 'pandas.DataFrame' },
  //   ],
  //   style: {
  //     icon: 'pivot-table',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: 'from pandas import concat',
  //   returnType: 'pandas.DataFrame',
  // },
  // {
  //   name: 'add',
  //   uri: 'pandas.series.add',
  //   arguments: [
  //     { name: 'self', isPort: true, type: 'any' },
  //     { name: 'other', type: 'pandas.Series' },
  //   ],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'pandas.Series',
  // },
  // {
  //   name: 'mul',
  //   uri: 'pandas.series.mul',
  //   arguments: [
  //     { name: 'self', isPort: true, type: 'any' },
  //     { name: 'other', type: 'pandas.Series' },
  //   ],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'pandas.Series',
  // },
  // {
  //   name: '__getitem__',
  //   uri: 'pandas.dataframe.__getitem__',
  //   arguments: [
  //     { name: 'self', isPort: true, type: 'any' },
  //     { name: 'columns', type: 'any' },
  //   ],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'pandas.Series',
  // },
  // {
  //   name: 'columns',
  //   uri: 'pandas.series.columns',
  //   functionType: 'attribute',
  //   arguments: [{ name: 'self', type: 'pandas.DataFrame' }],
  //   style: {
  //     icon: 'array',
  //     bg: {
  //       color: 'yellow',
  //       opacity: 600,
  //     },
  //   },
  //   code: '',
  //   returnType: 'pandas.Index',
  // },
  ...numpy,

  ...chart,
];
