import React, { useCallback, useMemo } from 'react';
import { Button, ButtonProps, Icon, IconName, Intent } from '@blueprintjs/core';
import css from 'csz';
import { v4 } from 'src/utils/uuid';
import {
  useDiffableFieldArray,
  FieldArrayHelperProps,
  useFieldArray,
} from 'src/utils/formik';
import { isLoading } from './EditableField';

const className = css`/styles/editable-list.css`;

interface P<T extends { id: string }> {
  name: string;
  removeButtonProps?: {
    position?: 'left' | 'right';
    icon?: IconName;
    intent?: Intent;
  };
  className?: string;
  filter?: (p: T) => boolean;
  addButtonText?: string;
  addButtonProps?: Partial<ButtonProps>;
  diffable?: boolean;
  showAddButton?: boolean;
  canAddItem?: (p: T[]) => boolean;
  canRemoveItem?: (p: T[]) => boolean;
  addItem?: {} | ((props: FieldArrayHelperProps<T>) => {});
  component: (p: { name: string }) => JSX.Element;
  componentProps?: {};
}

export default function EditableList<T extends { id: string }>(p: P<T>) {
  const [field, , helpers] =
    p.diffable !== false
      ? useDiffableFieldArray<T>(p.name)
      : useFieldArray<T>(p.name);
  const rawValues = ((field.value || []) as T[]).map(
    (d: T, rowIndex: number) => ({ ...d, rowIndex })
  );
  const values: any = p.filter != null ? rawValues.filter(p.filter) : rawValues;
  const Component = p.component as any;
  const handlePush = useCallback(() => {
    if (typeof p.addItem === 'function') {
      p.addItem(helpers);
    } else {
      const item = p.addItem ?? {};
      helpers.push({ id: v4(), ...item } as T);
    }
  }, [p.name, helpers]);
  if (isLoading(values)) {
    return <React.Fragment />;
  }
  const reverseClass =
    p.removeButtonProps?.position === 'right' ? 'flex-row-reverse' : '';

  return useMemo(
    () => (
      <div className={`${className} ${p.className ?? ''}`}>
        {values.map((v) => (
          <div className={`editable-list-row ${reverseClass}`} key={v.rowIndex}>
            {p.canRemoveItem == null || p.canRemoveItem(values) ? (
              <Button
                icon={p.removeButtonProps?.icon ?? 'remove'}
                intent={p.removeButtonProps?.intent ?? Intent.NONE}
                minimal
                onClick={() => {
                  helpers.remove(v.rowIndex);
                }}
              />
            ) : (
              <Icon
                iconSize={14}
                className="text-gray-600 pr-3"
                icon="symbol-circle"
              />
            )}
            <Component
              name={`${p.name}.${v.rowIndex}`}
              {...(p.componentProps ?? {})}
            />
          </div>
        ))}
        {p.showAddButton !== false &&
        (p.canAddItem == null || p.canAddItem(values)) ? (
          <Button
            {...(p.addButtonProps ?? {})}
            intent={Intent.PRIMARY}
            minimal
            icon="plus"
            text={p.addButtonText ?? 'Add'}
            onClick={handlePush}
          />
        ) : (
          ''
        )}
      </div>
    ),
    [values]
  );
}
