import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { MouseEventHandler, useState, useCallback } from 'react';
import { Question } from 'src/apps/athena/gql-types';
import { iconForQuestionType } from 'src/utils/survey/icons';
import { QuestionCardBody } from './QuestionCardBody';
import { QuestionOpinionScale } from './QuestionOpinionScale';
import { ShowQuestionOptions, QuestionOptions } from './QuestionOptions';

export function QuestionCard(p: {
  question: Question;
  code?: string;
  field?: string;
  variablesField?: string;
  onContextMenu?: MouseEventHandler<HTMLDivElement>;
  onClick?: (question: Question) => void;
  actionElement?: JSX.Element;
  className?: string;
  galleryMode?: boolean;
  preview?: boolean;
  selected?: boolean;
}) {
  const [editing] = useState(false);
  const { onClick, question, onContextMenu, code } = p;
  const { body, description, options, numberOfVariants } = p.question;
  const galleryMode = p.galleryMode === true;
  const handleClick = useCallback(
    () => onClick && onClick(question),
    [question, onClick]
  );
  return (
    <div
      onClick={handleClick}
      onContextMenu={onContextMenu}
      className={classNames('question-card', p.className, {
        'ring-inset ring-2': p.selected,
        'gallery-mode': p.galleryMode,
      })}
    >
      <div className="flex items-center pt-1 px-2">
        {code == null && (
          <Icon className="mr-2" icon={iconForQuestionType(p.question.type)} />
        )}
        <div className="question-header">
          {code != null && (
            <div className="question-code">
              {code}
              <Icon icon="caret-right" />
            </div>
          )}
          <div className="flex-grow flex flex-column">
            <QuestionCardBody
              question={p.question}
              preview={p.preview}
              field={p.field}
            />
            {!isEmpty(description) && (
              <p className="question-description">{description}</p>
            )}
          </div>
          {p.actionElement}
        </div>
      </div>
      {!galleryMode ? (
        <>
          <QuestionOptions {...p} field={p.field ?? p.variablesField} />
          <QuestionOpinionScale {...p} />
        </>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}
