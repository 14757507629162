import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Colors,
  Dialog,
  Divider,
  Expander,
  FormGroup,
  Icon,
  IconName,
  Intent,
  Menu,
  NonIdealState,
  NumericInput,
  Popover,
  PopoverInteractionKind,
  ProgressBar,
  Tag,
} from '@blueprintjs/core';
import { MenuItem2, Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Form, Formik, useField, useFieldValue, useStatus } from 'formik';
import { compact, debounce, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Person,
  Ref,
  Survey,
  SurveyShare as ISurveyShare,
  SurveyShare,
  SurveyShareInput,
  SurveyShareMedium,
  SurveyShareMetrics,
  SurveyShareType,
} from 'src/apps/athena/gql-types';
import { useNotifications } from 'src/context/NotificationContext';
import { SEARCH_QUERY_WITH_MEDIUMS } from 'src/graphql/people';
import { MUTATIONS, QUERY } from 'src/graphql/surveys';
import { useGetPerson } from 'src/hooks/crm';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { useDateAccessor } from 'src/utils/accessors';
import { useAlert } from 'src/utils/alert';
import { useSendCommand, useSubscribeForEvents } from 'src/utils/events';
import { useGraphqlClient, useGraphQLFetch } from 'src/utils/graphql';
import { AppColumn, useAppTable } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import {
  CheckboxField,
  NumericField,
  NumericInputField,
  TextAreaField,
  ToggleButtonField,
} from '../BlueprintFields';
import { BottomBar } from '../BottomBar';
import DateField from '../DateField';
import { DatePickerField } from '../DatePicker';
import { PersonSuggesterField } from '../GraphQLSuggester';
import { useRefLinkAccessor } from '../RefLink';
import SubmitButton from '../SubmitButton';
import { Table } from '../table';
import {
  useDisableSurveyShare,
  useEnableSurveyShare,
  useRemoveSurveyShare,
} from './shareHooks';
import { SurveyCompletionBar } from './SurveyCompletionBar';
import { delay } from 'src/hooks/workflow/utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface SurveyShareForm {
  person?: Ref;
  medium?: { sms: boolean; telegram: boolean; email: boolean };
  type: SurveyShareType;
  message?: string;
  expirationDate?: string;
  quota?: number;
}

export function SurveyShareMessageDialog(p: {
  surveyId: string;
  handleClose: () => void;
}) {
  const [message, setMessage] = useState(null);
  const queryClient = useQueryClient();

  const { t } = useTranslation('survey');

  const [{ isLoading }, sendDisableShareCommand] = useSendCommand(
    MUTATIONS.DISABLE_SHARE_SURVEY,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_SHARE_DISABLED') {
        p.handleClose();
        ctx.complete();
        ctx.showSuccessNotification({ message: 'Share disabled' });
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['surveyShares']),
    }
  );

  return (
    <Dialog isOpen={true} className={Classes.ALERT}>
      <div className={Classes.ALERT_BODY}>
        <Icon intent={Intent.PRIMARY} iconSize={44} icon={'warning-sign'} />
        <div className={`${Classes.ALERT_CONTENTS} flex flex-col`}>
          <p className="text-lg mb-2 text-primary">
            Are you sure you want to disable this user?
          </p>
          <p className="mb-1">Message:</p>
          <input
            type="text"
            className="flex-grow rounded p-3"
            onChange={debounce((e) => setMessage(e.target.value), 300)}
          />
        </div>
      </div>
      <div className={Classes.ALERT_FOOTER}>
        <Button
          text={'Yes, disable'}
          intent={Intent.DANGER}
          onClick={() => sendDisableShareCommand({ id: p.surveyId, message })}
        />
        <Button
          disabled={isLoading}
          onClick={() => p.handleClose()}
          text={t('Cancel')}
        />
      </div>
    </Dialog>
  );
}

export function SurveyShareDialog(p: {
  surveyId: string;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const graphqlFetch = useGraphQLFetch();
  const { onClose, surveyId } = p;
  const { show } = useNotifications();
  const [isLoading, setLoading] = useState(false);

  const shareSurvey = useCallback(
    async (variables: { share: SurveyShareInput }) => {
      setLoading(true);

      // Awit for the share to complete
      await graphqlFetch(MUTATIONS.SHARE_SURVEY, variables);
      await delay(1200);
      // debugger;

      console.log('=>', ['surveyShares', { surveyId }]);
      queryClient.invalidateQueries(['surveyShares', { surveyId }]);

      onClose();
    },
    [onClose, surveyId]
  );
  // const [state, shareSurvey] = useSendCommand<{}, { share: SurveyShareInput }>(
  //   MUTATIONS.SHARE_SURVEY,
  //   (evt, ctx) => {
  //     if (evt.type === 'SURVEY_SHARED') {
  //       ctx.complete();
  //       ctx.showSuccessNotification({ message: 'Survey Shared Successfully' });
  //     }
  //   },
  //   {
  //     onMutate: () => {
  //       p.onClose();
  //     },
  //     optimistic: false,
  //     onSuccess: () => p.onClose(),
  //     onSettled: () => {
  //       queryClient.invalidateQueries(['surveyShare', p.surveyId]);
  //     },
  //   }
  // );

  const initialValues: SurveyShareForm = {
    person: null,
    type: SurveyShareType.Web,
    quota: 100,
    message:
      '{{sender.firstName}} ti ha invitato a completare il questionario {{survey.name}}, clicca qui per cominciare {{url}}',
    expirationDate: DateTime.now().plus({ days: 31 }).toISO(),
  };
  const handleSubmit = (data: SurveyShareForm) => {
    const share: SurveyShareInput = {
      personId: data.person?.id,
      surveyId: p.surveyId,
      quota: data.quota,
      medium: compact([
        data.medium?.telegram ? SurveyShareMedium.Telegram : null,
        data.medium?.sms ? SurveyShareMedium.Sms : null,
        data.medium?.email ? SurveyShareMedium.Email : null,
      ]),
      type: data.type,
      expirationDate: data.expirationDate,
    };
    return shareSurvey({ share });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Dialog
        icon="share"
        title="Share Survey"
        isOpen={true}
        onClose={p.onClose}
      >
        <Form>
          <div className={Classes.DIALOG_BODY}>
            <div className="flex flex-col">
              <FormGroup label="Condividi a...">
                <div className="flex">
                  <PersonSuggesterField
                    name="person"
                    query={SEARCH_QUERY_WITH_MEDIUMS}
                    labelRenderer={(p) =>
                      'firstName' in p ? (
                        <>
                          <Icon
                            iconSize={13}
                            icon="send-message"
                            className={
                              p.telegram == null ? 'opacity-20 mx-1' : 'mx-1'
                            }
                            intent={
                              p.telegram != null ? Intent.PRIMARY : Intent.NONE
                            }
                          />
                          <Icon
                            iconSize={13}
                            icon="mobile-phone"
                            className={
                              isEmpty(p.phoneNumbers)
                                ? 'opacity-20 mx-1'
                                : 'mx-1'
                            }
                            intent={
                              !isEmpty(p.phoneNumbers)
                                ? Intent.PRIMARY
                                : Intent.NONE
                            }
                          />
                          <Icon
                            iconSize={13}
                            icon="envelope"
                            className={
                              isEmpty(p.emailAddresses)
                                ? 'opacity-20 mx-1'
                                : 'mx-1'
                            }
                            intent={
                              !isEmpty(p.emailAddresses)
                                ? Intent.PRIMARY
                                : Intent.NONE
                            }
                          />
                        </>
                      ) : (
                        <React.Fragment />
                      )
                    }
                  />
                  <div className="mx-2 flex-grow" />
                  <PersonShareMediumField />
                </div>
              </FormGroup>
              <div className="flex gap-x-2">
                <FormGroup label="Quote Assegnate">
                  <NumericInputField min={1} name="quota" />
                </FormGroup>
                <FormGroup label="Data di scadenza">
                  <DatePickerField name="expirationDate" />
                </FormGroup>
              </div>
              <FormGroup
                label="Invia come"
                helperText="Se decidi di inviare una survey web, potrai aprirla da qualunque browser ma non sarà personale e collegata all'account Kbee della persona."
              >
                <SurveyShareTypeField />
              </FormGroup>
              <FormGroup
                label="Messaggio di condivisione"
                helperText="Puoi utilizzare le variabile sender, person, survey e url per customizzare il tuo messaggio e renderlo più personale."
              >
                <TextAreaField fill name="message" />
              </FormGroup>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <SubmitButton
              type="submit"
              loading={isLoading}
              intent={Intent.PRIMARY}
              icon="tick"
              text="Share Survey"
              fill
            />
          </div>
        </Form>
      </Dialog>
    </Formik>
  );
}

export function SurveyShareChangeQuotaDialog(p: {
  shareId: string;
  quota: number;
  onClose: () => void;
}) {
  const [quota, setQuota] = useState(p.quota);
  const queryClient = useQueryClient();

  const [state, sendCommand] = useSendCommand<{ id: string; quota: number }>(
    MUTATIONS.CHANGE_SURVEY_SHARE_QUOTE,
    async (e, ctx) => {
      if (e.type === 'SURVEY_SHARE_QUOTA_CHANGED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: 'Quote Aggiornate con Successo',
        });
      }
    },
    {
      onSuccess: (ctx) => {
        p.onClose();
      },
      onSettled: () => {
        queryClient.invalidateQueries(['surveyShares']);
      },
    }
  );

  return (
    <Dialog
      icon="target"
      title="Change Quota"
      isOpen={true}
      onClose={p.onClose}
    >
      <div className={classNames(Classes.DIALOG_BODY)}>
        <FormGroup
          label="Quote Assegnate"
          helperText="Puoi modificare le quote assegnate ad una risorsa. "
        >
          <NumericInput value={quota} onValueChange={(v) => setQuota(v)} />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          icon="tick"
          fill
          intent={Intent.PRIMARY}
          disabled={quota === p.quota}
          text="Aggiorna Quote"
          loading={state.isLoading}
          onClick={() => sendCommand({ id: p.shareId, quota })}
        />
      </div>
    </Dialog>
  );
}

function PersonShareMediumField() {
  const [_, __, helper] = useField('medium');
  const [value] = useFieldValue('person');
  const { data } = useGetPerson(value?.id, { enabled: value != null });
  useEffect(() => {
    helper.setValue({});
  }, [data?.person?.id]);
  if (value != null) {
    const hasTelegram = data?.person?.telegram != null;
    const hasEmailAddress = !isEmpty(data?.person?.emailAddresses);
    const hasMobilePhoneNumber = !isEmpty(data?.person?.phoneNumbers);
    return (
      <ButtonGroup>
        <ToggleButtonField
          disabled={!hasTelegram}
          name="medium.telegram"
          text="Telegram"
          icon="send-message"
        />
        <ToggleButtonField
          disabled={!hasMobilePhoneNumber}
          name="medium.sms"
          icon="mobile-phone"
          text="SMS"
        />
        <ToggleButtonField
          disabled={!hasEmailAddress}
          name="medium.email"
          icon="envelope"
          text="Email"
        />
      </ButtonGroup>
    );
  }
  return <React.Fragment />;
}

function SurveyShareTypeField() {
  const [field, _, meta] = useField('type');

  return (
    <ButtonGroup>
      <Button
        icon="globe"
        text="Link Web"
        onClick={() => meta.setValue(SurveyShareType.Web)}
        active={field.value === SurveyShareType.Web}
        intent={
          field.value === SurveyShareType.Web ? Intent.PRIMARY : Intent.NONE
        }
      />
      <Button
        icon="send-message"
        text="Telegram"
        onClick={() => meta.setValue(SurveyShareType.Telegram)}
        active={field.value === SurveyShareType.Telegram}
        intent={
          field.value === SurveyShareType.Telegram
            ? Intent.PRIMARY
            : Intent.NONE
        }
      />
    </ButtonGroup>
  );
}
