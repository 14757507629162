import React, { useCallback, useState } from 'react';
import type { IconName } from '@blueprintjs/core';
import { Classes, Icon } from '@blueprintjs/core';
import { first } from 'lodash';
import css from 'csz';
import classNames from 'classnames';

const className = css`/styles/tab-bar.css`;

export interface Tab {
  id: string;
  icon: IconName;
  title: string;
}

function TabItem(p: {
  selected: boolean;
  title: string;
  icon: IconName;
  onClick: () => void;
}) {
  return (
    <div
      className={`tab-item ${p.selected ? 'selected' : ''}`}
      onClick={p.onClick}
    >
      <Icon icon={p.icon} iconSize={20} />
      <div className="title">{p.title}</div>
    </div>
  );
}

export function useTabBar(tabs: Tab[], defaultTabId?: string) {
  const firstTab = first(tabs);
  const initialTabId = defaultTabId || (firstTab != null ? firstTab.id : '');
  const [currentTab, setCurrentTab] = useState(initialTabId);
  const handleChangeTab = useCallback((tab: Tab) => {
    setCurrentTab(tab.id);
  }, []);
  return { currentTab, tabs, onChangeTab: handleChangeTab };
}

export function TabBar(p: {
  className?: string;
  tabs: Tab[];
  currentTab: string;
  onChangeTab: (tab: Tab) => void;
}) {
  const handleChangeTab = useCallback(
    (t: Tab) => () => p.onChangeTab(t),
    [p.onChangeTab]
  );
  return (
    <div className={classNames(className, p.className)}>
      <div className="flex-grow" />
      {p.tabs.map((t) => (
        <TabItem
          key={t.id}
          title={t.title}
          selected={t.id === p.currentTab}
          icon={t.icon}
          onClick={handleChangeTab(t)}
        />
      ))}
      <div className="flex-grow" />
    </div>
  );
}
