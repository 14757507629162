import React, { createContext, useContext } from 'react';
import { CustomizeColumns } from 'src/hooks/columns';

export interface Environment {
  APP_TITLE: string;
  APP_LOGO: string;
  APP_FEATURE_SET: string;
  DEFAULT_FROM_EMAIL: string;
  APP_CHANGELOG?: string;
  BOT_NAME?: string;
  GRAPHQL_ENDPOINT?: string;
  GRAPHQL_WS_ENDPOINT?: string;
  GRAPHQL_INTROSPECTION?: string | {};
  GOOGLE_MAPS_API_KEY?: string;
  DEFAULT_QUOTATION_VENDOR?: {
    id: string;
    name: string;
    type: string;
  };
  COLUMNS_CUSTOMIZERS?: {
    [key: string]: CustomizeColumns<any>;
  };
}

const EnvironmentContext = createContext<Environment>({
  APP_TITLE: '',
  DEFAULT_FROM_EMAIL: 'info@keix.com',
  APP_FEATURE_SET: '/featureSet.json',
  APP_LOGO: 'https://via.placeholder.com/300?Text=Logo',
});

export function EnvironmentContextProvider(p: {
  value: Environment;
  children: JSX.Element;
}) {
  return (
    <EnvironmentContext.Provider value={p.value}>
      {p.children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironment<T extends Environment>() {
  return useContext(EnvironmentContext) as T;
}
