import React from 'react';

export function Flag(p: {
  countryCode: string;
  className?: string;
  style?: {};
}) {
  const rawCountryCode = p.countryCode.toUpperCase() ?? 'IT';
  const countryCode = rawCountryCode === 'EN' ? 'US' : rawCountryCode;
  return (
    <img
      loading="lazy"
      className={p.className}
      style={{ width: 22, ...(p.style ?? {}) }}
      src={`/flags/${countryCode}.png`}
    />
  );
}
