import {
  Button,
  Checkbox,
  Drawer,
  Expander,
  Icon,
  InputGroup,
  Keys,
  NonIdealState,
  NumericInput,
  Switch,
} from '@blueprintjs/core';
import { compact, get, isEmpty, set } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { applyNodeChanges, Node, useReactFlow } from 'react-flow-renderer';
import { Accordion } from 'src/components/Accordion';
import { TextField } from 'src/components/BlueprintFields';
import { FieldRow } from 'src/components/EditableField';
import Section from 'src/components/Section';
import { Block, LibraryBlockArgument } from '@keix/workflow-types';
import {
  getBlockArguments,
  getBlockReturnType,
} from 'src/hooks/workflow/utils';
import { BlockIcon } from './BlockIcon';
import {
  getNodeById,
  useWorkflowContext,
  useWorkflowState,
  WorkflowAction,
} from './WorkflowContext';
import Editor from '@monaco-editor/react';
import {
  resolveDataType,
  useLibraryContext,
} from 'src/hooks/workflow/LibraryContext';
import BlockNodeArguments from './BlockNodeArguments';
import DataType from './DataType';
import classNames from 'classnames';
import { TabBar, useTabBar } from 'src/components/TabBar';
import BlockCode from './BlockCode';
import BlockPreview from './BlockPreview';
import BlockNodeOverview from './BlockNodeOverview';
import { BlockLayoutSettings } from './BlockLayoutSettings';

export function BlockSettings(p: { node: Node<Block> | null }) {
  const { dispatch } = useWorkflowContext();
  const tabBar = useTabBar([
    { icon: 'home', title: 'Overview', id: 'overview' },
    { icon: 'code-block', title: 'Code', id: 'code' },
    { icon: 'dashboard', title: 'Layout', id: 'layout' },
    { icon: 'panel-table', title: 'Output', id: 'output' },
  ]);

  if (p.node == null) {
    return (
      <div className="flex flex-col flex-grow">
        <NonIdealState
          icon="code-block"
          title="No Block Selected"
          description="Select a block to show settings and documetation"
        />
      </div>
    );
  }

  const { id } = p.node;

  let content: JSX.Element;
  switch (tabBar.currentTab) {
    case 'overview':
      content = (
        <>
          <Accordion initiallyOpened={true} kind="section" title="Overview">
            <BlockNodeOverview node={p.node} />
          </Accordion>
          <BlockNodeArguments node={p.node} />
        </>
      );
      break;
    case 'layout':
      content = <BlockLayoutSettings node={p.node} />;
      break;
    case 'code':
      content = <BlockCode node={p.node} />;
      break;
    case 'output':
      content = <BlockPreview node={p.node} />;
  }

  return (
    <div className="flex flex-col flex-grow relative">
      <div className="flex-grow">{content}</div>
      <TabBar className="sticky bottom-0" {...tabBar} />
    </div>
  );
}
