import React, { useCallback, useState } from 'react';
import type { IconName } from '@blueprintjs/core';

import { Intent, Classes, Dialog, Button, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { SendCommand } from 'src/components/SendCommand';
import { useSendCommand } from './events';

export type ShowAlertFn<T> = (
  alertProps?: Partial<AlertProps<T>>,
  ctx?: T
) => void;

interface AlertProps<T> {
  message: string;
  icon: IconName;
  intent?: Intent;
  confirmButtonText: string;
  confirmButtonIcon?: IconName;
  cancelButtonText?: string;
  onConfirm: (ctx: T) => Promise<unknown>;
}

export function useSendCommandAlert<T>(
  props: Omit<Partial<AlertProps<T>>, 'onConfirm'> & { command: SendCommand }
) {
  const [_, sendCommand] = useSendCommand(
    props.command.query,
    props.command.handler,
    props.command.options
  );
  return useAlert({
    ...props,
    onConfirm: sendCommand,
  });
}

export function useAlert<T>(
  defaultAlertProps: Partial<AlertProps<T>>
): [JSX.Element, ShowAlertFn<T>] {
  const { onConfirm } = defaultAlertProps;
  const [loading, setLoading] = useState(false);
  const [ctx, setCtx] = useState<T>();
  const [alertProps, setAlertProps] = useState<AlertProps<T> | null>(null);

  const handleConfirm = useCallback(async () => {
    if (onConfirm != null) {
      setLoading(true);
      await onConfirm(ctx);
      setLoading(false);
      setAlertProps(null);
    }
  }, [ctx, onConfirm]);
  const handleCancel = useCallback(() => setAlertProps(null), []);

  const alert = (
    <CustomAlert
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      loading={loading}
      alert={alertProps}
    />
  );

  const showAlert = useCallback((alertProps, ctx) => {
    setCtx(ctx);
    setAlertProps(Object.assign({}, defaultAlertProps, alertProps));
  }, []);

  return [alert, showAlert];
}

function CustomAlert<T>(props: {
  alert?: AlertProps<T>;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const { alert, loading, onConfirm, onCancel } = props;
  return (
    <Dialog isOpen={alert != null} className={Classes.ALERT}>
      <div className={Classes.ALERT_BODY}>
        <Icon intent={alert?.intent} iconSize={44} icon={alert?.icon} />
        <div className={Classes.ALERT_CONTENTS}>
          <p>{alert?.message}</p>
        </div>
      </div>
      <div className={Classes.ALERT_FOOTER}>
        <Button
          loading={loading}
          text={alert?.confirmButtonText}
          icon={alert?.confirmButtonIcon}
          intent={alert?.intent}
          onClick={onConfirm}
        />
        <Button
          disabled={loading}
          onClick={onCancel}
          text={alert?.cancelButtonText ?? t('Cancel')}
        />
      </div>
    </Dialog>
  );
}
