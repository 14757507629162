import React, { useCallback } from 'react';
import {
  Intent,
  IconName,
  Classes,
  Tab,
  Tabs,
  Icon,
  Button,
  Tag,
  EditableText,
} from '@blueprintjs/core';
import { find, isString } from 'lodash';
import css from 'csz';

const className = css`/styles/page-header.css`;

export interface HeaderTab {
  id: string;
  title: string;
  icon: IconName;
  badge?: {
    title: string;
    intent?: Intent;
  };
}

export interface PageHeaderP {
  editable?: boolean;
  loading?: boolean;
  preRender?: boolean;
  icon?: IconName;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  id: string;
  defaultSelectedTabId?: string;
  currentTab?: string;
  onChangeTitle?: (title: string) => void;
  onChangeTab?: (tab: HeaderTab) => void;
  tabs?: HeaderTab[];
  rightElement?: JSX.Element;
}

export function PageHeader(p: PageHeaderP) {
  const headerClassName = p.loading === true ? Classes.SKELETON : undefined;
  const title = isString(p.title) && p.editable === true ? (
    <EditableText value={p.title} onChange={p.onChangeTitle} />
  ) : p.title;
  return (
    <div className={className}>
      <div className="header-title-container">
        <div className="header-title">
          <div className="flex items-center">
            {p.icon && (
              <Icon
                className="text-gray-400 mr-4"
                iconSize={40}
                icon={p.icon}
              />
            )}
            <h2 className={headerClassName}>
              {p.loading ? 'Dummy Title' : title}
            </h2>
          </div>
          {!p.loading && <div className="subtitle">{p.subtitle}</div>}
        </div>
        <div className="spacer" />
        {p.rightElement}
        <div className="padding" />
      </div>
      <TitleTabBar {...p} />
    </div>
  );
}

type TitleTabBarProps = Pick<
  PageHeaderP,
  'preRender' | 'loading' | 'id' | 'tabs' | 'onChangeTab' | 'currentTab'
>;

export function TitleTabBar(p: TitleTabBarProps) {
  const { tabs, preRender, id, loading, onChangeTab, currentTab } = p;

  const handleTabChange = useCallback(
    (tabId: string) => {
      // Let's find the tab changed.
      const selectedTab = find(tabs, (t) => t.id === tabId);
      if (selectedTab != null) {
        onChangeTab(selectedTab);
      }
    },
    [tabs, onChangeTab]
  );
  return (
    <Tabs
      className={tabsClassName}
      renderActiveTabPanelOnly={preRender === true ? false : true}
      id={id}
      selectedTabId={currentTab}
      onChange={handleTabChange}
    >
      {tabs?.map((tab) => (
        <Tab
          key={tab.id}
          {...tab}
          title={<TabTitle {...tab} />}
          disabled={loading === true}
        />
      ))}
    </Tabs>
  );
}

const tabsClassName = css`
  .bp4-tab[aria-selected='false'] {
    .tab-title-name,
    .bp4-icon {
      color: var(--text-secondary-color);
    }
  }
  .tab-title {
    padding: 2px 4px;
  }

  .tab-title-name {
    padding: 4px 0px 4px 8px;
    font-size: 15px;
  }
  .bp4-tag {
    font-size: 0.9m;
    font-weight: 600;
    margin-left: 5px;
    min-width: 10px;
    min-height: 8px;
    padding: 1px 5px;
  }
`;

function TabTitle(props: HeaderTab) {
  const { icon, title, badge } = props;
  return (
    <div className="tab-title">
      <Icon icon={icon} />
      <span className="tab-title-name">{title}</span>
      {badge && (
        <Tag round minimal intent={badge.intent}>
          {badge.title}
        </Tag>
      )}
    </div>
  );
}
