import { Menu, MenuItem } from '@blueprintjs/core';
import { IItemListRendererProps, Omnibar } from '@blueprintjs/select';
import React, { useCallback, useEffect, useState } from 'react';
import {
  OmnibarSource,
  useOmnibarRepositoryContext,
} from 'src/context/OmnibarContext';
import { useTranslation } from 'react-i18next';
import { highlightQueryString, useGraphQLSuggester } from './GraphQLSuggester';
import { useNavigate } from 'react-router-dom';
import { useQuickLook } from './QuickLookDrawer';
import { useHotkey } from 'src/context/HotkeysContext';

export default function AppOmnibar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sources, closeOmnibar, isOpen, openOmnibar, preferredSourceKey } =
    useOmnibarRepositoryContext();

  const { quickLookRef } = useQuickLook();
  const [selectedSource, setSelectedSource] = useState<OmnibarSource | null>(
    null
  );

  useHotkey({
    combo: 'ctrl+space',
    label: 'Open Omnibar',
    onKeyDown: () => {
      openOmnibar();
    },
  });

  useEffect(() => {
    if (!isOpen) {
      if (preferredSourceKey != null) {
        const source = sources.find((s) => s.key === preferredSourceKey);
        if (source != null) {
          setSelectedSource(source);
        }
      } else {
        setSelectedSource(null);
      }
    }
  }, [preferredSourceKey]);

  const { setQueryString, queryString, filteredItems, noResultsText } =
    useGraphQLSuggester({
      query: selectedSource?.query ?? '',
      dataKey: selectedSource?.dataKey ?? '',
      valueRenderer: selectedSource?.valueRenderer ?? (() => ''),
    });

  const handleClose = useCallback(() => {
    // Only if we not the preferred, otherwise keep it.
    if (preferredSourceKey !== selectedSource?.key) {
      setSelectedSource(null);
    }
    closeOmnibar();
    setQueryString('');
  }, [preferredSourceKey, selectedSource]);

  const itemListRenderer = useCallback(
    (props: IItemListRendererProps<OmnibarSource>) => {
      return <Menu>{props.items.map(props.renderItem)}</Menu>;
    },
    []
  );
  const handleQuickLookItem = useCallback(
    (item: { id: string }) => {
      quickLookRef({
        kind: selectedSource?.kind ?? preferredSourceKey,
        ref: { id: item.id },
      });
      handleClose();
    },
    [preferredSourceKey, selectedSource]
  );

  return (
    <Omnibar<OmnibarSource>
      isOpen={isOpen}
      query={queryString}
      onClose={handleClose}
      onQueryChange={setQueryString}
      noResults={noResultsText}
      inputProps={{
        placeholder:
          selectedSource != null
            ? `${t('Search in')} ${t(selectedSource.title).toLowerCase()}...`
            : t('Search...'),
      }}
      itemRenderer={(item, props) => (
        <MenuItem
          key={item.key}
          onClick={props.handleClick}
          active={props.modifiers.active}
          icon={item.icon ?? selectedSource?.icon}
          text={
            'title' in item
              ? t(item.title)
              : highlightQueryString(
                  selectedSource?.valueRenderer(item),
                  queryString
                )
          }
        />
      )}
      itemListRenderer={itemListRenderer}
      items={selectedSource != null ? filteredItems : sources}
      onItemSelect={(item: any) =>
        selectedSource != null
          ? handleQuickLookItem(item)
          : setSelectedSource(item)
      }
    />
  );
}
