import { Button, Intent, NonIdealState, Tag } from '@blueprintjs/core';
import { useQuery } from '@tanstack/react-query';
import { EChartsOption } from 'echarts';
import { FormikValues } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Query, UserTransaction } from 'src/apps/athena/gql-types';
import { ReactECharts } from 'src/components/Echarts';
import { FieldRow } from 'src/components/EditableField';

import { useGraphQLFetch } from 'src/utils/graphql';

import DialogCreditsGesture from './ModalCreditsGesture';

export interface DataAmount {
  value: number;
  itemStyle?: { color: string };
}

export default function Monitor(p: { values: FormikValues }) {
  const id = p.values?.id;
  const graphqlFetch = useGraphQLFetch();

  const [openDialog, setOpenDialog] = useState<false | 'accredit' | 'charge'>(
    false
  );

  const balance: number =
    useQuery<Pick<Query, 'getUserBalance'>>(
      ['getUserBalance', id],
      () => graphqlFetch(`query($id: String!){getUserBalance(id:$id)}`, { id }),
      { enabled: id != null }
    ).data?.getUserBalance ?? 0;

  const transactions: UserTransaction[] =
    useQuery<Pick<Query, 'retrieveTransactions'>>(
      ['retrieveTransactions', id],
      () =>
        graphqlFetch(
          `query($userId: String!,$orderBy:JSON){retrieveTransactions(userId:$userId,orderBy:$orderBy){ items { id amount time creditDate delayed userId balance } pageInfo { endCursor total } } }`,
          {
            userId: id,
            orderBy: [{ time: 'asc' }],
          }
        ),
      {
        enabled: id != null,
      }
    ).data?.retrieveTransactions?.items ?? [];

  let dataAmount: DataAmount[] = [];
  let dataBalance: string[] = [];
  let dataTime: string[] = [];
  transactions.map((t) => {
    if (t.balance != null) {
      dataAmount.push({
        value: t.amount,
        itemStyle: { color: t.amount > 0 ? '#52c41a' : '#E52D2D' },
      });
      dataBalance.push(t.balance.toString());
      dataBalance.push(t.balance.toString());
      const month = new Date(t.time).getUTCMonth() + 1;
      const day = new Date(t.time).getUTCDate();
      const year = new Date(t.time).getUTCFullYear();

      const newdate = year + '/' + month + '/' + day;
      dataTime.push(newdate);
    }
  });

  const option: any = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        bottom: 30,
      },
      legend: {
        data: ['Balance', 'Transactions'],
        top: 10,
        bottom: 50,
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: 'Balance',
          position: 'right',
          axisLine: { onZero: false },
        },
        {
          type: 'value',
          scale: true,
          name: 'Amount',
          position: 'left',
          axisLine: { onZero: false },
        },
      ],
      xAxis: {
        type: 'category',
        data: dataTime,
      },
      series: [
        {
          name: 'Balance',
          type: 'line',
          data: dataBalance,
          label: {
            show: true,
            position: 'top',
          },
        },
        {
          name: 'Transactions',
          type: 'bar',
          color: '#f00',
          yAxisIndex: 1,
          data: dataAmount,
          label: {
            show: true,
            formatter: (val: { data: DataAmount }) => {
              if (val.data.value > 0) return '+' + val.data.value;
              else return `${val.data.value}`;
            },
            position: 'inside',
          },
        },
      ],
    };
  }, [dataAmount, dataBalance]);
  const { t } = useTranslation('credits');
  return (
    <div className="flex flex-col px-5 py-3">
      <FieldRow label={t('User Balance')}>
        <Tag intent={Intent.WARNING} icon="bank-account">
          {balance}
        </Tag>
      </FieldRow>
      <div>
        {transactions.length > 0 ? (
          <ReactECharts
            option={option}
            key={`chart-${p.values?.id}`}
            className="w-full h-96"
          />
        ) : (
          <NonIdealState title="Not enough data to show" icon="disable" />
        )}
      </div>
      <div className="mt-3">
        <p className="font-normal secondary-text">Manage user credits</p>
        <div className="flex">
          <DialogCreditsGesture
            isOpen={openDialog !== false}
            onClose={() => setOpenDialog(false)}
            type={openDialog === 'charge' ? 'charge' : 'accredit'}
            userId={id}
          />
          <Button
            text="Add"
            className="px-1 ml-2 flex-grow"
            intent={Intent.PRIMARY}
            onClick={() => setOpenDialog('accredit')}
            minimal
          />
          <Button
            text="Remove"
            className="px-1 ml-2 flex-grow"
            intent={Intent.DANGER}
            onClick={() => setOpenDialog('charge')}
            minimal
          />
        </div>
      </div>
    </div>
  );
}
