import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CANDIDATE_MUTATION, PROJECT_LIST_MUTATION } from 'src/graphql/project';
import { SendCommand, SendCommandButton } from '../SendCommand';
import { Event, SendCommandHook, useSendCommand } from 'src/utils/events';
import {
  Button,
  ButtonGroup,
  Classes,
  Divider,
  FormGroup,
  InputGroup,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { v4 } from 'src/utils/uuid';
import css from 'csz';
import { useGetProject, useGetProjectLists } from './ProjectMiddleware';
import { HTMLSelectField } from '../BlueprintFields';
import { Person, ProjectList } from 'src/apps/athena/gql-types';
import { FormikProvider, useField, useFormik } from 'formik';
import { array, object, string } from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { PersonSuggesterField } from '../GraphQLSuggester';
import { useFieldArray } from 'src/utils/formik';
import RefLink from '../RefLink';
import { getDisplayName } from 'src/utils/accessors';
import classNames from 'classnames';

const ErrorCodes = {
  404: 'People not found',
  409: 'Candidate Already Exist',
};

export interface AddProjectCandidateInput {
  projectId: string;
  projectListId?: string;
}

interface P extends AddProjectCandidateInput {
  id?: string;
  isNew?: boolean;
  onClose: () => void;
  isOpen: boolean;
}

interface ManualFormState {
  projectId: string;
  projectListId?: string | null;
  personId: string[];
  _personId?: string;
  people: Person[];
}

export function useAddProjectCandidate(): [
  SendCommandHook<AddProjectCandidateInput & { personId: string | string[] }>,
  string
] {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState();
  const { t } = useTranslation('projects');

  const sendCommand = useSendCommand(
    CANDIDATE_MUTATION.ADD_PROJECT_CANDIDATE,
    (e: Event<{ errorCode?: number }>, ctx) => {
      console.log('=> ', e);
      if (e.type === 'PROJECT_CANDIDATE_ADDED') {
        ctx.showSuccessNotification({
          message: t('People Successfully Added'),
        });
        ctx.complete();
      } else if (e.type === 'PROJECT_NOT_FOUND') {
        ctx.showFailureNotification({
          message: t('People Failed to Added'),
        });
        ctx.complete();
        console.log('=>', e.data.errorCode, ErrorCodes);
        setErrorMessage(t(ErrorCodes[e.data.errorCode] ?? 'Project not Found'));
      } else if (e.type === 'PEOPLE_NOT_FOUND') {
        ctx.showFailureNotification({
          message: t('People Failed to Added'),
        });
        ctx.complete();
        console.log('=>', e.data.errorCode, ErrorCodes);
        setErrorMessage(t(ErrorCodes[e.data.errorCode] ?? 'People not Found'));
      } else {
        ctx.showFailureNotification({
          message: t('People Failed to Added'),
        });
        ctx.complete();
        console.log('=>', e.data.errorCode, ErrorCodes);
        setErrorMessage(
          t(ErrorCodes[e.data.errorCode] ?? 'Something went wrong!')
        );
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['projectCandidates']),
    }
  );
  return [sendCommand, errorMessage];
}

export function AddProjectCandidatesManual(p: P) {
  const { t } = useTranslation('projects');
  const { data: projectData } = useGetProject(p.projectId);

  const [[_, addProjectCandidates], errorMessage] = useAddProjectCandidate();
  const form = useFormik<ManualFormState>({
    initialValues: {
      projectId: p.projectId,
      projectListId: p.projectListId,
      personId: null,
      _personId: null,
      people: [],
    },
    isInitialValid: false,
    validationSchema: object({
      projectId: string().required(),
      personId: array().required().defined().min(1),
    }).required(),
    enableReinitialize: false,
    onSubmit: addProjectCandidates,
  });
  const numberOfCandidates = form.values.people.length;
  return (
    <FormikProvider value={form}>
      <div className={classNames(Classes.DIALOG_BODY, 'flex flex-col')}>
        <div className={'flex-grow flex flex-col items-stretch'}>
          <FormGroup
            label={t('Project List')}
            helperText={t(
              'Pick the list you want to add this/these candaditates to.'
            )}
          >
            <ProjectListsSelectField
              projectId={projectData?.project?.id}
              name="projectListId"
            />
          </FormGroup>
          <div className="flex-grow py-12">
            <ProjectCandidateTriageList />
          </div>
          <ProjectCandidateSuggesterField />
          <Divider />
          {/* <div className={'flex-1'}>
          {errorMessage && (
            <Callout intent={Intent.DANGER}>{errorMessage}</Callout>
          )}
        </div> */}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="tick"
            intent={Intent.PRIMARY}
            disabled={!form.isValid}
            onClick={form.submitForm}
            loading={form.isSubmitting}
            text={`Add ${numberOfCandidates > 0 ? numberOfCandidates : ''
              } candidates`}
          />
        </div>
      </div>
    </FormikProvider>
  );
}

function ProjectCandidateSuggesterField() {
  const [personIdField, __, personIdHelpers] =
    useFieldArray<string>('personId');
  const [peopleField, ___, peopleHelpers] = useFieldArray<Person>('people');
  const [_, ____, personFakeHelper] = useField('_personId');

  const handleAddPerson = useCallback(
    (person: Person) => {
      const alreadyAdded = personIdField.value?.includes(person.id) ?? false;
      if (!alreadyAdded) {
        personIdHelpers.push(person.id);
        peopleHelpers.push(person);
        personFakeHelper.setValue(null);
      }
    },
    [personIdField.value, peopleHelpers, personIdHelpers, personFakeHelper]
  );

  return (
    <PersonSuggesterField
      fill={true}
      name="_personId"
      placeholder="Start typing the person you want to add"
      onSelect={handleAddPerson}
    />
  );
}

function ProjectCandidateTriageList() {
  const [field, _, helpers] = useFieldArray<Person>('people');

  if (field.value.length === 0) {
    return (
      <NonIdealState
        className="justify-items-center opacity-50"
        icon="people"
        title="Empty People List"
        description="Start by typing someones name on the bottom search bar..."
      />
    );
  }

  return (
    <div className="flex flex-col bg-white rounded shadow px-1 pr-2 overflow-scroll">
      {field.value.map((person, index) => (
        <div className="flex items-center py-2 border-b">
          <div className="flex-grow">
            <RefLink
              kind="person"
              value={{
                name: getDisplayName(person),
                id: person.id,
              }}
            />
          </div>
          <Button
            icon="cross"
            minimal
            small
            intent={Intent.DANGER}
            onClick={() => helpers.remove(index)}
          />
        </div>
      ))}
    </div>
  );
}

const className = css`
  width: 37vw;
  height: 60vh;
  .${Classes.DIALOG_BODY} {
    position: relative;
  }
`;

export function ProjectListsSelectField(p: {
  projectId: string;
  name: string;
}) {
  const { data: projectListData } = useGetProjectLists(p.projectId);
  const { t } = useTranslation('project');

  const projectListOptions = useMemo(
    () => [
      { label: t('Default List'), value: null },
      ...(projectListData?.projectLists?.items.map((item: ProjectList) => {
        return { label: item.name, value: item.id };
      }) ?? []),
    ],
    [projectListData?.projectLists?.items]
  );

  return (
    <HTMLSelectField name={p.name} fill={true} options={projectListOptions} />
  );
}

export function AddProjectListField(p: {
  projectId: string;
  name: string;
  buttonText?: string;
  placeholder?: string;
}) {
  const [projectListField, , projectListHelper] = useField(p.name);
  const queryClient = useQueryClient();
  const addProjectList: SendCommand = {
    query: PROJECT_LIST_MUTATION.ADD_PROJECT_LIST,
    handler: (evt, ctx) => {
      if (evt.type === 'PROJECT_LIST_ADDED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: 'Project List Added!',
        });
      } else if (evt.type == 'ADD_PROJECT_LIST_FAILED') {
        ctx.complete();
        ctx.showFailureNotification({
          message: 'Cannot Add Project List!',
        });
      }
    },
    options: {
      onSettled: () =>
        queryClient.invalidateQueries(['projectLists', p.projectId]),
    },
  };
  const addProjectListData: ProjectList = {
    id: v4(),
    projectId: p.projectId,
    name: projectListField.value,
  };
  return (
    <ButtonGroup className="mb-3 ml-3 mr-3 flex justify-end">
      <InputGroup
        name={p.name}
        placeholder={p.placeholder}
        className="flex-auto"
        onChange={(event) => {
          projectListHelper.setValue(event.target.value);
        }}
        fill
      />
      <SendCommandButton
        command={addProjectList}
        variables={addProjectListData}
        icon="plus"
        intent={Intent.PRIMARY}
        minimal
        text={p.buttonText}
        className="flex-none "
      />
    </ButtonGroup>
  );
}
