import * as React from 'react';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import { useHotkey } from 'src/context/HotkeysContext';

export interface HotkeyDefinition {
  global?: boolean;
  scope?: string;
  combo: string;
  label: string;
  onKeyDown: () => void;
}

class _HotkeysContainer extends React.Component<{ children: JSX.Element }> {
  public render() {
    return <div>{this.props.children}</div>;
  }
  public static hotkeyRenderer(hotkeys: HotkeyDefinition[]) {
    return () => (
      <Hotkeys>
        {hotkeys.map((key, index) => (
          <Hotkey key={index} {...key} />
        ))}
      </Hotkeys>
    );
  }
}

export function useHotkeys(hotkeys: HotkeyDefinition[]) {
  const HotkeysContainer = React.useMemo(() => {
    function HotWrapper() {}
    HotWrapper.prototype = Object.create(_HotkeysContainer.prototype);
    HotWrapper.prototype.renderHotkeys =
      _HotkeysContainer.hotkeyRenderer(hotkeys);
    return HotkeysTarget(HotWrapper as any);
  }, []);

  return React.useCallback(
    (props: { children: JSX.Element }) => (
      <HotkeysContainer hotkeys={hotkeys}>{props.children}</HotkeysContainer>
    ),
    []
  );
}
