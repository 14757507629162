import { MenuItem } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import React, { useMemo } from 'react';
import { useViewport } from 'react-flow-renderer';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { LibraryBlock } from '@keix/workflow-types';
import { BlockIcon } from './BlockIcon';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';

export function AddBlockOmnibar(p: {
  centerPosition?: { x: number; y: number };
}) {
  const { state, dispatch } = useWorkflowContext();
  const { showAddDialog } = state;
  const { blocksByUri } = useLibraryContext();
  const items = useMemo(() => Object.values(blocksByUri), [blocksByUri]);

  return (
    <>
      <Omnibar
        isOpen={showAddDialog}
        onClose={() => dispatch({ type: WorkflowAction.CLOSE_ADD_DIALOG })}
        items={items}
        onItemSelect={(block) =>
          dispatch({ type: WorkflowAction.ADD_NODE, block })
        }
        itemPredicate={(q, i) => i.name.indexOf(q) >= 0}
        itemRenderer={(item, props) => (
          <MenuItem
            icon={
              <BlockIcon {...item.style} hasInputs={false} hasOutputs={false} />
            }
            active={props.modifiers.active}
            label={item.uri}
            onClick={props.handleClick}
            text={item.name}
          />
        )}
      />
    </>
  );
}
