import React, { useContext, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useContextMenu } from 'src/utils/useContextMenu';
import { useEnvironment } from './EnvironmentContext';

export interface FeatureSet {
  isEnabled(key: string): boolean;
}

const FeatureSetContext = React.createContext<FeatureSet>({
  isEnabled: () => false,
});

export function FeatureSetBlock(p: { feature: string; children: JSX.Element }) {
  const { isEnabled } = useFeatureSet();

  return isEnabled(p.feature) ? p.children : <React.Fragment />;
}

export function AppFeaturSetContext(p: { children: JSX.Element }) {
  const env = useEnvironment();
  const [features, setFeatures] = useState<{}>();
  const featureSet = useMemo<FeatureSet>(
    () => ({
      isEnabled: (key: string) => features?.[key] ?? false,
    }),
    [features]
  );

  useEffect(() => {
    if (env.APP_FEATURE_SET != null) {
      fetch(env.APP_FEATURE_SET)
        .then((res) => res.json())
        .then((res: string[]) => {
          console.log(`[AppFeatures] Loaded ${res.length} features...`);
          setFeatures(
            res.reduce((prev, next) => ({ ...prev, [next]: true }), {})
          );
        });
    }
  }, [env.APP_FEATURE_SET]);

  return (
    <FeatureSetContext.Provider value={featureSet}>
      {p.children}
    </FeatureSetContext.Provider>
  );
}

export function useFeatureSet(): FeatureSet {
  return useContext(FeatureSetContext);
}
