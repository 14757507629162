import React, { useMemo, useState, useCallback } from 'react';
import {
  Hooks,
  TableInstance,
  HeaderProps,
  actions,
  ColumnInstance,
  Meta,
} from 'react-table';
import {
  Button,
  Intent,
  Popover,
  Icon,
  Position,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import { OPEN_FILTERS_DIALOG } from './useRemoteFilters';
import { useTranslation } from 'react-i18next';
import { get, compact } from 'lodash';

export type WithContextMenu<T, O> = T & {
  contextMenu?: (row: O, instance: T) => string;
  showDisclousureIndicator?: boolean;
  features?: {
    contextMenu?: boolean;
  };
};

const ACTION_MENU_ID = 'action-menu';
const CONTEXT_MENU_ID = 'context-menu';

export const OPEN_COLUMNS_DIALOG = 'OPEN_COLUMNS_DIALOG';
export const CLOSE_COLUMNS_DIALOG = 'CLOSE_COLUMNS_DIALOG';

function visibleColumns<T extends object>(
  allColumns: Array<ColumnInstance<T>>,
  meta: Meta<T>
) {
  const { instance } = meta;
  const contextMenu = get(instance, 'features.contextMenu') ?? true;
  const disclosureIndicator =
    get(instance, 'features.disclosureIndicator') ?? true;

  return compact([
    contextMenu
      ? {
          id: 'context-menu',
          width: 40,
          Header: () => <div />,
          Cell: ({ row }) => <Cell contextMenu={row.contextMenu} />,
        }
      : {
          id: 'space',
          width: 10,
        },
    ...allColumns,
    disclosureIndicator && {
      id: 'action-menu',
      width: 35,
      Header: ActionsMenu,
      Cell: ({ row }) => (
        <div className="flex flex-column items-center">
          {row.showDisclousureIndicator ? (
            <Icon icon="chevron-right" />
          ) : (
            <> </>
          )}
        </div>
      ),
    },
  ]);
}

function useInstance<T extends object>(
  instance: WithContextMenu<TableInstance<T>, T>
) {
  instance.rows.forEach((row) => {
    Object.assign(row, {
      showDisclousureIndicator: instance.showDisclousureIndicator ?? false,
      contextMenu: instance.contextMenu?.(row.original, instance),
    });
  });
}

function reducer(state, action, prevState, instance: TableInstance) {
  if (action.type === actions.init) {
    return {
      ...state,
      isColumnsDialogOpen: false,
      // hiddenColumns: instance.columns
      //   .filter((c) => c.isVisible === false)
      //   .map((c) => c.id),
    };
  }
  if (action.type === OPEN_COLUMNS_DIALOG) {
    return { ...state, isColumnsDialogOpen: true };
  }
  if (action.type === CLOSE_COLUMNS_DIALOG) {
    return { ...state, isColumnsDialogOpen: false };
  }

  return state;
}

function Cell(p: { contextMenu: JSX.Element }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <Popover
      content={
        <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          {p.contextMenu}
        </div>
      }
      isOpen={isOpen}
      onClose={() => setOpen(false)}
    >
      <Button
        className={`ml-1 contextMenuButton ${isOpen ? 'opened' : ''}`}
        minimal
        icon="key-command"
        intent={Intent.PRIMARY}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      />
    </Popover>
  );
}

export function useActionContextMenu<T extends object>(hooks: Hooks<T>) {
  const ActionsHeader = useCallback(ActionsMenu, []);

  hooks.useInstance.push(useInstance);
  hooks.stateReducers.push(reducer as any);
  hooks.visibleColumns.push(visibleColumns);
}

function ActionsMenu<T extends object>(props: HeaderProps<T>) {
  const { dispatch, state } = props;
  const { t } = useTranslation();

  const content = useMemo(
    () => (
      <Menu>
        <MenuItem
          text={t('Configure Columns...')}
          icon="column-layout"
          onClick={() => dispatch({ type: OPEN_COLUMNS_DIALOG })}
        />
        <MenuItem
          text={t('Apply Filters...')}
          icon="filter-list"
          onClick={() => dispatch({ type: OPEN_FILTERS_DIALOG })}
        />
      </Menu>
    ),
    []
  );

  return (
    <div className="flex flex-column items-center">
      <Popover position={Position.BOTTOM_LEFT} content={content}>
        <Button icon="cog" minimal />
      </Popover>
    </div>
  );
}
