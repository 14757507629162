import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import {
  ControlGroup,
  Expander,
  FormGroup,
  HTMLSelect,
  IPanelProps,
  Menu,
  MenuItem,
  NonIdealState,
  NumericInput,
} from '@blueprintjs/core';
import {
  Dialog,
  Classes,
  H5,
  Icon,
  PanelStack,
  RadioGroup,
  Radio,
  Button,
  Intent,
  InputGroup,
} from '@blueprintjs/core';
import {
  AppTableInstance,
  AppColumnInstance,
  FilterKind,
  AppColumnFilter,
} from 'src/utils/table/useAppTable';
import css from 'csz';
import {
  CLEAR_FILTER,
  CLEAR_FILTERS,
  inverseDateOperator,
  PUSH_FILTER,
} from 'src/utils/table/useRemoteFilters';
import { useTranslation } from 'react-i18next';
import { getFilterPreviousValue } from '.';
import { Popover2 } from '@blueprintjs/popover2';
import { useDebounce } from 'src/utils/size';
import { debounce, isArray, isEmpty, isNumber, isString, values } from 'lodash';
import { NumericInputField } from '../BlueprintFields';
import { DateTime } from 'luxon';

const className = css`/styles/filters.css`;

interface FilterState<T extends object> {
  column: AppColumnInstance<T>;
  op: string;
  value: string;
}
interface P<T extends object> {
  isOpen: boolean;
  table: AppTableInstance<T>;
  onClose: () => void;
}

function FilterDialogItem<T extends object>(p: {
  column: string;
  onChange?: (filter: FilterState<T>) => void;
  onRemove?: (column: AppColumnInstance<T>) => void;
  table: AppTableInstance<T>;
}) {
  const previousValue = getFilterPreviousValue(p.table.state, p.column);
  const column = p.table.columns.find((c) => c.id === p.column);
  return (
    <>
      <div className="flex items-center py-1">
        <label className="flex-grow font-semibold primary-text">
          {column.Header}
        </label>
        <div>
          <FilterSelector
            column={column}
            onChange={(s) => p.onChange?.({ ...s, column })}
            table={p.table}
            shouldShowButtons={false}
            initialValues={previousValue}
          />
        </div>
        <Button icon="cross" minimal onClick={() => p.onRemove(column)} />
      </div>
    </>
  );
}

export default function FiltersDialog<T extends object>(p: P<T>) {
  const { t } = useTranslation();
  const { dispatch, state } = p.table;
  const { filteredColumns, filters } = state;
  const [order, setOrder] = useState<string[]>(filteredColumns);

  const menu = (
    <Menu>
      {getAvailableFilters(p.table).map((column) => (
        <MenuItem
          key={column.id}
          text={column.Header ?? column.id}
          onClick={(evt: React.MouseEvent) => {
            dispatch({
              type: PUSH_FILTER,
              column,
              op: column.filter?.defaultOperator ?? 'contains',
              value: '',
            });
            setOrder((p) => [...p, column.id]);
            evt.stopPropagation();
          }}
        />
      ))}
    </Menu>
  );

  const handleDebouncedChange = debounce((state: FilterState<any>) => {
    dispatch({ type: PUSH_FILTER, ...state });
  }, 500);

  return (
    <Dialog
      isOpen={p.isOpen}
      icon="filter-list"
      className="w-1/3"
      canOutsideClickClose={false}
      title={t('Edit Filters')}
      onClose={p.onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        {filteredColumns?.length === 0 ? (
          <NonIdealState
            className="py-8"
            icon="filter-open"
            title={t('No Filter Set')}
            description={
              t('Add your first filter to query your data') as string
            }
          />
        ) : (
          order?.map((column) => (
            <FilterDialogItem
              {...p}
              column={column}
              onRemove={(column) => {
                dispatch({ type: CLEAR_FILTER, column });
                setOrder((p) => p.filter((d) => d !== column.id));
              }}
              onChange={handleDebouncedChange}
            />
          ))
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex flex-grow mt-2">
          <Button
            icon="reset"
            className="mr-2"
            text={t('Reset')}
            minimal
            onClick={() => {
              dispatch({ type: CLEAR_FILTERS });
              setOrder([]);
            }}
          />
          <Expander />

          <Popover2 position="bottom-right" content={menu}>
            <Button
              icon="plus"
              rightIcon="chevron-down"
              minimal
              intent={Intent.PRIMARY}
              text={t('Add Filter')}
            />
          </Popover2>
        </div>
      </div>
    </Dialog>
  );
}

function getAvailableFilters<T extends object>(table: AppTableInstance<T>) {
  return table.columns?.filter((c) => c.filter != null);
}

function ColumnSelector<T extends object>(p: IPanelProps & P<T>) {
  const { table, onClose } = p;
  const { t } = useTranslation();
  return (
    <>
      <h4 className="text-lg text-gray-900">{t('Available Fields')}</h4>
      <p className="text-gray-500 mb-2">{t('Edit Filter Description')}</p>
      <div className="columns">
        {p.table.columns
          ?.filter((c) => c.filter != null)
          .map((c) => (
            <div
              key={c.id}
              className="item"
              onClick={() =>
                p.openPanel({
                  component: FilterSelector,
                  props: { table, onClose, column: c },
                })
              }
            >
              {c.Header}
              <Icon icon="chevron-right" />
            </div>
          ))}
      </div>
    </>
  );
}

const OPERATOR_LABELS = {
  exists: 'exists',
  notExists: 'notExists',
  '=': 'equals',
  '>': 'greater than',
  '>=': 'greater than or equal',
  '<': 'less than',
  '<=': 'less than or equal',
  '!=': 'not equals',
  startsWith: 'starts with',
  contains: 'contains',
};

/*
 */

function getAvailableOperators(type: FilterKind) {
  if (type === FilterKind.TEXT || type === FilterKind.KEYWORD) {
    return ['exists', 'notExists', '=', '!=', 'startsWith', 'contains'];
  }
  if (type === FilterKind.NUMBER) {
    return ['exists', 'notExists', '=', '!=', '>', '>=', '<', '<='];
  }
  if (type === FilterKind.AGE) {
    return ['exists', 'notExists', '=', '!=', '>', '>=', '<', '<='];
  }
  return [];
}

export function FilterSelector<T extends object>(
  p: Partial<IPanelProps> &
    Omit<P<T>, 'isOpen' | 'onClose'> & {
      column: AppColumnInstance<T>;
      hasFilter?: boolean;
      onApply?: () => void;
      onChange?: (filter: { op: string; value: string }) => void;
      shouldShowButtons?: boolean;
      initialValues?: { op: string; value: string };
    }
) {
  const { column, onApply } = p;
  const { filter } = column;
  const { t } = useTranslation();
  const { dispatch } = p.table;
  const [op, setOp] = useState(() => {
    const op = p.initialValues?.op ?? 'exists';
    if (filter.kind === FilterKind.AGE) {
      return inverseDateOperator(op);
    } else {
      return op;
    }
  });
  const [value, setValue] = useState<string>(p.initialValues?.value ?? '');
  const handleChangeOp = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      setOp(evt.currentTarget.value);
      setValue('');
    },
    [p.onChange]
  );

  useEffect(() => {
    p.onChange?.({ op, value });
  }, [op, value]);

  const handleCancelFilter = useCallback(() => {
    dispatch({ type: CLEAR_FILTER, column: p.column });
    onApply?.();
  }, [onApply, value, op, column]);
  const handleApplyFilter = useCallback(() => {
    dispatch({ type: PUSH_FILTER, column, op, value });
    onApply?.();
  }, [onApply, value, op, column]);

  const disabled = ['exists', 'notExists'].includes(op);
  const operators = getAvailableOperators(filter.kind);
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          handleApplyFilter();
        }
      }}
    >
      <ControlGroup>
        <HTMLSelect value={op} onChange={handleChangeOp}>
          {operators.map((op) => (
            <option value={op} key={op}>
              {t(OPERATOR_LABELS[op])}
            </option>
          ))}
        </HTMLSelect>
        <FilterKindValue
          value={value}
          disabled={disabled}
          onChange={setValue}
          filter={filter}
        />
      </ControlGroup>

      {p.shouldShowButtons !== false && (
        <div className="flex mt-2">
          <Button
            small
            text={t('Remove Filter')}
            minimal
            // intent={p.hasFilter === true ? Intent.DANGER : Intent.NONE}
            disabled={p.hasFilter !== true}
            onClick={handleCancelFilter}
            icon="filter-remove"
          />
          <div className="flex-grow" />
          <Button
            icon="filter-open"
            minimal
            small
            intent={Intent.PRIMARY}
            text={t('Apply Filter')}
            onClick={handleApplyFilter}
          />
        </div>
      )}
    </div>
  );
}

function FilterKindValue(p: {
  filter: AppColumnFilter;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}) {
  const ref = useRef<HTMLInputElement>();
  const { filter, value, onChange } = p;

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const handleValueChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      onChange(evt.target.value);
    },
    [onChange]
  );
  if (!isEmpty(filter.values)) {
    const joinedValues = filter.values?.map((d) => ({
      ...d,
      value: isArray(d.value) ? d.value.join(',') : d.value,
    }));
    return (
      <HTMLSelect
        disabled={p.disabled}
        value={value}
        options={[{ value: '', label: '-' }, ...joinedValues]}
        onChange={handleValueChange}
      />
    );
  }

  if (filter.kind === FilterKind.TEXT || filter.kind === FilterKind.KEYWORD) {
    return (
      <InputGroup
        inputRef={ref}
        disabled={p.disabled}
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (filter.kind === FilterKind.AGE) {
    console.log(value, parseDateValue(value));
    return (
      <NumericInput
        inputRef={ref}
        disabled={p.disabled}
        value={parseDateValue(value)}
        onValueChange={(_, v) => p.onChange(v)}
      />
    );
  }

  return <React.Fragment />;
}

function parseDateValue(value: string) {
  let numericValue = 18;
  try {
    let maybeAdate = DateTime.fromISO(value);
    if (value.length > 8 && maybeAdate.isValid) {
      console.log('Is a date!');

      return Math.floor(Math.abs(maybeAdate.diffNow('years').years));
    }

    let maybeAnInt = parseInt(value);
    if (!isNaN(maybeAnInt)) {
      console.log('Is a number!');
      numericValue = maybeAnInt;
    }
  } catch {}
  return numericValue;
}
