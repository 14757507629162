import React, { useState } from 'react';
import { Button, Classes, Dialog, InputGroup, Intent } from '@blueprintjs/core';
import { DataTypeComponentProps, DataTypeDefinition } from '@keix/workflow-types';
import Editor from '@monaco-editor/react';
import { useSize } from 'src/utils/size';

export const dataTypes: DataTypeDefinition[] = [
  {
    name: 'Javascript Code',
    uri: 'keix.javascript',
    icon: 'code',
    description: 'A Javascript code block',
    inputComponent: CodeEditor,
  },
];

export function CodeEditor(
  props: DataTypeComponentProps<string> & { previewPanel?: JSX.Element }
) {
  const [isOpen, setOpen] = useState(false);
  const [size, ref] = useSize();

  const monacoWidth =
    props.previewPanel != null ? size.width / 2.0 : size.width;

  return (
    <>
      <Button
        icon="code-block"
        onClick={() => setOpen(true)}
        text="Open Editor"
        intent={Intent.PRIMARY}
        minimal
      />
      <Dialog
        className="w-10/12"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title="Code Editor"
      >
        <div className="overflow-hidden" ref={ref}>
          <div className="flex items-stretch">
            <Editor
              height={size.height}
              width={monacoWidth}
              language="javascript"
              options={{
                minimap: {
                  enabled: false,
                },
              }}
              value={props.value}
              onChange={(v) => props.onChange(v)}
            />
            {props.previewPanel != null && (
              <div className="w-1/2 flex flex-col">{props.previewPanel}</div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}
