export const MUTATIONS = {
  ADD_PERSON:
    'mutation AddPerson($id: String, $person: PersonBasicInfo) { addPerson(id: $id, person: $person) }',
  UPDATE_PERSON:
    'mutation UpdatePerson($id: String!, $person: PersonBasicInfo) { updatePerson(id: $id, person: $person) }',
  REMOVE_PERSON:
    'mutation RemovePerson($id: String!) { removePerson(id: $id) }',
  ADD_COMPANY:
    'mutation AddCompany($id: String, $company: CompanyBasicInfo) { addCompany(id: $id, company: $company) }',
  UPDATE_COMPANY:
    'mutation UpdateCompany($id: String!, $company: CompanyBasicInfo) { updateCompany(id: $id, company: $company) }',
  SET_COMPANY_DISCOUNT:
    'mutation SetCompanyDiscount($id: String!, $discount: Float!) { setCompanyDiscount(id: $id, discount: $discount) }',
  REMOVE_COMPANY:
    'mutation RemoveCompany($id: String!) { removeCompany(id: $id) }',
  ADD_EMAIL_ADDRESS:
    'mutation AddEmailAddress($parentId: String!, $parentKind: String!, $record: EmailAddressRecord!) { addEmailAddress(parentId: $parentId, parentKind: $parentKind, emailAddress: $record) }',
  UPDATE_EMAIL_ADDRESS:
    'mutation UpdateEmailAddress($parentId: String!, $parentKind: String!, $record: EmailAddressRecord!) { updateEmailAddress(parentId: $parentId, parentKind: $parentKind,  emailAddress: $record) }',
  REMOVE_EMAIL_ADDRESS:
    'mutation removeEmailAddress($parentId: String!, $parentKind: String!, $id: String! ) { removeEmailAddress(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  ADD_LOCATION:
    'mutation AddLocation($parentId: String!, $parentKind: String!, $record: LocationRecord!) { addLocation(parentId: $parentId, parentKind: $parentKind, location: $record) }',
  UPDATE_LOCATION:
    'mutation UpdateLocation($parentId: String!, $parentKind: String!, $record: LocationRecord!) { updateLocation(parentId: $parentId, parentKind: $parentKind, location: $record) }',
  REMOVE_LOCATION:
    'mutation removeLocation($parentId: String!, $parentKind: String!, $id: String! ) { removeLocation(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  ADD_PHONE_NUMBER:
    'mutation AddPhoneNumber($parentId: String!, $parentKind: String!, $record: PhoneNumberRecord!) { addPhoneNumber(parentId: $parentId, parentKind: $parentKind, phoneNumber: $record) }',
  UPDATE_PHONE_NUMBER:
    'mutation UpdatePhoneNumber($parentId: String!, $parentKind: String!, $record: PhoneNumberRecord!) { updatePhoneNumber(parentId: $parentId, parentKind: $parentKind, phoneNumber: $record) }',
  REMOVE_PHONE_NUMBER:
    'mutation removePhoneNumber($parentId: String!, $parentKind: String!, $id: String! ) { removePhoneNumber(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  SET_TAGS:
    'mutation setTags($parentId: String!, $parentKind: String!, $tags: [String]! ) { setTags(parentId: $parentId, parentKind: $parentKind, tags: $tags) }',
  ADD_EMPLOYEE:
    'mutation AddCompanyEmployee($parentId: String!,  $id: String!) { addCompanyEmployee(id: $parentId, personId: $id) }',
  REMOVE_EMPLOYEE:
    'mutation RemoveCompanyEmployee($parentId: String!,  $id: String!) { removeCompanyEmployee(id: $parentId, personId: $id) }',
  ADD_WORK_EXPERIENCE:
    'mutation AddWorkExperience($parentId: String!, $experience: WorkExperienceInput!) { addWorkExperience(parentId: $parentId, experience: $experience) }',
  UPDATE_WORK_EXPERIENCE:
    'mutation UpdateWorkExperience($parentId: String!, $experience: WorkExperienceInput!) { updateWorkExperience(parentId: $parentId, experience: $experience) }',
  REMOVE_WORK_EXPERIENCE:
    'mutation removeWorkExperience($parentId: String!, $id: String! ) { removeWorkExperience(parentId: $parentId, id: $id) }',
};

export const QUERIES = {
  RETRIEVE_PEOPLE:
    'query People($filters: JSON, $cursor: JSON, $limit: Int, $orderBy: JSON) { people(filters: $filters, limit: $limit, orderBy: $orderBy, cursor: $cursor) { pageInfo { total endCursor } items { id birthDate birthCity { name } gender createdAt updatedAt fiscalCode firstName lastName locations { location { name city province } } employer { id name } projects { id name } emailAddresses { id emailAddress } certificates { id name } registrationSource phoneNumbers { id number } } } }',
  GET_PERSON:
    'query($id: String!) { person(id: $id) { id gender firstName lastName tags registryType operatorMroType networkSize revision birthDate birthCity { name } openToCollab education vatId fiscalCode createdAt updatedAt deletedAt certificates { id name } employer { id name } telegram { chatId username } projects { id name } emailAddresses { id type emailAddress } phoneNumbers { id type countryCode number } locations { id type location { name countryCode route streetNumber city province postalCode coordinates { lat lon } } } sdi pec } }',
  GET_COMPANY:
    'query($id: String!) { company(id: $id) { id name vatId fiscalCode tags sdi pec revision createdAt updatedAt deletedAt website discount reaNumber bankAccounts certificates { id name } emailAddresses { id type emailAddress } phoneNumbers { id type countryCode number } employees { id firstName lastName } irCode teseoCode locations { id type location { name countryCode route streetNumber city province postalCode coordinates { lat lon } } } } }',
};
