import { Expander, Icon } from '@blueprintjs/core';
import { useFieldValue } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDiffableFieldArray } from 'src/utils/formik';
import { QuestionListField } from './QuestionListField';

export default function QuestionRowsField(p: {
  isEditingTranslations: boolean;
}) {
  const [value] = useFieldValue('rows');
  const { t } = useTranslation('survey');

  if (value == null) {
    return <React.Fragment />;
  }

  return (
    <div className="flex flex-col items-stretch border-b mb-3 pb-2">
      <QuestionListField
        name="rows"
        isEditingTranslations={p.isEditingTranslations}
        placeholder={t('Type to add a new row')}
      />
    </div>
  );

  return <div>AA</div>;
}
