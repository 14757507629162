import { isString, isEmpty } from "lodash";

export const sanitizeTypename = (value: unknown) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v) => sanitizeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    // tslint:disable-next-line:no-string-literal
    Object["entries"](value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = sanitizeTypename(v);
      }

      // Because dynamo does not support empty strings, we make sure
      // we do not pass any of those strings to the graph backeend.
      if (isString(v) && isEmpty(v)) {
        newObj[key] = undefined;
      }
    });
    return newObj;
  }
  return value;
};

export default sanitizeTypename;
