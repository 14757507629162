import type { Hooks, TableOptions } from 'react-table';
import type { AppTableInstance, AppTableOptions } from './useAppTable';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { times } from 'lodash';

export interface InfiniteLoaderProps {
  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<any> | null;
  itemCount: number;
  threshold?: number;
  minimumBatchSize?: number;
}

const DEFAULT_BATCH = 25;

function getNumberOfLoadingRows<T>(rows: T[]): number {
  let count = 0;
  for (let i = rows.length - 1; i >= 0; i--) {
    const item = rows[i];
    if (item != null && !('_loading' in item)) {
      break;
    } else {
      count++;
    }
  }

  return count;
}

function useOptions<T extends object>(options: AppTableOptions<T>) {
  if (options.pageInfo == null) {
    return options;
  }
  const rowsToLoad = options.pageInfo.total - options.data.length;

  if (rowsToLoad >= 0) {
    const missing: any = times(Math.min(rowsToLoad, DEFAULT_BATCH), () => ({
      _loading: true,
    }));
    options.data = [...options.data, ...missing];
  }
  return options;
}

function useInstance<T extends object>(instance: AppTableInstance<T>) {
  const numberOfLoadingRows = getNumberOfLoadingRows(instance.data);

  const isItemLoaded = useCallback(
    (index: number) => {
      const loadedLength = instance.data.length - numberOfLoadingRows;
      return index < loadedLength;
    },
    [instance.data, numberOfLoadingRows]
  );
  const itemCount = useMemo(
    () => instance.pageInfo?.total,
    [instance.pageInfo?.total]
  );
  const loadMoreItems = useCallback(
    async (start: number, end: number) => {
      if (instance.pageInfo != null) {
        instance?.onNextCursor(instance.pageInfo.endCursor);
      }
    },
    [instance.pageInfo?.endCursor]
  );

  useEffect(() => {
    if (
      instance.features?.infiniteLoader?.prefetch === true &&
      instance.pageInfo?.endCursor != null
    ) {
      instance?.onNextCursor(instance.pageInfo.endCursor);
    }
  }, [instance.pageInfo?.endCursor]);

  instance.infiniteLoader = {
    isItemLoaded,
    itemCount,
    loadMoreItems,
    minimumBatchSize: DEFAULT_BATCH,
    threshold: Math.floor(DEFAULT_BATCH / 2),
  };
}

export function useInfiniteLoader<T extends object>(hooks: Hooks<T>) {
  hooks.useInstance.push(useInstance);
  hooks.useOptions.push(useOptions);
}
