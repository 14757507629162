import { Dictionary, flatten, keyBy } from 'lodash';
import React, { createContext, useContext, useMemo, useState } from 'react';
import {
  LibraryBlock,
  DataTypeDefinition,
} from '@keix/workflow-types';
import {
  library as defaultLibrary,
} from './types';
import { dataTypes as baseDataType } from './data';
import { useQueries } from '@tanstack/react-query';

export const DATA_TYPE_URI = 'keix.type';

export interface LibraryRepository {
  name: string;
  url: string;
}

export interface LibraryContext {
  repositories: LibraryRepository[];
  blocksByUri: Dictionary<LibraryBlock>;
  dataTypes: Dictionary<DataTypeDefinition>;
}

export const LibraryCtx = createContext<LibraryContext>({
  repositories: [],
  blocksByUri: {},
  dataTypes: {},
});

export function LibraryContextProvider(p: { children: JSX.Element }) {
  const [repositories, setRepositories] = useState<LibraryRepository[]>([
    { name: 'keix', url: 'https://cdn.skypack.dev/@keix/blocks' },
  ]);
  const [library, setLibrary] = useState<LibraryBlock[]>(defaultLibrary);
  const [dataTypes, setDataTypes] =
    useState<DataTypeDefinition[]>(baseDataType);

  const repositoryResponse = useQueries(
    {
      queries: repositories.map((repo) => ({
        queryKey: [`getBlockLibrary`, repo.name],
        queryFn: async () => {
          const { blocks } = await import(/* @vite-ignore */ repo.url).catch(
            () => ({ blocks: [] })
          );
          return blocks;
        },
        staleTime: Infinity,
      }))
    }
  );

  const value: LibraryContext = useMemo(() => {
    const repositoryBlocks: LibraryBlock[] = repositoryResponse.flatMap(
      (d) => d.data ?? []
    );
    const libraryBlocks: LibraryBlock[] = [...repositoryBlocks, {
      name: "read_csv",
      style: {
        bg: { color: "red", opacity: 500 },
        icon: "maximize",
      },
      uri: "pandas.read_csv",
      returnType: "pandas.dataframe",
      arguments: [
        {
          name: "filepath_or_buffer",
          type: ["python.string"], //TYPE PATH
          defaultValue: "_empty",
        },
        { name: "sep", type: "python.string", defaultValue: null },
        { name: "delimiter", type: "python.string", defaultValue: null },
        {
          name: "header",
          type: ["python.int", "python.int_array"],
          defaultValue: null,
        },
        { name: "names", type: "_empty", defaultValue: null }, //TYPE ARRAY-LIKE
        {
          name: "index_col",
          type: [
            "python.string",
            "python.string_array", //TYPE STRING ARRAY
            "python.int",
            "python.int_array", //TYPE INT ARRAY
          ],
          defaultValue: null,
        },
        { name: "usecols", type: "_empty", defaultValue: null }, //TYPE ARRAY-LIKE
        { name: "squeeze", type: "python.bool", defaultValue: false },
        { name: "prefix", type: "python.string", defaultValue: null },
        { name: "mangle_dupe_cols", type: "python.bool", defaultValue: true },
        { name: "dtype", type: "_empty", defaultValue: null }, //TYPE DTYPE
        { name: "engine", type: ["c", "python", "pyarrow"], defaultValue: null },
        { name: "converters", type: "_empty", defaultValue: null }, //TYPE DICT
        { name: "true_values", type: "_empty", defaultValue: null }, //TYPE LIST
        { name: "false_values", type: "_empty", defaultValue: null }, //TYPE LIST
        { name: "skipinitialspace", type: "python.bool", defaultValue: false },
        { name: "skiprows", type: ["python.int"], defaultValue: null },
        { name: "skipfooter", type: "python.int", defaultValue: 0 },
        { name: "nrows", type: "python.int", defaultValue: null },
        { name: "na_values", type: ["python.string"], defaultValue: null }, //TYPE SCALAR, LIST-LIKE, DICT
        { name: "keep_defaultValue_na", type: "python.bool", defaultValue: true },
        { name: "na_filter", type: "python.bool", defaultValue: true },
        { name: "verbose", type: "python.bool", defaultValue: false },
        { name: "skip_blank_lines", type: "python.bool", defaultValue: true },
        {
          name: "parse_dates",
          type: ["python.bool", "python.int_array", "python.string_array"], //TYPE LIST OF DICT
          defaultValue: false,
        },
        {
          name: "infer_datetime_format",
          type: "python.bool",
          defaultValue: false,
        },
        { name: "keep_date_col", type: "python.bool", defaultValue: false },
        { name: "date_parser", type: "_empty", defaultValue: null }, //TYPE FUNCTION
        { name: "dayfirst", type: "python.bool", defaultValue: false },
        { name: "cache_dates", type: "python.bool", defaultValue: true },
        { name: "iterator", type: "python.bool", defaultValue: false },
        { name: "chunksize", type: "python.int", defaultValue: null },
        { name: "compression", type: ["python.string"], defaultValue: "infer" }, //TYPE DICT
        { name: "thousands", type: "python.string", defaultValue: null },
        { name: "decimal", type: "python.string", defaultValue: "." },
        { name: "lineterminator", type: "python.string", defaultValue: null },
        { name: "quotechar", type: "python.string", defaultValue: '"' },
        { name: "quoting", type: "python.int", defaultValue: 0 },
        { name: "doublequote", type: "python.bool", defaultValue: true },
        { name: "escapechar", type: "python.string", defaultValue: null },
        { name: "comment", type: "python.string", defaultValue: null },
        { name: "encoding", type: "python.string", defaultValue: null },
        { name: "encoding_errors", type: "python.string", defaultValue: null },
        { name: "dialect", type: "python.string", defaultValue: null },
        { name: "error_bad_lines", type: "python.bool", defaultValue: null },
        { name: "warn_bad_lines", type: "python.bool", defaultValue: null },
        {
          name: "on_bad_lines",
          type: "python.string",
          //  ["error", "warn", "skip"],
          defaultValue: "error",
        },
        {
          name: "delim_whitespace",
          type: "python.bool",
          defaultValue: false,
        },
        { name: "low_memory", type: "python.bool", defaultValue: true },
        { name: "memory_map", type: "python.bool", defaultValue: false },
        { name: "float_precision", type: "python.string", defaultValue: null },
        {
          name: "storage_options",
          type: "typing.Optional[typing.Dict[str, typing.Any]]", //TYPE DICT
          defaultValue: null,
        },
      ],
      code: "from pandas import read_csv",
    },

    {
      name: 'load_survey_shares',
      uri: 'keix.load_survey_shares',
      arguments: [
        {
          name: 'in',
          type: 'python.string',
          isPort: false,
        },
      ],
      code: `
import json
import js
import pandas as pd
from io import StringIO
from pyodide import to_js
from js import Object
import collections

def flatten_dict(d, parent_key='', sep='_'):
    items = []
    for k, v in d.items():
        new_key = parent_key + sep + k if parent_key else k
        if isinstance(v, collections.abc.MutableMapping):
            items.extend(flatten_dict(v, new_key, sep=sep).items())
        else:
            items.append((new_key, v))
    return dict(items)

async def load_survey_shares(_in):
    print("Sending req...")
    idToken = js.window.idToken
    if idToken is None:
      raise Exception("Not Authorized")
    resp = await js.fetch('https://dev.graphql.keix.com/graphql',
      method="POST",
      body=json.dumps({ "query" : "query Shares($id: String!) { surveyShares(surveyId: $id, limit: 3000) { items { id enabled sender { id name } target { id name } medium  metrics { opens durations closes dropout screenout avgDuration stdDuration minDuration maxDuration } type expirationDate quota url sentAt } } }", "variables": { "id": _in }}),
      credentials="same-origin",
      headers=Object.fromEntries(to_js({ "Content-Type": "application/json", "Authorization": "Bearer " + idToken })),
    )
    response_data = await resp.json()
    
    shares = response_data.data.surveyShares.items.to_py()
    records = map(lambda x: flatten_dict(x), shares)
    return pd.DataFrame.from_records(records)
`,
      returnType: 'pandas.dataframe',
      async: true,
      style: {
        icon: 'form',
        bg: {
          color: 'blue',
          opacity: 500,
        },
      },
    },
    {
      name: 'value_counts',
      style: {
        bg: { color: 'red', opacity: 500 },
        icon: 'percentage',
      },
      functionType: 'method',
      uri: 'pandas.value_counts',
      docsUrl:
        'https://pandas.pydata.org/docs/reference/api/pandas.DataFrame.value_counts.html',
      returnType: 'pandas.series',
      arguments: [
        {
          name: 'self',
          type: 'pandas.dataframe',
          isPort: true,
        },
        {
          name: 'subset',
          type: ['python.string', 'python.string_array'],
          description: 'Columns to use when counting unique combinations.',
        },
        {
          name: 'sort',
          type: 'python.bool',
          defaultValue: true,
          description: 'Sort by frequencies.',
        },
        {
          name: 'ascending',
          type: 'python.bool',
          defaultValue: false,
          description: 'Sort in ascending order.',
        },
        {
          name: 'normalize',
          type: 'python.bool',
          defaultValue: true,
          description: 'Return proportions rather than frequencies.',
        },
        {
          name: 'dropna',
          type: 'python.bool',
          defaultValue: true,
          description: 'Don’t include counts of rows that contain NA values.',
        },
      ],
      code: '',
    },
    {
      name: "merge",
      style: {
        bg: { color: "red", opacity: 500 },
        icon: "maximize",
      },
      returnType: 'pandas.dataframe',
      uri: "pandas.merge",
      arguments: [
        { name: "left", type: "pandas.dataframe", isPort: true, defaultValue: null }, //TYPE DataFrame
        { name: "right", type: "pandas.dataframe", isPort: true, defaultValue: null }, //TYPE DataFrame
        {
          name: "how",
          type: "python.string",
          defaultValue: "inner",
        },
        {
          name: "on",
          type: ["python.string", "pandas.string_list"], //TYPE  list of strings
          defaultValue: null,
        },
        {
          name: "left_on",
          type: ["python.string", "pandas.string_list"], //TYPE  list of strings | array-like
          defaultValue: null,
        },
        {
          name: "right_on",
          type: ["python.string", "pandas.string_list"], //TYPE  list of strings | array-like
          defaultValue: null,
        },
        { name: "left_index", type: "python.bool", defaultValue: false },
        { name: "right_index", type: "python.bool", defaultValue: false },
        { name: "sort", type: "python.bool", defaultValue: false },
        // { name: "suffixes", type: "_empty", defaultValue: ["_x", "_y"] }, //TYPE list-like
        { name: "copy", type: "python.bool", defaultValue: true },
        {
          name: "indicator",
          type: ["python.bool", "python.string"],
          defaultValue: false,
        },
        { name: "validate", type: "python.string", defaultValue: null },
      ],
      code: "from pandas import merge",
    },
    {
      name: "to_frame",
      style: {
        bg: { color: "red", opacity: 500 },
        icon: "maximize",
      },
      returnType: 'pandas.dataframe',
      functionType: 'method',
      uri: "pandas.to_frame",
      arguments: [
        { name: "self", type: "pandas.series", isPort: true, defaultValue: null }, //TYPE DataFrame
        { name: "name", type: "python.string", defaultValue: null }, //TYPE DataFram
      ],
      code: '',
    },
    {
      name: "to_frame",
      style: {
        bg: { color: "red", opacity: 500 },
        icon: "maximize",
      },
      returnType: 'pandas.dataframe',
      functionType: 'method',
      uri: "pandas.to_frame",
      arguments: [
        { name: "self", type: "pandas.series", isPort: true, defaultValue: null }, //TYPE DataFrame
        { name: "name", type: "python.string", defaultValue: null }, //TYPE DataFram
      ],
      code: '',
    },
    {
      name: 'pivot_table',
      style: {
        bg: { color: 'red', opacity: 500 },
        icon: 'pivot',
      },
      functionType: 'function',
      returnType: 'pandas.dataframe',
      uri: 'pandas.pivot_table',
      arguments: [
        { name: 'data', type: 'pandas.dataframe', isPort: true },
        {
          name: 'index',
          type: ['python.string', 'python.string_array']
        },
        {
          name: 'columns',
          type: ['python.string_array', 'python.string']
        },
        {
          name: 'values',
          type: ['python.string_array', 'python.string']
        },
        {
          name: 'aggfunc',
          type: 'python.string',
          defaultValue: 'mean'
        },
        {
          name: 'fill_value',
          type: ['python.int', 'python.float'],
          defaultValue: 0,
        }
        , {
          name: 'margins',
          type: 'python.bool',
          defaultValue: false,
        }
        , {
          name: 'dropna',
          type: 'python.bool',
          defaultValue: true,
        },
        {
          name: 'margins_name',
          type: 'python.string',
          defaultValue: 'All'
        },
        {
          name: 'sort',
          type: 'python.bool',
          defaultValue: true
        }
      ],
      code: 'from pandas import pivot_table',
    },
    {
      name: 'calculate_pivot_percentage',
      style: {
        bg: { color: 'red', opacity: 500 },
        icon: 'percentage',
      },
      functionType: 'function',
      returnType: 'pandas.dataframe',
      uri: 'pandas.calculate_pivot_percentage',
      arguments: [
        { name: 'in', type: 'pandas.dataframe', isPort: true },
        { name: 'decimals', type: 'python.int', defaultValue: 3 },
      ],
      code: `
def calculate_pivot_percentage(_in, decimals = 3):
  return (_in.iloc[:-1].div(_in.iloc[-1], axis=1) * 100).round(decimals)
`,
    },
    {
      name: 'exclude_random_data',
      style: {
        bg: { color: 'red', opacity: 500 },
        icon: 'filter',
      },
      functionType: 'function',
      returnType: 'pandas.dataframe',
      uri: 'pandas.exclude_random_data',
      arguments: [
        { name: 'in', type: 'pandas.dataframe', isPort: true },
        { name: 'expr', type: 'python.string' },
        { name: 'amount', type: 'python.int', defaultValue: 1 },
        { name: 'frac', type: 'python.float' },
        { name: 'random_state', type: 'python.int', defaultValue: 1 },
      ],
      code: `
def exclude_random_data(_in, expr = "", amount = 1, frac=None, random_state = 1):
  query = _in.query(expr).sample(n=amount, frac=frac, random_state=random_state)
  return _in.loc[~_in.index.isin(query.index)]
`,
    },
    {
      name: "round",
      style: {
        bg: { color: "red", opacity: 500 },
        icon: "maximize",
      },
      returnType: 'pandas.dataframe',
      functionType: 'method',
      uri: "pandas.round",
      arguments: [
        { name: "self", type: "pandas.dataframe", isPort: true }, //TYPE DataFrame
        { name: "decimals", type: "python.int" }, //TYPE DataFram
      ],
      code: '',
    },
    ];

    // Build the data type.
    const keixDataType: DataTypeDefinition = {
      name: 'Data Type',
      uri: DATA_TYPE_URI,
      description: 'A data type definition',
      extends: 'python.string',
      predefinedValues: dataTypes.map((d) => ({
        label: d.name,
        icon: d.icon,
        value: d.uri,
        description: d.uri,
      })),
    };


    return {
      repositories,
      blocksByUri: keyBy(libraryBlocks, 'uri'),
      dataTypes: keyBy([keixDataType, ...dataTypes], 'uri'),
    };
  }, [library, dataTypes, repositories, repositoryResponse]);
  return <LibraryCtx.Provider value={value}>{p.children}</LibraryCtx.Provider>;
}

export function useLibraryContext() {
  return useContext(LibraryCtx);
}

export function resolveDataType(
  dataType: DataTypeDefinition,
  dataTypes: Dictionary<DataTypeDefinition>
): DataTypeDefinition {
  if (dataType.extends != null) {
    const parent = dataTypes[dataType.extends];
    const parentDataType = resolveDataType(parent, dataTypes);
    return Object.assign({}, parentDataType, dataType);
  }
  return dataType;
}
