import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Callout,
  Card,
  IconName,
  Position,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { Icon, H2, Button, Intent, Classes } from '@blueprintjs/core';
import { Accordion } from 'src/components/Accordion';
import EditableField, {
  Loadable,
  isLoaded,
  isLoading,
} from 'src/components/EditableField';
import {
  EditableTextField,
  EditableTextFieldProps,
  HTMLSelectField,
  NumericField,
  NumericInputField,
  SwitchField,
  TextAreaField,
  TextField,
} from 'src/components/BlueprintFields';
import {
  useFormik,
  FormikContext,
  FormikContextType,
  FormikState,
  FormikHelpers,
  useIsSubmitting,
  useIsDirty,
  useSubmitForm,
  useResetForm,
  useField,
} from 'formik';
import {
  LazyMapLocationField,
  LocationField,
} from 'src/components/LocationField';
import EditableList from 'src/components/EditableList';
import PhoneNumberField from 'src/components/PhoneNumberField';
import { compact, get, isEmpty, update, upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Ref,
  RegistryType,
  OperatorMroType,
  DocumentType,
} from '../../apps/athena/gql-types';
import { combine, diffableArray, Result, simpleField } from 'src/utils/diffV2';
import { array, date, object, string } from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useGraphQLFetch } from 'src/utils/graphql';
import css from 'csz';
import {
  Crumb,
  useCrumbsContext,
  useResourceDetailCrumbs,
} from 'src/context/CrumbsContext';
import { DateTime } from 'luxon';
import { useEnvironment } from 'src/context/EnvironmentContext';
import { FeatureSetBlock } from 'src/context/FeatureSet';
import { Tooltip2 } from '@blueprintjs/popover2';
import { DiffDebug, DiffState } from '../DiffDebug';
import { ValidationDebug } from '../ValidationDebug';
import { DebugView } from '../DebugView';
import CertificatesDetail from './CertificatesDetail';
import { getEnumValues } from 'src/utils/accessors';
import classNames from 'classnames';
import Monitor from 'src/pages/Credits/CreditsSection';

export interface BaseObject {
  id: string;
  certificates?: Ref[];
}

export type FormHelper<T> = FormikHelpers<Partial<T>> & FormikState<Partial<T>>;

function getLocationTypes(dataKey: string) {
  if (dataKey === 'company') {
    return ['HEADQUARTERS', 'FIELDOFFICE'];
  } else if (dataKey === 'person') {
    return ['RESIDENCE', 'DOMICILE', 'OFFICE'];
  } else {
    return ['GENERIC'];
  }
}

export function useBasicValidationSchema(validationSchema: any) {
  const { t } = useTranslation();
  const requiredError = t('is required');
  const notExpiredError = t('not expired');
  return useMemo(
    () =>
      object({
        ...validationSchema,
        emailAddresses: array()
          .nullable()
          .of(
            object({
              type: string().nullable(),
              emailAddress: string()
                .email(t('must be a valid email'))
                .required(requiredError),
            }).required(requiredError)
          ),
        phoneNumbers: array()
          .nullable()
          .of(
            object({
              type: string().nullable(),
              number: string().required(requiredError),
            })
          ),
        registryType: string()
          .nullable()
          .oneOf([null, ...getEnumValues(RegistryType)]),
        locations: array()
          .nullable()
          .of(
            object({
              type: string().nullable(),
              location: object({
                name: string().required(),
              }),
            }).required(requiredError)
          ),
        documents: array()
          .nullable()
          .of(
            object({
              type: string().oneOf(Object.values(DocumentType), requiredError),
              number: string().required(requiredError),
              expirationDate: date().min(
                DateTime.now().endOf('day'),
                notExpiredError
              ),
            })
          ),
      }),
    [validationSchema]
  );
}

export function getBasicMiddleware(parentId: string, parentKind: string) {
  return combine(
    diffableArray({
      name: 'emailAddresses',
      commandSuffix: 'EMAIL_ADDRESS',
      data: { parentId, parentKind },
    }),
    diffableArray({
      name: 'phoneNumbers',
      commandSuffix: 'PHONE_NUMBER',
      data: { parentId, parentKind },
    }),
    diffableArray({
      name: 'locations',
      commandSuffix: 'LOCATION',
      data: { parentId, parentKind },
    }),
    simpleField({
      name: 'tags',
      command: 'SET_TAGS',
      data: { parentId, parentKind },
    })
  );
}

export function useBasicCrumbs<T>(data: any, props: P<T>) {
  const { updateCrumbs } = useCrumbsContext();
  const { t } = useTranslation();
  const { dataKey, headerFields, isNew, id } = props;

  const crumb: Crumb = useMemo(() => {
    const values = get(data, dataKey);
    const label = compact(headerFields.map(({ name }) => values?.[name])).join(
      ' '
    );
    const text = isNew ? t('New') : label.length > 0 ? label : t('Loading...');

    return {
      text,
      href: `/${dataKey}/${id}`,
    };
  }, [data, dataKey, headerFields, id, isNew]);

  useResourceDetailCrumbs(crumb);

  // function regenerateCrumbs() {
  //   updateCrumbs(generateCrumbs(text));
  // }
  // useEffect(regenerateCrumbs, [data]);
  // useEffect(() => {
  //   regenerateCrumbs();
  //   return () => {
  //     updateCrumbs(generateCrumbs());
  //   };
  // }, []);

  // const key = window.location.pathname;
  // const generateCrumbs = (text?: string): Crumb[] =>
  //   compact([
  //     {
  //       href: `${key}`,
  //       text: t(`${key}`),
  //     },
  //     text != null
  //       ? {
  //           href: `/${dataKey}/${id}`,
  //           text,
  //         }
  //       : null,
  //   ]);
}
export interface P<T> {
  id?: string;
  isNew?: boolean;
  icon: IconName;
  query: string;
  dataKey: string;
  children: JSX.Element;
  validationSchema: any; //ValidationSche<{}>;
  updateCommand: string;
  onChange?: (values: Partial<T>, form: FormHelper<T>) => void;
  onSaveChanges: (
    form: FormikContextType<Loadable<T>>,
    debug?: boolean
  ) => Promise<Result<{}>[]>;
  onApplyChanges?: (value: T) => void;
  headerFields: EditableTextFieldProps[];
}

export default function BasicDetail<T extends BaseObject>(p: P<T>) {
  const { id, query, dataKey, onChange } = p;
  const fetch = useGraphQLFetch();

  const { t } = useTranslation();
  const [debug, setDebug] = useState(true);
  const [debugState, setDebugState] = useState<DiffState>([]);
  const [shouldRefreshData, setShouldRefreshData] = React.useState(false);
  const [stale, setStale] = useState(false);

  const { data } = useQuery<any>([p.dataKey, id], () => fetch(query, { id }), {
    enabled: p.isNew !== true,
  });
  useBasicCrumbs(data, p);
  const values = get(data, dataKey);
  useEffect(() => {
    const resetForm = () => form.resetForm({ values, initialValues: values });
    if (isLoading(form.values) && !isEmpty(data)) {
      resetForm();
    } else if (shouldRefreshData) {
      setShouldRefreshData(false);
      resetForm();
    } else if (isLoaded(form.values) && !isEmpty(form.values)) {
      if (form.values == null || values == null) {
        return;
      }
      if ('revision' in form.values && 'revision' in values) {
        if (form.values.revision < values.revision) {
          setStale(true);
        } else {
          resetForm();
        }
      }
    }
  }, [data]);

  const validationSchema = useBasicValidationSchema(p.validationSchema);

  const initialValues: Loadable<T> =
    get(data, dataKey) ?? (p.isNew ? {} : { _loading: true });
  const form = useFormik<Loadable<Partial<T>>>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      setShouldRefreshData(true);
      return p.onSaveChanges(form);
    },
  });

  useEffect(() => {
    if (onChange && isLoaded(form.values)) {
      onChange(form.values, form);
    }
  }, [form.values, form.errors, onChange]);

  const handleUpdateChanges = useCallback(() => {
    // We have to reset the form with new values.
    const values = get(data, dataKey);
    form.resetForm({ values, initialValues: values });
    setStale(false);
  }, [data, dataKey]);

  useEffect(() => {
    if (debug) {
      p.onSaveChanges(form, true).then((res) => {
        setDebugState(res);
      });
    }
  }, [form.values]);

  const locationTypes = getLocationTypes(p.dataKey);

  const name = compact(p.headerFields.map((d) => form.values[d.name])).join(
    ' '
  );
  const owner: Ref = { id: p.id, name };
  return (
    <FormikContext.Provider value={form}>
      <div className="flex-grow flex flex-col relative items-stretch">
        <div className="flex-grow flex flex-column overflow-scroll">
          <div className="flex-grow flex flex-column pb-10">
            <EditableBasicHeader
              isNew={p.isNew}
              icon={p.icon}
              headerFields={p.headerFields}
            />
            <DebugView>
              <DiffDebug diff={debugState} />
              <ValidationDebug errors={form.errors} />
            </DebugView>
            {p.children}
            <>
              <FeatureSetBlock feature="CRM_DETAIL_CERTIFICATES">
                <CertificatesDetail owner={owner} type={upperCase(p.dataKey)} />
              </FeatureSetBlock>
              <Accordion
                initiallyOpened={true}
                kind="section"
                title={t('Registry')}
              >
                <EditableField
                  label={t('Open To Collaborate')}
                  name="openToCollab"
                  component={SwitchField}
                  componentProps={{ defaultValue: values?.openToCollab }}
                />
                <EditableField
                  label={t('Registry Type')}
                  name="registryType"
                  component={HTMLSelectField}
                  componentProps={{
                    minimal: true,
                    options: [
                      { label: '-', value: 'UNKNOWN' },
                      {
                        label: t('MRO'),
                        value: RegistryType.Mro,
                      },
                      {
                        label: t('Respondent'),
                        value: RegistryType.Respondent,
                      },
                      {
                        label: t('Work Applicant'),
                        value: RegistryType.WorkApplicant,
                      },
                    ],
                  }}
                />
                {values?.registryType === RegistryType.Mro && (
                  <EditableField
                    label={t('Operator MRO Type')}
                    name="operatorMroType"
                    component={HTMLSelectField}
                    componentProps={{
                      minimal: true,
                      options: [
                        { label: '-', value: 'UNKNOWN' },
                        {
                          label: 'Rilevatore Face To Face',
                          value: OperatorMroType.RilevatoreFaceToFace,
                        },
                        {
                          label: 'Reperitore Qualitativo',
                          value: OperatorMroType.ReperitoreQualitativo,
                        },
                        {
                          label: 'Reperitore Quantitativo',
                          value: OperatorMroType.ReperitoreQuantitativo,
                        },
                        {
                          label: 'Rilevatore Face To Face',
                          value: OperatorMroType.RilevatoreFaceToFace,
                        },
                        {
                          label: 'Rilevatore Telefonico',
                          value: OperatorMroType.RilevatoreTelefonico,
                        },
                        {
                          label: 'Rilevatore Online',
                          value: OperatorMroType.RilevatoreOnline,
                        },
                        {
                          label: 'Moderatore Qualitativo',
                          value: OperatorMroType.ModeratoreQualitativo,
                        },
                        {
                          label: 'Ricercatore Qualitativo',
                          value: OperatorMroType.RicercatoreQualitativo,
                        },
                        {
                          label: 'Mistery Client',
                          value: OperatorMroType.MisteryClient,
                        },
                        {
                          label: "Supervisore d'area",
                          value: OperatorMroType.SupervisoreArea,
                        },
                      ],
                    }}
                  />
                )}
                {values?.registryType === RegistryType.Mro && (
                  <EditableField
                    label={t('Network Size')}
                    name="networkSize"
                    component={NumericInputField}
                  />
                )}
              </Accordion>
              <Accordion
                kind="section"
                initiallyOpened={true}
                title={t('Credits Section')}
              >
                <Monitor values={values} />
              </Accordion>
              <Accordion
                kind="section"
                initiallyOpened={true}
                title={t('Email Addresses')}
              >
                <EditableList
                  addButtonText={t('Add Email Address')}
                  name="emailAddresses"
                  component={EmailAddressField}
                  addItem={{ type: 'ORDER' }}
                />
              </Accordion>
              <Accordion
                initiallyOpened={true}
                kind="section"
                title={t('Locations')}
              >
                <EditableList
                  name="locations"
                  addButtonText={t('Add Location')}
                  component={LocationsField}
                  componentProps={{ locationTypes }}
                  addItem={{ type: locationTypes[0] }}
                />
                <FeatureSetBlock feature="PERSON_LOCATION_MAP">
                  <LazyMapLocationField name="locations" />
                </FeatureSetBlock>
              </Accordion>
              <Accordion
                initiallyOpened={true}
                kind="section"
                title={t('Phone Numbers')}
              >
                <EditableList
                  name="phoneNumbers"
                  addButtonText={t('Add Phone Number')}
                  component={PhoneNumbersField}
                  addItem={{ type: 'cellular' }}
                />
              </Accordion>
              <Accordion
                initiallyOpened={true}
                kind="section"
                title={t('Invoice Details')}
              >
                {p.dataKey === 'company' && (
                  <EditableField
                    label={t('Discount')}
                    name="discount"
                    componentProps={{
                      min: 0,
                      max: 99,
                      style: { width: '70px' },
                      buttonPosition: 'none',
                      leftIcon: 'percentage',
                      maxLength: 2,
                    }}
                    component={NumericField}
                  />
                )}
                <EditableField
                  label={t('SDI')}
                  name="sdi"
                  component={EditableTextField}
                />
                <EditableField
                  label={t('Pec')}
                  name="pec"
                  component={EditableTextField}
                />
                <FeatureSetBlock feature="TESEO">
                  <EditableField
                    label={t('Codice Teseo')}
                    name="teseoCode"
                    component={EditableTextField}
                  />
                </FeatureSetBlock>
              </Accordion>
            </>

            <BottomActionBar
              ns="people"
              valid={form.isValid}
              createdAt={values?.createdAt}
              updatedAt={values?.updatedAt}
              registrationSource={values?.registrationSource}
              stale={stale}
              onUpdateChanges={handleUpdateChanges}
            />
          </div>
        </div>
      </div>
    </FormikContext.Provider>
  );
}

function EmailAddressField(p: { name: string }) {
  const [_, meta] = useField(p.name);

  return (
    <div className="flex items-center">
      <HTMLSelectField
        name={`${p.name}.type`}
        className="mr-3"
        options={[
          { label: '-', value: '' },
          { label: 'OFFER', value: 'OFFER' },
          { label: 'ORDER', value: 'ORDER' },
        ]}
      />
      <EditableTextField
        alwaysRenderInput={true}
        intent={meta.error != null ? Intent.WARNING : null}
        name={`${p.name}.emailAddress`}
      />
    </div>
  );
}

export function LocationsField(p: {
  name: string;
  locationTypes: string[];
  showInfoButton?: boolean;
}) {
  const { t } = useTranslation();
  const onlyLocationType = p.locationTypes.length === 1;
  return (
    <div className="flex items-center">
      <HTMLSelectField
        name={`${p.name}.type`}
        className={classNames('mr-3', { hidden: onlyLocationType })}
        options={p.locationTypes.map((type) => ({
          label: t(`LOCATION_TYPE_${type.toUpperCase()}`),
          value: type,
        }))}
      />
      <LocationField
        name={`${p.name}.location`}
        info={p.showInfoButton ?? true}
        types={['geocode']}
        fields={[
          'formatted_address',
          'geometry.location',
          'address_components',
        ]}
      />
    </div>
  );
}
function PhoneNumbersField(p: { name: string }) {
  const [_, meta] = useField(p.name);
  return (
    <div className="flex items-center">
      <HTMLSelectField
        name={`${p.name}.type`}
        className="mr-3"
        options={[
          { label: 'cellulare', value: 'cellular' },
          { label: 'casa', value: 'home' },
          { label: 'lavoro', value: 'work' },
        ]}
      />
      <PhoneNumberField name={p.name} defaultCountry="IT" />
    </div>
  );
}

export function BasicHeader(p: {
  icon: IconName | JSX.Element;
  title: string;
  actions?: JSX.Element;

  isLoading?: boolean;
}) {
  return (
    <div className="border-bottom px-4 py-4 flex items-center card-bg card-box-shadow sticky-top z2">
      <Icon icon={p.icon} className="tertiary-text mr-4" iconSize={60} />
      <h2
        className={`primary-text text-2xl font-semibold ${
          p.isLoading ? Classes.SKELETON : ''
        }`}
      >
        {p.title}
      </h2>
      <div className="flex-grow" />
      {p.actions}
    </div>
  );
}
export function EditableBasicHeader(p: {
  icon: IconName;
  isNew: boolean;
  disabled?: boolean;
  actions?: JSX.Element;
  headerFields: EditableTextFieldProps[];
  codeHeaderFields?: EditableTextFieldProps;
}) {
  return (
    <div className="border-bottom px-3 py-6 flex flex-shrink-0 items-center card-bg card-box-shadow sticky-top z-10">
      <Icon icon={p.icon} className="tertiary-text mr-4" iconSize={60} />
      {p.codeHeaderFields && !p.isNew ? (
        <h2 className="text-3xl font-semibold primary-text">
          <EditableTextField
            className="mr-2"
            name={p.codeHeaderFields.name}
            placeholder={p.codeHeaderFields.placeholder}
            disabled
          />
        </h2>
      ) : null}
      {p.headerFields.map((field, index) => (
        <h2 key={index} className="text-3xl font-semibold primary-text">
          <EditableTextField
            className="mr-2"
            disabled={p.disabled}
            {...field}
          />
        </h2>
      ))}
      <div className="flex-grow" />
      {p.actions}
    </div>
  );
}

export function BottomActionBar(p: {
  ns?: string;
  stale?: boolean;
  createdAt?: string;
  updatedAt?: string;
  registrationSource?: string;
  readOnly?: boolean;
  valid?: boolean;
  onSubmit?: () => void;
  onUpdateChanges?: () => void;
}) {
  const isSubmitting = useIsSubmitting();
  const isDirty = useIsDirty();
  const submitForm = useSubmitForm();
  const resetForm = useResetForm();

  const { t } = useTranslation(p.ns);

  function renderUpdatedDescription(date: DateTime) {
    if (Math.abs(date.diffNow('months').months) > 3) {
      return date.toLocaleString();
    } else if (Math.abs(date.diffNow('minutes').minutes) <= 1) {
      return t('just now');
    } else {
      return date.toRelative();
    }
  }

  const updatedAt = p.updatedAt != null ? DateTime.fromISO(p.updatedAt) : null;
  const updateDetail =
    updatedAt != null ? (
      <Tooltip2
        content={
          p.createdAt || p.registrationSource ? <CreationInfoBox /> : null
        }
        position={Position.TOP}
        minimal
      >
        <div className="text-xs italic">
          <span className="text-gray-500">{t('Updated')}</span>
          <span className="px-1 font-semibold text-gray-600">
            {renderUpdatedDescription(updatedAt)}
          </span>
        </div>
      </Tooltip2>
    ) : null;
  function CreationInfoBox() {
    return (
      <>
        {p.registrationSource ? (
          <p>
            {t('Registration Source')} {p.registrationSource}
          </p>
        ) : null}
        {p.createdAt ? (
          <p>
            {t('Created')}{' '}
            {renderUpdatedDescription(DateTime.fromISO(p.createdAt))}
          </p>
        ) : null}
      </>
    );
  }
  return (
    <div className="absolute bottom-0 left-0 right-0 border-top flex-shrink-0 card-secondary-bg card-box-shadow p-1 flex items-center">
      {p.readOnly !== true && (
        <Button
          icon="cross"
          text={t('Cancel')}
          minimal
          disabled={isSubmitting}
          onClick={() => resetForm()}
        />
      )}
      <div className="flex-grow" />
      {p.stale ? (
        <div className={reloadClassName}>
          <Button
            small
            icon={<Icon icon="refresh" iconSize={12} />}
            intent={Intent.PRIMARY}
            onClick={p.onUpdateChanges}
          >
            {t('Load new changes...')}
          </Button>
        </div>
      ) : (
        updateDetail
      )}
      <div className="flex-grow" />
      {p.readOnly !== true && (
        <Button
          icon="tick"
          intent={p.valid === false ? Intent.WARNING : Intent.PRIMARY}
          text={t('Save')}
          loading={isSubmitting}
          disabled={isSubmitting || p.valid === false}
          onClick={p.onSubmit ?? submitForm}
          minimal={!isDirty || p.valid === false}
        />
      )}
    </div>
  );
}

const reloadClassName = css`
  align-self: center;
  .${Classes.TAG} {
    cursor: pointer;
  }
`;
