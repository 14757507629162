import React, { useState, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Intent,
  H4,
  EditableText,
  Divider,
  Icon,
  HTMLSelect,
  Classes,
  Popover,
} from '@blueprintjs/core';
import {
  Note,
  NoteInput,
  Query,
  NoteReminderInput,
  SchedulerRate,
} from 'src/apps/athena/gql-types';
import classNames from 'classnames';
import css from 'csz';
import { useSendCommand, useWaitForIndex } from 'src/utils/events';
import { DateTime } from 'luxon';
import { useGraphQLFetch } from 'src/utils/graphql';
import { update } from 'lodash';
import {
  updatePaginatedQueryData,
  deleteItemOnPaginatedQueryData,
  prependPaginatedQueryData,
} from 'src/utils/queryCache';
import { useNotifications } from 'src/context/NotificationContext';
import { useTranslation } from 'react-i18next';
import { DateInput } from './DateField';
import { Form, Formik } from 'formik';

const FRAGMENT = `
fragment BaseNote on Note {
  id
  uri
  title
  body
  author {
    name
    id
  }
  reminder {
    date
    rate
  }
  pinned
  createdAt
  updatedAt
}`;
const QUERY = `
  query NotesByUri($uri: String!) {
    notes(uri: $uri) {
      items {
        ...BaseNote
      }
      pageInfo {
        endCursor
      }
    }
  }
${FRAGMENT}`;

export function useNotesQuery(uri: string) {
  const graphqlFetch = useGraphQLFetch();
  return useQuery<Pick<Query, 'notes'>>(
    ['notes', uri],
    () => graphqlFetch(QUERY, { uri }),
    {
      refetchInterval: 10000,
    }
  );
}

export function NotesByUri(p: { uri: string }) {
  const { data } = useNotesQuery(p.uri);

  return (
    <>
      <NoteCard uri={p.uri} />
      {data?.notes?.items?.map((note) => (
        <NoteCard uri={p.uri} note={note} />
      ))}
    </>
  );
}

function DummyNoteCard(p: { uri: string }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const key = ['notes', p.uri];
  const waitForIndex = useWaitForIndex();

  const [{ isLoading }, createNote] = useSendCommand<
    { id: string },
    { note: NoteInput },
    Note
  >(
    'mutation CreateNote($note: NoteInput!) { createNote(note: $note) }',
    async (evt, ctx) => {
      if (evt.type === 'NOTE_CREATED') {
        const note = await waitForIndex<Note>(
          `query Note($id: String!) { note(id: $id) { ...BaseNote } } ${FRAGMENT}`,
          { id: evt.data.id }
        );
        ctx.complete(note);
      }
    },
    {
      optimistic: false,
      onSuccess: (note?: Note) => {
        notification.show({
          intent: Intent.SUCCESS,
          icon: 'tick',
          message: t('Note Added'),
        });
        if (note != null) {
          prependPaginatedQueryData(queryClient, key, 'notes', note);
        }
      },
      onSettled: () => queryClient.invalidateQueries(key),
    }
  );

  const [value, setValue] = useState('');
  const handleConfirm = useCallback((body: string) => {
    // Safe check.
    if (body?.length <= 0) {
      return;
    }

    // Reset the value.
    setValue('');

    // Start the create note.
    createNote({
      note: {
        body,
        uri: p.uri,
      },
    });
  }, []);

  return (
    <div className={classNames('card p0 isNew', className)}>
      <EditableText
        disabled={isLoading}
        multiline
        isEditing={false}
        placeholder={
          isLoading ? t('Saving...') : t('Start typing a new note...')
        }
        onConfirm={handleConfirm}
        onChange={setValue}
        value={value}
      />
      {isLoading && (
        <Button
          loading={isLoading}
          className={floatingButton}
          minimal
          intent={Intent.PRIMARY}
        />
      )}
    </div>
  );
}

function NoteTimestamp(p: { note: Note }) {
  const { createdAt, updatedAt } = p.note;
  if (updatedAt != null) {
    return (
      <div className="note-timestamp">
        {DateTime.fromISO(updatedAt).toRelative()}
      </div>
    );
  } else {
    return (
      <div className="note-timestamp">
        {DateTime.fromISO(createdAt).toRelative()}
      </div>
    );
  }
}

function NoteCard(p: { uri: string; note?: Note }) {
  if (p.note == null) {
    return <DummyNoteCard {...p} />;
  }
  const { id, reminder } = p.note;
  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(
    () => queryClient.invalidateQueries(['notes', p.uri]),
    []
  );
  const { t } = useTranslation();
  const notification = useNotifications();
  const [bodyEditing, setBodyEditing] = useState(false);
  const [reminderEditing, setReminderEditing] = useState(false);

  const [bodyValue, setBodyValue] = useState<string>(p.note.body);
  const [reminderValue, setReminderValue] =
    useState<NoteReminderInput | null>();

  const [body, setBody] = useSendCommand<{}, { id: string; body: string }>(
    'mutation SetBody($id: String!, $body: String!) { setNoteBody(id: $id, body: $body) }',
    (evt, ctx) => {
      if (evt.type === 'NOTE_BODY_SET') {
        ctx.complete();
      }
    },
    {
      onMutate: ({ body, id }) => {
        updatePaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => (n.id === id ? { ...n, body } : n)
        );
      },
      onSuccess: () => {
        notification.show({
          intent: Intent.SUCCESS,
          message: t('Note Updated'),
          icon: 'tick',
        });
      },
      onSettled: invalidateQuery,
    }
  );

  const [_, setNoteReminder] = useSendCommand<
    {},
    { id: string; reminder: NoteReminderInput }
  >(
    'mutation SetNoteReminder($id: String!, $reminder: NoteReminderInput!) { setNoteReminder(id: $id, reminder: $reminder) }',
    (evt, ctx) => {
      if (evt.type === 'NOTE_REMINDER_SET') {
        ctx.complete();
      }
    },
    {
      onMutate: ({ id, reminder }) => {
        updatePaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => (n.id === id ? { ...n, reminder } : n)
        );
      },
      onSuccess: () => {
        notification.show({
          intent: Intent.SUCCESS,
          message: t('Note Reminder Set'),
          icon: 'notifications',
        });
      },
      onSettled: invalidateQuery,
    }
  );
  const [__, unsetNoteReminder] = useSendCommand<{}, { id: string }>(
    'mutation UnsetNoteReminder($id: String!) { unsetNoteReminder(id: $id) }',
    (evt, ctx) => {
      if (evt.type === 'NOTE_REMINDER_UNSET') {
        ctx.complete();
      }
    },
    {
      onMutate: ({ id }) => {
        updatePaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => (n.id === id ? { ...n, reminder: null } : n)
        );
      },
      onSuccess: () => {
        notification.show({
          intent: Intent.SUCCESS,
          message: t('Note Reminder Removed'),
          icon: 'notifications',
        });
      },
      onSettled: invalidateQuery,
    }
  );

  const [remove, deleteNote] = useSendCommand<{}, { id: string }>(
    'mutation DeleteNote($id: String!) { deleteNote(id: $id) }',
    (evt, ctx) => {
      ctx.complete();
    },
    {
      onSuccess: () => {
        deleteItemOnPaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => n.id === id
        );
        notification.show({
          intent: Intent.SUCCESS,
          message: t('Note Removed'),
          icon: 'tick',
        });
      },
    }
  );
  const [pin, pinNote] = useSendCommand<{}, { id: string }>(
    'mutation PinNote($id: String!) { pinNote(id: $id) }',
    (evt, ctx) => {
      ctx.complete();
    },
    {
      onSuccess: () => {
        updatePaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => (n.id === id ? { ...n, pinned: true } : n)
        );
      },
      onSettled: invalidateQuery,
    }
  );
  const [unpin, unpinNote] = useSendCommand<{}, { id: string }>(
    'mutation UnpinNote($id: String!) { unpinNote(id: $id) }',
    (evt, ctx) => {
      ctx.complete();
    },
    {
      onSuccess: () => {
        updatePaginatedQueryData(
          queryClient,
          ['notes', p.uri],
          'notes',
          (n: Note) => (n.id === id ? { ...n, pinned: false } : n)
        );
      },
      onSettled: invalidateQuery,
    }
  );

  const editing = bodyEditing || reminderEditing;
  const handleStopEditing = useCallback(() => {
    if (bodyEditing) {
      setBodyEditing(false);
    } else if (reminderEditing) {
      setReminderEditing(false);
    }
  }, [bodyEditing, reminderEditing]);

  const handleRemoveReminder = useCallback(() => {
    unsetNoteReminder({ id });
    setReminderEditing(false);
  }, []);

  const handleStartEditingBody = useCallback(() => {
    setBodyValue(p.note.body);
    setBodyEditing(true);
  }, [p.note.body]);

  const handleStartEditingReminder = useCallback(() => {
    setReminderValue(p.note.reminder);
    setReminderEditing(true);
  }, [p.note.reminder]);

  const handleApplyEdits = useCallback(() => {
    if (bodyEditing) {
      setBody({ id, body: bodyValue });
      setBodyEditing(false);
    } else if (reminderEditing) {
      console.log(reminderValue);
      setReminderEditing(false);
      setNoteReminder({ id, reminder: reminderValue });
    }
  }, [bodyEditing, reminderEditing, bodyValue, reminderValue]);

  return (
    <>
      <div className={classNames('card p0', className, { editing })}>
        <div className="card-header">
          <div className="card-author">{p.note.author.name}</div>
          <div className="flex-grow" />
          {editing ? (
            <div className="card-header-primary-buttons">
              <Button small minimal icon="cross" onClick={handleStopEditing} />
              <Button
                small
                minimal
                intent={Intent.PRIMARY}
                icon="tick"
                onClick={handleApplyEdits}
              />
            </div>
          ) : (
            <>
              <div className="card-header-secondary-buttons">
                <Button
                  small
                  minimal
                  icon="edit"
                  onClick={handleStartEditingBody}
                />
                <Button small minimal icon="people" />
                <Button
                  small
                  minimal
                  icon="notifications"
                  onClick={handleStartEditingReminder}
                />
                <Button
                  small
                  minimal
                  loading={remove.isLoading}
                  icon="trash"
                  onClick={() => deleteNote({ id })}
                />
                {p.note.pinned !== true && (
                  <Button
                    small
                    loading={pin.isLoading}
                    minimal
                    icon="pin"
                    onClick={() => pinNote({ id })}
                  />
                )}
              </div>
              <div className="card-header-primary-buttons">
                {p.note.pinned === true && (
                  <Button
                    small
                    loading={unpin.isLoading}
                    intent={Intent.PRIMARY}
                    minimal
                    icon="pin"
                    onClick={() => unpinNote({ id })}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {bodyEditing ? (
          <EditableText
            disabled={body.isLoading}
            multiline
            value={bodyValue}
            onChange={setBodyValue}
          />
        ) : (
          <div className="fake-editable-content">{p.note.body}</div>
        )}
        {(reminder || reminderEditing) && (
          <div className={classNames('note-options', { editing })}>
            <Icon
              icon="notifications"
              intent={reminderEditing ? Intent.PRIMARY : Intent.NONE}
              iconSize={14}
            />
            <div>Remind me at</div>
            {!editing ? (
              <div className="reminder-date">
                {DateTime.fromISO(reminder.date).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </div>
            ) : (
              <>
                <div className="reminder-date">
                  <DateInput
                    displayFormat={DateTime.DATETIME_MED}
                    minimal={false}
                    onChange={(date) =>
                      setReminderValue((prev) => ({
                        ...(prev ?? { rate: SchedulerRate.None }),
                        date,
                      }))
                    }
                    value={reminderValue?.date}
                  />
                </div>
                <div className="flex-grow" />
                {reminder && (
                  <Button
                    icon="cross"
                    minimal
                    small
                    intent={Intent.DANGER}
                    onClick={handleRemoveReminder}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className={cardFooterClasseName}>
        <div className="flex-grow" />
        <NoteTimestamp note={p.note} />
      </div>
    </>
  );
}

function NoteRecurrencePopover() {
  return <div>hello</div>;
}

const floatingButton = css`
  position: absolute;
  bottom: 2px;
  right: 3px;
`;

const className = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  .card-author {
    font-weight: 500;
  }
  .card-author-time {
    font-weight: 400;
    margin-left: 4px;
    color: var(--text-tertiary-color);
  }
  .note-options {
    .bp4-icon {
      color: var(--text-tertiary-color);
      margin-right: 6px;
    }
    &.editing .bp4-icon {
      color: var(--primary-color);
    }
    font-size: 0.95em;
    :not(.editing) {
      color: var(--text-secondary-color);
    }
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-top: 1px solid var(--card-border-color);
    .reminder-date {
      margin-left: 6px;
      color: var(--text-color);
    }
    .bp4-input {
      border: none;
      box-shadow: none;
      height: 24px;
      line-height: 24px;
      padding: 0px;
      font-size: 0.95em;
    }
  }
  &.editing .card-header {
    background: var(--primary-color) !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: white;
    .bp4-button.bp4-intent-primary {
      color: white;
    }
  }

  .card-header {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    min-height: 30px;
    transition: background 0.1s ease-in;
  }
  :hover {
    .card-header-secondary-buttons {
      opacity: 1;
    }
  }
  .card-header-secondary-buttons {
    opacity: 0;
    .bp4-button .bp4-icon {
      color: var(--text-tertiary-color);
    }
  }
  &.isNew {
    border-style: dashed;
  }
  position: relative;
  flex-direction: column;
  .bp4-editable-text {
    margin: 2px;
  }
  .bp4-editable-text-content,
  .bp4-editable-text-input,
  .fake-editable-content {
    padding: 8px;
  }
  .fake-editable-content {
    margin: 2px;
    word-wrap: break;
  }
`;

const cardFooterClasseName = css`
  margin-bottom: 16px;
  margin-top: 3px;
  margin-left: var(--card-margin);
  margin-right: var(--card-margin);
  display: flex;
  .note-timestamp {
    font-style: italic;
    font-size: 0.95em;
    color: var(--text-secondary-color);
  }
`;
