import { isObject } from 'formik';
import type { FormikState, FormikConfig } from 'formik';
import type { Loadable } from 'src/components/EditableField';
import { serialPromises } from 'src/utils/promise';
import {
  difference,
  get,
  keyBy,
  isEmpty,
  flatten,
  compact,
  omit,
  some,
  isArray,
} from 'lodash';
import sanitizeTypename from './sanitizeTypename';
import { WAIT_FOR_EVENT } from './diffV2';
import { GraphQLFetch } from './graphql';

const MUTATIONS = {
  ADD_PERSON:
    'mutation AddPerson($id: String, $person: PersonBasicInfo) { addPerson(id: $id, person: $person) }',
  UPDATE_PERSON:
    'mutation UpdatePerson($id: String!, $person: PersonBasicInfo) { updatePerson(id: $id, person: $person) }',
  REMOVE_PERSON:
    'mutation RemovePerson($id: String!) { removePerson(id: $id) }',
  ADD_COMPANY:
    'mutation AddCompany($id: String, $company: CompanyBasicInfo) { addCompany(id: $id, company: $company) }',
  UPDATE_COMPANY:
    'mutation UpdateCompany($id: String!, $company: CompanyBasicInfo) { updateCompany(id: $id, company: $company) }',
  REMOVE_COMPANY:
    'mutation RemoveCompany($id: String!) { removeCompany(id: $id) }',
  ADD_EMAIL_ADDRESS:
    'mutation AddEmailAddress($parentId: String!, $parentKind: String!, $id: String!, $emailAddress: String!) { addEmailAddress(parentId: $parentId, parentKind: $parentKind, id: $id, emailAddress: $emailAddress) }',
  UPDATE_EMAIL_ADDRESS:
    'mutation UpdateEmailAddress($parentId: String!, $parentKind: String!, $id: String!, $emailAddress: String!) { updateEmailAddress(parentId: $parentId, parentKind: $parentKind, id: $id, emailAddress: $emailAddress) }',
  REMOVE_EMAIL_ADDRESS:
    'mutation removeEmailAddress($parentId: String!, $parentKind: String!, $id: String! ) { removeEmailAddress(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  ADD_LOCATION:
    'mutation AddLocation($parentId: String!, $parentKind: String!, $id: String!, $location: LocationInput!) { addLocation(parentId: $parentId, parentKind: $parentKind, id: $id, location: $location) }',
  UPDATE_LOCATION:
    'mutation UpdateLocation($parentId: String!, $parentKind: String!, $id: String!, $location: LocationInput!) { updateLocation(parentId: $parentId, parentKind: $parentKind, id: $id, location: $location) }',
  REMOVE_LOCATION:
    'mutation removeLocation($parentId: String!, $parentKind: String!, $id: String! ) { removeLocation(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  ADD_PHONE_NUMBER:
    'mutation AddPhoneNumber($parentId: String!, $parentKind: String!, $id: String!, $countryCode: String, $number: String!) { addPhoneNumber(parentId: $parentId, parentKind: $parentKind, id: $id, countryCode: $countryCode, number: $number) }',
  UPDATE_PHONE_NUMBER:
    'mutation UpdatePhoneNumber($parentId: String!, $parentKind: String!, $id: String!, $countryCode: String, $number: String!) { updatePhoneNumber(parentId: $parentId, parentKind: $parentKind, id: $id, countryCode: $countryCode, number: $number) }',
  REMOVE_PHONE_NUMBER:
    'mutation removePhoneNumber($parentId: String!, $parentKind: String!, $id: String! ) { removePhoneNumber(parentId: $parentId, parentKind: $parentKind, id: $id) }',
  ADD_EMPLOYEE:
    'mutation AddCompanyEmployee($parentId: String!,  $id: String!) { addCompanyEmployee(id: $parentId, personId: $id) }',
  REMOVE_EMPLOYEE:
    'mutation RemoveCompanyEmployee($parentId: String!,  $id: String!) { removeCompanyEmployee(id: $parentId, personId: $id) }',
};

interface DiffStatus<T extends object> {
  command: string;
  data: Item | T;
}

export enum DiffActionKind {
  ADD,
  REMOVE,
  MOVE,
}

export interface DiffAction {
  kind: DiffActionKind;
}

export interface RemoveDiffAction extends DiffAction {
  kind: DiffActionKind.REMOVE;
  id: string;
}

export interface AddDiffAction<T extends object> extends DiffAction {
  kind: DiffActionKind.ADD;
  data: T | T[];
  index?: number;
}

export interface MoveDiffAction extends DiffAction {
  kind: DiffActionKind.MOVE;
  id: string;
  after: string | null;
}

export type DiffActions<T extends object> =
  | RemoveDiffAction
  | AddDiffAction<T>
  | MoveDiffAction;

export interface MoveStatus {
  from: string;
  to: string | null;
}

type Form<T> = FormikState<T> & Pick<FormikConfig<T>, 'initialValues'>;
type Item = { id: string };

interface Config {
  parentId: string;
  parentKind: string;
  updateCommand: string;
  ignoreTouches?: boolean;
  fields?: { name: string; suffix: string; key?: string }[];
  subfields?: { name: string; suffix: string; key?: string }[];
}

export async function applyChanges<T extends object>(
  client: GraphQLFetch<unknown>,
  changes: DiffStatus<T>[],
  mutations: { [key: string]: string } = MUTATIONS
) {
  const promises = compact(
    changes.map((val) => {
      // IF wait for event.
      if (val.command === WAIT_FOR_EVENT && 'subscribe' in val.data) {
        return (prev?: string) => {
          const { context, subscribe } = val.data as any;
          return subscribe(prev, context);
        };
      }

      const mutation = mutations[val.command];
      if (mutation != null) {
        // const { id, ...other } = val.data as any;
        // const data = other.parentId != null ? val.data : { id, [kind]: other };
        return () =>
          client(mutation, sanitizeTypename(val.data)).then((data) => {
            const dataKeys = Object.keys(data);
            return data[dataKeys[0]] as string;
          });
      }
    })
  );
  const [result] = serialPromises(promises);
  return result;
}

function isTruthy(val: any): boolean {
  if (isObject(val)) {
    return some(Object.keys(val), (k) => isTruthy(val[k]));
  } else {
    return val === true;
  }
}

export function getFieldChanges<T extends Item>(
  form: Form<Loadable<T>>,
  config: Config
): Array<DiffStatus<T>> {
  if ('_loading' in form.values) {
    return null;
  }
  const { id, ...data } = form.values;
  const allChanges =
    config.ignoreTouches ?? false
      ? data
      : Object.keys(form.touched)
        .filter((k) => isTruthy(form.touched[k]))
        .reduce((prev, next) => {
          return { ...prev, [next]: data[next] };
        }, {});

  // If fields are defined, we have to check those.
  if (config.fields) {
    // For each field, we have to return a set command.
    return config.fields
      ?.filter((f) => f.name in allChanges)
      .map((f) => ({
        command: `SET_${f.suffix}`,
        data: { id, [f.key ?? f.name]: allChanges[f.name] },
      }));
  } else {
    // Otherwise let's use all the changed except the subfileds, if defined.
    const changes = omit(
      allChanges,
      config.subfields?.map((d) => d.name) ?? []
    );

    // And return a single update command.
    return [
      {
        command: config.updateCommand,
        data: { ...changes, id },
      },
    ];
  }
}

function shouldEmbedCommand(command: string) {
  return command.startsWith('REMOVE_') || command.startsWith('MOVE_');
}

export function getFormChangeCommands<T extends Item>(
  form: Form<Loadable<T>>,
  config: Config
): DiffStatus<{}>[] {
  if ('_loading' in form.values) {
    return null;
  }
  const fields = getFieldChanges(form, config);
  const subfields = flatten(
    config.subfields?.map(({ name, suffix, key }) =>
      getFieldArrayChanges(form, name, suffix).map((d) => {
        const shouldEmbed = !shouldEmbedCommand(d.command) && key != null;
        const data = shouldEmbed ? { [key]: d.data } : d.data;
        return {
          ...d,
          data: {
            ...data,
            parentId: config.parentId,
            parentKind: config.parentKind,
          },
        };
      })
    )
  );
  const changes = compact(flatten([...fields, ...subfields]));
  return !isEmpty(changes) ? changes : null;
}

export function getFieldArrayChanges<T extends Item>(
  form: Form<Loadable<T>>,
  name: string,
  suffix: string
): DiffStatus<T>[] {
  if ('_loading' in form.values || '_loading' in form.initialValues) {
    return null;
  }
  const prev = get(form.initialValues, name) ?? [];
  const next = get(form.values, name) ?? [];

  const prevIds = prev.map((p: { id: string }) => p.id);
  const nextIds = next.map((p: { id: string }) => p.id);
  const nextById = keyBy(next, 'id');

  const removedIds = difference(prevIds, nextIds);
  const addedIds = difference(nextIds, prevIds);

  const moves: MoveStatus[] = form.values[`${name}_moves`] || [];
  console.log(moves);

  // Now lets get the changed ids, but lets not consider the added ones.
  const touchedValues = ((get(form.touched, name) as any) ?? []).filter(
    (f: any) => f != null
  );
  const touchedIds = Object.keys(touchedValues).map((k) => next[k].id);
  const updatedIds = difference(touchedIds, addedIds);

  return [
    ...updatedIds.map<DiffStatus<T>>((id: string) => ({
      command: `UPDATE_${suffix}`,
      data: { ...nextById[id], id },
    })),
    ...removedIds.map<DiffStatus<T>>((id: string) => ({
      command: `REMOVE_${suffix}`,
      data: { id },
    })),
    ...addedIds.map<DiffStatus<T>>((id: string) => ({
      command: `ADD_${suffix}`,
      data: { ...nextById[id], id },
    })),
    ...moves.map(({ from, to }) => ({
      command: `MOVE_${suffix}`,
      data: { id: from, after: to },
    })),
  ];
}
