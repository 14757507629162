import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import css from 'csz';
import classNames from 'classnames';
import { strings } from 'src/utils/strings';
import { useField, useFormikContext } from 'formik';
import { Spinner, Intent, Icon, Button } from '@blueprintjs/core';
import { useUploader } from 'src/utils/useUploader';
import { SMALL } from '@blueprintjs/core/lib/esm/common/classes';
import { isEmpty } from 'lodash';
const containerClassName = css`/styles/dropzone.css`;

interface P {
  className?: string;
  uploading?: boolean;
  uploadProgress?: number;
  children?: JSX.Element;
  onUploadFiles: (files: File[]) => void;
}

export default function FileDropzone(p: P) {
  const { t } = useTranslation();
  const { uploading } = p;
  const ghost = p.children != null;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive: active,
    isDragAccept: accept,
    isDragReject: reject,
  } = useDropzone({
    noClick: ghost,
    disabled: p.uploading,
  });
  const className = classNames(
    'dropzone',
    { ghost },
    { uploading },
    { active },
    { accept },
    { reject }
  );
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      p.onUploadFiles(acceptedFiles);
    }
  }, [acceptedFiles]);
  const disabled = uploading;
  const {
    onBlur,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    ...otherProps
  } = getRootProps({ className });
  const handlers = {
    onBlur,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
  };
  const childrenProps = ghost ? handlers : {};
  const contentProps = !ghost ? handlers : {};
  return (
    <div className={classNames(containerClassName, p.className)}>
      <div
        {...childrenProps}
        className={classNames('children', { active: accept, uploading })}
      >
        {p.children}
      </div>
      <div {...otherProps} {...contentProps}>
        <input {...getInputProps({ disabled: disabled })} />
        <div className="body">
          <div className="flex-grow" />
          {p.uploading && (
            <Spinner
              intent={Intent.PRIMARY}
              size={30}
              value={p.uploadProgress}
            />
          )}
          <p>
            {strings(
              { [t('File Dropzone Uploading')]: uploading },
              { [t('File Dropzone Reject')]: reject },
              { [t('File Dropzone Active')]: active },
              { [t('File Dropzone Accept')]: accept },
              t('File Dropzone Placeholder')
            )}
          </p>
          <div className="flex-grow" />
        </div>
      </div>
    </div>
  );
}

export type UploadedFile = { name: string; url: string };

export function UploadFileDropzoneField(p: {
  name: string;
  className?: string;
  successMessage?: string;
  onUpload?: (uploads: UploadedFile[]) => void;
  multiple?: boolean;
}) {
  const upload = useUploader();
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [uploading, setUploading] = useState(false);
  const [field, _, meta] = useField(p.name);

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      setUploading(true);
      const firstFile = files[0];
      const value = await (p.multiple
        ? Promise.all(files.map((f) => upload(f)))
        : upload(firstFile, setUploadProgress));

      // Set the urls.
      meta.setValue(Array.isArray(value) ? value.map((p) => p.url) : value.url);

      if (p.onUpload != null) {
        p.onUpload(Array.isArray(value) ? value : [value]);
      }

      setUploading(false);
    },
    [p.name, p.multiple, p.onUpload]
  );

  if (!isEmpty(field.value)) {
    const successMessage = p.successMessage ?? t('Document Uploaded');
    return (
      <div className="bg-gray-100 rounded px-2 py-1 flex items-center font-semibold shadow-sm">
        <Icon icon="tick" className="text-primary" />
        <div className="px-2 flex-grow text-gray-600">{successMessage}</div>
        <Button icon="cross" minimal onClick={() => meta.setValue(null)} />
      </div>
    );
  }

  return (
    <FileDropzone
      className={p.className}
      onUploadFiles={handleUploadFiles}
      uploading={uploading}
      uploadProgress={uploadProgress}
    />
  );
}
