import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  EditableText,
  Expander,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { LibraryBlock, LibraryBlockArgument } from '@keix/workflow-types';
import { BlockNode } from './BlockNode';
import { BlockIcon, BlockNodeIcon } from './BlockIcon';
import { Accordion } from 'src/components/Accordion';
import Section from 'src/components/Section';
import { FieldRow } from 'src/components/EditableField';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import {
  EditableTextField,
  HTMLSelectField,
  SelectField,
  SelectOption,
  SwitchField,
} from 'src/components/BlueprintFields';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { useFieldArray } from 'src/utils/formik';
import { Tooltip2 } from '@blueprintjs/popover2';
import MonacoEditor from 'src/components/MonacoEditor';

export function useDataTypes(): [SelectOption[], SelectOption] {
  const { dataTypes: dataTypesByName } = useLibraryContext();
  const dataTypes = useMemo(
    () => Object.values(dataTypesByName),
    [dataTypesByName]
  );
  const options = useMemo(
    () =>
      dataTypes.map((d) => ({
        icon: d.icon,
        value: d.uri,
        label: d.name,
      })),
    [dataTypes]
  );
  const multipleDataTypeOption: SelectOption = {
    icon: 'multi-select',
    label: 'Multiple Data Type',
    value: '',
  };
  return [options, multipleDataTypeOption];
}

export function BlockEditor(p: { block: LibraryBlock | null }) {
  const { block } = p;
  const [advanced, setAdvanced] = useState(false);
  const form = useFormik({
    initialValues: block,
    enableReinitialize: true,
    onSubmit: () => null,
  });

  if (form.values == null) {
    return (
      <div className="w-2/3">
        <NonIdealState
          icon="code-block"
          title="No Block Selected"
          description="Select a block from the library to open the editor"
        />
      </div>
    );
  }

  return (
    <FormikProvider value={form}>
      <div className="w-2/3 flex flex-col overflow-scroll">
        <div className="flex border-b sticky top-0 left-0 right-0 z-10 items-center bg-white shadow-md py-2 px-3">
          <BlockIcon {...p.block.style} />
          <div className="px-2 font-semibold">{p.block.name}</div>
          <Expander />
          {advanced ? (
            <Button
              icon="console"
              text="Visuale"
              small
              minimal
              onClick={() => setAdvanced(false)}
            />
          ) : (
            <Button
              icon="code"
              text="Avanzato"
              small
              minimal
              onClick={() => setAdvanced(true)}
            />
          )}
        </div>
        {advanced ? (
          <MonacoEditor
            value={JSON.stringify(form.values, null, '\t')}
            language="json"
            onChange={(v) => {
              try {
                form.setValues(JSON.parse(v));
              } catch { }
            }}
          />
        ) : (
          <div className="flex-grow flex flex-col px-2 py-3 gap-y-3 ">
            <BasicInfo />
            <Arguments />
          </div>
        )}
      </div>
    </FormikProvider>
  );
}

function BasicInfo() {
  const [options, unknownOption] = useDataTypes();
  const form = useFormikContext<LibraryBlock>();

  return (
    <div className="bg-white border-t-0 border rounded-md shadow-md overflow-hidden">
      <Accordion initiallyOpened={true} title="Block Defintion" kind="section">
        <div className="flex flex-col divide-y">
          <FieldRow label="Name">
            <EditableTextField name="name" />
          </FieldRow>
          <FieldRow label="Uri">
            <EditableTextField name="uri" />
          </FieldRow>
          <FieldRow label="Icon">
            <div className="flex gap-x-2 items-center">
              <EditableTextField name="style.icon" />
              <HTMLSelectField minimal name="style.bg.color">
                {['red', 'blue', 'green', 'yellow'].map((c) => (
                  <option value={c}>{c}</option>
                ))}
              </HTMLSelectField>
              <HTMLSelectField minimal name="style.bg.opacity">
                {[200, 300, 400, 500, 600, 700, 800].map((c) => (
                  <option value={c}>{c}</option>
                ))}
              </HTMLSelectField>
              <BlockIcon {...form.values.style} />
            </div>
          </FieldRow>
          <FieldRow label="Block Type">
            <HTMLSelectField minimal name="functionType">
              <option value="static">Static</option>
              <option value="function">Function</option>
              <option value="attribute">Attribute</option>
            </HTMLSelectField>
          </FieldRow>
          <FieldRow label="Asynchronous">
            <SwitchField name="async" />
          </FieldRow>
          <FieldRow label="No-op">
            <SwitchField name="noop" />
          </FieldRow>
          <FieldRow label="Return Type">
            <SelectField
              unknownOption={unknownOption}
              minimal
              name="returnType"
              options={options}
            />
          </FieldRow>
        </div>
      </Accordion>
    </div>
  );
}

function Arguments() {
  const form = useFormikContext<LibraryBlock>();
  const [_, meta, { push, remove }] =
    useFieldArray<LibraryBlockArgument>('arguments');
  const [options, unknownOption] = useDataTypes();

  return (
    <div className="bg-white border-t-0 border rounded-md shadow-md overflow-hidden">
      <Accordion initiallyOpened={true} title="Block Inputs" kind="section">
        <div className="flex flex-col divide-y">
          {form.values.arguments?.map((arg, index) => {
            const isPort = arg.isPort ?? false;
            return (
              <div className="flex px-3">
                <Tooltip2
                  className="self-center"
                  position="left"
                  content={isPort ? 'An input port' : 'A block argument'}
                >
                  <Button
                    minimal
                    icon={isPort ? 'inheritance' : 'new-text-box'}
                    onClick={() =>
                      form.setFieldValue(`arguments.${index}.isPort`, !isPort)
                    }
                  />
                </Tooltip2>
                <div className="flex flex-grow flex-col py-1 px-1">
                  <EditableTextField
                    className="font-semibold"
                    name={`arguments.${index}.name`}
                  />
                  <EditableTextField
                    multiline={true}
                    placeholder="Type a description..."
                    className="text-gray-500 text-xs"
                    name={`arguments.${index}.description`}
                  />
                </div>
                <SelectField
                  minimal
                  className="self-center"
                  name={`arguments.${index}.type`}
                  options={options}
                  unknownOption={unknownOption}
                />
                <Button
                  icon="cross"
                  className="self-center"
                  onClick={() => remove(index)}
                  intent={Intent.DANGER}
                  minimal
                />
              </div>
            );
          })}
        </div>
        <div className="p-2 border-t">
          <Button
            icon="plus"
            minimal
            fill
            text="Add Argument"
            onClick={() =>
              push({
                name: '',
                type: '',
              })
            }
            intent={Intent.PRIMARY}
          />
        </div>
      </Accordion>
    </div>
  );
}
