import React from 'react';
import css from 'csz';
import { NonIdealState, Classes, Icon, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default class AppErrorBoundary extends React.Component<
  {},
  { hasError: boolean; errorMessage: string; stack: string }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      // checks if an error has occured in its children.
      hasError: false,
      errorMessage: '',
      stack: '',
    };
  }
  componentDidCatch(err: Error, info: {}) {
    // set the the hasError state to true so on the next render it will display the `<div>Error occured.</div>` in the DOM.
    this.setState({
      hasError: true,
      errorMessage: err.message,
      stack: err.stack,
    });
  }
  render() {
    if (this.state.hasError) {
      // if the hasError state boolean is true, it returns this to tell the user an error has occurred
      return <ErrorMessage {...this.state} />;
    } else {
      // if there is no error the children components are returned so there are rendered.
      return this.props.children;
    }
  }
}

function ErrorMessage(p: { errorMessage?: string; stack?: string }) {
  const { t } = useTranslation();
  return (
    <div className={classNames(className, Classes.INTENT_DANGER)}>
      <div className="error-container card">
        <NonIdealState
          icon={<Icon icon="warning-sign" size={60} intent={Intent.WARNING} />}
          title={t('Something Went Wrong')}
          description={
            <div>
              {t(
                'There was an error, the issue has been already recorded and sent to our support team.'
              )}
            </div>
          }
        />
        <p className="bold text-yellow-600">{p.errorMessage}</p>
        <div className="tertiary-text">
          <pre>{p.stack}</pre>
        </div>
      </div>
    </div>
  );
}

const className = css`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  > div.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--card-bg-color);
    p {
      margin-top: 12px;
    }
  }
`;
