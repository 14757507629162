import { LibraryBlock } from '@keix/workflow-types';

export const library: LibraryBlock[] = [
  {
    name: 'load_survey',
    uri: 'keix.load_survey',
    arguments: [
      {
        name: 'in',
        type: 'python.string',
        isPort: false,
      },
    ],
    code: `
import json
import js
import pandas as pd
from io import StringIO
from pyodide import to_js
from js import Object

async def load_survey(_in):
    print("Sending req...")
    idToken = js.window.idToken
    if idToken is None:
      raise Exception("Not Authorized")
    resp = await js.fetch('https://dev.graphql.keix.com/graphql',
      method="POST",
      body=json.dumps({ "query" : "query DumpSurvey($id: String!) { dumpSurveyData(id: $id) }", "variables": { "id": _in }}),
      credentials="same-origin",
      headers=Object.fromEntries(to_js({ "Content-Type": "application/json", "Authorization": "Bearer " + idToken })),
    )
    response_data = await resp.json()
    print(response_data)
    text = StringIO(response_data.data.dumpSurveyData)
    return pd.read_csv(text)
    `,
    returnType: 'pandas.dataframe',
    async: true,
    style: {
      icon: 'form',
      bg: {
        color: 'blue',
        opacity: 500,
      },
    },
  },

  {
    name: 'value_counts',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'percentage',
    },
    functionType: 'method',
    uri: 'pandas.value_counts',
    docsUrl:
      'https://pandas.pydata.org/docs/reference/api/pandas.DataFrame.value_counts.html',
    returnType: 'pandas.series',
    arguments: [
      {
        name: 'self',
        type: 'pandas.dataframe',
        isPort: true,
      },
      {
        name: 'subset',
        type: ['python.string', 'python.string_array'],
        description: 'Columns to use when counting unique combinations.',
      },
      {
        name: 'sort',
        type: 'python.bool',
        defaultValue: true,
        description: 'Sort by frequencies.',
      },
      {
        name: 'ascending',
        type: 'python.bool',
        defaultValue: false,
        description: 'Sort in ascending order.',
      },
      {
        name: 'normalize',
        type: 'python.bool',
        defaultValue: true,
        description: 'Return proportions rather than frequencies.',
      },
      {
        name: 'dropna',
        type: 'python.bool',
        defaultValue: true,
        description: 'Don’t include counts of rows that contain NA values.',
      },
    ],
    code: '',
  },
  {
    name: 'query',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'moon',
    },
    uri: 'pandas.query',
    returnType: 'pandas.dataframe',
    functionType: 'method',
    arguments: [
      {
        name: 'self',
        type: 'pandas.dataframe',
        isPort: true,
      },
      { name: 'expr', type: 'python.string' },
      { name: 'inplace', type: 'python.bool', defaultValue: false },
    ],
    code: ``,
  },
  {
    name: 'get_columns',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'column-layout',
    },
    uri: 'pandas.get_columns',
    returnType: 'pandas.dataframe',
    arguments: [
      {
        name: 'in',
        type: 'pandas.dataframe',
        isPort: true,
      },
      { name: 'columns', type: ['python.string', 'python.string_array'] },
    ],
    code: `
def get_columns(_in, columns):
  return _in[columns]    
    `,
  },
  {
    name: 'reset_index',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'remove-column-left',
    },
    uri: 'pandas.reset_index',
    functionType: 'method',
    returnType: 'pandas.dataframe',
    arguments: [
      {
        name: 'self',
        type: 'pandas.dataframe',
        isPort: true,
      },
    ],
    code: '',
  },
  {
    name: 'cumsum',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'small-plus',
    },
    uri: 'pandas.cumsum',
    functionType: 'method',
    returnType: 'pandas.dataframe',
    arguments: [
      {
        name: 'self',
        type: 'pandas.dataframe',
        isPort: true,
      },
    ],
    code: '',
  },
  {
    name: 'sort_values',
    style: {
      bg: { color: 'red', opacity: 500 },
      icon: 'sort',
    },
    uri: 'pandas.sort_values',
    functionType: 'method',
    returnType: 'pandas.dataframe',
    arguments: [
      {
        name: 'self',
        type: 'pandas.dataframe',
        isPort: true,
      },
      {
        name: 'by',
        type: ['python.string', 'python.string_array'],
        isPort: false,
      },
      {
        name: 'ascending',
        type: 'python.bool',
        isPort: false,
      },
      {
        name: 'ignore_index',
        type: 'python.bool',
        isPort: false,
      },
    ],
    code: '',
  },
];

export default library;
