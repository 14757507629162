import { useQuery } from '@tanstack/react-query';
import { Query } from 'src/apps/athena/gql-types';
import { useGraphQLFetch } from 'src/utils/graphql';

export const MUTATIONS = {
  ADD_SURVEY_QUESTION:
    'mutation AddSurveyQuestion($surveyId: String!, $index: Int, $questions: [SurveyQuestionInput]!) { addSurveyQuestions(id: $surveyId, index: $index, questions: $questions) }',
  REMOVE_SURVEY_QUESTION:
    'mutation RemoveSurveyQuestion($surveyId: String!, $id: String!) { removeSurveyQuestion(id: $surveyId, questionId: $id) }',
  MOVE_SURVEY_QUESTION:
    'mutation MoveSurveyQuestion($surveyId: String!, $id: String!, $after: String) { moveSurveyQuestion(id: $surveyId, questionId: $id, after: $after) }',
  UPDATE_SURVEY_QUESTION:
    'mutation UpdateSurveyQuestion($surveyId: String!, $questions: [SurveyQuestionInput]!) { updateSurveyQuestions(id: $surveyId, questions: $questions) }',
  SET_SURVEY_AS_TEMPLATE:
    'mutation SetSurveyAsTemplate($id: String! $category:SurveyCategoryTemplateInput!) { setSurveyAsTemplate(id: $id, category: $category) }',
  SET_QUESTION_TEXT_TO_TEMPLATE_KEYWORD:
    'mutation SetQuestionTextToTemplateKeyword($id: String! $data:SurveyTemplateKeywordInput!) { setQuestionTextToTemplateKeyword(id: $id, data:$data) }',

  ADD_FILTER:
    'mutation AddFilter($surveyId: String!, $record: FilterInput!) { addFilter(surveyId: $surveyId, filter: $record) }',
  UPDATE_FILTER:
    'mutation AddFilter($surveyId: String!, $record: FilterInput!) { updateFilter(surveyId: $surveyId, filter: $record) }',
  REMOVE_FILTER:
    'mutation RemoveFilter($id: String!, $surveyId: String!) { removeFilter(surveyId: $surveyId, id: $id) }',

  SET_SURVEY_LANGUAGES:
    'mutation SetLanguages($id: String!, $languages: [String]) { setSurveyLanguages(id: $id, languages: $languages) }',
  SET_SURVEY_STATE:
    'mutation setSurveyState($id: String!, $state:SurveyState!) {setSurveyState(id:$id,state:$state)}',
  SET_SURVEY_LABELS:
    'mutation SetLabels($id: String!, $labels: SurveyLabelsInput!) { setSurveyLabels(id: $id, labels: $labels) }',
  SET_SURVEY_TARGET:
    'mutation SetTarget($id: String!, $amount: Int!, $deadlineDate: String) { setSurveyTarget(id: $id, amount: $amount, deadlineDate: $deadlineDate) }',
  SET_SURVEY_STYLE:
    'mutation SetStyle($id: String!, $style: SurveyStyleInput!) { setSurveyStyle(id: $id, style: $style) }',

  SET_SURVEY_QUOTA:
    'mutation SetQuota($surveyId: String!, $record: SurveyQuotaInput!) { setSurveyQuota(id: $surveyId, quota: $record) }',
  REMOVE_SURVEY_QUOTA:
    'mutation RemoveQuota($surveyId: String!, $id: String!) { removeSurveyQuota(id: $surveyId, quotaId: $id) }',

  SET_SURVEY_FREQUENCY:
    'mutation SetFrequency($surveyId: String!, $record: SurveyFrequencyInput!) { setSurveyFrequency(id: $surveyId, frequency: $record) }',
  REMOVE_SURVEY_FREQUENCY:
    'mutation RemoveFrequency($surveyId: String!, $id: String!) { removeSurveyFrequency(id: $surveyId, frequencyId: $id) }',

  ANSWER_QUESTION:
    'mutation AnswerQuestion($id: String!, $questionId: String!, $sessionId: String, $answer: JSON, $optionId: JSON) { answerQuestion(questionId: $questionId, id: $id, sessionId: $sessionId, answer: $answer, optionId: $optionId) }',

  SHARE_SURVEY:
    'mutation ShareSurvey($share: SurveyShareInput) { shareSurvey(share: $share) }',
  CHANGE_SURVEY_SHARE_QUOTE:
    'mutation SetSurveyShareQuota($id: String!, $quota: Int!) { changeSurveyShareQuota(id: $id, quota: $quota) }',

  DISABLE_SHARE_SURVEY:
    'mutation DisableSurveySurvey($id: String!,$message: String) { disableSurveyShare(id: $id,message:$message) }',
  ENABLE_SHARE_SURVEY:
    'mutation EnableSurveySurvey($id: String!) { enableSurveyShare(id: $id) }',
  REMOVE_SHARE_SURVEY:
    'mutation RemoveSurveySurvey($id: String!) { removeSurveyShare(id: $id) }',

  START_SURVEY_SESSION:
    'mutation StartSession($session: SurveySessionInput!) { startSurveySession(session: $session) }',

  SEND_INVITE_LINK:
    'mutation SendInviteLink($inviteToSend: SendInviteInput!) { sendInviteLink(inviteToSend: $inviteToSend) { result errorMessage } }',

  CREATE_SURVEY_CATEGORY:
    'mutation CreateSurveyCategory($category: SurveyCategoryInput!) { createSurveyCategory(category: $category) }',

  CREATE_WIZARD_SUBSCRIPTION:
    'mutation CreateWizardSubscription($data: WizardSubscriptionInput!) { createWizardSubscription(data: $data) }',
  CREATE_SURVEY_WIZARD:
    'mutation CreateSurveyWizard($title: String!,$surveyTemplateId: String!, $category:SurveyCategoryInput, $keys:[SurveyWizardKeyInput]!,$userId:String!) {createSurveyWizard(title: $title, surveyTemplateId: $surveyTemplateId,category:$category,keys:$keys,userId:$userId)}',
  CREATE_SURVEY:
    'mutation CreateSurvey($survey: SurveyInput!) { createSurvey(survey: $survey) }',
};

export const FRAGMENTS = {
  QUESTION:
    'fragment questionData on Question { id body description language availableLanguages type rows { id body } settings { labels { otherOption lowScale undefinedScaleOption mediumScale highScale } allowMultipleChoices enableUndefinedOption randomizeOptions enableOtherOption enableDropdown optional scaleSize scaleKind } options { id body } }',
  SURVEY_QUESTION:
    'fragment questionData on SurveyQuestion { id body questionId description language availableLanguages variables rows { id body }  type settings { labels { otherOption lowScale undefinedScaleOption mediumScale highScale } allowMultipleChoices enableUndefinedOption randomizeOptions enableOtherOption enableDropdown optional scaleSize scaleKind } options { id body } }',
};

export const QUERY = {
  TELEGRAM_DATA:
    'query TelegramData($id: String!) { telegramData(id: $id) { id telegram { chatId } } }',
  SEARCH_SURVEY:
    'query SuggestSurvey($queryString: String!) { surveysSuggester(query: $queryString) { id name } }',
  RETRIEVE_SURVEY:
    'query Surveys($filters: JSON, $cursor: JSON) { surveys(filters: $filters, cursor: $cursor) { pageInfo { total endCursor } items { id state name target { amount } metrics { opens closes dropout screenout avgDuration minDuration maxDuration stdDuration } application } } }',
  RETRIEVE_SURVEY_DATA:
    'query SurveyData($surveyId: String!, $limit: Int, $orderBy: JSON) { surveyData(surveyId: $surveyId, limit: $limit, orderBy: $orderBy) { items { id startedAt endedAt data } } }',
  DUMP_SURVEY:
    'query DumpSurvey($surveyId: String!) { dumpSurveyData(id: $surveyId) }',
  GET_SURVEY: `query Survey($id: String!) { survey(id: $id) { id name revision languages labels { title introMessage startButton thanksMessage } style { introImage logoImage hideTelegram tintColor } target { amount deadlineDate } metrics { opens closes dropout screenout avgDuration stdDuration minDuration maxDuration } frequencies { id questions } questions { id questionId variables } quotas { id questionId options { optionId amount } } filters { id conditions effects { id type target } } application } }`,
  GET_SURVEY_CLUSTER: `query SurveyCluster($id: String!) { surveyCluster(id: $id) { id surveyId clusterQuotas { quotas { quotaId optionId questionId } target amount completed } } }`,
  GET_SURVEY_QUESTIONS: `${FRAGMENTS.QUESTION} query GetSurveyQuestion($ids: [String]!) { getQuestions(ids: $ids) { id body parentId description numberOfVariants translations type ...questionData } }`,
  GET_SURVEY_FREQUENCIES:
    'query GetSurveyFrequencies($id: String!) { surveyFrequencies(id: $id) { id frequencies { id questionIds options { id optionIds amount percentage } } } }',
  GET_SURVEY_SHARE:
    'query SurveyShare($id: String!) { surveyShare(id: $id) { id enabled pinCode target { id name } expirationDate surveyId quota metrics { opens closes } } }',
  GET_SURVEY_SHARE_DETAIL:
    'query SurveyShare($id: String!) { surveyShare(id: $id) { id surveyId enabled sender { id name } target { id name } medium numberOfDevices  metrics { opens durations closes dropout screenout avgDuration stdDuration minDuration maxDuration } type expirationDate quota url sentAt } }',
  GET_INVITE_URL:
    'query GetInviteUrl($invite: InviteInput!) { getInviteUrl(invite: $invite) { url } }',
  GET_QUESTION: `${FRAGMENTS.QUESTION} query GetQuestio($id: String!) { question(id: $id) { id body parentId description numberOfVariants translations type ...questionData } }`,
  RETRIEVE_QUESTIONS: `${FRAGMENTS.QUESTION} query RetrieveQuestions($query: String, $filters: JSON) { questions(queryString: $query, filters: $filters) { items { id body parentId description numberOfVariants translations type ...questionData } } }`,
  RETRIEVE_QUESTION_VARIANTS:
    'query RetrieveQuestionsVariants($parentId: String!, $query: String) { questionVariants(parentId: $parentId, queryString: $query) { items { id body description numberOfVariants options { id body } } } }',
  RETRIEVE_SHARES:
    'query SurveyShare($surveyId: String!, $orderBy: JSON) { surveyShares(surveyId: $surveyId, orderBy: $orderBy) { items { id enabled sender { id name } target { id name city province coordinates {lat lon} } medium numberOfDevices  metrics { opens durations closes dropout screenout avgDuration stdDuration minDuration maxDuration } type expirationDate quota url sentAt } pageInfo { endCursor total } } }',
  GET_QUESTION_PRESENTATION: `${FRAGMENTS.QUESTION} query GetQuestionPresentation($id: String!) { questionPresentation(id: $id) { id ...questionData } }`,
  GET_TEMPLATE_KEYWORDS:
    'query SurveyTemplateKeywords($surveyTemplateId: String!) { surveyTemplateKeywords(surveyTemplateId: $surveyTemplateId) { items { id surveyTemplateId keyword questionText options } } }',
  GET_SURVEY_CATEGORY: `query SurveyCategory($id: String!) { surveyCategory(id: $id) { id name subCategories { id name } options { relativePopulation } } }`,
  RETRIEVE_SURVEY_CATEGORIES:
    'query SurveyCategories($filters: JSON, $cursor: JSON) { surveyCategories(filters: $filters, cursor: $cursor) { pageInfo { total endCursor } items { id name subCategories { id name } options { relativePopulation } } } }',
  RETRIEVE_WIZARD_SUBSCRIPTIONS: `query WizardSubscriptions($filters: JSON, $cursor: JSON) { wizardSubscriptions(filters: $filters, cursor: $cursor) { pageInfo { total endCursor } items { id name application { categoryId subCategoryId } plan value { amount currency } startAt createdAt endAt options } } }`,
  GET_WIZARD_SUBSCRIPTION: `query WizardSubscription($id: String!) { wizardSubscription(id: $id) { id name application { categoryId subCategoryId } plan value { amount currency } startAt createdAt endAt options } }`,

  USER_SURVEYS_WIZARDS:
    'query UserSurveyWizards($userId: String!) { userSurveyWizards(userId: $userId) { pageInfo { total endCursor } items { id title userId surveyTemplateId keys { id keyword positions { questionId value optionId } } category { id name parentId subCategories { id name } options { relativePopulation } } } } }',
  GET_SURVEY_WIZARD:
    'query SurveyWizard($id: String!) { surveyWizard(id: $id) {  id title userId surveyTemplateId keys { id keyword positions { questionId value optionId } } } }',

  RETRIEVE_USER_SURVEYS:
    'query UserSurveys($userId: String!) { userSurveys(userId: $userId) { pageInfo { total endCursor } items { id name metrics { opens closes dropout screenout avgDuration minDuration maxDuration stdDuration } creation { userId createdAt } application questions { id questionId } } } }',
  RETRIEVE_SURVEY_TEMPLATES:
    'query SurveysTemplates($filters: JSON, $limit: Int, $cursor: JSON) { surveysTemplates(filters: $filters, limit: $limit, cursor: $cursor) { pageInfo { total endCursor } items { id name metrics { opens closes dropout screenout avgDuration minDuration maxDuration stdDuration } application questions { id questionId } } } }',
  RETRIVE_SURVEY_TEMPLATE_KEYWORDS:
    'query SurveyTemplateKeywords( $surveyTemplateId: String! ){ surveyTemplateKeywords(surveyTemplateId: $surveyTemplateId){ pageInfo { total endCursor } items { id surveyTemplateId keyword questionText } } }',
};

export function useGetUserSurveys(
  userId: string,
  options?: { enabled: boolean }
) {
  const graphqlFetch = useGraphQLFetch();

  return useQuery<Pick<Query, 'userSurveys'>>(
    ['userSurveys', userId],
    () => graphqlFetch(QUERY.RETRIEVE_USER_SURVEYS, { userId }),
    { enabled: options?.enabled ?? true }
  );
}

export function useRetrieveSurveyTemplateKeywords(
  surveyTemplateId: string,
  options?: { enabled: boolean }
) {
  const graphqlFetch = useGraphQLFetch();

  return useQuery<Pick<Query, 'surveyTemplateKeywords'>>(
    ['surveyTemplateKeywords', surveyTemplateId],
    () =>
      graphqlFetch(QUERY.RETRIVE_SURVEY_TEMPLATE_KEYWORDS, {
        surveyTemplateId,
      }),
    { enabled: options?.enabled ?? true }
  );
}

export function useRetrieveShares(
  surveyId: string,
  options?: { enabled: boolean }
) {
  const graphqlFetch = useGraphQLFetch();

  return useQuery<Pick<Query, 'surveyShares'>>(
    ['surveyShares', surveyId],
    () =>
      graphqlFetch(QUERY.RETRIEVE_SHARES, {
        surveyId,
      }),
    { enabled: options?.enabled ?? true }
  );
}
