import { Switch } from '@blueprintjs/core';
import React from 'react';
import { Node } from 'react-flow-renderer';
import { FieldRow } from 'src/components/EditableField';
import { Block } from '@keix/workflow-types';
import { getBlockReturnType } from 'src/hooks/workflow/utils';
import DataType from './DataType';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';

export default function BlockNodeOverview(p: { node: Node<Block> }) {
  const returnType = getBlockReturnType(p.node.data);
  const { dispatch } = useWorkflowContext();
  const { isCollapsed } = p.node.data;

  return (
    <div className="flex flex-col">
      <FieldRow label="Uri">{p.node.data.uri}</FieldRow>
      <FieldRow label="Output">
        <DataType type={returnType} />
      </FieldRow>
      <FieldRow label="Collapsed">
        <Switch
          checked={isCollapsed}
          onChange={(e) =>
            dispatch({
              type: WorkflowAction.SET_NODE_COLLAPSED,
              id: p.node.id,
              isCollapsed: !isCollapsed,
            })
          }
        />
      </FieldRow>
    </div>
  );
}
