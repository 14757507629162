import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Drawer,
  Button,
  Icon,
  NonIdealState,
  DrawerSize,
} from '@blueprintjs/core';
import type { AppRef } from 'src/utils/table/useAppTable';
import CenteredSpinner from 'src/components/CenteredSpinner';
import css from 'csz';
import classNames from 'classnames';
import { useHotkeys } from 'src/utils/useHotkeys';
import { ContextualPanel } from 'src/components/ContextualPanel';
import { v5 } from 'uuid';
import { isEmpty } from 'lodash';

const WIDTH = 60;
const FORCE_TEST_FULLSCREEN = false;
export interface P<T extends { id: string }> {
  isOpen: boolean;
  id?: string;
  isRef: boolean;
  ctx?: unknown;
  isFullscreen: boolean;
  kind: string;
  isNew: boolean;
  quickLookRef: (ref: AppRef) => void;
  onClose: () => void;
  onApplyChanges: (value: T) => void;
}

interface QuickLookProvider {
  kind: string;
  size?: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
}
interface QuickLookRepositoryCtx {
  registerQuickLookProvider(provider: QuickLookProvider): void;
  providers: QuickLookProvider[];
}

interface QuickLookCtx {
  quickLookRef: (ref: AppRef, ctx?: unknown) => void;
  quickAdd: (kind: string, ctx?: unknown) => void;
  exitQuickLook: () => void;
  currentItem?: { item: AppRef; ctx?: unknown };
  itemToAdd?: { kind: string; ctx?: unknown };
}

const QuickLookRepositoryContext = React.createContext<QuickLookRepositoryCtx>({
  registerQuickLookProvider: () => null,
  providers: [],
});

const QuickLookContext = React.createContext<QuickLookCtx>({
  quickLookRef: () => null,
  quickAdd: () => null,
  exitQuickLook: () => null,
  currentItem: null,
});

export function useQuickLook() {
  return useContext(QuickLookContext);
}

export function useQuickLookRepository() {
  return useContext(QuickLookRepositoryContext);
}

function calculateDetailFromSize(size: string) {
  return `${100 - Number(size.replace('%', ''))}%`;
}

export function QuickLookRepositoryProvider(p: { children: JSX.Element }) {
  const [providers, setProviders] = useState<QuickLookProvider[]>([]);
  const registerQuickLookProvider = useCallback(
    (provider: QuickLookProvider) => {
      setProviders((p) => [...p, provider]);
    },
    []
  );
  return (
    <QuickLookRepositoryContext.Provider
      value={{ providers, registerQuickLookProvider }}
    >
      {p.children}
    </QuickLookRepositoryContext.Provider>
  );
}

export function AppQuickLookContext(p: { children: JSX.Element }) {
  const [currentItem, setQuickLookRef] = useState<{
    item: AppRef;
    ctx: unknown;
  }>(null);
  const [itemToAdd, setQuickAddItem] = useState<{ kind: string; ctx: unknown }>(
    null
  );
  const value = useMemo(
    () => ({
      currentItem,
      quickLookRef: (item: AppRef, ctx?: unknown) =>
        item != null ? setQuickLookRef({ item, ctx }) : setQuickLookRef(null),
      itemToAdd,
      quickAdd: (kind: string, ctx?: unknown) =>
        kind != null ? setQuickAddItem({ kind, ctx }) : setQuickAddItem(null),
      exitQuickLook: () => {
        setQuickLookRef(null);
        setQuickAddItem(null);
      },
    }),
    [currentItem, itemToAdd]
  );
  return (
    <QuickLookContext.Provider value={value}>
      {p.children}
    </QuickLookContext.Provider>
  );
}

export function AppQuickLookDrawer<T extends { id: string }>() {
  const { quickLookRef, quickAdd, currentItem, itemToAdd } =
    useContext(QuickLookContext);

  const handleApplyChanges = useCallback(
    (value: T) => {
      if (itemToAdd != null) {
        quickAdd(null);
        quickLookRef({ kind: itemToAdd.kind, ref: { id: value.id } });
      }
    },
    [currentItem, itemToAdd]
  );

  const handleClose = useCallback(() => {
    return currentItem != null ? quickLookRef(null) : quickAdd(null);
  }, [currentItem]);

  return (
    <>
      <QuickLookDrawer<T>
        isOpen={currentItem != null || itemToAdd != null}
        isNew={itemToAdd !== null}
        isFullscreen={false}
        onClose={handleClose}
        isRef={true}
        ctx={itemToAdd?.ctx ?? currentItem?.ctx}
        quickLookRef={quickLookRef}
        kind={itemToAdd?.kind ?? currentItem?.item?.kind}
        id={currentItem?.item?.ref?.id}
        onApplyChanges={handleApplyChanges}
      />
    </>
  );
}

export default function QuickLookDrawer<T extends { id: string }>(p: P<T>) {
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    setFullscreen(FORCE_TEST_FULLSCREEN ? true : p.isFullscreen);
  }, [p.isFullscreen]);

  const handleToggleFullscreen = useCallback(
    () => setFullscreen((p) => !p),
    []
  );
  const handleClose = useCallback(() => setFullscreen(false), []);
  const handleOpenFullscreen = useCallback(() => setFullscreen(true), []);

  const HotkeyContainer = useHotkeys([
    {
      combo: 'space',
      label: 'Toggle Fullscreen',
      onKeyDown: handleToggleFullscreen,
    },
  ]);

  const uri = `${p.kind}://${p.id}`;

  const { providers } = useQuickLookRepository();
  const { Component, size } = useMemo(() => {
    const provider = providers.find((provider) => p.kind === provider.kind);
    if (provider != null) {
      const Component = provider.component;
      const size = provider.size ?? DrawerSize.STANDARD;
      return { Component, size };
    } else {
      const Component = () => (
        <NonIdealState
          icon="warning-sign"
          title="No Quick Look Provider Found"
          description={`You have to register a provider for this ${p.kind} kind.`}
        />
      );
      return { Component, size: DrawerSize.STANDARD };
    }
  }, [providers, p.kind]);

  const resourceId =
    p.kind?.startsWith('certificate') && !isEmpty(p.id)
      ? v5(p.id, '70FF4EB9-7C3B-4F5C-8491-1FA11BD43C3D')
      : p.id;
  const resource = `${p.kind}://${resourceId}`;

  return (
    <HotkeyContainer>
      <Drawer
        portalClassName="quicklook-drawer"
        {...p}
        position="right"
        size={isFullscreen ? '100%' : size}
        onClosed={handleClose}
      >
        <div className={expandAreaClassName} onClick={handleOpenFullscreen}>
          <Icon icon="chevron-left" iconSize={20} />
        </div>
        <div
          className={classNames({ isFullscreen }, overlayButtonAreaClassName)}
        >
          <Button
            minimal
            large
            icon={<Icon icon="menu-open" iconSize={20} />}
            onClick={() => setFullscreen(false)}
          />
          <Button
            minimal
            large
            icon={<Icon icon="cross" iconSize={20} />}
            onClick={p.onClose}
          />
        </div>

        <div
          className={classNames({ isFullscreen }, masterClassName)}
          style={{ width: isFullscreen ? size : '100%' }}
        >
          <React.Suspense fallback={<CenteredSpinner />}>
            <Component {...p} />
          </React.Suspense>
        </div>
        <ContextualPanel
          width={calculateDetailFromSize(size)}
          uri={uri}
          resource={resource}
          className={classNames({ isFullscreen }, detailClassName)}
        />
      </Drawer>
    </HotkeyContainer>
  );
}

const masterClassName = css`
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  z-index: 9;
  &.isFullscreen {
    border-right: 1px solid var(--card-border-color);
  }
`;

const detailClassName = css`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  &.isFullscreen {
    opacity: 1;
  }
`;

const expandAreaClassName = css`
  height: 100%;
  position: absolute;
  width: ${WIDTH}px;
  left: -${WIDTH}px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s ease-in;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  :hover .bp4-icon {
    color: rgba(255, 255, 255, 1);
  }
  .bp4-icon {
    transition: color 0.1s ease-in;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const overlayButtonAreaClassName = css`
  position: absolute;
  top: 8px;
  right: -8px;
  z-index: 99999;
  opacity: 0;
  display: flex;
  align-items: center;
  transition: all 0.05s ease;
  &.isFullscreen {
    opacity: 1;
    right: 8px;
  }
`;
