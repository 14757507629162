import { Checkbox, FormGroup, NumericInput } from '@blueprintjs/core';
import React from 'react';
import { Node } from 'react-flow-renderer';
import { FieldRow } from 'src/components/EditableField';
import { Block } from '@keix/workflow-types';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';

export function BlockLayoutSettings(p: { node: Node<Block> }) {
  const { dispatch } = useWorkflowContext();
  const { id, layout } = p.node.data;
  return (
    <div className="flex flex-col">
      <FieldRow label="Layout">
        <Checkbox
          checked={layout != null}
          label="Show output in dashboard"
          onChange={(e) =>
            dispatch({
              type: WorkflowAction.SET_NODE_LAYOUT,
              id: p.node.id,
              layout: layout != null ? null : { x: 0, y: 0, w: 2, h: 2 },
            })
          }
        />
      </FieldRow>
      {layout != null && (
        <>
          <FieldRow label="X">
            <NumericInput
              value={layout.x}
              onValueChange={(x) =>
                dispatch({
                  type: WorkflowAction.SET_NODE_LAYOUT,
                  id: p.node.id,
                  layout: { ...layout, x },
                })
              }
            />
          </FieldRow>
          <FieldRow label="Y">
            <NumericInput
              value={layout.y}
              onValueChange={(y) =>
                dispatch({
                  type: WorkflowAction.SET_NODE_LAYOUT,
                  id: p.node.id,
                  layout: { ...layout, y },
                })
              }
            />
          </FieldRow>
          <FieldRow label="Width">
            <NumericInput
              value={layout.w}
              onValueChange={(w) =>
                dispatch({
                  type: WorkflowAction.SET_NODE_LAYOUT,
                  id: p.node.id,
                  layout: { ...layout, w },
                })
              }
            />
          </FieldRow>
          <FieldRow label="Height">
            <NumericInput
              value={layout.h}
              onValueChange={(h) =>
                dispatch({
                  type: WorkflowAction.SET_NODE_LAYOUT,
                  id: p.node.id,
                  layout: { ...layout, h },
                })
              }
            />
          </FieldRow>
        </>
      )}
    </div>
  );
}
