import { useCallback, useState, useRef, useEffect } from 'react';
import type { IToastProps } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core';
import { useNotifications } from 'src/context/NotificationContext';
import sanitizeTypename from './sanitizeTypename';
import { backOff, IBackOffOptions } from 'exponential-backoff';
import { first } from 'lodash';
import { GraphQLFetch, useGraphqlClient, useGraphQLFetch } from './graphql';
import { Subscription } from 'src/apps/athena/gql-types';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

const TIMEOUT = 6000;
const SETTLE_TICK = 300;
const SETTLE_TIMEOUT = 3000;
const SETTLE_DELAY = 1000;
const SUBSCRIPTION_QUERY = `
subscription SubscribeForEvents($id: String!) {
  subscribeForEvents(id: $id) {
    id,
    stream_name,
    type,
    time,
    data,
    metadata
  }
}
`;

export interface Event<T = {}> {
  stream_name: string;
  type: string;
  id: string;
  time: string;
  data: T;
  metadata: {};
}

export enum CommandStatus {
  IDLE,
  SENDING,
  WAITING,
  COMPLETED,
  FAILED,
  TIMED_OUT,
}

export interface CommandState {
  status: CommandStatus;
  isLoading: boolean;
}

export interface CommandOption<D = {}, V = {}, T = {}> {
  optimistic?: boolean;
  timeout?: number;
  settleQueryKey?: QueryKey;
  settleCondition?: (obj: T, values?: V) => boolean;
  variablesBuilder?: (values: V) => {};
  onMutate?: (values: V) => void;
  onSuccess?: (data?: D) => void;
  onSettled?: (data?: D) => void;
  onError?: () => void;
}

export type SendCommandOptions<T = {}, V = {}, D = {}> = [
  query: string,
  handler: HandleEventFn<T, D>,
  options: CommandOption<D, V>
];

export type HandleEventCtx<D = {}, Ctx = {}> = {
  context?: Ctx;
  complete: (data?: D) => void;
  showSuccessNotification: (toast: Partial<IToastProps>) => void;
  showFailureNotification: (toast: Partial<IToastProps>) => void;
};
export type SendCommandHook<V = {}> = [CommandState, RunCommandFn<V>];

export type HandleEventFn<T = {}, Ctx = {}, D = {}> = (
  event: Event<T>,
  ctx: HandleEventCtx<D, Ctx>
) => void;
export type RunCommandFn<V = {}> = (variables?: V) => Promise<string | boolean>;

export function useSendCommand<T = {}, V = {}, D = {}>(
  query: string,
  handler: HandleEventFn<T, D> = () => null,
  options: CommandOption<D, V> = {}
): SendCommandHook<V> {
  const client = useGraphQLFetch();

  const queryClient = useQueryClient();

  const onComplete = useCallback(
    (data?: D, ctx?: V) => {
      console.log('Complete...', data);

      const hasSettleCondition =
        options.settleQueryKey != null && options.settleCondition != null;
      const onSettled = () =>
        options.onSettled != null ? options.onSettled(data) : null;

      const onCompleted = () => {
        setStatus(CommandStatus.COMPLETED);
        options.onSuccess != null ? options.onSuccess(data) : null;
      };

      // If settle condition defined.
      if (options.settleQueryKey != null && options.settleCondition != null) {
        const clearSettleInterval = () => {
          if (interval != null) {
            clearInterval(interval);
            interval = null;
          }
        };
        const settleTick = () => {
          // Refetch.
          queryClient.invalidateQueries(options.settleQueryKey);

          // Get the query data, if condition is met.
          const data = queryClient.getQueryData<T>(options.settleQueryKey);
          if (options.settleCondition(data, ctx)) {
            // Clear the interval.
            clearSettleInterval();
            clearTimeout(timeout);
            onCompleted();
          }
        };

        // Start a tick.
        let interval = setInterval(settleTick, SETTLE_TICK);

        // Make a
        let timeout = setTimeout(() => {
          clearSettleInterval();
          onCompleted();
          onSettled();
        }, SETTLE_TIMEOUT);
      } else {
        // Why this timeout? Because sometimes the indexing is slower, so we wait a little bit.
        onCompleted();

        setTimeout(onSettled, SETTLE_DELAY);
      }
    },
    [options]
  );

  const subscribeForEvents = useSubscribeForEvents<T, D, {}>(
    handler,
    onComplete
  );
  const [status, setStatus] = useState(CommandStatus.IDLE);
  const optimistic = options?.optimistic ?? true;
  const variablesBuilder = options?.variablesBuilder ?? ((a) => a);

  useEffect(() => {
    if (status === CommandStatus.WAITING) {
      const timeout = setTimeout(() => {
        setStatus(CommandStatus.TIMED_OUT);
      }, options.timeout ?? TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [status]);

  const sendCommand = useCallback(
    async (values?: V) => {
      setStatus(CommandStatus.SENDING);
      options?.onMutate && options?.onMutate(values);
      const variables = variablesBuilder(sanitizeTypename(values));
      return client(query, variables)
        .then((res) => {
          console.log('=> Got', res);
          setStatus(CommandStatus.WAITING);
          const id = getCommandResponse(res);
          subscribeForEvents(id, values);
          return id;
        })
        .catch((err) => {
          console.log('=> Catched', err);

          setStatus(CommandStatus.FAILED);
          return false;
        });
    },
    [query, subscribeForEvents]
  );

  // If optimistic, we consider "loading" only the sending state. Once the command is sent
  // we optimistically say that the command is succeded.
  const loadingStates = optimistic
    ? [CommandStatus.SENDING]
    : [CommandStatus.SENDING, CommandStatus.WAITING];
  const isLoading = loadingStates.includes(status);
  const state = { isLoading, status };

  return [state, sendCommand];
}

export function getCommandResponse(res: any): string {
  const keys = Object.keys(res);
  if (keys.length !== 1) {
    throw new Error('Cannot get command response with multi keys');
  }
  return res[keys[0]] as string;
}

export type SubscriberFn<T, Ctx = {}> = (event: Event<T>, ctx?: Ctx) => void;

export type SubscribeFn<T, Ctx = {}> = (
  id: string,
  ctx?: Ctx
) => Promise<Event<T>>;
type RegexSubscriberOptions = { toast: Partial<IToastProps> } & (
  | { successRegex: string }
  | { failureRegex: string }
);

export function useRegexSubscriberForEvents(options: RegexSubscriberOptions) {
  return useSubscribeForEvents((event, ctx) => {
    const { type } = event;
    let match = false;
    if ('successRegex' in options) {
      match = new RegExp(options.successRegex).test(type);
    } else {
      match = !new RegExp(options.failureRegex).test(type);
    }
    if (match) {
      ctx.complete();
      ctx.showSuccessNotification(options.toast);
    }
  });
}

export function useSubscribeForEvents<T, D = {}, Ctx = {}>(
  handler: HandleEventFn<T, Ctx, D>,
  onComplete?: (data?: D, ctx?: Ctx) => void
): SubscribeFn<T> {
  const [ctx, setCtx] = useState(null);
  const client = useGraphqlClient();

  const [idToSubscribe, setIdToSubscribe] = useState(null);
  const promiseToResolve = useRef<Function | null>(null);
  const cancelFunction = useRef<Function | null>(null);
  const notifications = useNotifications();
  const handleEvents = useCallback(
    (response: { data: Pick<Subscription, 'subscribeForEvents'> }) => {
      const event = response?.data?.subscribeForEvents;
      if (event == null) {
        console.log('[WS] Got an event with no subscribeForEvents data');
        console.log(response);
        return;
      }
      handler(event as any, {
        complete: (data?: D) => {
          const resolvePromise = promiseToResolve.current;
          setIdToSubscribe(null);
          onComplete != null ? onComplete(data, ctx) : null;
          resolvePromise != null ? resolvePromise(event) : null;
          promiseToResolve.current = null;
          cancelFunction.current = null;
        },
        showSuccessNotification: (toast) => {
          notifications.show({
            message: 'Success',
            icon: 'tick',
            intent: Intent.SUCCESS,
            ...toast,
          });
        },
        showFailureNotification: (toast) => {
          notifications.show({
            message: 'Failure',
            icon: 'cross',
            intent: Intent.DANGER,
            ...toast,
          });
        },
      });
    },
    [handler, onComplete, ctx]
  );

  return (id: string) => {
    return new Promise((resolve, reject) => {
      promiseToResolve.current = resolve;
      cancelFunction.current = client.subscribe(
        {
          subscription: SUBSCRIPTION_QUERY,
          variables: { id },
        },
        handleEvents
      );
    });
    //   console.log('=> Subscribing for', id);
    //   client.subscribe<Pick<Subscription, 'subscribeForEvents'>>(
    //     {
    //       query: SUBSCRIPTION_QUERY,
    //       variables: { id },
    //     },
    //     {
    //       next: (evt) => handleEvents(evt.data),
    //       complete: () => null,
    //       error: reject,
    //     }
    //   );
    //   // setIdToSubscribe(id);
    //   // promiseToResolve.current = resolve;
    // });
  };
}

export async function waitForIndex<T>(
  fetch: GraphQLFetch<unknown>,
  query: string,
  variables: {},
  indexChecker?: (data: T) => boolean,
  options?: Partial<IBackOffOptions>
): Promise<T | null> {
  // Now wait for the backoff to retrieve the new entry.
  return backOff(
    () =>
      fetch(query, variables).then((result) => {
        const keys = Object.keys(result);
        const rootKey = first(keys);
        const data: T | null = result[rootKey];
        if (data == null) {
          throw new Error('Still not indexed');
        } else {
          if (indexChecker != null) {
            if (!indexChecker(data)) {
              throw new Error('Still not indexed');
            }
          }
          return data;
        }
      }),
    options ?? {
      startingDelay: 100,
      timeMultiple: 1.2,
      maxDelay: 1000,
    }
  );
}

export function useWaitForIndex() {
  const fetch = useGraphQLFetch<any, any>();
  return <T, V = {}>(
    query: string,
    variables?: V,
    indexChecker?: (data: T) => boolean
  ) => waitForIndex<T>(fetch, query, variables, indexChecker);
}
