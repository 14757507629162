import React, { useCallback, useMemo } from 'react';
import {
  Person,
  Company,
  Ref,
  EmailAddress,
  PhoneNumber,
  Location,
  Candidate,
  Project,
  ProjectTimeline,
  Gender,
  Survey,
  WizardSubscriptionApplication,
  WizardSubscription,
  Query,
} from '../apps/athena/gql-types';
import { compact, first, get, isEmpty } from 'lodash';
import RefLink from 'src/components/RefLink';
import type { LocaleOptions, DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import { isLoaded, Loadable } from 'src/components/EditableField';
import { Classes, Icon, Tag } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { EnumTag, EnumTagProps } from 'src/components/EnumTag';
import {
  getAreaForProvince,
  provinceByArea,
  provinceByKey,
} from 'src/vendor/province';
import { TFunction } from 'i18next';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useQuery } from '@tanstack/react-query';
import { QUERY } from 'src/graphql/surveys';
import { useGraphQLFetch } from './graphql';

export function renderArray<T extends {}>(
  array: T[],
  mapFn: (v: T) => JSX.Element,
  emptyView: JSX.Element
): JSX.Element {
  if (isEmpty(array)) {
    return emptyView;
  } else {
    return <>{array.map(mapFn)}</>;
  }
}

export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getEnumValues<T extends {}>(obj: T): string[] {
  return Object.values(obj);
}

export function getPecAddress(person: Person | Company) {
  return person.pec;
}
export function getAge(person: Person) {
  const { birthDate, gender } = person;
  if (birthDate != null) {
    const years = DateTime.fromISO(birthDate).diffNow('years').years;
    const age = Math.floor(Math.abs(years));
    const color =
      gender === Gender.Male
        ? 'bg-blue-500'
        : gender === Gender.Female
          ? 'bg-pink-400'
          : 'bg-gray-300 text-gray-700';
    return <Tag className={color}>{age}</Tag>;
  }
  return '-';
}

export function getCity(a: Person | Company) {
  const location = first<Location>(a.locations ?? []);
  const city = location?.location?.city;
  const province = location?.location?.province;
  return compact([city, province]).join(', ');
}

export function getRegion(a: Person | Company) {
  const location = first<Location>(a.locations ?? []);
  const province = location?.location?.province;
  const region = provinceByKey[province];

  return region?.regione;
}

export function getArea(t?: TFunction) {
  return (a: Person | Company) => {
    const location = first<Location>(a.locations ?? []);
    const province = location?.location?.province;
    if (province != null) {
      const area = getAreaForProvince(province);
      return t != null ? t(area) : area;
    }
  };
}

export function getDisplayName(person: Person) {
  return (
    get(person, 'name') ?? `${person.firstName ?? ''} ${person.lastName ?? ''}`
  );
}
export function getEmailAddress(person: Person | Company) {
  const emailAddress = first<EmailAddress>(person.emailAddresses ?? []);
  return emailAddress?.emailAddress ?? '-';
}

export function getPhoneNumber(person: Person | Company) {
  const phoneNumber = first<PhoneNumber>(person.phoneNumbers ?? []);
  return phoneNumber?.number ?? '-';
}
export function getBirthDate(person: Person) {
  const { birthDate } = person;
  if (birthDate != null) {
    return DateTime.fromISO(birthDate).toLocaleString(DateTime.DATE_MED);
  }
  return '-';
}

export function getCandidatePersonFirstName(candidate: Candidate) {
  return candidate.person?.firstName;
}

export function getCandidatePersonLastName(candidate: Candidate) {
  return candidate.person?.lastName;
}

export function getCandidateAssignmentState(candidate: Candidate) {
  const state = candidate?.assignment?.state
    ? candidate?.assignment?.state
    : '-';
  return state;
}

export function getCandidateInvitationState(candidate: Candidate) {
  /*const invitation = candidate?.invitation?.state
    ? candidate?.invitation?.state
    : '-';*/
  return '-';
}

export function getProjectList(defaultValue?: string) {
  return (candidate: Candidate) => {
    const listName = candidate?.projectList?.name;
    return listName ?? defaultValue;
  };
}

export function getStartDate(project: Project) {
  const timeline: ProjectTimeline = project.timeline ?? undefined;
  return timeline
    ? DateTime.fromISO(timeline?.startDate).toLocaleString(DateTime.DATE_MED)
    : '-';
}

export function getEndDate(project: Project) {
  const timeline: ProjectTimeline = project.timeline ?? undefined;
  return timeline
    ? DateTime.fromISO(timeline?.endDate).toLocaleString(DateTime.DATE_MED)
    : '-';
}

export function getLocation(person: Person | Company) {
  const personLocation = first<Location>(person.locations ?? []);
  return personLocation?.location?.name ?? '-';
}
export function getCertificate(onClick: (item: Ref) => void) {
  return (value: Person | Company) => {
    const certificate = first(value.certificates ?? []);
    if (certificate != null) {
      return (
        <RefLink
          kind="certificate"
          value={certificate}
          onClick={() => onClick(certificate)}
        />
      );
    }
    return '-';
  };
}

export function getEmployer(onClick: (item: Ref) => void) {
  return (person: Person) => {
    const { employer } = person;
    if (employer != null) {
      const ref: Ref = {
        id: employer.id,
        name: employer.name,
      };
      return (
        <RefLink kind="company" value={ref} onClick={() => onClick(ref)} />
      );
    }
  };
}

export function useFormatCurrency(options?: {
  currency?: string;
  locale?: string;
}) {
  const { i18n } = useTranslation();
  const locale = options?.locale ?? i18n.languages[0];
  return useCallback(
    (val: number) => {
      return Number(val).toLocaleString(locale, {
        ...(options ?? {}),
        style: 'currency',
        currency: 'EUR',
      });
    },
    [options]
  );
}

export function useCurrencyAccessor(
  dataKey: string,
  options?: {
    currency?: string;
    locale?: string;
  }
) {
  const formatCurrency = useFormatCurrency(options);
  return useCallback(
    (val: Loadable<unknown>) => {
      if (isLoaded(val)) {
        const value = val[dataKey];
        if (value != null) {
          return formatCurrency(value);
        }
        return '-';
      }
      return <div className={Classes.SKELETON}>1000€</div>;
    },
    [dataKey, options]
  );
}

export function useDateAccessor(
  dataKey: string,
  options: LocaleOptions & DateTimeFormatOptions
) {
  return useCallback(
    (val: Loadable<unknown>) => {
      if (isLoaded(val)) {
        const date = val[dataKey];
        return date != null
          ? DateTime.fromISO(date).toLocaleString(options)
          : '-';
      } else {
        return <div className={Classes.SKELETON}>Date</div>;
      }
    },
    [dataKey, options]
  );
}

export function useNumberAccessor(
  dataKey: string,
  options: Intl.NumberFormatOptions
) {
  const formatter = useMemo(
    () => new Intl.NumberFormat('it', options),
    [options]
  );
  return useCallback(
    (val: Loadable<unknown>) => {
      if (isLoaded(val)) {
        const value = val[dataKey];
        return value != null ? formatter.format(value) : '-';
      } else {
        return <div className={Classes.SKELETON}>Date</div>;
      }
    },
    [dataKey, options]
  );
}

export function useEnumTagAccessor<T, V>(
  dataKey: string,
  tagProps: Omit<EnumTagProps<V>, 'value'>
) {
  return useCallback(
    (val: Loadable<T>) => {
      if (isLoaded(val)) {
        const value: V | null = get(val, dataKey);
        return value != null ? <EnumTag value={value} {...tagProps} /> : '-';
      } else {
        return <div className={Classes.SKELETON}>{dataKey}</div>;
      }
    },
    [dataKey]
  );
}

export function getIfSurveyTemplate(survey: Survey) {
  if (survey?.application != null) {
    if (survey.application?.isTemplate === true) {
      return (
        <div className="flex">
          <div className="flex items-center text-gray-400 w-12">
            <Tooltip2
              hoverOpenDelay={500}
              content={'This Survey is a Template'}
            >
              <Icon
                icon={'tick'}
                className={`px-1 opacity-90 text-green-500`}
              />
            </Tooltip2>
            Template
          </div>
        </div>
      );
    }
    if (survey.application?.surveyWizardId != null) {
      return (
        <div className="flex">
          <div className="flex items-center text-gray-400 w-12">
            <Tooltip2
              hoverOpenDelay={500}
              content={'This Survey is created from Survey Wizard'}
            >
              <Icon
                icon={'bring-data'}
                className={`px-1 opacity-90 text-blue-500`}
              />
            </Tooltip2>
            Wizard
          </div>
        </div>
      );
    }
  }
}

export function getSurveyCategory(survey: Survey) {
  const category = survey.application?.category?.name;
  return category ?? '';
}

export function getCreatedAt(survey: Survey) {
  const date = survey?.creation?.createdAt ?? undefined;
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : '-';
}

export function getWizardSubscriptionApplication(
  subscription: WizardSubscription
) {
  const categories = subscription?.application;

  const categoryText =
    "This Subscription will be applied to all category' subCategories";
  const subCategoryText =
    'This Subscription will be applied just to subCategory';

  const openDelay = 300;

  if (categories?.subCategoryId) {
    return (
      <div className="flex">
        <div className="flex items-center text-gray-400 w-12">
          <Tooltip2 hoverOpenDelay={openDelay} content={subCategoryText}>
            <Icon icon="book" className="px-1 opacity-90 text-blue-500" />
          </Tooltip2>
          <Tooltip2 hoverOpenDelay={openDelay} content={subCategoryText}>
            <Icon icon={'chevron-right'} className={`px-1 opacity-90`} />
          </Tooltip2>
          <Tooltip2 hoverOpenDelay={openDelay} content={subCategoryText}>
            <Icon
              icon={'bookmark'}
              className={`px-1 opacity-90 text-green-500`}
            />
          </Tooltip2>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex">
        <div className="flex items-center text-gray-400 w-12">
          <Tooltip2 hoverOpenDelay={openDelay} content={categoryText}>
            <Icon icon={'book'} className={`px-1 opacity-90 text-blue-500`} />
          </Tooltip2>
        </div>
      </div>
    );
  }
}

export function currencyValueAccessor(subscription: WizardSubscription) {
  return `${subscription?.value?.amount ?? ''} ${subscription?.value?.currency ?? ''
    }`;
}
