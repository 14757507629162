import {
  Button,
  ButtonProps,
  Expander,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { FormikProvider, useField, useFieldValue, useFormik } from 'formik';
import { isEmpty, pick, set, update } from 'lodash';
import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import {
  EffectType,
  Filter,
  FilterEffect,
  Survey,
} from 'src/apps/athena/gql-types';
import { MUTATIONS } from 'src/graphql/surveys';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { diffableArray, DiffableForm, useApplyChanges } from 'src/utils/diffV2';
import {
  FieldArrayHelperProps,
  useDiffableFieldArray,
  useDiffableFieldArrayHelpers,
} from 'src/utils/formik';
import { v4 } from 'src/utils/uuid';
import { HTMLSelectField } from '../BlueprintFields';
import { BottomBar } from '../BottomBar';
import { DebugView } from '../DebugView';
import { DiffDebug } from '../DiffDebug';
import EditableList from '../EditableList';
import { ValidationDebug } from '../ValidationDebug';

// export interface Filter {
//   id: string;
//   surveyId: string;
//   conditions: FilterCondition[];
//   effects: FilterEffect[];
// }

// export enum EffectType {
//   SHOW_QUESTION = 'SHOW_QUESTION',
//   HIDE_QUESTION = 'HIDE_QUESTION',
//   SHOW_OPTION = 'SHOW_OPTION',
//   HIDE_OPTION = 'HIDE_OPTION',
//   CLOSE_SURVEY = 'CLOSE_SURVEY',
//   SHOW_SURVEY = 'SHOW_SURVEY',
// }

// export type SourceType = 'survey' | 'question' | 'option';
// export type TargetType = 'survey' | 'question' | 'option';

// export type FilterSourceTarget =
//   | FilterEffectQuestionTarget
//   | FilterEffectOptionTarget;
// export type FilterEffectTarget =
//   | FilterEffectSurveyTarget
//   | FilterEffectQuestionTarget
//   | FilterEffectOptionTarget;

// export interface FilterEffectSurveyTarget {
//   kind: 'survey';
// }

// export interface FilterEffectQuestionTarget {
//   id: string;
//   kind: 'question';
//   questionId: string;
// }

// export interface FilterEffectOptionTarget {
//   id: string;
//   kind: 'option';
//   questionId: string;
//   optionId: string;
// }

// export type FilterCondition = FilterBooleanCondition | FilterValueCondition;

// export type ValueOperator = 'EQUAL' | 'NOT_EQUAL';
// export type BooleanOperator = 'AND' | 'OR';

// export interface FilterValueCondition {
//   questionId: string;
//   optionId: string;
//   op: ValueOperator;
// }

// export interface FilterBooleanCondition {
//   op: BooleanOperator;
//   conditions: FilterCondition[];
// }

// export interface FilterEffect {
//   id: string;
//   type: EffectType;
//   target: FilterEffectTarget;
// }

// export interface ConditionType {
//   EQUAL: 'EQUAL';
//   NOT_EQUAL: 'NOT_EQUAL';
//   EMPTY: 'EMPTY';
//   NOT_EMPTY: 'NOT_EMPTY';
// }

function AddSurveyFilterButton(p: Partial<ButtonProps>) {
  const { push } = useDiffableFieldArrayHelpers<Filter>('filters');
  return (
    <Button
      icon="plus"
      text="Add Filter"
      minimal
      {...p}
      onClick={() =>
        push({
          conditions: [],
          effects: [],
          id: v4(),
        })
      }
    />
  );
}

export default function SurveyLogic(p: { surveyId: string }) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const applyChanges = useApplyChanges(MUTATIONS);

  const form = useFormik<{ filters: Filter[] }>({
    initialValues: {
      filters: data?.survey?.filters ?? [],
    },
    onSubmit: async () => {
      const changes = runMiddleware(form);
      const res = await applyChanges(changes);
    },
  });

  const runMiddleware = useCallback((form: DiffableForm<Partial<Survey>>) => {
    const middleware = diffableArray({
      name: 'filters',
      commandSuffix: 'FILTER',
      data: { surveyId: p.surveyId },
    });
    return middleware(form);
  }, []);

  const diff = useMemo(() => runMiddleware(form), [form]);

  const hasFilters = form.values.filters.length > 0;

  return (
    <FormikProvider value={form}>
      <DebugView>
        <DiffDebug diff={diff} />
        <ValidationDebug errors={form.errors} />
      </DebugView>
      {hasFilters ? (
        <div className="flex-grow flex flex-col">
          <EditableList
            component={SurveyFilterField}
            componentProps={{ surveyId: p.surveyId }}
            name="filters"
            showAddButton={false}
          />
          <Expander />
          <BottomBar>
            <AddSurveyFilterButton />
            <Expander />
            <Button
              icon="tick"
              intent={Intent.PRIMARY}
              minimal
              onClick={form.submitForm}
              text="Save"
            />
          </BottomBar>
        </div>
      ) : (
        <NonIdealState
          title="No Logic Yet"
          description="You haven't hadded any logic yet, click here to add your first filter."
          icon="function"
          action={
            <AddSurveyFilterButton intent={Intent.PRIMARY} minimal={false} />
          }
        />
      )}
    </FormikProvider>
  );
}

function SurveyFilterField(p: { name: string; surveyId: string }) {
  const [field, _, helpers] = useField(`${p.name}.op`);
  const questions = useQuestions(p.surveyId);
  const currentOperator = isEmpty(field.value) ? 'AND' : field.value;
  return (
    <div className="flex bg-white rounded p-4 mb-2 mt-2 border shadow-sm">
      <div className="w-1/2 flex items-center">
        <div className="font-light text-gray-400 text-3xl ">IF</div>
        <div
          className="transform -rotate-90 text-blue-400 hover:bg-gray-50 cursor-pointer px-1"
          onClick={() => helpers.setValue(field.value === 'OR' ? 'AND' : 'OR')}
        >
          {currentOperator}
        </div>
        <div className="flex-grow">
          <EditableList
            diffable={false}
            removeButtonProps={{ position: 'right', icon: 'cross' }}
            component={SurveyFilterConditionField}
            componentProps={{ surveyId: p.surveyId, questions }}
            name={`${p.name}.conditions`}
            addButtonText="Add Filter Condition"
          />
        </div>
      </div>
      <div className="w-1/2 flex items-center">
        <div className="font-light text-gray-400 text-3xl">THEN</div>
        <div className="flex-grow">
          <EditableList
            diffable={false}
            removeButtonProps={{ position: 'right', icon: 'cross' }}
            component={SurveyFilterEffectField}
            componentProps={{ surveyId: p.surveyId, questions }}
            name={`${p.name}.effects`}
            addButtonText="Add Filter Effect"
          />
        </div>
      </div>
    </div>
  );
}

function useQuestions(surveyId: string) {
  const { data } = useGetSurveyWithQuestions(surveyId);
  return data?.survey?.questions?.map((q, index) => ({
    label: `Q${index}. ${q.body}`,
    value: q.id,
  }));
}

function useQuestionOptions(surveyId: string, name: string) {
  const { data } = useGetSurveyWithQuestions(surveyId);
  const [value] = useFieldValue<string>(`${name}.questionId`);
  const question = data?.survey?.questions?.find((q) => q.id === value);

  return (
    question?.options?.map((opt) => ({
      label: opt.body,
      value: opt.id,
    })) ?? []
  );
}

function SurveyFilterConditionField(p: {
  questions: { label: string; value: string }[];
  surveyId: string;
  name: string;
}) {
  const optionIdOptions = useQuestionOptions(p.surveyId, p.name);
  return (
    <div className="flex">
      <HTMLSelectField
        name={`${p.name}.questionId`}
        className="mr-2"
        placeholder="Question"
        options={p.questions}
      />
      <HTMLSelectField
        name={`${p.name}.op`}
        className="mr-2"
        minimal
        placeholder="Operator"
        options={[
          { label: 'Equal', value: 'EQUAL' },
          { label: 'Not Equal', value: 'NOT_EQUAL' },
        ]}
      />
      {!isEmpty(optionIdOptions) && (
        <HTMLSelectField
          className="mr-2"
          placeholder="Option"
          name={`${p.name}.optionId`}
          options={optionIdOptions}
        />
      )}
    </div>
  );
}

function SurveyFilterEffectField(p: {
  questions: { label: string; value: string }[];
  name: string;
  surveyId: string;
}) {
  const optionIdOptions = useQuestionOptions(p.surveyId, `${p.name}.target`);
  const [field] = useField<string>(`${p.name}.type`);
  const [kindField, _, helpers] = useField<string>(`${p.name}.target.kind`);

  useEffect(() => {
    if (field.value.endsWith('QUESTION')) {
      helpers.setValue('question');
    } else if (field.value.endsWith('OPTION')) {
      helpers.setValue('option');
    } else {
      helpers.setValue('survey');
    }
  }, [field.value]);

  const value = field.value ?? '';
  return (
    <div className="flex">
      <HTMLSelectField
        name={`${p.name}.type`}
        className="mr-2"
        minimal
        placeholder="Type"
        options={[
          { label: 'Show Question', value: 'SHOW_QUESTION' },
          { label: 'Hide Question', value: 'HIDE_QUESTION' },
          { label: 'Show Option', value: 'SHOW_OPTION' },
          { label: 'Hide Option', value: 'HIDE_OPTION' },
          { label: 'Close Survey', value: 'CLOSE_SURVEY' },
        ]}
      />
      {value !== 'CLOSE_SURVEY' && (
        <>
          <HTMLSelectField
            name={`${p.name}.target.questionId`}
            options={p.questions}
            placeholder="Question"
          />
          {!isEmpty(optionIdOptions) && value.endsWith('OPTION') && (
            <HTMLSelectField
              name={`${p.name}.target.optionId`}
              options={optionIdOptions}
              placeholder="Option"
            />
          )}
        </>
      )}
    </div>
  );
}
