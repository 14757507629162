import classNames from 'classnames';
import React from 'react';
import { Node } from 'react-flow-renderer';
import { Block } from '@keix/workflow-types';
import { BlockNodeIcon } from './BlockIcon';

export function BlockDrawerHeader(p: {
  node: Node<Block> | null;
  nodes: Node<Block>[];
  selectedIndex: number;
  onChangeIndex: (index: number) => void;
}) {
  const { node, nodes, selectedIndex, onChangeIndex } = p;
  return node != null ? (
    <div className="flex flex-col items-center p-4  border-b">
      <div className="mb-2 font-medium text-gray-800 text-base">
        {node?.data.name}
      </div>
      <div className="flex gap-x-2">
        {nodes.map((node, index) => (
          <BlockNodeIcon
            className={classNames('cursor-pointer hover:opacity-80', {
              'outline outline-primary outline-offset-2':
                selectedIndex === index,
            })}
            block={node.data}
            onClick={() => onChangeIndex(index)}
            hasInputs={false}
            hasOutputs={false}
          />
        ))}
      </div>
      <div className="mt-2 text-gray-400">{node?.data.uri}</div>
    </div>
  ) : (
    <React.Fragment />
  );
}
