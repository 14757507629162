import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@/styles/global.css';
import '@/styles/tailwind.css';

const root = document.getElementById('root');
createRoot(root).render(<App />);
