import React from "react";
import { Spinner } from "@blueprintjs/core";

export default function CenteredSpinner() {
  return (
    <div className="flex-centered">
      <Spinner />
    </div>
  );
}
