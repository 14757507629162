import { set } from 'lodash/fp';
import { WorkflowActions } from '..';
import { WorkflowState } from '../../workflow/types';
import { getNodeIndexById } from '../../workflow/WorkflowContext';

export enum WorkflowSelectionActionType {
  CHANGE_SUB_SELECTION_NODE_INDEX,
  NEXT_SUB_SELECTION_NODE_INDEX,
  PREV_SUB_SELECTION_NODE_INDEX,
  SET_NODE_COLLAPSED,
}

export type WorkflowSelectionActions =
  | {
      type: WorkflowSelectionActionType.SET_NODE_COLLAPSED;
      id: string;
      isCollapsed: boolean;
    }
  | {
      type: WorkflowSelectionActionType.CHANGE_SUB_SELECTION_NODE_INDEX;
      index?: number;
    }
  | { type: WorkflowSelectionActionType.PREV_SUB_SELECTION_NODE_INDEX }
  | { type: WorkflowSelectionActionType.NEXT_SUB_SELECTION_NODE_INDEX };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowSelectionActionType.CHANGE_SUB_SELECTION_NODE_INDEX:
      return set('subselectionNodeIndex', action.index ?? 0)(prev);
    case WorkflowSelectionActionType.NEXT_SUB_SELECTION_NODE_INDEX:
      return set('subselectionNodeIndex', prev.subselectionNodeIndex + 1)(prev);
    case WorkflowSelectionActionType.PREV_SUB_SELECTION_NODE_INDEX:
      return set('subselectionNodeIndex', prev.subselectionNodeIndex - 1)(prev);
    case WorkflowSelectionActionType.SET_NODE_COLLAPSED: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return set(
        `nodes.${nodeIndex}.data.isCollapsed`,
        action.isCollapsed
      )(prev);
    }
  }
  return prev;
}
