import React from 'react';
import { GraphQLSuggesterField } from 'src/components/GraphQLSuggester';
import type { Competence, Partner, UploadType } from '../gql-types';
import { MenuItem } from '@blueprintjs/core';
import { EditableTextField } from 'src/components/BlueprintFields';
import { useTranslation } from 'react-i18next';
import { formatUploadTypeName } from '../../../pages/UploadTypes';

function isCompetence(val: string | Competence): val is Competence {
  return typeof val !== 'string';
}
function isPartner(val: string | Partner): val is Partner {
  return typeof val !== 'string';
}
function isUploadType(val: string | UploadType): val is UploadType {
  return typeof val !== 'string';
}

export function CompetenceSuggesterField(p: {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const { t } = useTranslation('certificates');
  if (p.readOnly) {
    return <EditableTextField disabled name={p.name} />;
  }
  return (
    <GraphQLSuggesterField<string | Competence>
      {...p}
      valueRenderer={(p) => (isCompetence(p) ? p.id : p)}
      valueSetter={(p) => (isCompetence(p) ? p.id : p)}
      query="query Search($queryString: String) { competences(queryString: $queryString) { items { id name  } } }"
      menuItemRenderer={(p) => ({ text: isCompetence(p) ? p.id : p })}
      placeholder={p.placeholder ?? t('Pick a Competence...')}
      itemRenderer={(competence, props) =>
        isCompetence(competence) && (
          <MenuItem
            {...props}
            active={props.modifiers.active}
            onClick={props.handleClick}
            text={competence.id}
            label={competence.name}
          />
        )
      }
      dataKey="competences"
    />
  );
}
export function PartnerSuggesterField(p: {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const { t } = useTranslation('certificates');
  if (p.readOnly) {
    return (
      <EditableTextField
        placeholder={p.placeholder ?? t('No Partner Set')}
        disabled
        name={`${p.name}.name`}
      />
    );
  }
  return (
    <GraphQLSuggesterField<Partner | string>
      {...p}
      valueRenderer={(p) => (isPartner(p) ? p.name : p)}
      valueSetter={(p) => (isPartner(p) ? p.id : p)}
      query="query SearchPartners($queryString: String) { partners(queryString: $queryString) { items { id name } } }"
      menuItemRenderer={(p) => ({ text: isPartner(p) ? p.name : p })}
      placeholder={p.placeholder ?? t('Pick a Partner...')}
      itemRenderer={(p, props) =>
        isPartner(p) && (
          <MenuItem {...props} onClick={props.handleClick} text={p.name} />
        )
      }
      dataKey="partners"
    />
  );
}

export function UploadTypeSuggesterField(p: {
  name: string;
  placeholder?: string;
  filterItems?: (item: UploadType) => boolean;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  if (p.readOnly) {
    return (
      <EditableTextField
        placeholder={p.placeholder ?? t('No Document Type Set')}
        disabled
        name={`${p.name}.name`}
      />
    );
  }
  return (
    <GraphQLSuggesterField<UploadType | string>
      {...p}
      valueRenderer={(p) =>
        isUploadType(p) ? (formatUploadTypeName(p, true) as string) : p
      }
      valueSetter={(p) => (isUploadType(p) ? p.name : p)}
      query="query { uploadTypes { items { id name } } }"
      menuItemRenderer={(p) => ({ text: isUploadType(p) ? p.name : p })}
      placeholder={p.placeholder ?? t('Pick a Document Type...')}
      itemRenderer={(p, props) =>
        isUploadType(p) && (
          <MenuItem
            {...props}
            onClick={props.handleClick}
            text={formatUploadTypeName(p)}
          />
        )
      }
      dataKey="uploadTypes"
    />
  );
}
