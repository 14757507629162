import { set, unset } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HotkeyDefinition, useHotkeys } from 'src/utils/useHotkeys';
import { v4 } from 'src/utils/uuid';
import hotkeys from 'hotkeys-js';

export interface HotkeysCtx {
  definitions: { [id: string]: HotkeyDefinition };
  registerHotkey: (id: string, hotkey: HotkeyDefinition) => void;
  unregisterHotkey: (id: string) => void;
}

const HotkeysContext = createContext<HotkeysCtx>({
  definitions: {},
  registerHotkey: () => {
    throw new Error('Cannot use an empty context');
    return null;
  },
  unregisterHotkey: () => null,
});

export function AppHotkeys(p: { children: JSX.Element }) {
  const [definitions, setDefinitions] = useState<{
    [id: string]: HotkeyDefinition;
  }>({});
  const value = useMemo(
    () => ({
      registerHotkey: (id: string, hotkey: HotkeyDefinition) => {
        hotkeys(
          hotkey.combo,
          {
            scope: hotkey.scope,
          },
          hotkey.onKeyDown
        );
        setDefinitions((prev) => ({
          ...prev,
          [id]: hotkey,
        }));
      },
      unregisterHotkey: (id: string) =>
        setDefinitions((prev) => {
          const hotkey = prev[id];
          if (hotkey != null) {
            hotkeys.unbind(hotkey.combo, hotkey.scope);
          }
          unset(prev, id);
          return prev;
        }),
      definitions,
    }),
    [definitions]
  );

  return (
    <HotkeysContext.Provider value={value}>
      {p.children}
    </HotkeysContext.Provider>
  );
}

export function useHotkey(hotkey: HotkeyDefinition) {
  const { registerHotkey, unregisterHotkey } = useContext(HotkeysContext);
  useEffect(() => {
    const id = v4();
    registerHotkey(id, hotkey);
    return () => unregisterHotkey(id);
  }, []);
}
