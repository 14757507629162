import { Checkbox, Expander } from '@blueprintjs/core';
import { useField, useFieldValue, useStatus } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'src/apps/athena/gql-types';
import { getSurveyLanguages } from 'src/pages/Survey/SurveyPage';
import { CheckboxField, SelectField, SelectOption } from '../BlueprintFields';
import EditableField from '../EditableField';
import { Flag } from '../Flag';

const languageOptions: SelectOption[] = getSurveyLanguages().map((opt) => ({
  icon: <Flag countryCode={opt.countryCode} />,
  value: opt.countryCode,
  label: opt.name,
}));

export default function QuestionSettingsField(p: {
  isEditingTranslations: boolean;
  currentLanguage: string;
}) {
  const [typeValue] = useFieldValue('type');
  const [languageField, __, languageHelpers] = useField('language');
  const isTranslated = !isEmpty(languageField.value);
  const { t } = useTranslation('survey');
  const [field, _, helpers] = useField('rows');
  return (
    <div className="py-3 text-sm">
      <Checkbox
        checked={isTranslated}
        disabled={p.isEditingTranslations}
        onChange={(e) =>
          (e.target as HTMLInputElement).checked
            ? languageHelpers.setValue('IT')
            : languageHelpers.setValue(null)
        }
      >
        {t('Default Language')}
        {isTranslated && (
          <SelectField
            small
            className="ml-2"
            disabled={p.isEditingTranslations}
            options={languageOptions}
            name={'language'}
          />
        )}
      </Checkbox>

      {typeValue === QuestionType.MultipleChoice && (
        <>
          <CheckboxField
            disabled={p.isEditingTranslations}
            name="settings.allowMultipleChoices"
          >
            {t('Enable Multiple Choices')}
          </CheckboxField>
          <CheckboxField
            disabled={p.isEditingTranslations}
            name="settings.randomizeOptions"
          >
            {t('Randomize Options')}
          </CheckboxField>
          <CheckboxField
            disabled={p.isEditingTranslations}
            name="settings.enableDropdown"
          >
            {t('Enable Dropdown')}
          </CheckboxField>
          <CheckboxField
            disabled={p.isEditingTranslations}
            name="settings.enableOtherOption"
          >
            {t('Enable Other Option')}
          </CheckboxField>
        </>
      )}
      {typeValue === QuestionType.OpinionScale && (
        <CheckboxField
          disabled={p.isEditingTranslations}
          name="settings.enableUndefinedOption"
        >
          {t('Enable undefined option')}
        </CheckboxField>
      )}

      <Checkbox
        disabled={p.isEditingTranslations}
        checked={field.value != null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          e.target.checked ? helpers.setValue([]) : helpers.setValue(null)
        }
      >
        {t('Repeat more times')}
      </Checkbox>
      <CheckboxField
        disabled={p.isEditingTranslations}
        name="settings.optional"
      >
        {t('Optional')}
      </CheckboxField>
    </div>
  );
}
