import React, { useMemo, useState } from 'react';
import { Icon, NonIdealState } from '@blueprintjs/core';
import {
  Block,
  DataTypeComponentProps,
  DataTypeDefinition,
  PreviewDataType,
} from '@keix/workflow-types';
import { getPreviewData, PandasPreview } from './pandas';
import { Dictionary, isEmpty, isEqual } from 'lodash';
import { ECharts } from 'echarts/core';
import { EChartsOption } from 'echarts';
import { ReactECharts } from 'src/components/Echarts';
import { CodeEditor } from './code';
import { Size, useSize } from 'src/utils/size';

export const dataTypes: DataTypeDefinition[] = [
  {
    name: 'Chart',
    uri: 'keix.chart',
    icon: 'chart',
    description: 'A Keix Chart based on Apache ECharts',
    preview: (proxy: any, { type, parameters, size }) => {
      return (
        <ChartPreview
          size={size}
          proxy={proxy}
          type={type}
          parameters={parameters}
        />
      );
    },
  },
  {
    name: 'EChart Code',
    uri: 'keix.echartCode',
    extends: 'keix.javascript',
    icon: 'code',
    description: 'A Javascript code block with ECharts preview',
    inputComponent: ChartCodeEditor,
  },
];

function ChartCodeEditor(props: DataTypeComponentProps<string>) {
  const { block } = props;
  const options = useEChartOptions(block.parameters, block.state.proxy);
  const [size, ref] = useSize();
  const height = Math.floor(size.height / 2.0);
  const previewPanel = useMemo(
    () => (
      <div ref={ref} className="flex-grow overflow-hidden">
        <div className="flex flex-col" style={{ height: size.height }}>
          {options != null ? (
            <ReactECharts style={{ height }} option={options} />
          ) : (
            <NonIdealState title="Missing data" />
          )}
          <div className="flex-grow border-t">
            <PandasPreview
              proxy={block.state.proxy}
              type={PreviewDataType.SMALL}
              width="100%"
              height={height}
            />
          </div>
        </div>
      </div>
    ),
    [options, height]
  );
  console.log('Render code editor');
  return <CodeEditor {...props} previewPanel={previewPanel} />;
}

function useEChartOptions(parameters: { [key: string]: string }, proxy?: any) {
  const { code } = parameters;
  const [options, setOptions] = useState<EChartsOption>();
  let nextOptions: EChartsOption;
  const data = getPreviewData(proxy);
  try {
    const fn = Function(code);
    const nextOptions = fn(data);
    if (!isEmpty(nextOptions) && !isEqual(nextOptions, options)) {
      setOptions(nextOptions);
    }
  } catch (err) {
    console.error(err);
  }
  return options;
}

function ChartPreview(p: {
  proxy: any;
  size: Size;
  type: PreviewDataType;
  parameters: { [key: string]: string };
}) {
  const options = useEChartOptions(p.parameters, p.proxy);

  if (p.type === PreviewDataType.MICRO) {
    return options != null ? (
      <Icon icon="chart" />
    ) : (
      <React.Fragment />
    );
  }

  return (
    <div style={p.size} className="relative">
      {options != null ? (
        <>
          <ReactECharts style={p.size} option={options} />
        </>
      ) : (
        <NonIdealState
          title="Missing Data"
          description="Run the workflow to see the chart data"
          icon="dashboard"
        />
      )}
    </div>
  );
}
