import { useCallback } from "react";
import mimeTypes from "./mime-types";

const endpoint = "https://graphql.api.keix.com/graphql";
const query = `
query GetUploadURL($name: String!){
  getUploadURL(params:{
    kind:CV
    filename:$name,
    ext:"pdf"
  }, op:RW) {
    urlW,
    urlR,
    url
  }
}`;

export function useUploader(
  options: { kind: string; operation: string } = {
    kind: "DOC",
    operation: "RW",
  }
) {
  return useCallback(
    async (file: File, onProgress?: (progress: number) => void) => {
      // Prep the variables to send.
      const ext = file.type in mimeTypes ? mimeTypes[file.type] : "doc";
      const variables = {
        name: file.name,
        ext,
      };
      const getUploadURL = await window
        .fetch(endpoint, {
          method: "POST",
          body: JSON.stringify({
            operationName: "GetUploadURL",
            query,
            variables,
          }),
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.json());
      if (!("data" in getUploadURL)) {
        console.log("We havent upload url...");
      }

      const { urlW, urlR } = getUploadURL.data.getUploadURL;

      // Upload the cv.
      const uploadRes = await uploadFile(urlW, file, onProgress);
      if (!uploadRes.ok) {
        console.log("We havent uploaded the cv...");
        throw new Error("Something wen wrong uploading");
      }
      return { url: urlR as string, name: file.name };
    },
    [options]
  );
}

async function uploadFile(
  url: string,
  file: File,
  onProgress?: (progress: number) => void
): Promise<{ ok: boolean }> {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("PUT", url);
    xhr.onload = () => {
      resolve({ ok: xhr.status >= 200 && xhr.status < 300 });
    };
    xhr.onerror = () => {
      reject(new Error("Something went wrong uploading"));
    };
    xhr.upload.onprogress = (event) => {
      if (onProgress && event.lengthComputable) {
        const percent = event.loaded / event.total;
        onProgress(percent);
      }
    };
    xhr.send(file);
  });
}
