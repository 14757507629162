import {
  Button,
  ButtonGroup,
  Classes,
  Colors,
  Divider,
  Icon,
  IconName,
  Intent,
  Menu,
  ProgressBar,
  Tag,
} from '@blueprintjs/core';
import { MenuItem2 } from '@blueprintjs/popover2';
import { compact, first, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Survey,
  SurveyShare as ISurveyShare,
  SurveyShare,
  SurveyShareMedium,
  SurveyShares,
  SurveyShareType,
} from 'src/apps/athena/gql-types';
import { useNotifications } from 'src/context/NotificationContext';
import { QUERY } from 'src/graphql/surveys';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { getLocation, useDateAccessor } from 'src/utils/accessors';
import { useGraphQLFetch } from 'src/utils/graphql';
import { AppColumn, useAppTable } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import { BottomBar } from '../BottomBar';
import { DatePickerField } from '../DatePicker';
import { PersonSuggesterField } from '../GraphQLSuggester';
import { useRefLinkAccessor } from '../RefLink';
import { Table } from '../table';
import {
  useDisableSurveyShare,
  useEnableSurveyShare,
  useRemoveSurveyShare,
} from './shareHooks';
import { SurveyCompletionBar } from './SurveyCompletionBar';
import {
  SurveyShareChangeQuotaDialog,
  SurveyShareDialog,
  SurveyShareMessageDialog,
} from './SurveyShareDialogs';
import { SurveyMetric, SurveyMetrics } from './SurveyMetrics';
import { formatPercentage } from 'src/utils';

export type ShareDialog =
  | { type: 'SHARE_DIALOG' }
  | {
      type: 'CHANGE_SURVER_SHARE_QUOTA_DIALOG';
      shareId: string;
      quota: number;
    }
  | {
      type: 'DISABLE_SURVEY_SHARE_MESSAGE';
      shareId: string;
    };

function SmallColorIcon(p: { className: string; icon: IconName }) {
  return <Icon iconSize={12} icon={p.icon} className={p.className} />;
}

function SmallColorTag(p: { className: string; icon: IconName; text: string }) {
  return (
    <Tag
      className="mr-2"
      icon={<SmallColorIcon icon={p.icon} className={p.className} />}
      minimal
    >
      {p.text}
    </Tag>
  );
}

export function completionAccessor(survey: Survey) {
  return <SurveyCompletionBar survey={survey} />;
}

export function metricsAccessor(share: ISurveyShare | Survey) {
  return <SurveyMetrics metrics={share.metrics} />;
}
export function quoteAccessor(share: ISurveyShare) {
  const closes = share.metrics?.closes ?? 0;
  const quota = share.quota ?? closes;
  const progress = closes / quota;
  return (
    <div className="flex items-center">
      <ProgressBar
        className="w-24"
        intent={Intent.PRIMARY}
        value={progress}
        animate={false}
      />
      <div className=" text-gray-600 font-medium px-2">
        {formatPercentage(progress)}
      </div>
    </div>
  );
}

export default function SurveySharesPage(p: { surveyId: string }) {
  const [shareDialog, setShareDialog] = useState<ShareDialog | null>(null);
  const queryClient = useQueryClient();
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const senderAccessor = useRefLinkAccessor('sender', 'person');
  const targetAccessor = useRefLinkAccessor('target', 'person');
  const locationAccessor = (data) =>
    compact([data?.target?.city, data?.target?.province]).join(', ') ?? '-';
  const sentAccessor = useDateAccessor('sentAt', DateTime.DATETIME_MED);

  const typeAccessor = useCallback((share: ISurveyShare) => {
    const isWeb = share.type === SurveyShareType.Web;
    const isEnabled = share.enabled;
    if (!isEnabled) {
      return (
        <SmallColorTag
          className="text-red-600"
          icon="disable"
          text="DISABLED"
        />
      );
    }
    return (
      <SmallColorTag
        className={isWeb ? 'text-green-600' : 'text-blue-400'}
        icon={isWeb ? 'globe' : 'send-message'}
        text={isWeb ? 'WEB' : 'TELEGRAM'}
      />
    );
  }, []);

  const mediumAccessor = useCallback((share: ISurveyShare) => {
    let tags = [];

    if (share.medium?.includes(SurveyShareMedium.Telegram)) {
      tags.push(
        <SmallColorTag
          icon="send-message"
          className="text-blue-400"
          text="TELEGRAM"
        />
      );
    }
    if (share.medium?.includes(SurveyShareMedium.Sms)) {
      tags.push(
        <SmallColorTag
          icon="mobile-phone"
          className="text-green-500"
          text="SMS"
        />
      );
    }
    if (share.medium?.includes(SurveyShareMedium.Email)) {
      tags.push(
        <SmallColorTag
          icon="envelope"
          className="text-yellow-500"
          text="EMAIL"
        />
      );
    }
    return isEmpty(tags) ? <div className="text-gray-400">-</div> : tags;
  }, []);

  const timingsAccessor = useCallback(
    (share: ISurveyShare) => {
      // Safe check.
      if (data.survey == null || share.metrics?.avgDuration == 0) {
        return '-';
      }

      const { minDuration, maxDuration } = data.survey?.metrics ?? {};
      const { avgDuration, stdDuration } = share.metrics ?? {};
      const height = 30;
      const linePadding = 5;
      const avgPosition =
        (avgDuration - minDuration) / (maxDuration - minDuration);
      const stdPosition =
        (stdDuration - minDuration) / (maxDuration - minDuration);
      return (
        <div className="w-24">
          {avgDuration}
          {stdPosition}
          <svg viewBox={`0 0 100 ${height}`}>
            <rect
              width="100"
              height={height}
              style={{ fill: Colors.LIGHT_GRAY2, stroke: 'rgba(0,0,0,0.05)' }}
            />
            <rect
              y={linePadding}
              width={stdPosition * 100}
              height={height - linePadding * 2}
              style={{ fill: Colors.LIGHT_GRAY5 }}
            />

            <line
              x1={avgPosition * 100}
              y1={linePadding}
              x2={avgPosition * 100}
              y2={height - linePadding}
              style={{ stroke: Colors.BLUE3, strokeWidth: 2 }}
            />
          </svg>
        </div>
      );
    },
    [data.survey]
  );

  const devicesAccessor = useCallback((share: SurveyShare) => {
    const { numberOfDevices, metrics } = share;
    const deviceRatio = (metrics?.opens ?? 1) / numberOfDevices;
    const closesRatio = (metrics?.closes ?? 1) / (metrics?.opens ?? 1);
    return (
      <div className="flex items-center">
        {deviceRatio > 0 && (
          <SurveyMetric
            metric={deviceRatio.toFixed(1)}
            icon="desktop"
            color="blue"
            tooltip="Device Interview Ratio"
          />
        )}

        {closesRatio > 0 && (
          <SurveyMetric
            metric={formatPercentage(closesRatio, {
              maximumSignificantDigits: 1,
              minimumSignificantDigits: 1,
            })}
            icon="saved"
            color="green"
            tooltip="Completion Rate"
          />
        )}
        {metrics?.avgDuration > 0 && (
          <SurveyMetric
            metric={`${metrics.avgDuration} ± ${metrics.stdDuration}`}
            icon="time"
            color="blue"
            tooltip="Timings"
          />
        )}
      </div>
    );
  }, []);

  const columns: AppColumn<ISurveyShare>[] = useMemo(
    () => [
      {
        id: 'type',
        width: 50,
        accessor: typeAccessor,
        Header: 'Type',
      },
      // {
      //   id: 'sender',
      //   accessor: senderAccessor,
      //   Header: 'Sender',
      // },
      {
        id: 'person',
        accessor: targetAccessor,
        Header: 'Person',
        width: 80,
      },
      {
        id: 'target',
        accessor: locationAccessor,
        Header: 'Location',
        width: 80,
      },
      {
        id: 'quota',
        accessor: 'quota',
        Header: 'Quote',
        width: 30,
      },
      {
        id: 'progress',
        accessor: quoteAccessor,
        Header: 'Progress',
        width: 70,
      },
      // {
      //   id: 'medium',
      //   accessor: mediumAccessor,
      //   Header: 'Medium',
      //   width: 90,
      // },
      {
        id: 'opens',
        accessor: metricsAccessor,
        Header: 'Stats',
        width: 120,
      },
      {
        id: 'devices',
        accessor: devicesAccessor,
        Header: 'Metrics',
        width: 140,
      },
      // {
      //   id: 'timingsAccessor',
      //   accessor: timingsAccessor,
      //   Header: 'Timings',
      //   width: 200,
      // },

      // {
      //   id: 'sentAt',
      //   accessor: sentAccessor,
      //   Header: 'sentAt',
      // },
    ],
    []
  );
  const [disableAlert, showDisableAlert] = useDisableSurveyShare();
  const [enableAlert, showEnableAlert] = useEnableSurveyShare();
  const [removeAlert, showRemoveAlert] = useRemoveSurveyShare(p.surveyId);
  const contextMenu = useCallback((share: ISurveyShare) => {
    return (
      <Menu>
        <MenuItem2
          icon="clipboard"
          disabled={navigator.clipboard == null}
          text="Copy Link"
          onClick={() => {
            navigator.clipboard.writeText(share.url);
          }}
        />
        <MenuItem2
          icon="globe"
          text="Open Link"
          onClick={() => window.open(share.url, '_blank')}
        />
        <Divider />
        <MenuItem2
          icon="target"
          text="Update Quote"
          onClick={() =>
            setShareDialog({
              type: 'CHANGE_SURVER_SHARE_QUOTA_DIALOG',
              shareId: share.id,
              quota: share.quota,
            })
          }
        />
        <Divider />
        {share.enabled ? (
          <MenuItem2
            icon="disable"
            text="Disable"
            intent={Intent.WARNING}
            onClick={() => showDisableAlert({}, share.id)}
          />
        ) : (
          <MenuItem2
            icon="endorsed"
            text="Enable"
            intent={Intent.PRIMARY}
            onClick={() => showEnableAlert({}, share.id)}
          />
        )}
        {share.enabled && (
          <MenuItem2
            icon="disable"
            text="Disable With Message"
            intent={Intent.WARNING}
            onClick={() =>
              setShareDialog({
                type: 'DISABLE_SURVEY_SHARE_MESSAGE',
                shareId: share.id,
              })
            }
          />
        )}
        <MenuItem2
          icon="trash"
          text="Remove"
          intent={Intent.DANGER}
          onClick={() => showRemoveAlert({}, share.id)}
        />
      </Menu>
    );
  }, []);

  const { table, ref } = usePaginatedTableQuery(
    QUERY.RETRIEVE_SHARES,
    'surveyShares',
    'surveyShare',
    {
      columns,
      variables: { surveyId: p.surveyId },
      contextMenu,
      // onRowClick: () => null,
      showDisclousureIndicator: false,
      emptyView: {
        icon: 'share',
        title: 'No Shares Yet',
        description:
          'You have not shares yet, click the button below to share this survey for the first time',
        action: (
          <Button
            minimal
            intent={Intent.PRIMARY}
            icon="share"
            text="Share Survey"
            onClick={() => setShareDialog({ type: 'SHARE_DIALOG' })}
          />
        ),
      },
      labels: {
        addButton: 'Share Survey',
        itemsLabel: 'share',
      },
    }
  );
  const assignedQuotas =
    queryClient
      .getQueryData<{
        pages: { surveyShares: SurveyShares }[];
      }>(['surveyShares', { surveyId: p.surveyId }])
      ?.pages?.reduce?.((prev, next) => {
        console.log(next);
        return (
          prev +
          (next.surveyShares?.items?.reduce?.((j, share) => {
            return j + share.quota ?? 0;
          }, 0) ?? 0)
        );
      }, 0) ?? 0;
  return (
    <div className="flex flex-col flex-grow" ref={ref}>
      {disableAlert}
      {removeAlert}
      {enableAlert}
      {shareDialog?.type === 'SHARE_DIALOG' && (
        <SurveyShareDialog
          surveyId={p.surveyId}
          onClose={() => setShareDialog(null)}
        />
      )}
      {shareDialog?.type === 'DISABLE_SURVEY_SHARE_MESSAGE' && (
        <SurveyShareMessageDialog
          surveyId={shareDialog.shareId}
          handleClose={() => setShareDialog(null)}
        />
      )}
      {shareDialog?.type === 'CHANGE_SURVER_SHARE_QUOTA_DIALOG' && (
        <SurveyShareChangeQuotaDialog
          shareId={shareDialog.shareId}
          quota={shareDialog.quota}
          onClose={() => setShareDialog(null)}
        />
      )}

      <Table
        className="flex-grow m-3 shadow-sm"
        {...table}
        bottomItems={null}
      />
      <BottomBar>
        <Button icon="blank" className="hidden" text="Test" />
        <div className="flex flex-col items-center flex-grow relative">
          {data.survey.metrics != null && (
            <SurveyMetrics
              metrics={{ ...data.survey.metrics, assignedQuotas }}
            />
          )}
        </div>
        <Button
          icon="share"
          minimal
          intent={Intent.PRIMARY}
          text="Share Survey"
          onClick={() => setShareDialog({ type: 'SHARE_DIALOG' })}
        />
      </BottomBar>
    </div>
  );
}
