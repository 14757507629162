import { uniq } from 'lodash';
import { set, unset, update } from 'lodash/fp';
import { applyNodeChanges, NodeChange } from 'react-flow-renderer';
import { WorkflowState } from '../workflow/WorkflowContext';

export default function changeNodes(
  prev: WorkflowState,
  changes: NodeChange[]
): WorkflowState {
  const prevState = changes.reduce((p, n) => {
    switch (n.type) {
      case 'select':
        if (n.selected === true) {
          return update('selectedNodeIds', (ids: string[]) =>
            uniq([...ids, n.id])
          )(p);
        } else {
          return update('selectedNodeIds', (ids: string[]) => {
            return ids.filter((f) => f !== n.id);
          })(p);
        }
      case 'remove':
        //  Rebuild the index map.
        return set(
          'nodeIndexById',
          p.nodes.reduce(
            (a, b, i) => (b.id !== n.id ? { ...a, [b.id]: i } : a),
            {}
          )
        )(p);

      default:
        return p;
    }
  }, prev);

  return update('nodes', (nodes) => applyNodeChanges(changes, nodes))(
    prevState
  );
}
