import type { Hooks, Meta, RowPropGetter } from 'react-table';
import { useCallback } from 'react';
import { useQuickLook } from 'src/components/QuickLookDrawer';
import { AppTableInstance } from './useAppTable';

export interface WithRowClick<T extends object> {
  onRowClick: (v: T) => void;
}

export function useRowClick<T extends object>(hooks: Hooks<T>) {
  const { quickLookRef } = useQuickLook();
  const getRowProps: RowPropGetter<T> = useCallback((props, meta) => {
    const instance: AppTableInstance<T> = meta.instance;
    return [
      props,
      {
        onClick: (event: React.MouseEvent) => {
          if (instance.onRowClick != null) {
            instance.onRowClick(meta.row.original, event);
          } else {
            quickLookRef(
              {
                kind: instance.routeKey,
                ref: { id: meta.row.original.id },
              },
              instance.quickLookCtx
            );
          }
        },
      } as any,
    ];
  }, []);

  hooks.getRowProps.push(getRowProps);
}
