import React, { createContext, useContext } from "react";
import type { IToastProps } from "@blueprintjs/core";
import { Toaster, Position } from "@blueprintjs/core";

export interface NotificationCtx {
  dismiss: (key: string) => void;
  show: (toast: IToastProps, key?: string) => string;
}

const NotificationContext = createContext<NotificationCtx>(null);
const toaster = Toaster.create({
  position: Position.TOP_RIGHT,
});

export function NotificationProvider(p: { children: JSX.Element }) {
  return (
    <NotificationContext.Provider value={toaster}>
      {p.children}
    </NotificationContext.Provider>
  );
}

export function useNotifications(): NotificationCtx {
  return useContext(NotificationContext);
}
