import { LibraryBlock } from '@keix/workflow-types';

export const library: LibraryBlock[] = [
  {
    name: 'random_with_round',
    uri: 'keix.random_with_round',
    code: ``,
    style: {
      bg: { color: 'blue', opacity: 400 },
      icon: 'random',
    },
    blocks: [
      {
        id: 'b0',
        connections: [],
        uri: 'keix.input',
        parameters: {
          type: 'python.int',
          name: 'digits',
        },
      },
      {
        id: 'b1',
        parameters: {},
        connections: [],
        uri: 'python.random.random',
      },
      {
        id: 'b2',
        parameters: {},
        connections: [
          { from: { id: 'b0' }, to: { port: 'digits' } },
          { from: { id: 'b1' }, to: { port: 'in' } },
        ],
        uri: 'python.round',
      },
      {
        id: 'b3',
        uri: 'keix.output',
        parameters: {
          type: 'python.float',
          name: 'out',
        },
        connections: [{ from: { id: 'b2' }, to: { port: 'in' } }],
      },
    ],
  },
  {
    name: 'graphql',
    uri: 'keix.graphql',
    code: ``,
    arguments: [
      {
        name: 'query',
        type: 'python.string',
        isPort: false,
      },
    ],
    style: {
      icon: 'globe',
      bg: {
        color: 'blue',
        opacity: 500,
      },
    },
  },

];

export default library;
