import React from 'react';
import {
  Block,
  DataTypeDefinition,
  PreviewDataType,
  PyProxy,
} from '@keix/workflow-types';
import { Node } from 'react-flow-renderer';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { NonIdealState } from '@blueprintjs/core';
import { Size } from 'src/utils/size';
import CenteredSpinner from 'src/components/CenteredSpinner';

export function BlockOutputPreviewer(p: {
  type: PreviewDataType;
  node: Node<Block>;
  size: Size;
}) {
  const { dataTypes } = useLibraryContext();
  const { type, size } = p;
  const { data } = p.node;
  const { returnType, state, parameters } = data;

  const returnDataType = dataTypes[data.returnType];
  if (returnDataType == null || returnDataType.preview == null) {
    return (
      <NonIdealState
        icon="data-connection"
        title="Data Type Preview not available"
        description={`The data type ${returnType} does not provide a previewer`}
      />
    );
  }

  return (
    <React.Suspense fallback={<CenteredSpinner />}>
      <BlockPreview
        dataType={returnDataType}
        type={type}
        proxy={state.proxy}
        parameters={parameters}
        size={size}
      />
    </React.Suspense>
  );
}

function BlockPreview(p: {
  dataType: DataTypeDefinition<unknown>;
  proxy: PyProxy;
  type: PreviewDataType;
  parameters: { [key: string]: string };
  size: Size;
}) {
  const { dataType, type, proxy, parameters, size } = p;
  const preview = dataType.preview(proxy, {
    type,
    parameters,
    size,
  });
  return <>{preview}</>
}
