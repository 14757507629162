import React, { useCallback } from 'react';
import { useFieldValue } from 'formik';
import { isLoaded, Loadable } from '../EditableField';
import { Accordion } from '../Accordion';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Expander,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Tag,
} from '@blueprintjs/core';
import CertificateStatusEnumTag from 'src/apps/athena/components/certificates/CertificateStatusEnumTag';
import { Certificate, Query } from 'src/apps/athena/gql-types';
import { useGraphQLFetch } from 'src/utils/graphql';
import RefLink from '../RefLink';
import { useQuery } from '@tanstack/react-query';
import { Ref } from 'src/types/crm';
import { useQuickLook } from '../QuickLookDrawer';
import { Popover2 } from '@blueprintjs/popover2';
import { isEmpty } from 'lodash';

export default function CertificatesDetail(p: { owner: Ref; type: string }) {
  const { t } = useTranslation();
  const [certificates] = useFieldValue<Ref[]>('certificates');
  return (
    <Accordion kind="section" initiallyOpened={true} title={t('Certificates')}>
      <div className="flex flex-col">
        {certificates?.map((c) => (
          <CertificatePreview certificate={c} owner={p.owner.id} />
        ))}
        <Popover2
          position="bottom"
          className="m-1"
          content={<AddCertificateMenu owner={p.owner} type={p.type} />}
        >
          <Button
            fill
            minimal
            icon="plus"
            rightIcon="chevron-down"
            intent={Intent.PRIMARY}
            text="Aggiungi Certificato"
          />
        </Popover2>
      </div>
    </Accordion>
  );
}
function AddCertificateMenu(p: { owner: Ref; type: string }) {
  const graphqlFetch = useGraphQLFetch();
  const { quickAdd } = useQuickLook();

  const { data } = useQuery<Pick<Query, 'competences'>>(['competences'], () =>
    graphqlFetch('query { competences { items { id name type } } }')
  );
  if (isEmpty(data?.competences?.items)) {
    return (
      <Menu>
        <MenuItem disabled text="Loading..." />
      </Menu>
    );
  }
  const competences = data.competences.items.filter(
    (f) => f.type === p.type && f.id.endsWith('ITEC')
  );

  return (
    <Menu>
      {competences.map((c) => (
        <MenuItem
          onClick={() =>
            quickAdd('certificate', {
              type: p.type,
              competence: c.id,
              owner: p.owner,
            })
          }
          icon="endorsed"
          text={
            <span className="text-gray-600">
              Emetti <span className="font-medium primary-text ">{c.name}</span>
            </span>
          }
        />
      ))}
    </Menu>
  );
}
function CertificatePreview(p: { certificate: Ref; owner?: string }) {
  const { quickLookRef } = useQuickLook();
  const graphqlFetch = useGraphQLFetch();

  const query =
    'query Certificate($id: String!) { certificate(id: $id) { id status owner { id name } nextRenewalDate } }';
  const { data } = useQuery<Pick<Query, 'certificate'>>(
    ['certificate', p.certificate.id],
    () => graphqlFetch(query, { id: p.certificate.id })
  );

  const certificate: Loadable<Certificate> = data?.certificate ?? {
    _loading: true,
  };

  const handleClick = useCallback(() => {
    quickLookRef({ kind: 'certificate', ref: p.certificate });
  }, [p.certificate]);

  return (
    <div
      className="flex items-center border-b border-gray-100 px-3 py-1 hover:bg-gray-100 cursor-pointer"
      onClick={handleClick}
    >
      <RefLink kind="certificate" value={p.certificate} />
      <Expander />
      {isLoaded(certificate) &&
        (p.owner != null && certificate.owner.id !== p.owner ? (
          <Tag icon={<Icon icon="git-branch" iconSize={12} />} minimal>
            Variato
          </Tag>
        ) : (
          <CertificateStatusEnumTag minimal value={certificate} />
        ))}
    </div>
  );
}
