import React, { Suspense, useEffect, useMemo } from 'react';
import { useLoginContext } from 'src/context/LoginContext';
import { RouteProps, Navigate, Routes } from 'react-router-dom';

import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import CenteredSpinner from './CenteredSpinner';
import { Routes as IRoutes } from 'src/routes';

export const PublicRoute = Route;

export function AuthRequired(p: { children: JSX.Element }) {
  const { state } = useLoginContext();
  if (state.loggedIn) {
    return p.children;
  } else {
    // return <div>No.</div>;
    return <Navigate to="/login" />;
    // return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
  }
}

export function AppFallback() {
  return <CenteredSpinner />;
}

export function AppContent(p: { routes: IRoutes; setup?: () => void }) {
  const location = useLocation();

  useEffect(p.setup ?? (() => undefined), []);

  const routes = useMemo(
    () =>
      Object.values(p.routes).map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.public ? (
              <route.component />
            ) : (
              <AuthRequired>
                <route.component />
              </AuthRequired>
            )
          }
        />
      )),
    [p.routes]
  );

  return (
    <Suspense fallback={<AppFallback />}>
      <Routes location={location}>{routes}</Routes>
    </Suspense>
  );
}

export function AppRouter(p: { children: any }) {
  return <Router>{p.children}</Router>;
}
