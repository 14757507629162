import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import React, { useEffect } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { Flag } from './Flag';

export function useI18n() {
  const i18n = getI18n();

  return {
    language: i18n.language,
    changeLanguage: (lang: string) => {
      localStorage.setItem('preferredLanguage', lang);
      i18n.changeLanguage(lang);
    },
  };
}

export function useAppLanguage() {
  return getI18n().language;
}

function LanguageMenu() {
  const { t } = useTranslation();
  const { language, changeLanguage } = useI18n();
  return (
    <Menu>
      <MenuItem2
        text="Italiano"
        icon={language === 'it' ? 'tick' : 'blank'}
        labelElement={<Flag countryCode="it" />}
        onClick={() => changeLanguage('it')}
      />
      <MenuItem2
        text="English"
        icon={language === 'en' ? 'tick' : 'blank'}
        labelElement={<Flag countryCode="us" />}
        onClick={() => changeLanguage('en')}
      />
    </Menu>
  );
}

export default function LanguageButton() {
  return (
    <Popover2 content={<LanguageMenu />}>
      <Button minimal icon="globe" />
    </Popover2>
  );
}
