import { Button, Intent } from '@blueprintjs/core';
import React from 'react';
import { WorkflowView } from './types';
import {
  WorkflowAction,
  useWorkflowContext,
} from './WorkflowContext';

export function WorkflowViewPicker() {
  const { state, dispatch } = useWorkflowContext();

  return (
    <div className="absolute z-10 bottom-2 left-1/2 -translate-x-1/2 flex bg-white shadow-md border rounded">
      <Button
        intent={
          state.view === WorkflowView.CANVAS ? Intent.PRIMARY : Intent.NONE
        }
        minimal
        icon="edit"
        text="Editor"
        onClick={() => dispatch({ type: WorkflowAction.SHOW_CANVAS })}
      />
      <Button
        intent={
          state.view === WorkflowView.DASHBOARD ? Intent.PRIMARY : Intent.NONE
        }
        minimal
        icon="dashboard"
        text="Dashboard"
        onClick={() => dispatch({ type: WorkflowAction.SHOW_DASHBOARD })}
      />
    </div>
  );
}
