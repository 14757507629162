import routes, { Routes } from 'src/routes';
import lazy from 'src/utils/lazy';

export default {
  ...routes,
  Home: {
    path: '/',
    exact: true,
    component: lazy(() => import('./pages/Home')),
  },
  Workflow: {
    path: '/workflow/:id',
    component: lazy(() => import('./pages/WorkflowPage')),
  },
  Workflows: {
    path: '/workflows',
    component: lazy(() => import('./pages/Workflows')),
  },
  BookingDashboard: {
    path: '/bookingDashboard',
    component: lazy(() => import('src/pages/BookingDashboardPage')),
  },
  Projects: {
    path: '/projects',
    component: lazy(() => import('src/pages/Projects')),
  },
  Project: {
    path: '/project/:id',
    component: lazy(() => import('src/pages/Project')),
  },
  SurveyTemplates: {
    path: '/surveyWizardTemplates',
    component: lazy(() => import('src/pages/SurveyWizardTemplatesPage')),
  },
  WizardSubscription: {
    path: '/wizardSubscriptions',
    component: lazy(() => import('src/pages/WizardSubscriptionsPage')),
  },
  SurveyWizardHome: {
    path: '/survey',
    component: lazy(() => import('src/pages/SurveyWizard/Home')),
  },
} as Routes;
