import React, { useCallback, useEffect, useState } from 'react';
import {
  IInputGroupProps,
  IHTMLSelectProps,
  IEditableTextProps,
  ICheckboxProps,
  ISwitchProps,
  ITextAreaProps,
  HTMLInputProps,
  INumericInputProps,
  ITagInputProps,
  TagInput,
  Card,
  Icon,
  ButtonGroup,
  IconName,
  MenuItem,
  Button,
  ButtonProps,
  Tag,
} from '@blueprintjs/core';
import {
  TextArea,
  InputGroup,
  NumericInput,
  HTMLSelect,
  EditableText,
  Checkbox,
  Switch,
  Classes,
  Intent,
} from '@blueprintjs/core';
import {
  IItemRendererProps,
  ItemRenderer,
  MultiSelect,
  MultiSelect2,
  Select,
  Select2,
  SelectProps,
} from '@blueprintjs/select';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import css from 'csz';
import { isEmpty } from 'lodash';

import { LocationField } from './LocationField';
import PhoneNumberField from './PhoneNumberField';
import EditableField, { FieldRow, HourAndMinutesField } from './EditableField';
import {
  CompanySuggesterField,
  TreeSuggesterField,
  TreeType,
} from './GraphQLSuggester';
import { DatePickerField } from './DatePicker';
import RefLink from './RefLink';
import { Person } from 'src/apps/athena/gql-types';
import { useQuickLook } from './QuickLookDrawer';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'src/utils/formik';

export function InputGroupField(p: IInputGroupProps & { name: string }) {
  const [field] = useField(p.name);
  return <InputGroup {...p} {...field} id={p.name} />;
}
export function TextAreaField(p: ITextAreaProps & { name: string }) {
  const [field] = useField(p.name);
  return <TextArea {...p} {...field} id={p.id ?? p.name} />;
}

export const NumericInputField = NumericField;
export function NumericField(
  p: HTMLInputProps &
    INumericInputProps & {
      name: string;
      readOnly?: boolean;
      defaultValue?: number;
    }
) {
  const [field, , helper] = useField(p.name);

  // useEffect(() => {
  //   p.defaultValue != undefined ? helper.setValue(p.defaultValue) : null;
  // }, [p.defaultValue]);

  const handleChange = useCallback(
    (value: number) => helper.setValue(value),
    [p.name]
  );

  const value = field.value != null ? field.value : undefined;

  return (
    <NumericInput
      {...p}
      {...field}
      id={p.name}
      value={value}
      onValueChange={handleChange}
    />
  );
}

export function TextField(
  p: IInputGroupProps & HTMLInputProps & { name: string }
) {
  const [field] = useField(p.name);
  return <InputGroup {...p} {...field} id={p.name} />;
}

export function HTMLSelectField(
  p: IHTMLSelectProps & {
    name: string;
    readOnly?: boolean;
    intent?: Intent;
    showUndefinedOption?: boolean;
    placeholder?: string;
  }
) {
  const { intent, ...props } = p;
  const [{ multiple, ...field }] = useField(p.name);
  const className = p.intent === Intent.WARNING ? Classes.INTENT_WARNING : '';

  if (p.readOnly === true) {
    const rawValue = field.value;
    const option = p.options?.find((o) =>
      typeof o === 'object' ? o.value === rawValue : o === rawValue
    );
    const value =
      typeof option === 'object' ? option.label ?? option.value : option;
    return (
      <div className={classNames('px1', Classes.TEXT_MUTED)}>
        {value ?? '-'}
      </div>
    );
  } else {
    const options =
      p.showUndefinedOption || p.placeholder != null
        ? [
            {
              label: p.placeholder ?? '-',
              value: '',
              disabled: true,
            },
            ...(p.options ?? []),
          ]
        : p.options;
    return (
      <HTMLSelect
        className={className}
        required={true}
        {...props}
        {...field}
        value={field.value ?? ''}
        options={options}
        id={p.name}
      />
    );
  }
}

export function SwitchField(
  p: ISwitchProps & { name: string; defaultValue?: number }
) {
  const [field] = useField(p.name);
  return (
    <Switch {...p} {...field} checked={field.value === true} id={p.name} />
  );
}

export type EditableTextFieldProps = IEditableTextProps & {
  name: string;
  kind?: 'string' | 'number';
};
export function EditableTextField(p: EditableTextFieldProps) {
  const [field, meta, helper] = useField(p.name);

  const { t } = useTranslation();
  const onChange = useCallback(
    (val: string) => {
      let value: string | number = val;
      if (p.kind === 'number') {
        value = parseFloat(val);
      }
      helper.setValue(value);
    },
    [p.kind, p.name, meta.initialValue]
  );

  return (
    <div id={p.name}>
      <EditableText
        placeholder={t('Click to edit...')}
        {...p}
        {...field}
        className={`
        px-1
        ${
          p.intent === Intent.WARNING ? 'ring-2 ring-yellow-400 rounded' : ''
        } ${p.className ?? ''}`}
        onConfirm={(value) => {
          onChange(value);
          helper.setTouched(value !== meta.initialValue);
        }}
        onChange={onChange}
      />
    </div>
  );
}

export function ToggleButtonField(p: { name: string } & ButtonProps) {
  const [field, _, meta] = useField<boolean>(p.name);

  return (
    <Button
      {...p}
      active={p.disabled !== true && field.value}
      intent={p.disabled !== true && field.value ? Intent.PRIMARY : Intent.NONE}
      onClick={() => meta.setValue(field.value === true ? false : true)}
    />
  );
}

export function CheckboxField(p: ICheckboxProps & { name: string }) {
  const [field, _, helpers] = useField<boolean>(p.name);
  return (
    <Checkbox
      {...p}
      checked={field.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        helpers.setValue(e.target.checked)
      }
      id={p.name}
    />
  );
}

export function FormGroupList(p: { children: JSX.Element[] }) {
  const className = css`
    display: flex;
    flex-direction: column;
    .${Classes.LABEL} {
      width: 180px;
    }
    .${Classes.FORM_CONTENT} {
      flex-grow: 1;
    }
  `;
  return <div className={className}>{p.children}</div>;
}

export function TagInputField(
  p: Omit<ITagInputProps, 'values' | 'onChange'> & {
    name: string;
    minimal?: boolean;
  }
) {
  const [field, , helpers] = useField<string[]>(p.name);
  const className =
    p.className ??
    (p.minimal
      ? 'border-none shadow-none hover:bg-gray-100 bg-opacity-40'
      : '');
  return (
    <TagInput
      {...p}
      className={className}
      values={!isEmpty(field.value) ? field.value : []}
      onChange={(values: React.ReactNode[]) => {
        helpers.setValue(values as string[]);
        helpers.setTouched(true);
      }}
    />
  );
}

export function EmailAddressField(p: { name: string }) {
  return (
    <div className="flex items-center">
      {/* <HTMLSelectField
        name={`${p.name}.type`}
        className="mr2"
        options={[
          { label: 'offer', value: 'offer' },
          { label: 'order', value: 'order' },
        ]}
      /> */}
      <EditableTextField name={`${p.name}.emailAddress`} />
    </div>
  );
}

export function LocationsField(p: { name: string }) {
  return (
    <div className="flex items-center">
      <HTMLSelectField
        name={`${p.name}.type`}
        className="mr2"
        options={[
          { label: 'residenza', value: 'residence' },
          { label: 'domicilio', value: 'domicile' },
        ]}
      />
      <LocationField
        name={`${p.name}.location`}
        types={['geocode']}
        fields={[
          'formatted_address',
          'geometry.location',
          'address_components',
        ]}
      />
    </div>
  );
}
export function PhoneNumbersField(p: { name: string }) {
  return (
    <div className="flex items-center">
      <HTMLSelectField
        name={`${p.name}.type`}
        className="mr2"
        options={[
          { label: 'cellulare', value: 'cellular' },
          { label: 'casa', value: 'home' },
          { label: 'lavoro', value: 'work' },
        ]}
      />
      <PhoneNumberField name={p.name} defaultCountry="IT" />
    </div>
  );
}
export function WorkExperiencesField(p: { name: string }) {
  const { t } = useTranslation('translation');
  const [field, ,] = useField(p.name);
  const [checkBox, setCheckBox] = useState(false);
  const form = useFormikContext<Person>();
  const { quickLookRef } = useQuickLook();
  const workExperienceCompany = field.value?.company;
  const workExperienceProfession = field.value?.profession;

  useEffect(() => {
    if (field.value?.endDate === 'present') {
      setCheckBox(true);
    }
  }, [form]);

  const company = {
    id: workExperienceCompany?.id,
    name: workExperienceCompany?.name,
  };

  const handleClick = useCallback(() => {
    quickLookRef({
      kind: 'company',
      ref: company,
    });
  }, [workExperienceCompany?.id]);

  const handleCleanCompany = () => {
    form.setFieldValue(`${p.name}.company`, '');
  };

  const handleCleanProfession = () => {
    form.setFieldValue(`${p.name}.profession`, '');
  };

  const handleChangeCheckBox = () => {
    setCheckBox(!checkBox);
    if (checkBox === false) {
      form.setFieldValue(`${p.name}.endDate`, 'present');
    }
  };

  return (
    <Card className={'border border-gray-100 hover:border-blue-300'}>
      <FormGroupList>
        <div className="flex items-center">
          <EditableField
            label={t('Profession')}
            name={`${p.name}.profession`}
            component={TreeSuggesterField}
            componentProps={{ type: TreeType.OCCUPATIONS, minimal: true }}
          />
          {workExperienceProfession?.id ? (
            <Icon
              icon={'cross'}
              color={'red'}
              onClick={handleCleanProfession}
            />
          ) : null}
        </div>
        <div className="flex items-center">
          {!workExperienceCompany?.name ? (
            <EditableField
              label={t('Company')}
              name={`${p.name}.company`}
              component={CompanySuggesterField}
              componentProps={{ minimal: true }}
            />
          ) : (
            <>
              <FieldRow label={t('Company')}>
                <RefLink value={company} kind="company" onClick={handleClick} />
              </FieldRow>
              <Icon icon={'cross'} color={'red'} onClick={handleCleanCompany} />
            </>
          )}
        </div>
        <EditableField
          label={t('Description')}
          name={`${p.name}.description`}
          component={EditableTextField}
        />
        <EditableField
          label={t('Start Date')}
          name={`${p.name}.startDate`}
          component={DatePickerField}
          componentProps={{ maxDate: new Date(), minimal: true }}
        />
        <div className="flex items-center">
          <EditableField
            label={t('End Date')}
            name={`${p.name}.endDate`}
            component={DatePickerField}
            componentProps={{
              disabled: checkBox,
              maxDate: new Date(),
              minimal: true,
            }}
          />
          <Checkbox
            checked={checkBox}
            onChange={handleChangeCheckBox}
            label={t('Present')}
            inline
            alignIndicator={'right'}
          />
        </div>
      </FormGroupList>
    </Card>
  );
}

export function TimelineField(props: { minimal?: boolean; name: string }) {
  const { t } = useTranslation();

  const [fieldStartDate] = useField<string>(`${props.name}.startDate`);
  const TextLabel = (p: { text: string }) => {
    return (
      <div className="pl-12 font-extralight text-sm text-justify ">
        <p>{t(p.text)}</p>
      </div>
    );
  };
  return (
    <ButtonGroup>
      <div className="container">
        <DatePickerField name={`${props.name}.startDate`} minimal />
        <TextLabel text={'start date'} />
      </div>
      <div className="container">
        <DatePickerField
          name={`${props.name}.endDate`}
          minimal
          minDate={new Date(fieldStartDate.value) ?? new Date()}
        />
        <TextLabel text={'end date'} />
      </div>
    </ButtonGroup>
  );
}

export function EstimatedTaskDurationField(props) {
  const [field, ,] = useField(props.name);
  return (
    <>
      <div className="container w-12 border-none">
        <NumericInputField {...props} />
      </div>
      <div className="pl-8 ">
        <HourAndMinutesField minutes={field.value} />
      </div>
    </>
  );
}

export interface SelectOption {
  label: string;
  value: string;
  icon?: IconName | JSX.Element;
}

export function SelectField(props: {
  name: string;
  small?: boolean;
  minimal?: boolean;
  disabled?: boolean;
  className?: string;
  intent?: Intent;
  multiple?: boolean;
  filterable?: boolean;
  unknownOption?: SelectOption;
  valueFormatter?: (opt: SelectOption) => string;
  options?: SelectOption[];
}) {
  const [field, _, helpers] = useField<string>(props.name);
  const [multipleField, __, multipleHelpers] = useFieldArray<string>(
    props.name
  );
  const option =
    props.options?.find((opt) => opt.value === field.value) ??
    props.unknownOption;
  const itemRenderer = useCallback(
    (item: SelectOption, props: IItemRendererProps) => {
      return (
        <MenuItem
          key={props.index}
          active={props.modifiers.active}
          onClick={props.handleClick}
          text={item.label}
          icon={item.icon}
          labelElement={
            option?.value === item.value ? (
              <Icon icon="tick" />
            ) : (
              <React.Fragment />
            )
          }
        />
      );
    },
    [option]
  );
  return props.multiple ? (
    <MultiSelect2<SelectOption>
      selectedItems={
        props.options?.filter((f) => multipleField.value?.includes(f.value)) ??
        []
      }
      items={props.options ?? []}
      itemRenderer={itemRenderer}
      tagRenderer={(item) => item.label ?? item.value}
      onRemove={(_, index) => () => multipleHelpers.remove(index)}
      onItemSelect={(item) => multipleHelpers.push(item.value)}
    />
  ) : (
    <Select2<SelectOption>
      {...props}
      items={props.options ?? []}
      itemRenderer={itemRenderer}
      filterable={props.filterable}
      onItemSelect={(item) => helpers.setValue(item.value)}
    >
      <Button
        minimal={props.minimal}
        intent={props.intent}
        small={props.small}
        disabled={props.disabled}
        className={props.className}
        rightIcon="chevron-down"
        text={props.valueFormatter?.(option) ?? option?.label}
        icon={option?.icon}
      />
    </Select2>
  );
}

export const menuItemRenderer: ItemRenderer<{
  icon?: IconName;
  label?: string;
  description?: string;
  value: string;
}> = (item, props) => {
  return (
    <MenuItem
      text={item.label ?? item?.value}
      icon={item.icon}
      label={item.description}
      onClick={props.handleClick}
      selected={props.modifiers.active}
    />
  );
};
