import { Button, Classes, Dialog } from '@blueprintjs/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { LibraryBlock } from '@keix/workflow-types';
import { BlockEditor } from './BlockEditor';
import { BlockIcon } from './BlockIcon';
import { BlockRepositories } from './BlockRepositories';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';

export default function BlockLibraryDialog() {
  const { state, dispatch } = useWorkflowContext();
  const { isOpen } = state.libraryDialog;
  const [selectedBlock, setSelectedBlock] = useState<LibraryBlock | null>(null);
  const [showRepositories, setShowRepositories] = useState(false);

  return (
    <Dialog
      title="Block Library"
      className="w-11/12"
      icon="book"
      isOpen={isOpen}
      onClose={() => dispatch({ type: WorkflowAction.CLOSE_DIALOG_LIBRARY })}
    >
      <div className="flex -mb-5" style={{ height: '80vh' }}>
        <BlockList
          showRepositories={showRepositories}
          onSelectBlock={setSelectedBlock}
          onToggleShowRepositories={() => setShowRepositories((p) => !p)}
        />
        {showRepositories ? (
          <BlockRepositories />
        ) : (
          <BlockEditor block={selectedBlock} />
        )}
      </div>
    </Dialog>
  );
}

function BlockList(p: {
  showRepositories: boolean;
  onToggleShowRepositories: () => void;
  onSelectBlock: (block: LibraryBlock) => void;
}) {
  const { blocksByUri } = useLibraryContext();
  const blocks = useMemo(() => Object.values(blocksByUri), [blocksByUri]);
  const parentRef = React.useRef();

  const rowVirtualizer = useVirtualizer({
    count: blocks.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 44,
    overscan: 25,
  });
  return (
    <>
      <div className="w-1/3 flex-shrink-0 flex flex-col relative border-r bg-white">
        <div ref={parentRef} className="flex flex-col flex-grow  overflow-scroll">
          <div
            className="divide-y"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const block = blocks[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  className={classNames(
                    'px-2 py-3 flex items-center gap-x-3 hover:bg-gray-50'
                  )}
                  onClick={() => p.onSelectBlock(block)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <BlockIcon {...block.style} />
                  <div className="flex flex-col">
                    <div className="font-semibold -mb-1">{block.name}</div>
                    <div className="font-light text-sm text-gray-400">
                      {block.uri}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sticky bottom-0 left-0 right-0 bg-white border-t px-3 py-2">
          <Button
            fill
            minimal
            icon={p.showRepositories ? 'code-block' : 'git-repo'}
            text={p.showRepositories ? 'Block Editor' : 'Repositories'}
            onClick={p.onToggleShowRepositories}
          />
        </div>
      </div>
    </>
  );
}
