import React, { useRef } from 'react';
import {
  useAppTable,
  AppTableOptions,
  AppTableInstance,
  AppRef,
} from './table/useAppTable';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { compact, flatten, get, isEmpty, last, set } from 'lodash';
import {
  Intent,
  Menu,
  MenuItem,
  MenuDivider,
  NonIdealState,
  Button,
} from '@blueprintjs/core';
import { useSize } from './size';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useGraphQLFetch } from './graphql';
import { useQuickLook } from 'src/components/QuickLookDrawer';
import {
  CLEAR_FILTER,
  CLEAR_FILTERS,
  PUSH_FILTERS,
} from './table/useRemoteFilters';
import { useSendCommandAlert } from './alert';
import { BarItem } from 'src/components/BottomBar';

function isRef<T extends { id: string }>(item: T | AppRef): item is AppRef {
  return item != null && '_ref' in item;
}

// function handleLocationChange<T extends object>(
//   table: AppTableInstance<T>,
//   location: Location,
//   itemDialog: { kind: string; id?: string }
// ) {
//   // If quicklook found.
//   const query = new URLSearchParams(location.search);
//   const url = query.get('quickLook');
//   if (url != null) {
//     // Get the url.
//     try {
//       const [kind, id] = url.split('://');
//       if (itemDialog.kind !== kind || itemDialog.id !== id) {
//         // REplace!
//         table.quickLookRef({ kind, ref: { id } });
//       }
//     } catch {
//       console.error('Cannot parse quicklook query param.');
//     }
//   } else {
//     table.exitQuickLook();
//   }
// }

export function usePaginatedTableQuery<T extends { id: string }>(
  query: string,
  dataKey: string,
  routeKey: string,
  tableOptions: Omit<AppTableOptions<T>, 'data' | 'pageInfo' | 'routeKey'>
) {
  // Some state to manage filters and cursor.
  const navigate = useNavigate();
  const { t } = useTranslation([
    tableOptions.translationKey ?? dataKey,
    'translation',
  ]);
  const [filters, applyFilters] = useState();

  const [removeAlert, showRemoveAlert] = useSendCommandAlert({
    icon: 'trash',
    intent: Intent.DANGER,
    message: t('Are you sure to delete this item?'),
    confirmButtonText: t('Delete it'),
    cancelButtonText: t('Cancel'),
    command: tableOptions.removeCommand ?? { query: '', handler: () => { } },
  });

  const [_, setData] = useState<T[]>([]);
  const shouldReset = useRef(false);
  const [showNewDialog, setShowNewDialog] = useState<string | null>(null);
  const [size, ref] = useSize();
  const variables = tableOptions.variables ?? {};

  const fetch = useGraphQLFetch();
  const hasFilters = !isEmpty(filters);
  const { quickLookRef, quickAdd } = useQuickLook();

  // React user query.
  const queryKey = compact([dataKey, variables, filters]);
  const result = useInfiniteQuery(
    queryKey,
    (context) => {
      return fetch(query, {
        ...variables,
        filters: filters != null ? filters : undefined,
        cursor: context.pageParam,
      });
    },
    {
      getNextPageParam: (page) => get(page, `${dataKey}.pageInfo.endCursor`),
      refetchInterval: tableOptions?.query?.refetchInterval,
    }
  );

  // Unpack the data from the query.
  const pageInfo = get(last(result.data?.pages), `${dataKey}.pageInfo`) ?? {
    total: 25,
  };
  const data: T[] = useMemo(
    () =>
      flatten(
        result.data?.pages
          .map((d) => get(d, `${dataKey}.items`))
          .filter((f) => f != null) ?? []
      ),
    [result.data?.pages]
  );

  // On change filters, reset data to empty.
  useEffect(() => {
    setData([]);
    // shouldReset.current = true;
  }, [filters]);

  const defaultContextMenu = useCallback(
    (d: T, instance: AppTableInstance<T>) => {
      return (
        <Menu>
          <MenuItem
            icon="document"
            text={t('translation:Open...')}
            onClick={() => navigate(`/${routeKey}/${d.id}`)}
          />
          <MenuItem
            icon="document-open"
            text={t('translation:Open in a new page...')}
            onClick={() => window.open(`/${routeKey}/${d.id}`, '_blank')}
          />
          <MenuItem
            icon="eye-open"
            text={t('translation:Quick Look')}
            onClick={() =>
              quickLookRef(
                { kind: routeKey, ref: { id: d.id } },
                tableOptions.quickLookCtx
              )
            }
          />
          {tableOptions.removeCommand != null && (
            <>
              <MenuDivider />
              <MenuItem
                icon="trash"
                intent={Intent.DANGER}
                text={t('Remove', { ns: dataKey })}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  showRemoveAlert({}, d);
                }}
              />
            </>
          )}
        </Menu>
      );
    },
    []
  );

  const contextMenu = tableOptions.contextMenu ?? defaultContextMenu;

  const cache = useMemo(
    () => ({
      prependItem: (item: T) => setData((prev) => [item, ...prev]),
      appendItem: (item: T) => setData((prev) => [...prev, item]),
      deleteItem: (item: T) =>
        setData((prev) => prev.filter((p) => p.id !== item.id)),
      updateItem: (item: T) =>
        setData((prev) => prev.map((p) => (p.id === item.id ? item : p))),
      upsertItem: (item: T, prepend: boolean = true) =>
        setData((prev) => {
          const index = prev.findIndex((p) => p.id === item.id);
          if (index >= 0) {
            return set(prev, `${index}`, item);
          } else {
            return prepend ? [item, ...prev] : [...prev, item];
          }
        }),
    }),
    []
  );

  const table = useAppTable({
    data,
    pageInfo,
    contextMenu,
    onApplyFilters: applyFilters,
    onNextCursor: result.fetchNextPage,
    showDisclousureIndicator: true,
    routeKey,
    ...tableOptions,
  });

  // Default Bottom items.
  const bottomItems: BarItem[] = [
    {
      text: tableOptions?.labels?.filterButton ?? t('translation:Filters'),
      minimal: true,
      icon: 'filter-list',
      onClick: () => table.dispatch({ type: 'OPEN_FILTERS_DIALOG' }),
    },
    // {
    //   text: t('translation:Manage View'),
    //   minimal: true,
    //   rightIcon: 'double-caret-vertical',
    //   menu: (
    //     <Menu>
    //       <MenuItem text="Save view..." icon="floppy-disk" />
    //       <MenuItem text="Make this view default" icon="bookmark" />
    //       <MenuDivider />
    //       <MenuItem text="Export to..." icon="export" />
    //     </Menu>
    //   ),
    //   icon: 'list-detail-view',
    //   // onClick: () => table.dispatch({ type: 'OPEN_FILTERS_DIALOG' }),
    // },
    { expand: true },
    {
      text: `${pageInfo.total} ${tableOptions?.labels?.itemsLabel ?? 'items'}`,
      label: true,
    },
    { expand: true },
    {
      text: tableOptions?.labels?.addButton ?? t('Add', { ns: dataKey }),
      minimal: true,
      icon: 'plus',
      intent: Intent.PRIMARY,
      onClick: () => {
        if (tableOptions?.onAddClick != null) {
          tableOptions.onAddClick();
        } else {
          quickAdd(routeKey, tableOptions.quickLookCtx)
        }
      }
    },
  ];

  const defaultEmptyIcon = hasFilters ? 'filter-remove' : 'database';
  const defaultEmptyTitle = hasFilters ? t('No Matches') : t('No Data');
  const defaultEmptyDescription = hasFilters
    ? t(
      'No data matched your query, try a different query or reset the filters'
    )
    : t('Add your first record, click on the bottom right plus button');
  const defaultEmptyAction = hasFilters && (
    <Button
      intent={Intent.PRIMARY}
      icon="filter-remove"
      minimal
      text={t('Remove Filters')}
      onClick={() => table.dispatch({ type: CLEAR_FILTERS })}
    />
  );
  const emptyView = table.rows.length === 0 && (
    <NonIdealState
      icon={tableOptions.emptyView?.icon ?? defaultEmptyIcon}
      title={tableOptions.emptyView?.title ?? defaultEmptyTitle}
      description={
        tableOptions.emptyView?.description ?? defaultEmptyDescription
      }
      action={tableOptions.emptyView?.action ?? defaultEmptyAction}
    />
  );

  const { removeCommand } = tableOptions;
  const dialogs = <>{removeAlert}</>;
  const filtersAction = useMemo(
    () => ({
      clear: (id: string) => {
        const column = table.columns.find((f) => f.id === id);
        if (!column) {
          console.warn('Cannot find column with id', id);
          return;
        }
        table.dispatch({ type: CLEAR_FILTER, column });
      },

      push: (id: string, filters: { op: string; value: string }[]) => {
        const column = table.columns.find((f) => f.id === id);
        if (!column) {
          console.warn('Cannot find column with id', id);
          return;
        }
        table.dispatch({ type: PUSH_FILTERS, op: 'and', column, filters });
      },
    }),
    [table]
  );

  return {
    ref,
    cache,
    data,
    pageInfo,
    filters: filtersAction,
    dialogs,
    table: { table, bottomItems, size, emptyView, filters },
  };
}
