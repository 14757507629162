import { Expander, Icon, IconName } from '@blueprintjs/core';
import classNames from 'classnames';
import { useField, useFieldValue } from 'formik';
import { isArray, last, times } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Question,
  QuestionScaleKind,
  QuestionSettings,
  QuestionType,
} from 'src/apps/athena/gql-types';
import { useFieldHelpers } from 'src/utils/formik';
import {
  EditableTextField,
  HTMLSelectField,
  NumericInputField,
  SelectField,
  SelectOption,
} from '../BlueprintFields';

export function QuestionScaleField(p: { isEditingTranslations: boolean }) {
  const { t } = useTranslation('survey');
  const [typeValue] = useFieldValue('type');
  const settingsHelper = useFieldHelpers<QuestionSettings>('settings');
  const type: QuestionType = typeValue;

  useEffect(() => {
    if (type === QuestionType.OpinionScale) {
      settingsHelper.updateValue((prev) => ({
        ...prev,
        scaleKind: prev.scaleKind ?? QuestionScaleKind.Numeric,
        scaleSize: prev.scaleSize ?? 10,
      }));
    } else {
      settingsHelper.updateValue((prev) => ({
        ...prev,
        scaleKind: null,
        scaleSize: null,
      }));
    }
  }, [type]);

  // Safe check.
  if (type !== QuestionType.OpinionScale) {
    return <React.Fragment />;
  }

  const scaleKindOptions: SelectOption[] = Object.keys(QuestionScaleKind).map(
    (k) => ({
      label: t(`questionScaleKind.${k.toUpperCase()}`),
      value: QuestionScaleKind[k],
      icon: iconForScaleKind(QuestionScaleKind[k]),
    })
  );
  return (
    <div className="flex flex-col items-stretch">
      <div className="flex">
        <SelectField
          filterable={false}
          minimal
          disabled={p.isEditingTranslations}
          name="settings.scaleKind"
          options={scaleKindOptions}
        />
        <Expander />
        <NumericInputField
          disabled={p.isEditingTranslations}
          name="settings.scaleSize"
        />
      </div>
      <div className="flex mt-3">
        <QuestionOpinionScaleField />
      </div>
    </div>
  );
}

function QuestionOpinionScaleField() {
  const [type] = useFieldValue('type');
  const [field] = useField<QuestionSettings>('settings');
  const defaultSettings: QuestionSettings = {
    scaleSize: 10,
    scaleKind: QuestionScaleKind.Numeric,
  };
  const question = { type, settings: field.value ?? defaultSettings };
  return <QuestionOpinionScale question={question} editing={true} />;
}

export function scaleItemForIndex(index: number, kind: QuestionScaleKind) {
  const emoji = ['😞', '😟', '😐', '😃', '😍'];
  if (kind === QuestionScaleKind.Numeric) {
    return index + 1;
  } else if (kind === QuestionScaleKind.Emoji) {
    return emoji[index];
  } else if (kind === QuestionScaleKind.Stars) {
    return '⭐️';
  }
}

function QuestionOpinionScaleItem(p: {
  index: number;
  className?: string;
  hover?: number;
  value?: string;
  scaleKind: QuestionScaleKind;
}) {
  const emoji = ['😞', '😟', '😐', '😃', '😍'];
  switch (p.scaleKind) {
    case QuestionScaleKind.Numeric:
      return (
        <div className={`${p.className ?? ''} text-lg sm:text-xl`}>
          {p.index + 1}
        </div>
      );
    case QuestionScaleKind.Emoji:
      return (
        <div className={`${p.className ?? ''} text-3xl`}>
          {emoji[p.index] ?? ''}
        </div>
      );
    case QuestionScaleKind.Stars:
      let icon: IconName = 'star-empty';

      if (p.value != null) {
        const index = parseInt(p.value);
        icon = index > p.index ? 'star' : 'star-empty';
      } else if (p.hover >= 0) {
        icon = p.hover >= p.index ? 'star' : 'star-empty';
      }
      const color = icon === 'star' ? 'text-yellow-500' : 'text-gray-500';

      return (
        <div className={`${p.className ?? ''} ${color}`}>
          <Icon icon={icon} iconSize={20} />
        </div>
      );
  }
  return <React.Fragment />;
}

export function QuestionOpinionScale(p: {
  question: Pick<Question, 'type' | 'settings'>;
  className?: string;
  editing?: boolean;
  hover?: number;
  value?: string | string[];
  onSelectValue?: (value?: string) => void;
  galleryMode?: boolean;
}) {
  const { type, settings } = p.question;
  const { scaleKind, scaleSize, labels } = settings ?? {};
  const [disabled, setDisabled] = useState(false);
  if (type !== QuestionType.OpinionScale) {
    return <React.Fragment />;
  }

  useEffect(() => {
    setDisabled(true);
    const delay = 500 + Math.random() * 1000;
    const interval = setInterval(() => {
      setDisabled(false);
    }, delay);
    return () => clearInterval(interval);
  }, [p.value]);

  const { t } = useTranslation('survey');
  const value = isArray(p.value) ? undefined : p.value;
  const enableUndefinedOption = settings?.enableUndefinedOption ?? false;
  const values = times(scaleSize ?? 5, (index) => `${index + 1}`);
  const options = times(scaleSize ?? 5, (index) => (
    <button
      onClick={() => p.onSelectValue?.(values[index])}
      className={classNames(
        'flex-grow h-10 group hover:bg-gray-100 outline-none transform transition-all cursor-pointer flex flex-col justify-center items-center  bg-opacity-25',
        {
          // 'rounded-l': !enableUndefinedOption && index === 0,
          // 'rounded-r': !enableUndefinedOption && index === scaleSize - 1,
          // 'border-l-0': index > 0,
          'bg-blue-500 bg-opacity-100': values[index] === value,
          'bg-blue-200': p.hover === index,
        }
      )}
    >
      <QuestionOpinionScaleItem
        className={classNames(
          'transition duration-300  group-hover:scale-125 group-hover:text-primary',
          {
            'transform scale-75': value != null && values[index] !== value,
            'transform scale-110': values[index] === value,
          }
        )}
        index={index}
        hover={p.hover}
        value={value}
        scaleKind={scaleKind}
      />
    </button>
  ));
  return (
    <div
      className={classNames(
        'py-2 transform transition-all  flex flex-grow flex-col items-stretch',
        {
          'opacity-30 pointer-events-none scale-90': disabled,
        }
      )}
    >
      <div className="flex py-2  text-sm font-medium text-gray-400">
        {p.editing ? (
          <EditableTextField name="settings.labels.lowScale" />
        ) : (
          labels?.lowScale
        )}
        <Expander />
        {p.editing ? (
          <EditableTextField name="settings.labels.mediumScale" />
        ) : (
          labels?.mediumScale
        )}
        <Expander />

        {p.editing ? (
          <EditableTextField name="settings.labels.highScale" />
        ) : (
          labels?.highScale
        )}
      </div>
      <div className="flex divide-x-2 border-2 rounded">{options}</div>
      {enableUndefinedOption && (
        <div
          onClick={() => p.onSelectValue?.('-1')}
          className={classNames(
            'border-2 border-t-0 group hover:bg-gray-100 rounded-b text-center py-2 text-sm bg-opacity-25 font-medium cursor-pointer',
            {
              'bg-blue-200': p.hover === options.length,
              'bg-blue-500 bg-opacity-100 text-white': '-1' === value,
            }
          )}
        >
          {p.editing ? (
            <EditableTextField
              className="text-lg mt-1"
              name="settings.labels.undefinedScaleOption"
              placeholder="Prefer not to say"
            />
          ) : (
            <div className="group-hover:scale-110 transition group-hover:text-primary">
              {labels?.undefinedScaleOption ?? t('Not Specified')}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function iconForScaleKind(kind: QuestionScaleKind): IconName {
  switch (kind) {
    case QuestionScaleKind.Emoji:
      return 'emoji';
    case QuestionScaleKind.Numeric:
      return 'numerical';
    case QuestionScaleKind.Stars:
      return 'star';
  }
}
