import React from 'react';
import { Block } from '@keix/workflow-types';
import { Node } from 'react-flow-renderer';
import { Accordion } from 'src/components/Accordion';
import Editor from '@monaco-editor/react';
import { isEmpty } from 'lodash';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';
import { NonIdealState } from '@blueprintjs/core';

export default function BlockCode(p: { node: Node<Block> | null }) {
  const { node } = p;
  const { id, data } = node;
  const { dispatch } = useWorkflowContext();

  if (isEmpty(data.code)) {
    return (
      <NonIdealState
        icon="code"
        title="Code not available"
        description="This block does not provide custom python code, it only use an available library."
      />
    );
  }
  return (
    <Accordion initiallyOpened={true} kind="section" title="Code">
      <Editor
        height="40vh"
        theme="vs-dark"
        options={{
          extraEditorClassName: 'pt-2',
          minimap: { enabled: false },
        }}
        onChange={(code) =>
          dispatch({
            type: WorkflowAction.SET_NODE_EXECUTION_CODE,
            id,
            code,
          })
        }
        defaultValue={p.node.data.code}
        language="python"
      />
    </Accordion>
  );
}
