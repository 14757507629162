import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';

export function ValidationDebug<T>(p: { errors: FormikErrors<T> }) {
  return isEmpty(p.errors) ? (
    <React.Fragment />
  ) : (
    <div className="flex flex-col">{JSON.stringify(p.errors)}</div>
  );
}
