import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { Query, Upload } from 'src/apps/athena/gql-types';
import css from 'csz';
import {
  ButtonGroup,
  Button,
  Classes,
  Intent,
  Spinner,
  MenuItem,
  EditableText,
  MenuDivider,
  IBreadcrumbsProps,
  IBreadcrumbProps,
  Breadcrumbs,
  Breadcrumb,
  IconName,
  Icon,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import UploadDialog from './UploadDialog';
import { useSendCommand } from 'src/utils/events';
import FileDropzone from './FileDropzone';
import { useUploader } from 'src/utils/useUploader';
import classNames from 'classnames';
import { useContextMenu } from 'src/utils/useContextMenu';
import { useAlert } from 'src/utils/alert';
import { useGraphQLFetch } from 'src/utils/graphql';
import {
  updatePaginatedQueryData,
  deleteItemOnPaginatedQueryData,
} from 'src/utils/queryCache';
import { string } from 'yup';

const className = css`/styles/upload.css`;
const UPLOAD_TYPES_QUERY = `query { uploadTypes { items { id name deletedAt } } }`;
const QUERY =
  'query($res: String!, $filters: JSON) { uploadsByResource(resource: $res, filters: $filters) { items { id uri name resources date tags type } } }';
interface P {
  editable: boolean;
  uri: string;
}

enum DocumentView {
  ICON,
  LIST,
}

function RenameTitle(p: {
  upload: Upload;
  renaming: boolean;
  onRenamed: () => void;
}) {
  const { id, type } = p.upload;
  const ref = useRef<any>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(p.upload.name);
  const handleConfirm = useCallback(
    async (value: string) => {
      await sendRename({ id, value });
      p.onRenamed();
    },
    [id]
  );

  useEffect(() => {
    if (p.renaming) {
      ref.current?.toggleEditing();
    }
  }, [p.renaming]);

  const queryKeys = p.upload.resources?.map((r) => getQueryKey(r, type)) ?? [];
  const [state, sendRename] = useSendCommand<{}, { id: string; value: string }>(
    'mutation Rename($id: String!, $value: String!) { renameUpload(id: $id, name: $value) }',
    (evt, ctx) => {
      if (evt.type === 'UPLOAD_RENAMED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Document Renamed'),
        });
      }
    },
    {
      onMutate: ({ value, id }) => {
        queryKeys.forEach((k) =>
          updatePaginatedQueryData(
            queryClient,
            k,
            'uploadsByResource',
            (u: Upload) =>
              u.id === id
                ? {
                  ...u,
                  name: value,
                }
                : u
          )
        );
      },
      onSettled: () =>
        queryKeys.forEach((k) => queryClient.invalidateQueries(k)),
    }
  );

  return (
    <div className="flex">
      <EditableText
        ref={ref}
        selectAllOnFocus={true}
        confirmOnEnterKey={true}
        disabled={!p.renaming}
        multiline={p.renaming}
        value={value}
        onConfirm={handleConfirm}
        onChange={setValue}
      />
    </div>
  );
}

function Document(p: { upload: Upload }) {
  const [renaming, setRenaming] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleClick = useCallback(() => {
    window.open(p.upload.uri, '_blank');
  }, [p.upload.uri]);

  const queryKeys = p.upload.resources?.map((r) =>
    getQueryKey(r, p.upload.type)
  );
  const [, removeUpload] = useSendCommand<{}, { id: string }>(
    'mutation Remove($id: String!) { removeUpload(id: $id) }',
    (evt, ctx) => {
      if (evt.type === 'UPLOAD_REMOVED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Document Deleted'),
        });
      }
    },
    {
      onMutate: ({ id }) => {
        queryKeys.forEach((k) =>
          deleteItemOnPaginatedQueryData(
            queryClient,
            k,
            'uploadsByResource',
            (n: Upload) => n.id === id
          )
        );
      },
      onSettled: () =>
        queryKeys.forEach((k) => queryClient.invalidateQueries(k)),
    }
  );

  const [alert, showAlert] = useAlert({
    confirmButtonIcon: 'tick',
    confirmButtonText: t('Delete Document'),
    message: t('Delete Document Message'),
    intent: Intent.DANGER,
    icon: 'trash',
    onConfirm: () => removeUpload({ id: p.upload.id }),
  });

  const contextMenu = useContextMenu(
    <>
      <MenuItem text={p.upload.name} disabled />
      <MenuDivider />
      <MenuItem
        icon="document-open"
        text={t('Open...')}
        onClick={handleClick}
      />
      {/* <MenuItem
        icon="cloud-download"
        text={t("Download")}
        onClick={handleDownload}
      /> */}
      <MenuItem
        icon="edit"
        text={t('Rename...')}
        onClick={() => setRenaming(true)}
      />
      <MenuDivider />
      <MenuItem
        icon="trash"
        intent={Intent.DANGER}
        text={t('Delete..')}
        onClick={() => showAlert()}
      />
    </>
  );

  return (
    <>
      <div className="upload-item" onClick={handleClick} {...contextMenu}>
        <div className="upload-item-icon">
          <img src="/assets/BOH.png" />
        </div>
        <div className="upload-item-title">
          <RenameTitle
            upload={p.upload}
            renaming={renaming}
            onRenamed={() => setRenaming(false)}
          />
        </div>
      </div>
      {alert}
    </>
  );
}

function Folder(p: { folder: string; onClick: () => void }) {
  return (
    <div className="upload-item" onClick={p.onClick}>
      <div className="upload-item-icon">
        <img loading="lazy" src="/assets/Folder.png" />
      </div>
      <div className="upload-item-title">{p.folder}</div>
    </div>
  );
}

type Uploads = Pick<Query, 'uploadsByResource'>;

function DocumentFolders(p: {
  uri: string;
  path: string;
  onChangePath: (path: string) => void;
}) {
  const { data, isLoading } = useUploadTypes();

  const { path, onChangePath } = p;
  const items = data?.uploadTypes.items ?? [];
  const folders = useMemo(() => {
    const prefix = path === '' ? path : `${path}/`;
    const itemsWithPath = items
      .filter((i) => {
        return (
          i.name.startsWith(prefix) &&
          i.name.split('/').length === prefix.split('/').length
        );
      })
      .map((i) => ({
        ...i,
        folder: i.name.replace(prefix, '').split('/').slice(0, 1).join(''),
      }));
    return itemsWithPath;
  }, [items, path]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {path.length > 0 && (
        <Folder
          folder=".."
          onClick={() => onChangePath(path.split('/').slice(0, -1).join(''))}
        />
      )}
      {folders.map((item) => (
        <Folder
          key={item.id}
          folder={item.folder}
          onClick={() => onChangePath(item.name)}
        />
      ))}
      <DocumentItems uri={p.uri} type={path} />
    </>
  );
}

type T = Pick<Query, 'uploadTypes'>;

function useUploadTypes() {
  const graphqlFetch = useGraphQLFetch();
  return useQuery<Pick<Query, 'uploadTypes'>>(
    ['uploadTypes'],
    () => graphqlFetch(UPLOAD_TYPES_QUERY) as any
  );
}

export function getQueryKey(uri: string, type: string) {
  return ['uploadsByResource', uri, type];
}

function useUploads(uri: string, type: string) {
  const graphqlFetch = useGraphQLFetch();
  return useQuery<Pick<Query, 'uploadsByResource'>>(
    getQueryKey(uri, type),
    () =>
      graphqlFetch(QUERY, {
        res: uri,
        filters: {
          op: 'and',
          filters: [
            { attributeName: 'type', filters: [{ op: '=', value: type }] },
          ],
        },
      }) as any
  );
}

export default function Documents(p: P) {
  const [documentView, setDocumentView] = useState(DocumentView.ICON);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [itemToUpload, setItemToUpload] = useState<Partial<{
    uri: string[];
    name: string[];
  }> | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [path, setPath] = useState('');

  const upload = useUploader();

  const handleUploadItem = useCallback((item: Upload) => {
    // waitForIndex<Uploads>(client, QUERY, { res: p.uri }, (uploads) => {
    //   return uploads.uploadsByResource.items.findIndex((u) => u.id === item.id) >= 0;
    // });
  }, []);

  const handleClose = useCallback(() => {
    setUploadOpen(false);
    setItemToUpload(null);
  }, []);

  const handleUploadFiles = useCallback(async (files: File[]) => {
    alert(
      'Il caricamento file con il trascinamento è temporanemante disabilitato, utilizzare il pulsante Upload File in basso.'
    );
    // const file = files[0];
    // if (file == null) {
    //   return;
    // }
    // setUploading(true);
    // const { url: uri, name } = await upload(file, setUploadProgress);
    // setUploading(false);
    // setItemToUpload({ uri: [ uri ], name });
    // setUploadOpen(true);
  }, []);

  const breadcrumbs: IBreadcrumbProps[] = [
    { icon: 'home', text: 'Root', onClick: () => setPath('') },
    ...(path.length > 0
      ? path.split('/').map((p, index, tot) => ({
        icon: 'folder-close' as IconName,
        onClick: () => setPath(tot.slice(0, index + 1).join('/')),
        text: p,
      }))
      : []),
  ];
  const breadcrumbRenderer = useCallback((props: IBreadcrumbProps) => {
    return <Breadcrumb {...props} />;
  }, []);

  return (
    <div className={className}>
      <div className="documents">
        <Breadcrumbs
          currentBreadcrumbRenderer={breadcrumbRenderer}
          items={breadcrumbs}
        />
        <FileDropzone
          className="document-dropzone"
          uploading={uploading}
          uploadProgress={uploadProgress}
          onUploadFiles={handleUploadFiles}
        >
          <div
            className={classNames('upload-items', {
              icon: documentView === DocumentView.ICON,
              list: documentView === DocumentView.LIST,
            })}
          >
            <DocumentFolders uri={p.uri} path={path} onChangePath={setPath} />
          </div>
        </FileDropzone>
        <div className="documents-actions">
          <Button
            minimal
            intent={Intent.PRIMARY}
            icon="cloud-upload"
            text="Upload File..."
            onClick={() => setUploadOpen(true)}
          />
          <div className="flex-grow" />
          <ButtonGroup minimal className={Classes.SMALL}>
            <Button
              active={documentView === DocumentView.LIST}
              icon="list"
              text="List"
              onClick={() => setDocumentView(DocumentView.LIST)}
            />
            <Button
              active={documentView === DocumentView.ICON}
              icon="layout-grid"
              text="Grid"
              onClick={() => setDocumentView(DocumentView.ICON)}
            />
          </ButtonGroup>
        </div>
      </div>
      <UploadDialog
        {...itemToUpload}
        resources={[p.uri]}
        type={path}
        isOpen={isUploadOpen}
        onUploadItem={handleUploadItem}
        onClose={handleClose}
      />
    </div>
  );
}

function DocumentItems(p: { uri: string; type: string }) {
  const { isFetched, data, isLoading } = useUploads(p.uri, p.type);
  // Do not show anything on load.
  return isLoading ? (
    <span />
  ) : (
    <>
      {data?.uploadsByResource.items.map((p) => (
        <Document key={p.id} upload={p} />
      ))}
    </>
  );
}
