import React, { useState, useEffect } from "react";
import { Dialog, Classes, Tag, Intent } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { TagDefinition, EnumTag } from "./EnumTag";
import { useEnvironment } from "src/context/EnvironmentContext";
import { useFetch } from "react-hooks-async";
import { DateTime } from "luxon";
import { last } from "lodash";
import { useNotifications } from "src/context/NotificationContext";

const CHANGELOG_READ_KEY = "lastChangeLogDate";

interface ChangeLog {
  releases: ChangeLogRelease[];
}

interface ChangeLogRelease {
  name: string;
  emoji?: string;
  version: string;
  date: string;
  changelog: {
    kind: ChangeKind;
    description: string;
  }[];
}

enum ChangeKind {
  FEATURE = "FEATURE",
  IMPROVEMENT = "IMPROVEMENT",
  FIXED = "FIXED",
}

const items: TagDefinition<ChangeKind>[] = [
  {
    intent: Intent.SUCCESS,
    value: ChangeKind.FEATURE,
    icon: "tag",
    label: "Feature",
  },
  {
    intent: Intent.PRIMARY,
    value: ChangeKind.IMPROVEMENT,
    icon: "key-shift",
    label: "Improvement",
  },
  {
    intent: Intent.WARNING,
    value: ChangeKind.FIXED,
    icon: "build",
    label: "Resolved",
  },
];

export function ChangelogDialog() {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const { APP_CHANGELOG } = useEnvironment();
  const toast = useNotifications();
  const res = useFetch<ChangeLog>(APP_CHANGELOG);
  const changelog = res.result ?? { releases: [] };

  // This gets
  useEffect(() => {
    // Get the last release, if none simply return.
    const lastRelease = last(changelog.releases);
    if (lastRelease == null) {
      return;
    }

    const lastChangeLogDate = localStorage.getItem(CHANGELOG_READ_KEY);
    if (
      lastChangeLogDate == null ||
      new Date(lastChangeLogDate) < new Date(lastRelease.date)
    ) {
      toast.show({
        message: t("New Version Released"),
        icon: "history",
        intent: Intent.PRIMARY,
        action: {
          text: t("See more"),
          onClick: () => setIsShown(true),
        },
      });

      localStorage.setItem(CHANGELOG_READ_KEY, new Date().toISOString());
    }
  }, [changelog]);

  return (
    <Dialog
      isOpen={isShown}
      title={t("Whats's New")}
      icon="history"
      onClose={() => setIsShown(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        {changelog.releases.map((release) => (
          <Release release={release} />
        ))}
      </div>
    </Dialog>
  );
}
function Change(p: { kind: ChangeKind; description: string }) {
  return (
    <div className="flex items-center mb1">
      <EnumTag minimal uppercase value={p.kind} items={items} />
      <div className="flex-grow" />
      <div className="ml1">{p.description}</div>
    </div>
  );
}

function Release(p: { release: ChangeLogRelease }) {
  return (
    <div className="flex flex-column border-bottom py2">
      <div className="flex items center mb2 ">
        <span className="h2">{p.release.emoji ?? "📌"}</span>
        <h3 className="m0 ml1">Version {p.release.version}</h3>
        <div className="flex-grow" />
        <span className="italic seconary-text">
          {DateTime.fromISO(p.release.date).toRelative()}
        </span>
      </div>
      <div className="flex flex-column">
        {p.release.changelog.map((c) => (
          <Change {...c} />
        ))}
      </div>
    </div>
  );
}
