import { isEmpty, reverse, uniq, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { Hooks, actions } from 'react-table';
import {
  AppTableState,
  AppTableInstance,
  AppColumnInstance,
  AppColumnFilter,
  FilterKind,
} from './useAppTable';

export const OPEN_FILTERS_DIALOG = 'OPEN_FILTERS_DIALOG';
export const CLOSE_FILTERS_DIALOG = 'CLOSE_FILTERS_DIALOG';
export const PUSH_FILTER = 'PUSH_FILTER';
export const PUSH_FILTERS = 'PUSH_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export type Action<T extends object> =
  | {
      type: 'OPEN_FILTERS_DIALOG';
    }
  | {
      type: 'CLOSE_FILTERS_DIALOG';
    }
  | {
      type: 'PUSH_FILTER';
      column: AppColumnInstance<T>;
      op: string;
      value: string;
    }
  | {
      type: 'PUSH_FILTERS';
      column: AppColumnInstance<T>;
      filters: {
        op: string;
        value: string;
      }[];
    }
  | {
      type: 'CLEAR_FILTERS';
    }
  | {
      type: 'CLEAR_FILTER';
      column: AppColumnInstance<T>;
    };

function reducer<T extends object>(
  state: AppTableState<T>,
  action: Action<T>,
  previousState,
  instance: AppTableInstance<T>
) {
  if (action.type === actions.init) {
    return {
      ...state,
      filters: undefined,
      filteredColumns: [],
      isFiltersDialogOpen: false,
    };
  }
  if (action.type === OPEN_FILTERS_DIALOG) {
    return { ...state, isFiltersDialogOpen: true };
  }
  if (action.type === CLOSE_FILTERS_DIALOG) {
    return { ...state, isFiltersDialogOpen: false };
  }
  if (action.type === CLEAR_FILTERS) {
    instance?.onApplyFilters(null);
    return { ...state, filters: undefined, filteredColumns: [] };
  }
  if (action.type === CLEAR_FILTER) {
    const { column } = action;
    const { filter } = column;
    const { id } = filter;
    const attributeNames = typeof id === 'string' ? [id] : id;
    const filterIdentifier = attributeNames.join(',');

    const filters = state.filters?.filters.filter(
      (d) => d._id !== filterIdentifier
    );
    const filteredColumns = state.filteredColumns.filter(
      (d) => d !== column.id
    );
    if (isEmpty(filters)) {
      instance?.onApplyFilters(null);
      return { ...state, filters: null, filteredColumns: [] };
    } else {
      const newFilters = {
        op: 'and',
        filters,
      };
      instance?.onApplyFilters(newFilters);
      return {
        ...state,
        filters: newFilters,
        filteredColumns,
      };
    }
  }
  if (action.type === PUSH_FILTER || action.type === PUSH_FILTERS) {
    let actionFilters: { op: string; value: string }[] = [];
    let preferredOperator = 'and';
    if (action.type === 'PUSH_FILTERS') {
      actionFilters = action.filters;
    } else if (action.type === 'PUSH_FILTER') {
      const values = action.value.split(',');
      if (values.length > 1) {
        actionFilters = values.map((value) => ({
          op: action.op,
          value,
        }));
        preferredOperator = 'or';
      } else {
        actionFilters = [{ op: action.op, value: action.value }];
      }
    }

    const { column } = action;
    const { filter } = column;
    const { id } = filter;
    const attributeNames = typeof id === 'string' ? [id] : id;
    const filterIdentifier = attributeNames.join(',');

    let filters = state.filters?.filters ?? [];
    const attributeFilters = generateAttributeFilter(
      filter,
      attributeNames,
      actionFilters,
      preferredOperator
    );

    if (attributeNames.length > 1) {
      filters.push({
        _id: filterIdentifier,
        op: 'or',
        filters: attributeFilters,
      });
    } else {
      filters.push({
        _id: filterIdentifier,
        ...attributeFilters[0],
      });
    }

    const newFilters = {
      op: 'and',
      filters: uniqBy(reverse(filters), '_id'),
    };
    instance?.onApplyFilters(newFilters);
    return {
      ...state,
      filters: newFilters,
      filteredColumns: uniq([column.id, ...state?.filteredColumns]),
    };
  }

  return state;
}

function useInstance(instance: any) {
  const { dispatch } = instance;
  instance.showFiltersDialog = () => dispatch({ type: OPEN_FILTERS_DIALOG });
  instance.closeFiltersDialog = () => dispatch({ type: CLOSE_FILTERS_DIALOG });
}

export function useRemoteFilters<T extends object>(hooks: Hooks<T>) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
}

function generateAttributeFilter(
  filter: AppColumnFilter,
  attributeNames: string[],
  filters: { op: string; value: string }[],
  preferredOperator: string
) {
  let processedFilters = filters;

  if (filter.kind === FilterKind.AGE) {
    // Conver age to dates.
    processedFilters = processedFilters
      .filter(({ value }) => !isNaN(parseInt(value)))
      .map(({ op, value }) => ({
        op: inverseDateOperator(op),
        value: DateTime.now()
          .minus({ years: parseInt(value) })
          .toISO(),
      }));
  }

  return attributeNames.map((name) => ({
    attributeName: name,
    op: preferredOperator,
    filters: processedFilters,
  }));
}

export function inverseDateOperator(op: string) {
  if (op.startsWith('>')) {
    return op.replace('>', '<');
  } else if (op.startsWith('<')) {
    return op.replace('<', '>');
  } else {
    return op;
  }
}
