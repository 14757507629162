import * as React from "react";

export interface PreloadableLazyComponent<T extends React.ComponentType<any>>
  extends React.LazyExoticComponent<T> {
  preload: () => void;
}

export default function lazy<T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableLazyComponent<T> {
  const Component = React.lazy(factory) as any;
  Component.preload = factory;
  return Component;
}
