import React, { useCallback, useState } from 'react';
import { TableInstance, ColumnInstance } from 'react-table';
import { IconName, NonIdealState } from '@blueprintjs/core';
import { Icon, Intent, Button, Popover, Classes } from '@blueprintjs/core';
import { FixedSizeList } from 'react-window';
import type { Size } from 'src/utils/size';
import css from 'csz';
import EditColumnsDialog from 'src/components/table/EditColumnsDialog';
import { CLOSE_COLUMNS_DIALOG } from 'src/utils/table/useActionContextMenu';
import {
  AppTableInstance,
  AppColumnInstance,
  FilterKind,
  AppTableState,
  AppColumnFilter,
} from 'src/utils/table/useAppTable';
import FiltersDialog, {
  FilterSelector,
} from 'src/components/table/FiltersDialog';
import InfiniteLoader from 'react-window-infinite-loader';
import { get } from 'lodash';
import { CLEAR_FILTER, CLEAR_FILTERS } from 'src/utils/table/useRemoteFilters';
import { useTranslation } from 'react-i18next';
import { Popover2 } from '@blueprintjs/popover2';
import { BarItem } from '../BottomBar';

const className = css`/styles/table.css`;

const DEFAULT_ROW_HEIGHT = 35;
const DEFAULT_HEADER_HEIGHT = 30;

interface P<D extends object> {
  table: AppTableInstance<D>;
  size: Size;
  className?: string;
  emptyView?: JSX.Element;
  rowHeight?: number;
  headerHeight?: number;
  bottomItems?: BarItem[];
}

export function TableHeader(p: { children?: JSX.Element }) {
  const tableHeaderClassName = css`
    background-color: var(--table-header-bg-color);
  `;
  return <div className={tableHeaderClassName}>{p.children}</div>;
}

export function Table<D extends object>(p: P<D>) {
  const { size, table } = p;
  const rowHeight = p.rowHeight ?? DEFAULT_ROW_HEIGHT;
  const headerHeight = p.headerHeight ?? DEFAULT_HEADER_HEIGHT;
  const {
    getTableProps,
    getTableBodyProps,
    infiniteLoader,
    headerGroups,
    dispatch,
    totalColumnsWidth,
    rows,
    state,
    prepareRow,
  } = table;

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map((cell) => {
            return (
              <div {...cell.getCellProps()} className="td">
                {'_loading' in cell.row.original ? (
                  <span className={Classes.SKELETON}>
                    {cell.render('Header')}
                  </span>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <div
      {...getTableProps()}
      className={`${className} ${p.className ?? ''} table`}
    >
      <div className={p.emptyView ? 'opacity-30 pointer-events-none' : ''}>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map((column) => (
              <HeaderColumn
                key={column.id}
                table={table}
                height={headerHeight}
                column={column as AppColumnInstance<D>}
              />
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()}>
        <InfiniteLoader {...infiniteLoader}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              ref={ref}
              onItemsRendered={onItemsRendered}
              height={Math.min(
                size.height - headerHeight,
                rows.length * rowHeight
              )}
              width={size.width}
              itemCount={rows.length}
              itemSize={rowHeight}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
      {p.emptyView != null && (
        <div className='absolute w-full h-full flex flex-col items-center flex-grow'>{p.emptyView}</div>
      )}
      {p.bottomItems != null && (
        <div className="bottom-bar px-3 py-2">
          {p.bottomItems?.map((item, index) => (
            <BottomBarItem key={index} item={item} />
          ))}
        </div>
      )}
      <EditColumnsDialog
        isOpen={state.isColumnsDialogOpen}
        table={table}
        onClose={() => dispatch({ type: CLOSE_COLUMNS_DIALOG })}
      />
      <FiltersDialog
        isOpen={table.state.isFiltersDialogOpen}
        table={table}
        onClose={() => table.closeFiltersDialog()}
      />
    </div>
  );
}

function BottomBarItem(p: { item: BarItem }) {
  if ('expand' in p.item) {
    return <div className="flex-grow" />;
  } else if ('label' in p.item) {
    return <label className="secondary-text">{p.item.text}</label>;
  } else if (p.item.menu != null) {
    return (
      <Popover2 position="bottom-right" content={p.item.menu}>
        <Button {...p.item} />
      </Popover2>
    );
  } else {
    return <Button {...p.item} />;
  }
}
interface SortableColumnInstance {
  getSortByToggleProps: () => {};
  isSorted: boolean;
  isSortedDesc: boolean;
}

function getDefaultFilterInitialValues(filter: AppColumnFilter) {
  if (filter.defaultOperator != null) {
    return { op: filter.defaultOperator, value: '' };
  }
  switch (filter?.kind) {
    case FilterKind.TEXT:
      return { op: 'contains', value: '' };
    case FilterKind.KEYWORD:
      return { op: '=', value: '' };
    default:
      return { op: 'exists', value: '' };
  }
}

function findPreviousValue(filters: any) {
  const someFilter = filters[0];
  if ('filters' in someFilter) {
    return findPreviousValue(someFilter.filters);
  } else if ('op' in someFilter) {
    return someFilter;
  } else {
    return null;
  }
}

export function getFilterPreviousValue<D extends object>(
  state: AppTableState<D>,
  id: string
) {
  const previousFilter = state.filters?.filters?.find((d) => d._id === id);
  const previousValue =
    previousFilter != null ? findPreviousValue(previousFilter.filters) : null;
  return previousValue;
}

function FilterPopopver<D extends object>(p: {
  table: AppTableInstance<D>;
  column: AppColumnInstance<D>;
  onApply: () => void;
  hasFilter?: boolean;
}) {
  const { table, onApply, column } = p;
  const { t } = useTranslation();
  const { dispatch, state } = table;
  const { filters } = state;

  const filterIdentifier =
    typeof column.filter?.id === 'object'
      ? column.filter?.id.join(',')
      : column.filter?.id;

  const previousValue = getFilterPreviousValue(state, filterIdentifier);
  const initialValues = p.hasFilter
    ? previousValue
    : getDefaultFilterInitialValues(p.column.filter);

  return (
    <div className="p-3">
      <FilterSelector {...p} initialValues={initialValues} />
    </div>
  );
}

function HeaderColumn<D extends object>(p: {
  table: AppTableInstance<D>;
  column: AppColumnInstance<D> & Partial<SortableColumnInstance>;
  height: number;
}) {
  const { column } = p;
  const { filteredColumns } = p.table.state;
  const [isFilterOpen, setFilterOpen] = useState(false);
  const hasSort = column.getSortByToggleProps != null;
  const sortProps = hasSort ? column.getSortByToggleProps() : {};
  const hasFilter =
    filteredColumns?.find((c) => c === column.id) != null ?? false;
  const props = Object.assign({}, sortProps, { style: { height: p.height } });

  return (
    <div {...column.getHeaderProps(props)} className="th">
      <div className="flex-grow">
        {column.render('Header')}
        {column.id !== 'context-menu' &&
          column.id !== 'action-menu' &&
          column.filter != null && (
            <Popover
              isOpen={isFilterOpen}
              onClose={() => setFilterOpen(false)}
              content={
                <FilterPopopver
                  table={p.table}
                  column={column}
                  onApply={() => setFilterOpen(false)}
                  hasFilter={hasFilter}
                />
              }
            >
              <Icon
                className="px-2"
                iconSize={13}
                aria-label={`Filter by ${column.id}`}
                intent={Intent.NONE}
                onClick={() => setFilterOpen(true)}
                icon={hasFilter ? 'filter-remove' : 'filter'}
              />
            </Popover>
          )}
        {column.isSorted ? (
          <Icon
            className="ml1"
            intent={Intent.PRIMARY}
            icon={column.isSortedDesc ? 'chevron-down' : 'chevron-up'}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
