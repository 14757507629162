import type { FormikState, FormikConfig } from 'formik';
import {
  get,
  isEmpty,
  isEqual,
  isArray,
  flatten,
  compact,
  pick as _pick,
} from 'lodash';
import { useCallback } from 'react';
import { DiffActions, DiffActionKind } from './diff';
import { SubscribeFn } from './events';
import { getDiffsFieldName } from './formik';
import { GraphQLFetch, useGraphQLFetch } from './graphql';
import { serialPromises } from './promise';
import sanitizeTypename from './sanitizeTypename';

export const WAIT_FOR_EVENT = 'WAIT_FOR_EVENT';
export interface Result<T extends {}> {
  command: string;
  data: T;
}

export type DiffableForm<T> = FormikState<T> &
  Pick<FormikConfig<T>, 'initialValues'>;

type DiffableMiddleware<T, O extends {}> = (
  form: DiffableForm<T>
) => Result<O>[];

/**
 * When the name changes allows to send the command individually and edit the data using the alias
 *
 * @export
 * @template T
 * @template O
 * @param {{
 *   name: string;
 *   alias?: string;
 *   command: string;
 *   data?: {};
 * }} options
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function simpleField<T, O extends {}>(options: {
  name: string;
  alias?: string;
  command: string;
  data?: {};
}): DiffableMiddleware<T, O> {
  return (form) => {
    // Get the prev and next value.
    const prevValue = get(form.initialValues, options.name);
    const nextValue = get(form.values, options.name);

    // If something changed.
    if (!isEqual(prevValue, nextValue)) {
      return [
        {
          command: options.command,
          data: {
            [options.alias ?? options.name]: nextValue,
            ...(options.data ?? {}),
          } as O,
        },
      ];
    }

    return [];
  };
}

function diffObject(a: unknown, b: unknown, keys?: string[]) {
  const keysToCheck = keys ?? Object.keys(a);
  const changedKeys = keysToCheck.filter((key) => {
    const prev = get(a, key);
    const next = get(b, key);
    return !isEqual(prev, next);
  });
  return changedKeys.reduce(
    (prev, next) => ({
      ...prev,
      [next]: get(a, next),
    }),
    {}
  );
}

/**
 * Allows to create a custom DiffableMiddleware using a function that return:
 *
 * [
 *  {
 *   command: string;
 *   data: T;
 *  }
 *]
 *
 * Inside the function can use a set, unset and get function to manage the data
 *
 * @export
 * @template T
 * @template O
 * @param {DiffableMiddleware<T, O>} handler
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function custom<T, O extends {}>(
  handler: DiffableMiddleware<T, O>
): DiffableMiddleware<T, O> {
  return handler;
}

/**
 * When the name changes allows to send the command individually and edit the data using dataBuilder.
 *
 * - Pick: does not return any changes but the picked fields.
 *
 * - Omit: does not return any changes, when one of the omitted fields changes.
 *
 * - Data: add some date
 *
 * - DataBuilder: manipulate some data and return it
 *
 * Note: Pick and Omit cannot be used together.
 *
 * @export
 * @template T
 * @template O
 * @template D
 * @param {{
 *   name?: string;
 *   pick?: string[];
 *   omit?: string[];
 *   data?: {};
 *   dataBuilder?: (val: D) => O;
 *   command: string;
 * }} options
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function simpleObject<T, O extends {}, D extends {}>(options: {
  name?: string;
  pick?: string[];
  omit?: string[];
  data?: {};
  dataBuilder?: (val: D) => O;
  command: string;
}): DiffableMiddleware<T, O> {
  return (form) => {
    // Get values and initial values.
    const initialValues = options.name
      ? get(form.initialValues, options.name)
      : form.initialValues;
    const values = options.name ? get(form.values, options.name) : form.values;

    // Only take the keys we want to pick or not to omit.
    const keys = Object.keys(values).filter((key) => {
      if (options.pick) {
        return options.pick.includes(key);
      } else if (options.omit) {
        return !options.omit.includes(key);
      } else {
        return true;
      }
    });
    let data = diffObject(values, initialValues, keys) as unknown;
    if (!isEmpty(data)) {
      if (options.data != null) {
        data = { ...(data as O), ...(options.data ?? {}) };
      } else if (options.dataBuilder != null) {
        data = options.dataBuilder(data as D);
      }
      return [
        {
          command: options.command,
          data: data as O,
        },
      ];
    }
    return [];
  };
}

function fillRecordWithNewData<Item extends { id: string }>(
  record: Item,
  values: Item[]
) {
  return values.find((r) => r.id === record.id);
}

export function diffableArray<T, Item extends { id: string }>(options: {
  name: string;
  commandSuffix: string;
  transform?: (val: unknown) => T | null;
  partial?: boolean;
  prefixes?: {
    add?: string;
    remove?: string;
    move?: string;
    update?: string;
  };
  data?: {};
}) {
  return (form: DiffableForm<T>) => {
    // This is easire because diffable array supports _diff out of the box.
    const diffs: DiffActions<Item>[] =
      get(form.values, getDiffsFieldName(options.name)) ?? [];
    // Now what it's not removed or added, we have also to check with the simpleObject
    // middleware.
    const addedOrRemovedIds = compact(
      flatten(
        diffs.map((d) => {
          if (d.kind === DiffActionKind.REMOVE) {
            return d.id;
          } else if (d.kind === DiffActionKind.ADD) {
            return isArray(d.data) ? d.data.map((f) => f.id) : d.data.id;
          }
        })
      )
    );

    // Now for all the values that ar not added or removed we have to check whether is changed.
    const prevValues: Item[] = get(form.initialValues, options.name) ?? [];
    const values: Item[] = get(form.values, options.name) ?? [];
    const otherData = options.data ?? {};
    const changes = compact(
      values.map((val) => {
        if (!addedOrRemovedIds.includes(val.id)) {
          const prevValue = prevValues.find((f) => f.id === val.id) ?? {};
          const diff = diffObject(val, prevValue);
          if (prevValue && !isEmpty(diff)) {
            const data = options.partial ? diff : val;
            const transformedData = options.transform?.(data) ?? data;
            if (transformedData == null) {
              return null;
            }
            return {
              command: `${options?.prefixes?.update ?? 'UPDATE'}_${
                options.commandSuffix
              }`,
              data: {
                record: { ...transformedData, id: val.id },
                ...otherData,
              },
            };
          }
        }
      })
    );

    const diffChanges = compact(
      diffs.map((d) => {
        if (d.kind === DiffActionKind.ADD) {
          let data: {};
          if (isArray(d.data)) {
            data = {
              records: d.data.map((r) => fillRecordWithNewData(r, values)),
              index: d.index,
              ...otherData,
            };
          } else {
            data = {
              record: fillRecordWithNewData(d.data, values),
              index: d.index,
              ...otherData,
            };
          }
          const transformedData = options.transform?.(data) ?? data;
          if (transformedData == null) {
            return null;
          }
          return {
            command: `${options?.prefixes?.add ?? 'ADD'}_${
              options.commandSuffix
            }`,
            data: transformedData,
          };
        } else if (d.kind === DiffActionKind.REMOVE) {
          return {
            command: `${options?.prefixes?.remove ?? 'REMOVE'}_${
              options.commandSuffix
            }`,
            data: {
              id: d.id,
              ...otherData,
            },
          };
        } else {
          const { id, after } = d;
          return {
            command: `${options?.prefixes?.move ?? 'MOVE'}_${
              options.commandSuffix
            }`,
            data: {
              id,
              after,
              ...otherData,
            },
          };
        }
      })
    );

    return [...diffChanges, ...changes];
  };
}

/**
 * Allows to combine values on values array.
 * It supports: simpleField and simpleObject.
 *
 * @export
 * @template T
 * @template O
 * @param {...DiffableMiddleware<T, O>[]} values
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function combine<T, O extends {}>(
  ...values: DiffableMiddleware<T, O>[]
): DiffableMiddleware<T, O> {
  return (form: DiffableForm<T>): Result<O>[] => {
    return flatten(compact(values.map((middleware) => middleware(form))));
  };
}

/**
 * Returns the second parameter if the condition (first parameter)
 * is true, otherwise it returns the third
 *
 * @export
 * @template T
 * @template O
 * @param {boolean} condition
 * @param {DiffableMiddleware<T, O>} trueMiddleware
 * @param {DiffableMiddleware<T, O>} falseMiddleware
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function branch<T, O extends {}>(
  condition: boolean,
  trueMiddleware: DiffableMiddleware<T, O>,
  falseMiddleware: DiffableMiddleware<T, O>
): DiffableMiddleware<T, O> {
  return condition ? trueMiddleware : falseMiddleware;
}

export function waitForSubscription<T, O extends {}, Ctx = {}>(
  subscribe: SubscribeFn<T>,
  context?: Ctx
): DiffableMiddleware<T, O> {
  return () => [
    {
      command: WAIT_FOR_EVENT,
      data: {
        subscribe,
        context,
      } as any,
    },
  ];
}

/**
 * Does not return any changes but the picked fields.
 *
 * @export
 * @template T
 * @template O
 * @template D
 * @param {{
 *   command: string;
 *   fields: string[];
 *   dataBuilder?: (data: D) => O;
 * }} options
 * @return {*}  {DiffableMiddleware<T, O>}
 */
export function pick<T, O extends {}, D extends {}>(options: {
  command: string;
  fields: string[];
  dataBuilder?: (data: D) => O;
}): DiffableMiddleware<T, O> {
  return custom<T, O>((form: DiffableForm<T>) => {
    const data = _pick(form.values, options.fields) as unknown;
    return [
      {
        command: options.command,
        data:
          options.dataBuilder != null
            ? options.dataBuilder(data as D)
            : (data as O),
      },
    ];
  });
}

export function useApplyChanges<T extends object>(mutations: {
  [key: string]: string;
}) {
  const fetch = useGraphQLFetch();
  return useCallback((changes: Result<T>[]) => {
    return applyChanges(fetch, changes, mutations);
  }, []);
}

export async function applyChanges<T extends object>(
  fetch: GraphQLFetch<unknown, unknown>,
  changes: Result<T>[],
  mutations: { [key: string]: string }
) {
  const promises = compact(
    changes.map((val) => {
      // IF wait for event.
      if (val.command === WAIT_FOR_EVENT && 'subscribe' in val.data) {
        return (prev?: string) => {
          const { context, subscribe } = val.data as any;
          return subscribe(prev, context);
        };
      }

      const mutation = mutations[val.command];
      if (mutation != null) {
        // const { id, ...other } = val.data as any;
        // const data = other.parentId != null ? val.data : { id, [kind]: other };
        return () =>
          fetch(mutation, sanitizeTypename(val.data)).then((data) => {
            const dataKeys = Object.keys(data);
            return data[dataKeys[0]] as string;
          });
      }
    })
  );
  const [result] = serialPromises(promises);
  return result;
}
