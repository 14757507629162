// React-ECharts.tsx

import type { ECharts, EChartsOption, SetOptionOpts } from 'echarts';
import { getInstanceByDom, init, registerTheme } from 'echarts';
import type { CSSProperties } from 'react';
import React, { useEffect, useRef } from 'react';
import keixTheme from './theme.json'

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  className?: string;
  settings?: SetOptionOpts;
  loading?: boolean;
  onChartLoad?: (chart: ECharts) => void;
  theme?: 'light' | 'dark';
}

export function ReactECharts({
  option,
  style,
  onChartLoad,
  className,
  settings,
  loading,
  theme,
}: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log('Register', theme)
    registerTheme('keix', keixTheme)
  }, [])

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'keix');
      onChartLoad?.(chart);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]);
  // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function
  useEffect(() => {
    const chart = getInstanceByDom(chartRef.current);
    chart?.resize();
  }, [style]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [loading, theme]);

  return <div className={className} ref={chartRef} style={style} />;
}
