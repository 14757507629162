import { Intent } from '@blueprintjs/core';
import { useQueryClient } from '@tanstack/react-query';
import { MUTATIONS } from 'src/graphql/surveys';
import { ShowAlertFn, useAlert } from 'src/utils/alert';
import { useSendCommand } from 'src/utils/events';

export function useDisableSurveyShare(): [JSX.Element, ShowAlertFn<string>] {
  const queryClient = useQueryClient();

  const [{ isLoading }, sendDisableShareCommand] = useSendCommand(
    MUTATIONS.DISABLE_SHARE_SURVEY,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_SHARE_DISABLED') {
        ctx.complete();
        ctx.showSuccessNotification({ message: 'Share disabled' });
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['surveyShares']),
    }
  );

  const [disableAlert, showDisableAlert] = useAlert({
    icon: 'warning-sign',
    intent: Intent.WARNING,
    message: 'Are you sure you want to disable this link?',
    confirmButtonText: 'Yes, disable',
    onConfirm: (id: string) => sendDisableShareCommand({ id }),
  });

  return [disableAlert, showDisableAlert];
}
export function useRemoveSurveyShare(
  surveyId: string
): [JSX.Element, ShowAlertFn<string>] {
  const queryClient = useQueryClient();
  const [_, sendRemoveShareCommand] = useSendCommand(
    MUTATIONS.REMOVE_SHARE_SURVEY,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_SHARE_REMOVED') {
        ctx.complete();
        ctx.showSuccessNotification({ message: 'Share removed' });
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['surveyShare', surveyId]),
    }
  );
  const [removeAlert, showRemovedAlert] = useAlert({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    message: 'Are you sure you want to remove this link?',
    confirmButtonText: 'Yes, disable',
    onConfirm: (id: string) => sendRemoveShareCommand({ id }),
  });

  return [removeAlert, showRemovedAlert];
}

export function useEnableSurveyShare(): [JSX.Element, ShowAlertFn<string>] {
  const queryClient = useQueryClient();

  const [_, sendEnableShareCommand] = useSendCommand(
    MUTATIONS.ENABLE_SHARE_SURVEY,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_SHARE_ENABLED') {
        ctx.complete();
        ctx.showSuccessNotification({ message: 'Share enabled' });
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['surveyShares']),
    }
  );
  const [enableAlert, showEnableAlert] = useAlert({
    icon: 'warning-sign',
    intent: Intent.PRIMARY,
    message: 'Are you sure you want to enable this link?',
    confirmButtonText: 'Yes, enable',
    onConfirm: (id: string) => sendEnableShareCommand({ id }),
  });

  return [enableAlert, showEnableAlert];
}
