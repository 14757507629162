import React, { useCallback, useMemo } from 'react';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import { DataGrid } from '../DataGrid';
import type { AppColumn } from 'src/utils/table/useAppTable';
import { useTranslation } from 'react-i18next';
import type { Query, SurveySessionData } from 'src/apps/athena/gql-types';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { IconName } from '@blueprintjs/core';
import { Icon, Intent } from '@blueprintjs/core';
import { useDateAccessor } from 'src/utils/accessors';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { GraphQLFetch, useGraphQLFetch } from 'src/utils/graphql';
import '@blueprintjs/table/lib/css/table.css';
import { QUERY } from 'src/graphql/surveys';

export async function dumpSurveyData(
  surveyId: string,
  graphqlFetch: GraphQLFetch<Pick<Query, 'dumpSurveyData'>, {}>
) {
  const data = await graphqlFetch(QUERY.DUMP_SURVEY, {
    surveyId,
  });
  return data.dumpSurveyData;
}

export function SurveyData(p: { surveyId: string }) {
  const { surveyId } = p;
  const { t } = useTranslation('survey');

  const { data, isFetching } = useGetSurveyWithQuestions(p.surveyId);
  const graphqlFetch = useGraphQLFetch();
  const result = useQuery<Pick<Query, 'survey'>>(
    ['surveyData', p.surveyId],
    () =>
      graphqlFetch(
        'query Survey($id: String!) { survey(id: $id) { id questions { id body } } }',
        { id: surveyId }
      )
  );

  // Get all the questions.
  const questions = data?.survey?.questions ?? [];
  const startedAtAccessor = useCallback((session: SurveySessionData) => {
    const icon: IconName = session.endedAt != null ? 'tick' : 'blank';
    const intent: Intent =
      session.endedAt != null ? Intent.SUCCESS : Intent.NONE;
    return session.startedAt != null ? (
      <div className="flex items-center">
        <Icon
          icon={icon}
          iconSize={14}
          intent={intent}
          className={session.endedAt == null ? 'opacity-25' : ''}
        />
        <div className="pl-1 text-gray-600">
          {DateTime.fromISO(session.startedAt).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </div>
      </div>
    ) : (
      ''
    );
  }, []);
  const columns = useMemo<AppColumn<any>[]>(
    () => [
      {
        id: 'id',
        Header: t('Id'),
        accessor: 'id',
      },
      {
        id: 'startedAt',
        Header: t('Timestamp'),
        accessor: startedAtAccessor,
      },
      ...questions.map((question, index) => ({
        id: question.id,
        Header: `Q${index + 1}`,
        accessor: `data.${question.id}`,
      })),
    ],
    [isFetching]
  );

  const { table, ref } = usePaginatedTableQuery(
    QUERY.RETRIEVE_SURVEY_DATA,
    'surveyData',
    'surveyData',
    {
      columns,
      variables: { surveyId, orderBy: [{ startedAt: 'desc' }] },
      features: {
        contextMenu: false,
        disclosureIndicator: false,
      },
    }
  );

  return (
    <div className="card flex-grow flex flex-column p0" ref={ref}>
      <DataGrid className="flex-grow" {...table} />
    </div>
  );
}
