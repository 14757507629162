import {
  Button,
  Classes,
  Dialog,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
} from '@blueprintjs/core';
import { useFormik, FormikProvider, useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Query,
  Question,
  QuestionInput,
  QuestionType,
} from 'src/apps/athena/gql-types';
import { useSendCommand } from 'src/utils/events';
import { iconForQuestionType } from 'src/utils/survey/icons';
import { object, string, array } from 'yup';
import {
  SelectOption,
  EditableTextField,
  SelectField,
} from '../BlueprintFields';
import { QuestionOptionsField } from './QuestionOptions';
import { QuestionScaleField } from './QuestionOpinionScale';
import QuestionSettingsField from './QuestionSettings';
import QuestionRowsField from './QuestionRowsField';
import { simpleObject } from 'src/utils/diffV2';
import { Flag } from '../Flag';
import { BottomBar } from '../BottomBar';
import { compact, fromPairs, get, isEmpty, set, uniq } from 'lodash';
import classNames from 'classnames';
import { getSurveyLanguages } from 'src/pages/Survey/SurveyPage';
import { GraphQLFetch, useGraphQLFetch } from 'src/utils/graphql';
import { QUERY } from 'src/graphql/surveys';

async function getQuestion(
  id: string,
  fetch: GraphQLFetch<Pick<Query, 'question'>>
): Promise<Question | null> {
  const { question } = await fetch(QUERY.GET_QUESTION, { id });
  return question;
}

export function QuestionDetail(p: {
  question?: Question;
  isNew?: boolean;
  parentQuestion?: Question;
  onReplaceQuestion?: (question: Question) => void;
  onSelectQuestion?: (question: Question) => void;
  onAddQuestion?: (question: Question) => void;
}) {
  const fetch = useGraphQLFetch();
  const queryClient = useQueryClient();
  const [, createQuestion] = useSendCommand(
    'mutation CreateQuestion($question: QuestionInput!) { createQuestion(question: $question) }',
    (evt, ctx) => {
      if (evt.type === 'QUESTION_CREATED') {
        ctx.complete(evt.data);
        ctx.showSuccessNotification({
          message: t('Question Successfully Created'),
        });
      }
    },
    {
      optimistic: false,
      onSettled: async (data: { id: string }) => {
        queryClient.invalidateQueries(['questions']);

        // Update the outer state with the new updated question.
        const question = await getQuestion(data.id, fetch);
        p.onSelectQuestion?.(question);
      },
    }
  );
  const [, updateQuestion] = useSendCommand(
    'mutation UpdateQuestion($question: UpdateQuestionInput!) { updateQuestion(question: $question) }',
    (evt, ctx) => {
      if (evt.type === 'QUESTION_UPDATED') {
        ctx.complete(evt.data);
        ctx.showSuccessNotification({
          message: t('Question Successfully Updated'),
        });
      }
    },
    {
      optimistic: false,
      onSettled: async (data: { id: string }) => {
        queryClient.invalidateQueries(['questions']);

        // Update the outer state with the new updated question.
        const question = await getQuestion(data.id, fetch);
        p.onSelectQuestion?.(question);
      },
    }
  );

  const [, setQuestionTranslation] = useSendCommand(
    'mutation SetQuestionTranslation($translation: QuestionTranslation) { setQuestionTranslation(translation: $translation) }',
    (evt, ctx) => {
      if (evt.type === 'QUESTION_TRANSLATION_SET') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Question Translation Updated'),
        });
      }
    },
    {
      optimistic: false,
      onSettled: () => queryClient.invalidateQueries(['questions']),
    }
  );

  const { t } = useTranslation('survey');
  const questionTypeOptions: SelectOption[] = Object.keys(QuestionType).map(
    (k) => ({
      value: QuestionType[k],
      icon: iconForQuestionType(QuestionType[k]),
      label: t(`survey.questionType.${k}`, { defaultValue: k }),
    })
  );

  const initialValues = useMemo(() => {
    console.log('Initial values');
    if (p.question) {
      return p.question as QuestionInput;
    }
    return {
      parentId: p.parentQuestion?.id,
      body: p.parentQuestion?.body ?? '',
      settings: {},
      rows: null,
      type: p.parentQuestion?.type ?? QuestionType.MultipleChoice,
      description: p.parentQuestion?.description ?? '',
      options: p.parentQuestion?.options ?? [],
    };
  }, [p.isNew, p.question?.id, p.parentQuestion?.id]);

  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const form = useFormik<QuestionInput>({
    initialValues,
    validationSchema: object().shape({
      body: string().required(),
      description: string().nullable(),
      type: string().required(),
      options: array().of(
        object().shape({
          id: string().required(),
          body: string().required(),
        })
      ),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (question) => {
      if (p.isNew) {
        return createQuestion({ question });
      } else {
        if (isEditingTranslations) {
          const translation = get(
            form,
            `values.translations.${currentLanguage}`
          );
          return setQuestionTranslation({
            translation: {
              id: p.question?.id,
              language: currentLanguage,
              ...translation,
            },
          });
        } else {
          const cmd = simpleObject({
            command: '',
          })(form);
          if (cmd.length > 0) {
            const changes = cmd[0].data;
            return updateQuestion({
              question: {
                ...changes,
                id: p.question?.id,
              },
            });
          }
        }
      }
    },
  });

  const isEditingTranslations =
    currentLanguage != null && currentLanguage !== form.values.language;
  const handleChangeCurrentLanguage = useCallback((language: string) => {
    setCurrentLanguage(language);
  }, []);

  if (p.isNew !== true && p.question == null) {
    return (
      <NonIdealState
        icon="select"
        title="No Question Selected"
        description="Select a question to see its details"
      />
    );
  }
  const saveText = p.isNew
    ? t('Save Question')
    : isEditingTranslations
      ? t('Update Translation')
      : t('Update Question');

  return (
    <div className="rounded shadow ml-2 mr-3 bg-gray-50 p-3">
      <FormikProvider value={form}>
        <div className="flex bg-gray-50 rounded-t ">
          <div className="flex-grow flex flex-col">
            <EditableTextField
              key={p.question?.id}
              multiline={true}
              className="font-semibold text-base"
              name={
                isEditingTranslations
                  ? `translations.${currentLanguage}.body`
                  : 'body'
              }
              placeholder={t('Type a question...')}
            />
            <EditableTextField
              name={
                isEditingTranslations
                  ? `translations.${currentLanguage}.description`
                  : 'description'
              }
              className="text-sm mt-1 mb-2 text-gray-500"
              multiline={true}
              placeholder={t('Type a description...')}
            />
          </div>
          <SelectField
            minimal
            disabled={isEditingTranslations}
            className="font-medium"
            intent={Intent.PRIMARY}
            options={questionTypeOptions}
            name="type"
          />
        </div>
        <div className="py-3 border-t border-b bg-white -mx-3 px-3">
          <QuestionRowsField isEditingTranslations={isEditingTranslations} />
          <QuestionOptionsField
            currentLanguage={currentLanguage}
            isEditingTranslations={isEditingTranslations}
          />
          <QuestionScaleField isEditingTranslations={isEditingTranslations} />
        </div>
        <QuestionSettingsField
          currentLanguage={currentLanguage}
          isEditingTranslations={isEditingTranslations}
        />
        <QuestionLanguagesField
          currentLanguage={currentLanguage ?? form.values.language}
          onChangeCurrentLanuage={handleChangeCurrentLanguage}
        />
        <BottomBar
          className="-mx-3 -mb-3 rounded-b bg-gray-100"
          items={compact([
            !isEmpty(form.values.language) && {
              text: 'Add Language',
              minimal: true,
              rightIcon: 'caret-down',
              menu: <LanguageMenu />,
              icon: 'globe',
            },
            { expand: true },
            p.onReplaceQuestion != null
              ? {
                text: 'Sostuisci Domanda',
                minimal: true,
                icon: 'exchange',
                onClick: () => p.onReplaceQuestion(p.question),
              }
              : null,
            p.onAddQuestion != null
              ? {
                text: 'Add to Survey',
                minimal: true,
                icon: 'plus',
                disabled: p.question?.id == null,
                onClick: () => p.onAddQuestion(p.question),
              }
              : null,
            {
              text: saveText,
              intent: Intent.PRIMARY,
              icon: 'tick',
              minimal: true,
              loading: form.isSubmitting,
              onClick: form.submitForm,
              disabled: !form.isValid,
            },
          ])}
        />
      </FormikProvider>
    </div>
  );
}

function LanguageMenu() {
  const form = useFormikContext<Question>();
  const [availableLanguages, __, availableLanguagesHelpers] =
    useField('availableLanguages');

  const [___, _, translationsHelpers] = useField('translations');

  const handleAddLanguage = useCallback(
    (language: string) => {
      const prev = availableLanguages.value ?? [];
      availableLanguagesHelpers.setValue(uniq([...prev, language]));

      // We should prepare the translation dictionary with all the options and rows.
      translationsHelpers.setValue(
        set(form.values.translations ?? {}, language, {
          body: form.values.body,
          description: form.values.description,
          options: form.values.options,
          rows: form.values.rows,
        })
      );
    },
    [availableLanguages.value, form]
  );

  return (
    <Menu>
      {getSurveyLanguages().map((opt) => (
        <MenuItem
          text={opt.name}
          labelElement={<Flag countryCode={opt.countryCode} />}
          onClick={() => handleAddLanguage(opt.countryCode)}
        />
      ))}
    </Menu>
  );
}

function QuestionLanguagesField(p: {
  currentLanguage: string;
  onChangeCurrentLanuage: (lang: string) => void;
}) {
  const [languageField, _, languageHelpers] = useField('language');
  const [languagesField, __, languagesHelpers] = useField('availableLanguages');
  const isTranslated = !isEmpty(languageField.value);

  const languages = compact([
    languageField.value,
    ...(languagesField.value ?? []),
  ]);

  if (isTranslated) {
    return (
      <div className="bg-white border-t border-b -mx-3 p-3 flex items-center">
        <div className="text-gray-500 italic">Available in: </div>
        <div className="flex px-2 gap-x-2 ">
          {languages.map((d, index) => (
            <div
              key={index}
              className={classNames('ring-1 rounded-sm px-1 cursor-pointer ', {
                'bg-blue-800 border-blue-100': p.currentLanguage === d,
              })}
              onClick={() => p.onChangeCurrentLanuage(d)}
            >
              <Flag countryCode={d} style={{ width: 24 }} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <React.Fragment />;
}
