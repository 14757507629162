import { Button, Icon, Intent, Spinner } from '@blueprintjs/core';
import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  ControlButton,
  NodeTypes,
  BackgroundVariant,
  ReactFlowProvider,
  useViewport,
} from 'react-flow-renderer';
import classNames from 'classnames';
import { BlockNode } from './BlockNode';
import './workflow.css';
import {
  useWorkflowContext,
  WorkflowAction,
  WorkflowContextProvider,
  WorkflowView,
} from './WorkflowContext';
import { LibraryContextProvider } from 'src/hooks/workflow/LibraryContext';
import { AddBlockOmnibar } from './AddBlockOminbar';
import BlockDrawer from './BlockDrawer';
import { Size } from 'src/utils/size';
import BlockLibraryDialog from './BlockLibraryDialog';

export function WorkflowCanvas(p: { size: Size; children: JSX.Element }) {
  const nodeTypes: any = useMemo(
    () => ({
      block: BlockNode as unknown as ReactNode,
    }),
    []
  );
  const viewport = useViewport();
  useEffect(() => {
    dispatch({
      type: WorkflowAction.OFFSET_VIEWPORT,
      offset: { x: viewport.x, y: viewport.y },
    });
  }, [viewport]);

  const { state, dispatch } = useWorkflowContext();
  const nodes = useMemo(
    () => state.nodes.filter((f) => f.data.isCollapsed !== true),
    [state.nodes]
  );
  return (
    <ReactFlow
      style={{ height: p.size.height ?? 'calc(100vh - 44px)' }}
      snapToGrid={true}
      snapGrid={[4, 4]}
      nodeTypes={nodeTypes}
      onMouseMove={(e) => dispatch({ type: WorkflowAction.MOVE_CURSOR, cursor: { x: e.clientX, y: e.clientY } })}
      className={classNames('flex-grow relative')}
      onEdgesChange={(changes) =>
        dispatch({ type: WorkflowAction.CHANGE_EDGES, changes })
      }
      onNodesChange={(changes) =>
        dispatch({ type: WorkflowAction.CHANGE_NODES, changes })
      }
      onConnect={(connection) =>
        dispatch({ type: WorkflowAction.ADD_CONNECTION, connection })
      }
      nodes={nodes}
      edges={state.edges}
    >
      {p.children}
      <Background variant={BackgroundVariant.Lines} />
    </ReactFlow>);
}
