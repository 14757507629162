import { WorkflowState, WorkflowView } from '../workflow/types';
import { Dispatch, useCallback, useReducer } from 'react';
import {
  WorkflowRunActions,
  reduce as runReducer,
  WorkflowRunActionType,
} from './reducers/run';
import {
  WorkflowDesignerActions,
  reduce as designerReducer,
  WorkflowDesignerActionType,
} from './reducers/designer';
import {
  WorkflowLayoutActions,
  reduce as layoutReducer,
  WorkflowLayoutActionType,
} from './reducers/layout';
import {
  WorkflowNodeActions,
  reduce as nodeReducer,
  WorkflowNodeActionType,
} from './reducers/nodes';
import {
  WorkflowViewportActions,
  reduce as viewportReducer,
  WorkflowViewportActionType,
} from './reducers/viewport';
import {
  WorkflowParamsActions,
  reduce as paramsReducer,
  WorkflowParamsActionType,
} from './reducers/parameters';
import {
  WorkflowSelectionActions,
  reduce as selectionReducer,
  WorkflowSelectionActionType,
} from './reducers/selection';
import { v4 } from 'src/utils/uuid';

export const reducers = [
  runReducer,
  designerReducer,
  layoutReducer,
  nodeReducer,
  viewportReducer,
  paramsReducer,
  selectionReducer,
];

export const WorkflowAction = {
  ...WorkflowRunActionType,
  ...WorkflowDesignerActionType,
  ...WorkflowLayoutActionType,
  ...WorkflowNodeActionType,
  ...WorkflowParamsActionType,
  ...WorkflowSelectionActionType,
  ...WorkflowViewportActionType,
};

export type WorkflowActions =
  | WorkflowRunActions
  | WorkflowDesignerActions
  | WorkflowLayoutActions
  | WorkflowNodeActions
  | WorkflowParamsActions
  | WorkflowSelectionActions
  | WorkflowViewportActions;

export type ReduceFn = (
  state: WorkflowState,
  action: WorkflowActions
) => WorkflowState;

export const emptyState: WorkflowState = {
  id: v4(),
  view: WorkflowView.CANVAS,
  name: 'New Workflow',
  nodes: [],
  nodeIndexById: {},
  edges: [],
  viewport: {
    size: { width: 0, height: 0 },
    offset: { x: 0, y: 0 },
    center: { x: 0, y: 0 },
    cursor: { x: 0, y: 0 },
  },
  drawer: {
    isOpen: true,
    width: 460,
  },
  libraryDialog: {
    isOpen: false,
  },
  showAddDialog: false,
  isRunning: false,
  isReady: false,
  selectedNodeIds: [],
  subselectionNodeIndex: 0,
  minimalLayout: false,
};

export function useWorkflowReducer(
  reducers: ReduceFn[]
): [WorkflowState, Dispatch<WorkflowActions>] {
  const combinedReducers = useCallback(
    (prev: WorkflowState, action: WorkflowActions) => {
      return reducers.reduce((state, fn) => {
        const res = fn(state, action);
        if (res == null) {
          throw new Error('A reducer returned a null state');
        }
        return res;
      }, prev);
    },
    [reducers]
  );

  const [state, dispatch] = useReducer(combinedReducers, emptyState);

  return [state, dispatch];
}
