import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Query,
  Question,
  SurveyClusterQuota,
  SurveyClusterQuotas,
} from 'src/apps/athena/gql-types';
import { QUERY } from 'src/graphql/surveys';
import {
  SurveyQuestionData,
  useGetSurveyWithQuestions,
  useGetSurveyQuestions,
} from 'src/pages/Survey/SurveyPage';
import { useGraphQLFetch } from 'src/utils/graphql';
import CenteredSpinner from '../CenteredSpinner';

function getQuestionBody(
  questions: Partial<SurveyQuestionData>[],
  questionId: string,
  optionId: string
): string {
  const question = questions.find((d) => d.id === questionId);
  return question?.options?.find((d) => d.id === optionId)?.body ?? optionId;
}

export default function SurveyClusterPage(p: { surveyId: string }) {
  return <SurveyCluster surveyId={p.surveyId} id={p.surveyId} />;
}

export function SurveyCluster(p: { surveyId: string; id: string }) {
  const graphqlFetch = useGraphQLFetch();
  const { surveyId, id } = p;
  const { data: surveyData } = useGetSurveyWithQuestions(p.surveyId);

  const { data } = useQuery<Pick<Query, 'surveyCluster'>>(
    ['surveyCluster', id],
    () => graphqlFetch(QUERY.GET_SURVEY_CLUSTER, { id })
  );

  if (data == null) {
    return <CenteredSpinner />;
  }

  const clusters: SurveyClusterQuotas[] =
    data.surveyCluster?.clusterQuotas ?? [];
  const numberOfCluster = clusters.length ?? 0;
  const columns = Math.ceil(Math.sqrt(numberOfCluster));

  return (
    <div className={`p-4 grid grid-cols-${columns}`}>
      {clusters.map((c) => (
        <div className="bg-white border relative h-36 flex flex-col items-center justify-center">
          <div
            className="absolute h-3 left-0 bottom-0 bg-blue-500"
            style={{
              width: `${Math.min(100, 100 * (c.amount / c.target))}%`,
            }}
          ></div>
          <div className="flex flex-col items-center">
            {c.quotas.map((d, index, total) => (
              <>
                <div className="font-semibold">
                  {getQuestionBody(
                    surveyData.survey.questions ?? [],
                    d.questionId,
                    d.optionId
                  )}
                </div>
                {isNotLast(index, total) ? (
                  <div className="text-blue-600">x</div>
                ) : (
                  <span className="text-gray-500 py-1">
                    {c.amount} su {c.target}
                  </span>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function isNotLast(index: number, total: any[]) {
  return index < total.length - 1;
}
