import React from 'react';
import { Navbar, Classes, Alignment, Button } from '@blueprintjs/core';
import AppCrumbs from './AppCrumbs';
import { useEnvironment } from 'src/context/EnvironmentContext';
import { useLoginContext } from 'src/context/LoginContext';
import { NotificationsButton } from './NotificationsPopover';
import LanguageButton from './LanguageButton';
import { TicketButton } from './TicketPopover';
import { PhoneButton } from './Phone';
import css from 'csz';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppSearch } from './AppSearch';
import classNames from 'classnames';

const logoClassName = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    cursor: pointer;
    transition: transform ease-in 0.1s;
    width: 38px;
    &:hover {
      transform: rotate(6deg) scale(1.1);
    }
  }
`;

export default function NavigationBar() {
  const { APP_LOGO, APP_TITLE } = useEnvironment();
  const { state } = useLoginContext();
  const navigate = useNavigate();
  const location = useLocation();

  const redeem = location.pathname.endsWith('redeem');

  return state.loggedIn && !redeem ? (
    <Navbar className={classNames(Classes.DARK, 'z-20')}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading className={logoClassName}>
          <img src={APP_LOGO} alt={APP_TITLE} onClick={() => navigate('/')} />
        </Navbar.Heading>
        <Navbar.Divider />
        <AppSearch className="mx-3" />
        <Navbar.Divider />
        <AppCrumbs />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <NotificationsButton />
        <LanguageButton />
        <Account />
      </Navbar.Group>
    </Navbar>
  ) : (
    <React.Fragment />
  );
}

function Account() {
  const { state, actions } = useLoginContext();
  const { loggedIn } = state;
  if (loggedIn) {
    return (
      <Button
        large
        minimal
        icon="log-out"
        text="Logout"
        onClick={actions.logout}
      />
    );
  }
  return null;
}
