export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Activities = {
  __typename?: 'Activities';
  items?: Maybe<Array<Maybe<Activity>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Activity = {
  __typename?: 'Activity';
  action: ActivityActionType;
  date: Scalars['String'];
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  source: ActivitySource;
  target: ActivityTarget;
  type: ActivityType;
};

export enum ActivityActionType {
  Accepted = 'ACCEPTED',
  Done = 'DONE',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Unavailable = 'UNAVAILABLE'
}

export type ActivityInput = {
  action: ActivityActionType;
  date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  target: ActivityTargetInput;
  type: ActivityType;
};

export type ActivityPrice = {
  __typename?: 'ActivityPrice';
  adminCost?: Maybe<Price>;
  cost: Price;
};

export type ActivityPriceInput = {
  adminCost?: InputMaybe<PriceInput>;
  cost: PriceInput;
};

export type ActivitySource = {
  __typename?: 'ActivitySource';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type ActivityTarget = {
  __typename?: 'ActivityTarget';
  id: Scalars['String'];
  kind: ActivityTargetKind;
  name?: Maybe<Scalars['String']>;
};

export type ActivityTargetInput = {
  id: Scalars['String'];
  kind: ActivityTargetKind;
};

export enum ActivityTargetKind {
  Company = 'COMPANY',
  Person = 'PERSON'
}

export enum ActivityType {
  FollowUp = 'FOLLOW_UP',
  Meeting = 'MEETING',
  PhoneCall = 'PHONE_CALL',
  Sms = 'SMS',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP'
}

export type AddQuotationRecordInput = {
  activityId?: InputMaybe<Scalars['String']>;
  costPrice: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  markup: Scalars['Float'];
  quantity?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  unit: QuotationUnit;
  vatId?: InputMaybe<Scalars['String']>;
};

export type AddTreeNodeInput = {
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AgedVariableValue = {
  __typename?: 'AgedVariableValue';
  date?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  probability: Scalars['Float'];
  refs?: Maybe<Array<Maybe<VariableRef>>>;
  value: Scalars['JSON'];
  variableId: Scalars['String'];
};

export type AgedVariableValues = {
  __typename?: 'AgedVariableValues';
  items?: Maybe<Array<Maybe<AgedVariableValue>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AgeingFunction = {
  kind: AgeingFunctionKind;
};

export type AgeingFunctionInput = {
  a?: InputMaybe<Scalars['Float']>;
  kind: AgeingFunctionKind;
  timeUnit?: InputMaybe<AgeingFunctionTimeUnit>;
  z?: InputMaybe<Scalars['Float']>;
};

export enum AgeingFunctionKind {
  Curve = 'CURVE',
  Linear = 'LINEAR',
  Never = 'NEVER'
}

export enum AgeingFunctionTimeUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
  Years = 'YEARS'
}

export enum AssignmentState {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export type Automation = {
  __typename?: 'Automation';
  executionIntervals?: Maybe<Array<Maybe<ExecutionInterval>>>;
  frequency: AutomationFrequency;
  id: Scalars['String'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  projectList?: Maybe<Ref>;
  state: AutomationState;
  steps: Array<Maybe<AutomationStep>>;
};

export type AutomationFrequency = {
  __typename?: 'AutomationFrequency';
  amount: Scalars['Int'];
  unit: TimeUnit;
};

export type AutomationFrequencyInput = {
  amount?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<TimeUnit>;
};

export type AutomationInput = {
  executionIntervals?: InputMaybe<Array<InputMaybe<ExecutionIntervalInput>>>;
  frequency: AutomationFrequencyInput;
  name: Scalars['String'];
  projectListId: Scalars['String'];
  steps: Array<InputMaybe<AutomationStepInput>>;
};

export enum AutomationState {
  Paused = 'PAUSED',
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

export type AutomationStep = {
  __typename?: 'AutomationStep';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: AutomationStepType;
};

export type AutomationStepInput = {
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: AutomationStepType;
};

export enum AutomationStepType {
  ConsoleLog = 'CONSOLE_LOG',
  SendEmail = 'SEND_EMAIL',
  SendSms = 'SEND_SMS'
}

export type Automations = {
  __typename?: 'Automations';
  items?: Maybe<Array<Maybe<Automation>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BasicLocation = {
  __typename?: 'BasicLocation';
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  countryCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  place_id?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type BasicPerson = {
  __typename?: 'BasicPerson';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type BasicProjectListData = {
  __typename?: 'BasicProjectListData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Candidate = {
  __typename?: 'Candidate';
  assignment?: Maybe<CandidateAssignmentData>;
  id: Scalars['String'];
  person: BasicPerson;
  projectId: Scalars['String'];
  projectList?: Maybe<BasicProjectListData>;
};

export type CandidateAssignmentData = {
  __typename?: 'CandidateAssignmentData';
  id?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  state?: Maybe<AssignmentState>;
};

export type Candidates = {
  __typename?: 'Candidates';
  items?: Maybe<Array<Maybe<Candidate>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Certificate = {
  __typename?: 'Certificate';
  competence: Scalars['String'];
  expirationDate: Scalars['String'];
  hasFgasDiscount?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  invoice?: Maybe<CertificateInvoice>;
  issueDate: Scalars['String'];
  nextRenewalDate?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  owner: Ref;
  partner?: Maybe<Ref>;
  renewals: Array<Maybe<CertificateRenewal>>;
  revision: Scalars['Int'];
  status: CertificateStatus;
  type: CertificateType;
};

export type CertificateInput = {
  competence: Scalars['String'];
  issueDate: Scalars['String'];
  number: Scalars['String'];
  owner: Scalars['String'];
  partner?: InputMaybe<Scalars['String']>;
};

export type CertificateInvoice = {
  __typename?: 'CertificateInvoice';
  date: Scalars['String'];
  number: Scalars['String'];
};

export type CertificateRenewal = {
  __typename?: 'CertificateRenewal';
  adminCost?: Maybe<Price>;
  cost: Price;
  date: Scalars['String'];
  documentDate?: Maybe<Scalars['String']>;
  invoice?: Maybe<CertificateInvoice>;
  isDocumentReceived?: Maybe<Scalars['Boolean']>;
  isPaymentReceived?: Maybe<Scalars['Boolean']>;
  paymentDate?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['String']>;
  status: CertificateRenewalStatus;
};

export type CertificateRenewalNotification = {
  __typename?: 'CertificateRenewalNotification';
  address?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  certificateNumber: Scalars['String'];
  certificateType?: Maybe<Scalars['String']>;
  certifyUrl: Scalars['String'];
  date: Scalars['String'];
  email: Scalars['String'];
  expirationDate: Scalars['String'];
  fiscalCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  sdi?: Maybe<Scalars['String']>;
  sentAt: Scalars['String'];
  shouldCheckEmail?: Maybe<Scalars['Boolean']>;
  templateId: Scalars['String'];
  type: CertificateRenewalNotificationType;
  vatNumber?: Maybe<Scalars['String']>;
};

export enum CertificateRenewalNotificationType {
  FirstExpiredReminderSent = 'FIRST_EXPIRED_REMINDER_SENT',
  FirstRenewalReminderSent = 'FIRST_RENEWAL_REMINDER_SENT',
  RenewalRequestSent = 'RENEWAL_REQUEST_SENT',
  SecondExpiredReminderSent = 'SECOND_EXPIRED_REMINDER_SENT',
  SecondRenewalReminderSent = 'SECOND_RENEWAL_REMINDER_SENT'
}

export type CertificateRenewalNotifications = {
  __typename?: 'CertificateRenewalNotifications';
  items: Array<Maybe<CertificateRenewalNotification>>;
  pageInfo?: Maybe<Scalars['JSON']>;
};

export enum CertificateRenewalStatus {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Idle = 'IDLE'
}

export type CertificateRenewalsNotification = {
  __typename?: 'CertificateRenewalsNotification';
  id: Scalars['String'];
  renewals?: Maybe<Array<Maybe<CertificateRenewalNotification>>>;
};

export enum CertificateStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED'
}

export enum CertificateType {
  Company = 'COMPANY',
  Person = 'PERSON'
}

export type Certificates = {
  __typename?: 'Certificates';
  items?: Maybe<Array<Maybe<Certificate>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum CivilStatusType {
  Cohabitating = 'COHABITATING',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widow = 'WIDOW'
}

export type Companies = {
  __typename?: 'Companies';
  items?: Maybe<Array<Maybe<Company>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Company = {
  __typename?: 'Company';
  bankAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  certificates?: Maybe<Array<Maybe<Ref>>>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  emailAddresses?: Maybe<Array<Maybe<EmailAddress>>>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  fiscalCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  irCode?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  reaNumber?: Maybe<Scalars['String']>;
  registrationSource?: Maybe<Scalars['String']>;
  revision: Scalars['Int'];
  sdi?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  teseoCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyBasicInfo = {
  bankAccounts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  discount?: InputMaybe<Scalars['Float']>;
  fiscalCode?: InputMaybe<Scalars['String']>;
  irCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pec?: InputMaybe<Scalars['String']>;
  reaNumber?: InputMaybe<Scalars['String']>;
  registrationSource?: InputMaybe<Scalars['String']>;
  sdi?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teseoCode?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type Competence = {
  __typename?: 'Competence';
  activities: Array<Maybe<CompetenceActivity>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  numRenewals?: Maybe<Scalars['Int']>;
  type: CompetenceType;
};

export type CompetenceActivity = {
  __typename?: 'CompetenceActivity';
  id: Scalars['String'];
  price: ActivityPrice;
};

export type CompetenceInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  numRenewals?: InputMaybe<Scalars['Int']>;
  type: CompetenceType;
};

export enum CompetenceType {
  Company = 'COMPANY',
  Person = 'PERSON'
}

export type Competences = {
  __typename?: 'Competences';
  items: Array<Maybe<Competence>>;
  pageInfo: PageInfo;
};

export type ConfidenceIntervalMetric = {
  __typename?: 'ConfidenceIntervalMetric';
  centerOfInterval?: Maybe<Scalars['Float']>;
  lowerEndOfInterval?: Maybe<Scalars['Float']>;
  marginOfError?: Maybe<Scalars['Float']>;
  upperEndOfInterval?: Maybe<Scalars['Float']>;
};

export type ConsumerData = {
  __typename?: 'ConsumerData';
  id: Scalars['String'];
  poolMember: Scalars['Int'];
  poolSize: Scalars['Int'];
  streamName: Scalars['String'];
  subscriberId: Scalars['String'];
};

export type ConversionResult = {
  __typename?: 'ConversionResult';
  result?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type CoordinatesInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type CurveAgeingFunction = AgeingFunction & {
  __typename?: 'CurveAgeingFunction';
  a: Scalars['Float'];
  kind: AgeingFunctionKind;
};

export enum DocumentType {
  DrivingLicense = 'DRIVING_LICENSE',
  IdentityCard = 'IDENTITY_CARD',
  Passport = 'PASSPORT'
}

export type EditTreeNodeInput = {
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum EducationLevel {
  BachelorDegree = 'BACHELOR_DEGREE',
  Doctorate = 'DOCTORATE',
  MasterDegree = 'MASTER_DEGREE',
  Primary = 'PRIMARY',
  SecondaryFirstGrade = 'SECONDARY_FIRST_GRADE',
  SecondarySecondGrade = 'SECONDARY_SECOND_GRADE',
  SingleCycleDegree = 'SINGLE_CYCLE_DEGREE',
  Tertiary = 'TERTIARY'
}

export enum EffectType {
  CloseSurvey = 'CLOSE_SURVEY',
  HideOption = 'HIDE_OPTION',
  HideQuestion = 'HIDE_QUESTION',
  ShowOption = 'SHOW_OPTION',
  ShowQuestion = 'SHOW_QUESTION',
  ShowSurvey = 'SHOW_SURVEY'
}

export type Email = {
  __typename?: 'Email';
  attachments?: Maybe<Array<Maybe<EmailAttachment>>>;
  errorMessage?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  sentAt: Scalars['String'];
  status?: Maybe<EmailStatus>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type EmailAccount = {
  __typename?: 'EmailAccount';
  account?: Maybe<EmailAccountInfo>;
  address: Scalars['String'];
  id: Scalars['String'];
};

export type EmailAccountInfo = {
  __typename?: 'EmailAccountInfo';
  apiKey?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  secure?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
};

export type EmailAccountInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  pass?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['Int']>;
  secure?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export type EmailAccounts = {
  __typename?: 'EmailAccounts';
  items?: Maybe<Array<Maybe<EmailAccount>>>;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type EmailAddressRecord = {
  emailAddress: Scalars['String'];
  id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type EmailInput = {
  attachments?: InputMaybe<Array<InputMaybe<UploadedFile>>>;
  bcc?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateInput?: InputMaybe<Scalars['JSON']>;
  text?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
};

export enum EmailStatus {
  Failed = 'FAILED',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  attachments?: Maybe<Array<Maybe<EmailAttachment>>>;
  body?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type EmailTemplates = {
  __typename?: 'EmailTemplates';
  items?: Maybe<Array<Maybe<EmailTemplate>>>;
};

export type Emails = {
  __typename?: 'Emails';
  items?: Maybe<Array<Maybe<Email>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Employee = {
  __typename?: 'Employee';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Employer = {
  __typename?: 'Employer';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  resources: Array<Maybe<Scalars['String']>>;
  timestamp: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<Ref>;
};

export type Events = {
  __typename?: 'Events';
  items?: Maybe<Array<Maybe<Event>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExecutionInterval = {
  __typename?: 'ExecutionInterval';
  exclude: Scalars['Boolean'];
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ExecutionIntervalInput = {
  exclude: Scalars['Boolean'];
  from: Scalars['String'];
  to: Scalars['String'];
};

export type Filter = {
  __typename?: 'Filter';
  conditions: Array<Maybe<Scalars['JSON']>>;
  effects: Array<Maybe<FilterEffect>>;
  id: Scalars['String'];
};

export type FilterEffect = {
  __typename?: 'FilterEffect';
  id?: Maybe<Scalars['String']>;
  target: Scalars['JSON'];
  type: EffectType;
};

export type FilterEffectInput = {
  id: Scalars['String'];
  target: Scalars['JSON'];
  type: EffectType;
};

export type FilterInput = {
  conditions?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  effects?: InputMaybe<Array<InputMaybe<FilterEffectInput>>>;
  id: Scalars['String'];
};

export type FrequencyOptionResult = {
  __typename?: 'FrequencyOptionResult';
  amount: Scalars['Int'];
  group?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  optionIds: Array<Maybe<Scalars['String']>>;
  percentage: Scalars['Float'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GiftCard = {
  __typename?: 'GiftCard';
  amounts?: Maybe<Array<Maybe<Scalars['Int']>>>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GiftCards = {
  __typename?: 'GiftCards';
  items?: Maybe<Array<Maybe<GiftCard>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type InviteInput = {
  chatId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['String']>;
  shareId?: InputMaybe<Scalars['String']>;
};

export type InviteSentRespone = {
  __typename?: 'InviteSentRespone';
  errorMessage?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  artifacts?: Maybe<Array<Maybe<InvoiceArtifact>>>;
  client: InvoiceActor;
  date: Scalars['String'];
  division?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  netTotal: Scalars['Float'];
  progressiveNumber: Scalars['Int'];
  quotation: InvoiceQuotation;
  status: InvoiceStatus;
  subtotal?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  vatTotal: Scalars['Float'];
  vendor: InvoiceActor;
  year: Scalars['Int'];
};

export type InvoiceActor = {
  __typename?: 'InvoiceActor';
  firstName?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sdi: Scalars['String'];
  type: QuotationActorType;
  vatId?: Maybe<Scalars['String']>;
};

export type InvoiceArtifact = {
  __typename?: 'InvoiceArtifact';
  kind: InvoiceArtifactKind;
  path: Scalars['String'];
};

export enum InvoiceArtifactKind {
  AssosoftwarePdf = 'ASSOSOFTWARE_PDF',
  FatturaPaPdf = 'FATTURA_PA_PDF',
  FatturaPaXml = 'FATTURA_PA_XML'
}

export type InvoiceInput = {
  date?: InputMaybe<Scalars['String']>;
  division?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type InvoiceQuotation = {
  __typename?: 'InvoiceQuotation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum InvoiceStatus {
  Delivered = 'DELIVERED',
  Generated = 'GENERATED',
  Rejected = 'REJECTED',
  SentToSdi = 'SENT_TO_SDI',
  Undeliverable = 'UNDELIVERABLE'
}

export type Invoices = {
  __typename?: 'Invoices';
  items?: Maybe<Array<Maybe<Invoice>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LinearAgeingFunction = AgeingFunction & {
  __typename?: 'LinearAgeingFunction';
  a: Scalars['Float'];
  kind: AgeingFunctionKind;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  location: BasicLocation;
  type?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<CoordinatesInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type LocationRecord = {
  id: Scalars['String'];
  location?: InputMaybe<LocationInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  displayName?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  isEmailToBeVerified?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isFromSurveyWizard?: Maybe<Scalars['Boolean']>;
  isPhoneToBeVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  loggedIn: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  data?: Maybe<Scalars['JSON']>;
  global_position: Scalars['Int'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  position: Scalars['Int'];
  stream_name: Scalars['String'];
  time: Scalars['String'];
  type: Scalars['String'];
};

export type MessageStoreMessage = {
  __typename?: 'MessageStoreMessage';
  data?: Maybe<Scalars['JSON']>;
  global_position: Scalars['Int'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  position: Scalars['Int'];
  stream_name: Scalars['String'];
  time: Scalars['String'];
  type: Scalars['String'];
};

export type MessageStoreQuery = {
  limit?: InputMaybe<Scalars['Int']>;
  sincePosition?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  streamName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  untilPosition?: InputMaybe<Scalars['Int']>;
};

export type MessageStoreStats = {
  __typename?: 'MessageStoreStats';
  runLoop: RunLoopStats;
  startDate: Scalars['String'];
  uptime: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptQuotation?: Maybe<Scalars['String']>;
  /** Company */
  addCompany?: Maybe<Scalars['String']>;
  addCompanyEmployee?: Maybe<Scalars['String']>;
  addCompetence?: Maybe<Scalars['String']>;
  /** Email Address */
  addEmailAddress?: Maybe<Scalars['String']>;
  /** Filters */
  addFilter?: Maybe<Scalars['String']>;
  /** GIFT_CARDS */
  addGiftCard?: Maybe<Scalars['String']>;
  /** Location */
  addLocation?: Maybe<Scalars['String']>;
  addPartner?: Maybe<Scalars['String']>;
  /** Person */
  addPerson?: Maybe<Scalars['String']>;
  /** Phone Numbers */
  addPhoneNumber?: Maybe<Scalars['String']>;
  /** Candidate */
  addProjectCandidate?: Maybe<Scalars['String']>;
  /** List */
  addProjectList?: Maybe<Scalars['String']>;
  addQuestionOptions?: Maybe<Scalars['String']>;
  addQuotationRecord?: Maybe<Scalars['String']>;
  addSurveyQuestions?: Maybe<Scalars['String']>;
  addTreeNode?: Maybe<Scalars['String']>;
  addUploadResource?: Maybe<Scalars['String']>;
  addUploadTag?: Maybe<Scalars['String']>;
  addUploadType?: Maybe<Scalars['String']>;
  addVat?: Maybe<Scalars['String']>;
  /** Work Experience */
  addWorkExperience?: Maybe<Scalars['String']>;
  answerQuestion?: Maybe<Scalars['String']>;
  applySubscription?: Maybe<Scalars['String']>;
  broadcastTelegramMessage: Scalars['String'];
  cancelCertificate?: Maybe<Scalars['String']>;
  changeSurveyShareQuota?: Maybe<Scalars['String']>;
  commentTicket: Scalars['String'];
  completeForgotPassword?: Maybe<Scalars['String']>;
  confirmUserEmail?: Maybe<Scalars['String']>;
  confirmUserPhone?: Maybe<Scalars['String']>;
  convertDocxToPdf?: Maybe<ConversionResult>;
  createNote?: Maybe<Scalars['String']>;
  /** Project */
  createProject?: Maybe<Scalars['String']>;
  /** Automation */
  createProjectAutomation?: Maybe<Scalars['String']>;
  createQuestion?: Maybe<Scalars['String']>;
  createQuotation?: Maybe<Scalars['String']>;
  createQuotationRevision?: Maybe<Scalars['String']>;
  createSurvey?: Maybe<Scalars['String']>;
  /** Category */
  createSurveyCategory?: Maybe<Scalars['String']>;
  createSurveyWizard?: Maybe<Scalars['String']>;
  /** Subscriptions */
  createWizardSubscription?: Maybe<Scalars['String']>;
  defineVariable?: Maybe<Scalars['String']>;
  deleteNote?: Maybe<Scalars['String']>;
  deleteSubscriberConsumer?: Maybe<Scalars['Boolean']>;
  deleteSubscriberConsumers?: Maybe<Scalars['Boolean']>;
  deliveryGiftCard?: Maybe<Scalars['String']>;
  disableSurveyShare?: Maybe<Scalars['String']>;
  disableUser?: Maybe<Scalars['String']>;
  /** EARN_CREDITS */
  earnCredits?: Maybe<Scalars['String']>;
  earnDelayedCredits?: Maybe<Scalars['String']>;
  enableSurveyShare?: Maybe<Scalars['String']>;
  enableUser?: Maybe<Scalars['String']>;
  finalizeQuotation?: Maybe<Scalars['String']>;
  forceConfirmUser?: Maybe<Scalars['String']>;
  forgotPassword?: Maybe<Scalars['String']>;
  generateInvoice?: Maybe<Scalars['String']>;
  generateQuotationPdf?: Maybe<Scalars['String']>;
  getUploadUrl?: Maybe<UploadInfo>;
  hideQuotationRecord?: Maybe<Scalars['String']>;
  issueCertificate?: Maybe<Scalars['String']>;
  loginUser?: Maybe<LoginResponse>;
  markAllNotificationsAsRead?: Maybe<Scalars['String']>;
  markDocumentationReceived?: Maybe<Scalars['String']>;
  markInvoiceReceived?: Maybe<Scalars['String']>;
  markNotificationAsRead?: Maybe<Scalars['String']>;
  markPaymentReceived?: Maybe<Scalars['String']>;
  moveQuotationRecord?: Maybe<Scalars['String']>;
  moveSurveyQuestion?: Maybe<Scalars['String']>;
  moveTreeNode?: Maybe<Scalars['String']>;
  openTicket: Scalars['String'];
  pauseProjectAutomation?: Maybe<Scalars['String']>;
  pinNote?: Maybe<Scalars['String']>;
  recordActivity?: Maybe<Scalars['String']>;
  redeemGiftCard?: Maybe<Scalars['String']>;
  registerSurveyWizardUser?: Maybe<Scalars['String']>;
  registerUser?: Maybe<Scalars['String']>;
  rejectQuotation?: Maybe<Scalars['String']>;
  removeCompany?: Maybe<Scalars['String']>;
  removeCompanyEmployee?: Maybe<Scalars['String']>;
  removeCompetence?: Maybe<Scalars['String']>;
  removeEmailAddress?: Maybe<Scalars['String']>;
  removeFilter?: Maybe<Scalars['String']>;
  removeGiftCard?: Maybe<Scalars['String']>;
  removeLocation?: Maybe<Scalars['String']>;
  removePartner?: Maybe<Scalars['String']>;
  removePartnerActivityCost?: Maybe<Scalars['String']>;
  removePerson?: Maybe<Scalars['String']>;
  removePhoneNumber?: Maybe<Scalars['String']>;
  removeProjectAutomation?: Maybe<Scalars['String']>;
  removeProjectCandidate?: Maybe<Scalars['String']>;
  removeQuestionOption?: Maybe<Scalars['String']>;
  removeQuotationRecord?: Maybe<Scalars['String']>;
  removeSurveyAsTemplate?: Maybe<Scalars['String']>;
  removeSurveyFrequency?: Maybe<Scalars['String']>;
  removeSurveyQuestion?: Maybe<Scalars['String']>;
  removeSurveyQuota?: Maybe<Scalars['String']>;
  removeSurveyShare?: Maybe<Scalars['String']>;
  removeSurveyWizardKey?: Maybe<Scalars['String']>;
  removeTreeNode?: Maybe<Scalars['String']>;
  removeUpload?: Maybe<Scalars['String']>;
  removeUploadResource?: Maybe<Scalars['String']>;
  removeUploadTag?: Maybe<Scalars['String']>;
  removeUploadType?: Maybe<Scalars['String']>;
  removeVat?: Maybe<Scalars['String']>;
  removeWorkExperience?: Maybe<Scalars['String']>;
  renameCertificate?: Maybe<Scalars['String']>;
  renameUpload?: Maybe<Scalars['String']>;
  renameUploadType?: Maybe<Scalars['String']>;
  resendCertificateRenewalNotification?: Maybe<Scalars['String']>;
  resendUserEmailConfirmation?: Maybe<Scalars['String']>;
  resendUserPhoneConfirmation?: Maybe<Scalars['String']>;
  restoreCertificate?: Maybe<Scalars['String']>;
  reupload?: Maybe<Scalars['String']>;
  revokeCertificate?: Maybe<Scalars['String']>;
  /** Simply a proxy. */
  saveRecruitment?: Maybe<Scalars['JSON']>;
  saveWorkflow?: Maybe<Scalars['String']>;
  sayHello?: Maybe<Scalars['String']>;
  sendAutomotiveMessage?: Maybe<Scalars['String']>;
  sendCertificateRenewal?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['String']>;
  sendEmailQuotation?: Maybe<Scalars['String']>;
  sendInviteLink?: Maybe<InviteSentRespone>;
  sendInvoiceToSdi?: Maybe<Scalars['String']>;
  /** Assignment */
  sendProjectAssignment?: Maybe<Scalars['String']>;
  sendSMS?: Maybe<Scalars['String']>;
  sendTelegramMessage: Scalars['String'];
  sendTelegramQuestion?: Maybe<Scalars['String']>;
  setCertificateIssueDate?: Maybe<Scalars['String']>;
  setCertificateManualPrice?: Maybe<Scalars['String']>;
  setCertificatePartner?: Maybe<Scalars['String']>;
  setCompanyDiscount?: Maybe<Scalars['String']>;
  setCompetenceActivityPrice?: Maybe<Scalars['String']>;
  setEmailAccount?: Maybe<Scalars['String']>;
  setEmailTemplate?: Maybe<Scalars['String']>;
  setNoteBody?: Maybe<Scalars['String']>;
  setNoteRecipients?: Maybe<Scalars['String']>;
  setNoteReminder?: Maybe<Scalars['String']>;
  setNoteTitle?: Maybe<Scalars['String']>;
  setPartnerActivityCost?: Maybe<Scalars['String']>;
  setProjectBriefing?: Maybe<Scalars['String']>;
  setProjectDetails?: Maybe<Scalars['String']>;
  setProjectEvaluationSurvey?: Maybe<Scalars['String']>;
  setQuestionTextToTemplateKeyword?: Maybe<Scalars['String']>;
  setQuestionTranslation?: Maybe<Scalars['String']>;
  setQuotationClient?: Maybe<Scalars['String']>;
  setQuotationClientRepresentative?: Maybe<Scalars['String']>;
  setQuotationCustomField?: Maybe<Scalars['String']>;
  setQuotationDate?: Maybe<Scalars['String']>;
  setQuotationDiscount?: Maybe<Scalars['String']>;
  setQuotationExpirationDate?: Maybe<Scalars['String']>;
  setQuotationNote?: Maybe<Scalars['String']>;
  setQuotationRecordGroup?: Maybe<Scalars['String']>;
  setQuotationTitle?: Maybe<Scalars['String']>;
  setQuotationVendorRepresentative?: Maybe<Scalars['String']>;
  setSubscriberPositions?: Maybe<Scalars['Boolean']>;
  setSubscriberSize?: Maybe<Scalars['Boolean']>;
  setSubscribersPosition?: Maybe<Scalars['Boolean']>;
  /** Template */
  setSurveyAsTemplate?: Maybe<Scalars['String']>;
  /** Frequencies */
  setSurveyFrequency?: Maybe<Scalars['String']>;
  /** Labels and style */
  setSurveyLabels?: Maybe<Scalars['String']>;
  setSurveyLanguages?: Maybe<Scalars['String']>;
  setSurveyQuota?: Maybe<Scalars['String']>;
  /** Survey State */
  setSurveyState?: Maybe<Scalars['String']>;
  setSurveyStyle?: Maybe<Scalars['String']>;
  /** Quotas */
  setSurveyTarget?: Maybe<Scalars['String']>;
  /** Tags */
  setTags?: Maybe<Scalars['String']>;
  setTreeNodeTranslation?: Maybe<Scalars['String']>;
  setUploadDate?: Maybe<Scalars['String']>;
  setUploadType?: Maybe<Scalars['String']>;
  setVariableValue?: Maybe<Scalars['String']>;
  /** Survey Share */
  shareSurvey?: Maybe<Scalars['String']>;
  startProjectAutomation?: Maybe<Scalars['String']>;
  /** Starts the survey session. */
  startSurveySession?: Maybe<Scalars['String']>;
  startTelegramSurvey?: Maybe<Scalars['String']>;
  stopProjectAutomation?: Maybe<Scalars['String']>;
  suspendCertificate?: Maybe<Scalars['String']>;
  transferCertificate?: Maybe<Scalars['String']>;
  unhideQuotationRecord?: Maybe<Scalars['String']>;
  unmarkDocumentationReceived?: Maybe<Scalars['String']>;
  unmarkInvoiceReceived?: Maybe<Scalars['String']>;
  unmarkPaymentReceived?: Maybe<Scalars['String']>;
  unpinNote?: Maybe<Scalars['String']>;
  unsetNoteReminder?: Maybe<Scalars['String']>;
  unsetQuotationCustomField?: Maybe<Scalars['String']>;
  unsetQuotationDiscount?: Maybe<Scalars['String']>;
  updateCompany?: Maybe<Scalars['String']>;
  updateCompetence?: Maybe<Scalars['String']>;
  updateEmailAddress?: Maybe<Scalars['String']>;
  updateFilter?: Maybe<Scalars['String']>;
  updateGiftCard?: Maybe<Scalars['String']>;
  updateLocation?: Maybe<Scalars['String']>;
  updatePerson?: Maybe<Scalars['String']>;
  updatePhoneNumber?: Maybe<Scalars['String']>;
  updateProjectAutomation?: Maybe<Scalars['String']>;
  updateQuestion?: Maybe<Scalars['String']>;
  updateQuotationRecord?: Maybe<Scalars['String']>;
  updateSurveyQuestions?: Maybe<Scalars['String']>;
  updateSurveyWizardKey?: Maybe<Scalars['String']>;
  updateTicket: Scalars['String'];
  updateTreeNode?: Maybe<Scalars['String']>;
  updateUserDetails?: Maybe<Scalars['String']>;
  updateVat?: Maybe<Scalars['String']>;
  updateWorkExperience?: Maybe<Scalars['String']>;
  upload?: Maybe<Scalars['String']>;
  /** USE_CREDITS */
  useCredits?: Maybe<Scalars['String']>;
};


export type MutationAcceptQuotationArgs = {
  id: Scalars['String'];
  revision?: InputMaybe<Scalars['Int']>;
};


export type MutationAddCompanyArgs = {
  company?: InputMaybe<CompanyBasicInfo>;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationAddCompanyEmployeeArgs = {
  id: Scalars['String'];
  personId: Scalars['String'];
};


export type MutationAddCompetenceArgs = {
  competence: CompetenceInput;
};


export type MutationAddEmailAddressArgs = {
  emailAddress: EmailAddressRecord;
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationAddFilterArgs = {
  filter: FilterInput;
  surveyId: Scalars['String'];
};


export type MutationAddGiftCardArgs = {
  amounts?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationAddLocationArgs = {
  location: LocationRecord;
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationAddPartnerArgs = {
  partner: PartnerInput;
};


export type MutationAddPersonArgs = {
  id?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<PersonBasicInfo>;
};


export type MutationAddPhoneNumberArgs = {
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
  phoneNumber: PhoneNumberRecord;
};


export type MutationAddProjectCandidateArgs = {
  personId: Array<InputMaybe<Scalars['String']>>;
  projectId: Scalars['String'];
  projectListId?: InputMaybe<Scalars['String']>;
};


export type MutationAddProjectListArgs = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationAddQuestionOptionsArgs = {
  options: Array<InputMaybe<QuestionOptionsInput>>;
  questionId: Scalars['String'];
};


export type MutationAddQuotationRecordArgs = {
  quotationId: Scalars['String'];
  record: AddQuotationRecordInput;
};


export type MutationAddSurveyQuestionsArgs = {
  id: Scalars['String'];
  index?: InputMaybe<Scalars['Int']>;
  questions: Array<InputMaybe<SurveyQuestionInput>>;
};


export type MutationAddTreeNodeArgs = {
  node: AddTreeNodeInput;
};


export type MutationAddUploadResourceArgs = {
  id: Scalars['String'];
  resource: Scalars['String'];
};


export type MutationAddUploadTagArgs = {
  id: Scalars['String'];
  tag: Scalars['String'];
};


export type MutationAddUploadTypeArgs = {
  name: Scalars['String'];
};


export type MutationAddVatArgs = {
  vat: VatInput;
};


export type MutationAddWorkExperienceArgs = {
  experience: WorkExperienceInput;
  parentId: Scalars['String'];
};


export type MutationAnswerQuestionArgs = {
  answer?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  optionId?: InputMaybe<Scalars['JSON']>;
  questionId: Scalars['String'];
  sessionId?: InputMaybe<Scalars['String']>;
};


export type MutationApplySubscriptionArgs = {
  data: SurveyWizardSubscriptionInput;
  id: Scalars['String'];
};


export type MutationBroadcastTelegramMessageArgs = {
  message: TelegramBroadcastMessageInput;
};


export type MutationCancelCertificateArgs = {
  number: Scalars['String'];
};


export type MutationChangeSurveyShareQuotaArgs = {
  id: Scalars['String'];
  quota: Scalars['Int'];
};


export type MutationCommentTicketArgs = {
  comment?: InputMaybe<TicketCommentInput>;
};


export type MutationCompleteForgotPasswordArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
  password: Scalars['String'];
};


export type MutationConfirmUserEmailArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};


export type MutationConfirmUserPhoneArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};


export type MutationConvertDocxToPdfArgs = {
  filename?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type MutationCreateNoteArgs = {
  note: NoteInput;
};


export type MutationCreateProjectArgs = {
  id?: InputMaybe<Scalars['String']>;
  project: ProjectBasicDataInput;
};


export type MutationCreateProjectAutomationArgs = {
  automation: AutomationInput;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationCreateQuestionArgs = {
  question?: InputMaybe<QuestionInput>;
};


export type MutationCreateQuotationArgs = {
  quotation: QuotationInput;
};


export type MutationCreateQuotationRevisionArgs = {
  id: Scalars['String'];
};


export type MutationCreateSurveyArgs = {
  survey?: InputMaybe<SurveyInput>;
};


export type MutationCreateSurveyCategoryArgs = {
  category?: InputMaybe<SurveyCategoryInput>;
};


export type MutationCreateSurveyWizardArgs = {
  category?: InputMaybe<SurveyCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  keys: Array<InputMaybe<SurveyWizardKeyInput>>;
  surveyTemplateId: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateWizardSubscriptionArgs = {
  data: WizardSubscriptionInput;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDefineVariableArgs = {
  variable: VariableInput;
};


export type MutationDeleteNoteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSubscriberConsumerArgs = {
  consumerId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteSubscriberConsumersArgs = {
  id: Scalars['String'];
};


export type MutationDeliveryGiftCardArgs = {
  id: Scalars['String'];
  idCard: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationDisableSurveyShareArgs = {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};


export type MutationDisableUserArgs = {
  id: Scalars['String'];
};


export type MutationEarnCreditsArgs = {
  amount: Scalars['Int'];
  id: Scalars['String'];
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationEarnDelayedCreditsArgs = {
  amount: Scalars['Int'];
  id: Scalars['String'];
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationEnableSurveyShareArgs = {
  id: Scalars['String'];
};


export type MutationEnableUserArgs = {
  id: Scalars['String'];
};


export type MutationFinalizeQuotationArgs = {
  id: Scalars['String'];
};


export type MutationForceConfirmUserArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGenerateInvoiceArgs = {
  invoice: InvoiceInput;
};


export type MutationGenerateQuotationPdfArgs = {
  id: Scalars['String'];
  logo: Scalars['String'];
  revision?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  templateType?: InputMaybe<QuotationPdfTemplateType>;
};


export type MutationGetUploadUrlArgs = {
  name: Scalars['String'];
};


export type MutationHideQuotationRecordArgs = {
  id: Scalars['String'];
  quotationId: Scalars['String'];
};


export type MutationIssueCertificateArgs = {
  certificate: CertificateInput;
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMarkDocumentationReceivedArgs = {
  date: Scalars['String'];
  number: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationMarkInvoiceReceivedArgs = {
  invoiceDate: Scalars['String'];
  invoiceNumber: Scalars['String'];
  number: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['String'];
};


export type MutationMarkPaymentReceivedArgs = {
  number: Scalars['String'];
  paymentDate: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationMoveQuotationRecordArgs = {
  after?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  quotationId: Scalars['String'];
};


export type MutationMoveSurveyQuestionArgs = {
  after?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationMoveTreeNodeArgs = {
  id: Scalars['String'];
  newParentId?: InputMaybe<Scalars['String']>;
};


export type MutationOpenTicketArgs = {
  ticket?: InputMaybe<OpenTicketInput>;
};


export type MutationPauseProjectAutomationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationPinNoteArgs = {
  id: Scalars['String'];
};


export type MutationRecordActivityArgs = {
  activity: ActivityInput;
};


export type MutationRedeemGiftCardArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  idCard: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationRegisterSurveyWizardUserArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterUserArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  isFromSurveyWizard?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  shouldConfirmEmail?: InputMaybe<Scalars['Boolean']>;
  shouldConfirmPhone?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRejectQuotationArgs = {
  id: Scalars['String'];
  revision?: InputMaybe<Scalars['Int']>;
};


export type MutationRemoveCompanyArgs = {
  id: Scalars['String'];
};


export type MutationRemoveCompanyEmployeeArgs = {
  id: Scalars['String'];
  personId: Scalars['String'];
};


export type MutationRemoveCompetenceArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEmailAddressArgs = {
  id: Scalars['String'];
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationRemoveFilterArgs = {
  id: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationRemoveGiftCardArgs = {
  id: Scalars['String'];
};


export type MutationRemoveLocationArgs = {
  id: Scalars['String'];
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationRemovePartnerArgs = {
  id: Scalars['String'];
};


export type MutationRemovePartnerActivityCostArgs = {
  activityId: Scalars['String'];
  competence: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemovePersonArgs = {
  id: Scalars['String'];
};


export type MutationRemovePhoneNumberArgs = {
  id: Scalars['String'];
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationRemoveProjectAutomationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveProjectCandidateArgs = {
  personId: Array<InputMaybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationRemoveQuestionOptionArgs = {
  optionId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationRemoveQuotationRecordArgs = {
  id: Scalars['String'];
  quotationId: Scalars['String'];
};


export type MutationRemoveSurveyAsTemplateArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSurveyFrequencyArgs = {
  frequencyId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveSurveyQuestionArgs = {
  id: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationRemoveSurveyQuotaArgs = {
  id: Scalars['String'];
  quotaId: Scalars['String'];
};


export type MutationRemoveSurveyShareArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSurveyWizardKeyArgs = {
  id: Scalars['String'];
  keyId: Scalars['String'];
};


export type MutationRemoveTreeNodeArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUploadArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUploadResourceArgs = {
  id: Scalars['String'];
  resource: Scalars['String'];
};


export type MutationRemoveUploadTagArgs = {
  id: Scalars['String'];
  tag: Scalars['String'];
};


export type MutationRemoveUploadTypeArgs = {
  name: Scalars['String'];
};


export type MutationRemoveVatArgs = {
  id: Scalars['String'];
};


export type MutationRemoveWorkExperienceArgs = {
  id: Scalars['String'];
  parentId: Scalars['String'];
};


export type MutationRenameCertificateArgs = {
  number: Scalars['String'];
  renamedNumber: Scalars['String'];
};


export type MutationRenameUploadArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRenameUploadTypeArgs = {
  name: Scalars['String'];
  prevName: Scalars['String'];
};


export type MutationResendCertificateRenewalNotificationArgs = {
  certificateNumber: Scalars['String'];
  expirationDate: Scalars['String'];
  type: CertificateRenewalNotificationType;
};


export type MutationResendUserEmailConfirmationArgs = {
  email: Scalars['String'];
};


export type MutationResendUserPhoneConfirmationArgs = {
  email: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationRestoreCertificateArgs = {
  number: Scalars['String'];
};


export type MutationReuploadArgs = {
  id: Scalars['String'];
  uri: Scalars['String'];
};


export type MutationRevokeCertificateArgs = {
  number: Scalars['String'];
};


export type MutationSaveRecruitmentArgs = {
  data?: InputMaybe<Scalars['JSON']>;
};


export type MutationSaveWorkflowArgs = {
  id: Scalars['String'];
  workflow?: InputMaybe<Scalars['JSON']>;
};


export type MutationSayHelloArgs = {
  firstName: Scalars['String'];
};


export type MutationSendAutomotiveMessageArgs = {
  car: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  sms: SmsInput;
  token: Scalars['String'];
};


export type MutationSendCertificateRenewalArgs = {
  date?: InputMaybe<Scalars['String']>;
};


export type MutationSendEmailArgs = {
  email: EmailInput;
};


export type MutationSendEmailQuotationArgs = {
  data: QuotationSendEmailDataInput;
  id: Scalars['String'];
};


export type MutationSendInviteLinkArgs = {
  inviteToSend: SendInviteInput;
};


export type MutationSendInvoiceToSdiArgs = {
  id: Scalars['String'];
};


export type MutationSendProjectAssignmentArgs = {
  candidates: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationSendSmsArgs = {
  sms: SmsInput;
};


export type MutationSendTelegramMessageArgs = {
  message?: InputMaybe<TelegramMessageInput>;
};


export type MutationSendTelegramQuestionArgs = {
  chatId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationSetCertificateIssueDateArgs = {
  issueDate: Scalars['String'];
  number: Scalars['String'];
};


export type MutationSetCertificateManualPriceArgs = {
  number: Scalars['String'];
  price: ActivityPriceInput;
  replaceAll?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetCertificatePartnerArgs = {
  number: Scalars['String'];
  partner?: InputMaybe<Scalars['String']>;
};


export type MutationSetCompanyDiscountArgs = {
  discount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
};


export type MutationSetCompetenceActivityPriceArgs = {
  activityId: Scalars['String'];
  id: Scalars['String'];
  price: ActivityPriceInput;
};


export type MutationSetEmailAccountArgs = {
  account: EmailAccountInput;
  address: Scalars['String'];
};


export type MutationSetEmailTemplateArgs = {
  attachments?: InputMaybe<Array<InputMaybe<UploadedFile>>>;
  body?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
};


export type MutationSetNoteBodyArgs = {
  body: Scalars['String'];
  id: Scalars['String'];
};


export type MutationSetNoteRecipientsArgs = {
  id: Scalars['String'];
  recipients: NoteRecipientsInput;
};


export type MutationSetNoteReminderArgs = {
  id: Scalars['String'];
  reminder: NoteReminderInput;
};


export type MutationSetNoteTitleArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


export type MutationSetPartnerActivityCostArgs = {
  activityId: Scalars['String'];
  competence: Scalars['String'];
  id: Scalars['String'];
  price: ActivityPriceInput;
};


export type MutationSetProjectBriefingArgs = {
  briefing: ProjectBriefingDataInput;
  id: Scalars['String'];
};


export type MutationSetProjectDetailsArgs = {
  id: Scalars['String'];
  project: ProjectDetailsDataInput;
};


export type MutationSetProjectEvaluationSurveyArgs = {
  evaluationSurvey: ProjectEvaluationSurveyDataInput;
  id: Scalars['String'];
};


export type MutationSetQuestionTextToTemplateKeywordArgs = {
  data: SurveyTemplateKeywordInput;
  id: Scalars['String'];
};


export type MutationSetQuestionTranslationArgs = {
  translation?: InputMaybe<QuestionTranslation>;
};


export type MutationSetQuotationClientArgs = {
  client: QuotationActorInput;
  id: Scalars['String'];
};


export type MutationSetQuotationClientRepresentativeArgs = {
  id: Scalars['String'];
  representative?: InputMaybe<Scalars['String']>;
};


export type MutationSetQuotationCustomFieldArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: QuotationCustomFieldType;
  value?: InputMaybe<Scalars['JSON']>;
};


export type MutationSetQuotationDateArgs = {
  date: Scalars['String'];
  id: Scalars['String'];
};


export type MutationSetQuotationDiscountArgs = {
  discount: QuotationDiscountInput;
  id: Scalars['String'];
};


export type MutationSetQuotationExpirationDateArgs = {
  expirationDate: Scalars['String'];
  id: Scalars['String'];
};


export type MutationSetQuotationNoteArgs = {
  id: Scalars['String'];
  note: Scalars['String'];
};


export type MutationSetQuotationRecordGroupArgs = {
  code: Scalars['String'];
  quotationId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationSetQuotationTitleArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


export type MutationSetQuotationVendorRepresentativeArgs = {
  id: Scalars['String'];
  representative?: InputMaybe<Scalars['String']>;
};


export type MutationSetSubscriberPositionsArgs = {
  id: Scalars['String'];
  position: Scalars['Int'];
};


export type MutationSetSubscriberSizeArgs = {
  id: Scalars['String'];
  size: Scalars['Int'];
};


export type MutationSetSubscribersPositionArgs = {
  position: Scalars['Int'];
};


export type MutationSetSurveyAsTemplateArgs = {
  category: SurveyCategoryTemplateInput;
  id: Scalars['String'];
};


export type MutationSetSurveyFrequencyArgs = {
  frequency: SurveyFrequencyInput;
  id: Scalars['String'];
};


export type MutationSetSurveyLabelsArgs = {
  id: Scalars['String'];
  labels: SurveyLabelsInput;
};


export type MutationSetSurveyLanguagesArgs = {
  id: Scalars['String'];
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationSetSurveyQuotaArgs = {
  id: Scalars['String'];
  quota: SurveyQuotaInput;
};


export type MutationSetSurveyStateArgs = {
  id: Scalars['String'];
  state: SurveyState;
};


export type MutationSetSurveyStyleArgs = {
  id: Scalars['String'];
  style: SurveyStyleInput;
};


export type MutationSetSurveyTargetArgs = {
  amount: Scalars['Int'];
  deadlineDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationSetTagsArgs = {
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
  tags: Array<InputMaybe<Scalars['String']>>;
};


export type MutationSetTreeNodeTranslationArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  locale: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationSetUploadDateArgs = {
  date: Scalars['String'];
  id: Scalars['String'];
};


export type MutationSetUploadTypeArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};


export type MutationSetVariableValueArgs = {
  value: VariableValueInput;
};


export type MutationShareSurveyArgs = {
  share?: InputMaybe<SurveyShareInput>;
};


export type MutationStartProjectAutomationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationStartSurveySessionArgs = {
  session: SurveySessionInput;
};


export type MutationStartTelegramSurveyArgs = {
  chatId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationStopProjectAutomationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationSuspendCertificateArgs = {
  number: Scalars['String'];
};


export type MutationTransferCertificateArgs = {
  number: Scalars['String'];
  toOwner: Scalars['String'];
};


export type MutationUnhideQuotationRecordArgs = {
  id: Scalars['String'];
  quotationId: Scalars['String'];
};


export type MutationUnmarkDocumentationReceivedArgs = {
  number: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationUnmarkInvoiceReceivedArgs = {
  number: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationUnmarkPaymentReceivedArgs = {
  number: Scalars['String'];
  renewal?: InputMaybe<Scalars['Int']>;
};


export type MutationUnpinNoteArgs = {
  id: Scalars['String'];
};


export type MutationUnsetNoteReminderArgs = {
  id: Scalars['String'];
};


export type MutationUnsetQuotationCustomFieldArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUnsetQuotationDiscountArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  company?: InputMaybe<CompanyBasicInfo>;
  id: Scalars['String'];
};


export type MutationUpdateCompetenceArgs = {
  competence: CompetenceInput;
};


export type MutationUpdateEmailAddressArgs = {
  emailAddress: EmailAddressRecord;
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationUpdateFilterArgs = {
  filter: FilterInput;
  surveyId: Scalars['String'];
};


export type MutationUpdateGiftCardArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateLocationArgs = {
  location: LocationRecord;
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
};


export type MutationUpdatePersonArgs = {
  id: Scalars['String'];
  person?: InputMaybe<PersonBasicInfo>;
};


export type MutationUpdatePhoneNumberArgs = {
  parentId: Scalars['String'];
  parentKind: Scalars['String'];
  phoneNumber: PhoneNumberRecord;
};


export type MutationUpdateProjectAutomationArgs = {
  automation: UpdateAutomationInput;
  id: Scalars['String'];
};


export type MutationUpdateQuestionArgs = {
  question?: InputMaybe<UpdateQuestionInput>;
};


export type MutationUpdateQuotationRecordArgs = {
  quotationId: Scalars['String'];
  record: UpdateQuotationRecordInput;
};


export type MutationUpdateSurveyQuestionsArgs = {
  id: Scalars['String'];
  questions: Array<InputMaybe<SurveyQuestionInput>>;
};


export type MutationUpdateSurveyWizardKeyArgs = {
  id: Scalars['String'];
  key: SurveyWizardKeyInput;
};


export type MutationUpdateTicketArgs = {
  ticket?: InputMaybe<UpdateTicketInput>;
};


export type MutationUpdateTreeNodeArgs = {
  node: EditTreeNodeInput;
};


export type MutationUpdateUserDetailsArgs = {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationUpdateVatArgs = {
  id: Scalars['String'];
  vat: VatInput;
};


export type MutationUpdateWorkExperienceArgs = {
  experience: WorkExperienceInput;
  parentId: Scalars['String'];
};


export type MutationUploadArgs = {
  params?: InputMaybe<UploadInput>;
};


export type MutationUseCreditsArgs = {
  amount: Scalars['Int'];
  id: Scalars['String'];
  transactionId?: InputMaybe<Scalars['String']>;
};

export type NaturalVariableDomain = VariableDomain & {
  __typename?: 'NaturalVariableDomain';
  kind: VariableDomainKind;
  range?: Maybe<Array<Maybe<Scalars['Float']>>>;
  tick?: Maybe<Scalars['Int']>;
};

export type NeverAgeingFunction = AgeingFunction & {
  __typename?: 'NeverAgeingFunction';
  kind: AgeingFunctionKind;
};

export type Note = {
  __typename?: 'Note';
  author: Ref;
  body: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  pinned?: Maybe<Scalars['Boolean']>;
  recipients: NoteRecipients;
  reminder?: Maybe<NoteReminder>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  uri: Scalars['String'];
};

export type NoteInput = {
  body: Scalars['String'];
  uri: Scalars['String'];
};

export type NoteRecipients = {
  __typename?: 'NoteRecipients';
  everyone: Scalars['Boolean'];
  groups: Array<Maybe<Ref>>;
  me: Scalars['Boolean'];
  users: Array<Maybe<Ref>>;
};

export type NoteRecipientsInput = {
  everyone: Scalars['Boolean'];
  groups: Array<InputMaybe<Scalars['String']>>;
  me: Scalars['Boolean'];
  users: Array<InputMaybe<Scalars['String']>>;
};

export type NoteReminder = {
  __typename?: 'NoteReminder';
  date: Scalars['String'];
  rate: SchedulerRate;
};

export type NoteReminderInput = {
  date: Scalars['String'];
  rate: SchedulerRate;
};

export type Notes = {
  __typename?: 'Notes';
  items?: Maybe<Array<Maybe<Note>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  readAt?: Maybe<Scalars['String']>;
  sentAt: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

export type Notifications = {
  __typename?: 'Notifications';
  items?: Maybe<Array<Maybe<Notification>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OpenTicketInput = {
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: TicketType;
};

export enum OperatorMroType {
  MisteryClient = 'MISTERY_CLIENT',
  ModeratoreQualitativo = 'MODERATORE_QUALITATIVO',
  ReperitoreQualitativo = 'REPERITORE_QUALITATIVO',
  ReperitoreQuantitativo = 'REPERITORE_QUANTITATIVO',
  RicercatoreQualitativo = 'RICERCATORE_QUALITATIVO',
  RilevatoreFaceToFace = 'RILEVATORE_FACE_TO_FACE',
  RilevatoreOnline = 'RILEVATORE_ONLINE',
  RilevatoreTelefonico = 'RILEVATORE_TELEFONICO',
  SupervisoreArea = 'SUPERVISORE_AREA'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['JSON']>;
  total?: Maybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  competences?: Maybe<Array<Maybe<PartnerCompetence>>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PartnerActivity = {
  __typename?: 'PartnerActivity';
  id: Scalars['String'];
  price: ActivityPrice;
};

export type PartnerCompetence = {
  __typename?: 'PartnerCompetence';
  activites?: Maybe<Array<Maybe<PartnerActivity>>>;
  competence: Scalars['String'];
};

export type PartnerInput = {
  name: Scalars['String'];
};

export type Partners = {
  __typename?: 'Partners';
  items?: Maybe<Array<Maybe<Partner>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PdfRendered = {
  __typename?: 'PdfRendered';
  url: Scalars['String'];
};

export type People = {
  __typename?: 'People';
  items?: Maybe<Array<Maybe<Person>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Person = {
  __typename?: 'Person';
  bankAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  birthCity?: Maybe<BasicLocation>;
  birthDate?: Maybe<Scalars['String']>;
  certificates?: Maybe<Array<Maybe<Ref>>>;
  civilStatus?: Maybe<CivilStatusType>;
  createdAt?: Maybe<Scalars['String']>;
  creditsBalance?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  education?: Maybe<Scalars['JSON']>;
  educationLevel?: Maybe<EducationLevel>;
  emailAddresses?: Maybe<Array<Maybe<EmailAddress>>>;
  employer?: Maybe<Employer>;
  firstName?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  networkSize?: Maybe<Scalars['Int']>;
  openToCollab?: Maybe<Scalars['Boolean']>;
  operatorMroType?: Maybe<OperatorMroType>;
  pec?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  prCode?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<Ref>>>;
  registrationSource?: Maybe<Scalars['String']>;
  registryType?: Maybe<RegistryType>;
  revision: Scalars['Int'];
  sdi?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  telegram?: Maybe<TelegramUser>;
  teseoCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  workExperiences?: Maybe<Array<Maybe<WorkExperience>>>;
};

export type PersonBasicInfo = {
  bankAccounts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  birthCity?: InputMaybe<LocationInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  civilStatus?: InputMaybe<CivilStatusType>;
  creditsBalance?: InputMaybe<Scalars['Int']>;
  education?: InputMaybe<Scalars['JSON']>;
  educationLevel?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fiscalCode?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  networkSize?: InputMaybe<Scalars['Int']>;
  openToCollab?: InputMaybe<Scalars['Boolean']>;
  operatorMroType?: InputMaybe<OperatorMroType>;
  pec?: InputMaybe<Scalars['String']>;
  prCode?: InputMaybe<Scalars['String']>;
  registrationSource?: InputMaybe<Scalars['String']>;
  registryType?: InputMaybe<RegistryType>;
  role?: InputMaybe<Scalars['String']>;
  sdi?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telegram?: InputMaybe<TelegramUserInput>;
  teseoCode?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
  workExperiences?: InputMaybe<Array<InputMaybe<WorkExperienceInput>>>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  number: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
};

export type PhoneNumberRecord = {
  countryCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  number: Scalars['String'];
  type: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int'];
  vatId: Scalars['String'];
};

export type PriceInput = {
  amount?: InputMaybe<Scalars['Int']>;
  vatId: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  briefing?: Maybe<ProjectBriefingData>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedTaskDuration?: Maybe<Scalars['Int']>;
  evaluationSurvey?: Maybe<ProjectEvaluationSurveyData>;
  id: Scalars['String'];
  quotationId?: Maybe<Scalars['String']>;
  timeline?: Maybe<ProjectTimeline>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProjectBasicDataInput = {
  createdAt?: InputMaybe<Scalars['String']>;
  quotationId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type ProjectBriefingData = {
  __typename?: 'ProjectBriefingData';
  body?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type ProjectBriefingDataInput = {
  body?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type ProjectDetailsDataInput = {
  description?: InputMaybe<Scalars['String']>;
  estimatedTaskDuration?: InputMaybe<Scalars['Int']>;
  timeline?: InputMaybe<ProjectTimelineInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProjectEvaluationSurveyData = {
  __typename?: 'ProjectEvaluationSurveyData';
  id: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  surveyId?: Maybe<Scalars['String']>;
};

export type ProjectEvaluationSurveyDataInput = {
  id?: InputMaybe<Scalars['String']>;
  minScore?: InputMaybe<Scalars['Int']>;
  surveyId?: InputMaybe<Scalars['String']>;
};

export type ProjectList = {
  __typename?: 'ProjectList';
  id: Scalars['String'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
};

export type ProjectLists = {
  __typename?: 'ProjectLists';
  items?: Maybe<Array<Maybe<ProjectList>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ProjectStateAutomationInput = {
  automationId: Scalars['String'];
  id: Scalars['String'];
  projectListId: Scalars['String'];
};

export type ProjectTimeline = {
  __typename?: 'ProjectTimeline';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type ProjectTimelineInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type Projects = {
  __typename?: 'Projects';
  items?: Maybe<Array<Maybe<Project>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Query = {
  __typename?: 'Query';
  activities?: Maybe<Activities>;
  certificate?: Maybe<Certificate>;
  certificateRenewalNotifications?: Maybe<CertificateRenewalNotifications>;
  certificates?: Maybe<Certificates>;
  certificatesSuggester?: Maybe<Array<Maybe<Certificate>>>;
  companies?: Maybe<Companies>;
  companiesSuggester?: Maybe<Array<Maybe<Company>>>;
  company?: Maybe<Company>;
  companyByVatNumber?: Maybe<Companies>;
  competence?: Maybe<Competence>;
  competences?: Maybe<Competences>;
  displayName?: Maybe<Scalars['String']>;
  dumpSurveyData?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
  emailAccounts?: Maybe<EmailAccounts>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplates?: Maybe<EmailTemplates>;
  emails?: Maybe<Emails>;
  eventsForResource?: Maybe<Events>;
  getGiftCard?: Maybe<GiftCard>;
  getInviteUrl?: Maybe<TelegramInvite>;
  getMessageStoreStats?: Maybe<MessageStoreStats>;
  getQuestions?: Maybe<Array<Maybe<Question>>>;
  getSubscriberConsumers?: Maybe<Array<Maybe<ConsumerData>>>;
  getSubscriberPositions?: Maybe<Array<Maybe<SubscriberPosition>>>;
  getSubscriberSize?: Maybe<Scalars['Int']>;
  getUserBalance?: Maybe<Scalars['Int']>;
  getUserPendingBalance?: Maybe<Scalars['Int']>;
  getValues?: Maybe<Scalars['String']>;
  getVariableAgedValues?: Maybe<AgedVariableValues>;
  getVariableValueSlicesByRef?: Maybe<VariableValueSlices>;
  getVariableValues?: Maybe<VariableValues>;
  getVariableValuesByRef?: Maybe<VariableValues>;
  helloWorld?: Maybe<Scalars['String']>;
  invoices?: Maybe<Invoices>;
  mioTest?: Maybe<Scalars['String']>;
  myNotes?: Maybe<Notes>;
  note?: Maybe<Note>;
  notes?: Maybe<Notes>;
  notifications?: Maybe<Notifications>;
  partner?: Maybe<Partner>;
  partners?: Maybe<Partners>;
  people?: Maybe<People>;
  peopleSuggester?: Maybe<Array<Maybe<Person>>>;
  person?: Maybe<Person>;
  personByFiscalCode?: Maybe<People>;
  previewCertificateRenewals?: Maybe<CertificateRenewalsNotification>;
  previewPdfTemplate?: Maybe<PdfRendered>;
  /** Project */
  project?: Maybe<Project>;
  /** Automation */
  projectAutomation?: Maybe<RuntimeAutomation>;
  projectAutomations?: Maybe<Automations>;
  /** Candidate */
  projectCandidate?: Maybe<Candidate>;
  projectCandidates?: Maybe<Candidates>;
  /** List */
  projectList?: Maybe<ProjectList>;
  projectLists?: Maybe<ProjectLists>;
  projects?: Maybe<Projects>;
  question?: Maybe<Question>;
  questionPresentation?: Maybe<Question>;
  questionVariants?: Maybe<Questions>;
  questions?: Maybe<Questions>;
  quotation?: Maybe<Quotation>;
  quotationRevisions?: Maybe<Quotations>;
  quotations?: Maybe<Quotations>;
  readMessages?: Maybe<Array<Maybe<MessageStoreMessage>>>;
  retrieveActiveGiftCard?: Maybe<GiftCards>;
  retrieveGiftCards?: Maybe<GiftCards>;
  retrievePeopleTest?: Maybe<People>;
  retrieveTransactions?: Maybe<UserTransactions>;
  searchActivityTreeNode?: Maybe<Trees>;
  searchEconomicActivitesTreeNode?: Maybe<Trees>;
  searchEducationTreeNode?: Maybe<Trees>;
  searchOccupationsTreeNode?: Maybe<Trees>;
  searchTreeNode?: Maybe<Trees>;
  survey?: Maybe<Survey>;
  surveyCategories?: Maybe<SurveyCategories>;
  surveyCategory?: Maybe<SurveyCategory>;
  surveyCluster?: Maybe<SurveyCluster>;
  surveyData?: Maybe<SurveyData>;
  surveyFrequencies?: Maybe<SurveyFrequenciesResult>;
  surveyKeyword?: Maybe<SurveyTemplateKeyword>;
  surveyKeywords?: Maybe<SurveyTemplateKeywords>;
  surveyShare?: Maybe<SurveyShare>;
  surveyShares?: Maybe<SurveyShares>;
  surveyTemplateKeywords?: Maybe<SurveyTemplateKeywords>;
  surveyWizard?: Maybe<SurveyWizard>;
  surveyWizards?: Maybe<SurveyWizards>;
  surveys?: Maybe<Surveys>;
  surveysSuggester?: Maybe<Array<Maybe<Survey>>>;
  surveysTemplates?: Maybe<Surveys>;
  telegramConversation?: Maybe<TelegramConversation>;
  telegramConversationMessages?: Maybe<TelegramConversationMessages>;
  telegramConversations?: Maybe<TelegramConversations>;
  telegramData?: Maybe<TelegramData>;
  test?: Maybe<Scalars['JSON']>;
  testHTTP?: Maybe<Scalars['JSON']>;
  testJson?: Maybe<Scalars['JSON']>;
  tests?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Ticket>;
  tickets?: Maybe<Tickets>;
  treeNode?: Maybe<TreeNode>;
  trees?: Maybe<Trees>;
  upload?: Maybe<Upload>;
  uploadTypes: UploadTypes;
  uploads: Uploads;
  uploadsByOwner: Uploads;
  uploadsByResource: Uploads;
  user?: Maybe<User>;
  userSurveyWizards?: Maybe<SurveyWizards>;
  userSurveys?: Maybe<Surveys>;
  users: Users;
  variable?: Maybe<Variable>;
  variables?: Maybe<Variables>;
  vats?: Maybe<Array<Maybe<Vat>>>;
  wizardSubscription?: Maybe<WizardSubscription>;
  wizardSubscriptions?: Maybe<WizardSubscriptions>;
  workflow?: Maybe<Scalars['JSON']>;
  workflows?: Maybe<Workflows>;
};


export type QueryActivitiesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryCertificateArgs = {
  id: Scalars['String'];
};


export type QueryCertificateRenewalNotificationsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
};


export type QueryCertificatesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryCertificatesSuggesterArgs = {
  field?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};


export type QueryCompaniesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  omitDeleted?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryCompaniesSuggesterArgs = {
  field: Scalars['String'];
  omitDeleted?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryCompanyByVatNumberArgs = {
  vatNumber: Scalars['String'];
};


export type QueryCompetenceArgs = {
  id: Scalars['String'];
};


export type QueryCompetencesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['String']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryDisplayNameArgs = {
  id: Scalars['String'];
};


export type QueryDumpSurveyDataArgs = {
  id: Scalars['String'];
};


export type QueryEmailArgs = {
  id: Scalars['String'];
};


export type QueryEmailAccountsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
};


export type QueryEmailTemplateArgs = {
  name: Scalars['String'];
};


export type QueryEmailTemplatesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryEmailsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryEventsForResourceArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  resource: Scalars['String'];
};


export type QueryGetGiftCardArgs = {
  id: Scalars['String'];
};


export type QueryGetInviteUrlArgs = {
  invite: InviteInput;
};


export type QueryGetQuestionsArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};


export type QueryGetSubscriberConsumersArgs = {
  id: Scalars['String'];
};


export type QueryGetSubscriberPositionsArgs = {
  id: Scalars['String'];
};


export type QueryGetSubscriberSizeArgs = {
  id: Scalars['String'];
};


export type QueryGetUserBalanceArgs = {
  id: Scalars['String'];
};


export type QueryGetUserPendingBalanceArgs = {
  id: Scalars['String'];
};


export type QueryGetValuesArgs = {
  key: Scalars['String'];
};


export type QueryGetVariableAgedValuesArgs = {
  atDate: Scalars['String'];
  options?: InputMaybe<VariableValuesByOptions>;
  variableId: Scalars['String'];
};


export type QueryGetVariableValueSlicesByRefArgs = {
  options?: InputMaybe<VariableValueSlicesByRefOptions>;
  ref: VariableRefInput;
};


export type QueryGetVariableValuesArgs = {
  options?: InputMaybe<VariableValuesByOptions>;
  variableId: Scalars['String'];
};


export type QueryGetVariableValuesByRefArgs = {
  options?: InputMaybe<VariableValuesByOptions>;
  ref: VariableRefInput;
};


export type QueryHelloWorldArgs = {
  pippo?: InputMaybe<Scalars['String']>;
};


export type QueryMioTestArgs = {
  parola: Scalars['String'];
};


export type QueryMyNotesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryNoteArgs = {
  id: Scalars['String'];
};


export type QueryNotesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


export type QueryNotificationsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnerArgs = {
  id: Scalars['String'];
};


export type QueryPartnersArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryPeopleArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  omitDeleted?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryPeopleSuggesterArgs = {
  field: Scalars['String'];
  omitDeleted?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};


export type QueryPersonArgs = {
  id: Scalars['String'];
};


export type QueryPersonByFiscalCodeArgs = {
  fiscalCode: Scalars['String'];
};


export type QueryPreviewCertificateRenewalsArgs = {
  date?: InputMaybe<Scalars['String']>;
  previewEvent?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPreviewPdfTemplateArgs = {
  template?: InputMaybe<Scalars['JSON']>;
  url: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['String'];
};


export type QueryProjectAutomationArgs = {
  id: Scalars['String'];
};


export type QueryProjectAutomationsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  projectId: Scalars['String'];
};


export type QueryProjectCandidateArgs = {
  id: Scalars['String'];
};


export type QueryProjectCandidatesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  projectId: Scalars['String'];
};


export type QueryProjectListArgs = {
  id: Scalars['String'];
};


export type QueryProjectListsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  projectId: Scalars['String'];
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionArgs = {
  id: Scalars['String'];
};


export type QueryQuestionPresentationArgs = {
  id: Scalars['String'];
};


export type QueryQuestionVariantsArgs = {
  parentId: Scalars['String'];
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryQuotationArgs = {
  id: Scalars['String'];
};


export type QueryQuotationRevisionsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
};


export type QueryQuotationsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryReadMessagesArgs = {
  query?: InputMaybe<MessageStoreQuery>;
};


export type QueryRetrieveActiveGiftCardArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRetrieveGiftCardsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRetrievePeopleTestArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRetrieveTransactionsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  userId: Scalars['String'];
};


export type QuerySearchActivityTreeNodeArgs = {
  queryString: Scalars['String'];
};


export type QuerySearchEconomicActivitesTreeNodeArgs = {
  queryString: Scalars['String'];
};


export type QuerySearchEducationTreeNodeArgs = {
  queryString: Scalars['String'];
};


export type QuerySearchOccupationsTreeNodeArgs = {
  queryString: Scalars['String'];
};


export type QuerySearchTreeNodeArgs = {
  queryString: Scalars['String'];
};


export type QuerySurveyArgs = {
  id: Scalars['String'];
};


export type QuerySurveyCategoriesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QuerySurveyCategoryArgs = {
  id: Scalars['String'];
};


export type QuerySurveyClusterArgs = {
  id: Scalars['String'];
};


export type QuerySurveyDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  surveyId: Scalars['String'];
};


export type QuerySurveyFrequenciesArgs = {
  id: Scalars['String'];
};


export type QuerySurveyKeywordArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
};


export type QuerySurveyKeywordsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QuerySurveyShareArgs = {
  id: Scalars['String'];
};


export type QuerySurveySharesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  surveyId: Scalars['String'];
};


export type QuerySurveyTemplateKeywordsArgs = {
  surveyTemplateId: Scalars['String'];
};


export type QuerySurveyWizardArgs = {
  id: Scalars['String'];
};


export type QuerySurveyWizardsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QuerySurveysArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QuerySurveysSuggesterArgs = {
  query: Scalars['String'];
};


export type QuerySurveysTemplatesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryTelegramConversationArgs = {
  id: Scalars['String'];
};


export type QueryTelegramConversationMessagesArgs = {
  conversationId: Scalars['String'];
};


export type QueryTelegramConversationsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryTelegramDataArgs = {
  id: Scalars['String'];
};


export type QueryTestArgs = {
  id: Scalars['String'];
};


export type QueryTestJsonArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type QueryTestsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryTicketArgs = {
  id: Scalars['String'];
};


export type QueryTreeNodeArgs = {
  id: Scalars['String'];
};


export type QueryUploadArgs = {
  id: Scalars['String'];
};


export type QueryUploadTypesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryUploadsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryUploadsByOwnerArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  owner: Scalars['String'];
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryUploadsByResourceArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
  resource: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserSurveyWizardsArgs = {
  orderBy?: InputMaybe<Scalars['JSON']>;
  userId: Scalars['String'];
};


export type QueryUserSurveysArgs = {
  userId: Scalars['String'];
};


export type QueryVariableArgs = {
  id: Scalars['String'];
};


export type QueryVariablesArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryWizardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryWizardSubscriptionsArgs = {
  cursor?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['JSON']>;
  queryString?: InputMaybe<Scalars['String']>;
};


export type QueryWorkflowArgs = {
  id: Scalars['String'];
};


export type QueryWorkflowsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Question = {
  __typename?: 'Question';
  availableLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  body: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  numberOfVariants: Scalars['Int'];
  options?: Maybe<Array<Maybe<QuestionOption>>>;
  parentId?: Maybe<Scalars['String']>;
  rows?: Maybe<Array<Maybe<QuestionRows>>>;
  settings?: Maybe<QuestionSettings>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  translations?: Maybe<Scalars['JSON']>;
  type?: Maybe<QuestionType>;
};

export type QuestionInput = {
  body: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  optional?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<InputMaybe<QuestionOptionsInput>>>;
  parentId?: InputMaybe<Scalars['String']>;
  rows?: InputMaybe<Array<InputMaybe<QuestionRowsInput>>>;
  settings?: InputMaybe<QuestionSettingsInput>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: QuestionType;
};

export type QuestionLabels = {
  __typename?: 'QuestionLabels';
  highScale?: Maybe<Scalars['String']>;
  lowScale?: Maybe<Scalars['String']>;
  mediumScale?: Maybe<Scalars['String']>;
  otherOption?: Maybe<Scalars['String']>;
  undefinedScaleOption?: Maybe<Scalars['String']>;
};

export type QuestionLabelsInput = {
  highScale?: InputMaybe<Scalars['String']>;
  lowScale?: InputMaybe<Scalars['String']>;
  mediumScale?: InputMaybe<Scalars['String']>;
  otherOption?: InputMaybe<Scalars['String']>;
  undefinedScaleOption?: InputMaybe<Scalars['String']>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  body: Scalars['String'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type QuestionOptionsInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type QuestionRows = {
  __typename?: 'QuestionRows';
  body: Scalars['String'];
  id: Scalars['String'];
  variableId?: Maybe<Scalars['String']>;
};

export type QuestionRowsInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  variableId?: InputMaybe<Scalars['String']>;
};

export enum QuestionScaleKind {
  Emoji = 'EMOJI',
  Numeric = 'NUMERIC',
  Stars = 'STARS'
}

export type QuestionSettings = {
  __typename?: 'QuestionSettings';
  allowMultipleChoices?: Maybe<Scalars['Boolean']>;
  enableDropdown?: Maybe<Scalars['Boolean']>;
  enableOtherOption?: Maybe<Scalars['Boolean']>;
  enableUndefinedOption?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<QuestionLabels>;
  optional?: Maybe<Scalars['Boolean']>;
  randomizeOptions?: Maybe<Scalars['Boolean']>;
  scaleKind?: Maybe<QuestionScaleKind>;
  scaleSize?: Maybe<Scalars['Int']>;
};

export type QuestionSettingsInput = {
  allowMultipleChoices?: InputMaybe<Scalars['Boolean']>;
  enableDropdown?: InputMaybe<Scalars['Boolean']>;
  enableOtherOption?: InputMaybe<Scalars['Boolean']>;
  enableUndefinedOption?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<QuestionLabelsInput>;
  optional?: InputMaybe<Scalars['Boolean']>;
  randomizeOptions?: InputMaybe<Scalars['Boolean']>;
  scaleKind?: InputMaybe<QuestionScaleKind>;
  scaleSize?: InputMaybe<Scalars['Int']>;
};

export type QuestionTranslation = {
  body?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  language: Scalars['String'];
  options?: InputMaybe<Array<InputMaybe<QuestionOptionsInput>>>;
  rows?: InputMaybe<Array<InputMaybe<QuestionRowsInput>>>;
};

export enum QuestionType {
  Date = 'DATE',
  EmailAddress = 'EMAIL_ADDRESS',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleText = 'MULTIPLE_TEXT',
  Number = 'NUMBER',
  OpinionScale = 'OPINION_SCALE',
  Percentage = 'PERCENTAGE',
  PhoneNumber = 'PHONE_NUMBER',
  Photo = 'PHOTO',
  Ranking = 'RANKING',
  Rating = 'RATING',
  Text = 'TEXT',
  Tree = 'TREE'
}

export type Questions = {
  __typename?: 'Questions';
  items?: Maybe<Array<Maybe<Question>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Quotation = {
  __typename?: 'Quotation';
  client: QuotationActor;
  clientRepresentative?: Maybe<Ref>;
  customFields?: Maybe<Scalars['JSON']>;
  date: Scalars['String'];
  discount?: Maybe<QuotationDiscount>;
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: Scalars['String'];
  netTotal?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  progressiveNumber?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<QuotationRecord>>>;
  revision: Scalars['Int'];
  status: QuotationStatus;
  title: Scalars['String'];
  total?: Maybe<Scalars['Float']>;
  vatTotal?: Maybe<Scalars['Float']>;
  vendor: QuotationActor;
  vendorRepresentative?: Maybe<Ref>;
  year?: Maybe<Scalars['Int']>;
};

export type QuotationActor = {
  __typename?: 'QuotationActor';
  discount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: QuotationActorType;
};

export type QuotationActorInput = {
  id: Scalars['String'];
  type: QuotationActorType;
};

export enum QuotationActorType {
  Company = 'company',
  Person = 'person'
}

export enum QuotationCustomFieldType {
  Boolean = 'boolean',
  Number = 'number',
  String = 'string'
}

export type QuotationDiscount = {
  __typename?: 'QuotationDiscount';
  type: QuotationDiscountType;
  value: Scalars['Float'];
};

export type QuotationDiscountInput = {
  type: QuotationDiscountType;
  value: Scalars['Float'];
};

export enum QuotationDiscountType {
  Abs = 'ABS',
  Percentage = 'PERCENTAGE'
}

export type QuotationInput = {
  client?: InputMaybe<QuotationActorInput>;
  clientRepresentative?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<QuotationDiscountInput>;
  expirationDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  vendor: QuotationActorInput;
  vendorRepresentative?: InputMaybe<Scalars['String']>;
};

export enum QuotationPdfTemplateType {
  Complete = 'COMPLETE',
  NoRecordPrices = 'NO_RECORD_PRICES'
}

export type QuotationRecord = {
  __typename?: 'QuotationRecord';
  activityId?: Maybe<Scalars['String']>;
  costPrice: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  markup: Scalars['Float'];
  quantity?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  unit: QuotationUnit;
  vatId?: Maybe<Scalars['String']>;
};

export type QuotationSendEmailDataInput = {
  cc?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  html: Scalars['String'];
  revision?: InputMaybe<Scalars['Int']>;
  subject: Scalars['String'];
  to: Scalars['String'];
};

export enum QuotationStatus {
  Accepted = 'ACCEPTED',
  Creating = 'CREATING',
  Finalized = 'FINALIZED',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum QuotationUnit {
  Hour = 'HOUR',
  Unit = 'UNIT'
}

export type Quotations = {
  __typename?: 'Quotations';
  items?: Maybe<Array<Maybe<Quotation>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type RealVariableDomain = VariableDomain & {
  __typename?: 'RealVariableDomain';
  kind: VariableDomainKind;
  precision?: Maybe<Scalars['Int']>;
  range?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Ref = {
  __typename?: 'Ref';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export enum RegistryType {
  Mro = 'MRO',
  Respondent = 'RESPONDENT',
  WorkApplicant = 'WORK_APPLICANT'
}

export type RunLoopExecutorStat = {
  __typename?: 'RunLoopExecutorStat';
  context?: Maybe<ConsumerData>;
  id: Scalars['String'];
  interval: Scalars['Int'];
};

export type RunLoopStats = {
  __typename?: 'RunLoopStats';
  executors?: Maybe<Array<Maybe<RunLoopExecutorStat>>>;
  interval: Scalars['Int'];
  lastIntervalStarted?: Maybe<Scalars['String']>;
};

export type RuntimeAutomation = {
  __typename?: 'RuntimeAutomation';
  executionIndex: Scalars['Int'];
  executionIntervals?: Maybe<Array<Maybe<ExecutionInterval>>>;
  executionSize?: Maybe<Scalars['Int']>;
  frequency: AutomationFrequency;
  id: Scalars['String'];
  name: Scalars['String'];
  nextTick?: Maybe<Scalars['String']>;
  projectListId: Scalars['String'];
  state: AutomationState;
  steps: Array<Maybe<AutomationStep>>;
};

export type SmsInput = {
  body: Scalars['String'];
  recipient: Scalars['String'];
  sender: Scalars['String'];
  type?: InputMaybe<SmsType>;
};

export enum SmsType {
  Basic = 'BASIC',
  Plus = 'PLUS'
}

export enum SchedulerRate {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  None = 'NONE',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type SendInviteInput = {
  phoneNumber: Scalars['String'];
  shareId: Scalars['String'];
};

export type SubscriberPosition = {
  __typename?: 'SubscriberPosition';
  id: Scalars['String'];
  lastPosition?: Maybe<Scalars['Int']>;
  poolMember: Scalars['Int'];
  poolSize: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  streamName: Scalars['String'];
  subscriberId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  helloWorld?: Maybe<Scalars['String']>;
  subscribeForEvents?: Maybe<Message>;
  telegramConversationMessages?: Maybe<TelegramConversationMessages>;
};


export type SubscriptionHelloWorldArgs = {
  pippo?: InputMaybe<Scalars['String']>;
};


export type SubscriptionSubscribeForEventsArgs = {
  id: Scalars['String'];
};


export type SubscriptionTelegramConversationMessagesArgs = {
  conversationId: Scalars['String'];
};

export type Survey = {
  __typename?: 'Survey';
  application?: Maybe<Scalars['JSON']>;
  creation?: Maybe<SurveyCreation>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  frequencies?: Maybe<Array<Maybe<SurveyFrequency>>>;
  id: Scalars['String'];
  labels?: Maybe<SurveyLabels>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<SurveyShareMetrics>;
  name: Scalars['String'];
  questions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  quotas?: Maybe<Array<Maybe<SurveyQuota>>>;
  revision?: Maybe<Scalars['Int']>;
  state: SurveyState;
  style?: Maybe<SurveyStyle>;
  target?: Maybe<SurveyTarget>;
};

export type SurveyApplicationType = SurveyTemplateApplication | SurveyWizardApplication;

export type SurveyCategories = {
  __typename?: 'SurveyCategories';
  items?: Maybe<Array<Maybe<SurveyCategory>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SurveyCategory = {
  __typename?: 'SurveyCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<SurveyCategoryOptions>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Ref>>>;
};

export type SurveyCategoryInput = {
  name: Scalars['String'];
  options?: InputMaybe<SurveyCategoryOptionsInput>;
};

export type SurveyCategoryOptions = {
  __typename?: 'SurveyCategoryOptions';
  relativePopulation?: Maybe<Scalars['Int']>;
};

export type SurveyCategoryOptionsInput = {
  relativePopulation?: InputMaybe<Scalars['Int']>;
};

export type SurveyCategoryTemplateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  parentId: Scalars['String'];
};

export type SurveyCluster = {
  __typename?: 'SurveyCluster';
  clusterQuotas?: Maybe<Array<Maybe<SurveyClusterQuotas>>>;
  id?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['String']>;
};

export type SurveyClusterQuota = {
  __typename?: 'SurveyClusterQuota';
  optionId: Scalars['String'];
  questionId: Scalars['String'];
  quotaId: Scalars['String'];
};

export type SurveyClusterQuotas = {
  __typename?: 'SurveyClusterQuotas';
  amount: Scalars['Int'];
  completed: Scalars['Boolean'];
  quotas: Array<Maybe<SurveyClusterQuota>>;
  target: Scalars['Int'];
};

export type SurveyCreation = {
  __typename?: 'SurveyCreation';
  createdAt: Scalars['String'];
  userId: Scalars['String'];
};

export type SurveyData = {
  __typename?: 'SurveyData';
  items: Array<Maybe<SurveySessionData>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Frequencies */
export type SurveyFrequenciesResult = {
  __typename?: 'SurveyFrequenciesResult';
  frequencies: Array<Maybe<SurveyFrequencyResult>>;
  id: Scalars['String'];
};

export type SurveyFrequency = {
  __typename?: 'SurveyFrequency';
  chartType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  questions: Array<Maybe<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SurveyFrequencyInput = {
  chartType?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  questions: Array<InputMaybe<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type SurveyFrequencyResult = {
  __typename?: 'SurveyFrequencyResult';
  chartType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  options: Array<Maybe<FrequencyOptionResult>>;
  questionIds: Array<Maybe<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SurveyInput = {
  name: Scalars['String'];
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SurveyLabels = {
  __typename?: 'SurveyLabels';
  introMessage?: Maybe<Scalars['String']>;
  startButton?: Maybe<Scalars['String']>;
  thanksMessage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SurveyLabelsInput = {
  introMessage?: InputMaybe<Scalars['String']>;
  startButton?: InputMaybe<Scalars['String']>;
  thanksMessage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  id: Scalars['String'];
  questionId: Scalars['String'];
  variables?: Maybe<Scalars['JSON']>;
};

export type SurveyQuestionInput = {
  id: Scalars['String'];
  questionId: Scalars['String'];
  variables?: InputMaybe<Scalars['JSON']>;
};

export type SurveyQuota = {
  __typename?: 'SurveyQuota';
  id: Scalars['String'];
  options: Array<Maybe<SurveyQuotaOption>>;
  questionId: Scalars['String'];
};

export type SurveyQuotaInput = {
  id: Scalars['String'];
  options: Array<InputMaybe<SurveyQuotaOptionInput>>;
  questionId: Scalars['String'];
};

export type SurveyQuotaOption = {
  __typename?: 'SurveyQuotaOption';
  amount: Scalars['Int'];
  optionId: Scalars['String'];
};

export type SurveyQuotaOptionInput = {
  amount: Scalars['Int'];
  optionId: Scalars['String'];
};

export type SurveySessionData = {
  __typename?: 'SurveySessionData';
  data: Scalars['JSON'];
  endedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  info?: Maybe<Scalars['JSON']>;
  startedAt: Scalars['String'];
};

export type SurveySessionInput = {
  contextId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  info?: InputMaybe<Scalars['JSON']>;
  kind: SurveySessionKind;
  language?: InputMaybe<Scalars['String']>;
  shareId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['String'];
};

export enum SurveySessionKind {
  Telegram = 'TELEGRAM',
  Web = 'WEB'
}

export type SurveyShare = {
  __typename?: 'SurveyShare';
  enabled?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['String']>;
  frequencies?: Maybe<Array<Maybe<SurveyFrequencyResult>>>;
  id: Scalars['String'];
  medium?: Maybe<Array<Maybe<SurveyShareMedium>>>;
  metrics?: Maybe<SurveyShareMetrics>;
  numberOfDevices?: Maybe<Scalars['Int']>;
  pinCode?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Int']>;
  sender: Ref;
  sentAt?: Maybe<Scalars['String']>;
  surveyId: Scalars['String'];
  target?: Maybe<TargetInfo>;
  type?: Maybe<SurveyShareType>;
  url?: Maybe<Scalars['String']>;
};

export type SurveyShareInput = {
  expirationDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  medium?: InputMaybe<Array<InputMaybe<SurveyShareMedium>>>;
  personId?: InputMaybe<Scalars['String']>;
  quota?: InputMaybe<Scalars['Int']>;
  surveyId: Scalars['String'];
  type?: InputMaybe<SurveyShareType>;
};

export enum SurveyShareMedium {
  Email = 'EMAIL',
  Sms = 'SMS',
  Telegram = 'TELEGRAM'
}

export type SurveyShareMetrics = {
  __typename?: 'SurveyShareMetrics';
  assignedQuotas?: Maybe<Scalars['Int']>;
  avgDuration?: Maybe<Scalars['Float']>;
  closes?: Maybe<Scalars['Int']>;
  confidenceInterval?: Maybe<ConfidenceIntervalMetric>;
  dropout?: Maybe<Scalars['Int']>;
  durations?: Maybe<Array<Maybe<Scalars['Float']>>>;
  maxDuration?: Maybe<Scalars['Float']>;
  minDuration?: Maybe<Scalars['Float']>;
  numberOfDevices?: Maybe<Scalars['Int']>;
  opens?: Maybe<Scalars['Int']>;
  screenout?: Maybe<Scalars['Int']>;
  stdDuration?: Maybe<Scalars['Float']>;
};

export enum SurveyShareType {
  Telegram = 'TELEGRAM',
  Web = 'WEB'
}

export type SurveyShares = {
  __typename?: 'SurveyShares';
  items?: Maybe<Array<Maybe<SurveyShare>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Survey States */
export enum SurveyState {
  Completed = 'COMPLETED',
  Design = 'DESIGN',
  Live = 'LIVE',
  Testing = 'TESTING'
}

export type SurveyStyle = {
  __typename?: 'SurveyStyle';
  hideTelegram?: Maybe<Scalars['Boolean']>;
  introImage?: Maybe<Scalars['String']>;
  logoImage?: Maybe<Scalars['String']>;
  tintColor?: Maybe<Scalars['String']>;
};

export type SurveyStyleInput = {
  hideTelegram?: InputMaybe<Scalars['Boolean']>;
  introImage?: InputMaybe<Scalars['String']>;
  logoImage?: InputMaybe<Scalars['String']>;
  tintColor?: InputMaybe<Scalars['String']>;
};

export type SurveyTarget = {
  __typename?: 'SurveyTarget';
  amount: Scalars['Int'];
  deadlineDate?: Maybe<Scalars['String']>;
};

export type SurveyTemplateApplication = {
  __typename?: 'SurveyTemplateApplication';
  category?: Maybe<SurveyCategory>;
  id: Scalars['String'];
  isTemplate?: Maybe<Scalars['Boolean']>;
};

export type SurveyTemplateKeyword = {
  __typename?: 'SurveyTemplateKeyword';
  category: SurveyCategory;
  id: Scalars['String'];
  keyword: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  questionText: Scalars['String'];
  surveyTemplateId: Scalars['String'];
};

export type SurveyTemplateKeywordInput = {
  keyword: Scalars['String'];
  options?: InputMaybe<Scalars['JSON']>;
  questionText: Scalars['String'];
  surveyTemplateId: Scalars['String'];
};

export type SurveyTemplateKeywords = {
  __typename?: 'SurveyTemplateKeywords';
  items?: Maybe<Array<Maybe<SurveyTemplateKeyword>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SurveyWizard = {
  __typename?: 'SurveyWizard';
  category: SurveyCategory;
  id: Scalars['String'];
  keys: Array<Maybe<SurveyWizardKey>>;
  surveyTemplateId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SurveyWizardApplication = {
  __typename?: 'SurveyWizardApplication';
  category?: Maybe<SurveyCategory>;
  subscriptions?: Maybe<Array<Maybe<SurveyWizardSubscription>>>;
  surveyWizardId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
};

export type SurveyWizardKey = {
  __typename?: 'SurveyWizardKey';
  id: Scalars['String'];
  keyword: Scalars['String'];
  positions: Array<Maybe<SurveyWizardKeyValue>>;
};

export type SurveyWizardKeyInput = {
  id: Scalars['String'];
  keyword: Scalars['String'];
  value: Array<InputMaybe<Scalars['String']>>;
};

export type SurveyWizardKeyValue = {
  __typename?: 'SurveyWizardKeyValue';
  optionId?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type SurveyWizardSubscription = {
  __typename?: 'SurveyWizardSubscription';
  id: Scalars['String'];
  paiedAt: Scalars['String'];
  paymentType: Scalars['JSON'];
  userId: Scalars['String'];
};

export type SurveyWizardSubscriptionInput = {
  id: Scalars['String'];
  paiedAt: Scalars['String'];
  paymentType: Scalars['JSON'];
  userId: Scalars['String'];
};

export enum SurveyWizardSubscriptionType {
  Annual = 'ANNUAL',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
  Weekly = 'WEEKLY'
}

export enum SurveyWizardType {
  PoliticalSurvey = 'POLITICAL_SURVEY'
}

export type SurveyWizards = {
  __typename?: 'SurveyWizards';
  items?: Maybe<Array<Maybe<SurveyWizard>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Surveys = {
  __typename?: 'Surveys';
  items?: Maybe<Array<Maybe<Survey>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TargetInfo = {
  __typename?: 'TargetInfo';
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type TelegramBroadcastMessageInput = {
  message: TelegramMessageContentInput;
  messageIdentifier?: InputMaybe<Scalars['String']>;
};

export type TelegramConversation = {
  __typename?: 'TelegramConversation';
  chatId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastMessage?: Maybe<TelegramMessage>;
  lastName?: Maybe<Scalars['String']>;
  numberOfBees: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  person?: Maybe<Ref>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  startedAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type TelegramConversationMessages = {
  __typename?: 'TelegramConversationMessages';
  items?: Maybe<Array<Maybe<TelegramMessage>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TelegramConversations = {
  __typename?: 'TelegramConversations';
  items?: Maybe<Array<Maybe<TelegramConversation>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TelegramData = {
  __typename?: 'TelegramData';
  id: Scalars['String'];
  telegram?: Maybe<TelegramUser>;
};

export type TelegramInvite = {
  __typename?: 'TelegramInvite';
  url: Scalars['String'];
};

export type TelegramMessage = {
  __typename?: 'TelegramMessage';
  chatId: Scalars['String'];
  date: Scalars['String'];
  direction?: Maybe<TelegramMessageDirection>;
  id: Scalars['String'];
  message: TelegramMessageContent;
};

export type TelegramMessageButton = {
  data?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type TelegramMessageContent = {
  __typename?: 'TelegramMessageContent';
  extra?: Maybe<Scalars['JSON']>;
  photo?: Maybe<TelegramPhoto>;
  text?: Maybe<Scalars['String']>;
};

export type TelegramMessageContentInput = {
  extra?: InputMaybe<TelegramMessageExtra>;
  photo?: InputMaybe<TelegramPhotoInput>;
  text?: InputMaybe<Scalars['String']>;
};

export enum TelegramMessageDirection {
  Received = 'RECEIVED',
  Sent = 'SENT'
}

export type TelegramMessageExtra = {
  caption?: InputMaybe<Scalars['String']>;
  markup?: InputMaybe<TelegramMessageMarkup>;
  parseMode?: InputMaybe<TelegramParseMode>;
  silent?: InputMaybe<Scalars['Boolean']>;
};

export type TelegramMessageInput = {
  chatId: Scalars['String'];
  message: TelegramMessageContentInput;
  messageIdentifier?: InputMaybe<Scalars['String']>;
};

export type TelegramMessageMarkup = {
  buttons?: InputMaybe<Array<InputMaybe<TelegramMessageButton>>>;
  inline?: InputMaybe<Scalars['Boolean']>;
  numberOfRows?: InputMaybe<Scalars['Int']>;
};

export enum TelegramParseMode {
  Html = 'HTML',
  Markdown = 'Markdown',
  MarkdownV2 = 'MarkdownV2'
}

export type TelegramPhoto = {
  __typename?: 'TelegramPhoto';
  uri: Scalars['String'];
};

export type TelegramPhotoInput = {
  uri: Scalars['String'];
};

export type TelegramUser = {
  __typename?: 'TelegramUser';
  chatId: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type TelegramUserInput = {
  chatId: Scalars['String'];
  profilePhoto?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  body: Scalars['String'];
  comments?: Maybe<Array<Maybe<TicketComment>>>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  prority?: Maybe<Scalars['Int']>;
  status: TicketState;
  title?: Maybe<Scalars['String']>;
  type: TicketType;
  updatedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type TicketComment = {
  __typename?: 'TicketComment';
  body: Scalars['String'];
  id: Scalars['String'];
  sentAt: Scalars['String'];
  userId: Scalars['String'];
};

export type TicketCommentInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  ticketId: Scalars['String'];
};

export enum TicketState {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Processing = 'PROCESSING'
}

export enum TicketType {
  Bug = 'BUG',
  Enhancement = 'ENHANCEMENT',
  Feature = 'FEATURE'
}

export type Tickets = {
  __typename?: 'Tickets';
  items?: Maybe<Array<Maybe<Ticket>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum TimeUnit {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds'
}

export type TreeNode = {
  __typename?: 'TreeNode';
  childrens?: Maybe<Array<Maybe<TreeNode>>>;
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  numberOfChildrens?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<TreeNodeTranslations>;
  treeId?: Maybe<Scalars['String']>;
};

export type TreeNodeInput = {
  code?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  treeId?: InputMaybe<Scalars['String']>;
};

export type TreeNodeTranslation = {
  __typename?: 'TreeNodeTranslation';
  data?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
};

export type TreeNodeTranslations = {
  __typename?: 'TreeNodeTranslations';
  de?: Maybe<TreeNodeTranslation>;
  en?: Maybe<TreeNodeTranslation>;
  es?: Maybe<TreeNodeTranslation>;
  fr?: Maybe<TreeNodeTranslation>;
  it?: Maybe<TreeNodeTranslation>;
  nl?: Maybe<TreeNodeTranslation>;
  pt?: Maybe<TreeNodeTranslation>;
  se?: Maybe<TreeNodeTranslation>;
};

export type Trees = {
  __typename?: 'Trees';
  items?: Maybe<Array<Maybe<TreeNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UpdateAutomationInput = {
  executionIntervals?: InputMaybe<Array<InputMaybe<ExecutionIntervalInput>>>;
  frequency?: InputMaybe<AutomationFrequencyInput>;
  name?: InputMaybe<Scalars['String']>;
  projectListId?: InputMaybe<Scalars['String']>;
  steps?: InputMaybe<Array<InputMaybe<AutomationStepInput>>>;
};

export type UpdateQuestionInput = {
  body?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  optional?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<InputMaybe<QuestionOptionsInput>>>;
  parentId?: InputMaybe<Scalars['String']>;
  rows?: InputMaybe<Array<InputMaybe<QuestionRowsInput>>>;
  settings?: InputMaybe<QuestionSettingsInput>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<QuestionType>;
};

export type UpdateQuotationRecordInput = {
  activityId?: InputMaybe<Scalars['String']>;
  costPrice?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  markup?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<QuotationUnit>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type UpdateTicketInput = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TicketType>;
};

export type Upload = {
  __typename?: 'Upload';
  date?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  resources?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type UploadInfo = {
  __typename?: 'UploadInfo';
  urlR?: Maybe<Scalars['String']>;
  urlW?: Maybe<Scalars['String']>;
};

export type UploadInput = {
  date?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type UploadType = {
  __typename?: 'UploadType';
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UploadTypes = {
  __typename?: 'UploadTypes';
  items: Array<Maybe<UploadType>>;
};

export type UploadedFile = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type Uploads = {
  __typename?: 'Uploads';
  items: Array<Maybe<Upload>>;
  pageInfo?: Maybe<PageInfo>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isEmailToBeVerified?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isFromSurveyWizard?: Maybe<Scalars['Boolean']>;
  isPhoneToBeVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: UserStatus;
};

export type UserRegistration = {
  __typename?: 'UserRegistration';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum UserStatus {
  Disabled = 'DISABLED',
  Idle = 'IDLE',
  PendingConfirmationEmail = 'PENDING_CONFIRMATION_EMAIL',
  PendingConfirmationPhone = 'PENDING_CONFIRMATION_PHONE',
  Registered = 'REGISTERED'
}

export type UserTransaction = {
  __typename?: 'UserTransaction';
  amount: Scalars['Int'];
  balance?: Maybe<Scalars['Int']>;
  creditDate?: Maybe<Scalars['String']>;
  delayed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  time: Scalars['String'];
  userId: Scalars['String'];
};

export type UserTransactions = {
  __typename?: 'UserTransactions';
  items?: Maybe<Array<Maybe<UserTransaction>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Users = {
  __typename?: 'Users';
  items?: Maybe<Array<Maybe<User>>>;
};

export type Variable = {
  __typename?: 'Variable';
  ageing: AgeingFunction;
  description?: Maybe<Scalars['String']>;
  domain: VariableDomain;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type VariableDomain = {
  kind: VariableDomainKind;
};

export type VariableDomainInput = {
  kind: VariableDomainKind;
  precision?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  tick?: InputMaybe<Scalars['Int']>;
};

export enum VariableDomainKind {
  Natural = 'NATURAL',
  Real = 'REAL'
}

export type VariableInput = {
  ageing: AgeingFunctionInput;
  description?: InputMaybe<Scalars['String']>;
  domain: VariableDomainInput;
  name: Scalars['String'];
};

export type VariableRef = {
  __typename?: 'VariableRef';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type VariableRefInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type VariableValue = {
  __typename?: 'VariableValue';
  date?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  refs?: Maybe<Array<Maybe<VariableRef>>>;
  value: Scalars['JSON'];
  variableId: Scalars['String'];
};

export type VariableValueInput = {
  date?: InputMaybe<Scalars['String']>;
  refs?: InputMaybe<Array<InputMaybe<VariableRefInput>>>;
  value?: InputMaybe<Scalars['JSON']>;
  variableId: Scalars['String'];
};

export type VariableValueSlice = {
  __typename?: 'VariableValueSlice';
  slice: Scalars['JSON'];
  values: Array<Maybe<VariableValue>>;
};

export type VariableValueSlices = {
  __typename?: 'VariableValueSlices';
  items?: Maybe<Array<Maybe<VariableValueSlice>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type VariableValueSlicesByRefOptions = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  slices?: InputMaybe<Scalars['JSON']>;
};

export type VariableValues = {
  __typename?: 'VariableValues';
  items?: Maybe<Array<Maybe<VariableValue>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type VariableValuesByOptions = {
  cursor?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Variables = {
  __typename?: 'Variables';
  items?: Maybe<Array<Maybe<Variable>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Vat = {
  __typename?: 'Vat';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type VatInput = {
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  value?: InputMaybe<Scalars['Int']>;
};

export type WizardSubscription = {
  __typename?: 'WizardSubscription';
  application: WizardSubscriptionApplication;
  createdAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  plan: SurveyWizardSubscriptionType;
  startAt: Scalars['String'];
  value: Scalars['Int'];
};

export type WizardSubscriptionApplication = {
  __typename?: 'WizardSubscriptionApplication';
  categoryId: Scalars['String'];
  subcategoryId?: Maybe<Scalars['String']>;
};

export type WizardSubscriptionApplicationInput = {
  categoryId: Scalars['String'];
  subcategoryId?: InputMaybe<Scalars['String']>;
};

export type WizardSubscriptionInput = {
  application: WizardSubscriptionApplicationInput;
  endAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['JSON']>;
  plan: SurveyWizardSubscriptionType;
  startAt: Scalars['String'];
  value: Scalars['Int'];
};

export type WizardSubscriptions = {
  __typename?: 'WizardSubscriptions';
  items?: Maybe<Array<Maybe<WizardSubscription>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  companyId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  professionId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type WorkExperienceInput = {
  companyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  professionId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type Workflows = {
  __typename?: 'Workflows';
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  pageInfo?: Maybe<PageInfo>;
};
