import { useEffect, useState } from "react";
import GoogleMapsApi from "src/vendor/gmap";
import { useEnvironment } from "src/context/EnvironmentContext";

export function useGoogleMaps() {
  const [google, setGoogle] = useState(window.google);
  const env = useEnvironment();
  useEffect(() => {
    if (env.GOOGLE_MAPS_API_KEY) {
      new GoogleMapsApi(env.GOOGLE_MAPS_API_KEY).load().then((res) => {
        setGoogle(window.google);
      });
    }
  }, [env.GOOGLE_MAPS_API_KEY]);
  return google;
}
