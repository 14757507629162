import { Button, Icon, Intent, Spinner } from '@blueprintjs/core';
import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  ControlButton,
  NodeTypes,
  Node,
  BackgroundVariant,
  ReactFlowProvider,
  useViewport,
} from 'react-flow-renderer';
import classNames from 'classnames';
import { BlockNode } from './BlockNode';
import './workflow.css';
import {
  useWorkflowContext,
  WorkflowAction,
  WorkflowContextProvider,
} from './WorkflowContext';

import { LibraryContextProvider } from 'src/hooks/workflow/LibraryContext';
import { AddBlockOmnibar } from './AddBlockOminbar';
import BlockDrawer from './BlockDrawer';
import { Size } from 'src/utils/size';
import BlockLibraryDialog from './BlockLibraryDialog';
import { WorkflowCanvas } from './WorkflowCanvas';
import { WorkflowSidebar } from './WorkflowSidebar';
import { WorkflowViewPicker } from './WorkflowViewPicker';
import { WorkflowDashboard } from './WorkflowDashboard';
import { WorkflowView } from './types';

interface DesignerProps {
  size: Size;
  id: string;
}

export default function WorkflowDesigner(p: DesignerProps) {
  return (
    <LibraryContextProvider>
      <ReactFlowProvider>
        <WorkflowContextProvider id={p.id} size={p.size}>
          <>
            <WorkflowFlowDesigner {...p} />
            <AddBlockOmnibar />
            <BlockLibraryDialog />
            <BlockDrawer />
          </>
        </WorkflowContextProvider>
      </ReactFlowProvider>
    </LibraryContextProvider>
  );
}


export function WorkflowFlowDesigner(p: DesignerProps) {
  const { state } = useWorkflowContext();
  const { view } = state;
  const { id, size } = p;

  return useMemo(() => {
    switch (state.view) {
      case WorkflowView.DASHBOARD:
        return (
          <WorkflowDashboard size={p.size}>
            <>
              <WorkflowViewPicker />
            </>
          </WorkflowDashboard>
        );
      default:
        return (
          <WorkflowCanvas size={p.size}>
            <>
              <WorkflowSidebar id={p.id} />
              <WorkflowViewPicker />
            </>
          </WorkflowCanvas>
        );
    }
  }, [view, id, size])


}

