import { BlockState } from '@keix/workflow-types';
import { set } from 'lodash/fp';
import { WorkflowActions } from '..';
import { WorkflowState } from '../../workflow/types';
import { getNodeIndexById } from '../../workflow/WorkflowContext';

export enum WorkflowRunActionType {
  RUN = 'RUN',
  STOP = 'STOP',
  SET_READY = 'SET_READY',
  COMPLETE_RUN = 'COMPLETE_RUN',
  SET_NODE_STATE = 'SET_NODE_STATE',
  SET_NODE_EXECUTION_CODE = 'SET_NODE_EXECUTION_CODE',
}

export type WorkflowRunActions =
  | { type: WorkflowRunActionType.RUN }
  | { type: WorkflowRunActionType.SET_READY; isReady: boolean }
  | { type: WorkflowRunActionType.STOP }
  | {
      type: WorkflowRunActionType.SET_NODE_STATE;
      id: string;
      state: BlockState;
    }
  | {
      type: WorkflowRunActionType.SET_NODE_EXECUTION_CODE;
      id: string;
      code: string;
    };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowRunActionType.RUN:
      return set('isRunning', true)(prev);
    case WorkflowRunActionType.STOP:
      return set('isRunning', false)(prev);
    case WorkflowRunActionType.SET_READY:
      return set('isReady', action.isReady)(prev);

    case WorkflowRunActionType.SET_NODE_STATE: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return set(`nodes.${nodeIndex}.data.state`, action.state)(prev);
    }

    case WorkflowRunActionType.SET_NODE_EXECUTION_CODE: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return set(`nodes.${nodeIndex}.data.code`, action.code)(prev);
    }
  }
  return prev;
}
