import { Dictionary, isArray, uniq } from 'lodash';
import { set, update } from 'lodash/fp';
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  Node,
  Edge,
  ReactFlowProvider,
  NodeChange,
  EdgeChange,
  applyEdgeChanges,
  Connection,
  addEdge,
  useViewport,
  XYPosition,
} from 'react-flow-renderer';
import { Layout } from 'react-grid-layout';
import { useLoginContext } from 'src/context/LoginContext';
import {
  Block,
  BlockLayout,
  BlockState,
  Executor,
  LibraryBlock,
  LibraryBlockArgument,
  PreviewDataType,
} from '@keix/workflow-types';
import { useLocalExecutor } from 'src/hooks/workflow/useLocalExecutor';
import { convertReactFlowToBlocks, createNode } from 'src/hooks/workflow/utils';
import { Size } from 'src/utils/size';
import changeNodes from '../store/changeNodes';
import { emptyState, reducers, useWorkflowReducer, WorkflowAction } from '../store';
import { WorkflowContext, DrawerState, WorkflowState, ViewportState } from './types';
import { useKeixPlatform } from 'src/hooks/workflow/useKeixPlatform';
import { PreviewContext, useLocalPreviewer, usePreviewContext } from './PreviewContext';


export { WorkflowAction } from '../store';

export const WorkflowCtx = createContext<WorkflowContext>({
  state: emptyState,
  dispatch: () => null,
});

export const getNodeIndexById = (
  state: Pick<WorkflowState, 'nodeIndexById'>,
  id: string
): number | null => state.nodeIndexById[id];

export function getNodeById(
  state: Pick<WorkflowState, 'nodes' | 'nodeIndexById'>,
  id: string
): Node<Block> | null {
  const index = getNodeIndexById(state, id);
  if (index != null) {
    return state.nodes[index];
  }
  return null;
}

export function WorkflowContextProvider(p: {
  children: JSX.Element;
  id: string;
  size?: Size;
}) {

  const previewer = useLocalPreviewer();
  const executor = useLocalExecutor(previewer);

  const { size } = p;
  const { state: loginState } = useLoginContext();
  const { getWorkflow } = useKeixPlatform();
  const { run, isReady } = executor;

  useEffect(() => {
    getWorkflow(p.id).then(workflow => {
      if (workflow != null) {
        dispatch({ type: WorkflowAction.LOAD, state: workflow })
      }
    });
  }, [p.id]);


  useEffect(() => {
    window['idToken'] = loginState.idToken;
  }, [loginState.idToken]);

  const [state, dispatch] = useWorkflowReducer(
    reducers
  )

  useEffect(() => {
    dispatch({ type: WorkflowAction.RESIZE_VIEWPORT, size });
  }, [size]);

  useEffect(() => {
    dispatch({ type: WorkflowAction.SET_READY, isReady });
  }, [isReady]);

  useEffect(
    () =>
      executor.registerObserver({
        onBlockStateUpdate: (id, state) => {
          dispatch({ type: WorkflowAction.SET_NODE_STATE, id, state })
        }
      }),
    []
  );

  useEffect(() => {
    if (state.isRunning && isReady) {
      run(convertReactFlowToBlocks(state.nodes, state.edges)).then(() =>
        dispatch({ type: WorkflowAction.STOP })
      );
    }
  }, [state.isRunning, isReady]);

  const value: WorkflowContext = useMemo(() => ({ state, dispatch }), [JSON.stringify(state)]);

  return (
    <WorkflowCtx.Provider value={value}>
      <PreviewContext.Provider value={previewer}>
        <ReactFlowProvider>{p.children}</ReactFlowProvider>
      </PreviewContext.Provider>
    </WorkflowCtx.Provider>
  );
}


export function useWorkflowContext() {
  return useContext(WorkflowCtx);
}

export function useWorkflowState(): WorkflowState {
  return useContext(WorkflowCtx).state;
}

function useNodeObserver(
  executor: Executor,
  callback: (id: string, state: BlockState) => void
) {
  useEffect(() => {
    return executor.registerObserver({
      onBlockStateUpdate: callback,
    });
  }, []);
}
