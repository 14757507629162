import React, { useCallback } from 'react';
import { Loadable } from 'src/components/EditableField';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import type {
  Project,
  ProjectBriefingDataInput,
  ProjectEvaluationSurveyDataInput,
  Query,
} from '../../apps/athena/gql-types';
import { useSubscribeForEvents } from 'src/utils/events';
import {
  simpleObject,
  branch,
  combine,
  custom,
  DiffableForm,
} from 'src/utils/diffV2';
import { get, isEmpty, last, pick, set, unset } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBasicMiddleware } from '../crm/BasicDetail';
import {
  CANDIDATE_MUTATION,
  PROJECT_LIST_QUERY,
  PROJECT_MUTATION,
  PROJECT_QUERY,
} from 'src/graphql/project';
import { applyChanges } from 'src/utils/diff';
import { v4 } from 'src/utils/uuid';
import { useGraphQLFetch } from 'src/utils/graphql';
import { IPerson } from '../MultiSelectSuggester';

export function useSaveProject(id: string, isNew: boolean) {
  const { t } = useTranslation('project');

  const client = useGraphQLFetch();
  const queryClient = useQueryClient();
  const subscribe = useSubscribeForEvents((event, ctx) => {
    ctx.complete();
    ctx.showSuccessNotification({
      message: t('Project Saved'),
    });
    console.log(event);
  });

  return useCallback(async (form: FormikContextType<Loadable<Project>>) => {
    const newProjectId = v4();
    const newBriefingId = v4();
    const newEvaluationSurveyId = v4();

    const data: Loadable<Project> = form.values;
    //console.log('FORM VALUES:', data);

    const briefingMiddleware = simpleObject({
      pick: ['briefing'],
      command: 'SET_PROJECT_BRIEFING',
      dataBuilder: (data: { briefing: ProjectBriefingDataInput }) => {
        const briefingId = data.briefing.id ? data.briefing.id : newBriefingId;
        const briefingData = {
          id: isNew ? newBriefingId : briefingId,
          body: data.briefing.body,
          videoUrl: data.briefing.videoUrl,
        };

        return {
          id: isNew ? newProjectId : id,
          briefing: briefingData,
        };
      },
    });

    const evaluationSurveyMiddleware = simpleObject({
      pick: ['evaluationSurvey'],
      command: 'SET_PROJECT_EVALUATION_SURVEY',
      dataBuilder: (data: {
        evaluationSurvey: ProjectEvaluationSurveyDataInput;
      }) => {
        const evaluationSurveyId = data.evaluationSurvey.id
          ? data.evaluationSurvey.id
          : newEvaluationSurveyId;
        const evaluationSurveyData = {
          id: isNew ? newEvaluationSurveyId : evaluationSurveyId,
          minScore: data.evaluationSurvey.minScore,
          surveyId: data.evaluationSurvey.surveyId,
        };

        return {
          id: isNew ? newProjectId : id,
          evaluationSurvey: evaluationSurveyData,
        };
      },
    });

    const detailsMiddleware = simpleObject({
      pick: [
        'title',
        'code',
        'description',
        'estimatedTaskDuration',
        'timeline',
      ],
      command: 'SET_PROJECT_DETAILS',
      dataBuilder: (project) => ({
        id: isNew ? newProjectId : id,
        project,
      }),
    });

    const createMiddleware = combine(
      simpleObject({
        pick: ['title'],
        command: 'CREATE_PROJECT',
        dataBuilder: (project) => ({
          id: newProjectId,
          project,
        }),
      }),
      simpleObject({
        pick: ['code', 'description', 'estimatedTaskDuration', 'timeline'],
        command: 'SET_PROJECT_DETAILS',
        dataBuilder: (project) => ({
          id: newProjectId,
          project,
        }),
      })
    );

    const upsertMiddleware = branch(isNew, createMiddleware, detailsMiddleware);
    const basicMiddleware = getBasicMiddleware(id, 'project');
    const middleware = combine(
      upsertMiddleware,
      basicMiddleware,
      briefingMiddleware,
      evaluationSurveyMiddleware
    );

    const changes = middleware(form);
    console.log('C', changes);
    if (isEmpty(changes)) {
      return;
    }

    const idsToSubscribe = await applyChanges(
      client,
      changes,
      PROJECT_MUTATION
    );
    await subscribe(last(idsToSubscribe));
    queryClient.invalidateQueries(['project', id]);
  }, []);
}

export function useGetProject(id: string, pause?: boolean) {
  const graphqlFetch = useGraphQLFetch();
  const { data, ...other } = useQuery<Pick<Query, 'project'>>(
    ['project', id],
    () => graphqlFetch(PROJECT_QUERY.SEARCH_PROJECT_BY_ID, { id: id }),
    {
      enabled: pause !== true,
    }
  );

  return { data, ...other };
}

export function useGetProjectLists(projectId: string, pause?: boolean) {
  const graphqlFetch = useGraphQLFetch();
  const { data, ...other } = useQuery<Pick<Query, 'projectLists'>>(
    ['projectLists', projectId],
    () =>
      graphqlFetch(PROJECT_LIST_QUERY.SEARCH_PROJECT_LIST_BY_PROJECTID, {
        projectId: projectId,
      }),
    {
      enabled: pause !== true,
    }
  );

  return { data, ...other };
}
