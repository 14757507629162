import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Query } from 'src/apps/athena/gql-types';
import { WorkflowState } from 'src/apps/kgest/pages/workflow/types';
import { useGraphQLFetch } from 'src/utils/graphql';

export function useKeixPlatform() {
  const graphqlFetch = useGraphQLFetch();

  const getWorkflow = useCallback(async (id: string) => {
    const data: Pick<Query, 'workflow'> = await graphqlFetch(
      'query getWorkflow($id: String!) { workflow(id: $id) }',
      { id }
    );
    return data.workflow as WorkflowState | null;
  }, []);

  const saveWorkflow = useMutation(
    ['saveWorkflow'],
    (variables: { id: string; workflow: WorkflowState }) =>
      graphqlFetch(
        'mutation saveWorkflow($id: String!, $workflow: JSON) { saveWorkflow(id: $id, workflow: $workflow) }',
        variables
      )
  );
  return { saveWorkflow, getWorkflow };
}
