import { Icon, IconName, Menu, MenuItem } from '@blueprintjs/core';
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  DependencyList,
} from 'react';

export interface Crumb {
  text: string;
  href: string;
  menu?: JSX.Element;
  onClick?: () => void;
}

export interface CrumbsCtx {
  crumbs: Crumb[];
  updateCrumbs(crumbs: Crumb[]): void;
}

const CrumbsContext = createContext<CrumbsCtx>({
  crumbs: [],
  updateCrumbs: () => null,
});

export function useDynamicCrumbs(fn: () => Crumb[], deps?: DependencyList) {
  const ctx = useCrumbsContext();
  const crumbs = useMemo(fn, deps ?? []);
  useEffect(() => {
    ctx.updateCrumbs(crumbs);
  }, [crumbs]);
}

export interface MenuCrumbOption<T> {
  icon: IconName;
  text: string;
  value: T;
}

export function useMenuCrumb<T>(
  href: string,
  options: MenuCrumbOption<T>[],
  initialValue: T
): [T, Crumb, (val: T) => void] {
  const [value, setValue] = useState<T>(initialValue);

  const text = options.find((v) => v.value === value)?.text ?? '-';
  const menu = (
    <Menu>
      {options.map((option, index) => (
        <MenuItem
          key={index}
          {...option}
          onClick={() => setValue(option.value)}
          labelElement={
            <Icon icon={option.value == value ? 'tick' : 'blank'} />
          }
        />
      ))}
    </Menu>
  );
  const crumb = {
    text,
    href,
    menu,
  };

  return [value, crumb, setValue];
}

export function useCrumbs(crumbs: Crumb[]) {
  const ctx = useCrumbsContext();
  useEffect(() => {
    ctx.updateCrumbs(crumbs);
  }, []);
}

export function useCrumbsContext() {
  return useContext(CrumbsContext);
}

export function CrumbsProvider(p: { children: JSX.Element }) {
  const [crumbs, updateCrumbs] = useState<Crumb[]>([]);
  const context = { crumbs, updateCrumbs };

  return (
    <CrumbsContext.Provider value={context}>
      {p.children}
    </CrumbsContext.Provider>
  );
}

export function useResourceDetailCrumbs(crumb: Crumb) {
  const { updateCrumbs, crumbs } = useCrumbsContext();
  const oldCrumbs = useRef<Crumb[]>([]);

  function regenerateCrumbs() {
    // Update the crumbs.
    updateCrumbs([...oldCrumbs.current, crumb]);
  }

  useEffect(() => {
    // Regerenerate crumbs.
    oldCrumbs.current = crumbs.slice(0, 1);

    regenerateCrumbs();
    return () => {
      // Bring back old crumbs.
      updateCrumbs(oldCrumbs.current);
    };
  }, []);

  useEffect(() => {
    regenerateCrumbs();
  }, [crumb.href, crumb.text]);
}
