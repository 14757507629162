import { isEmpty, get, set, isArray, Dictionary } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion } from 'src/components/Accordion';
import { FieldRow } from 'src/components/EditableField';
import {
  useLibraryContext,
  resolveDataType,
  DATA_TYPE_URI,
} from 'src/hooks/workflow/LibraryContext';
import {
  Block,
  DataTypeDefinition,
  LibraryBlockArgument,
} from '@keix/workflow-types';
import {
  getBlockArguments,
  getDataType,
  getDefaultType,
} from 'src/hooks/workflow/utils';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';
import { Node } from 'react-flow-renderer';
import {
  Button,
  EditableText,
  Expander,
  Icon,
  Intent,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import DataType from './DataType';
import { Select2 } from '@blueprintjs/select';
import { Popover2 } from '@blueprintjs/popover2';

export default function BlockNodeArguments(p: { node: Node<Block> }) {
  const [isEditing, setEditing] = useState(false);
  const rightElement = isEditing ? (
    <div className="flex gap-x-1">
      <Button
        minimal
        small
        icon="cross"
        text="Cancel"
        onClick={() => setEditing(false)}
      />
      <Button
        minimal
        small
        icon="tick"
        text="Save"
        intent={Intent.PRIMARY}
        onClick={() => setEditing(false)}
      />
    </div>
  ) : (
    <Button minimal small icon="edit" onClick={() => setEditing(true)} />
  );
  return (
    <Accordion
      initiallyOpened={true}
      kind="section"
      title="Arguments"
      rightElement={rightElement}
    >
      <NodeArguments node={p.node} isEditing={isEditing} />
    </Accordion>
  );
}
function NodeArguments(p: { node: Node<Block>; isEditing: boolean }) {
  const { dispatch } = useWorkflowContext();

  const { dataTypes } = useLibraryContext();
  const typeDataType = dataTypes[DATA_TYPE_URI];
  const args =
    getBlockArguments(p.node.data).filter((d) => d.isPort !== true) ?? [];
  if (isEmpty(args)) {
    return (
      <div className="text-gray-400 text-center p-4">
        No arguments available
      </div>
    );
  }

  const updateNodeArgument = (argument: LibraryBlockArgument) =>
    dispatch({
      type: WorkflowAction.EDIT_NODE_ARGUMENT,
      id: p.node.id,
      argument,
    });

  return (
    <div className="py-2 flex flex-col divide-y">
      {args.map((d) => (
        <FieldRow
          key={d.name}
          className="autosize py-2"
          helperText={d.description}
          label={
            p.isEditing ? (
              <EditableText
                defaultValue={d.name}
                onConfirm={(v) => updateNodeArgument(set(d, 'name', v))}
              />
            ) : (
              d.name
            )
          }
        >
          {p.isEditing ? (
            <div className="flex items-center">
              <Select2<DataTypeDefinition>
                items={Object.values(dataTypes)}
                onItemSelect={(item) =>
                  updateNodeArgument(set(d, 'type', item.uri))
                }
                itemRenderer={(item, props) => (
                  <MenuItem
                    icon={item.icon}
                    text={item.name}
                    label={item.uri}
                    onClick={props.handleClick}
                    selected={d.type === item.uri}
                  />
                )}
              >
                <Button
                  minimal
                  rightIcon="double-caret-vertical"
                  text={<DataType type={getDefaultType(d.type)} />}
                />
              </Select2>
              <Expander />
              <Button small minimal icon="edit" />
              <Button
                small
                minimal
                icon="cross"
                intent={Intent.DANGER}
                onClick={() =>
                  dispatch({
                    type: WorkflowAction.REMOVE_NODE_PARAMETER,
                    id: p.node.id,
                    name: d.name,
                  })
                }
              />
            </div>
          ) : (
            <ArgumentField block={p.node.data} argument={d} />
          )}
        </FieldRow>
      ))}
      {p.isEditing && (
        <div className="px-2 py-1">
          <Button
            icon="plus"
            text="Add Argument"
            intent={Intent.PRIMARY}
            fill
            onClick={() =>
              dispatch({
                type: WorkflowAction.ADD_NODE_PARAMETER,
                id: p.node.id,
              })
            }
            minimal
          />
        </div>
      )}
    </div>
  );
}

function ArgumentField(p: { block: Block; argument: LibraryBlockArgument }) {
  const { dispatch } = useWorkflowContext();
  const { dataTypes } = useLibraryContext();
  const { id } = p.block;
  const { name, type } = p.argument;

  const typeToShow = isArray(type) ? type[0] : type;

  const value = get(p.block.parameters, p.argument.name);
  const handleValueChange = useCallback(
    (value: any) => {
      dispatch({
        type: WorkflowAction.SET_NODE_PARAMETER_VALUE,
        id,
        name,
        value,
      });
    },
    [id, name]
  );

  const dataType = getDataType(typeToShow, dataTypes);
  const dataTypeSelector = isArray(type) ? (
    <Popover2
      content={
        <Menu>
          {type.map((d, index) => {
            const dataType = getDataType(d, dataTypes);
            return (
              <MenuItem
                icon={dataType?.icon}
                labelElement={
                  index === 0 ? (
                    <Icon intent={Intent.PRIMARY} icon="tick" />
                  ) : null
                }
                text={dataType?.name}
                onClick={() =>
                  dispatch({
                    type: WorkflowAction.SET_NODE_ARGUMENT_PREFERRED_TYPE,
                    name,
                    dataType: dataType.uri,
                    id,
                  })
                }
              />
            );
          })}
        </Menu>
      }
    >
      <Button
        rightIcon="chevron-down"
        icon={dataType?.icon ?? 'help'}
        minimal
      />
    </Popover2>
  ) : (
    <React.Fragment />
  );

  const Component =
    dataType?.inputComponent ??
    (() => <div className="text-gray-400">Unknown data type {typeToShow}</div>);

  return (
    <div className="flex">
      <div className="flex-grow">
        <Component
          block={p.block}
          value={value}
          onChange={handleValueChange}
          type={dataType}
        />
      </div>
      {dataTypeSelector}
    </div>
  );
}
