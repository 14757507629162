import React, { useCallback, useEffect, useState } from 'react';
// import ReactDatePicker from 'react-date-picker';
import { useField, useFieldValue } from 'formik';
import { DateTime } from 'luxon';
import { DateInput2 as BlueprintDatePicker } from '@blueprintjs/datetime2';
import { pick } from 'lodash';
import {
  Button,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Popover,
} from '@blueprintjs/core';
import { Checkbox, Switch } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

function defaultFormatDate() {}

export default function DatePicker(props: {
  formatDate?: (date: Date) => string;
  parseDate?: (date: string) => Date;
  rightElement?: JSX.Element;
  className?: string;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
  minimal?: boolean;
  disabled?: boolean;
  onChange: (date: Date) => void;
  value: Date | null;
}) {
  const formatDate = useCallback(
    (d: Date) => DateTime.fromJSDate(d).toLocaleString(DateTime.DATE_MED),
    []
  );
  return (
    <BlueprintDatePicker
      {...props}
      inputProps={{
        className: props.minimal ? Classes.MINIMAL : '',
        leftIcon: 'calendar',
        rightElement: props.rightElement,
      }}
      formatDate={formatDate}
      parseDate={tryToParseDate}
      value={props.value?.toISOString()}
      onChange={(val) => props.onChange(new Date(val))}
    />
  );
}

function tryToParseDate(date: string): Date | null {
  try {
    const d = DateTime.fromISO(date);
    if (d.isValid) {
      return d.toJSDate();
    } else {
      return null;
    }
  } catch {
    return null;
  }
}
export function DatePickerField(p: {
  readOnly?: boolean;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  className?: string;
  placeholder?: string;
  minimal?: boolean;
  rightElement?: JSX.Element;
  dateFormat?: Intl.DateTimeFormatOptions;
}) {
  const [field, meta, helpers] = useField(p.name);
  const date = tryToParseDate(field.value);
  const handleChange = useCallback(
    (value: Date) => {
      const val = value.toISOString();
      helpers.setValue(val);
      helpers.setTouched(val !== meta.initialValue);
    },
    [p.name]
  );
  const formatDate = useCallback(
    (d: Date) => {
      const format = p.dateFormat ?? DateTime.DATE_MED;
      return DateTime.fromJSDate(d).toLocaleString(format);
    },
    [p.dateFormat]
  );
  const parseDate = useCallback(tryToParseDate, []);
  const props = pick(p, [
    'maxDate',
    'minimal',
    'minDate',
    'className',
    'placeholder',
    'disabled',
    'rightElement',
  ]);

  if (p.readOnly === true) {
    return (
      <div className="flex items-center text-gray-600 ">
        <Icon icon="calendar" className="text-gray-400 mr-2" />
        {formatDate(date)}
      </div>
    );
  }

  return (
    <DatePicker
      {...props}
      parseDate={parseDate}
      formatDate={formatDate}
      value={date}
      onChange={handleChange}
    />
  );
}
export function DatePickerPlusDaysField(p: {
  days?: string;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  dateFormat?: Intl.DateTimeFormatOptions;
}) {
  const [fieldDays] = useField(p.days);
  const [dataPlusDay, setDataPlusDay] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date>();

  useEffect(() => {
    if (fieldDays.value) {
      setDataPlusDay(
        DateTime.local().plus({ days: fieldDays.value }).toJSDate()
      );
    } else if (!fieldDays.value) {
      setDataPlusDay(DateTime.local().plus({ days: 90 }).toJSDate());
    }
  }, [fieldDays.value]);

  useEffect(() => {
    setSelectedDate(dataPlusDay);
  }, [dataPlusDay]);

  const handleChange = useCallback((value: Date) => {
    setSelectedDate(value);
  }, []);

  const formatDate = useCallback(
    (d: Date) => {
      const format = p.dateFormat ?? DateTime.DATE_MED;
      return DateTime.fromJSDate(d).toLocaleString(format);
    },
    [p.dateFormat]
  );

  const parseDate = useCallback(tryToParseDate, []);
  const props = pick(p, ['maxDate', 'minDate', 'disabled']);

  return (
    <DatePicker
      {...props}
      parseDate={parseDate}
      formatDate={formatDate}
      value={selectedDate}
      onChange={handleChange}
    />
  );
}

/**
 * This date picker allows to render a formik field that manages a relative date.
 * Optionally, if you pass a relativeTo field name, the date will be relative to the passed field, otherwise
 * it will be relative to today's date.
 *
 * @param {{
 *   name: string;
 *   relativeTo?: string;
 *   readOnly?: boolean;
 * }} p
 * @returns
 */
export function RelativeDatePicker(p: {
  /**
   * The date picker field name.
   *
   * @type {string}
   */
  name: string;
  /**
   * The relative field name, if passed the date picker will be relative to this field value, otherwise it will be relative to today's date.
   *
   * @type {string}
   */
  relativeTo?: string;
  /**
   * If true, the field will be in read only mode.
   *
   * @type {boolean}
   */
  readOnly?: boolean;
  /**
   * If true, the field will be display minimally.
   *
   * @type {boolean}
   */
  minimal?: boolean;
}) {
  const { t } = useTranslation();
  const [field, _, helpers] = useField<string | null>(p.name);
  const [relativeField] = useField<string>(p.relativeTo ?? `_${p.name}`);
  const fieldValue = DateTime.fromISO(field.value);
  const relativeValue = DateTime.fromISO(
    relativeField.value || new Date().toISOString()
  );
  const minDate = relativeValue;

  const days = Math.round(fieldValue.diff(relativeValue, 'days').days);

  // When the relative date changes, we want to make sure that our date
  // is after the relative date, otherwise we just reset to 30 days.
  useEffect(() => {
    if (days <= 0) {
      helpers.setValue(relativeValue.plus({ days: 30 }).toISO());
      helpers.setTouched(true);
    }
  }, [days]);

  const options = [
    {
      value: 90,
      label: t('90 days'),
    },
    {
      value: 7,
      label: t('7 days'),
    },
    {
      value: 15,
      label: t('15 days'),
    },
    {
      value: 30,
      label: t('30 days'),
    },
    {
      value: 60,
      label: t('60 days'),
    },

    {
      value: 120,
      label: t('120 days'),
    },
  ];

  const relativeMenu = (
    <Menu>
      {options.map(({ value, label }) => (
        <MenuItem
          key={label}
          text={label}
          onClick={() => {
            helpers.setValue(relativeValue.plus({ days: value }).toISO());
            helpers.setTouched(true);
          }}
        />
      ))}
    </Menu>
  );

  return (
    <div className="flex items-center">
      <DatePickerField
        name={p.name}
        minimal={p.minimal}
        rightElement={
          <Popover content={relativeMenu}>
            <Button small minimal={p.minimal} rightIcon="double-caret-vertical">
              {days} days
            </Button>
          </Popover>
        }
        minDate={minDate.toJSDate()}
      />
    </div>
  );
}
