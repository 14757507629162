import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Query } from 'src/apps/athena/gql-types';
import WorkflowDesigner from 'src/apps/kgest/pages/workflow/WorkflowDesigner';
import { QUERY } from 'src/graphql/surveys';
import { useLocalExecutor } from 'src/hooks/workflow/useLocalExecutor';
import { GraphQLFetch, useGraphQLFetch } from 'src/utils/graphql';
import { v4 } from 'src/utils/uuid';
import { dumpSurveyData } from './SurveyData';

export default function SurveyLocalDashboard(p: { surveyId: string }) {
  const graphqlFetch = useGraphQLFetch();
  const [mode, setMode] = useState<'dashboard' | 'code'>('dashboard');
  const { isReady, run } = useLocalExecutor();

  //   const play = useCallback(async () => {
  //     const dump = await dumpSurveyData(p.surveyId, graphqlFetch);
  //     (window as any).dump = dump;
  //     run([
  //       {
  //         id: v4(),
  //         uri: 'keix.load_dump',
  //         name: 'load_dump',
  //         connections: [],
  //         parameters: {},
  //         code: `
  // import pandas as pd
  // import js
  // from io import StringIO
  // def load_dump():
  //   return pd.read_csv(StringIO(js.window.dump))
  //         `,
  //       } as any,
  //     ]);
  //   }, [run]);

  return (
    <div className="flex-grow relative">
      <div className="absolute bottom-4 right-4 z-10">
        <ButtonGroup>
          <Button
            text="Dashboard"
            icon="dashboard"
            active={mode === 'dashboard'}
            intent={mode === 'dashboard' ? Intent.PRIMARY : Intent.NONE}
            onClick={() => setMode('dashboard')}
          />
          <Button
            text="Code"
            icon="code"
            active={mode === 'code'}
            intent={mode === 'code' ? Intent.PRIMARY : Intent.NONE}
            onClick={() => setMode('code')}
          />
        </ButtonGroup>
      </div>

      {/* {isReady ? 'Ready' : 'Not Ready'} */}
      {/* <Button icon="play" text="Play" onClick={play} /> */}
    </div>
  );
}
