import React, { useRef, useContext, useCallback, useMemo } from 'react';

import { getLoginState, useLoginContext } from 'src/context/LoginContext';
import { useEnvironment, Environment } from 'src/context/EnvironmentContext';
import { Client, createClient } from 'graphqurl';
import { GraphQLError } from 'graphql';

// function createGraphQLClient(env: Environment) {
//   const { GRAPHQL_ENDPOINT, GRAPHQL_WS_ENDPOINT, GRAPHQL_INTROSPECTION } = env;

//   if (GRAPHQL_ENDPOINT == null) {
//     return null;
//   }

//   // const subscriptionClient =
//   //   GRAPHQL_WS_ENDPOINT != null
//   //     ? new websocket.SubscriptionClient(GRAPHQL_WS_ENDPOINT, {
//   //         reconnect: true,
//   //       })
//   //     : null;

//   // const subExchange =
//   //   subscriptionClient != null
//   //     ? subscriptionExchange({
//   //         forwardSubscription: (operation) =>
//   //           subscriptionClient.request(operation),
//   //       })
//   //     : null;

//   const state = getLoginState();
//   const loggedIn = (state.loggedIn && state.idToken != null);
//   const headers = {
//     return { authorization: `Bearer ${state.idToken}` };
//   }
//   return {

//   return createClient({
//     url: GRAPHQL_ENDPOINT,
//     headers: () => {
//      };
//     },
//   });

//   // return createClient({
//   //   url: GRAPHQL_ENDPOINT,
//   //   fetchOptions: () => {

//   //   },
//   //   exchanges: compact<Exchange>([
//   //     dedupExchange,
//   //     // devtoolsExchange,
//   //     cacheExchange({
//   //       schema: GRAPHQL_INTROSPECTION as any,
//   //       keys: {
//   //         CertificateRenewal: () => null,
//   //         CompetenceActivity: () => null,
//   //         CertificateInvoice: () => null,
//   //         NaturalVariableDomain: () => null,
//   //         RealVariableDomain: () => null,
//   //         NeverAgeingFunction: () => null,
//   //         LinearAgeingFunction: () => null,
//   //         AgeingFunction: () => null,
//   //         CurveAgeingFunction: () => null,
//   //         Price: () => null,
//   //         ActivityPrice: () => null,
//   //       },
//   //     }) as any,
//   //     fetchExchange,
//   //     // subExchange,
//   //   ]),
//   // });
// }

export const GraphQLContext = React.createContext<Client | null>(null);

export default function GraphQLProvider(p: { children: JSX.Element }) {
  const env = useEnvironment();
  // const client = useRef(createGraphQLClient(env));

  const state = getLoginState();
  const isLoggedIn = state.loggedIn && state.idToken != null;

  const client = useMemo(() => {
    const baseHeaders = { 'Content-Type': 'application/json' };
    const headers = isLoggedIn
      ? { ...baseHeaders, authorization: `Bearer ${state.idToken}` }
      : baseHeaders;
    return createClient({
      endpoint: env.GRAPHQL_ENDPOINT,
      headers,
      websocket: {
        endpoint: env.GRAPHQL_WS_ENDPOINT,
        shouldRetry: true,
        onConnectionSuccess: () => {
          console.log('[WS] Connection succeded...');
        },
        onConnectionError: (err) => {
          console.log('[WS] Connection failed with error:', err);
        },
        onConnectionKeepAlive: () => {
          console.log('[WS] Keep alive...');
        },
      },
    });
  }, [isLoggedIn]);

  return (
    <GraphQLContext.Provider value={client}>
      {p.children}
    </GraphQLContext.Provider>
  );
}

export type GraphQLFetch<T, V = {}> = (
  query: string,
  variables?: V
) => Promise<T>;

export function useGraphqlClient() {
  return useContext(GraphQLContext);
}

export function useGraphQLFetch<
  T,
  V extends Record<string, unknown>
>(): GraphQLFetch<T, V> {
  const client = useContext(GraphQLContext);

  return useCallback(async (query: string, variables?: V) => {
    return client
      .query({
        query,
        variables,
      })
      .then((res) => res.data as T)
      .catch((err: { data: T; errors: GraphQLError[] }) => {
        // We can accept data even if there are some errors in the request.
        console.error(err.errors);
        return err.data;
      });
  }, []);
}
