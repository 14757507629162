import React from 'react';
import { TitleTabBar, HeaderTab } from './PageHeader';
import { NotesByUri, useNotesQuery } from './NotesByUri';
import classNames from 'classnames';
import css from 'csz';
import Documents from './Documents';
import { useTabBar } from './TabBar';
import { useTranslation } from 'react-i18next';
import { EventsByResource } from './Events';

function useHeaderTabs(uri: string): HeaderTab[] {
  const { data } = useNotesQuery(uri);
  const { t } = useTranslation();
  const count = data?.notes?.items?.length ?? 0;

  return [
    {
      id: 'events',
      title: t('History'),
      icon: 'history',
    },
    {
      id: 'notes',
      title: t('Notes'),
      icon: 'clipboard',
      badge: count > 0 ? { title: `${count}` } : null,
    },
    { id: 'documents', title: t('Documents'), icon: 'folder-open' },
  ];
}

export function ContextualPanel(p: {
  uri: string;
  resource?: string;
  className?: string;
  width?: number | string;
}) {
  const { uri } = p;
  const resource = p.resource ?? uri;
  const headerTabs = useHeaderTabs(uri);
  const { currentTab, tabs, onChangeTab } = useTabBar(
    headerTabs,
    resource.startsWith('certificates') ? 'events' : 'notes'
  );

  const style = p.width != null ? { width: p.width } : {};
  return (
    <div className={classNames(detailClassName, p.className)} style={style}>
      <div className={titleBarContainer}>
        <TitleTabBar
          id=""
          currentTab={currentTab}
          onChangeTab={onChangeTab}
          tabs={tabs}
        />
      </div>
      {currentTab === 'events' && <EventsByResource resource={resource} />}
      {currentTab === 'notes' && <NotesByUri uri={uri} />}
      {currentTab === 'documents' && <Documents editable={true} uri={uri} />}
    </div>
  );
}
const titleBarContainer = css`
  padding: 4px 86px 4px 16px;
  flex-shrink: 0;
  > div {
    overflow-x: scroll;
  }
`;

const detailClassName = css`
  padding: 8px;
  flex-grow: 1;
  background: var(--body-bg-color);
`;
