export const PROJECT_QUERY = {
  SEARCH_QUERY_OMNIBAR:
    'query ($queryString: String) { projects(queryString: $queryString) { items { id title } } }',
  SEARCH_PROJECTS_LIST:
    'query($filters: JSON, $cursor: JSON){ projects (filters: $filters, cursor: $cursor) { pageInfo { total endCursor } items { id quotationId code createdAt updatedAt title description estimatedTaskDuration timeline { startDate endDate } } } }',
  SEARCH_PROJECT_BY_ID:
    'query($id: String!) { project(id: $id) { id quotationId code createdAt updatedAt title description estimatedTaskDuration timeline { startDate endDate } briefing { id videoUrl body} evaluationSurvey { id surveyId minScore } } }',
};

export const PROJECT_MUTATION = {
  CREATE_PROJECT:
    'mutation CreateProject($id: String, $project: ProjectBasicDataInput!) { createProject(id: $id, project: $project) }',
  SET_PROJECT_DETAILS:
    'mutation SetProjectDetails($id: String!, $project: ProjectDetailsDataInput!) { setProjectDetails(id: $id, project: $project) }',
  SET_PROJECT_BRIEFING:
    'mutation SetProjectBriefing($id: String!, $briefing: ProjectBriefingDataInput!) { setProjectBriefing(id: $id, briefing: $briefing) }',
  SET_PROJECT_EVALUATION_SURVEY:
    'mutation SetProjectEvaluationSurvey($id: String!, $evaluationSurvey: ProjectEvaluationSurveyDataInput!) { setProjectEvaluationSurvey(id: $id, evaluationSurvey: $evaluationSurvey) }',
};

export const PROJECT_LIST_QUERY = {
  SEARCH_PROJECT_LIST_BY_PROJECTID:
    'query ($projectId: String!) { projectLists(projectId: $projectId) { pageInfo { total endCursor } items { id name projectId }}}',
  SEARCH_PROJECT_LIST_BY_ID:
    'query ($id: String!) { projectList(id: $id) { id name projectId } }',
};

export const PROJECT_LIST_MUTATION = {
  ADD_PROJECT_LIST:
    'mutation AddProjectList($id: String, $name: String!, $projectId: String!) { addProjectList(id: $id, name: $name, projectId: $projectId) }',
};

export const CANDIDATE_QUERY = {
  SEARCH_CANDIDATES_BY_PROJECTID:
    'query($filters: JSON, $cursor: JSON) { projectCandidates(projectId: "db403ca9-2b7b-48ba-8aed-a2ac1d3b5163", cursor: $cursor, filters: $filters) { items { id projectId person { id firstName lastName} projectList { id name } assignment { id state sentAt } } pageInfo { endCursor total } } }',
  SEARCH_CANDIDATE_BY_ID:
    'query($id: String!) { projectCandidate(id: $id) { id projectId person { id firstName lastName} projectList { id name } assignment { id state sentAt } } }',
};

export const CANDIDATE_MUTATION = {
  ADD_PROJECT_CANDIDATE:
    'mutation AddProjectCandidate( $projectId: String! $personId: [String]!, $projectListId: String) { addProjectCandidate(projectId: $projectId, personId: $personId, projectListId: $projectListId ) }',
  REMOVE_PROJECT_CANDIDATE:
    'mutation RemoveProjectCandidate( $projectId: String! $personId: [String]!) { removeProjectCandidate(projectId: $projectId, personId: $personId ) }',
  SEND_PROJECT_ASSIGNMENT:
    'mutation SendProjectAssignment($id: String!, $projectId: String! $candidates: [String]!) { sendProjectAssignment(id: $id, projectId: $projectId, candidates: $candidates ) }',
};

export const PROJECT_AUTOMATION_QUERY = {
  RETRIEVE_PROJECT_AUTOMATIONS_BY_PROJECTID:
    'query($projectId: String!) { projectAutomations(projectId: $projectId) { items { id name state frequency { amount unit } steps { id title type data } projectList { id name } executionIntervals { from to exclude }  } pageInfo { endCursor total } } }',
  GET_PROJECT_AUTOMATION:
    'query($id: String!) { projectAutomation(id: $id) { id name state frequency { amount unit } steps { id title type data } projectListId executionIndex executionSize nextTick executionIntervals { from to exclude } } }',
};

export const PROJECT_AUTOMATION_MUTATION = {
  START_AUTOMATION:
    'mutation StartAutomation($id: String!) { startProjectAutomation(id: $id) }',
  CREATE_PROJECT_AUTOMATION:
    'mutation CreateProjectAutomation($automation: AutomationInput!) { createProjectAutomation(automation: $automation) }',
  UPDATE_PROJECT_AUTOMATION:
    'mutation UpdateProjectAutomation($id: String!, $automation: UpdateAutomationInput!) { updateProjectAutomation(id: $id, automation: $automation) }',
  STOP_AUTOMATION:
    'mutation StopAutomation($id: String!) { stopProjectAutomation(id: $id) }',
  PAUSE_AUTOMATION:
    'mutation PauseAutomation($id: String!) { pauseProjectAutomation(id: $id) }',
};
