import React, { useState } from 'react';
import css from 'csz';
import { InputGroup, ITagProps } from '@blueprintjs/core';
import {
  Tag,
  Button,
  Popover,
  Menu,
  MenuItem,
  Intent,
} from '@blueprintjs/core';
const className = css`/styles/master-detail.css`;

export interface MasterRowTag extends ITagProps {
  text: string;
}

export function MasterDetail(props: {
  className?: string;
  card?: boolean;
  masterRatio?: number;
  master: JSX.Element;
  detail: JSX.Element;
}) {
  const masterRatio = props.masterRatio ?? 0.33;
  const detailRatio = 1 - masterRatio;
  return (
    <div
      className={`${props.card !== false ? 'card' : ''} p0 ${className} ${
        props.className
      }`}
    >
      <div className="master" style={{ width: `${masterRatio * 100}%` }}>
        {props.master}
      </div>
      <div className="detail" style={{ width: `${detailRatio * 100}%` }}>
        {props.detail}
      </div>
    </div>
  );
}

export function MasterTitleBar(p: {
  title: JSX.Element | string;
  query?: string;
  onQueryChange?: (val: string) => void;
}) {
  return (
    <div className="master-title-bar">
      <div className="flex-grow px-2">{p.title}</div>
      {p.query != null ? (
        <AnimatedSearchBar {...p} query={p.query} />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

function AnimatedSearchBar(p: {
  query: string;
  onQueryChange?: (val: string) => void;
}) {
  const [searching, setSearching] = useState(false);

  return searching ? (
    <InputGroup
      value={p.query}
      type="search"
      leftIcon="search"
      onChange={(e) => p.onQueryChange?.(e.target.value)}
    />
  ) : (
    <Button minimal icon="search" onClick={() => setSearching(true)} />
  );
}

export function MasterActions(p: { children: JSX.Element }) {
  return <div className="master-actions">{p.children}</div>;
}

export function MasterRow(props: {
  icon?: JSX.Element;
  title: string | JSX.Element;
  time?: string | JSX.Element;
  selected?: boolean;
  className?: string;
  disabled?: boolean;
  subtitle?: string | JSX.Element;
  onDelete?: () => void;
  tags?: MasterRowTag[];
  onClick?: () => void;
}) {
  const [isOpen, setOpen] = useState(false);
  const shouldRenderActions = props.onDelete != null;
  const menu = (
    <Menu>
      <MenuItem
        text="Delete"
        intent={Intent.DANGER}
        icon="trash"
        onClick={props.onDelete}
      />
    </Menu>
  );
  return (
    <div
      className={`master-row ${props.selected ? 'selected' : ''} ${
        props.disabled ? 'disabled' : ''
      } ${props.className}`}
      onClick={props.disabled !== true && props.onClick}
    >
      {props.icon && <div className="icon">{props.icon}</div>}
      <div className="content">
        <div className="flex items-baseline">
          <div
            className={`${
              props.selected ? 'primary-text' : 'text-gray-600'
            } font-semibold flex-grow text-base`}
          >
            {props.title}
          </div>
          <div className="text-gray-400 text-">{props.time}</div>
        </div>
        <div className="text-gray-500 text-sm">{props.subtitle}</div>
        <div className="tags">
          {props.tags?.map(({ text, ...tag }, index) => (
            <Tag key={index} {...tag}>
              {text}
            </Tag>
          ))}
        </div>
      </div>
      {shouldRenderActions && (
        <div className={`action ${isOpen ? 'open' : ''}`}>
          <Popover
            isOpen={isOpen}
            position="bottom"
            onClose={() => setOpen(false)}
            content={menu}
          >
            <Button icon="chevron-down" minimal onClick={() => setOpen(true)} />
          </Popover>
        </div>
      )}
    </div>
  );
}
