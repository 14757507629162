import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import type {
  Query,
  Question,
  Survey,
  SurveyQuestion,
  SurveyQuestionInput,
} from 'src/apps/athena/gql-types';
import CenteredSpinner from 'src/components/CenteredSpinner';
import { useTranslation } from 'react-i18next';
import { useCrumbs, useDynamicCrumbs } from 'src/context/CrumbsContext';
import { SurveyData } from 'src/components/surveys/SurveyData';
import { PageHeader } from 'src/components/PageHeader';
import { useTabBar } from 'src/components/TabBar';
import SurveyLogic from 'src/components/surveys/SurveyLogic';
import { useGraphQLFetch } from 'src/utils/graphql';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QUERY } from 'src/graphql/surveys';
import { ScrollView } from 'src/components/ScrollView';
import { SurveyDesign } from 'src/components/surveys/SurveyDesign';
import SurveyShare from 'src/components/surveys/SurveyShare';
import SurveyStyle from 'src/components/surveys/SurveyStyle';
import SurveyTemplate from 'src/components/surveys/SurveyTemplate';
import { OmnibarSource } from 'src/context/OmnibarContext';
import SurveyOptions from 'src/components/surveys/SurveyOptions';
import SurveyDashboard from 'src/components/surveys/SurveyDashboard';
import SurveyLocalDashboard from 'src/components/surveys/SurveyLocalDashboard';
import SurveyLanguages from 'src/components/surveys/SurveyLanguages';
import { compact, isEmpty, set } from 'lodash/fp';
import SurveyCluster from 'src/components/surveys/SurveyCluster';
import { useTitle } from 'hoofd';
import SurveyHeaderElement from './SurveyHeaderElement';
import SurveyWorkflow from './SurveyWorkflow';

export const surveysOmnibarSource: OmnibarSource = {
  key: 'survey',
  kind: 'survey',
  icon: 'form',
  title: 'Surveys',
  query: QUERY.SEARCH_SURVEY,
  dataKey: 'surveysSuggester',
  valueRenderer: (v: Survey) => v.name,
};

export function getSurveyLanguages() {
  return [
    { countryCode: 'IT', name: 'Italian' },
    { countryCode: 'EN', name: 'English' },
    { countryCode: 'ES', name: 'Spanish' },
    { countryCode: 'FR', name: 'French' },
    { countryCode: 'DE', name: 'German' },
    { countryCode: 'PT', name: 'Portuguese' },
    { countryCode: 'SE', name: 'Swedish' },
    { countryCode: 'NL', name: 'Dutch' },
    { countryCode: 'CZ', name: 'Czech' },
    { countryCode: 'SK', name: 'Slovak' },
  ];
}
export type SurveyQuestionData = Question & Pick<SurveyQuestion, 'questionId'>;
export type SurveyWithQuestions = Omit<Survey, 'questions'> & {
  questions?: Partial<SurveyQuestionData>[];
};

export function useGetSurvey(id: string): UseQueryResult<{
  survey?: Survey;
}> {
  const graphqlFetch = useGraphQLFetch();
  return useQuery<Pick<Query, 'survey'>>(['survey', id], () =>
    graphqlFetch(QUERY.GET_SURVEY, { id })
  );
}
export function useGetSurveyWithQuestions(id: string): UseQueryResult<{
  survey?: SurveyWithQuestions;
}> {
  const response = useGetSurvey(id);
  const { data: surveyQuestionData } = useGetSurveyQuestions(
    id,
    response.data?.survey?.questions
  );

  const surveyQuestions = surveyQuestionData?.getQuestions;
  const questions = useMemo(() => {
    if (isEmpty(surveyQuestions)) {
      return response.data?.survey?.questions;
    }
    return response.data?.survey?.questions?.map(
      ({ id, questionId, variables }, index) => {
        const question = surveyQuestions[index];
        return {
          ...question,
          id,
          questionId,
          variables,
        };
      }
    );
  }, [surveyQuestions, response.data?.survey?.revision]);

  return set('data.survey.questions', questions)(response) as any;

}

export function useGetSurveyQuestions(
  surveyId: string,
  questions?: SurveyQuestion[]
) {
  const ids = questions?.map((d) => d.questionId) ?? [];
  const graphqlFetch = useGraphQLFetch();
  const queryKey = ids.map((d) => d.slice(0, 6)).join('');
  return useQuery<Pick<Query, 'getQuestions'>>(
    ['surveyQuestions', queryKey],
    () => graphqlFetch(QUERY.GET_SURVEY_QUESTIONS, { ids }),
    { enabled: !isEmpty(ids) }
  );
}

export default function SurveyPage() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetSurveyWithQuestions(id);
  const { t } = useTranslation('survey');
  useTitle(data?.survey?.name ?? t('Loading...'));

  useDynamicCrumbs(
    () => [
      { href: '/surveys', text: t('Surveys', { ns: 'translation' }) },
      { href: `/survey/${id}`, text: data?.survey?.name ?? t('Loading') },
    ],
    [data?.survey?.name]
  );

  const tabs = useTabBar(
    compact([
      { id: 'dashboard-beta', title: 'Dashboard', icon: 'dashboard' },
      { id: 'workflow', title: 'Workflow', icon: 'code' },
      { id: 'quote', title: 'Quote', icon: 'heat-grid' },
      { id: 'design', title: 'Design', icon: 'page-layout' },
      { id: 'style', title: 'Style', icon: 'style' },
      { id: 'logic', title: 'Logic', icon: 'function' },
      { id: 'settings', title: 'Settings', icon: 'settings' },
      { id: 'share', title: 'Share', icon: 'share' },
      data?.survey?.application?.isTemplate && {
        id: 'template',
        title: 'Template',
        icon: 'variable',
      },
      { id: 'data', title: 'Data', icon: 'database' },
    ]),
    'design'
    // 'workflow'
  );

  if (isLoading || data == null) {
    return <CenteredSpinner />;
  }

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader
        loading={isLoading}
        title={data?.survey?.name}
        id="survey"
        rightElement={<SurveyHeaderElement surveyId={id} />}
        {...tabs}
      />
      <ScrollView>
        {tabs.currentTab === 'dashboard' && <SurveyDashboard surveyId={id} />}
        {tabs.currentTab === 'dashboard-beta' && (
          <SurveyLocalDashboard surveyId={id} />
        )}
        {tabs.currentTab === 'workflow' && <SurveyWorkflow surveyId={id} />}
        {tabs.currentTab === 'quote' && <SurveyCluster surveyId={id} />}
        {tabs.currentTab === 'design' && <SurveyDesign surveyId={id} />}
        {tabs.currentTab === 'style' && <SurveyStyle surveyId={id} />}
        {tabs.currentTab === 'logic' && <SurveyLogic surveyId={id} />}
        {tabs.currentTab === 'share' && <SurveyShare surveyId={id} />}
        {tabs.currentTab === 'settings' && <SurveyOptions surveyId={id} />}
        {tabs.currentTab === 'template' && <SurveyTemplate surveyId={id} />}
        {tabs.currentTab === 'data' && <SurveyData surveyId={id} />}
      </ScrollView>
    </div>
  );
}
