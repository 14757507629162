import React, { useMemo, useRef, useState } from 'react';
import { DataTypeDefinition, PreviewDataType, PyProxy } from '@keix/workflow-types';

import { useEffect } from 'react';

const AgGrid = React.lazy(() => import('src/components/AgGrid'));

import type { ColDef } from 'ag-grid-community';
import { isArray, range } from 'lodash';
import { isEmpty, last } from 'lodash/fp';

export const dataTypes: DataTypeDefinition[] = [
  {
    name: 'series',
    uri: 'pandas.series',
    icon: 'array-numeric',
    description: 'A Pandas series',
    preview: (proxy: PyProxy, { type, size }) => {
      return <SeriesPreview proxy={proxy} type={type} {...size} />;
    },
  },
  {
    name: 'dataframe',
    uri: 'pandas.dataframe',
    icon: 'panel-table',
    description: 'A Pandas Dataframe',
    preview: (proxy: PyProxy, { type, size }) => {
      return <DataframePreview proxy={proxy} type={type} {...size} />;
    },
  },
];

export function PandasPreview(p: {
  proxy: any;
  type: PreviewDataType;
  width?: number | string;
  height?: number | string;
}) {
  const { proxy, type } = p;
  if (proxy == null || !('index' in proxy)) {
    return <React.Fragment />;
  }

  if ('columns' in proxy) {
    return <DataframePreview {...p} />;
  } else {
    return <SeriesPreview {...p} />;
  }
}

export function DataframePreview(p: {
  proxy: any;
  type: PreviewDataType;
  width?: number | string;
  height?: number | string;
}) {
  const { proxy, type, width, height } = p;
  const hasProxy = proxy != null && 'index' in proxy;

  const shape: number[] = hasProxy ? proxy.shape.toJs({ create_proxies: false }) : [];
  // const columns: string[] = hasProxy ? proxy.columns.tolist().toJs({ create_proxies: false }) : [];
  // const data: any = hasProxy
  //   ? proxy.to_dict
  //     .callKwargs({ orient: 'records' })
  //     .toJs({ dict_converter: Object.fromEntries, create_proxies: false })
  //   : [];

  const data = getPreviewData<unknown>(proxy);
  const columns = !isEmpty(data) ? Object.keys(data[0]) : [];

  const columnDefs: ColDef[] = useMemo(
    () => columns.map((field) => ({ field: String(field), resizable: true })),
    [columns]
  );

  if (!hasProxy) {
    return <React.Fragment />;
  }

  if (type === PreviewDataType.MICRO) {
    return (
      <div>
        {shape[0]} x {shape[1]}
      </div>
    );
  }

  const style = useMemo(
    () => ({
      width: width ?? 600,
      height: height ?? 400,
    }),
    [width, height]
  );

  console.log(columns);
  return (
    <div className="ag-theme-alpine bg-red-50 z-10" style={style}>
      <AgGrid
        headerHeight={30}
        rowHeight={30}
        columnDefs={columnDefs}
        rowData={data}
      />
    </div>
  );
}

export function getPreviewData<T>(proxy: any): T[] {
  if (proxy == null || !('index' in proxy)) {
    return [];
  }

  if ('columns' in proxy) {
    const res: { index: string[]; columns: (string | string[])[]; data: unknown[][] } = proxy.to_dict
      .callKwargs({ orient: 'tight' })
      .toJs({ dict_converter: Object.fromEntries, create_proxies: false });

    return res.index.map((index, idx) => ({
      index,
      ...res.columns.reduce((prev, next, jdx) => ({
        ...prev,
        [isArray(next) ? last(next) : next]: res.data[idx][jdx]
      }), {})
    })) as any;

  } else {
    const data: any = proxy.to_list().toJs({ dict_converter: Object.fromEntries, create_proxies: false });
    const index: any = proxy.index.tolist().toJs({ dict_converter: Object.fromEntries, create_proxies: false });
    return data.map((d: any, idx: number) => ({
      index: index[idx],
      data: d,
    }));
  }
}

export function SeriesPreview(p: {
  proxy: any;
  type: PreviewDataType;
  width?: number | string;
  height?: number | string;
}) {
  const { proxy, type, width, height } = p;
  if (proxy == null || !('index' in proxy)) {
    return <React.Fragment />;
  }

  const shape: number[] = proxy.shape.toJs({ create_proxies: false });
  const data: any = proxy.to_list().toJs({ create_proxies: false });
  const index: any = proxy.index.tolist().toJs({ create_proxies: false });

  if (type === PreviewDataType.MICRO) {
    return <div>{shape[0]}</div>;
  }

  const rowData = useMemo(
    () =>
      data.map((d: any, idx: number) => ({
        index: index[idx],
        data: d,
      })),
    [data, index]
  );



  const columnDefs: ColDef[] = useMemo(
    () => {
      const firstRow = rowData[0];

      const indexLength = firstRow != null && isArray(firstRow.index) ? firstRow.index.length : 1;
      if (indexLength > 1) {
        return [
          ...range(indexLength).map((_, idx) => ({ field: `index.${idx}`, resizable: true })),
          { field: 'data', resizable: true },
        ]
      } else {
        return [
          { field: 'index', resizable: true },
          { field: 'data', resizable: true },

        ]
      }

    },
    [rowData]
  );

  const style = useMemo(
    () => ({
      width: width ?? 400,
      height: height ?? 400,
    }),
    [width, height]
  );

  return (
    <div className="ag-theme-alpine bg-red-50 z-10" style={style}>
      <AgGrid
        headerHeight={30}
        rowHeight={30}
        columnDefs={columnDefs}
        rowData={rowData}
      />
    </div>
  );
}
