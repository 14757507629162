import React, { useCallback, useMemo } from 'react';
import {
  Dialog,
  Classes,
  Checkbox,
  H5,
  Expander,
  Button,
  Icon,
} from '@blueprintjs/core';
import type { TableInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import {
  AppColumnInstance,
  AppTableInstance,
  AppTableState,
} from 'src/utils/table/useAppTable';
import { isEmpty, keyBy } from 'lodash';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

interface P<T extends object> {
  isOpen: boolean;
  table: AppTableInstance<T>;
  onClose: () => void;
}

export default function EditColumnsDialog<T extends object>(p: P<T>) {
  const { columns, visibleColumns, toggleHideColumn, state, setColumnOrder } =
    p.table;
  const { t } = useTranslation();

  const orderedColumns = useMemo(() => {
    const columnsByKey = keyBy(columns, 'id');
    if (isEmpty(state.columnOrder)) {
      return columns;
    } else {
      return state.columnOrder.map((id) => columnsByKey[id]);
    }
  }, [state.columnOrder, columns]);

  const handleSortEnd = useCallback(
    (s: { oldIndex: number; newIndex: number }) => {
      console.log('On Sort end', s);
      const order = orderedColumns.map((d) => d.id);
      setColumnOrder(arrayMove(order, s.oldIndex, s.newIndex));
    },
    [orderedColumns]
  );

  return (
    <Dialog
      isOpen={p.isOpen}
      onClose={p.onClose}
      icon="column-layout"
      title={t('Edit Columns')}
    >
      <div className={Classes.DIALOG_BODY}>
        <H5>
          {t('Visible Columns')} ({visibleColumns.length - 2}){' '}
        </H5>
        <SortableColumns
          axis="y"
          helperClass="sortable-helper"
          items={orderedColumns}
          useDragHandle={true}
          onSortEnd={handleSortEnd}
          onToggleHideColumn={toggleHideColumn}
        />
      </div>
    </Dialog>
  );
}

const SortableColumns = SortableContainer(
  (p: {
    items: AppColumnInstance<any>[];
    onToggleHideColumn: (id: string) => void;
  }) => {
    return (
      <div>
        {p.items.map((col, index) => (
          <SortableColumnItem
            index={index}
            key={col.id}
            value={col}
            onToggleColumnState={() => p.onToggleHideColumn(col.id)}
          />
        ))}
      </div>
    );
  }
);
const DragHandle = SortableHandle(() => (
  <Icon className="sortable-handle" icon="menu" />
));

const SortableColumnItem = SortableElement(
  (props: {
    value: AppColumnInstance<any>;
    onToggleColumnState: () => void;
  }) => {
    const { value: column, onToggleColumnState } = props;

    return (
      <Checkbox
        key={column.id}
        label={column.Header as string}
        checked={column.isVisible}
        className="flex"
        onChange={onToggleColumnState}
      >
        <Expander />
        <DragHandle />
      </Checkbox>
    );
  }
);
