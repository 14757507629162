import React from 'react';
import WorkflowDesigner from 'src/apps/kgest/pages/workflow/WorkflowDesigner';
import { useSize } from 'src/utils/size';

export default function SurveyWorklfow(p: { surveyId: string }) {
  const [size, ref] = useSize();
  return (
    <div ref={ref} className="flex-grow overflow-hidden">
      <WorkflowDesigner id={p.surveyId} size={size} />
    </div>
  );
}
