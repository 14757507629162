export default {
  __schema: {
    queryType: { name: 'Query' },
    mutationType: { name: 'Mutation' },
    subscriptionType: { name: 'Subscription' },
    types: [
      {
        kind: 'SCALAR',
        name: 'JSON',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Query',
        fields: [
          {
            name: 'variable',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Variable', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'variables',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Variables', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getVariableValues',
            args: [
              {
                name: 'variableId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'options',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'VariableValuesByOptions',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'VariableValues', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getVariableValuesByRef',
            args: [
              {
                name: 'ref',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VariableRefInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
              {
                name: 'options',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'VariableValuesByOptions',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'VariableValues', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getVariableValueSlicesByRef',
            args: [
              {
                name: 'ref',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VariableRefInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
              {
                name: 'options',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'VariableValueSlicesByRefOptions',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'VariableValueSlices', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getVariableAgedValues',
            args: [
              {
                name: 'variableId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'atDate',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'options',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'VariableValuesByOptions',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'AgedVariableValues', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'users',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Users', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'user',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'User', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'displayName',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'notifications',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notifications', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unreadNotifications',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notifications', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getNotificationById',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notification', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'people',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'People', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'person',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Person', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'personByFiscalCode',
            args: [
              {
                name: 'fiscalCode',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'People', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'notes',
            args: [
              {
                name: 'uri',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notes', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'companies',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Companies', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'companyByVatNumber',
            args: [
              {
                name: 'vatNumber',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Companies', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'company',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Company', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emails',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Emails', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailTemplates',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'EmailTemplates', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailTemplate',
            args: [
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'EmailTemplate', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailAccounts',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'EmailAccounts', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificateRenewalNotifications',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'CertificateRenewalNotifications',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'previewCertificateRenewals',
            args: [
              {
                name: 'date',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'CertificateRenewalsNotification',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'ticket',
            args: [
              {
                name: 'name',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'lastName',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tickets',
            args: [],
            type: { kind: 'OBJECT', name: 'Tickets', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'partners',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Partners', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'partner',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Partner', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vats',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Vat', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificates',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Certificates', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificate',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Certificate', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'questions',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Questions', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'surveys',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Surveys', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'survey',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Survey', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'surveyData',
            args: [
              {
                name: 'surveyId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'SurveyData', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uploadTypes',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'UploadTypes', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uploads',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Uploads', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'upload',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Upload', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uploadsByResource',
            args: [
              {
                name: 'resource',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Uploads', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uploadsByOwner',
            args: [
              {
                name: 'owner',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Uploads', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'previewPdfTemplate',
            args: [
              {
                name: 'url',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'template',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'PdfRendered', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'competences',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Competences', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'competence',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Competence', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'testHTTP',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'helloWorld',
            args: [
              {
                name: 'pippo',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'getValues',
            args: [
              {
                name: 'key',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tests',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'test',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'telegramConversations',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'TelegramConversations',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'telegramConversation',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'TelegramConversation',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'telegramConversationMessages',
            args: [
              {
                name: 'conversationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'TelegramConversationMessages',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'quotations',
            args: [
              {
                name: 'filters',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'orderBy',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'queryString',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Quotations', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'quotationRevisions',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Quotations', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'quotation',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Quotation', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'SCALAR',
        name: 'String',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'SCALAR',
        name: 'Int',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Mutation',
        fields: [
          {
            name: 'defineVariable',
            args: [
              {
                name: 'variable',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VariableInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setVariableValue',
            args: [
              {
                name: 'value',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VariableValueInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'registerUser',
            args: [
              {
                name: 'email',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'password',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'firstName',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'lastName',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'confirmUser',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'code',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'forgotPassword',
            args: [
              {
                name: 'email',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'completeForgotPassword',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'code',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'password',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'loginUser',
            args: [
              {
                name: 'email',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'password',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'LoginResponse', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'resendUserConfirmation',
            args: [
              {
                name: 'email',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateUserDetails',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'firstName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'lastName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'enableUser',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'disableUser',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'forceConfirmUser',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markNotificationAsRead',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markAllNotificationsAsRead',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendNotification',
            args: [
              {
                name: 'title',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'icon',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addNote',
            args: [
              {
                name: 'note',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'NoteInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateNote',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'note',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'NoteInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addPerson',
            args: [
              {
                name: 'id',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'person',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'PersonBasicInfo',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updatePerson',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'person',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'PersonBasicInfo',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removePerson',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addCompany',
            args: [
              {
                name: 'id',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'company',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'CompanyBasicInfo',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateCompany',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'company',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'CompanyBasicInfo',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeCompany',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addCompanyEmployee',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'personId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeCompanyEmployee',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'personId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addEmailAddress',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'emailAddress',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateEmailAddress',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'emailAddress',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeEmailAddress',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addLocation',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'location',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'LocationInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateLocation',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'location',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'LocationInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeLocation',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addPhoneNumber',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'countryCode',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updatePhoneNumber',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'countryCode',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removePhoneNumber',
            args: [
              {
                name: 'parentId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'parentKind',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setEmailTemplate',
            args: [
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'subject',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'body',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
              {
                name: 'attachments',
                type: {
                  kind: 'LIST',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'UploadedFile',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setEmailAccount',
            args: [
              {
                name: 'address',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'account',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'EmailAccountInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendEmail',
            args: [
              {
                name: 'email',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'EmailInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'resendCertificateRenewalNotification',
            args: [
              {
                name: 'certificateNumber',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'expirationDate',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'type',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'ENUM',
                    name: 'CertificateRenewalNotificationType',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendCertificateRenewal',
            args: [
              {
                name: 'date',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendSMS',
            args: [
              {
                name: 'sms',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'SMSInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendAutomotiveMessage',
            args: [
              {
                name: 'sms',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'SMSInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
              {
                name: 'firstName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'lastName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'car',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'token',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createTicket',
            args: [
              {
                name: 'title',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'body',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'assignTicket',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'userId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'delegateTicket',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'userId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'processTicket',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markTaskAsNeedAttention',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'message',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markAsResolved',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'rejectTicket',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'replyToTaskQuestion',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'message',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addPartner',
            args: [
              {
                name: 'partner',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'PartnerInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setPartnerActivityCost',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'competence',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'activityId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'price',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'ActivityPriceInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removePartnerActivityCost',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'competence',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'activityId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removePartner',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addVat',
            args: [
              {
                name: 'vat',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VatInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateVat',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'vat',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'VatInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeVat',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'issueCertificate',
            args: [
              {
                name: 'certificate',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'CertificateInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markPaymentReceived',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'paymentDate',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unmarkPaymentReceived',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markDocumentationReceived',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'date',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unmarkDocumentationReceived',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'suspendCertificate',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'restoreCertificate',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'revokeCertificate',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'cancelCertificate',
            args: [
              {
                name: 'number',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createQuestion',
            args: [
              {
                name: 'question',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'QuestionInput',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'answerQuestion',
            args: [
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'contextId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'answer',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addQuestionOptions',
            args: [
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'options',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'LIST',
                    name: null,
                    ofType: {
                      kind: 'INPUT_OBJECT',
                      name: 'QuestionOptionsInput',
                      ofType: null,
                    },
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeQuestionOption',
            args: [
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'optionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createSurvey',
            args: [
              {
                name: 'survey',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'SurveyInput',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addSurveyQuestions',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'questions',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'LIST',
                    name: null,
                    ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeSurveyQuestion',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'moveSurveyQuestion',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'after',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'shareSurvey',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'medium',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'SurveyShareMedium',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addUploadType',
            args: [
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'renameUploadType',
            args: [
              {
                name: 'prevName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeUploadType',
            args: [
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'upload',
            args: [
              {
                name: 'params',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'UploadInput',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'reupload',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'uri',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setUploadType',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'type',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'renameUpload',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setUploadDate',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'date',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addUploadTag',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'tag',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeUploadTag',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'tag',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addUploadResource',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'resource',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeUploadResource',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'resource',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeUpload',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addCompetence',
            args: [
              {
                name: 'competence',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'CompetenceInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateCompetence',
            args: [
              {
                name: 'competence',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'CompetenceInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeCompetence',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setCompetenceActivityPrice',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'activityId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'price',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'ActivityPriceInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sayHello',
            args: [
              {
                name: 'firstName',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'startTelegramSurvey',
            args: [
              {
                name: 'surveyId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'chatId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendTelegramQuestion',
            args: [
              {
                name: 'questionId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'chatId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendTelegramMessage',
            args: [
              {
                name: 'message',
                type: {
                  kind: 'INPUT_OBJECT',
                  name: 'TelegramMessageInput',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'broadcastTelegramMessage',
            args: [
              {
                name: 'message',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'TelegramBroadcastMessageInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createQuotation',
            args: [
              {
                name: 'quotation',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'QuotationInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'addQuotationRecord',
            args: [
              {
                name: 'quotationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'record',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'AddQuotationRecordInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updateQuotationRecord',
            args: [
              {
                name: 'quotationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'record',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'UpdateQuotationRecordInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'removeQuotationRecord',
            args: [
              {
                name: 'quotationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'moveQuotationRecord',
            args: [
              {
                name: 'quotationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'after',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationClient',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'client',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'QuotationActorInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationTitle',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'title',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationDate',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'date',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationExpirationDate',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'expirationDate',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationNote',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'note',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationDiscount',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'discount',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'QuotationDiscountInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unsetQuotationDiscount',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'setQuotationCustomField',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'type',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'ENUM',
                    name: 'QuotationCustomFieldType',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
              {
                name: 'value',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unsetQuotationCustomField',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'generateQuotationPdf',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'revision',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'logo',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'signature',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sendEmailQuotation',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
              {
                name: 'data',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'INPUT_OBJECT',
                    name: 'QuotationSendEmailDataInput',
                    ofType: null,
                  },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'finalizeQuotation',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createQuotationRevision',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'SCALAR',
        name: 'Boolean',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Variables',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Variable', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'AgedVariableValues',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'AgedVariableValue',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'VariableValues',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'VariableValue', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'VariableValueSlices',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'VariableValueSlice',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'AgedVariableValue',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'variableId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'probability',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'value',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'JSON', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'refs',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'VariableRef', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'SCALAR',
        name: 'Float',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'VariableValue',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'variableId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'value',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'JSON', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'refs',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'VariableRef', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'VariableValueSlice',
        fields: [
          {
            name: 'slice',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'JSON', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'values',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: { kind: 'OBJECT', name: 'VariableValue', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Variable',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'domain',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INTERFACE',
                name: 'VariableDomain',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'ageing',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INTERFACE',
                name: 'AgeingFunction',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'VariableRef',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'VariableDomainKind',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'NATURAL', isDeprecated: false, deprecationReason: null },
          { name: 'REAL', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'AgeingFunctionKind',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'NEVER', isDeprecated: false, deprecationReason: null },
          { name: 'LINEAR', isDeprecated: false, deprecationReason: null },
          { name: 'CURVE', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INTERFACE',
        name: 'VariableDomain',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'VariableDomainKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: [
          { kind: 'OBJECT', name: 'NaturalVariableDomain', ofType: null },
          { kind: 'OBJECT', name: 'RealVariableDomain', ofType: null },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'NaturalVariableDomain',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'VariableDomainKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'range',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tick',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [
          { kind: 'INTERFACE', name: 'VariableDomain', ofType: null },
        ],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'RealVariableDomain',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'VariableDomainKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'range',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'precision',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [
          { kind: 'INTERFACE', name: 'VariableDomain', ofType: null },
        ],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INTERFACE',
        name: 'AgeingFunction',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'AgeingFunctionKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: [
          { kind: 'OBJECT', name: 'NeverAgeingFunction', ofType: null },
          { kind: 'OBJECT', name: 'LinearAgeingFunction', ofType: null },
          { kind: 'OBJECT', name: 'CurveAgeingFunction', ofType: null },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'NeverAgeingFunction',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'AgeingFunctionKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [
          { kind: 'INTERFACE', name: 'AgeingFunction', ofType: null },
        ],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'LinearAgeingFunction',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'AgeingFunctionKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'a',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [
          { kind: 'INTERFACE', name: 'AgeingFunction', ofType: null },
        ],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CurveAgeingFunction',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'AgeingFunctionKind',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'a',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [
          { kind: 'INTERFACE', name: 'AgeingFunction', ofType: null },
        ],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableInput',
        fields: null,
        inputFields: [
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'domain',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'VariableDomainInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'ageing',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'AgeingFunctionInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableDomainInput',
        fields: null,
        inputFields: [
          {
            name: 'kind',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'VariableDomainKind',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'range',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'tick',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'precision',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'AgeingFunctionTimeUnit',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'MINUTES', isDeprecated: false, deprecationReason: null },
          { name: 'HOURS', isDeprecated: false, deprecationReason: null },
          { name: 'DAYS', isDeprecated: false, deprecationReason: null },
          { name: 'WEEKS', isDeprecated: false, deprecationReason: null },
          { name: 'MONTHS', isDeprecated: false, deprecationReason: null },
          { name: 'YEARS', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'AgeingFunctionInput',
        fields: null,
        inputFields: [
          {
            name: 'kind',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'AgeingFunctionKind',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'a',
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            defaultValue: null,
          },
          {
            name: 'z',
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            defaultValue: null,
          },
          {
            name: 'timeUnit',
            type: {
              kind: 'ENUM',
              name: 'AgeingFunctionTimeUnit',
              ofType: null,
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableRefInput',
        fields: null,
        inputFields: [
          {
            name: 'type',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableValueInput',
        fields: null,
        inputFields: [
          {
            name: 'variableId',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'date',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'refs',
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'VariableRefInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'value',
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableValuesByOptions',
        fields: null,
        inputFields: [
          {
            name: 'limit',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'cursor',
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VariableValueSlicesByRefOptions',
        fields: null,
        inputFields: [
          {
            name: 'limit',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'cursor',
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            defaultValue: null,
          },
          {
            name: 'slices',
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Users',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'User', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'UserStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'IDLE', isDeprecated: false, deprecationReason: null },
          {
            name: 'PENDING_CONFIRMATION',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'REGISTERED', isDeprecated: false, deprecationReason: null },
          { name: 'DISABLED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'User',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'email',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'UserStatus', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'firstName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lastName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'LoginResponse',
        fields: [
          {
            name: 'loggedIn',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'idToken',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'userId',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'displayName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'UploadedFile',
        fields: null,
        inputFields: [
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'url',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Subscription',
        fields: [
          {
            name: 'notifications',
            args: [
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notifications', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unreadNotifications',
            args: [
              {
                name: 'cursor',
                type: { kind: 'SCALAR', name: 'JSON', ofType: null },
                defaultValue: null,
              },
              {
                name: 'limit',
                type: { kind: 'SCALAR', name: 'Int', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Notifications', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tickets',
            args: [],
            type: { kind: 'OBJECT', name: 'Tickets', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'subscribeForEvents',
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: { kind: 'OBJECT', name: 'Message', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'helloWorld',
            args: [
              {
                name: 'pippo',
                type: { kind: 'SCALAR', name: 'String', ofType: null },
                defaultValue: null,
              },
            ],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'telegramConversationMessages',
            args: [
              {
                name: 'conversationId',
                type: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'SCALAR', name: 'String', ofType: null },
                },
                defaultValue: null,
              },
            ],
            type: {
              kind: 'OBJECT',
              name: 'TelegramConversationMessages',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Notification',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'title',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sentAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'icon',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'NotificationState', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sentTo',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'NotificationState',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'SENT', isDeprecated: false, deprecationReason: null },
          { name: 'READ', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Notifications',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Notification', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'CoordinatesInput',
        fields: null,
        inputFields: [
          {
            name: 'lat',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'lon',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'LocationInput',
        fields: null,
        inputFields: [
          {
            name: 'place_id',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'countryCode',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'coordinates',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'CoordinatesInput',
              ofType: null,
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Location',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'location',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'BasicLocation', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'BasicLocation',
        fields: [
          {
            name: 'place_id',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'countryCode',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'coordinates',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Coordinates', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Coordinates',
        fields: [
          {
            name: 'lat',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lon',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'NoteInput',
        fields: null,
        inputFields: [
          {
            name: 'uri',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'body',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'CompanyBasicInfo',
        fields: null,
        inputFields: [
          {
            name: 'name',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'vatId',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'fiscalCode',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'reaNumber',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'website',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'bankAccounts',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'PersonBasicInfo',
        fields: null,
        inputFields: [
          {
            name: 'firstName',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'lastName',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'fiscalCode',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'gender',
            type: { kind: 'ENUM', name: 'Gender', ofType: null },
            defaultValue: null,
          },
          {
            name: 'birthDate',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'bankAccounts',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'PageInfo',
        fields: [
          {
            name: 'endCursor',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'total',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Notes',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Note', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Note',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uri',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'writtenAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'writtenBy',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updatedAt',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updatedBy',
            args: [],
            type: { kind: 'OBJECT', name: 'Ref', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Companies',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Company', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'People',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Person', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Employer',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Ref',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Employee',
        fields: [
          {
            name: 'id',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'firstName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lastName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Company',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vatId',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'fiscalCode',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'reaNumber',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'website',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'bankAccounts',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailAddresses',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'EmailAddress', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'phoneNumbers',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'PhoneNumber', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'employees',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Employee', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'locations',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Location', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificates',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Person',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'firstName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lastName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'gender',
            args: [],
            type: { kind: 'ENUM', name: 'Gender', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'birthDate',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'fiscalCode',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'bankAccounts',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'locations',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Location', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailAddresses',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'EmailAddress', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'phoneNumbers',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'PhoneNumber', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'employer',
            args: [],
            type: { kind: 'OBJECT', name: 'Employer', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificates',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'Gender',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'MALE', isDeprecated: false, deprecationReason: null },
          { name: 'FEMALE', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailAddress',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'emailAddress',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'PhoneNumber',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'number',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'countryCode',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Emails',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Email', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'EmailStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'SENDING', isDeprecated: false, deprecationReason: null },
          { name: 'SENT', isDeprecated: false, deprecationReason: null },
          { name: 'FAILED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Email',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'from',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'to',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'subject',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'text',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'html',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sentAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'errorMessage',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: { kind: 'ENUM', name: 'EmailStatus', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailTemplates',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'EmailTemplate', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailTemplate',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'subject',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'attachments',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'EmailTemplateAttachment',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailAccounts',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'EmailAccount', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailAccount',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'address',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'account',
            args: [],
            type: { kind: 'OBJECT', name: 'EmailAccountInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailAccountInfo',
        fields: [
          {
            name: 'apiKey',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'host',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'port',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'secure',
            args: [],
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'user',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pass',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'EmailTemplateAttachment',
        fields: [
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'url',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'EmailAccountInput',
        fields: null,
        inputFields: [
          {
            name: 'apiKey',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'host',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'port',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'secure',
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            defaultValue: null,
          },
          {
            name: 'user',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'pass',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'EmailInput',
        fields: null,
        inputFields: [
          {
            name: 'from',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'to',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'cc',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'bcc',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'subject',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'text',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'templateId',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'templateInput',
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            defaultValue: null,
          },
          {
            name: 'attachments',
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'UploadedFile',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CertificateRenewalNotifications',
        fields: [
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'items',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'CertificateRenewalNotification',
                  ofType: null,
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CertificateRenewalsNotification',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'renewals',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'CertificateRenewalNotification',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CertificateRenewalNotification',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'email',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificateType',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certificateNumber',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'expirationDate',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'certifyUrl',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'templateId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'shouldCheckEmail',
            args: [],
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sentAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'CertificateRenewalNotificationType',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'CertificateRenewalNotificationType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          {
            name: 'RENEWAL_REQUEST_SENT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'FIRST_RENEWAL_REMINDER_SENT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'SECOND_RENEWAL_REMINDER_SENT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'FIRST_EXPIRED_REMINDER_SENT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'SECOND_EXPIRED_REMINDER_SENT',
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'SMSType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'BASIC', isDeprecated: false, deprecationReason: null },
          { name: 'PLUS', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'SMSInput',
        fields: null,
        inputFields: [
          {
            name: 'sender',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'body',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'recipient',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'type',
            type: { kind: 'ENUM', name: 'SMSType', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'TicketStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'CREATED', isDeprecated: false, deprecationReason: null },
          { name: 'ASSIGNED', isDeprecated: false, deprecationReason: null },
          { name: 'DELEGATED', isDeprecated: false, deprecationReason: null },
          { name: 'PROCESSING', isDeprecated: false, deprecationReason: null },
          {
            name: 'NEEDS_ATTENTION',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'RESOLVED', isDeprecated: false, deprecationReason: null },
          { name: 'REJECTED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Ticket',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'title',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'TicketStatus', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createdBy',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'createdAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'assignedTo',
            args: [],
            type: { kind: 'OBJECT', name: 'Ref', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'delegatedTo',
            args: [],
            type: { kind: 'OBJECT', name: 'Ref', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'messages',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'TicketMessage', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TicketMessage',
        fields: [
          {
            name: 'sentAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'sentBy',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Tickets',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ticket', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Partners',
        fields: [
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Partner', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Certificates',
        fields: [
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Certificate', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'CertificateType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'PERSON', isDeprecated: false, deprecationReason: null },
          { name: 'COMPANY', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'CertificateStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'ACTIVE', isDeprecated: false, deprecationReason: null },
          { name: 'SUSPENDED', isDeprecated: false, deprecationReason: null },
          { name: 'REVOKED', isDeprecated: false, deprecationReason: null },
          { name: 'CANCELLED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'CertificateInput',
        fields: null,
        inputFields: [
          {
            name: 'number',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'owner',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'competence',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'partner',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'issueDate',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Certificate',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'competence',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'CertificateType', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'owner',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Ref', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'number',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'issueDate',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'expirationDate',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'CertificateStatus', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'partner',
            args: [],
            type: { kind: 'OBJECT', name: 'Ref', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'renewals',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'CertificateRenewals',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'CertificateRenewalStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'IDLE', isDeprecated: false, deprecationReason: null },
          { name: 'COMPLETED', isDeprecated: false, deprecationReason: null },
          { name: 'EXPIRED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CertificateRenewals',
        fields: [
          {
            name: 'past',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'CertificateRenewal',
                  ofType: null,
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'current',
            args: [],
            type: { kind: 'OBJECT', name: 'CertificateRenewal', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'future',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'CertificateRenewal',
                  ofType: null,
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CertificateRenewal',
        fields: [
          {
            name: 'date',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'CertificateRenewalStatus',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'paymentDate',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'renewalDate',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'isPaymentReceived',
            args: [],
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'isDocumentReceived',
            args: [],
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'cost',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Price', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'adminCost',
            args: [],
            type: { kind: 'OBJECT', name: 'Price', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'VatInput',
        fields: null,
        inputFields: [
          {
            name: 'label',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'value',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Vat',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'label',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'value',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Partner',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'competences',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'PartnerCompetence',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'PartnerCompetence',
        fields: [
          {
            name: 'competence',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'activites',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'PartnerActivity', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'PartnerActivity',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'price',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'ActivityPrice', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'ActivityPrice',
        fields: [
          {
            name: 'cost',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Price', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'adminCost',
            args: [],
            type: { kind: 'OBJECT', name: 'Price', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Price',
        fields: [
          {
            name: 'amount',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Int', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vatId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'PriceInput',
        fields: null,
        inputFields: [
          {
            name: 'vatId',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'amount',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'ActivityPriceInput',
        fields: null,
        inputFields: [
          {
            name: 'cost',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'PriceInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'adminCost',
            type: { kind: 'INPUT_OBJECT', name: 'PriceInput', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'PartnerInput',
        fields: null,
        inputFields: [
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'SurveyShareMedium',
        fields: null,
        inputFields: [
          {
            name: 'chatId',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'SurveyInput',
        fields: null,
        inputFields: [
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'questions',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuestionOptionsInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'body',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuestionInput',
        fields: null,
        inputFields: [
          {
            name: 'body',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'tags',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'options',
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'QuestionOptionsInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'SurveyData',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'SurveySessionData',
                  ofType: null,
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'SurveySessionData',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'startedAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'endedAt',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'data',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'JSON', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Surveys',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Survey', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Questions',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Question', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Survey',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'questions',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Question', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Question',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tags',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'options',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'QuestionOption', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'QuestionOption',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'body',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'PdfRendered',
        fields: [
          {
            name: 'url',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Uploads',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: { kind: 'OBJECT', name: 'Upload', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'UploadTypes',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: { kind: 'OBJECT', name: 'UploadType', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Upload',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'owner',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'uri',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'tags',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'resources',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'UploadType',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'deletedAt',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'UploadInput',
        fields: null,
        inputFields: [
          {
            name: 'uri',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'type',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'name',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'date',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'tags',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'resources',
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'CompetenceInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'name',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'numRenewals',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'type',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'CompetenceType', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'CompetenceActivity',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'price',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'ActivityPrice', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'CompetenceType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'PERSON', isDeprecated: false, deprecationReason: null },
          { name: 'COMPANY', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Competences',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: { kind: 'OBJECT', name: 'Competence', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Competence',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'numRenewals',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'CompetenceType', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'activities',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'OBJECT',
                  name: 'CompetenceActivity',
                  ofType: null,
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Message',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'stream_name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'position',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Int', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'global_position',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Int', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'data',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'metadata',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'time',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramPhoto',
        fields: [
          {
            name: 'uri',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramMessageContent',
        fields: [
          {
            name: 'text',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'photo',
            args: [],
            type: { kind: 'OBJECT', name: 'TelegramPhoto', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'extra',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramConversations',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'TelegramConversation',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramConversationMessages',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'TelegramMessage', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'TelegramMessageDirection',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'SENT', isDeprecated: false, deprecationReason: null },
          { name: 'RECEIVED', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramMessage',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'direction',
            args: [],
            type: {
              kind: 'ENUM',
              name: 'TelegramMessageDirection',
              ofType: null,
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'chatId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'message',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'OBJECT',
                name: 'TelegramMessageContent',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramBroadcastMessageInput',
        fields: null,
        inputFields: [
          {
            name: 'messageIdentifier',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'TelegramMessageContentInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'TelegramParseMode',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'Markdown', isDeprecated: false, deprecationReason: null },
          { name: 'MarkdownV2', isDeprecated: false, deprecationReason: null },
          { name: 'HTML', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramMessageButton',
        fields: null,
        inputFields: [
          {
            name: 'text',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'url',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'data',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramMessageMarkup',
        fields: null,
        inputFields: [
          {
            name: 'inline',
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            defaultValue: null,
          },
          {
            name: 'numberOfRows',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'buttons',
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'TelegramMessageButton',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramMessageExtra',
        fields: null,
        inputFields: [
          {
            name: 'parseMode',
            type: { kind: 'ENUM', name: 'TelegramParseMode', ofType: null },
            defaultValue: null,
          },
          {
            name: 'silent',
            type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            defaultValue: null,
          },
          {
            name: 'caption',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'markup',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'TelegramMessageMarkup',
              ofType: null,
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramPhotoInput',
        fields: null,
        inputFields: [
          {
            name: 'uri',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramMessageContentInput',
        fields: null,
        inputFields: [
          {
            name: 'text',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'photo',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'TelegramPhotoInput',
              ofType: null,
            },
            defaultValue: null,
          },
          {
            name: 'extra',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'TelegramMessageExtra',
              ofType: null,
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'TelegramMessageInput',
        fields: null,
        inputFields: [
          {
            name: 'messageIdentifier',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'chatId',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'TelegramMessageContentInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'TelegramConversation',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'startedAt',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'parentId',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'chatId',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'profilePhotoUrl',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'firstName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lastName',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'username',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'updatedAt',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'numberOfBees',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Int', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'lastMessage',
            args: [],
            type: { kind: 'OBJECT', name: 'TelegramMessage', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Quotations',
        fields: [
          {
            name: 'items',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'Quotation', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pageInfo',
            args: [],
            type: { kind: 'OBJECT', name: 'PageInfo', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'Quotation',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'year',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'progressiveNumber',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'revision',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Int', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'status',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'QuotationStatus', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'title',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vendor',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'QuotationActor', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'client',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: 'QuotationActor', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'date',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'note',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'expirationDate',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'records',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: { kind: 'OBJECT', name: 'QuotationRecord', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'customFields',
            args: [],
            type: { kind: 'SCALAR', name: 'JSON', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'discount',
            args: [],
            type: { kind: 'OBJECT', name: 'QuotationDiscount', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'total',
            args: [],
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vatTotal',
            args: [],
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'netTotal',
            args: [],
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'pdf',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'QuotationStatus',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'CREATING', isDeprecated: false, deprecationReason: null },
          { name: 'FINALIZED', isDeprecated: false, deprecationReason: null },
          { name: 'SENT', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'QuotationActor',
        fields: [
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'QuotationActorType',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'QuotationRecord',
        fields: [
          {
            name: 'id',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'title',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'costPrice',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'markup',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'vatId',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'unit',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'QuotationUnit', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'quantity',
            args: [],
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: 'QuotationDiscount',
        fields: [
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'QuotationDiscountType',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'value',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'QuotationDiscountType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'ABS', isDeprecated: false, deprecationReason: null },
          { name: 'PERCENTAGE', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'QuotationUnit',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'HOUR', isDeprecated: false, deprecationReason: null },
          { name: 'UNIT', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'QuotationCustomFieldType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'boolean', isDeprecated: false, deprecationReason: null },
          { name: 'string', isDeprecated: false, deprecationReason: null },
          { name: 'number', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuotationInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'title',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'vendor',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'INPUT_OBJECT',
                name: 'QuotationActorInput',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'client',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'QuotationActorInput',
              ofType: null,
            },
            defaultValue: null,
          },
          {
            name: 'date',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'expirationDate',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'discount',
            type: {
              kind: 'INPUT_OBJECT',
              name: 'QuotationDiscountInput',
              ofType: null,
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuotationActorInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'type',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'QuotationActorType',
                ofType: null,
              },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: 'QuotationActorType',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'person', isDeprecated: false, deprecationReason: null },
          { name: 'company', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'AddQuotationRecordInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'title',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'costPrice',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'markup',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'vatId',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'unit',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: 'QuotationUnit', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'quantity',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'UpdateQuotationRecordInput',
        fields: null,
        inputFields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'title',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'description',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'costPrice',
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            defaultValue: null,
          },
          {
            name: 'markup',
            type: { kind: 'SCALAR', name: 'Float', ofType: null },
            defaultValue: null,
          },
          {
            name: 'vatId',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'unit',
            type: { kind: 'ENUM', name: 'QuotationUnit', ofType: null },
            defaultValue: null,
          },
          {
            name: 'quantity',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuotationDiscountInput',
        fields: null,
        inputFields: [
          {
            name: 'type',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'ENUM',
                name: 'QuotationDiscountType',
                ofType: null,
              },
            },
            defaultValue: null,
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Float', ofType: null },
            },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'INPUT_OBJECT',
        name: 'QuotationSendEmailDataInput',
        fields: null,
        inputFields: [
          {
            name: 'revision',
            type: { kind: 'SCALAR', name: 'Int', ofType: null },
            defaultValue: null,
          },
          {
            name: 'from',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'to',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'cc',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
          {
            name: 'subject',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'html',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            defaultValue: null,
          },
          {
            name: 'filename',
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            defaultValue: null,
          },
        ],
        interfaces: null,
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__Schema',
        fields: [
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'types',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'queryType',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'mutationType',
            args: [],
            type: { kind: 'OBJECT', name: '__Type', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'subscriptionType',
            args: [],
            type: { kind: 'OBJECT', name: '__Type', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'directives',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: { kind: 'OBJECT', name: '__Directive', ofType: null },
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__Type',
        fields: [
          {
            name: 'kind',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'ENUM', name: '__TypeKind', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'name',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'fields',
            args: [
              {
                name: 'includeDeprecated',
                type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
                defaultValue: 'false',
              },
            ],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'NON_NULL',
                name: null,
                ofType: { kind: 'OBJECT', name: '__Field', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'interfaces',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'NON_NULL',
                name: null,
                ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'possibleTypes',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'NON_NULL',
                name: null,
                ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'enumValues',
            args: [
              {
                name: 'includeDeprecated',
                type: { kind: 'SCALAR', name: 'Boolean', ofType: null },
                defaultValue: 'false',
              },
            ],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'NON_NULL',
                name: null,
                ofType: { kind: 'OBJECT', name: '__EnumValue', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'inputFields',
            args: [],
            type: {
              kind: 'LIST',
              name: null,
              ofType: {
                kind: 'NON_NULL',
                name: null,
                ofType: { kind: 'OBJECT', name: '__InputValue', ofType: null },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'ofType',
            args: [],
            type: { kind: 'OBJECT', name: '__Type', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: '__TypeKind',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'SCALAR', isDeprecated: false, deprecationReason: null },
          { name: 'OBJECT', isDeprecated: false, deprecationReason: null },
          { name: 'INTERFACE', isDeprecated: false, deprecationReason: null },
          { name: 'UNION', isDeprecated: false, deprecationReason: null },
          { name: 'ENUM', isDeprecated: false, deprecationReason: null },
          {
            name: 'INPUT_OBJECT',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'LIST', isDeprecated: false, deprecationReason: null },
          { name: 'NON_NULL', isDeprecated: false, deprecationReason: null },
        ],
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__Field',
        fields: [
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'args',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__InputValue',
                    ofType: null,
                  },
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'isDeprecated',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'deprecationReason',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__InputValue',
        fields: [
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'type',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'OBJECT', name: '__Type', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'defaultValue',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__EnumValue',
        fields: [
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'isDeprecated',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'deprecationReason',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'OBJECT',
        name: '__Directive',
        fields: [
          {
            name: 'name',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'String', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'description',
            args: [],
            type: { kind: 'SCALAR', name: 'String', ofType: null },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'isRepeatable',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: { kind: 'SCALAR', name: 'Boolean', ofType: null },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'locations',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'ENUM',
                    name: '__DirectiveLocation',
                    ofType: null,
                  },
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'args',
            args: [],
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'LIST',
                name: null,
                ofType: {
                  kind: 'NON_NULL',
                  name: null,
                  ofType: {
                    kind: 'OBJECT',
                    name: '__InputValue',
                    ofType: null,
                  },
                },
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: null,
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
      {
        kind: 'ENUM',
        name: '__DirectiveLocation',
        fields: null,
        inputFields: null,
        interfaces: null,
        enumValues: [
          { name: 'QUERY', isDeprecated: false, deprecationReason: null },
          { name: 'MUTATION', isDeprecated: false, deprecationReason: null },
          {
            name: 'SUBSCRIPTION',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'FIELD', isDeprecated: false, deprecationReason: null },
          {
            name: 'FRAGMENT_DEFINITION',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'FRAGMENT_SPREAD',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'INLINE_FRAGMENT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'VARIABLE_DEFINITION',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'SCHEMA', isDeprecated: false, deprecationReason: null },
          { name: 'SCALAR', isDeprecated: false, deprecationReason: null },
          { name: 'OBJECT', isDeprecated: false, deprecationReason: null },
          {
            name: 'FIELD_DEFINITION',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'ARGUMENT_DEFINITION',
            isDeprecated: false,
            deprecationReason: null,
          },
          { name: 'INTERFACE', isDeprecated: false, deprecationReason: null },
          { name: 'UNION', isDeprecated: false, deprecationReason: null },
          { name: 'ENUM', isDeprecated: false, deprecationReason: null },
          { name: 'ENUM_VALUE', isDeprecated: false, deprecationReason: null },
          {
            name: 'INPUT_OBJECT',
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            name: 'INPUT_FIELD_DEFINITION',
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        possibleTypes: null,
      },
    ],
    directives: [],
  },
};
