import React, { useState, useCallback } from 'react';
import css from 'csz';
import { Collapse, Icon, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

const className = css`/styles/accordion.css`;

export function Accordion(p: {
  title: string | JSX.Element;
  className?: string;
  kind?: 'simple' | 'section';
  initiallyOpened?: boolean;
  disabled?: boolean;
  rightElement?: JSX.Element;
  children: JSX.Element | JSX.Element[];
}) {
  const kind = p.kind ?? 'simple';
  const [isOpen, setOpen] = useState(p.initiallyOpened ?? false);
  const toggleOpen = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (p.disabled) {
        return;
      }
      setOpen((prev) => !prev);
    },
    [p.disabled]
  );
  const icon =
    kind === 'simple'
      ? isOpen
        ? 'caret-down'
        : 'caret-right'
      : isOpen
      ? 'chevron-up'
      : 'chevron-down';
  return (
    <div className={classNames(className, kind, { isOpen }, p.className)}>
      <div className="title" onClick={toggleOpen}>
        <Icon intent={Intent.PRIMARY} icon={icon} />
        {p.title}
        <div className="flex-grow" />
        {p.rightElement != null && (
          <div onClick={(e) => e.stopPropagation()}>{p.rightElement}</div>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="content">{p.children}</div>
      </Collapse>
    </div>
  );
}
