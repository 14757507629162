import { Icon } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import {
  BgColor,
  PreviewDataType,
  PreviewFunction,
  ProxyPreviewFunction,
} from '@keix/workflow-types';
import { usePreviewer } from './PreviewContext';

export function BlockPort(p: {
  id: string;
  text: string;
  type: string;
  bg?: BgColor;
  value?: string;
  minimal?: boolean;
  preview?: ProxyPreviewFunction;
  direction: 'in' | 'out';
  onConnect?: () => void;
  onInspectBlock?: () => any;
  onSetParameter?: (value?: string) => void;
}) {
  const hasPreview = p.preview != null;
  const { dataTypes } = useLibraryContext();
  const preview = hasPreview ? (
    <div className="rounded text-xs py-1  whitespace-nowrap cursor-pointer px-2 font-medium shadow left-full bg-gray-50 border text-primary hover:bg-primary hover:text-white hover:fill-white">
      {p.preview?.(PreviewDataType.MICRO)}
    </div>
  ) : null;

  return (
    <div
      className={classNames(
        'px-1 py-1 flex-grow relative text-gray-500 flex items-center text-center hover:bg-gray-100',
        {
          'flex-row-reverse  cursor-pointer': p.direction === 'in',

          'hover:bg-gray-100': p.direction === 'in',
        }
      )}
    >
      <Icon
        iconSize={12}
        className={classNames('text-gray-400  px-1')}
        icon={dataTypes[p.type]?.icon ?? 'circle'}
      />
      {p.minimal !== true && <div className="px-1 flex-grow">{p.text}</div>}
      <Handle
        id={p.id}
        onConnect={p.onConnect}
        type={p.direction === 'in' ? 'source' : 'target'}
        position={p.direction === 'in' ? Position.Left : Position.Right}
      />
      {hasPreview && (
        <div className="absolute transform left-full translate-x-3">
          <React.Suspense fallback={preview}>
            <Popover2
              interactionKind="hover"
              hoverOpenDelay={500}
              position="bottom"
              content={p.preview?.(PreviewDataType.SMALL)}
            >
              {preview}
            </Popover2>
          </React.Suspense>
        </div>
      )}
    </div>
  );
}

function PreviewContent(p: { id: string; onInspectBlock?: () => any }) {
  const value = p?.onInspectBlock?.();
  // ?.split('\n')
  // .map((d) => <p>{d}</p>);
  return <pre className="p-2 bg-gray-50">{value}</pre>;
}
