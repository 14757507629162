import {
  useTable,
  TableOptions,
  useFlexLayout,
  TableInstance,
  TableState,
  Column,
  Hooks,
  ColumnInstance,
  useColumnOrder,
} from 'react-table';
import { useRowClick } from './useRowClick';
import {
  useActionContextMenu,
  OPEN_COLUMNS_DIALOG,
  CLOSE_COLUMNS_DIALOG,
} from './useActionContextMenu';
import { useQuickLook, QUICK_LOOK_ITEM, EXIT_QUICK_LOOK } from './useQuickLook';
import { useRemoteFilters } from './useRemoteFilters';
import { InfiniteLoaderProps, useInfiniteLoader } from './useInfiniteLoader';
import { SendCommandOptions } from '../events';
import { SendCommand } from 'src/components/SendCommand';
import { NonIdealStateProps } from '@blueprintjs/core';

export enum FilterKind {
  TEXT,
  KEYWORD,
  NUMBER,
  DATE,
  AGE,
  LOCATION,
}

export interface PageInfo {
  endCursor: {} | null;
  total: number;
}

export interface FilterValues {
  label?: string;
  value: string | string[];
}

export type AppColumn<T extends object> = Column<T> & {
  isVisible?: boolean;
  filter?: {
    id: string | string[];
    kind: FilterKind;
    values?: FilterValues[];
    defaultOperator?: string;
  };
};

export interface AppColumnFilter {
  id: string | string[];
  kind: FilterKind;
  values?: FilterValues[];
  defaultOperator?: string;
}

export interface AppColumnInstance<T extends object> extends ColumnInstance<T> {
  filter?: AppColumnFilter;
}

export interface AppRef {
  kind: string;
  ref: {
    id: string;
    name?: string;
  };
}

export interface AppTableState<T extends object> extends TableState {
  filters?: { filters: any[]; op: string };
  isColumnsDialogOpen: boolean;
  isFiltersDialogOpen: boolean;
  filteredColumns: string[];
  hiddenColumns: string[];
  columnOrder: string[];
}

export interface AppTableInstance<T extends object>
  extends TableInstance<T>,
    AppTableOptions<T> {
  state: AppTableState<T>;
  columns: AppColumnInstance<T>[];
  showColumnsDialog: () => void;
  closeColumnsDialog: () => void;
  showFiltersDialog: () => void;
  closeFiltersDialog: () => void;
  setColumnOrder: (columnOrder: string[]) => void;
  infiniteLoader: InfiniteLoaderProps;
}

export interface AppTableOptions<T extends object> extends TableOptions<T> {
  columns: AppColumn<T>[];
  pageInfo?: PageInfo;
  routeKey?: string;
  translationKey?: string;
  filters?: {};
  emptyView?: NonIdealStateProps;
  variables?: {};
  quickLookCtx?: {};
  onRowClick?: (item: T, event: React.MouseEvent) => void;
  onAddClick?: () => void;
  onApplyFilters?: (filters: {}) => void;
  onNextCursor?: (cursor: {}) => void;
  showDisclousureIndicator?: boolean;
  contextMenu?: (item: T, instance: AppTableInstance<T>) => JSX.Element;
  removeCommand?: SendCommand;
  labels?: {
    filterButton?: string;
    addButton?: string;
    itemsLabel?: string;
  };
  query?: {
    refetchInterval?: number;
  };
  features?: {
    contextMenu?: boolean;
    emptyView?: boolean;
    disclosureIndicator?: boolean;
    infiniteLoader?: {
      threshold?: number;
      batchSize?: number;
      prefetch?: boolean;
    };
  };
}

function useAppTableInstance<T extends object>(instance: any) {
  const { dispatch } = instance;
  instance.showColumnsDialog = () => dispatch({ type: OPEN_COLUMNS_DIALOG });
  instance.closeColumnsDialog = () => dispatch({ type: CLOSE_COLUMNS_DIALOG });
}

function useExtendTable<T extends object>(hooks: Hooks<T>) {
  hooks.useInstance.push(useAppTableInstance);
}

export function useAppTable<T extends object>(
  options: AppTableOptions<T>
): AppTableInstance<T> {
  const table = useTable<T>(
    {
      ...options,
      initialState: {
        hiddenColumns: options.columns
          .filter((f) => f.isVisible === false)
          .map((d) => d.id),
      },
    },
    useExtendTable,
    useInfiniteLoader,
    useFlexLayout,
    useRowClick,
    useRemoteFilters,
    useColumnOrder,
    useActionContextMenu
  );
  // Why this hack? Because useTable TS definitions does
  // not support generics on TableState, nor modification at runtime using hooks.
  return table as any;
}
