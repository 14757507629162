import React, { useCallback, useState, useMemo } from 'react';
import { MenuItem, ContextMenu, Menu } from '@blueprintjs/core';

export function useContextMenu(items: JSX.Element, onClose?: () => void) {
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const menu = useMemo(() => <Menu>{items}</Menu>, [items]);
  const onContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
        setContextMenuOpen(false);
        onClose != null ? onClose() : null;
      });
      setContextMenuOpen(true);
    },
    [items, onClose]
  );
  return { isContextMenuOpen, onContextMenu };
}
