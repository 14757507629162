import type {
  UseFieldProps,
  FieldInputProps,
  FieldMetaProps,
  FieldHelperProps,
} from 'formik';
import { useField, move, insert } from 'formik';
import { useMemo, useCallback, useEffect } from 'react';
import type { SortableContainerProps } from 'react-sortable-hoc';
import {
  MoveStatus,
  DiffAction,
  DiffActionKind,
  DiffActions,
  AddDiffAction,
} from './diff';
import classNames from 'classnames';
import { pullAt, clone, isEmpty, update, set } from 'lodash';
import { Intent } from '@blueprintjs/core';

export type FieldArrayHelperProps<T> = FieldHelperProps<T[]> & {
  move: (from: number, to: number) => void;
  push: (val: T | T[]) => void;
  replace: (val: T, index: number) => void;
  insert: (val: T | T[], index: number) => void;
  remove: (index: number) => void;
};

export type DiffableField<T extends { id: string }> = DiffActions<T>[];

export function getDiffsFieldName(name: string) {
  return `${name}_diffs`;
}

export function useDiffableFieldArrayHelpers<T extends { id: string }>(
  fieldName: string,
  sortableOptions: Partial<SortableContainerProps> = {}
) {
  const [_, __, helpers] = useDiffableFieldArray<T>(fieldName, sortableOptions);
  return helpers;
}

function getPreviousMovedDiffIndex<T extends { id: string }>(
  diffs: DiffActions<T>[],
  obj: T
) {
  return diffs.findIndex((d) => {
    if (d.kind === DiffActionKind.MOVE) {
      return d.id === obj.id;
    }
    return false;
  });
}

function getPreviousAddedDiffIndex<T extends { id: string }>(
  diffs: DiffActions<T>[],
  obj: T
) {
  return diffs.findIndex((d) => {
    if (d.kind === DiffActionKind.ADD) {
      const ids = 'id' in d.data ? [d.data.id] : d.data.map((t) => t.id);
      return ids.includes(obj.id);
    }
    return false;
  });
}

export function useDiffableFieldArray<T extends { id: string }>(
  fieldName: string,
  sortableOptions: Partial<SortableContainerProps> = {}
): [
  FieldInputProps<T[]>,
  FieldMetaProps<T[]>,
  FieldArrayHelperProps<T>,
  Partial<SortableContainerProps>
] {
  const [diffField, _, diffHelpers] = useField<DiffableField<T>>(
    getDiffsFieldName(fieldName)
  );
  const diffs = isEmpty(diffField.value) ? [] : diffField.value;
  const pushDiff = (diff: DiffActions<T>) =>
    diffHelpers.setValue([...diffs, diff]);

  const [field, meta, _helpers] = useFieldArray<T>(fieldName);

  const helpers = useMemo(
    () => ({
      ..._helpers,
      move: (from: number, to: number) => {
        const fromId = field.value[from].id;
        const toId = to > 0 ? field.value[to - 1].id : null;
        _helpers.move(from, to);
        console.log(from, to);

        // If added right now we can just update the index.
        const previousAddedDiffIndex = getPreviousAddedDiffIndex(diffs, {
          id: fromId,
        });
        if (previousAddedDiffIndex >= 0) {
          diffHelpers.setValue(
            diffs.map((diff, index) =>
              index === previousAddedDiffIndex
                ? {
                    ...diff,
                    index: to,
                  }
                : diff
            )
          );
          return;
        }

        // If already moved, we can simply update the previous index.
        const previousMovedDiffIndex = getPreviousMovedDiffIndex(diffs, {
          id: fromId,
        });
        if (previousMovedDiffIndex >= 0) {
          diffHelpers.setValue(
            set(diffs, `${previousMovedDiffIndex}.after`, toId)
          );
          return;
        }

        // If it's a new move, just push
        pushDiff({
          kind: DiffActionKind.MOVE,
          id: fromId,
          after: toId,
        });
      },
      insert: (val: T | T[], index: number) => {
        _helpers.insert(val, index);
        pushDiff({ kind: DiffActionKind.ADD, data: val, index });
      },
      push: (val: T | T[]) => {
        _helpers.push(val);
        pushDiff({ kind: DiffActionKind.ADD, data: val });
      },
      replace: (val: T, index: number) => {
        _helpers.replace(val, index);
      },
      remove: (index: number) => {
        const obj = field.value[index];
        _helpers.remove(index);

        const previousAddedDiffIndex = getPreviousAddedDiffIndex(diffs, obj);
        if (previousAddedDiffIndex >= 0) {
          pullAt(diffs, previousAddedDiffIndex);
          diffHelpers.setValue(diffs);
          return;
        }

        pushDiff({ kind: DiffActionKind.REMOVE, id: obj.id });
      },
    }),
    [field.value, diffs, pushDiff, _helpers]
  );

  const sort = useMemo<Partial<SortableContainerProps>>(
    () => ({
      ...sortableOptions,
      onSortEnd: ({ oldIndex, newIndex }) => {
        helpers.move(oldIndex, newIndex);
      },
    }),
    [sortableOptions, name, field.value]
  );

  return [field, meta, helpers, sort];
}

export function useFieldArray<T>(
  propsOrFieldName: string | UseFieldProps<T[]>,
  sortableOptions?: Partial<SortableContainerProps>
): [FieldInputProps<T[]>, FieldMetaProps<T[]>, FieldArrayHelperProps<T>] {
  const [field, meta, _helpers] = useField<T[]>(propsOrFieldName);

  const helpers = useMemo(
    () => ({
      ..._helpers,
      move: (from: number, to: number) =>
        _helpers.setValue(move(field.value, from, to) as T[]),
      push: (val: T | T[]) =>
        _helpers.setValue([
          ...(field.value ?? []),
          ...(Array.isArray(val) ? val : [val]),
        ]),

      insert: (val: T | T[], index: number) => {
        const prev = Object.assign([], field.value ?? []);
        prev.splice(index, 0, ...(Array.isArray(val) ? val : [val]));
        _helpers.setValue(prev);
      },
      replace: (val: T, index: number) => {
        const prev = Object.assign([], field.value ?? []);
        prev.splice(index, 1, ...(Array.isArray(val) ? val : [val]));
        _helpers.setValue(prev);
      },
      remove: (index: number) => {
        let value = clone(field.value);
        pullAt(value, index);
        _helpers.setValue(value);
      },
    }),
    [field.value]
  );

  return [field, meta, helpers];
}

export function useSortableFieldArray<T extends { id: string }>(
  name: string,
  sortableOptions: Partial<SortableContainerProps> = {}
): [
  FieldInputProps<T[]>,
  FieldMetaProps<T[]>,
  FieldArrayHelperProps<T>,
  Partial<SortableContainerProps>
] {
  const [field, meta, helpers] = useFieldArray<T>(name);
  const [moveField, _, moveHelpers] = useField<MoveStatus[]>(`${name}_moves`);
  const moves = moveField.value ?? [];
  const value = field.value;
  const sort = useMemo<Partial<SortableContainerProps>>(
    () => ({
      ...sortableOptions,
      onSortEnd: ({ oldIndex, newIndex }) => {
        const from = value[oldIndex].id;
        const to = newIndex > 0 ? value[newIndex].id : null;
        helpers.setValue(move(value, oldIndex, newIndex) as T[]);
        moveHelpers.setValue([...moves, { from, to }]);
      },
    }),
    [sortableOptions, name, value, moves]
  );

  return [field, meta, helpers, sort];
}

export function useTableSortFix(className: string) {
  const onSortStart = useCallback(({ node }) => {
    const tds: any = document.getElementsByClassName(className)[0].childNodes;
    node.childNodes.forEach(
      (node: HTMLElement, idx: number) =>
        (tds[idx].style.width = `${node.offsetWidth}px`)
    );
  }, []);
  return { onSortStart, helperClass: className };
}

export function useFieldError(
  name: string,
  intent: Intent = Intent.NONE
): [Intent, string | null] {
  const [_, meta] = useField(name);
  const hasError = meta.touched && meta.error != null;
  const nextIntent = hasError ? Intent.WARNING : intent;
  return [nextIntent, hasError ? meta.error : null];
}

export function useFieldHelpers<T>(name: string) {
  const [field, __, helpers] = useField<T>(name);

  return useMemo(
    () => ({
      ...helpers,
      updateValue: (
        updater: (prev?: T) => T,
        shouldValidate: boolean = true
      ) => {
        helpers.setValue(updater(field.value), shouldValidate);
      },
    }),
    [helpers, field.value]
  );
}
