import React, { useEffect, useLayoutEffect } from 'react';
import { NotificationProvider } from './NotificationContext';
import { LoginProvider } from './LoginContext';
import { CrumbsProvider } from './CrumbsContext';
import GraphQLProvider from 'src/utils/graphql';
import 'src/utils/i18n';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppErrorBoundary from '../components/AppErrorBoundary';
import {
  AppQuickLookContext,
  AppQuickLookDrawer,
  QuickLookRepositoryProvider,
} from 'src/components/QuickLookDrawer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { OmnibarRepositoryProvider } from './OmnibarContext';
import AppOmnibar from 'src/components/AppOmnibar';
import { AppHotkeys } from './HotkeysContext';
import { AppFeaturSetContext } from './FeatureSet';
import { v4 } from 'src/utils/uuid';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useEnvironment } from './EnvironmentContext';
import { useTitleTemplate } from 'hoofd';
// import { useTitle, useTitleTemplate } from 'hoofd';

const queryClient = new QueryClient();

export const DeviceIdentifier = React.createContext<string>(v4());

export function useDeviceIdentifier() {
  return useContext(DeviceIdentifier);
}

export default function AppContext(p: { children: JSX.Element }) {
  const { i18n } = useTranslation();
  const { APP_TITLE } = useEnvironment();
  useTitleTemplate(`${APP_TITLE} - %s`);

  const deviceIdentifier = useMemo(() => {
    let id = localStorage.getItem('deviceIdentifier');
    if (isEmpty(id)) {
      id = v4();
      localStorage.setItem('deviceIdentifier', id);
    }
    return id;
  }, []);

  return (
    <AppErrorBoundary>
      <DeviceIdentifier.Provider value={deviceIdentifier}>
        <AppFeaturSetContext>
          <AppHotkeys>
            <QueryClientProvider client={queryClient}>
              <GraphQLProvider>
                <>
                  <AppQuickLookContext>
                    <QuickLookRepositoryProvider>
                      <OmnibarRepositoryProvider>
                        <NotificationProvider>
                          <LoginProvider>
                            <CrumbsProvider>{p.children}</CrumbsProvider>
                          </LoginProvider>
                        </NotificationProvider>
                      </OmnibarRepositoryProvider>
                    </QuickLookRepositoryProvider>
                  </AppQuickLookContext>
                  <ReactQueryDevtools
                    position="bottom-left"
                    initialIsOpen={false}
                  />
                </>
              </GraphQLProvider>
            </QueryClientProvider>
          </AppHotkeys>
        </AppFeaturSetContext>
      </DeviceIdentifier.Provider>
    </AppErrorBoundary>
  );
}
