import classNames from 'classnames';
import { isEmpty, last, update } from 'lodash';
import { set } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Node, NodeProps, useReactFlow } from 'react-flow-renderer';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { Block, BlockStatus, PreviewDataType } from '@keix/workflow-types';
import {
  getBlockArguments,
  getBlockProxy,
  getBlockReturnType,
  getBlockType,
  getDefaultType,
  useCollapsedBlocks,
} from 'src/hooks/workflow/utils';
import { BlockIcon, BlockNodeIcon } from './BlockIcon';
import { BlockLabel } from './BlockLabel';
import { BlockPort } from './BlockPort';
import { useWorkflowContext } from './WorkflowContext';
import { usePreviewer } from './PreviewContext';

export function BlockNode(node: NodeProps<Block>) {
  const { setNodes, getNode } = useReactFlow();
  const { dataTypes } = useLibraryContext();
  const { state } = useWorkflowContext();
  const previewer = usePreviewer();
  const { minimalLayout } = state;

  const updateNode = useCallback(
    (updater: (node: Node<Block>) => Node<Block>) => {
      setNodes((nodes) =>
        nodes.map((d) => (d.id === node.id ? updater(d) : d))
      );
    },
    [node.id, setNodes]
  );

  const collapsedBlocks = useCollapsedBlocks(node.id);

  const returnType = getBlockReturnType(node.data, collapsedBlocks);
  const dataReturnType = dataTypes[returnType];
  const args = getBlockArguments(node.data).filter((d) => d.isPort === true);
  const proxy = getBlockProxy(node.data);
  const previewReturnParameters = !isEmpty(collapsedBlocks)
    ? last(collapsedBlocks).data.parameters
    : node.data.parameters;
  const shouldShowBlockName =
    minimalLayout !== true || node.data.state.status === BlockStatus.FAILED;
  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames('flex items-stretch rounded bg-white shadow', {
          [`ring-2 ring-offset-1 ring-opacity-50 ring-${node.data.style.bg.color}-${node.data.style.bg.opacity}`]:
            node.selected,
        })}
      >
        <div className="flex flex-col divide-y">
          {args.map((p, index) => (
            <BlockPort
              id={p.name}
              text={p.name}
              minimal={minimalLayout}
              key={index}
              bg={node.data.style.bg}
              direction="in"
              type={getDefaultType(p.type)}
              value={node.data.parameters[p.name]}
            />
          ))}
        </div>
        <div className="self-stretch flex gap-x-1">
          <BlockNodeIcon block={node.data} hasInputs={!isEmpty(args)} />
          {collapsedBlocks.map((b) => (
            <BlockNodeIcon block={b.data} />
          ))}
        </div>
        {returnType != null && (
          <BlockPort
            id="out"
            text="out"
            minimal={minimalLayout}
            bg={node.data.style.bg}
            direction="out"
            preview={
              proxy != null
                ? (type: PreviewDataType) =>
                  previewer(`${node.data.id}_${type}`, () => dataReturnType?.preview?.(proxy, {
                    type,
                    parameters: previewReturnParameters,
                    size: { width: 640, height: 460 },
                  }))
                : null
            }
            type={returnType}
          />
        )}
      </div>
      {shouldShowBlockName && (
        <BlockLabel
          label={node.data.label ?? node.data.name}
          selected={node.selected}
          onChange={(label) => updateNode(set('data.label', label))}
          {...node.data.state}
        />
      )}
    </div>
  );
}
