import React, { useCallback } from 'react';
import type { AppTableInstance } from 'src/utils/table/useAppTable';
import type { Size } from 'src/utils/size';
import css from 'csz';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { Classes } from '@blueprintjs/core';
import { Table, Column, Cell } from '@blueprintjs/table';
import { flatten } from 'lodash';
import { HeaderGroup } from 'react-table';

const className = css`/styles/datagrid.css`;

const DEFAULT_ROW_HEIGHT = 30;
const DEFAULT_HEADER_HEIGHT = 30;

interface P<D extends object> {
  table: AppTableInstance<D>;
  size: Size;
  className?: string;
  rowHeight?: number;
  headerHeight?: number;
}

export function DataGrid<D extends object>(p: P<D>) {
  const { size, table } = p;
  const { getTableProps, getTableBodyProps, infiniteLoader, headerGroups, dispatch, totalColumnsWidth, rows, state, prepareRow } = table;
  const rowHeight = p.rowHeight ?? DEFAULT_ROW_HEIGHT;
  const headerHeight = p.headerHeight ?? DEFAULT_HEADER_HEIGHT;

  const cellRenderer = useCallback(
    (columnIndex: number) => (rowIndex: number) => {
      const row = rows[rowIndex];
      prepareRow(row);

      return <Cell>{row.cells[columnIndex].render('Cell')}</Cell>;
    },
    [rows, prepareRow]
  );

  return (
    <div className={className}>
      <Table numRows={rows.length}>
        {flatten(
          headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column, index) => <Column name={column.render('Header') as string} cellRenderer={cellRenderer(index)} />)
          )
        )}
      </Table>
    </div>
  );
}
