import React, { useCallback, useMemo } from 'react';
import { Size, useSize } from 'src/utils/size';
import RGL, { Layout, Layouts, WidthProvider } from 'react-grid-layout';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';
import { Block, BlockStatus, PreviewDataType } from '@keix/workflow-types';
import { Node } from 'react-flow-renderer';
import { BlockOutputPreviewer } from './BlockOutputPreviewer';
import { Icon } from '@blueprintjs/core';
import 'react-grid-layout/css/styles.css';

const ReactGridLayout = WidthProvider(RGL);

export function WorkflowDashboard(p: { size: Size; children?: JSX.Element }) {
  const { state, dispatch } = useWorkflowContext();

  const layoutBlocks = useMemo(
    () => state.nodes.filter((d) => d.data.layout != null),
    [state.nodes]
  );
  const layout: Layout[] = useMemo(
    () =>
      layoutBlocks.map((d) => ({
        ...d.data.layout,
        resizeHandles: ['se'],
        i: d.id,
      })),
    [layoutBlocks]
  );

  const handleLayoutChange = useCallback((layout: Layout[]) => {
    console.log('=> Layout change');
    dispatch({ type: WorkflowAction.SET_NODES_LAYOUT, layout });
  }, []);

  return (
    <div style={{ height: p.size.height }} className="relative">
      <ReactGridLayout layout={layout} onLayoutChange={handleLayoutChange}>
        {layoutBlocks.map((block) => (
          <div className="relative" key={block.id}>
            <WorkflowDashboardBlock block={block} />
          </div>
        ))}
      </ReactGridLayout>
      {p.children}
    </div>
  );
}

function WorkflowDashboardBlock(p: { block: Node<Block> }) {
  const { id, state } = p.block.data;
  const [size, ref] = useSize();
  let content: JSX.Element;

  switch (state.status) {
    case BlockStatus.COMPLETED:
      content = (
        <BlockOutputPreviewer
          size={size}
          type={PreviewDataType.LARGE}
          node={p.block}
        />
      );
      break;
    case BlockStatus.IDLE:
      content = <Icon size={30} className="text-gray-400" icon="database" />;
      break;
  }

  return (
    <div
      ref={ref}
      className="absolute top-0 left-0 right-0 bottom-0 rounded border bg-white shadow-sm"
    >
      {content}
    </div>
  );
}
