import {
  cloneDeep,
  compact,
  Dictionary,
  difference,
  differenceBy,
  every,
  get,
  includes,
  isArray,
  isEmpty,
  isNumber,
  isString,
  keyBy,
  last,
  update,
} from 'lodash';
import { Connection, Node, Edge } from 'react-flow-renderer';
import ELK, { ElkNode } from 'elkjs';
import {
  Block,
  BlockConnection,
  BlockState,
  BlockStatus,
  DataTypeDefinition,
  LibraryBlock,
  LibraryBlockArgument,
  PyProxy,
} from '@keix/workflow-types';
import { v4 } from 'src/utils/uuid';
import { LibraryContext, resolveDataType } from './LibraryContext';
import {
  useWorkflowContext,
  WorkflowState,
} from 'src/apps/kgest/pages/workflow/WorkflowContext';

export const DEFAULT_BLOCK_STATE: BlockState = {
  status: BlockStatus.IDLE,
};

const RESERVED_WORDS = [
  'False',
  'def',
  'if',
  'raise',
  'None',
  'del',
  'import',
  'return',
  'True',
  'elif',
  'in',
  'try',
  'and',
  'else',
  'is',
  'while',
  'as',
  'except',
  'lambda',
  'with',
  'assert',
  'finally',
  'nonlocal',
  'yield',
  'break',
  'for',
  'not',
  '',
  'class',
  'from',
  'or',
  '',
  'continue',
  'global',
  'pass',
];

const MAX_CALL_STACK = 1000;
export interface ExecutableCode {
  id: string;
  code: string;
}

export function convertReactFlowToBlocks(
  nodes: Node<Block>[],
  edges: Edge<Block>[]
): Block[] {
  // const { connections, blocks } = elements.reduce<{
  //   connections: Connection[];
  //   blocks: Block[];
  // }>(
  //   (prev, next) => {
  //     if ('sourceHandle' in next) {
  //       return update(prev, 'connections', (conn) => [...conn, next]);
  //     } else {
  //       return update(prev, 'blocks', (blocks) => [...blocks, next.data]);
  //     }
  //   },
  //   { connections: [], blocks: [] }
  // );

  let blocksById = keyBy(
    nodes.map((d) => d.data),
    'id'
  );

  edges.forEach(({ source, sourceHandle, target, targetHandle }) => {
    const connection: BlockConnection = {
      from: { id: target, port: targetHandle },
      to: { port: sourceHandle },
    };
    if (source in blocksById) {
      update(
        blocksById,
        `${source}.connections`,
        (prev?: BlockConnection[]) => [...(prev ?? []), connection]
      );
    } else {
      console.warn('Cannot connect an unknown block', source);
    }
  });

  // Return all the objects with the connections.
  return Object.values(blocksById);
}

export function getBlockVariable(id: string) {
  return id.length > 5 ? id.replaceAll('-', '').replace(/[0-9]/g, '') : id;
}

function sortBlocksByDependency<T extends Pick<Block, 'id' | 'connections'>>(
  blocks: T[]
): T[] {
  let ranBlocks = blocks.filter((b) => isEmpty(b.connections));

  let otherBlocks = differenceBy(blocks, ranBlocks, 'id');
  let callStack = 0;
  while (!isEmpty(otherBlocks)) {
    const ranBlockIds = ranBlocks.map((d) => d.id);

    ranBlocks.push(
      ...otherBlocks.filter((b) =>
        every(b.connections, (conn) => ranBlockIds.includes(conn.from.id))
      )
    );

    otherBlocks = differenceBy(blocks, ranBlocks, 'id');

    callStack++;
    if (callStack >= MAX_CALL_STACK) {
      throw new Error(`Call stack execeded max (${MAX_CALL_STACK})`);
    }
  }

  return ranBlocks;
}

type BlocksUpdater = (updater: Node<Block>[]) => Node<Block>[];
export async function layoutNodes(
  nodes: Node<Block>[],
  edges: Edge<Block>[]
): Promise<BlocksUpdater> {
  const elk = new ELK();

  const children = nodes.map(({ id }) => ({
    id: id,
    width: 200,
    height: 80,
  }));

  const graphEdges = edges.map(({ id, source, target }) => ({
    id,
    source: target,
    target: source,
  }));
  // const graph: ElkNode = nodes.reduce<ElkNode>(
  //   (prev, next) => {
  //     const children = [
  //       ...prev.children,
  //     ];
  //     const edges = [
  //       ...prev.edges,
  //       ...next.data.connections.map((d) => ({
  //         id: `f${d.from}${d.from.port ?? ''}t${d.to.port}`,
  //         sources: [d.from.id],
  //         targets: [d.to],
  //       })),
  //     ];
  //     return {
  //       ...prev,
  //       children,
  //       edges,
  //     };
  //   },
  //   {
  //     id: v4(),
  //     children: [],
  //     edges: [],
  //   }
  // );
  console.log(graphEdges);
  const layout = await elk.layout({
    id: 'root',
    children,
    layoutOptions: { 'elk.algorithm': 'layered' },
    edges: graphEdges,
  });

  console.log(layout);
  return (blocks) => {
    const layoutById = keyBy(layout.children ?? [], 'id');
    return blocks.map((d) => {
      const children = layoutById[d.id];
      if (children != null) {
        return {
          ...d,
          position: {
            x: children.x ?? d.position.x,
            y: children.y ?? d.position.y,
          },
        };
      }
      return d;
    });
  };
}

export function buildFunctionsCode(
  block: Block[],
  library: LibraryContext
): ExecutableCode[] {
  const functions = block.filter((f) => !isEmpty(f.blocks));

  return functions.map((f) => {
    const inputs = f.blocks?.filter((f) => f.uri === 'keix.input') ?? [];
    const outputs = f.blocks?.find((f) => f.uri === 'keix.output');

    const inputVariables = inputs.map((i) => i.parameters?.name ?? 'in');
    const definition = `def ${f.name}(${inputVariables.join(', ')}):`;
    const body = buildMainExecutionCode(
      f.blocks.map((blockRef, index) => {
        const block = library.blocksByUri[blockRef.uri];
        if (block == null) {
          throw new Error('Cannot find block with uri: ' + blockRef.uri);
        }
        const { code, name, functionType, returnType, async, ...other } = block;
        return {
          arguments: other.arguments,
          code,
          name,
          functionType,
          returnType,
          async,
          ...blockRef,
        };
      }),
      library,
      'function'
    );
    const code = `${definition}\n${body.map((b) => `\t${b.code}`).join('\n')}`;
    return {
      id: f.id,
      code,
    };
  });
}

export function buildMainExecutionCode(
  blocks: Pick<
    Block,
    | 'id'
    | 'uri'
    | 'connections'
    | 'parameters'
    | 'async'
    | 'functionType'
    | 'arguments'
    | 'name'
    | 'noop'
  >[],
  library: LibraryContext,
  mode: 'main' | 'function' = 'main'
): ExecutableCode[] {
  let main: ExecutableCode[] = [];

  sortBlocksByDependency(blocks).forEach((block) => {
    const connectionById = keyBy(block.connections, 'to.port');

    // For each argument.
    const defaultRes = { args: [], self: null };
    const res = getBlockArguments(block).reduce<{
      args: string[];
      self?: string;
    }>((prev, argument) => {
      const isSelfArgument = argument.name === 'self';
      const keyPath = isSelfArgument ? 'self' : 'args';

      function updater(value: string) {
        update(prev, keyPath, (prev) =>
          isSelfArgument ? value : [...prev, value]
        );
        return prev;
      }

      // if (argument.static === true) {
      //   return updater(argument.type);
      // }
      const paramName = RESERVED_WORDS.includes(argument.name)
        ? `_${argument.name}`
        : argument.name;

      const shouldAddArgsPrefix =
        !isSelfArgument && block.uri !== 'keix.output' && block.noop !== true;
      const prefix = shouldAddArgsPrefix ? `${paramName}=` : '';

      // First look for any parameter.
      if (argument.name in block.parameters && block.noop !== true) {
        const rawValue: any = block.parameters[argument.name];
        let argumentType = isArray(argument.type)
          ? argument.type[0]
          : argument.type;

        const dataType = getDataType(argumentType, library.dataTypes);
        const value = dataType?.build?.(rawValue) ?? rawValue;

        return updater(`${prefix}${value}`);
      }

      if (argument.name in connectionById) {
        const connection = connectionById[argument.name];
        return updater(`${prefix}${getBlockVariable(connection.from.id)}`);
      }
      return prev;
    }, defaultRes);

    const { self, args } = res ?? defaultRes;
    const variable = getBlockVariable(block.id);

    if (block.noop) {
      main.push({ id: block.id, code: `${variable} = ${args[0]}` });
      return;
    }

    const awaitOrNot = block.async === true ? 'await ' : '';
    const functionArguments =
      block.functionType !== 'attribute' ? `(${args.join(', ')})` : '';
    let code = `${variable} = ${awaitOrNot}${self != null ? `${self}.` : ''}${
      block.name
    }${functionArguments}`;

    if (block.uri === 'keix.input') {
      code = `${variable} = ${get(block?.parameters, 'name')}`;
    } else if (block.uri === 'keix.output') {
      const name = get(block?.parameters, 'name') ?? 'out';
      code = mode === 'function' ? `return ${args[0]}` : `${name} = ${args[0]}`;
    }

    // if (['keix.output', 'keix.input'].includes(block.uri)) {
    //   return;
    // }
    main.push({ id: block.id, code });
  });

  return main;
}

export function getBlockCode(
  block: Pick<Block, 'blocks' | 'code'>,
  library: LibraryBlock[]
): string[] {
  const { code } = block;
  const blocksCode =
    block.blocks?.map((b) => {
      const { uri } = b;
      const blockByUri = library.find((b) => b.uri === uri);
      return blockByUri?.code;
    }) ?? [];
  return compact([code, ...blocksCode]);
}

export function getBlockArguments(
  block: Pick<Block, 'blocks' | 'arguments'>
): LibraryBlockArgument[] {
  if (block.arguments != null) {
    return block.arguments;
  }

  if (!isEmpty(block.blocks)) {
    const inputs = block.blocks.filter((f) => f.uri === 'keix.input');
    return inputs.map((d) => ({
      name: d.parameters?.name ?? 'in',
      type: d.parameters?.type ?? 'any',
    }));
  }

  return [];
}

export function getBlockProxy(
  block: Block,
  collapsedBlocks?: Node<Block>[]
): PyProxy | null {
  if (!isEmpty(collapsedBlocks)) {
    const lastBlock = last(collapsedBlocks);
    return lastBlock.data.state.proxy;
  } else {
    return block.state.proxy;
  }
}

export function getBlockReturnType(
  block: Block,
  collapsedBlocks?: Node<Block>[]
): string {
  if (!isEmpty(collapsedBlocks)) {
    const lastBlock = last(collapsedBlocks);
    return getBlockReturnType(lastBlock.data);
  }

  // If has return type, return it.
  if (block.returnType != null) {
    return getBlockType(block.returnType, block);
  }

  // If it's a block with sublocks,
  const outputBlock = block.blocks?.find((d) => d.uri === 'keix.output');
  if (outputBlock != null) {
    return outputBlock.parameters?.type;
  }

  return null;
}

export function getBlockType(type: string | null, block: Block): string {
  if (type?.startsWith('{{')) {
    const template = type.replace('{{', '').replace('}}', '');
    return get(block, template);
  }
  return type;
}

export function createNode(
  block: LibraryBlock,
  position?: { x: number; y: number }
): Node<Block> {
  const data = cloneDeep({ ...block, id: v4() });
  const node: Node<Block> = {
    id: data.id,
    data: {
      ...data,
      isCollapsed: false,
      connections: [],
      parameters: (data.arguments ?? []).reduce((prev, arg) => {
        if (arg.defaultValue != null) {
          return {
            ...prev,
            [arg.name]: arg.defaultValue,
          };
        }
        return prev;
      }, {}),
      state: DEFAULT_BLOCK_STATE,
    },
    type: 'block',
    position: position ?? { x: 100, y: 100 },
  };
  return node;
}

export function getDataType(
  type: string,
  dataTypes: Dictionary<DataTypeDefinition>
): DataTypeDefinition | null {
  let dataType = dataTypes[type];
  if (dataType) {
    return resolveDataType(dataType, dataTypes);
  }
  return null;
}

export function getDefaultType(type: string | string[]): string {
  return isArray(type) ? type[0] : type;
}

export function getCollapsedBlocks(
  id: string,
  state: Pick<WorkflowState, 'nodes' | 'edges' | 'nodeIndexById'>
): Node<Block>[] {
  let blocks: Node<Block>[] = [];
  let currentBlockId = id;

  while (currentBlockId != null) {
    const outConection = state.edges.find((d) => d.target === currentBlockId);
    const outNode =
      outConection != null
        ? state.nodes[state.nodeIndexById[outConection.source]]
        : null;
    if (outNode && outNode.data.isCollapsed) {
      blocks = [...blocks, outNode];
      currentBlockId = outNode.id;
    } else {
      currentBlockId = null;
    }
  }
  return blocks;
}

export function useCollapsedBlocks(id: string): Node<Block>[] {
  const { state } = useWorkflowContext();

  return getCollapsedBlocks(id, state);
}

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
