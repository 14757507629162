import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useNotifications } from './NotificationContext';
import { Intent } from '@blueprintjs/core';
import { useGraphQLFetch } from 'src/utils/graphql';
import { Mutation, LoginResponse } from 'src/apps/athena/gql-types';

export interface LoginState extends LoginResponse {}

export interface LoginActions {
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

export interface LoginCtx {
  state: LoginState;
  actions: LoginActions;
}

const LoginContext = createContext(null);

export function useLoginContext(): LoginCtx {
  return useContext(LoginContext);
}

export function getLoginState(): LoginState {
  const notLoggedIn = { loggedIn: false };
  const prev = window.localStorage.getItem('login');
  try {
    const maybeState = JSON.parse(prev);
    if (!('idToken' in maybeState)) {
      return notLoggedIn;
    }
    return maybeState;
  } catch {
    return notLoggedIn;
  }
}

export function LoginProvider(p: { children: JSX.Element }) {
  const notifications = useNotifications();
  const [state, setState] = useState<LoginState>(getLoginState());
  const MUTATION =
    'mutation Login($email: String!, $password: String!) { loginUser(email: $email, password: $password) { loggedIn idToken userId displayName isEmailToBeVerified isPhoneToBeVerified isEmailVerified isPhoneVerified isFromSurveyWizard }}';

  const graphqlFetch = useGraphQLFetch();
  const login = useCallback(async (email: string, password: string) => {
    return graphqlFetch(MUTATION, { email, password }).then(
      (data: Pick<Mutation, 'loginUser'>) => {
        const loggedIn = data?.loginUser?.loggedIn ?? false;

        console.log('=> ', data?.loginUser);
        if (loggedIn) {
          notifications.show({
            message: 'Accesso effettuato!',
            icon: 'tick',
            intent: Intent.SUCCESS,
          });
          setState((prev) => ({
            ...prev,
            ...data?.loginUser,
          }));
        } else {
          notifications.show({
            message: 'Accesso non riuscito: email o password errati.',
            icon: 'cross',
            intent: Intent.DANGER,
          });
        }
        return loggedIn;
      }
    );
  }, []);
  const logout = useCallback(() => {
    setState({ loggedIn: false });
    notifications.show({
      message: 'Logout effettuato, a presto!',
      icon: 'log-out',
      intent: Intent.PRIMARY,
    });
  }, []);

  useEffect(() => {
    if (!state.loggedIn) {
      window.localStorage.removeItem('login');
    } else {
      window.localStorage.setItem('login', JSON.stringify(state));
    }
  }, [state]);

  const value = useMemo<LoginCtx>(() => {
    return {
      state,
      actions: {
        login,
        logout,
      },
    };
  }, [state]);
  return (
    <LoginContext.Provider value={value}>{p.children}</LoginContext.Provider>
  );
}
