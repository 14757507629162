import React, { useState, useCallback, useEffect } from 'react';

import {
  IInputGroupProps,
  HTMLInputProps,
  IPopoverProps,
  Button,
  Icon,
  Colors,
  Spinner,
  Tag,
  Intent,
  Classes,
} from '@blueprintjs/core';
import { MenuItem } from '@blueprintjs/core';

import {
  IItemListRendererProps,
  IItemRendererProps,
  ItemRenderer,
  Omnibar,
  Suggest2,
} from '@blueprintjs/select';
import { Suggest } from '@blueprintjs/select';
import type { Person, Company, Project, Ref } from 'src/apps/athena/gql-types';
import { getDisplayName } from 'src/utils/accessors';
import { useQuery } from '@tanstack/react-query';
import { useField, useFieldValue } from 'formik';
import { useTranslation } from 'react-i18next';
import { TreeNode } from 'src/apps/athena/gql-types';
import { useGraphQLFetch } from 'src/utils/graphql';
import {
  compact,
  get,
  isArray,
  isEmpty,
  isString,
  orderBy,
  set,
  times,
} from 'lodash';
import { useDebounce } from 'src/utils/size';
import { SEARCH_QUERY as SEARCH_PEOPLE } from 'src/graphql/people';
import { SEARCH_QUERY as SEARCH_COMPANIES } from 'src/graphql/companies';
import { useQuickLook } from './QuickLookDrawer';
import { SEARCH_PROJECTS } from 'src/graphql/projects';

export const NEW_IDENTIFIER = 'NEW';

export interface OmniP<T> extends P<T> {
  isOpen: boolean;
  onItemSelect: (item: T) => void;
}
import { QUERIES } from 'src/apps/athena/graphql/quotation';
import { v4 } from 'src/utils/uuid';
import { MenuItem2 } from '@blueprintjs/popover2';
import { useAppLanguage } from './LanguageButton';

export interface P<T> {
  name: string;
  valueRenderer: (item: T) => string;
  filterItems?: (item: T) => boolean;
  dataKey: string;
  paginated?: boolean;
  value?: T;
  inputProps?: IInputGroupProps & HTMLInputProps;
  popoverProps?: IPopoverProps;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  minimal?: boolean;
  onChange?: (item?: T) => void;
  onBlur?: (event: any) => void;
  menuItemRenderer?: (item: T) => {
    label?: string;
    labelElement?: JSX.Element;
    text: string;
  };
  itemRenderer: (
    item: T,
    prop: IItemRendererProps,
    queryString: string
  ) => JSX.Element;
  newItemBuilder?: (val: string) => Partial<T>;
  placeholder?: string;
  query: string;
  variables?: {};
}

function getModifiersProps(props: IItemRendererProps) {
  return {
    active: props.modifiers.active,
  };
}

function getQueryStringMatchedComponents(text: string, queryString: string) {
  const needle = queryString
    .toLowerCase()
    .split(' ')
    .filter((f) => f.length > 0);
  return compact(
    needle.map((n) => {
      const index = text.toLowerCase().indexOf(n);
      if (index >= 0) {
        return { index, length: n.length };
      } else {
        return null;
      }
    })
  );
}

export function highlightQueryString(text: string, queryString: string) {
  const indexes = getQueryStringMatchedComponents(text, queryString);
  const textComponents = times(text?.length ?? 0).map((index) => {
    const found = indexes.find((d) => d.index === index);
    const skip = indexes.find(
      (d) => index > d.index && index < d.index + d.length
    );
    if (skip != null) {
      return '';
    } else if (found != null) {
      return (
        <span className="bg-yellow-200 bg-opacity-40 text-gray-700">
          {text.slice(found.index, found.length + found.index)}
        </span>
      );
    } else {
      return text[index];
    }
  });
  return <span>{textComponents}</span>;
}

export function CompanySuggesterField(p: {
  name: string;
  disabled?: boolean;
  fill?: boolean;
  minimal?: boolean;
  readOnly?: boolean;
  labelRenderer?: (person: Company) => JSX.Element | string;
  valueSetter?: (company: Company) => unknown;
  onSelect?: (item: Company) => void;
}) {
  const { labelRenderer } = p;
  const { quickLookRef } = useQuickLook();
  const [value] = useFieldValue<Company | null>(p.name);
  const hasValue = value?.id != null;
  const handleQuickLook = () =>
    hasValue ? quickLookRef({ kind: 'company', ref: { id: value.id } }) : null;

  return (
    <GraphQLSuggesterField<Company>
      {...p}
      valueRenderer={(p) => p.name}
      query={SEARCH_COMPANIES}
      inputProps={{
        leftIcon: 'briefcase',
        rightElement: hasValue && (
          <Button
            icon="document-open"
            minimal
            intent={Intent.PRIMARY}
            onClick={handleQuickLook}
          />
        ),
      }}
      popoverProps={{
        targetClassName: p.fill ? Classes.FILL : '',
        minimal: true,
      }}
      newItemBuilder={(p) => ({ name: p, id: NEW_IDENTIFIER })}
      menuItemRenderer={(p) => ({ text: p.name })}
      itemRenderer={(c, props, queryString) => (
        <MenuItem
          {...props}
          {...getModifiersProps(props)}
          onClick={props.handleClick}
          labelElement={labelRenderer?.(c)}
          text={highlightQueryString(c.name, queryString)}
        />
      )}
      dataKey="companiesSuggester"
    />
  );
}

function SuggesterRightElement(p: { onQuickLook?: () => void }) {
  return (
    <>
      {p.onQuickLook != null && (
        <Button
          icon="document-open"
          minimal
          intent={Intent.PRIMARY}
          onClick={p.onQuickLook}
        />
      )}
    </>
  );
}

export function fiscalCodeRenderer(p: Person | Ref) {
  if (p != null && 'fiscalCode' in p) {
    return p.fiscalCode;
  }
  return null;
}

export function vatIdRenderer(p: Company | Ref) {
  if (p != null && 'vatId' in p) {
    return p.vatId;
  }
  return null;
}

export function PersonSuggesterField(p: {
  name: string;
  placeholder?: string;
  fill?: boolean;
  canAddItem?: boolean;
  query?: string;
  disabled?: boolean;
  readOnly?: boolean;
  minimal?: boolean;
  valueSetter?: (person: Person) => unknown;
  labelRenderer?: (person: Person | Ref) => JSX.Element | string;
  onSelect?: (item: Person) => void;
}) {
  const { quickLookRef } = useQuickLook();
  const [value] = useFieldValue<Person | null>(p.name);
  const hasValue = value?.id != null;
  const { labelRenderer } = p;
  const handleQuickLook = hasValue
    ? () => quickLookRef({ kind: 'person', ref: { id: value.id } })
    : null;
  const renderer = useCallback((p: Person | Ref) => {
    if (p == null) {
      return '';
    } else if (isString(p)) {
      return p;
    }
    if ('name' in p) {
      return p.name;
    } else if ('firstName' in p) {
      return compact([p.firstName, p.lastName]).join(' ');
    } else {
      return '';
    }
  }, []);

  return (
    <GraphQLSuggesterField<Person | Ref>
      {...p}
      valueRenderer={renderer}
      query={p.query ?? SEARCH_PEOPLE}
      placeholder={p.placeholder}
      minimal={p.minimal}
      inputProps={{
        leftIcon: 'person',
        fill: p.fill,
        rightElement: <SuggesterRightElement onQuickLook={handleQuickLook} />,
      }}
      popoverProps={{
        targetClassName: p.fill ? Classes.FILL : '',
        minimal: true,
      }}
      menuItemRenderer={(p) => ({ text: renderer(p) })}
      itemRenderer={(p, props, queryString) => (
        <MenuItem
          {...props}
          {...getModifiersProps(props)}
          onClick={props.handleClick}
          labelElement={labelRenderer?.(p)}
          text={highlightQueryString(renderer(p), queryString)}
        />
      )}
      dataKey="peopleSuggester"
    />
  );
}
export function ProjectSuggesterField(p: {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  minimal?: boolean;
  valueSetter?: (company: Project) => unknown;
  onSelect?: (item: Project) => void;
}) {
  const { quickLookRef } = useQuickLook();
  const [value] = useFieldValue<Project | null>(p.name);
  const hasValue = value?.id != null;
  const handleQuickLook = () =>
    hasValue ? quickLookRef({ kind: 'project', ref: { id: value.id } }) : null;
  return (
    <GraphQLSuggesterField<Project>
      {...p}
      valueRenderer={(p) => p.title}
      query={SEARCH_PROJECTS}
      minimal={p.minimal}
      menuItemRenderer={(p) => ({ text: p.title })}
      itemRenderer={(project, props, queryString) => (
        <MenuItem2
          {...props}
          {...getModifiersProps(props)}
          onClick={props.handleClick}
          text={highlightQueryString(project.title, queryString)}
        />
      )}
      dataKey="projects"
    />
  );
}

export const TreeType = {
  ACTIVITY: {
    query: QUERIES.SEARCH_ACTIVITY_TREE_NODE,
    dataKey: 'searchActivityTreeNode',
  },
  ECONOMIC: {
    query: QUERIES.SEARCH_ECONOMIC_TREE_NODE,
    dataKey: 'searchEconomicActivitesTreeNode',
  },
  EDUCATION: {
    query: QUERIES.SEARCH_EDUCATION_TREE_NODE,
    dataKey: 'searchEducationTreeNode',
  },
  OCCUPATIONS: {
    query: QUERIES.SEARCH_OCCUPATIONS_TREE_NODE,
    dataKey: 'searchOccupationsTreeNode',
  },
};

export type TreeType = { query: string; dataKey: string };

export function TreeSuggesterField(p: {
  name: string;
  type: TreeType;
  disabled?: boolean;
  readOnly?: boolean;
  minimal?: boolean;
  language?: string;
  valueSetter?: (tree: TreeNode) => unknown;
  onSelect?: (item: TreeNode) => void;
}) {
  const appLanguage = useAppLanguage();
  const language = p.language ?? appLanguage;
  const [field, _, helpers] = useField<TreeNode>(p.name);
  const handleResetActivity = useCallback(() => {
    helpers.setValue(null);
  }, []);
  const hasActivityId = !isEmpty(field.value) && field.value.id != null;

  return (
    <GraphQLSuggesterField<TreeNode>
      {...p}
      newItemBuilder={(val) => ({
        id: null,
        title: val,
      })}
      inputProps={{
        leftIcon: hasActivityId ? 'diagram-tree' : 'edit',
        rightElement: hasActivityId && (
          <Button
            text={field.value?.code}
            rightIcon="cross"
            minimal
            onClick={handleResetActivity}
          />
        ),
      }}
      valueRenderer={(p) => p.title}
      query={p.type.query}
      menuItemRenderer={(p) => ({ text: p.title })}
      itemRenderer={(tree, props, queryString) => {
        const title =
          language != null
            ? get(tree, `translations.${language}.title`) ?? tree.title
            : tree.title;
        return (
          <MenuItem2
            {...getModifiersProps(props)}
            {...props}
            icon={
              <Icon
                style={{ color: Colors.GRAY5 }}
                icon={tree.id != null ? 'diagram-tree' : 'edit'}
              />
            }
            labelElement={
              tree.code != null && (
                <Tag intent={Intent.PRIMARY} minimal>
                  {tree.code}
                </Tag>
              )
            }
            onClick={props.handleClick}
            text={
              tree.id != null ? (
                highlightQueryString(title, queryString)
              ) : (
                <strong>{title}</strong>
              )
            }
          />
        );
      }}
      dataKey={p.type.dataKey}
    />
  );
}
export function GraphQLSuggesterField<T>(
  p: Omit<P<T>, 'value' | 'onChange'> & {
    name: string;
    valueSetter?: (item: T) => unknown;
    onSelect?: (item: T) => void;
  }
) {
  const [field, meta, helper] = useField(p.name);

  const handleChange = useCallback(
    (item: T) => {
      const valueSetter = p.valueSetter ?? ((a) => a);
      p.onSelect?.(item);
      helper.setTouched(true);
      helper.setValue(valueSetter(item));
    },
    [p.name, p.valueSetter, p.onSelect]
  );

  return (
    <GraphQLSuggester<T> {...p} value={field.value} onChange={handleChange} />
  );
}

export function useGraphQLSuggester<T>(
  p: Pick<
    P<T>,
    | 'query'
    | 'dataKey'
    | 'readOnly'
    | 'disabled'
    | 'paginated'
    | 'valueRenderer'
    | 'value'
    | 'newItemBuilder'
    | 'filterItems'
  >
) {
  const [rawQueryString, setQueryString] = useState('');
  const queryString = useDebounce(rawQueryString, 300);

  const [items, setItems] = useState([]);

  const [filteredItems, setFilteredItems] = useState(items);

  const { t } = useTranslation();
  const fetch = useGraphQLFetch();
  const { isSuccess, isFetching, isError, data, error } = useQuery(
    [p.dataKey, queryString],
    () => fetch(p.query, { queryString }),
    { enabled: !isEmpty(p.query) && (!p.disabled || !p.readOnly) }
  );

  let noResultsText = t('Type to search...');
  useEffect(() => {
    if (p.value != null) {
      setQueryString(p.valueRenderer(p.value) ?? '');
    }
  }, [p.value, p.valueRenderer]);

  useEffect(() => {
    // const newItem = p.newItemBuilder?.(queryString) ?? null;

    const dataRoot = data?.[p.dataKey];
    const isPaginated = !isArray(dataRoot);
    const prevItems = (isPaginated ? dataRoot?.items : dataRoot) ?? [];

    // const hasAlreadyNewItem =
    //   prevItems.find((d: T) => p.valueRenderer(d) === queryString) != null;
    const items =
      // !hasAlreadyNewItem && queryString.length > 0
      //   ? compact([newItem, ...prevItems])
      /*:*/ prevItems;

    const sortedItems = orderBy(
      items,
      (i) => {
        const text = p.valueRenderer(i);
        const matches = getQueryStringMatchedComponents(text, queryString);

        return matches.reduce((p, n) => p + n.length, 0);
      },
      'desc'
    );

    isSuccess ? setItems(sortedItems) : [];
    setFilteredItems(
      p.filterItems != null ? sortedItems.filter(p.filterItems) : sortedItems
    );
  }, [data, p.dataKey, p.filterItems, p.newItemBuilder, queryString]);

  if (isFetching) {
    noResultsText = t('Searching...');
  }

  if (Object.keys(queryString).length == 0) {
    noResultsText = t('No data found...');
  }

  return { filteredItems, queryString, setQueryString, noResultsText };
}

export function GraphQLOmnibar<T>(p: OmniP<T>) {
  const { filteredItems, queryString, setQueryString, noResultsText } =
    useGraphQLSuggester(p);
  const { onItemSelect, isOpen } = p;
  return (
    <Omnibar<T>
      onItemSelect={onItemSelect}
      isOpen={isOpen}
      query={queryString}
      items={filteredItems}
      noResults={<MenuItem key={0} disabled text={noResultsText} />}
      itemRenderer={(a, b) => p.itemRenderer(a, b, queryString)}
      onQueryChange={setQueryString}
    />
  );
}

export default function GraphQLSuggester<T>(p: P<T>) {
  const { t } = useTranslation();
  const { filteredItems, queryString, setQueryString, noResultsText } =
    useGraphQLSuggester(p);
  return (
    <Suggest2<T>
      {...p}
      items={filteredItems}
      inputProps={{
        ...(p.inputProps ?? {}),
        id: p.name,
        className: p.minimal === true ? 'minimal' : '',
        name: p.name,
        readOnly: p.readOnly,
        placeholder: p.placeholder ?? t('Search...'),
      }}
      popoverProps={{
        placement: 'bottom-start',
      }}
      itemRenderer={(a, b) => p.itemRenderer(a, b, queryString)}
      createNewItemFromQuery={(t) => p.newItemBuilder?.(t) as T | undefined}
      createNewItemRenderer={(query, active) => {
        if (p.newItemBuilder != null) {
          const val = p.newItemBuilder?.(query);
          const value = p.valueRenderer(val as T);
          return (
            <MenuItem
              intent={Intent.PRIMARY}
              text={<span className="italic">{value}</span>}
              labelElement={
                <Tag
                  minimal
                  icon="plus"
                  className="uppercase"
                  intent={Intent.PRIMARY}
                ></Tag>
              }
              active={active}
            />
          );
        }
      }}
      inputValueRenderer={p.valueRenderer}
      query={queryString}
      noResults={<MenuItem key={0} disabled text={noResultsText} />}
      onItemSelect={p.onChange}
      onQueryChange={setQueryString}
    />
  );
}
