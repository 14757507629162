import React, { useCallback, useState, useEffect } from 'react';
import { useField } from 'formik';
import { EditableText, Text, InputGroup } from '@blueprintjs/core';
import { DateTime, LocaleOptions, DateTimeFormatOptions } from 'luxon';
import { get } from 'lodash';
import { parseDate } from 'chrono-node';

interface P {
  name: string;
  minimal?: boolean;
}

const kindToIntervals = {
  d: 'days',
  g: 'days',
  h: 'hours',
  o: 'hours',
  w: 'weeks',
  s: 'weeks',
  m: 'months',
  a: 'years',
  y: 'years',
  min: 'minutes',
};

const formats = ['dd/MM/yyyy', 'd/MM/yyyy', 'd/M/yyyy', 'dd/M/yyyy'];

export function DateInput(p: {
  minimal?: boolean;
  value?: string;
  displayFormat?: LocaleOptions & DateTimeFormatOptions;
  onChange: (value: string) => void;
}) {
  const { value, onChange } = p;
  const [internalValue, setInternalValue] = useState<string>('');

  const formatDate = useCallback(
    (date: Date) => {
      return DateTime.fromJSDate(date).toLocaleString(
        p.displayFormat ?? DateTime.DATE_MED
      );
    },
    [p.displayFormat]
  );

  const updateInternalValue = useCallback(
    (date?: Date) => {
      setInternalValue(date != null ? formatDate(date) : '');
    },
    [formatDate]
  );

  useEffect(() => {
    try {
      const d = DateTime.fromISO(value);
      d.isValid && updateInternalValue(d.toJSDate());
    } catch {}
  }, [value, formatDate]);

  const handleChange = useCallback((val: string) => {
    setInternalValue(val);
  }, []);

  const handleParseValue = useCallback<(value: string) => Date | null>(
    (value) => {
      // First test the date fomrats.
      if (
        /^((0)*[0-9]|[1-2][0-9]|(3)[0-1])(\/)(((0)*[0-9])|((1)[0-2]))(\/)\d{4}$/.test(
          internalValue
        )
      ) {
        const date = formats.reduce((prev: DateTime | null, next: string) => {
          if (prev == null || !prev.isValid) {
            return DateTime.fromFormat(value, next);
          }
          return prev;
        }, null);
        if (date?.isValid) {
          return date.toJSDate();
        }
      }

      if (/[0-9]+(d|g|w|s|m|h|o|a|y|min)/.test(value)) {
        const suffixLength = value.endsWith('min') ? 3 : 1;
        const kind = value.slice(-suffixLength);
        const amount = parseInt(value.slice(0, -suffixLength));
        const interval = kindToIntervals[kind];

        return DateTime.local()
          .plus({ [interval]: amount })
          .toJSDate();
      }

      // Try to parse with chrono.
      const parsedDate = parseDate(value);
      if (parsedDate != null) {
        return parsedDate;
      }

      return null;
    },
    []
  );

  const handleConfirm = useCallback(() => {
    const date = handleParseValue(internalValue);
    if (date) {
      updateInternalValue(date);
      onChange(date.toISOString());
    }
  }, [internalValue]);

  return p.minimal !== false ? (
    <EditableText
      value={internalValue}
      placeholder="gg/mm/yyyy"
      onConfirm={handleConfirm}
      onChange={handleChange}
    />
  ) : (
    <InputGroup
      value={internalValue}
      placeholder="gg/mm/yyyy"
      onBlur={handleConfirm}
      onChange={(e: any) => handleChange(e.target.value)}
    />
  );
}

export default function DateField(p: P) {
  const [field, f, helpers] = useField<string | null>(p.name);

  const handleChange = useCallback((value: string) => {
    helpers.setValue(value);
    helpers.setTouched(value !== f.initialValue);
  }, []);

  return <DateInput {...p} value={field.value} onChange={handleChange} />;
}
