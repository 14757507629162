import { Button } from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import { dumpSurveyData } from 'src/components/surveys/SurveyData';
import SurveyLanguages from 'src/components/surveys/SurveyLanguages';
import { useDownloadPdf } from 'src/hooks/quotation';
import { download } from 'src/utils/accessors';
import { useGraphQLFetch } from 'src/utils/graphql';

export default function SurveyHeaderElement(p: { surveyId: string }) {
  const graphqlFetch = useGraphQLFetch();
  const [loading, setLoading] = useState(false);

  const handleDump = useCallback(() => {
    setLoading(true);
    dumpSurveyData(p.surveyId, graphqlFetch)
      .then((res) => {
        download('dump.csv', res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [p.surveyId]);

  return (
    <div className="flex items-center">
      <SurveyLanguages surveyId={p.surveyId} />
      <Button
        icon="cloud-download"
        text="Dump Data"
        loading={loading}
        minimal
        onClick={handleDump}
      />
    </div>
  );
}
