import React from "react";
import { Tag, Intent, Icon, Tooltip } from "@blueprintjs/core";
import { useMemo } from "react";
import type { IconName } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

export interface TagDefinition<T> {
  value: T;
  label: string;
  icon?: IconName;
  intent?: Intent;
}

export interface EnumTagProps<T> {
  value: T;
  tooltip?: string;
  className?: string;
  minimal?: boolean;
  uppercase?: boolean;
  rightIcon?: IconName;
  items: TagDefinition<T>[];
}

export function EnumTag<T>(p: EnumTagProps<T>) {
  const currentTag = useMemo(() => p.items.find((i) => i.value === p.value), [
    p.value,
    p.items,
  ]);
  if (currentTag == null) {
    return null;
  }
  const { label, icon, ...props } = currentTag;
  const iconElement = icon != null ? <Icon icon={icon} iconSize={12} /> : null;
  const children = p.uppercase ? label.toUpperCase() : label;
  return (
    <Tag icon={iconElement} rightIcon={p.rightIcon} minimal={p.minimal} className={p.className} {...props}>
      {p.tooltip ? (
        <Tooltip2 content={p.tooltip} children={children} />
      ) : (
        children
      )}
    </Tag>
  );
}
