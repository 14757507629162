import React, { useState, useCallback } from 'react';
import css from 'csz';
import { Icon, Intent, Button, Classes, Tag } from '@blueprintjs/core';
import { useField, useFieldValue, useIsSubmitting, useValues } from 'formik';
import { useFieldError } from 'src/utils/formik';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

interface P<T extends { name: string }> {
  label: string;
  name: string;
  autosize?: boolean;
  hideReset?: boolean;
  centerLabel?: boolean;
  component?: (props: T) => JSX.Element;
  componentProps?: Omit<T, 'name'>;
  onReset?: () => void;
  children?: JSX.Element;
}

const className = css`/styles/editable-field.css`;

export function isLoading<T>(value: Loadable<T>): value is Loadable<T> {
  return '_loading' in value;
}
export function isLoaded<T>(value: Loadable<T>): value is T {
  return !('_loading' in value);
}

export type Loadable<T> = Partial<T> | { _loading: true };

export default function EditableField<T extends { name: string }>(p: P<T>) {
  const [field, meta, helpers] = useField(p.name);
  const [revision] = useFieldValue<number | null>('revision');

  const [__, error] = useFieldError(p.name);

  const isSubmitting = useIsSubmitting();
  const values = useValues<Loadable<any>>();

  const loading = isLoading(values);
  const Component = p.component as any;

  const componentProps = p.componentProps ?? {};
  const handleReset = useCallback(() => {
    helpers.setValue(meta.initialValue ?? null);
    helpers.setTouched(false);
  }, [meta.initialValue, p.name]);

  const touched =
    !isSubmitting && meta.touched && !isEqual(field.value, meta.initialValue);
  const children = p.children ?? (
    <Component name={p.name} {...componentProps} />
  );
  return (
    <div
      className={`${className} ${touched ? 'dirty' : ''} ${
        error != null ? 'error' : ''
      } ${p.autosize ? 'autosize' : ''} ${p.centerLabel ? 'centerLabel' : ''}`}
    >
      <label htmlFor={p.name} className="editable-field-label secondary-text">
        {p.label}
      </label>
      <div className={`flex-grow ${loading ? Classes.SKELETON : ''}`}>
        {children}
      </div>
      {error != null ? (
        <Tag
          icon={<Icon iconSize={12} icon="warning-sign" />}
          intent={Intent.WARNING}
          minimal={true}
        >
          {(typeof error === 'object'
            ? error[Object.keys(error)[0]]
            : error
          ).toUpperCase()}
        </Tag>
      ) : (
        <></>
      )}
      {p.hideReset !== true && (
        <div className="actions">
          <Button
            tabIndex={touched ? 0 : -1}
            icon="cross"
            className={Classes.SMALL}
            onClick={p.onReset ?? handleReset}
            minimal
          />
        </div>
      )}
    </div>
  );
}

export function FieldRow(p: {
  label: string | JSX.Element;
  helperText?: string;
  loading?: boolean;
  compact?: boolean;
  className?: string;
  children: JSX.Element | string;
}) {
  return (
    <div
      className={`${className} ${p.className} ${p.compact ? 'compact' : ''}`}
    >
      <label className="editable-field-label secondary-text">{p.label}</label>
      <div className="flex-grow flex flex-col">
        <div className={`${p.loading ? Classes.SKELETON : ''}`}>
          {p.children}
        </div>
        {p.helperText && (
          <div className="text-gray-400 italic text-sm">{p.helperText}</div>
        )}
      </div>
    </div>
  );
}

export function HourAndMinutesField(p: { minutes: number }) {
  const { t } = useTranslation('quotations');
  const totalHours = `${Math.floor(p.minutes / 60)}`;
  const totalMinutesLeft = `${Math.round(p.minutes % 60)}`;
  return (
    <p className="font-extralight text-sm text-justify">
      {totalHours}&nbsp;{t(totalHours == '1' ? 'hour and' : 'hours and')}
      &nbsp;
      {totalMinutesLeft}&nbsp;
      {t(totalMinutesLeft == '1' ? 'minute' : 'minutes')}.
    </p>
  );
}

export function SubField(p: { label: string; children: JSX.Element }) {
  return (
    <div className="group flex flex-col">
      <div className="text-xs font-semibold px-1 text-gray-500 group-hover:text-primary">
        {p.label}
      </div>
      {p.children}
    </div>
  );
}
