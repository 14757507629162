import { Button, Dialog, Intent, NumericInput } from '@blueprintjs/core';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useSendCommand } from 'src/utils/events';

export default function DialogCreditsGesture(p: {
  isOpen: boolean;
  onClose: () => void;
  type: 'accredit' | 'charge';
  userId: string;
}) {
  const [currentValue, setCurrentValue] = useState(0);

  const queryClient = useQueryClient();

  const [{}, earnCredits] = useSendCommand(
    'mutation ($id: String!,$amount: Int!){earnCredits(id: $id, amount: $amount)}',
    (evt, ctx) => {
      if (evt.type === 'CREDITS_EARNED') {
        ctx.showSuccessNotification({
          message: 'Credit successful',
        });
        ctx.complete();
        p.onClose();
      } else {
        ctx.showFailureNotification({
          message: 'Credit failed',
        });
        ctx.complete();
        p.onClose();
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['getUserBalance', p.userId]);
        queryClient.invalidateQueries(['retrieveTransactions', p.userId]);
      },
    }
  );

  const [{}, useCredits] = useSendCommand(
    'mutation ($id: String!,$amount: Int!){useCredits(id: $id, amount: $amount)}',
    (evt, ctx) => {
      if (evt.type === 'CREDITS_USED') {
        ctx.showSuccessNotification({
          message: 'Charge successful',
        });
        ctx.complete();
        p.onClose();
      } else {
        ctx.showFailureNotification({
          message: 'Charge failed',
        });
        ctx.complete();
        p.onClose();
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['getUserBalance', p.userId]);
        queryClient.invalidateQueries(['retrieveTransactions', p.userId]);
      },
    }
  );

  return (
    <Dialog
      isOpen={p.isOpen}
      onClose={p.onClose}
      canEscapeKeyClose
      canOutsideClickClose
      title={
        p.type === 'accredit'
          ? 'Create an accredit transaction'
          : 'Create a charge transaction'
      }
    >
      <div className="flex justify-center flex-col items-center mt-6">
        <p
          className={classNames(
            `text-3xl mb-6`,
            p.type === 'charge' ? 'text-red-500' : 'text-green-600'
          )}
        >
          {p.type === 'charge' ? '-' : '+'}
          {currentValue} Credits
        </p>
        <NumericInput
          defaultValue={currentValue}
          placeholder={'Credits Amount'}
          min={0}
          onValueChange={debounce((value) => setCurrentValue(value), 300)}
        />
        <Button
          className="p-1 mt-3"
          intent={p.type === 'accredit' ? Intent.PRIMARY : Intent.DANGER}
          onClick={() =>
            p.type === 'accredit'
              ? earnCredits({ id: p.userId, amount: currentValue })
              : useCredits({ id: p.userId, amount: currentValue })
          }
        >
          {p.type === 'accredit' ? 'Accredit' : 'Charge'}
        </Button>
      </div>
    </Dialog>
  );
}
