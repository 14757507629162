import { Tag, Intent } from '@blueprintjs/core';
import { isEmpty, last, flatten } from 'lodash';
import React from 'react';
import {
  Question,
  SurveyQuestion,
  SurveyQuestionInput,
} from 'src/apps/athena/gql-types';
import { EditableTextField } from '../BlueprintFields';

export function QuestionCardBody(p: {
  question: Question;
  preview?: boolean;
  field?: string;
}) {
  const { body } = p.question;
  const regex = /\{\{[A-z0-9]*\}\}/g;
  const matches: string[] = body.match(regex) ?? [];
  const indexes: [number, string, number][] = matches.map((d) => [
    body.indexOf(d),
    d.replaceAll('{', '').replaceAll('}', ''),
    body.indexOf(d) + d.length,
  ]);

  const lastIndex = isEmpty(indexes) ? 0 : last(indexes)[2];

  const elements = [
    ...flatten(
      indexes.map(([start, needle], index) => {
        const startIndex = index === 0 ? 0 : indexes[index - 1][2];
        const endIndex = index === 0 ? start : indexes[index][0];
        return [
          <p>{body.slice(startIndex, endIndex)}</p>,
          <QuestionCardBodyVariable
            question={p.question}
            preview={p.preview}
            name={needle}
            field={p.field != null ? `${p.field}.variables.${needle}` : null}
          />,
        ];
      })
    ),
    <p>{body.slice(lastIndex)}</p>,
  ];

  return <p className="flex items-center">{elements}</p>;
}

function QuestionCardBodyVariable(p: {
  question: Question;
  name: string;
  preview?: boolean;
  field?: string;
}) {
  const name = p.field ?? '';
  const editing = !isEmpty(p.field);
  return editing ? (
    <Tag className="mx-1" minimal intent={Intent.PRIMARY}>
      <EditableTextField
        disabled={p.preview}
        placeholder={p.name}
        name={name}
      />
    </Tag>
  ) : (
    <p className="mx-1 italic text-primary text-opacity-80 font-medium">
      {p.name}
    </p>
  );
}
