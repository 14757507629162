import { Expander } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useGraphQLFetch } from 'src/utils/graphql';
import type { Event, Query } from '../apps/athena/gql-types';

const QUERY =
  'query RetrieveEvents($resource: String!) { eventsForResource(resource: $resource, limit: 100) { items { id type timestamp user { id name } data }  pageInfo { endCursor total } } } ';

export function useEventsByResource(resource: string) {
  const graphqlFetch = useGraphQLFetch<
    Pick<Query, 'eventsForResource'>,
    { resource: string }
  >();
  return useQuery(['events', resource], () =>
    graphqlFetch(QUERY, { resource })
  );
}

export function EventsByResource(p: { resource: string }) {
  const { data } = useEventsByResource(p.resource);
  return (
    <div className="flex flex-col flex-grow py-4 ml-1">
      {data?.eventsForResource?.items?.map((event) => (
        <div className=" bg-white mb-3 border rounded-md">
          <div className="px-3 py-1 text-sm bg-gray-50 flex border-b rounded-t-md">
            <div className="flex-grow primary-text font-medium">
              {event.user?.name ?? 'Athena'}
            </div>

            <Expander />
            <div className="px-1 text-gray-400">
              {DateTime.fromISO(event.timestamp).toRelative()}
            </div>
          </div>
          <div className="py-2 px-3 text-sm">
            <span className="italic text-gray-500">{previewEvent(event)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

function Grayish(p: { children: JSX.Element | JSX.Element[] | string }) {
  return <span className="font-medium text-gray-800">{p.children}</span>;
}

function Primary(p: { children: JSX.Element | JSX.Element[] | string }) {
  return <span className="font-semibold primary-text">{p.children}</span>;
}

function previewEvent(event: Event): JSX.Element | string {
  const renewal = event.data?.renewal;
  const number = event.data?.number;
  switch (event.type) {
    case 'CERTIFICATE_ISSUED':
      return (
        <span>
          ha <Grayish>emesso</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'CERTIFICATE_REMOVED':
      return (
        <span>
          ha <Grayish>rimosso</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'INVOICE_RECEIVED_MARKED':
      return (
        <span>
          ha segnato <Grayish>l'emissione</Grayish> della fattura{' '}
          <Primary>{event.data?.invoiceNumber}</Primary> del{' '}
          <Primary>{renewal != null ? renewal : 'prossimo'} rinnovo</Primary>
        </span>
      );
    case 'CERTIFICATE_ISSUE_DATE_CHANGED':
      return (
        <span>
          ha cambiato la <Grayish>data di emissione</Grayish> in{' '}
          <Primary>
            {DateTime.fromISO(event.data?.issueDate).toLocaleString(
              DateTime.DATE_MED
            )}
          </Primary>
        </span>
      );
    case 'CERTIFICATE_PARTNER_  CHANGED':
      return <span>ha cambiato il partner'</span>;
    case 'PAYMENT_RECEIVED_MARKED':
      return (
        <span>
          ha impostato il <Grayish>pagamento</Grayish> ricevuto{' '}
          <Primary>{renewal != null ? renewal : 'prossimo'} rinnovo</Primary>
        </span>
      );
    case 'PAYMENT_RECEIVED_UNMARKED':
      return (
        <span>
          ha rimosso il <Grayish>pagamento</Grayish>{' '}
          <Primary>{renewal != null ? renewal : 'prossimo'} rinnovo</Primary>
        </span>
      );
    case 'DOCUMENT_RECEIVED_MARKED':
      return (
        <span>
          ha impostato la <Grayish>documentazione</Grayish> ricevuta{' '}
          <Primary>{renewal != null ? renewal : 'prossimo'} rinnovo</Primary>
        </span>
      );
    case 'DOCUMENT_RECEIVED_UNMARKED':
      return (
        <span>
          ha rimosso la <Grayish>documentazione</Grayish>{' '}
          <Primary>{renewal != null ? renewal : 'prossimo'} rinnovo</Primary>
        </span>
      );
    case 'CERTIFICATE_SUSPENDED':
      return (
        <span>
          ha <Grayish>sospeso</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'CERTIFICATE_RESTORED':
      return (
        <span>
          ha <Grayish>attivato</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'CERTIFICATE_REVOKED':
      return (
        <span>
          ha <Grayish>revocato</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'CERTIFICATE_CANCELLED':
      return (
        <span>
          ha <Grayish>cancellato</Grayish> il certificato{' '}
          <Primary>{number}</Primary>
        </span>
      );
    case 'CERTIFICATE_TRANSFERRED':
      return (
        <span>
          ha <Grayish>trasferito</Grayish> il certificato
        </span>
      );
    case 'CERTIFICATE_MANUAL_PRICE_SET':
      return (
        <span>
          ha <Grayish>impostato</Grayish> il prezzo manuale'
        </span>
      );
  }

  return event.type;
}
