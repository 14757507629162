import { NonIdealState, Spinner } from '@blueprintjs/core';
import React from 'react';
import { Node } from 'react-flow-renderer';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';
import { Block, BlockStatus, PreviewDataType } from '@keix/workflow-types';
import { getDataType } from 'src/hooks/workflow/utils';
import { useSize } from 'src/utils/size';
import { BlockOutputPreviewer } from './BlockOutputPreviewer';
import { useWorkflowContext } from './WorkflowContext';

export default function BlockPreview(p: { node: Node<Block> }) {
  const { data } = p.node;
  const { state, returnType, parameters } = data;
  const { dataTypes } = useLibraryContext();
  const [size, ref] = useSize();

  if (state.status === BlockStatus.FAILED) {
    return (
      <NonIdealState
        icon="error"
        title="Block Failed"
        description={state.error}
      />
    );
  }
  if (state.status === BlockStatus.RUNNING) {
    return (
      <NonIdealState
        icon="play"
        title="Block Running..."
        description="The workflow is running, please wait until the block data is available."
      />
    );
  }
  if (state.status === BlockStatus.IDLE) {
    return (
      <NonIdealState
        icon="pause"
        title="Block not runned"
        description="Run the workflow to preview this block data."
      />
    );
  }
  if (returnType == null) {
    return (
      <NonIdealState
        icon="saved"
        title="Block output empty"
        description="This block does not provide an output."
      />
    );
  }
  return (
    <div ref={ref} className="flex-grow">
      <BlockOutputPreviewer
        node={p.node}
        size={size}
        type={PreviewDataType.SMALL}
      />
    </div>
  );
}
