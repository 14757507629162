import React, { useEffect, useRef, useCallback } from 'react';
import Editor, { Monaco, OnChange } from '@monaco-editor/react';
import { useField, useFormik } from 'formik';

interface MonacoProps {
  width?: number;
  height?: number;
  language: string;
  value: string;
  onChange: OnChange;
  onBlur?: () => void;
}

interface MonacoFieldProps
  extends Omit<MonacoProps, 'value' | 'onChange' | 'onBlur'> {
  name: string;
}

export function MonacoEditorField(p: MonacoFieldProps) {
  const [field, _, { setValue }] = useField(p.name);
  const handleChange = useCallback(
    (val: string) => {
      setValue(val);
    },
    [p.name]
  );
  return <MonacoEditor {...p} value={field.value} onChange={handleChange} />;
}

export default function MonacoEditor(p: MonacoProps) {
  return (
    <Editor
      width={p.width}
      height={p.height}
      language={p.language}
      value={p.value}
      onChange={p.onChange}
    />
  );

  // // Some refs.

  // const ref = useRef();
  // const editor = useRef<monaco.editor.IStandaloneCodeEditor>();

  // // Create and dispose the editor.
  // useEffect(() => {
  //   editor.current = monaco.editor.create(ref.current, {
  //     value,
  //     language,
  //   });

  //   return () => {
  //     editor.current?.dispose();
  //     editor.current?.getModel()?.dispose();
  //     editor.current = null;
  //   };
  // }, []);

  // useEffect(() => {
  //   const model = editor.current?.getModel();
  //   if (
  //     model != null &&
  //     value != null &&
  //     value !== editor.current?.getValue()
  //   ) {
  //     editor?.current.pushUndoStop();
  //     model.pushEditOperations(
  //       [],
  //       [
  //         {
  //           range: model.getFullModelRange(),
  //           text: value,
  //         },
  //       ],
  //       null
  //     );
  //     editor.current.pushUndoStop();
  //   }
  // }, [value]);

  // useEffect(() => {
  //   editor.current?.layout();
  // }, [width, height]);

  // useEffect(() => {
  //   const didChangeSubscription = editor.current?.onDidChangeModelContent(
  //     (event) => {
  //       p.onChange(editor.current?.getValue(), event);
  //     }
  //   );

  //   const didBlurSubscription = editor.current?.onDidBlurEditorText(() =>
  //     p.onBlur != null ? p.onBlur() : null
  //   );

  //   return () => {
  //     didChangeSubscription.dispose();
  //     didBlurSubscription.dispose();
  //   };
  // }, [ref, p.onChange]);

  // return <div ref={ref} style={{ width, height }}></div>;
}
