import React from 'react';
import { useState } from 'react';
import { useHotkey } from 'src/context/HotkeysContext';

export function DebugView(p: {
  className?: string;
  children: JSX.Element | JSX.Element[];
}) {
  const [isVisible, setVisible] = useState(false);

  useHotkey({
    combo: 'ctrl+alt+cmd+space',
    label: 'Show Debug',
    onKeyDown: () => {
      setVisible((v) => !v);
    },
  });

  return isVisible ? (
    <div className={`${p.className} p-3`}>{p.children}</div>
  ) : (
    <React.Fragment />
  );
}
