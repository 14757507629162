import { Tag } from '@blueprintjs/core';
import React from 'react';
import { Result } from 'src/utils/diffV2';

export type DiffState<T = {}> = Result<T>[];

export function DiffDebug<T = {}>(p: { diff?: DiffState<T> }) {
  return (
    <div className="flex flex-col">
      {p.diff?.map((item, index) => (
        <div key={index} className="flex items-center">
          <div className="font-semibold text-blue-300">{item.command}</div>
          <div className="text-gray-600 italic break-words px-2">
            {JSON.stringify(item.data)}
          </div>
        </div>
      ))}
    </div>
  );
}
