import { Person } from 'src/apps/athena/gql-types';
import {
  combine,
  CustomizeColumns,
  hideColumn,
  removeColumn,
  showColumn,
} from 'src/hooks/columns';

const customize: CustomizeColumns<Person> = combine(
  removeColumn('certificates'),
  showColumn('projects'),
  hideColumn('employer'),
  hideColumn('fiscalCode'),
  showColumn('age')
);

export default customize;
