import { LibraryBlockArgument } from '@keix/workflow-types';
import { isArray, set, uniq, update } from 'lodash/fp';
import { WorkflowState } from '../../workflow/types';
import { WorkflowActions } from '..';
import { getNodeIndexById } from '../../workflow/WorkflowContext';

export enum WorkflowParamsActionType {
  SET_NODE_PARAMETER_VALUE = 'SET_NODE_PARAMETER_VALUE',
  ADD_NODE_PARAMETER = 'ADD_NODE_PARAMETER',
  REMOVE_NODE_PARAMETER = 'REMOVE_NODE_PARAMETER',
  EDIT_NODE_ARGUMENT = 'EDIT_NODE_ARGUMENT',
  SET_NODE_ARGUMENT_PREFERRED_TYPE = 'SET_NODE_ARGUMENT_PREFERRED_TYPE',
}

export type WorkflowParamsActions =
  | {
      type: WorkflowParamsActionType.ADD_NODE_PARAMETER;
      id: string;
    }
  | {
      type: WorkflowParamsActionType.REMOVE_NODE_PARAMETER;
      id: string;
      name: string;
    }
  | {
      type: WorkflowParamsActionType.SET_NODE_PARAMETER_VALUE;
      id: string;
      name: string;
      value: any;
    }
  | {
      type: WorkflowParamsActionType.EDIT_NODE_ARGUMENT;
      id: string;
      argument: LibraryBlockArgument;
    }
  | {
      type: WorkflowParamsActionType.SET_NODE_ARGUMENT_PREFERRED_TYPE;
      id: string;
      name: string;
      dataType: string;
    };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowParamsActionType.ADD_NODE_PARAMETER: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return update(
        `nodes.${nodeIndex}.data.arguments`,
        (args: LibraryBlockArgument[]) => [
          ...args,
          {
            name: `arg-${args.length}`,
            type: 'python.string',
          },
        ]
      )(prev);
    }

    case WorkflowParamsActionType.REMOVE_NODE_PARAMETER: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return update(
        `nodes.${nodeIndex}.data.arguments`,
        (f: LibraryBlockArgument[]) => f.filter((a) => a.name !== action.name)
      )(prev);
    }

    case WorkflowParamsActionType.SET_NODE_ARGUMENT_PREFERRED_TYPE: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return update(
        `nodes.${nodeIndex}.data.arguments`,
        (args: LibraryBlockArgument[]) =>
          args.map((arg) => {
            const { name, type } = arg;
            if (name === action.name && isArray(type)) {
              return { ...arg, type: uniq([action.dataType, ...type]) };
            } else {
              return arg;
            }
          })
      )(prev);
    }

    case WorkflowParamsActionType.EDIT_NODE_ARGUMENT: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return update(
        `nodes.${nodeIndex}.data.arguments`,
        (args: LibraryBlockArgument[]) =>
          args.map((arg) => {
            if (arg.name === action.argument.name) {
              return action.argument;
            } else {
              return arg;
            }
          })
      )(prev);
    }
    case WorkflowParamsActionType.SET_NODE_PARAMETER_VALUE: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return set(
        `nodes.${nodeIndex}.data.parameters.${action.name}`,
        action.value
      )(prev);
    }
  }
  return prev;
}
