import { Block } from '@keix/workflow-types';
import { Dictionary } from 'lodash';
import { Dispatch } from 'react';
import { Node, Edge } from 'react-flow-renderer';
import { Size } from 'src/utils/size';

export enum WorkflowView {
  CANVAS,
  DASHBOARD,
}

export interface WorkflowContext {
  state: WorkflowState;
  dispatch: Dispatch<any>;
}

export interface XYPosition {
  x: number;
  y: number;
}

export interface ViewportState {
  size: Size;
  offset: XYPosition;
  center: XYPosition;
  cursor: XYPosition;
}

export interface WorkflowState {
  id: string;
  view: WorkflowView;
  name: string;
  nodes: Node<Block>[];
  nodeIndexById: Dictionary<number>;
  edges: Edge[];
  viewport: ViewportState;
  drawer: DrawerState;
  libraryDialog: {
    isOpen: boolean;
  };
  showAddDialog: boolean;
  isRunning: boolean;
  isReady: boolean;
  minimalLayout: boolean;
  selectedNodeIds: string[];
  subselectionNodeIndex: number;
}

export interface DrawerState {
  isOpen: boolean;
  width: number;
}
