import React from 'react';
import { useSize } from 'src/utils/size';

export function ScrollView(p: {
  className?: string;
  children: JSX.Element | JSX.Element[];
}) {
  const [size, ref] = useSize(true);
  return (
    <div ref={ref} className="flex-grow relative">
      <div
        className={`flex flex-col overflow-y-scroll absolute top-0 left-0 right-0 bottom-0 ${
          p.className ?? ''
        }`}
        style={{
          height: size.height,
          maxHeight: size.height,
          width: size.width,
        }}
      >
        {p.children}
      </div>
    </div>
  );
}
