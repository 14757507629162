import { Button, EditableText, Icon, Intent } from '@blueprintjs/core';
import { useField, useFieldValue, useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionType,
  QuestionOption,
  Question,
} from 'src/apps/athena/gql-types';
import { useFieldArray } from 'src/utils/formik';
import { v4 } from 'src/utils/uuid';
import { Accordion } from '../Accordion';
import { EditableTextField } from '../BlueprintFields';
import { QuestionListField } from './QuestionListField';

export function ShowQuestionOptions(p: {
  question: Question;
  className?: string;
  editing?: boolean;
  galleryMode?: boolean;
}) {
  const { options } = p.question;
  return !isEmpty(options) ? (
    <Accordion className={'options-accordion flex-grow'} title="Show Answers">
      <QuestionOptions {...p} />
    </Accordion>
  ) : (
    <React.Fragment />
  );
}

function QuestionOptionRow(p: {
  question: Question;
  editing?: boolean;
  option?: QuestionOption;
}) {
  const { t } = useTranslation('survey');
  const opt = p.option;
  return (
    <div className="flex items-center">
      <Icon
        icon={p.question.settings?.allowMultipleChoices ? 'square' : 'circle'}
        iconSize={12}
        className="mx-2"
      />
      <span className="secondary-text">
        {opt != null ? (
          <EditableText
            maxLines={4}
            defaultValue={opt?.body}
            disabled={!p.editing}
          />
        ) : (
          <>{t('Other')}</>
        )}
      </span>
    </div>
  );
}

function getOptionsTemplate(options?: QuestionOption[]): string | null {
  if (options?.length === 1) {
    const regex = /\{\{[A-z0-9]*\}\}/g;
    const matches: string[] = options![0].body.match(regex) ?? [];
    return matches[0]?.replaceAll('{{', '')?.replaceAll('}}', '');
  }
  return null;
}

export function QuestionOptions(p: {
  question: Question;
  className?: string;
  field?: string;
  editing?: boolean;
  galleryMode?: boolean;
}) {
  const { t } = useTranslation('survey');

  const { question, editing } = p;
  let { options } = question;
  const needle = getOptionsTemplate(p.question.options) ?? 'empty';

  const enableOtherOption = p.question.settings?.enableOtherOption ?? false;

  const form = useFormikContext();
  const value = get(form?.values ?? {}, `${p.field}.variables.${needle}`);

  if (!isEmpty(value)) {
    options = value.map((s: string) => ({
      id: s,
      body: s,
    }));
  }

  // Do not display anything if empty options and not editing.
  if (isEmpty(options) && !editing) {
    return <React.Fragment />;
  }

  return (
    <div className="flex flex-column border-t mt-1 pt-1">
      {options?.map((opt) => (
        <QuestionOptionRow
          key={opt.id}
          option={opt}
          question={p.question}
          editing={p.editing}
        />
      ))}
      {enableOtherOption ? (
        <QuestionOptionRow question={p.question} editing={p.editing} />
      ) : (
        <React.Fragment />
      )}
      {editing && (
        <div className="question-option">
          <Icon icon="circle" iconSize={12} />
          <EditableText
            disabled={!editing}
            placeholder={t('Type to add a new option...')}
          />
        </div>
      )}
    </div>
  );
}

export function QuestionOptionsField(p: {
  currentLanguage: string | null;
  isEditingTranslations: boolean;
}) {
  const [typeValue] = useFieldValue('type');
  const type: QuestionType = typeValue;
  const { t } = useTranslation('survey');

  if (
    type == null ||
    type === QuestionType.MultipleChoice ||
    type === QuestionType.Ranking ||
    type === QuestionType.Percentage ||
    type === QuestionType.MultipleText
  ) {
    return (
      <QuestionListField
        isEditingTranslations={p.isEditingTranslations}
        name={
          p.isEditingTranslations
            ? `translations.${p.currentLanguage}.options`
            : 'options'
        }
        placeholder={t('Type to add a new option...')}
      />
    );
  }

  return <React.Fragment />;
}
