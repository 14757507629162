import {
  Button,
  Checkbox,
  HTMLSelect,
  InputGroup,
  Keys,
  Icon,
  NumericInput,
  TagInput,
  Switch,
  IconName,
} from '@blueprintjs/core';
import { DatePicker, DateTimePicker } from '@blueprintjs/datetime';
import { Select2 } from '@blueprintjs/select';
import { isArray, isEmpty, isString, keyBy } from 'lodash';
import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { menuItemRenderer } from 'src/components/BlueprintFields';
import {
  DataTypeComponentProps,
  DataTypeDefinition,
  DataTypePredefinedValue,
  PreviewDataType,
} from '@keix/workflow-types';
import { dataTypes as pandasDataTypes } from './pandas';
import { dataTypes as chartDataTypes } from './chart';
import { dataTypes as codeDataTypes } from './code';

export const dataTypes: DataTypeDefinition[] = [
  {
    name: 'string',
    uri: 'python.string',
    icon: 'bold',
    description: 'A Python string',
    build: (v: string) => v.indexOf("\"") >= 0 ? `""" ${v} """` : `"${v}"`,
    preview: (proxy) => {
      return proxy != null ? <>{proxy}</> : null;
    },
    inputComponent: ({
      type,
      value,
      onChange,
    }: DataTypeComponentProps<string>) => {
      if (isEmpty(type.predefinedValues)) {
        return (
          <InputGroup
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        );
      } else {
        const items = keyBy(type.predefinedValues, 'value');
        const predefinedValue = items[value] ?? null;
        return (
          <Select2<DataTypePredefinedValue>
            items={type.predefinedValues}
            onItemSelect={(e) => onChange(e.value)}
            itemRenderer={menuItemRenderer}
          >
            <Button
              icon={predefinedValue?.icon as IconName}
              text={
                predefinedValue?.label ??
                predefinedValue?.value ??
                'Select Data Type..'
              }
              rightIcon="double-caret-vertical"
            />
          </Select2>
        );
      }
    },
  },
  {
    name: 'int',
    uri: 'python.int',
    icon: 'numerical',
    extends: 'python.float',
    description: 'A Python numeric integer',
    inputComponent: (props: DataTypeComponentProps<number>) => (
      <NumericBlurInput {...props} kind="int" />
    ),
  },
  {
    name: 'float',
    uri: 'python.float',
    icon: 'numerical',
    description: 'A Python numeric float',
    preview: (proxy) => {
      return proxy != null ? <>{String(proxy)}</> : null;
    },
    inputComponent: (props: DataTypeComponentProps<number>) => (
      <NumericBlurInput {...props} kind="float" />
    ),
  },
  {
    name: 'bool',
    uri: 'python.bool',
    icon: 'tick',
    description: 'A Python boolean',
    build: (v: string | boolean) => {
      if (isString(v)) {
        return v === "true" ? 'True' : 'False';
      } else {
        return v ? 'True' : 'False'
      }

    },
    inputComponent: ({ value, onChange }: DataTypeComponentProps<boolean>) => {
      return (
        <Switch
          checked={value}
          onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
        />
      );
    },
  },
  {
    name: 'date',
    uri: 'python.datetime.date',
    icon: 'calendar',
    description: 'A Python date',
    preview: (proxy, { type }) => {
      if ('isoformat' in proxy) {
        const date = new Date((proxy as any).isoformat());
        if (type === PreviewDataType.MICRO) {
          return date.toLocaleDateString();
        } else {
          return <DatePicker value={date} />;
        }
      } else {
        return null;
      }
    },
  },
  {
    name: 'datetime',
    uri: 'python.datetime.datetime',
    icon: 'calendar',
    description: 'A Python date and time',
    extends: 'python.datetime.date',
    preview: (proxy: any, { type }) => {
      const date = new Date((proxy as any).isoformat());
      if (type === PreviewDataType.MICRO) {
        return date.toLocaleString();
      } else {
        const date = new Date((proxy as any).isoformat());
        return <DatePicker timePrecision="second" value={date} />;
      }
    },
  },
  {
    name: 'ndarray',
    uri: 'numpy.ndarray',
    icon: 'array-numeric',
    description: 'A Numpy array',
    preview: (proxy: any, { type }) => {
      return <NumpyPreview proxy={proxy} type={type} />;
    },
  },
  {
    name: 'string_array',
    uri: 'python.string_array',
    icon: 'array-string',
    description: 'An array of strings',
    build: (v: string[]) =>
      isArray(v) ? `[${v.map((d) => `"${d}"`).join(',')}]` : '[]',
    inputComponent: ({ value, onChange }: DataTypeComponentProps<string[]>) => {
      const val = isArray(value) ? value : [];
      return <TagInput values={val} onChange={onChange} />;
    },
  },
  ...pandasDataTypes,
  ...chartDataTypes,
  ...codeDataTypes,
];

function DataframePreview(p: { proxy: any; type: PreviewDataType }) {
  const { proxy, type } = p;

  if (proxy.shape == null) {
    return <React.Fragment />;
  }

  // if (false && type === PreviewDataType.MICRO) {
  return (
    <span className="flex items-center">
      <span>
        [ {proxy.shape.get(0)} x {proxy.shape.get(1)} ]
      </span>
    </span>
  );
}

function DataframeExtendedPreview(p: { proxy: any }) {
  const parentRef = React.useRef();
  const { proxy } = p;
  const values = 'values' in proxy ? proxy.values.tolist().toJs({ create_proxies: false }) : [];
  const columns = proxy.shape != null ? proxy.shape.get(1) : 0;
  const rowVirtualizer = useVirtualizer({
    count: proxy.shape != null ? proxy.shape.get(0) : 0,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 35, []),
  });
  const columnsVirtualizer = useVirtualizer({
    horizontal: true,
    count: columns,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 200, []),
  });
  return (
    <div
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        width: `${columnsVirtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
      ref={parentRef}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
        <React.Fragment key={virtualRow.index}>
          {columnsVirtualizer.getVirtualItems().map((virtualColumn) => (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${virtualColumn.size}px`,
                height: `${virtualRow.size}px`,
                transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
              }}
            >
              {values?.[virtualRow.index]?.[virtualColumn.index]}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

function NumpyPreview(p: { proxy: any; type: PreviewDataType }) {
  const { proxy, type } = p;
  const parentRef = React.useRef();
  const values = 'tolist' in proxy ? proxy.tolist() : [];

  const rowVirtualizer = useVirtualizer({
    count: values.length,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 35, []),
  });

  if (type === PreviewDataType.MICRO) {
    return (
      <span className="flex items-center">
        <span>[ {proxy.shape} ]</span>
      </span>
    );
  } else {
    return (
      <div ref={parentRef} className="h-64 w-24 overflow-auto">
        <div
          className="w-full relative divide-y"
          style={{ height: rowVirtualizer.totalSize }}
        >
          {rowVirtualizer.getVirtualItems().map((row) => (
            <div
              key={row.key}
              className="absolute top-0 left-0 w-full border-b"
              style={{
                height: `${row.size}px`,
                transform: `translateY(${row.start}px)`,
              }}
            >
              {row.index} - {values[row.index]}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function SeriesPreview(p: { proxy: any; type: PreviewDataType }) {
  const { proxy, type } = p;
  const parentRef = React.useRef();
  const values = 'values' in proxy ? proxy.values.tolist().toJs({ create_proxies: false }) : [];
  const indexes = 'index' in proxy ? proxy.index.tolist().toJs({ create_proxies: false }) : [];

  const rowVirtualizer = useVirtualizer({
    count: values.length,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 35, []),
  });

  if (type === PreviewDataType.MICRO) {
    return (
      <span className="flex items-center">
        <span>[ {proxy.shape} ]</span>
      </span>
    );
  } else {
    return (
      <div className="flex flex-col">
        <div className="border-b bg-gray-50 text-primary font-medium py-1 text-center">
          pandas.series
        </div>
        <div ref={parentRef} className="h-64 w-48 overflow-auto">
          <div
            className="w-full relative divide-y"
            style={{ height: rowVirtualizer.totalSize }}
          >
            {rowVirtualizer.getVirtualItems().map((row) => (
              <div
                key={row.key}
                className="absolute top-0 left-0 w-full flex py-2 px-4"
                style={{
                  height: `${row.size}px`,
                  transform: `translateY(${row.start}px)`,
                }}
              >
                <div className="w-24 text-gray-400">{indexes[row.index]}</div>
                <div className="text-gray-600 font-medium">
                  {values[row.index]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function NumericBlurInput(
  p: DataTypeComponentProps<number> & { kind: 'int' | 'float' }
) {
  const handleBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    const rawValue = (e.target as HTMLInputElement).value;
    const val = p.kind === 'int' ? parseInt(rawValue) : parseFloat(rawValue);
    p.onChange(val);
  };
  return (
    <NumericInput
      value={p.value}
      onValueChange={(v) => p.onChange(v)}
      onBlur={handleBlur}
      onKeyDown={(e) => (e.keyCode === Keys.ENTER ? handleBlur(e) : null)}
    />
  );
}
