import React, {
  useState,
  useCallback,
  useMemo,
  MouseEventHandler,
  MouseEvent,
} from 'react';
import {
  Query,
  Question,
  QuestionOption,
  QuestionInput,
  QuestionType,
  SurveyQuestion,
} from 'src/apps/athena/gql-types';
import {
  Icon,
  InputGroup,
  Spinner,
  Classes,
  Button,
  Intent,
  EditableText,
  Dialog,
  IconName,
  Tag,
  Menu,
  MenuItem,
  MenuDivider,
  Expander,
} from '@blueprintjs/core';
import css from 'csz';
import {
  EditableTextField,
  HTMLSelectField,
  SelectField,
  SelectOption,
} from 'src/components/BlueprintFields';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'src/components/Accordion';
import { compact, filter, flatten, isEmpty, last, times } from 'lodash';
import { useFormik, FormikProvider, useFieldValue, useField } from 'formik';
import { useDiffableFieldArray, useFieldArray } from 'src/utils/formik';
import { v4 } from 'src/utils/uuid';
import { string, array, object } from 'yup';
import { useSendCommand } from 'src/utils/events';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGraphQLFetch } from 'src/utils/graphql';
import { QUERY } from 'src/graphql/surveys';
import { Popover2 } from '@blueprintjs/popover2';
import { QuestionCard } from './QuestionCard';
import { iconForQuestionType } from 'src/utils/survey/icons';
import { QuestionOptionsField } from './QuestionOptions';
import { BottomBar } from '../BottomBar';
import { MasterDetail } from '../MasterDetail';
import { QuestionDetail } from './QuestionDetail';
import { ScrollView } from '../ScrollView';
import CenteredSpinner from '../CenteredSpinner';
const className = css`/styles/survey.css`;

export function QuestionsGalleryDialog(p: {
  isOpen: boolean;
  addedQuestions?: string[];
  onAddQuestion: (q: Question) => void;
  onClose: () => void;
}) {
  const { isOpen, onClose, ...props } = p;
  return (
    <Dialog
      className="large p-0"
      isOpen={isOpen}
      onClose={onClose}
      canEscapeKeyClose={false}
      icon="form"
      title="Questions Library"
    >
      <QuestionsGallery {...props} />
    </Dialog>
  );
}

export function EditQuestionDialog(p: {
  id: string;
  surveyQuestionId: string;
  onClose: () => void;
  isOpen: boolean;
}) {
  const { isOpen, onClose, surveyQuestionId, ...props } = p;
  const graphqlFetch = useGraphQLFetch();

  const [field, _, helpers] =
    useDiffableFieldArray<SurveyQuestion>('questions');

  const handleReplaceQuestion = useCallback(
    (question: Question) => {
      const index = field.value.findIndex((d) => d.id === surveyQuestionId);
      if (index >= 0) {
        helpers.replace(
          {
            ...question,
            id: surveyQuestionId,
            questionId: question.id,
            __typename: 'SurveyQuestion',
          },
          index
        );
      }
      p.onClose();
    },
    [field.value, surveyQuestionId, p.onClose]
  );

  const { data, isFetching } = useQuery<Pick<Query, 'question'>>(
    compact(['question', p.id]),
    () => graphqlFetch(QUERY.GET_QUESTION, { id: p.id })
  );

  return (
    <Dialog
      className="large p-0"
      isOpen={isOpen}
      onClose={onClose}
      canEscapeKeyClose={false}
      icon="edit"
      title="Edit Question"
    >
      <div className={classNames(className, 'p-3')}>
        {data?.question == null ? (
          <CenteredSpinner />
        ) : (
          <QuestionDetail
            onReplaceQuestion={handleReplaceQuestion}
            isNew={false}
            question={data?.question}
          />
        )}
      </div>
    </Dialog>
  );
}

export function QuestionsGallery(p: { onAddQuestion?: (q: Question) => void }) {
  const [query, setQuery] = useState('');
  const [showAddQuestion, setAddQuestion] = useState(false);
  const [parentQuestion, setParentQuestion] = useState(null);
  const [parentId, setParentId] = useState(null);
  const graphqlFetch = useGraphQLFetch();

  const queryKey = parentId == null ? 'questions' : 'questionVariants';
  const filters = useMemo(
    () =>
      parentId == null
        ? null
        : {
          op: 'or',
          filters: [
            {
              attributeName: 'parentId',
              filters: [{ op: '=', value: parentId }],
            },
            {
              attributeName: 'id',
              filters: [{ op: '=', value: parentId }],
            },
          ],
        },
    [parentId]
  );
  const { data, isFetching } = useQuery<Pick<Query, 'questions'>>(
    compact([queryKey, filter, query]),
    () => graphqlFetch(QUERY.RETRIEVE_QUESTIONS, { query, filters })
  );

  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );
  const questions = data?.questions?.items ?? [];

  return (
    <div className={classNames(className, 'flex flex-column h-full')}>
      <div className="flex items-center m-3">
        <InputGroup
          className="flex-grow"
          leftIcon="search"
          rightElement={isFetching && <Spinner size={15} />}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for a question..."
        />
      </div>
      <div className="flex-grow questions-gallery ">
        <MasterDetail
          masterRatio={0.4}
          card={false}
          master={
            <div className="flex flex-col h-full">
              <ScrollView className="gap-y-2">
                <>
                  {questions.map((question) => (
                    <QuestionCard
                      className="mx-3"
                      galleryMode={true}
                      selected={question.id === selectedQuestion?.id}
                      onClick={() => setSelectedQuestion(question)}
                      actionElement={
                        <QuestionActionElement
                          numberOfVariants={question.numberOfVariants}
                          onAddVariant={() => {
                            setAddQuestion(true);
                            setParentQuestion(question);
                          }}
                          onShowVariants={() => {
                            setParentId(question.id);
                          }}
                        />
                      }
                      key={question.id}
                      question={question}
                    />
                  ))}
                </>
                <Expander />
                <div className="sticky bottom-0 p-2 bg-white border-t">
                  <Button
                    minimal
                    intent={Intent.PRIMARY}
                    fill
                    icon="plus"
                    text="Add New Question"
                    onClick={() => {
                      setAddQuestion(true);
                      setSelectedQuestion(null);
                    }}
                  />
                </div>
              </ScrollView>
            </div>
          }
          detail={
            <QuestionDetail
              question={selectedQuestion}
              parentQuestion={parentQuestion}
              isNew={showAddQuestion}
              onSelectQuestion={(q) => {
                setAddQuestion(false);
                setSelectedQuestion(q);
              }}
              onAddQuestion={p.onAddQuestion}
            />
          }
        />
      </div>
    </div>
  );
}

function QuestionActionElement(p: {
  numberOfVariants: number;
  onAddVariant: () => void;
  onShowVariants: () => void;
}) {
  const menu = (
    <Menu>
      <MenuItem
        icon="duplicate"
        text="Crea Nuova Variante"
        onClick={p.onAddVariant}
      />
      {p.numberOfVariants > 0 && (
        <MenuItem icon="list" text="Mostra Varianti" />
      )}
      <MenuDivider />
      <MenuItem
        intent={Intent.PRIMARY}
        icon="plus"
        text="Aggiungi a questionario"
      />
    </Menu>
  );
  return (
    <div className="flex" onClick={(e) => e.stopPropagation()}>
      {p.numberOfVariants > 0 && (
        <Button rightIcon="duplicate" minimal small onClick={p.onShowVariants}>
          {p.numberOfVariants}
        </Button>
      )}
      <Popover2 content={menu}>
        <Button small icon="chevron-down" minimal />
      </Popover2>
    </div>
  );
}

type MasonryGalleryRenderer<T> = (p: { item: T; index: number }) => JSX.Element;

function MasonryGallery<T>(p: {
  columns: number;
  className?: string;
  items: T[];
  children: MasonryGalleryRenderer<T>;
}) {
  const { items, columns, children } = p;
  const cols = times(columns, (index) => {
    const indexes = times(items.length).filter((i) => i % columns === index);
    return (
      <div
        className="flex flex-column flex-grow space-y-2"
        style={{ width: 100 / columns }}
      >
        {indexes.map((i) => children({ item: items[i], index: i }))}
      </div>
    );
  });
  return (
    <div className={classNames('flex relative  space-x-2', p.className)}>
      {cols}
    </div>
  );
}
