import { Button, Card, Divider, FormGroup, Intent } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MUTATIONS } from 'src/graphql/surveys';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { useSendCommand } from 'src/utils/events';
import { Accordion } from '../Accordion';
import { CheckboxField, TextAreaField, TextField } from '../BlueprintFields';
import EditableField from '../EditableField';

function SurveyLabelsSection(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const [{ isLoading }, setSurveyLabels] = useSendCommand(
    MUTATIONS.SET_SURVEY_LABELS,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_LABELS_CHANGED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Survey Labels Set'),
        });
      }
    }
  );
  return (
    <Formik
      initialValues={data.survey?.labels ?? {}}
      enableReinitialize={true}
      onSubmit={(labels) => setSurveyLabels({ id: p.surveyId, labels })}
    >
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={
            <Button
              icon="tick"
              small
              minimal
              loading={isLoading}
              intent={Intent.PRIMARY}
              text="Save"
              type="submit"
            />
          }
          title={t('Survey Labels')}
        >
          <div className="p-2">
            <EditableField
              label={t('Title')}
              name="title"
              component={TextField}
            />
            <EditableField
              autosize={true}
              label={t('Intro Message')}
              name="introMessage"
              component={TextAreaField}
              componentProps={{ fill: true }}
            />
            <Divider />
            <EditableField
              label={t('Start Button')}
              name="startButton"
              component={TextField}
            />
            <EditableField
              label={t('Success Message')}
              name="thanksMessage"
              component={TextField}
            />
          </div>
        </Accordion>
      </Form>
    </Formik>
  );
}
function SurveyStyleSection(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const [{ isLoading }, setSurveyStyle] = useSendCommand(
    MUTATIONS.SET_SURVEY_STYLE,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_STYLE_CHANGED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Survey Style Set'),
        });
      }
    }
  );
  return (
    <Formik
      initialValues={data.survey?.style ?? {}}
      enableReinitialize={true}
      onSubmit={(style) => setSurveyStyle({ id: p.surveyId, style })}
    >
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={
            <Button
              icon="tick"
              small
              minimal
              loading={isLoading}
              intent={Intent.PRIMARY}
              text="Save"
              type="submit"
            />
          }
          title={t('Survey Style')}
        >
          <div className="p-2">
            <EditableField
              label="Intro Image"
              name="introImage"
              component={TextField}
            />
            <EditableField
              label="Logo Image"
              name="logoImage"
              component={TextField}
            />
            <EditableField
              label="Tint Color"
              name="tintColor"
              component={TextField}
            />
            <EditableField
              label="Hide Telegram"
              name="hideTelegram"
              component={CheckboxField}
            />
          </div>
        </Accordion>
      </Form>
    </Formik>
  );
}

export default function SurveyStyle(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const [{ isLoading }, setSurveyLabels] = useSendCommand(
    MUTATIONS.SET_SURVEY_LABELS,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_LABELS_CHANGED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Survey Labels Set'),
        });
      }
    }
  );

  return (
    <div className="flex flex-col flex-grow p-3">
      <SurveyLabelsSection {...p} />
      <SurveyStyleSection {...p} />
    </div>
  );
}
