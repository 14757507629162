import { QueryClient, QueryKey } from '@tanstack/react-query';
import { update } from 'lodash';

export function prependPaginatedQueryData<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  dataKey: string,
  item: T
) {
  queryClient.setQueryData(queryKey, (prev: any) => {
    return update(prev, `${dataKey}.items`, (items: T[]) => [item, ...items]);
  });
}
export function appendPaginatedQueryData<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  dataKey: string,
  item: T
) {
  queryClient.setQueryData(queryKey, (prev: any) => {
    return update(prev, `${dataKey}.items`, (items: T[]) => [...items, item]);
  });
}

export function updatePaginatedQueryData<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  dataKey: string,
  updater: (item: T) => T
) {
  queryClient.setQueryData(queryKey, (prev: any) => {
    return update(prev, `${dataKey}.items`, (items: T[]) => items.map(updater));
  });
}

export function deleteItemOnPaginatedQueryData<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  dataKey: string,
  condition: (item: T) => boolean
) {
  queryClient.setQueryData(queryKey, (prev: any) => {
    return update(prev, `${dataKey}.items`, (items: T[]) =>
      items.filter((v) => condition(v) === false)
    );
  });
}
