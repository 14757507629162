import { BlockLayout, LibraryBlock } from '@keix/workflow-types';
import { set, update } from 'lodash/fp';
import { applyEdgeChanges, EdgeChange, NodeChange } from 'react-flow-renderer';
import { createNode } from 'src/hooks/workflow/utils';
import { WorkflowState } from '../../workflow/types';
import changeNodes from '../../store/changeNodes';
import { Layout } from 'react-grid-layout';
import { getNodeIndexById } from '../../workflow/WorkflowContext';
import { WorkflowAction, WorkflowActions } from '..';

export enum WorkflowLayoutActionType {
  SET_NODE_LAYOUT = 'SET_NODE_LAYOUT',
  SET_NODES_LAYOUT = 'SET_NODES_LAYOUT',
}

export type WorkflowLayoutActions =
  | {
      type: WorkflowLayoutActionType.SET_NODE_LAYOUT;
      id: string;
      layout: BlockLayout | null;
    }
  | {
      type: WorkflowLayoutActionType.SET_NODES_LAYOUT;
      layout: Layout[];
    };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowLayoutActionType.SET_NODE_LAYOUT: {
      const nodeIndex = getNodeIndexById(prev, action.id);
      return set(`nodes.${nodeIndex}.data.layout`, action.layout)(prev);
    }
    case WorkflowLayoutActionType.SET_NODES_LAYOUT: {
      return action.layout.reduce((state, layout) => {
        const { i, x, y, w, h } = layout;
        const nodeIndex = getNodeIndexById(prev, i);
        return set(`nodes.${nodeIndex}.data.layout`, layout)(state);
      }, prev);
    }
  }
  return prev;
}
