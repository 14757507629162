import { set, uniq, uniqBy, update } from 'lodash';
import { useRef, useState } from 'react';
import { serialPromises } from 'src/utils/promise';
import { v4 } from 'src/utils/uuid';
import { useLibraryContext } from './LibraryContext';
import { library } from './types';
import {
  Block,
  BlockState,
  BlockStatus,
  Executor,
  ExecutorObserver,
} from '@keix/workflow-types';
import { useLocalPython } from './useLocalPython';
import {
  buildFunctionsCode,
  buildMainExecutionCode,
  getBlockCode,
  getBlockVariable,
} from './utils';
import { PreviewContext, usePreviewContext } from 'src/apps/kgest/pages/workflow/PreviewContext';

const l = library;

function formatStatus(status: BlockStatus) {
  switch (status) {
    case BlockStatus.IDLE:
      return 'idle';
    case BlockStatus.FAILED:
      return 'failed';
    case BlockStatus.COMPLETED:
      return 'completed';
    case BlockStatus.RUNNING:
      return 'running';
  }
}

export function useLocalExecutor(previewer: PreviewContext): Executor {
  const { isReady, python } = useLocalPython();
  const library = useLibraryContext();
  const [states, setStates] = useState({});
  const observers = useRef<{ [key: string]: ExecutorObserver }>({});

  function updateBlockState(id: string, state: BlockState) {
    console.debug(
      `[Block:${id}] New state: ${formatStatus(state.status)}`
    );

    // When changes to running, lets crea the preview.
    setStates((s) => set(s, id, state));
    Object.values(observers.current).forEach((o) =>
      o.onBlockStateUpdate(id, state)
    );
  }

  function inspectBlock(id: string) {
    console.debug(`[Block:${id}] Inspecting block...`);
    const proxy: any = python?.globals.get(getBlockVariable(id));
    return proxy?.toString();
  }

  return {
    isReady,
    version: python?.version ?? 'Loading...',
    inspectBlock,
    states,
    registerObserver: (observer) => {
      const id = v4();
      observers.current[id] = observer;
      return () => {
        delete observers.current[id];
      };
    },
    run: async (blocks) => {
      // Reset the states.
      setStates({});

      // Clear previews.
      previewer.clearPreviews();

      // First run all the functions.
      const functions = uniqBy(blocks, 'uri');
      const codes = functions.flatMap((c) => getBlockCode(c, l));
      const mainFunctions = buildFunctionsCode(blocks, library);
      const main = buildMainExecutionCode(blocks, library);

      const mainCode = [
        ...codes,
        ...mainFunctions.map((d) => d.code),
        ...main.map((d) => d.code),
      ].join('\n');
      console.info(mainCode);

      const [importPromises] = serialPromises(
        codes.flatMap((b) => async () => {
          console.info(b);
          await python?.loadPackagesFromImports(b);
          await python?.runPythonAsync(b);
        })
      );
      await importPromises;

      const [functionPromises] = serialPromises(
        mainFunctions.map((b) => () => {
          console.info(b.code);
          return python?.runPythonAsync(b.code) ?? Promise.resolve(true);
        })
      );
      await functionPromises;

      // For each code.
      let [runPromises] = serialPromises(
        main.map(({ id, code }) => async () => {
          updateBlockState(id, { status: BlockStatus.RUNNING });
          try {
            console.info(code);
            await python?.runPythonAsync(code);
            updateBlockState(id, {
              status: BlockStatus.COMPLETED,
              proxy: python?.globals.get(getBlockVariable(id)),
            });
            previewer.clearPreview(id, true);
          } catch (err) {
            console.error(err);
            updateBlockState(id, {
              status: BlockStatus.FAILED,
              error: err.message,
            });
            previewer.clearPreview(id, true);
          }
        })
      );
      await runPromises;
    },
  };
}


