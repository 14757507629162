import React, { useCallback, useState } from 'react';
import { Button, ButtonProps, Intent } from "@blueprintjs/core";
import { UseMutationResult } from "@tanstack/react-query";
import { delay } from 'src/hooks/workflow/utils';

export function MutationButton<T extends {}>(props: ButtonProps & { mutation: UseMutationResult<unknown, unknown, T, unknown>; variables: T }) {
  const { mutation, variables, ...buttonProps } = props;
  const [tempProps, setTempProps] = useState<Partial<ButtonProps>>({});

  const loading = mutation.isLoading;
  const disabled = mutation.isLoading;
  const handleClick = useCallback(async () => {
    try {
      await mutation.mutateAsync(variables)
      setTempProps({ icon: 'tick', intent: Intent.SUCCESS })
    } catch {
      setTempProps({ icon: 'warning-sign', intent: Intent.DANGER })
    }
    await delay(2000);
    setTempProps({});
  }, [variables])


  return (
    <Button disabled={disabled} loading={loading} onClick={handleClick}  {...buttonProps} {...tempProps} />
  )
}