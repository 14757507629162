import { IconName } from '@blueprintjs/core';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface OmnibarSource<T = object> {
  key: string;
  kind: string;
  icon: IconName;
  title: string;
  query: string;
  dataKey: string;
  valueRenderer: (item: T) => string;
}

export interface OmnibarRepositoryCtx {
  sources: OmnibarSource[];
  isOpen: boolean;
  preferredSourceKey?: string;
  registerOmnibarSource(source: OmnibarSource): void;
  registerPreferredSourceKey(key: string): void;
  unregisterPreferredSourceKey(key: string): void;
  openOmnibar(): void;
  closeOmnibar(): void;
}

const OmnibarRepositoryContext = createContext<OmnibarRepositoryCtx>({
  sources: [],
  isOpen: false,
  openOmnibar: () => null,
  closeOmnibar: () => null,
  registerOmnibarSource: () => null,
  registerPreferredSourceKey: () => null,
  unregisterPreferredSourceKey: () => null,
});

export function OmnibarRepositoryProvider(p: { children: JSX.Element }) {
  const [sources, updateSources] = useState<OmnibarSource[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [preferredSourceKey, setPreferredSourceKey] = useState<string | null>(
    null
  );

  const openOmnibar = useCallback(() => {}, []);
  const registerOmnibarSource = useCallback((source: OmnibarSource) => {
    updateSources((prevSources) => {
      const prev = prevSources.filter((f) => f.key !== source.key);
      return [...prev, source];
    });
  }, []);
  const registerPreferredSourceKey = useCallback(
    (key: string) => {
      if (sources.find((s) => s.key === key) != null) {
        setPreferredSourceKey(key);
      }
    },
    [sources]
  );
  const unregisterPreferredSourceKey = useCallback((key: string) => {
    setPreferredSourceKey(null);
  }, []);
  const value = useMemo(
    () => ({
      sources,
      isOpen,
      openOmnibar: () => setOpen(true),
      closeOmnibar: () => setOpen(false),
      preferredSourceKey,
      registerOmnibarSource,
      registerPreferredSourceKey,
      unregisterPreferredSourceKey,
    }),
    [sources, preferredSourceKey, isOpen]
  );
  return (
    <OmnibarRepositoryContext.Provider value={value}>
      {p.children}
    </OmnibarRepositoryContext.Provider>
  );
}

export function useOmnibarPreferredSourceKey(key: string) {
  const { registerPreferredSourceKey, unregisterPreferredSourceKey } =
    useOmnibarRepositoryContext();
  useEffect(() => {
    registerPreferredSourceKey(key);
    return () => {
      console.log('Unregister...', key);
      unregisterPreferredSourceKey(key);
    };
  }, []);
}

export function useOmnibarRepositoryContext() {
  return useContext(OmnibarRepositoryContext);
}
