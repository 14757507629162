import { LibraryBlock } from '@keix/workflow-types';
import { set, update } from 'lodash/fp';
import {
  addEdge,
  applyEdgeChanges,
  Connection,
  EdgeChange,
  NodeChange,
} from 'react-flow-renderer';
import { createNode } from 'src/hooks/workflow/utils';
import { WorkflowState } from '../../workflow/types';
import changeNodes from '../../store/changeNodes';
import { WorkflowActions } from '..';

export enum WorkflowNodeActionType {
  ADD_NODE = 'ADD_NODE',
  CHANGE_NODES = 'CHANGE_NODES',
  CHANGE_EDGES = 'CHANGE_EDGES',
  ADD_CONNECTION = 'ADD_CONNECTION',
}

export type WorkflowNodeActions =
  | {
      type: WorkflowNodeActionType.ADD_NODE;
      block: LibraryBlock;
      position?: { x: number; y: number };
    }
  | { type: WorkflowNodeActionType.ADD_CONNECTION; connection: Connection }
  | { type: WorkflowNodeActionType.CHANGE_NODES; changes: NodeChange[] }
  | { type: WorkflowNodeActionType.CHANGE_EDGES; changes: EdgeChange[] };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowNodeActionType.ADD_NODE:
      // Build the next state.
      const node = createNode(
        action.block,
        action.position ?? prev.viewport.center
      );
      const nextState = {
        ...prev,
        nodes: [...prev.nodes, node],
        nodeIndexById: {
          ...prev.nodeIndexById,
          [node.id]: prev.nodes.length,
        },
        showAddDialog: false,
      };
      return changeNodes(nextState, [
        { type: 'select', selected: true, id: node.id },
      ]);
    case WorkflowNodeActionType.CHANGE_NODES:
      return changeNodes(prev, action.changes);

    case WorkflowNodeActionType.CHANGE_EDGES:
      return update('edges', (edges) =>
        applyEdgeChanges(action.changes, edges)
      )(prev);

    case WorkflowNodeActionType.ADD_CONNECTION:
      return update('edges', (edges) => addEdge(action.connection, edges))(
        prev
      );
  }
  return prev;
}
