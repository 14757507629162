import React, { useState, useEffect, useCallback } from 'react';
import { useCrumbs } from 'src/context/CrumbsContext';
import { useTranslation } from 'react-i18next';
import {
  MasterDetail,
  MasterRow,
  MasterActions,
  MasterTitleBar,
} from 'src/components/MasterDetail';
import type { Query, UploadType } from '../apps/athena/gql-types';
import {
  Icon,
  Colors,
  Button,
  Intent,
  NonIdealState,
  FormGroup,
} from '@blueprintjs/core';
import { UploadTypeSuggesterField } from '../apps/athena/components/Suggesters';
import { useFormik, FormikProvider } from 'formik';
import { TextField } from 'src/components/BlueprintFields';
import { useAlert } from 'src/utils/alert';
import { useSendCommand } from 'src/utils/events';
import { useQuery } from '@tanstack/react-query';
import { useGraphQLFetch } from 'src/utils/graphql';

const ADD_MUTATION =
  'mutation AddUploadType($name: String!) { addUploadType(name: $name) }';
const RENAME_MUTATION =
  'mutation RenameUploadType($prevName: String!, $name: String!) { renameUploadType(prevName: $prevName, name: $name) }';
const REMOVE_MUTATION =
  'mutation RemoveUploadType($name: String!) { removeUploadType(name: $name) }';

interface MasterProps {
  rows: UploadType[];
  hasNew: boolean;
  selectedItem: string;
  onDeleteItem: (id: string) => void;
  onSelectItem: (id: string) => void;
  onAddItem: () => void;
}

export function formatUploadTypeName(
  r: UploadType,
  rawString: boolean = false
) {
  const { name, deletedAt } = r;
  const deletedClass = deletedAt != null ? 'line-through secondary-text' : '';
  const components = name.split('/');
  return rawString ? (
    components.reduce((prev, next, index) => {
      return prev + next + (index < components.length - 1 ? ' ▸ ' : '');
    }, '')
  ) : (
    <>
      {components.map((component, index) => (
        <>
          <span className={deletedClass}>{component}</span>
          {index < components.length - 1 && (
            <Icon className="tertiary-text" icon="chevron-right" />
          )}
        </>
      ))}
    </>
  );
}

function Master(p: MasterProps) {
  const { t } = useTranslation();

  return (
    <>
      <MasterTitleBar title={t('Document Types')} />

      {p.rows.map((r) => (
        <MasterRow
          disabled={p.hasNew}
          title={formatUploadTypeName(r)}
          onDelete={() => p.onDeleteItem(r.id)}
          selected={r.id === p.selectedItem}
          onClick={() => p.onSelectItem(r.id)}
        />
      ))}
      {p.hasNew && (
        <MasterRow className="italic" title={t('New Item')} selected={true} />
      )}
      <MasterActions>
        <Button
          intent={Intent.PRIMARY}
          icon="plus"
          fill
          onClick={p.onAddItem}
          text={t('Add Document Type')}
          minimal
        />
      </MasterActions>
    </>
  );
}

function getInitialValues(item?: UploadType) {
  if (item == null) {
    return { parent: '', name: '' };
  }
  const components = item.name.split('/');
  return {
    parent: components.length > 1 ? components.slice(0, -1).join('/') : '',
    name: components.slice(-1)[0],
  };
}

function Detail(p: {
  item?: UploadType;
  isNew: boolean;
  onDiscard: () => void;
}) {
  const { t } = useTranslation();
  const editable = p.item?.deletedAt == null;
  const [_, sendCommand] = useSendCommand(
    p.isNew ? ADD_MUTATION : RENAME_MUTATION,
    (evt, ctx) => {
      if (evt.type === 'UPLOAD_TYPE_ADDED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Upload Type Successfully Added'),
        });
      }
    }
  );

  const form = useFormik<{ parent: string; name: string }>({
    initialValues: getInitialValues(p.item),
    enableReinitialize: true,
    onSubmit: ({ name, parent }) => {
      const n = parent.length > 0 ? `${parent}/${name}` : name;
      const other = p.item?.name != null ? { prevName: p.item?.name } : {};
      return sendCommand({
        ...other,
        name: n,
      });
    },
  });

  useEffect(() => {
    if (p.isNew) {
      form.resetForm({ values: { parent: '', name: '' } });
    }
  }, [p.isNew]);

  if (p.item == null && !p.isNew) {
    return (
      <NonIdealState title={t('No Upload Selected')} icon="document-open" />
    );
  }

  return (
    <FormikProvider value={form}>
      <div className="flex flex-column flex-grow">
        <div className="card-header flex items-center mb2 px2">
          <h3 className={p.isNew ? 'italic' : ''}>
            {p.isNew ? t('New Item') : formatUploadTypeName(p.item)}
          </h3>
          <div className="flex-grow" />
          {p.isNew && (
            <Button
              icon="cross"
              text={t('Cancel')}
              className="mr1"
              minimal={true}
              disabled={form.isSubmitting}
              onClick={p.onDiscard}
            />
          )}
          <Button
            icon="tick"
            text={t('Apply')}
            disabled={!form.dirty || form.isSubmitting || !editable}
            loading={form.isSubmitting}
            onClick={form.submitForm}
            intent={Intent.PRIMARY}
            minimal={!form.dirty}
          />
        </div>
        <div className="p1 px2 flex flex-column flex-grow">
          <FormGroup
            label={t('Document Type Parent')}
            helperText={t('Document Type Parent Helper Text')}
          >
            <UploadTypeSuggesterField
              readOnly={!editable}
              filterItems={(i) => i.id !== p.item?.id}
              name="parent"
            />
          </FormGroup>
          <FormGroup
            label={t('Document Type Name')}
            helperText={t('Document Type Name Helper Text')}
          >
            <TextField name="name" disabled={!editable} />
          </FormGroup>
        </div>
      </div>
    </FormikProvider>
  );
}

export default function UploadTypes() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isNew, setIsNew] = useState(false);
  const [_, sendCommand] = useSendCommand(REMOVE_MUTATION, (evt, ctx) => {
    if (evt.type === 'UPLOAD_TYPE_REMOVED') {
      ctx.complete();
      ctx.showSuccessNotification({
        message: t('Upload Type Removed'),
      });
    }
  });

  const [alert, showAlert] = useAlert<{ name: string }>({
    message: 'Are you sure?',
    icon: 'warning-sign',
    intent: Intent.DANGER,
    confirmButtonText: 'Rimuovi',
    onConfirm: ({ name }) => {
      return sendCommand({ name });
    },
  });

  const graphqlFetch = useGraphQLFetch<Pick<Query, 'uploadTypes'>, {}>();
  const result = useQuery(['uploadTypes'], () =>
    graphqlFetch('query { uploadTypes { items { id name deletedAt } } }')
  );
  const uploadTypes: UploadType[] = result.data?.uploadTypes?.items ?? [];
  const item = uploadTypes.find((t) => t.id === selectedItem);
  const handleAddItem = useCallback(() => {
    setIsNew(true);
    setSelectedItem(null);
  }, []);

  useCrumbs([{ text: t('Manage Documents'), href: '/uploadTypes' }]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      const kind = uploadTypes.find((f) => f.id === id);
      if (kind == null) {
        return;
      }
      const name = formatUploadTypeName(kind, true) as string;
      showAlert(
        {
          message: `${t('Are you sure your want to remove ')} ${name}?`,
        },
        { name }
      );
    },
    [uploadTypes]
  );

  return (
    <>
      <MasterDetail
        master={
          <Master
            hasNew={isNew}
            onAddItem={handleAddItem}
            selectedItem={selectedItem}
            onDeleteItem={handleDeleteItem}
            onSelectItem={setSelectedItem}
            rows={uploadTypes}
          />
        }
        detail={
          <Detail isNew={isNew} item={item} onDiscard={() => setIsNew(false)} />
        }
      />
      {alert}
    </>
  );
}
