// import React, { useMemo } from 'react';
// import { useQuery, useQueryClient } from '@tanstack/react-query';
// import {
//   Query,
//   QuestionOption,
//   QuestionType,
//   Survey,
//   SurveyFrequenciesResult,
//   SurveyFrequencyResult,
//   SurveyQuestion,
// } from 'src/apps/athena/gql-types';
// import { QUERY } from 'src/graphql/surveys';
// import { useGraphQLFetch } from 'src/utils/graphql';
// import { useSize } from 'src/utils/size';
// import Plot from 'react-plotly.js';
// import type { Data, Layout } from 'plotly.js';
// import {
//   SurveyQuestionData,
//   SurveyWithQuestions,
//   useGetSurveyWithQuestions,
// } from 'src/pages/Survey/SurveyPage';
// import CenteredSpinner from '../CenteredSpinner';
// import { first, isEmpty, isEqual, times } from 'lodash';
// import { scaleItemForIndex } from './QuestionOpinionScale';
// import { Button } from '@blueprintjs/core';
// import { useState } from 'react';
// import classNames from 'classnames';

// function getQuestionItems(question: SurveyQuestionData): QuestionOption[] {
//   if (!isEmpty(question.options)) {
//     return question.options;
//   } else if (question.type === QuestionType.OpinionScale) {
//     return times(question.settings.scaleSize).map((d) => ({
//       body: `${d + 1}`,
//       id: `${d + 1}`,
//     }));
//   } else {
//     return [];
//   }
// }

// export default function SurveyDashboard(p: { surveyId: string }) {
//   const percent = 10;
//   const fetch = useGraphQLFetch();
//   const { data: surveyData } = useGetSurveyWithQuestions(p.surveyId);
//   const survey = surveyData.survey;
//   const { data } = useQuery<Pick<Query, 'surveyFrequencies'>>(
//     ['surveyFrequencies', p.surveyId],
//     () => fetch(QUERY.GET_SURVEY_FREQUENCIES, { id: p.surveyId }),
//     { refetchInterval: 5000 }
//   );

//   if (survey == null) {
//     return <CenteredSpinner />;
//   }

//   return (
//     <div className="flex-grow p-2 grid grid-cols-3 grid-rows-3 gap-2">
//       {data?.surveyFrequencies?.frequencies.map((result) => (
//         <DashboardGridItem survey={survey} result={result} />
//       ))}
//     </div>
//   );
// }

// function getFrequencyResultPlotlyChart(
//   survey: SurveyWithQuestions,
//   result: SurveyFrequencyResult
// ): { data: Data[]; layout: Partial<Layout>; title: string } {
//   const { options, questionIds } = result;
//   const number = new Intl.NumberFormat('it', {
//     style: 'percent',
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   });
//   const questions = survey.questions?.filter((q) => questionIds.includes(q.id));
//   const title = questions.map((d) => d.body).join(' x ');
//   const layout: Partial<Layout> = {
//     legend: {
//       orientation: 'h',
//       xanchor: 'center',
//       x: 0.5,
//     },
//     // paper_bgcolor: 'rgba(255,0,0,0.1)',
//     titlefont: {
//       size: 13,
//     },
//   };

//   if (questions.length === 1) {
//     const question = questions[0];
//     switch (question.type) {
//       case QuestionType.MultipleChoice:
//         if (question.settings?.allowMultipleChoices) {
//           return {
//             title,
//             layout: {
//               ...layout,
//               yaxis: {
//                 tickformat: ',.0%',
//               },
//               margin: {
//                 t: 20,
//                 b: 40,
//                 l: 40,
//                 r: 40,
//               },
//             },
//             data: [
//               {
//                 type: 'bar',
//                 x: question.options?.map((d) => d.body),
//                 y: question.options?.map(
//                   (_, index) => result.options[index].percentage
//                 ),
//               },
//             ],
//           };
//         } else {
//           return {
//             title,
//             data: [
//               {
//                 type: 'pie',
//                 labels: question.options?.map((d) => d.body),
//                 values: question.options?.map((d) => {
//                   const opt = options.find((t) => t.optionIds[0] === d.body);
//                   return opt?.amount ?? 0;
//                 }),
//               },
//             ],
//             layout: {
//               ...layout,
//               margin: {
//                 t: 20,
//                 b: 20,
//                 l: 20,
//                 r: 20,
//               },
//             },
//           };
//         }

//       case QuestionType.OpinionScale:
//         const { scaleKind, scaleSize } = question.settings;
//         const scaleOptions = times(scaleSize).map((d) =>
//           scaleItemForIndex(d, scaleKind)
//         );
//         const y = scaleOptions.map((d) => {
//           const opt = options.find((t) => `${d}` === t.optionIds[0]);
//           return opt?.percentage ?? 0;
//         });
//         return {
//           title,
//           data: [
//             {
//               type: 'bar',
//               textposition: 'auto',
//               hoverinfo: 'none',
//               x: scaleOptions,
//               text: y.map(number.format),
//               y,
//             },
//           ],
//           layout: {
//             ...layout,
//             yaxis: {
//               tickformat: ',.0%',
//             },
//             xaxis: {
//               dtick: 1,
//             },
//             margin: {
//               t: 20,
//               b: 40,
//               l: 40,
//               r: 40,
//             },
//           },
//         };
//     }
//   } else if (questions.length === 2) {
//     // If we have more than one question, is a multiple frequncy cross.
//     const [secondQuestion, firstQuestion] = questions;

//     const firstOptions = getQuestionItems(firstQuestion);
//     const secondOptions = getQuestionItems(secondQuestion);

//     const x = secondOptions.map((d) => d.body);

//     const data: Data[] = firstOptions.map((opt) => ({
//       name: opt.body,
//       type: 'bar',
//       x,
//       y: secondOptions.map((opt2) => {
//         const value = result.options.find((d) =>
//           isEqual(d.optionIds, [opt2.body, opt.body])
//         );
//         return value?.percentage ?? 0;
//       }),
//     }));

//     return {
//       data,
//       title,
//       layout: {
//         ...layout,
//         yaxis: {
//           tickformat: ',.0%',
//         },
//         margin: {
//           t: 20,
//           b: 40,
//           l: 40,
//           r: 40,
//         },
//         barmode: 'stack',
//       },
//     };
//   }

//   return { title, data: [], layout };
// }

// function DashboardGridItem(p: {
//   survey: SurveyWithQuestions;
//   result: SurveyFrequencyResult;
// }) {
//   const [size, ref] = useSize();

//   const intl = new Intl.NumberFormat('it', {
//     style: 'percent',
//   });
//   const { survey, result } = p;

//   const [showFullScreen, setFullScreen] = useState(false);
//   const [showData, setShowData] = useState(false);
//   const {
//     data,
//     layout: chartLayout,
//     title,
//   } = useMemo(
//     () => getFrequencyResultPlotlyChart(survey, result),
//     [survey, result]
//   );

//   const layout: Partial<Layout> = useMemo(
//     () => ({
//       ...size,
//       ...chartLayout,
//     }),
//     [chartLayout, size]
//   );

//   return (
//     <div className="bg-white rounded relative overflow-hidden border border-gray-200 group">
//       <div className="relative w-full h-full flex flex-col">
//         <div className="text-center py-3 font-medium text-gray-600">
//           {title}
//         </div>

//         <div className="flex-grow relative">
//           <div
//             ref={ref}
//             className={classNames(
//               {
//                 'fixed z-50': showFullScreen,
//                 absolute: !showFullScreen,
//               },
//               'absolute top-0 bottom-0 left-0 right-0 overflow-scroll'
//             )}
//           >
//             {showData ? (
//               <pre className="text-gray-700 text-xs p-2">
//                 <code>{JSON.stringify(result, null, 2)}</code>
//               </pre>
//             ) : (
//               <Plot
//                 layout={layout}
//                 data={data}
//                 config={{ displayModeBar: false }}
//               />
//             )}
//           </div>

//           <Button
//             icon="search-text"
//             minimal
//             className="absolute bottom-0 right-0 group-hover:opacity-80 opacity-0"
//             onClick={() => setShowData((p) => !p)}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// /*

// */

import React from 'react';
export default function SurveyDashboard() {
  return <React.Fragment />;
}
