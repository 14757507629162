import React, { useCallback } from 'react';
import { Ref } from 'src/apps/athena/gql-types';
import { Icon } from '@blueprintjs/core';
import type { IconName } from '@blueprintjs/core';
import { isLoaded, Loadable } from './EditableField';
import { get } from 'lodash';
import { AppTableInstance } from 'src/utils/table/useAppTable';
import { useQuickLook } from './QuickLookDrawer';

function getRefKindIcon(kind: string): IconName {
  if (kind === 'person') {
    return 'person';
  } else if (kind === 'company') {
    return 'briefcase';
  } else if (kind === 'certificate') {
    return 'endorsed';
  } else if (kind === 'quotation') {
    return 'headset';
  } else if (kind === 'projectList') {
    return 'list';
  } else if (kind === 'project') {
    return 'projects';
  } else if (kind === 'telegram') {
    return 'comment';
  }
}

export default function RefLink(p: {
  kind: string;
  value?: { id: string; name?: string };
  onClick?: () => void;
}) {
  const { value, kind } = p;
  const { quickLookRef } = useQuickLook();

  return value != null ? (
    <div className="flex">
      <div
        className="ref-link flex items-center"
        onClick={(event) => {
          event.stopPropagation();
          if (p.onClick != null) {
            p.onClick();
          } else {
            quickLookRef({ kind, ref: p.value });
          }
        }}
      >
        <Icon
          className="tertiary-text"
          icon={getRefKindIcon(p.kind)}
          iconSize={14}
        />
        <div className="primary-text semi-bold ml-2">{value.name}</div>
      </div>
    </div>
  ) : (
    <div className="text-gray-400">-</div>
  );
}

export function useRefLinkAccessor<T extends object>(
  dataKey: string,
  type: string,
  tableOrFn?: AppTableInstance<T> | (() => AppTableInstance<T>)
) {
  const { quickLookRef } = useQuickLook();
  return useCallback((t: Loadable<T>) => {
    if (isLoaded(t)) {
      const value = get(t, dataKey);
      return (
        <RefLink
          value={value}
          kind={type}
          onClick={() => {
            quickLookRef({
              kind: type,
              ref: value,
            });
          }}
        />
      );
    }
    return '-';
  }, []);
}
