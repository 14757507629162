import React, { useState } from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { BlockStatus } from '@keix/workflow-types';
import { EditableText, Icon } from '@blueprintjs/core';

export function BlockLabel(p: {
  label: string;
  selected?: boolean;
  status: BlockStatus;
  onChange: (label: string) => void;
  error?: string;
}) {
  const [isEditing, setEditing] = useState(false);
  return (
    <div
      className={classNames('text-sm mt-2 py-1 px-2', {
        'text-gray-500':
          p.selected !== false && p.status !== BlockStatus.FAILED,
        'text-gray-600 ':
          p.selected === true && p.status !== BlockStatus.FAILED,
        'bg-red-500 text-white rounded': p.status === BlockStatus.FAILED,
      })}
    >
      {p.error && <Icon icon="warning-sign" className="mr-2" />}
      <Tooltip2
        disabled={p.error == null}
        minimal={true}
        content={<pre>{p.error}</pre>}
      >
        {!isEditing ? (
          <span onDoubleClick={() => setEditing(true)}>{p.label}</span>
        ) : (
          <EditableText
            defaultValue={p.label}
            onConfirm={(v) => {
              p.onChange(v);
              setEditing(false);
            }}
          />
        )}
      </Tooltip2>
    </div>
  );
}
