import { Button, Intent } from '@blueprintjs/core';
import React from 'react';
import { MutationButton } from 'src/components/MutationButton';
import { useKeixPlatform } from 'src/hooks/workflow/useKeixPlatform';
import { useWorkflowContext, WorkflowAction } from './WorkflowContext';

export function WorkflowSidebar(p: { id: string }) {
  const { state, dispatch } = useWorkflowContext();
  const { saveWorkflow } = useKeixPlatform();

  return (
    <div className="absolute z-10 top-2 left-2 flex flex-col bg-white shadow-md border rounded">
      <Button
        minimal
        large
        icon="play"
        loading={state.isRunning}
        disabled={!state.isReady}
        intent={state.isReady ? Intent.PRIMARY : Intent.NONE}
        onClick={() =>
          dispatch({
            type: state.isRunning ? WorkflowAction.STOP : WorkflowAction.RUN,
          })
        }
      />
      <Button
        icon="plus"
        minimal
        large
        onClick={() => dispatch({ type: WorkflowAction.SHOW_ADD_DIALOG })}
      />
      <Button
        icon="book"
        minimal
        large
        onClick={() => dispatch({ type: WorkflowAction.OPEN_DIALOG_LIBRARY })}
      />
      <Button
        icon="settings"
        minimal
        large
        onClick={() =>
          dispatch({
            type: WorkflowAction.SET_DRAWER_STATE,
            drawer: state.drawer.isOpen ? { isOpen: false } : { isOpen: true },
          })
        }
      />
      <MutationButton
        icon="floppy-disk"
        minimal
        large
        mutation={saveWorkflow}
        variables={{ id: p.id, workflow: { ...state, id: p.id } }}
      />
    </div>
  );
}
