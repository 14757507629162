import React, { useCallback, useMemo, useState } from 'react';
import { Table } from 'src/components/table';
import type { PageInfo, Person, Query, Ref } from '../apps/athena/gql-types';
import { AppColumn, FilterKind } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import {
  getDisplayName,
  getPhoneNumber,
  getEmployer,
  getEmailAddress,
  getPecAddress,
  getAge,
  getLocation,
  getCertificate,
  getBirthDate,
  useDateAccessor,
  getRegion,
  getCity,
  getArea,
} from 'src/utils/accessors';
import { useTranslation } from 'react-i18next';
import { useCrumbs, Crumb } from 'src/context/CrumbsContext';
import QuickLookDrawer, { useQuickLook } from '../components/QuickLookDrawer';
import {
  OmnibarSource,
  useOmnibarPreferredSourceKey,
} from 'src/context/OmnibarContext';
import { SEARCH_QUERY } from 'src/graphql/people';
import { DateTime } from 'luxon';
import { MUTATIONS, QUERIES } from 'src/apps/athena/graphql/crm';
import { useQueryClient } from '@tanstack/react-query';
import { FeatureSetBlock, useFeatureSet } from 'src/context/FeatureSet';
import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import { ProjectSuggesterField } from 'src/components/GraphQLSuggester';
import { FormikProvider, useFormik } from 'formik';
import {
  ProjectListsSelectField,
  useAddProjectCandidate,
} from 'src/components/project/AddProjectCandidatesManual';
import { object } from 'yup';
import { string } from 'yup';
import { isEmpty, map } from 'lodash';
import { GraphQLFetch, useGraphQLFetch } from 'src/utils/graphql';
import { useSendCommand } from 'src/utils/events';
import { CANDIDATE_MUTATION } from 'src/graphql/project';
import { v4 } from 'src/utils/uuid';
import { isLoaded, Loadable } from 'src/components/EditableField';
import RefLink from 'src/components/RefLink';
import { useEnvironment } from 'src/context/EnvironmentContext';
import { useColumnCustomizer } from 'src/hooks/columns';
import { provinceByArea, provinceByRegion, regions } from 'src/vendor/province';
import { useTitle } from 'hoofd';

export function usePeopleCrumbs() {
  const { t } = useTranslation('people');
  return useMemo(
    () => [{ href: '/people', text: t('People', { ns: 'translation' }) }],
    []
  );
}

export const omnibarSourceByName: OmnibarSource = {
  key: 'people',
  kind: 'person',
  icon: 'people',
  title: 'People',
  query: SEARCH_QUERY,
  dataKey: 'peopleSuggester',
  valueRenderer: getDisplayName,
};

export default function PeoplePage() {
  const [isProjectListDialogOpen, setProjectListDialogOpen] = useState(false);
  const { t } = useTranslation('people');
  const crumbs = usePeopleCrumbs();
  const { quickLookRef } = useQuickLook();
  const features = useFeatureSet();
  const customize = useColumnCustomizer<Person>('people');

  useCrumbs(crumbs);
  useTitle('People');
  useOmnibarPreferredSourceKey('peopleByFirstName');

  const createdAtAccessor = useDateAccessor(
    'createdAt',
    DateTime.DATETIME_SHORT
  );
  const updatedAtAccessor = useDateAccessor(
    'updatedAt',
    DateTime.DATETIME_SHORT
  );
  const projectsAccessor = useCallback((person: Loadable<Person>) => {
    if (isLoaded(person) && !isEmpty(person.projects)) {
      return (
        <div className="italic text-gray-600">
          {person.projects?.length} progetti
        </div>
      );
    }
    return '-';
  }, []);

  const columns = useMemo<AppColumn<Person>[]>(
    () =>
      customize([
        {
          id: 'firstName',
          Header: t('First Name'),
          width: 150,
          accessor: 'firstName',
          filter: {
            id: 'firstName',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'lastName',
          Header: t('Last Name'),
          width: 150,
          accessor: 'lastName',
          filter: {
            id: 'lastName',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'age',
          Header: t('Age'),
          width: 60,
          isVisible: false,
          accessor: getAge,
          filter: {
            id: 'birthDate',
            kind: FilterKind.AGE,
          },
        },
        {
          id: 'fiscalCode',
          Header: t('Fiscal Code'),
          width: 180,
          accessor: 'fiscalCode',
          filter: {
            id: 'fiscalCode',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'gender',
          Header: t('Gender'),
          width: 140,
          isVisible: false,
          accessor: 'gender',
          filter: {
            id: 'gender',
            kind: FilterKind.KEYWORD,
            values: [{ value: 'MALE' }, { value: 'FEMALE' }],
          },
        },
        {
          id: 'livesIn',
          Header: t('Home Address'),
          width: 140,
          isVisible: false,
          accessor: getLocation,
          filter: {
            id: 'locations.location.name',
            kind: FilterKind.TEXT,
          },
        },

        {
          id: 'phoneNumber',
          Header: t('Phone Number'),
          width: 140,
          accessor: getPhoneNumber,
          filter: {
            id: 'phoneNumbers.number',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'emailAddress',
          Header: t('Email Address'),
          width: 200,
          accessor: getEmailAddress,
          filter: {
            id: 'emailAddresses.emailAddress',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'birthDate',
          Header: t('Birth Date'),
          width: 140,
          isVisible: false,
          accessor: getBirthDate,
        },

        {
          id: 'city',
          Header: t('City'),
          width: 180,
          accessor: getCity,
          filter: {
            id: 'locations.location.city',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'area',
          Header: t('area'),
          width: 180,
          accessor: getArea(t),
          filter: {
            id: 'locations.location.province',
            kind: FilterKind.KEYWORD,
            values: map(provinceByArea, (value, key) => ({
              label: t(key),
              value: value.join(','),
            })),
          },
        },
        {
          id: 'region',
          Header: t('Region'),
          width: 180,
          accessor: getRegion,
          filter: {
            id: 'locations.location.province',
            kind: FilterKind.KEYWORD,
            values: map(provinceByRegion, (provinces, region) => ({
              label: region,
              value: provinces.map((d) => d.sigla),
            })),
          },
        },
        {
          id: 'birthCity',
          Header: t('Birth City'),
          isVisible: false,
          width: 180,
          accessor: (p) => p.birthCity?.name ?? '-',
        },

        {
          id: 'pec',
          Header: t('PEC'),
          width: 180,
          isVisible: false,
          accessor: getPecAddress,
          filter: {
            id: 'pec',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'employer',
          Header: t('Employer'),
          width: 240,
          isVisible: true,
          accessor: getEmployer((ref: Ref) => {
            quickLookRef({ kind: 'company', ref });
          }),
          filter: {
            id: 'employer.name',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'certificates',
          Header: t('Certificates'),
          width: 200,
          accessor: getCertificate((ref: Ref) => {
            quickLookRef({ kind: 'certificate', ref });
          }),
          isVisible: features.isEnabled('CRM_DETAIL_CERTIFICATES'),
          filter: {
            id: 'certificates.name',
            kind: FilterKind.TEXT,
          },
        },
        {
          id: 'projects',
          Header: t('Projects'),
          width: 200,
          isVisible: features.isEnabled('PROJECTS'),
          accessor: projectsAccessor,
        },
        {
          id: 'createdAt',
          Header: t('Created At'),
          width: 140,
          isVisible: false,
          accessor: createdAtAccessor,
        },
        {
          id: 'updatedAt',
          Header: t('Updated At'),
          width: 140,
          isVisible: false,
          accessor: updatedAtAccessor,
        },
        {
          id: 'registrationSource',
          Header: t('Registration Source'),
          width: 140,
          isVisible: false,
          accessor: 'registrationSource',
        },
      ]),
    [t]
  );

  const queryClient = useQueryClient();

  const { table, ref, dialogs, pageInfo } = usePaginatedTableQuery(
    QUERIES.RETRIEVE_PEOPLE,
    'people',
    'person',
    {
      columns,
      removeCommand: {
        query: MUTATIONS.REMOVE_PERSON,
        handler: (evt, ctx) => {
          if (evt.type === 'PERSON_REMOVED') {
            ctx.complete();
            ctx.showSuccessNotification({
              message: t('Person Removed'),
            });
          }
        },
        options: {
          onSettled: () => queryClient.invalidateQueries(['people']),
        },
      },
    }
  );
  const prevBottomItems = table.bottomItems;

  const bottomItems = features.isEnabled('PROJECTS')
    ? insert(1, prevBottomItems, {
      text: 'Add to List',
      icon: 'list',
      onClick: () => setProjectListDialogOpen(true),
      minimal: true,
    })
    : prevBottomItems;

  return (
    <div
      className="card flex-grow flex flex-column p0 overflow-hidden"
      ref={ref}
    >
      <Table className="flex-grow" {...table} bottomItems={bottomItems} />
      {dialogs}
      <FeatureSetBlock feature="PROJECTS">
        {isProjectListDialogOpen && (
          <AddPeopleToListDialog
            isOpen={isProjectListDialogOpen}
            onClose={() => setProjectListDialogOpen(false)}
            filters={table.filters}
            pageInfo={pageInfo}
          />
        )}
      </FeatureSetBlock>
    </div>
  );
}

function insert<T>(n: number, arr: T[], ...ins: T[]) {
  return [...arr.slice(0, n), ...ins, ...arr.slice(n)];
}

async function getNextPeoplePage(
  graphqlFetch: GraphQLFetch<Pick<Query, 'people'>>,
  filters: {},
  cursor?: {}
): Promise<[string[], PageInfo]> {
  const result = await graphqlFetch(QUERIES.RETRIEVE_PEOPLE, {
    limit: 250,
    filters,
    cursor,
  });

  return [result.people.items.map((d) => d.id), result.people.pageInfo];
}

function AddPeopleToListDialog(p: {
  isOpen: boolean;
  filters?: {};
  onClose: () => void;
  pageInfo?: PageInfo;
}) {
  const graphqlFetch = useGraphQLFetch();
  const [[_, addProjectCandidates], errorMessage] = useAddProjectCandidate();
  const form = useFormik<{
    project?: Ref;
    projectListId: string;
  }>({
    initialValues: {
      project: null,
      projectListId: null,
    },
    validateOnMount: true,
    validationSchema: object({
      project: object({
        id: string().required(),
      }),
      projectListId: string().required(),
    }),
    onSubmit: async (values) => {
      // Get all the people that matches these requirements.
      let personId: string[] = [];
      let cursor = null;
      do {
        const [people, pageInfo] = await getNextPeoplePage(
          graphqlFetch,
          p.filters,
          cursor
        );
        personId.push(...people);
        cursor = pageInfo.endCursor;
      } while (cursor != null);

      if (personId.length > 1000) {
        alert('Too many people for now!');
      } else {
        return addProjectCandidates({
          projectId: values.project.id,
          projectListId: values.projectListId,
          personId,
        });
      }
    },
  });
  return (
    <FormikProvider value={form}>
      <Dialog icon="list" title="Add To List" {...p}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Choose the project:">
            <ProjectSuggesterField name="project" />
          </FormGroup>
          <FormGroup label="Choose the project list:">
            <ProjectListsSelectField
              name="projectListId"
              projectId={form.values.project?.id}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            icon="tick"
            intent={Intent.PRIMARY}
            disabled={p.pageInfo == null || !form.isValid}
            text={`Add ${p.pageInfo?.total} People`}
            onClick={form.submitForm}
            fill
          />
        </div>
      </Dialog>
    </FormikProvider>
  );
}
