import { set } from 'lodash/fp';
import { Size } from 'src/utils/size';
import { WorkflowActions } from '..';
import {
  DrawerState,
  ViewportState,
  WorkflowState,
  XYPosition,
} from '../../workflow/types';

export enum WorkflowViewportActionType {
  RESIZE_VIEWPORT = 'RESIZE_VIEWPORT',
  OFFSET_VIEWPORT = 'OFFSET_VIEWPORT',
  MOVE_CURSOR = 'MOVE_CURSOR',
}

export type WorkflowViewportActions =
  | { type: WorkflowViewportActionType.RESIZE_VIEWPORT; size: Size }
  | { type: WorkflowViewportActionType.OFFSET_VIEWPORT; offset: XYPosition }
  | { type: WorkflowViewportActionType.MOVE_CURSOR; cursor: XYPosition };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowViewportActionType.OFFSET_VIEWPORT:
      prev.viewport = recalculateViewport(
        {
          ...prev.viewport,
          offset: action.offset,
        },
        prev.drawer
      );
      return prev;
    case WorkflowViewportActionType.RESIZE_VIEWPORT:
      prev.viewport = recalculateViewport(
        {
          ...prev.viewport,
          size: action.size,
          offset: prev.viewport.offset,
          center: {
            x: action.size.width / 2.0,
            y: action.size.height / 2.0,
          },
        },
        prev.drawer
      );
      return prev;
    case WorkflowViewportActionType.MOVE_CURSOR:
      prev.viewport.cursor = action.cursor;
      return prev;
  }
  return prev;
}

export function recalculateViewport(
  viewport: ViewportState,
  drawer: DrawerState
): ViewportState {
  const { size, center, offset } = viewport;
  const { height } = size;
  const width = size.width - (drawer.isOpen ? drawer.width : 0);
  return {
    ...viewport,
    center: {
      x: width / 2.0 - offset.x,
      y: height / 2.0 - offset.y,
    },
  };
}
