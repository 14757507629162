import { Icon } from '@blueprintjs/core';
import React from 'react';
import { useLibraryContext } from 'src/hooks/workflow/LibraryContext';

export default function DataType(p: {
  type: string;
  showIcon?: boolean;
  emptyView?: JSX.Element;
}) {
  const { dataTypes } = useLibraryContext();
  const { type } = p;
  if (type == null) {
    return p.emptyView;
  }
  const dataType = dataTypes[type];
  if (dataType == null) {
    return (
      <div className="text-gray-400 text-center p-4">
        Data type {type} not found
      </div>
    );
  }
  return (
    <div className="flex items-center">
      {p.showIcon !== false && (
        <Icon className="text-gray-400 pr-1" icon={dataType.icon ?? 'circle'} />
      )}
      <span className="px-1 text-gray-800">{dataType.name}</span>
      <span className="text-gray-500 italic">({dataType.uri})</span>
    </div>
  );
}
