import React from 'react';
import { Icon, IconName, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { BgColor, Block, BlockStatus } from '@keix/workflow-types';
import { Node } from 'react-flow-renderer';
import { isEmpty } from 'lodash';

export interface BlockIconProps {
  icon: string;
  bg: BgColor;
  className?: string;
  isRunning?: boolean;
  hasInputs?: boolean;
  hasOutputs?: boolean;
  onClick?: () => void;
}

export function BlockNodeIcon(p: Partial<BlockIconProps> & { block: Block }) {
  const { block } = p;
  return (
    <BlockIcon
      onClick={p.onClick}
      className={p.className}
      icon={p.icon ?? block.style.icon}
      bg={p.bg ?? block.style.bg}
      isRunning={p.isRunning ?? block.state.status === BlockStatus.RUNNING}
      hasInputs={p.hasInputs ?? !isEmpty(block.arguments)}
      hasOutputs={p.hasOutputs ?? block.returnType != null}
    />
  );
}

export function BlockIcon(p: BlockIconProps) {
  const color = `${p.bg.color}-${p.bg.opacity - 100}`;
  const fromBgColor = `from-${p.bg.color}-${p.bg.opacity}`;
  const toBgColor = `to-${p.bg.color}-${p.bg.opacity - 100}`;
  return (
    <div
      onClick={p.onClick}
      className={classNames(
        `bg-gradient-to-br text-white p-1 w-8 text-center flex items-center justify-center hover:outline outline-2 outline-offset-1 outline-${color}`,
        fromBgColor,
        toBgColor,
        p.className,
        { 'rounded-l': !p.hasInputs, 'rounded-r': !p.hasOutputs }
      )}
    >
      {p.isRunning ? <Spinner size={10} /> : <Icon icon={p.icon as IconName} />}
    </div>
  );
}
