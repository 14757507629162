import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Tag,
  Classes,
  Popover,
  Icon,
  IconName,
  NonIdealState,
} from '@blueprintjs/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useGraphQLFetch } from 'src/utils/graphql';
import type { Query, Notification } from 'src/apps/athena/gql-types';
import css from 'csz';
import classNames from 'classnames';
import { updatePaginatedQueryData } from 'src/utils/queryCache';
import { useSendCommand } from 'src/utils/events';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { Popover2 } from '@blueprintjs/popover2';
import { renderArray } from 'src/utils/accessors';

export function useNotificationsQuery() {
  const graphqlFetch = useGraphQLFetch();

  const query = useQuery<Pick<Query, 'notifications'>>(
    ['notifications'],
    () =>
      graphqlFetch(
        `query MyNotifications { notifications(limit: 100) { items { id body icon uri read sentAt readAt } } }`
      ),
    {
      refetchInterval: 5000,
      enabled: false,
    }
  );
  const unreadNotifications = useMemo(
    () =>
      query.data?.notifications?.items?.filter((f) => f.read === false).length,
    [query.data?.notifications?.items]
  );

  return {
    ...query,
    unreadNotifications,
  };
}

export function useNotificationActions(notification: Notification) {
  const queryKey = ['notifications'];
  const { uri, id } = notification;
  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(
    () => queryClient.invalidateQueries(queryKey),
    []
  );

  const openNotification = useCallback(() => {
    // Push the router.
    if (uri) {
      push(`/${uri.replace('://', '/')}`);
    }

    // In any case, read the notification!
    readNotification();
  }, [uri]);

  const [readNotificationState, _readNotification] = useSendCommand<
    {},
    { id: string }
  >(
    `mutation ReadNot($id: String!) { markNotificationAsRead(id: $id) }`,
    (evt, ctx) => {
      if (evt.type === 'NOTIFICATION_READ') {
        ctx.complete();
      }
    },
    {
      onMutate: (variables: { id: string }) => {
        updatePaginatedQueryData(
          queryClient,
          queryKey,
          'notifications',
          (n: Notification) =>
            n.id === variables.id ? { ...n, read: true } : n
        );
      },
      onSettled: invalidateQuery,
    }
  );

  const readNotification = useCallback(() => {
    return _readNotification({ id });
  }, [id]);

  const [readAllNotification, readAllNotificationState] = useSendCommand(
    `mutation ReadAllNot { markNotificationAsRead }`,
    (evt, ctx) => {
      ctx.complete();
    },
    {
      onMutate: () => {
        updatePaginatedQueryData(
          queryClient,
          queryKey,
          'notifications',
          (n: Notification) => ({ ...n, read: true })
        );
      },
      onSettled: invalidateQuery,
    }
  );
  return {
    openNotification,
    readAllNotification,
    readAllNotificationState,
    readNotification,
    readNotificationState,
  };
}

function getNotificationIcon(notificaton: Notification): IconName {
  const { icon } = notificaton;

  if (icon === 'note') {
    return 'clipboard';
  } else {
    return icon as IconName;
  }
}

export function NotificationRow(p: { notification: Notification }) {
  const { id, read } = p.notification;
  const icon = getNotificationIcon(p.notification);
  const unread = read === false;
  const { readNotification, openNotification } = useNotificationActions(
    p.notification
  );
  return (
    <div
      className={classNames(notificationRowClassName, { unread })}
      onClick={openNotification}
    >
      {p.notification.icon && (
        <Icon iconSize={24} className="notification-icon" icon={icon} />
      )}
      <div className="notification-details">
        <div className="notification-body">{p.notification.body}</div>
        <div className="notification-time">
          {DateTime.fromISO(p.notification.sentAt).toRelative()}
        </div>
      </div>
      <div className="unread">
        {unread && (
          <Button
            className="unread-icon"
            minimal
            icon={<Icon iconSize={24} icon="dot" onClick={readNotification} />}
          />
        )}
      </div>
    </div>
  );
}

export function NotificationPopover() {
  const { data, unreadNotifications } = useNotificationsQuery();
  return (
    <div className={notificationPopoverClassName}>
      <div className="notifications-header">Notifications</div>
      {renderArray(
        data?.notifications?.items ?? [],
        (n) => (
          <NotificationRow notification={n} key={n.id} />
        ),
        <NonIdealState
          className="p-6"
          icon="notifications-snooze"
          title="Nessuna Notifica"
          description="Non sono presenti notifiche"
        />
      )}
    </div>
  );
}

export function NotificationsButton() {
  const { unreadNotifications } = useNotificationsQuery();
  return (
    <div className={className}>
      <Popover2 inheritDarkTheme={false} content={<NotificationPopover />}>
        <Button large icon="notifications" minimal />
      </Popover2>
      {unreadNotifications > 0 && (
        <div className={classNames(Classes.TAG, Classes.ROUND)}>
          {unreadNotifications}
        </div>
      )}
    </div>
  );
}

const notificationPopoverClassName = css`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  min-width: 320px;
  border-radius: 3px;
  max-height: 80vh;
  background: var(--card-bg-color);
  overflow: scroll;
  .notifications-header {
    background: var(--card-header-bg-color);
    border-bottom: 1px solid var(--card-border-color);
    font-size: 1.15em;
    font-weight: 600;
    color: var(--primary-color);
    text-align: center;
    padding: 8px;
  }
`;

const notificationRowClassName = css`
  padding: 8px 18px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;

  &.unread {
    background: var(--card-active-bg-color);
  }
  :hover {
    background: var(--card-hover-bg-color);
  }
  .notification-details {
    display: flex;
    flex-grow: 1;
    padding: 0px 12px;
    flex-direction: column;
  }
  &.unread .notification-body {
    color: var(--primary-color);
  }
  .notification-body {
    font-weight: 500;
    overflow-wrap: break-word;
  }
  .notification-time {
    font-size: 0.8em;
    color: var(--text-secondary-color);
  }
  .notification-icon {
    color: var(--text-tertiary-color);
  }
  .unread-icon {
    .${Classes.ICON} {
      color: var(--primary-color);
    }
  }
  border-bottom: 1px solid var(--card-border-color);
`;

const className = css`
  margin-left: -6px;
  margin-right: -6px;
  position: relative;
  .${Classes.TAG} {
    position: absolute;
    top: 0px;
    font-weight: 600;
    left: 24px;
    min-width: 0px;
    padding: 0px 6px;
    background: var(--danger-color);
    color: white;
    font-size: 0.85em;
  }
`;
