import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useGoogleMaps } from 'src/utils/gmap';
import { EditableTextField, TextField } from './BlueprintFields';
import { useErrors, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Intent, Popover } from '@blueprintjs/core';
import type { LocationInput, BasicLocation } from 'src/apps/athena/gql-types';
import { FieldRow } from './EditableField';
import { Flag } from './Flag';
import css from 'csz';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { LatLng, LatLngLiteral, LatLngTuple } from 'leaflet';
import { Popover2 } from '@blueprintjs/popover2';
import { compact, identity, omitBy, pickBy } from 'lodash';
export interface LocationFieldProps {
  name: string;
  minimal?: boolean;
  info?: boolean;
  types: string[];
  fields: string[];
}

function getAddressComponent(
  place: google.maps.places.PlaceResult,
  type: string
) {
  return place.address_components?.find((addr) => addr.types.includes(type));
}

function getDetails(place: google.maps.places.PlaceResult): LocationInput {
  return pickBy(
    {
      name: place.formatted_address ?? place.name,
      route: getAddressComponent(place, 'route')?.long_name,
      streetNumber: getAddressComponent(place, 'street_number')?.long_name,
      city: getAddressComponent(place, 'administrative_area_level_3')
        ?.long_name,
      province: getAddressComponent(place, 'administrative_area_level_2')
        ?.short_name,
      countryCode: getAddressComponent(place, 'country')?.short_name,
      postalCode: getAddressComponent(place, 'postal_code')?.long_name,
      coordinates: {
        lat: place.geometry?.location?.lat?.(),
        lon: place.geometry?.location?.lng?.(),
      },
    },
    identity
  ) as LocationInput;
}

function LocationDetails(p: { name: string; value: BasicLocation }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-column p-2">
      <FieldRow compact label={t('Street Name')}>
        <EditableTextField name={`${p.name}.route`} className="flex-grow" />
      </FieldRow>

      <FieldRow compact label={t('Street Number')}>
        <EditableTextField name={`${p.name}.streetNumber`} />
      </FieldRow>
      <FieldRow compact label={t('City')}>
        <EditableTextField name={`${p.name}.city`} />
      </FieldRow>

      <FieldRow compact label={t('Province')}>
        <EditableTextField name={`${p.name}.province`} />
      </FieldRow>

      <FieldRow compact label={t('Postal Code')}>
        <EditableTextField name={`${p.name}.postalCode`} />
      </FieldRow>
      <FieldRow compact label={t('Country')}>
        <>
          {p.value.countryCode != null ? (
            <Flag countryCode={p.value.countryCode} />
          ) : (
            '-'
          )}
        </>
      </FieldRow>
    </div>
  );
}

export const LazyMapLocationField = React.lazy(
  () => import('./MapLocationField')
);

export function LocationField(p: LocationFieldProps) {
  const google = useGoogleMaps();
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>();
  const autocomplete = useRef<google.maps.places.Autocomplete>();
  const [field, meta, helpers] = useField<BasicLocation | null>(p.name);

  const value = field.value;

  const handlePlaceChange = useCallback(() => {
    const place = autocomplete.current.getPlace();
    helpers.setValue(getDetails(place));
  }, [p.name, helpers]);

  useEffect(() => {
    if (google != null) {
      const input = ref.current.getElementsByTagName('input')[0];
      autocomplete.current = new google.maps.places.Autocomplete(input, {
        types: p.types,
      });
      autocomplete.current.setFields(p.fields);
      autocomplete.current.addListener('place_changed', handlePlaceChange);
    }
  }, [google]);
  return (
    <span className="location-field-container" ref={ref}>
      {p.minimal === false ? (
        <TextField
          name={`${p.name}.name`}
          placeholder={t('Type to search...')}
        />
      ) : (
        <EditableTextField
          placeholder={t('Type to search...')}
          {...p}
          intent={meta.error != null ? Intent.WARNING : null}
          alwaysRenderInput={true}
          name={`${p.name}.name`}
        />
      )}

      {value != null && p.info !== false && (
        <Popover2
          interactionKind="hover"
          content={<LocationDetails name={p.name} value={value} />}
        >
          <Button icon="info-sign" minimal />
        </Popover2>
      )}
    </span>
  );
}

// function Test(p: { name: string }) {
//   const google = useGoogleMaps();
//   const [mapState, setMapState] = useState(null);
//   const [map, setMap] = useState(null);
//   const [marker, setMarker] = useState(null);
//   const [showInfo, setShowInfo] = useState(false);
//   const [bounds, setBounds] = useState<google.maps.LatLngBounds>(
//     google !== undefined ? new google.maps.LatLngBounds() : null
//   );
//   const values = p.name;

//   useEffect(() => {
//     console.log('Google: ', google);

//     if (google !== undefined) {
//       //console.log(new google.maps.LatLngBounds());
//       setBounds(new google.maps.LatLngBounds());
//     }

//     map != null ?? map.fitBounds(bounds);
//     console.log('Map: ', map);
//     console.log('Bounds', bounds);
//   }, [values]);

//   const points = [
//     values?.map((value: { location?: BasicLocation }) => {
//       return value.location ? value.location?.coordinates : null;
//     }),
//   ];

//   bounds !== null ??
//     points.map((point) => {
//       bounds.extend(point);
//     });

//   return (
//     <>
//       {values ? (
//         <div className={mapFieldClassName}>
//           <Map
//             ref={(ref: any) => {
//               setMap(ref);
//             }}
//             containerStyle={containerStyle}
//             google={google}
//             //zoom={4}
//             InitialCenter={{
//               lat: 43,
//               lng: 13,
//             }}
//             bounds={bounds}
//             onReady={(mapProps, map) => {
//               setMapState({ map: map as google.maps.Map });
//               setMap(map);
//             }}
//           >
//             {values.map((value: { location: BasicLocation }) => {
//               const onClickMarker = (props, marker) => {
//                 setMarker(marker);
//                 setShowInfo(true);
//               };
//               return value?.location?.coordinates ? (
//                 <Marker
//                   onClick={onClickMarker}
//                   title={value.location.name}
//                   position={{
//                     lat: value?.location?.coordinates?.lat,
//                     lng: value?.location?.coordinates?.lon,
//                   }}
//                 />
//               ) : null;
//             })}
//             <InfoWindow
//               map={mapState as google.maps.Map}
//               google={google}
//               visible={showInfo}
//               marker={marker}
//             >
//               <div>{marker?.title}</div>
//             </InfoWindow>
//           </Map>
//         </div>
//       ) : null}
//     </>
//   );
// }

const mapFieldClassName = css`
  width: 100%;
  height: 400px;
  padding: 15px;
  margin-bottom: 5px;
`;
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};
