const FRAGMENT = `
fragment TreeNodeTranslations on TreeNode {
  translations {
    it {
      title
      data
    }
    en {
      title
      data
    }
    es {
      title
      data
    }
}
}`;

export const QUERIES = {
  GET_QUOTATION:
    'query Quotation($id: String!) { quotation(id: $id) { id revision discount { type value } title customFields pdf progressiveNumber year status vendor { type id name } vendorRepresentative { id name } client { type id name } clientRepresentative { id name } note expirationDate date records { id activityId title vatId costPrice markup unit quantity } } }',
  RETRIEVE_QUOTATIONS:
    'query RetrieveQuotations { quotations { pageInfo { endCursor total } items { id title total netTotal revision date status year client { type id name } progressiveNumber records { id title unit costPrice quantity markup} } } }',
  SEARCH_ACTIVITY_TREE_NODE:
    `query SearchActivity($queryString: String!) { searchActivityTreeNode(queryString: $queryString) { items { id title code data parentId numberOfChildrens ...TreeNodeTranslations } } } ${FRAGMENT}`,
  SEARCH_EDUCATION_TREE_NODE:
    `query SearchActivity($queryString: String!) { searchEducationTreeNode(queryString: $queryString) { items { id title code data parentId numberOfChildrens ...TreeNodeTranslations } } } ${FRAGMENT}`,
  SEARCH_ECONOMIC_TREE_NODE:
    `query SearchActivity($queryString: String!) { searchEconomicActivitesTreeNode(queryString: $queryString) { items { id title code data parentId numberOfChildrens ...TreeNodeTranslations } } } ${FRAGMENT}`,
  SEARCH_OCCUPATIONS_TREE_NODE:
    `query SearchActivity($queryString: String!) { searchOccupationsTreeNode(queryString: $queryString) { items { id title code data parentId numberOfChildrens ...TreeNodeTranslations } } } ${FRAGMENT}`,

};

export const MUTATIONS = {
  CREATE_QUOTATION:
    'mutation CreateQuotation($quotation: QuotationInput!) { createQuotation(quotation: $quotation) }',
  ADD_QUOTATION_RECORD:
    'mutation AddQuotationRecord($parentId: String!, $record: AddQuotationRecordInput!) { addQuotationRecord(quotationId: $parentId, record: $record) }',
  UPDATE_QUOTATION_RECORD:
    'mutation UpdateQuotationRecord($parentId: String!, $record: UpdateQuotationRecordInput!) { updateQuotationRecord(quotationId: $parentId, record: $record) }',
  REMOVE_QUOTATION_RECORD:
    'mutation RemoveQuotationRecord($parentId: String!, $id: String!) { removeQuotationRecord(quotationId: $parentId, id: $id) }',
  MOVE_QUOTATION_RECORD:
    'mutation MoveQuotationRecord($parentId: String!, $id: String!, $after: String) { moveQuotationRecord(quotationId: $parentId, id: $id, after: $after) }',
  SET_TITLE:
    'mutation SetQuotationTitle($id: String!, $title: String!) { setQuotationTitle(id: $id, title: $title) }',
  SET_DATE:
    'mutation SetQuotationDate($id: String!, $date: String!) { setQuotationDate(id: $id, date: $date) }',
  SET_NOTE:
    'mutation SetQuotationNote($id: String!, $note: String!) { setQuotationNote(id: $id, note: $note) }',
  SET_CLIENT:
    'mutation SetQuotationClient($id: String!, $client: QuotationActorInput!) { setQuotationClient(id: $id, client: $client) }',
  SET_DISCOUNT:
    'mutation SetQuotationDiscount($id: String!, $discount: QuotationDiscountInput!) { setQuotationDiscount(id: $id, discount: $discount) }',
  SET_EXPIRATION_DATE:
    'mutation SetQuotationExpirationDate($id: String!, $expirationDate: String!) { setQuotationExpirationDate(id: $id, expirationDate: $expirationDate) }',
  FINALIZE_QUOTATION:
    'mutation FinalizeQuotation($id: String!) { finalizeQuotation(id: $id) }',
  SET_CLIENT_REPRESENTATIVE:
    'mutation SetClientRepresentative($id: String!, $representative: String) { setQuotationClientRepresentative(id: $id, representative: $representative) }',
  SET_VENDOR_REPRESENTATIVE:
    'mutation SetVendorRepresentative($id: String!, $representative: String) { setQuotationVendorRepresentative(id: $id, representative: $representative) }',
  SET_CUSTOM_FIELD:
    'mutation SetQuotationCustomField($id: String!, $name: String!, $type: QuotationCustomFieldType!, $value: JSON){setQuotationCustomField(id:$id, name: $name, type: $type, value: $value)}',
  UNSET_CUSTOM_FIELD:
    'mutation UnsetQuotationCustomField($id: String!, $name: String!){unsetQuotationCustomField(id: $id, name: $name)}'

};
