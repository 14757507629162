import React from 'react';
import lazy, { PreloadableLazyComponent } from 'src/utils/lazy';
import { Route as BrowserRoute } from 'react-router-dom';
import loadable from '@loadable/component';

export interface Routes {
  [key: string]: PreloadableRoute<any>;
}
export interface PreloadableRoute<T extends React.ComponentType<any>> {
  component: PreloadableLazyComponent<T>;
  path: string;
  public?: boolean;
  exact?: boolean;
}

export default {
  Login: {
    path: '/login',
    public: true,
    component: loadable(() => import('src/pages/LoginPage')),
  },
  ForgotPassword: {
    path: '/forgotPassword',
    public: true,
    component: loadable(() => import('src/pages/ForgotPasswordPage')),
  },
  PersonUpdate: {
    path: '/person/:id/registration',
    public: true,
    component: loadable(() => import('src/pages/PersonUpdatePage')),
  },
  PersonRegister: {
    path: '/person/registration',
    public: true,
    component: loadable(() => import('src/pages/PersonUpdatePage')),
  },
  Person: {
    path: '/person/:id',
    component: loadable(() => import('src/pages/Person')),
  },
  Credits: {
    path: '/person/:id/credits',
    exact: true,
    component: loadable(() => import('src/pages/Credits/CreditsPage')),
  },
  People: {
    path: '/people',
    component: loadable(() => import('src/pages/People')),
  },
  Quotations: {
    path: '/quotations',
    component: loadable(() => import('src/pages/Quotations')),
  },
  UploadTypes: {
    path: '/uploadTypes',
    component: loadable(() => import('src/pages/UploadTypes')),
  },
  Companies: {
    path: '/companies',
    component: loadable(() => import('src/pages/Companies')),
  },

  Test: {
    path: '/test',
    component: loadable(() => import('src/pages/TestPage')),
  },
  Invoices: {
    path: '/invoices',
    component: loadable(() => import('src/pages/Invoices')),
  },
  Emails: {
    path: '/emails',
    component: loadable(() => import('src/pages/EmailsPage')),
  },
  Trees: {
    path: '/trees',
    component: loadable(() => import('src/pages/TreesPage')),
  },
  Templates: {
    path: '/templates',
    component: loadable(() => import('src/pages/TemplatePage')),
  },
  EmailAccounts: {
    path: '/emailAccounts',
    component: loadable(() => import('src/pages/EmailAccountsPage')),
  },
  Users: {
    path: '/users',
    component: loadable(() => import('src/pages/UsersPage')),
  },
  Conversations: {
    path: '/conversations',

    component: loadable(() => import('src/pages/TelegramConversations')),
  },
  Variables: {
    path: '/variables',
    component: loadable(() => import('src/pages/VariablesPage')),
  },
  VariableExplorer: {
    path: '/variablesExplorer',
    component: loadable(() => import('src/pages/VariableExplorerPage')),
  },
  Questions: {
    path: '/questions',
    component: loadable(() => import('src/pages/QuestionsPage')),
  },
  Surveys: {
    path: '/surveys',
    component: loadable(() => import('src/pages/Survey/SurveysPage')),
  },
  SurveyRegister: {
    path: '/surveys/register',
    public: true,
    component: loadable(() => import('src/pages/Survey/SurveyRegister')),
  },
  Dashboard: {
    path: '/dashboard/:id',
    exact: true,
    component: loadable(() => import('src/pages/DashboardPage')),
  },
  Survey: {
    path: '/survey/:id',
    exact: true,
    component: loadable(() => import('src/pages/Survey/SurveyPage')),
  },
  SurveyRedeem: {
    public: true,
    path: '/survey/:id/redeem',
    component: loadable(() => import('src/pages/Survey/SurveyRedeem')),
  },
  ConfirmUser: {
    path: '/confirmUser/:id/:code',
    component: loadable(() => import('src/pages/ConfirmUserPage')),
  },
  AdminHealth: {
    path: '/admin/health',
    component: loadable(() => import('src/pages/AdminHealthPage')),
  },
  AdminMessageStore: {
    path: '/admin/messages',
    component: loadable(() => import('src/pages/AdminMessageStorePage')),
  },
  AdminGql: {
    path: '/admin/gql',
    component: loadable(() => import('src/apps/gql/pages/home')),
  },
} as Routes;
