import { set, update } from 'lodash/fp';
import { WorkflowActions } from '..';
import { DrawerState, WorkflowState, WorkflowView } from '../../workflow/types';
import { recalculateViewport } from './viewport';

const MIN_DRAWER_WIDTH = 240;

export enum WorkflowDesignerActionType {
  LOAD = 'LOAD',
  RENAME = 'RENAME',
  OPEN_DIALOG_LIBRARY = 'OPEN_DIALOG_LIBRARY',
  SHOW_CANVAS = 'SHOW_CANVAS',
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
  CLOSE_DIALOG_LIBRARY = 'CLOSE_DIALOG_LIBRARY',
  TOGGLE_MINIMAL_LAYOUT = 'TOGGLE_MINIMAL_LAYOUT',
  SHOW_ADD_DIALOG = 'SHOW_ADD_DIALOG',
  CLOSE_ADD_DIALOG = 'CLOSE_ADD_DIALOG',
  SET_DRAWER_STATE = 'SET_DRAWER_STATE',
}

export type WorkflowDesignerActions =
  | { type: WorkflowDesignerActionType.LOAD; state: WorkflowState }
  | { type: WorkflowDesignerActionType.RENAME; name: string }
  | { type: WorkflowDesignerActionType.SHOW_ADD_DIALOG }
  | { type: WorkflowDesignerActionType.CLOSE_ADD_DIALOG }
  | { type: WorkflowDesignerActionType.OPEN_DIALOG_LIBRARY }
  | { type: WorkflowDesignerActionType.SHOW_CANVAS }
  | { type: WorkflowDesignerActionType.SHOW_DASHBOARD }
  | {
      type: WorkflowDesignerActionType.SET_DRAWER_STATE;
      drawer: Partial<DrawerState>;
    }
  | { type: WorkflowDesignerActionType.CLOSE_DIALOG_LIBRARY }
  | { type: WorkflowDesignerActionType.TOGGLE_MINIMAL_LAYOUT };

export function reduce(
  prev: WorkflowState,
  action: WorkflowActions
): WorkflowState {
  switch (action.type) {
    case WorkflowDesignerActionType.LOAD:
      return action.state;
    case WorkflowDesignerActionType.RENAME:
      return set('name', action.name)(prev);
    case WorkflowDesignerActionType.OPEN_DIALOG_LIBRARY:
      return set('libraryDialog.isOpen', true)(prev);
    case WorkflowDesignerActionType.CLOSE_DIALOG_LIBRARY:
      return set('libraryDialog.isOpen', false)(prev);
    case WorkflowDesignerActionType.SHOW_CANVAS:
      return set('view', WorkflowView.CANVAS)(prev);
    case WorkflowDesignerActionType.SHOW_DASHBOARD:
      prev.view = WorkflowView.DASHBOARD;
      prev.drawer.isOpen = false;
      return {
        ...prev,
        view: WorkflowView.DASHBOARD,
        drawer: { ...prev.drawer, isOpen: false },
      };
    case WorkflowDesignerActionType.TOGGLE_MINIMAL_LAYOUT:
      return update('minimalLayout', (p) => !p)(prev);
    case WorkflowDesignerActionType.SHOW_ADD_DIALOG:
      return set('showAddDialog', true)(prev);
    case WorkflowDesignerActionType.CLOSE_ADD_DIALOG:
      return set('showAddDialog', false)(prev);
    case WorkflowDesignerActionType.SET_DRAWER_STATE:
      const width = Math.max(
        action.drawer.width ?? prev.drawer.width,
        MIN_DRAWER_WIDTH
      );
      prev.drawer = { ...prev.drawer, ...action.drawer, width };
      prev.viewport = recalculateViewport(prev.viewport, prev.drawer);
      return prev;
  }
  return prev;
}
