export const SEARCH_QUERY =
  'query SuggestFirstName($queryString: String!) { peopleSuggester(query: $queryString, field: "suggestion") { id firstName lastName fiscalCode } }';

export const SEARCH_PEOPLE_BY_FIRST_NAME =
  'query SuggestFirstName($queryString: String!) { peopleSuggester(query: $queryString, field: "firstName") { id firstName lastName fiscalCode } }';

export const SEARCH_PEOPLE_BY_LAST_NAME =
  'query SuggestLastName($queryString: String!) { peopleSuggester(query: $queryString, field: "lastName") { id firstName lastName fiscalCode } }';

export const SEARCH_QUERY_WITH_MEDIUMS =
  'query SearchPeople($queryString: String!) { peopleSuggester(query: $queryString, field: "suggestion") { id firstName lastName emailAddresses { emailAddress } phoneNumbers { number } telegram { chatId} } }';
