import React from 'react';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { Flag } from '../Flag';

export default function SurveyLanguages(p: { surveyId: string }) {
  const { data, isLoading } = useGetSurveyWithQuestions(p.surveyId);

  return (
    <div className="flex items-center space-x-2">
      {data.survey?.languages?.map((code, index) => (
        <Flag key={index} countryCode={code} style={{ width: 30 }} />
      ))}
    </div>
  );
}
