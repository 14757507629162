import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Colors,
  Dialog,
  Divider,
  Expander,
  FormGroup,
  Icon,
  IconName,
  Intent,
  Menu,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  ProgressBar,
  Tag,
} from '@blueprintjs/core';
import { MenuItem2, Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Form, Formik, useField, useFieldValue, useStatus } from 'formik';
import { compact, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Person,
  Ref,
  Survey,
  SurveyShare as ISurveyShare,
  SurveyShare,
  SurveyShareInput,
  SurveyShareMedium,
  SurveyShareMetrics,
  SurveyShareType,
} from 'src/apps/athena/gql-types';
import { useNotifications } from 'src/context/NotificationContext';
import { SEARCH_QUERY_WITH_MEDIUMS } from 'src/graphql/people';
import { MUTATIONS, QUERY } from 'src/graphql/surveys';
import { useGetPerson } from 'src/hooks/crm';
import { useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { useDateAccessor } from 'src/utils/accessors';
import { useAlert } from 'src/utils/alert';
import { useSendCommand, useSubscribeForEvents } from 'src/utils/events';
import { useGraphQLFetch } from 'src/utils/graphql';
import { AppColumn, useAppTable } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import {
  CheckboxField,
  NumericInputField,
  TextAreaField,
  ToggleButtonField,
} from '../BlueprintFields';
import { BottomBar } from '../BottomBar';
import DateField from '../DateField';
import { DatePickerField } from '../DatePicker';
import { PersonSuggesterField } from '../GraphQLSuggester';
import { useRefLinkAccessor } from '../RefLink';
import SubmitButton from '../SubmitButton';
import { Table } from '../table';
import {
  useDisableSurveyShare,
  useEnableSurveyShare,
  useRemoveSurveyShare,
} from './shareHooks';
import { SurveyCompletionBar } from './SurveyCompletionBar';
import {
  SurveyShareChangeQuotaDialog,
  SurveyShareDialog,
} from './SurveyShareDialogs';

export function SurveyMetrics(p: { metrics: SurveyShareMetrics }) {
  const { metrics } = p;
  if (metrics == null) {
    return <>-</>;
  }
  const redemption = (metrics.opens / metrics.closes) * 100;

  return (
    <div className="flex">
      <SurveyMetric
        metric={metrics.opens}
        icon="document-open"
        tooltip="Number of sessions opened"
        color="blue"
      />
      <SurveyMetric
        metric={metrics.closes}
        icon="saved"
        tooltip="Number of sessions completed"
        color="green"
      />
      <SurveyMetric
        metric={metrics.screenout}
        icon="document-share"
        tooltip="Number of screen out"
        color="yellow"
      />
      <SurveyMetric
        metric={Math.max(0, metrics.opens - metrics.closes - metrics.screenout)}
        icon="filter-remove"
        tooltip="Number of dropouts"
        color="pink"
      />

      {'numberOfDevices' in metrics && (
        <SurveyMetric
          metric={metrics.numberOfDevices}
          icon="desktop"
          color="blue"
          tooltip="Number of different devices"
        />
      )}
      {'assignedQuotas' in metrics && (
        <SurveyMetric
          metric={metrics.assignedQuotas}
          icon="target"
          color="orange"
          tooltip="Number of assigned quotas"
        />
      )}
    </div>
  );
}

export function SurveyMetric(p: {
  metric: string | number;
  icon: IconName;
  tooltip: string;
  color: string;
}) {
  return (
    <div className="flex items-center text-gray-400 mx-0.5">
      <Tooltip2 hoverOpenDelay={500} content={p.tooltip} placement="bottom">
        <Icon icon={p.icon} className={`px-1 opacity-70 text-${p.color}-500`} />
      </Tooltip2>
      {p.metric}
    </div>
  );
}
