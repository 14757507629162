type MaybeString = { [key: string]: boolean } | string;

function firstAndOnlyKeyFrom(dict: { [key: string]: boolean }) {
  return Object.keys(dict)[0];
}

export function strings(...strings: MaybeString[]) {
  const returnString = strings.find((dict) => {
    if (typeof dict === "string") {
      return true;
    }
    const firstAndOnlyKey = firstAndOnlyKeyFrom(dict);
    if (firstAndOnlyKey == null) {
      return false;
    }
    return dict[firstAndOnlyKey] === true;
  });
  if (returnString == null) {
    return null;
  }
  if (typeof returnString === "string") {
    return returnString;
  } else {
    return firstAndOnlyKeyFrom(returnString);
  }
}
