import { last } from 'lodash';

export type CancellablePromise<T> = [Promise<T>, () => void];

export const serialPromises = <T>(
  fns: ((prev?: T) => Promise<T>)[]
): CancellablePromise<T[]> => {
  let isCancelled = false;
  const cancel = () => (isCancelled = true);
  const promise = fns.reduce(
    (promise, fn) =>
      promise.then((results) =>
        isCancelled ? results : fn(last(results)).then((r) => [...results, r])
      ),
    Promise.resolve([] as T[])
  );
  return [promise, cancel];
};

export const parallelPromises = <T>(
  fns: (() => Promise<T>)[]
): CancellablePromise<T[]> => {
  const promise = Promise.all(fns.map((fn) => fn()));
  return [promise, () => null];
};

export function runAfter(ms: number, fn: () => void) {
  return setTimeout(fn, ms);
}
