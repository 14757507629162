import React, { useMemo, useCallback, useState } from 'react';
import { SurveyQuestion, Question } from 'src/apps/athena/gql-types';
import { useDiffableFieldArray } from 'src/utils/formik';
import { v4 } from 'src/utils/uuid';
import { EditQuestionDialog, QuestionsGalleryDialog } from './QuestionsGallery';

export type OpenGalleryFn = (options?: {
  editingQuestion?: SurveyQuestion;
  index?: number;
}) => void;

export function useAddQuestionGallery(reloadFn: () => void) {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [desiredIndex, setDesiredIndex] = useState(null);
  const [editingQuestion, setEditingQuestion] = useState<SurveyQuestion | null>(
    null
  );

  const gallery = editingQuestion ? (
    <EditQuestionDialog
      isOpen={galleryOpen}
      surveyQuestionId={editingQuestion.id}
      id={editingQuestion.questionId}
      onClose={() => {
        reloadFn();
        setGalleryOpen(false);
        setDesiredIndex(null);
        setEditingQuestion(null);
      }}
    />
  ) : (
    <AddQuestionsGalleryDialog
      isOpen={galleryOpen}
      desiredIndex={desiredIndex}
      onClose={() => {
        reloadFn();
        setGalleryOpen(false);
        setDesiredIndex(null);
        setEditingQuestion(null);
      }}
    />
  );
  const openGallery = useCallback(
    (options?: { editingQuestion?: SurveyQuestion; index?: number }) => {
      console.log('Open', options);
      setDesiredIndex(options?.index);
      setEditingQuestion(options?.editingQuestion);
      setGalleryOpen(true);
    },
    []
  );

  return { gallery, openGallery };
}

export function AddQuestionsGalleryDialog(p: {
  isOpen: boolean;
  clearCache?: () => void;
  desiredIndex?: number;
  onClose: () => void;
}) {
  const [field, _, helpers] =
    useDiffableFieldArray<SurveyQuestion>('questions');
  const addedQuestions = useMemo(
    () => field.value.map((d: SurveyQuestion) => d.id),
    [field.value]
  );
  const handleAddQuestion = useCallback(
    (question: Question) => {
      p.onClose();
      const surveyQuestion: SurveyQuestion = {
        ...question,
        id: v4(),
        questionId: question.id,
        __typename: 'SurveyQuestion',
      };

      if (p.desiredIndex != null) {
        helpers.insert(surveyQuestion, p.desiredIndex);
      } else {
        helpers.push(surveyQuestion);
      }
      p.clearCache?.();
    },
    [helpers, p.desiredIndex, p.clearCache]
  );
  return (
    <QuestionsGalleryDialog
      {...p}
      addedQuestions={addedQuestions}
      onAddQuestion={handleAddQuestion}
    />
  );
}
