import { Intent, ProgressBar } from '@blueprintjs/core';
import React from 'react';
import { Survey } from 'src/apps/athena/gql-types';

export function SurveyCompletionBar(props: {
  survey: Survey;
  showLabel?: boolean;
}) {
  const { survey } = props;
  if (survey.metrics == null || survey.target?.amount == null) {
    return <span className="text-gray-500">-</span>;
  }
  const intl = new Intl.NumberFormat('it', {
    style: 'percent',
    maximumSignificantDigits: 2,
  });
  const progress = Math.min(1, survey.metrics.closes / survey.target.amount);
  return (
    <div className="flex items-center">
      <ProgressBar
        intent={Intent.PRIMARY}
        animate={false}
        className="h-2 w-24"
        value={progress}
      />
      {props.showLabel !== false && (
        <span className="font-medium text-gray-600 px-2">
          {intl.format(progress)}
        </span>
      )}
    </div>
  );
}
