import React, { useMemo } from 'react';
import type { Project } from 'src/apps/athena/gql-types';
import { AppColumn, FilterKind } from 'src/utils/table/useAppTable';
import { usePaginatedTableQuery } from 'src/utils/usePaginatedTableQuery';
import { useTranslation } from 'react-i18next';
import { useCrumbs } from 'src/context/CrumbsContext';
import {
  OmnibarSource,
  useOmnibarPreferredSourceKey,
} from 'src/context/OmnibarContext';
import { PROJECT_QUERY } from 'src/graphql/project';
import { getEndDate, getStartDate } from 'src/utils/accessors';
import { Table } from 'src/components/table';
import { Menu, MenuItem } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';

export const omnibarSource: OmnibarSource = {
  key: 'project',
  kind: 'project',
  icon: 'projects',
  title: 'Projects',
  query: PROJECT_QUERY.SEARCH_QUERY_OMNIBAR,
  dataKey: 'projects',
  valueRenderer: (t: Project) => t.title,
};

export default function ProjectsPage() {
  const { t } = useTranslation('projects');
  useOmnibarPreferredSourceKey('project');
  useCrumbs([
    { href: '/projects', text: t('Projects', { ns: 'translation' }) },
  ]);

  const columns = useMemo<AppColumn<Project>[]>(
    () => [
      {
        id: 'code',
        Header: t('Code'),
        width: 100,
        accessor: 'code',
        filter: {
          id: 'code',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'title',
        Header: t('Title'),
        width: 200,
        accessor: 'title',
        filter: {
          id: 'title',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'description',
        Header: t('Description'),
        width: 200,
        accessor: 'description',
        filter: {
          id: 'description',
          kind: FilterKind.TEXT,
        },
      },
      {
        id: 'estimatedTaskDuration',
        Header: t('Estimated Task Duration'),
        accessor: 'estimatedTaskDuration',
        width: 200,
      },
      {
        id: 'timeline.startDate',
        Header: t('Start Date'),
        accessor: getStartDate,
        width: 200,
      },
      {
        id: 'timeline.endDate',
        Header: t('End Date'),
        accessor: getEndDate,
        width: 200,
      },
      {
        id: 'createdAt',
        Header: t('Created At'),
        accessor: 'createdAt',
        width: 200,
        isVisible: false,
      },
      {
        id: 'updatedAt',
        Header: t('Updated At'),
        accessor: 'updatedAt',
        width: 200,
        isVisible: false,
      },
    ],
    []
  );

  const navigate = useNavigate();
  const { table, ref } = usePaginatedTableQuery(
    PROJECT_QUERY.SEARCH_PROJECTS_LIST,
    'projects',
    'project',
    {
      columns,
      onRowClick: (project: Project) => {
        navigate(`/project/${project.id}`);
      },
      contextMenu: (project: Project) => (
        <Menu>
          <MenuItem
            text={t('Show Candidates')}
            icon="people"
            onClick={(e: React.MouseEvent) => {
              // e.stopPropagation();
              navigate(`/project/${project?.id}/candidates`);
            }}
          />
        </Menu>
      ),
    }
  );

  return (
    <div
      className="card flex-grow flex flex-column p0 overflow-hidden"
      ref={ref}
    >
      <Table className="flex-grow" {...table} />
    </div>
  );
}
