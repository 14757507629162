import { IconName } from '@blueprintjs/core';
import { QuestionType } from 'src/apps/athena/gql-types';

export function iconForQuestionType(type: QuestionType): IconName {
  switch (type) {
    case QuestionType.MultipleChoice:
      return 'list';
    case QuestionType.Date:
      return 'calendar';
    case QuestionType.Rating:
      return 'star-empty';
    case QuestionType.Ranking:
      return 'random';
    case QuestionType.Percentage:
      return 'percentage';
    case QuestionType.MultipleText:
      return 'array-string';
    case QuestionType.PhoneNumber:
      return 'phone';
    case QuestionType.EmailAddress:
      return 'envelope';
    case QuestionType.Number:
      return 'numerical';
    case QuestionType.Text:
      return 'bold';
    case QuestionType.OpinionScale:
      return 'sort-asc';
    case QuestionType.Photo:
      return 'camera';
    case QuestionType.Tree:
      return 'diagram-tree';
    default:
      return 'form';
  }
}
