import { useEffect, useState } from 'react';

const CDN_URL = 'https://cdn.jsdelivr.net/pyodide';
const PYODIDE_VERSION = 'v0.21.0';
const LOADING_INTERVAL = 300;

interface LocalPython {
  isReady: boolean;
  python?: Pyodide;
}

export function useLocalPython(): LocalPython {
  const [python, setPython] = useState(null);
  useEffect(() => {
    // First load async the python env.
    const baseUrl = `${CDN_URL}/${PYODIDE_VERSION}/full/`;
    window['languagePluginUrl'] = baseUrl;

    let element = document.createElement('script');
    element.type = 'text/javascript';
    element.src = `${baseUrl}pyodide.js`;
    document.body.append(element);

    const interval = setInterval(() => {
      if ('loadPyodide' in window) {
        clearInterval(interval);
        loadPyodide().then((p) => {
          window['Pyodide'] = p;
          setPython(p);
        });
      }
    }, LOADING_INTERVAL);
  }, []);

  return {
    isReady: python != null,
    python,
  };
}
