import React from 'react';
import { Certificate, CertificateStatus } from '../../gql-types';
import { EnumTag, TagDefinition, EnumTagProps } from 'src/components/EnumTag';
import { Intent } from '@blueprintjs/core';

export const certificateStatusItems: TagDefinition<CertificateStatus>[] = [
  {
    value: CertificateStatus.Active,
    label: 'Attivo',
    intent: Intent.SUCCESS,
    icon: 'tick',
  },
  {
    value: CertificateStatus.Cancelled,
    label: 'Cancellato',
    intent: Intent.NONE,
    icon: 'eraser',
  },
  {
    value: CertificateStatus.Revoked,
    label: 'Revocato',
    intent: Intent.DANGER,
    icon: 'disable',
  },
  {
    value: CertificateStatus.Suspended,
    label: 'Sospeso',
    intent: Intent.WARNING,
    icon: 'pause',
  },
];

export default function CertificateStatusEnumTag(
  p: Omit<EnumTagProps<CertificateStatus>, 'value' | 'items'> & {
    value: Partial<Certificate>;
  }
) {
  const { value, ...other } = p;
  const { status } = p.value;
  return status != null ? (
    <EnumTag
      uppercase
      items={certificateStatusItems}
      value={status}
      {...other}
    />
  ) : (
    <span />
  );
}
