import { useCallback } from 'react';
import { useEnvironment } from 'src/context/EnvironmentContext';

import { AppColumn, AppColumnFilter } from 'src/utils/table/useAppTable';

export type CustomizeColumns<T extends object> = (
  columns: AppColumn<T>[]
) => AppColumn<T>[];

export function useColumnCustomizer<T extends object>(
  key: string
): CustomizeColumns<T> {
  const { COLUMNS_CUSTOMIZERS } = useEnvironment();
  const customizer = COLUMNS_CUSTOMIZERS[key];
  if (customizer != null) {
    return customizer;
  } else {
    return useCallback((c) => c, []);
  }
}

export function applyToColumn<T extends object>(
  id: string | string[],
  column: Partial<AppColumn<T>>
): CustomizeColumns<T> {
  const checkFn = (d: AppColumn<T>) =>
    typeof id === 'string' ? d.id === id : id.includes(d.id);
  return (c) => c.map((d) => (checkFn(d) ? Object.assign({}, d, column) : d));
}

export function hideColumn<T extends object>(
  id: string | string[]
): CustomizeColumns<T> {
  return applyToColumn(id, { isVisible: false });
}

export function showColumn<T extends object>(
  id: string | string[]
): CustomizeColumns<T> {
  return applyToColumn(id, { isVisible: true });
}

export function removeColumn<T extends object>(
  id: string | string[]
): CustomizeColumns<T> {
  const checkFn = (d: AppColumn<T>) =>
    typeof id === 'string' ? d.id !== id : !id.includes(d.id);
  return (c) => c.filter(checkFn);
}

export function combine<T extends object>(
  ...customizers: CustomizeColumns<T>[]
): CustomizeColumns<T> {
  return (c) => customizers.reduce((prev, next) => next(prev), c);
}
