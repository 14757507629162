import { useQuery } from '@tanstack/react-query';
import { Query } from 'src/apps/athena/gql-types';
import { QUERIES } from 'src/apps/athena/graphql/crm';
import { useGraphQLFetch } from 'src/utils/graphql';

export function useGetPerson(
  id: string | null,
  options?: { enabled: boolean; staleTime?: number }
) {
  const graphqlFetch = useGraphQLFetch();
  const isNew = id == null;
  return useQuery<Pick<Query, 'person'>>(
    ['person', id],
    () => graphqlFetch(QUERIES.GET_PERSON, { id }),
    { enabled: options?.enabled ?? isNew, staleTime: options?.staleTime }
  );
}
export function useGetCompany(id: string, options?: { enabled: boolean }) {
  const graphqlFetch = useGraphQLFetch();

  return useQuery<Pick<Query, 'company'>>(
    ['company', id],
    () => graphqlFetch(QUERIES.GET_COMPANY, { id }),
    { enabled: options?.enabled ?? true }
  );
}
