import React, { useCallback } from 'react';
import {
  Breadcrumb,
  Breadcrumbs,
  Button,
  IBreadcrumbsProps,
  Icon,
} from '@blueprintjs/core';
import { Crumb, useCrumbsContext } from '../context/CrumbsContext';
import { useNavigate } from 'react-router-dom';
import { Popover2 } from '@blueprintjs/popover2';

export default function AppCrumbs() {
  const { crumbs } = useCrumbsContext();
  const navigate = useNavigate();

  const breadcrumbRenderer = useCallback((props: Crumb) => {
    const { menu, text, ...other } = props;
    if (menu) {
      return (
        <Popover2 content={menu}>
          <Breadcrumb {...other}>
            {text}
            <Icon icon="caret-down" className="ml-2" />
          </Breadcrumb>
        </Popover2>
      );
    }
    return <Breadcrumb {...props} />;
  }, []);

  const items = [{ href: '/#', text: 'Home' }, ...crumbs].map(
    ({ href, onClick, ...crumb }) => ({
      ...crumb,
      onClick: onClick ?? (() => navigate(href)),
    })
  );
  return (
    <Breadcrumbs
      items={items}
      className="text-white"
      breadcrumbRenderer={breadcrumbRenderer}
    />
  );
}
