import { Button, EditableText, Icon, Intent } from '@blueprintjs/core';
import { isEmpty } from 'lodash';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'src/utils/formik';
import { v4 } from 'src/utils/uuid';
import { EditableTextField } from '../BlueprintFields';

export function QuestionListField<T extends { id: string; body: string }>(p: {
  name: string;
  isEditingTranslations: boolean;
  placeholder: string;
}) {
  const { t } = useTranslation('survey');

  const [key, setKey] = useState(v4());
  const [field, , helpers] = useFieldArray<T>(p.name);
  const handleAddItem = useCallback(
    (val: string) => {
      setKey(v4());
      if (val.length > 0) {
        const vals = val
          .split('\n')
          .map((f) => f.trimStart().trimEnd().replace('\t', ''))
          .filter((f) => !isEmpty(f));
        helpers.push(
          vals.map(
            (body) =>
              ({
                body,
                id: v4(),
              } as T)
          )
        );
      }
    },
    [helpers]
  );
  return (
    <div className="flex flex-column">
      {field.value?.map((opt, index) => (
        <div className="question-option" key={opt.id}>
          <Icon icon="circle" iconSize={12} />
          <span className="secondary-text">
            <EditableTextField
              name={`${p.name}.${index}.body`}
              className="text-gray-800"
            />
          </span>
          <div className="flex-grow" />
          <Button
            icon="cross"
            intent={Intent.DANGER}
            minimal
            disabled={p.isEditingTranslations}
            small
            onClick={() => helpers.remove(index)}
          />
        </div>
      ))}
      <div className="question-option mb3">
        <Icon icon="circle" iconSize={12} />
        <EditableText
          key={key}
          className="flex-grow"
          multiline={true}
          disabled={p.isEditingTranslations}
          placeholder={p.placeholder}
          onConfirm={handleAddItem}
        />
      </div>
    </div>
  );
}
