import classNames from 'classnames';
import css from 'csz';
import { Formik } from 'formik';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Query } from 'src/apps/athena/gql-types';
import { QUERY } from 'src/graphql/surveys';
import { KeywordsQuestionsList } from 'src/pages/components/SurveyTemplateKeywordDialog';
import { useGetSurvey, useGetSurveyWithQuestions } from 'src/pages/Survey/SurveyPage';
import { useGraphQLFetch } from 'src/utils/graphql';
import CenteredSpinner from '../CenteredSpinner';
const className = css`/styles/survey.css`;

export default function SurveyTemplate(p: { surveyId: string }) {
  const { data } = useGetSurvey(p.surveyId);
  const { data: keywordsData } = useGetSurveyKeyowrds(p.surveyId);

  if (data?.survey == null || keywordsData?.surveyTemplateKeywords == null) {
    return <CenteredSpinner />;
  }

  return (
    <div className={classNames(className, 'px-3 py-4')}>
      <KeywordsQuestionsList
        surveyTemplate={data.survey}
        templateKeywords={keywordsData.surveyTemplateKeywords.items ?? []}
      />
    </div>
  );
}

export function useGetSurveyKeyowrds(id: string | null) {
  const fetch = useGraphQLFetch();
  return useQuery<Pick<Query, 'surveyTemplateKeywords'>>(
    ['surveyTemplateKeywords', id],
    () => fetch(QUERY.GET_TEMPLATE_KEYWORDS, { surveyTemplateId: id }),
    {
      enabled: id != null,
    }
  );
}
