import React, { useEffect, useCallback } from 'react';
import { Dialog, Classes, FormGroup, Intent, Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { TextField } from './BlueprintFields';
import { useFormik, FormikProvider } from 'formik';
import DateField from './DateField';
import { UploadTypeSuggesterField } from 'src/apps/athena/components/Suggesters';
import type { Upload, UploadInput } from 'src/apps/athena/gql-types';
import { useSendCommand } from 'src/utils/events';
import { object, string, date } from 'yup';
import { UploadFileDropzoneField, UploadedFile } from './FileDropzone';
import { appendPaginatedQueryData } from 'src/utils/queryCache';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import EditableList from './EditableList';
import { array } from 'yup';
import { serialPromises } from 'src/utils/promise';

const QUERY =
  'query Upload($id: String!) { upload(id: $id) { id name uri resources tags type } }';

interface P {
  isOpen: boolean;
  uri?: string[];
  name?: string[];
  type?: string;
  onUploadItem: (item: Upload) => void;
  onClose: () => void;
  resources: string[];
}

export default function UploadDialog(p: P) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [{ isLoading }, sendCommand] = useSendCommand<
    Upload,
    { upload: UploadInput },
    Upload
  >(
    'mutation Upload($upload: UploadInput) { upload(params: $upload) }',
    async (evt, ctx) => {
      if (evt.type === 'UPLOADED') {
        ctx.showSuccessNotification({
          message: t('Upload Succeded'),
        });
        ctx.complete(evt.data);
        p.onClose();
      }
    },
    {
      optimistic: false,
      onSuccess: (upload: Upload) => {
        const { type, resources } = upload;
        resources?.forEach((uri) => {
          appendPaginatedQueryData(
            queryClient,
            ['uploadsByResource', uri, type],
            'uploadsByResource',
            upload
          );
        });
      },
    }
  );

  const form = useFormik<
    Pick<UploadInput, 'date' | 'type' | 'resources'> & {
      uri: string[];
      name: string[];
    }
  >({
    initialValues: {
      date: new Date().toISOString(),
      uri: p.uri ?? [],
      name: p.name ?? [],
      type: p.type,
      resources: p.resources ?? [],
    },

    validationSchema: object({
      uri: array(string().required().url()).required().min(1),
      type: string().required().min(3),
      name: array(string().required().min(3)).required().min(1),
      date: date(),
    }),
    onSubmit: async (upload) =>
      serialPromises(
        upload.uri.map(
          (uri, index) => () =>
            sendCommand({
              upload: {
                ...upload,
                uri,
                name: upload.name[index],
              },
            })
        )
      ),
  });

  useEffect(() => {
    // console.log('Resetting', p.resources, p.uri, p.name);
    // console.log(form.values);
    if (
      (p.resources != null && !isEqual(p.resources, form.values.resources)) ||
      (p.uri != null && p.uri !== form.values.uri) ||
      (p.name != null && !isEqual(p.name, form.values.name))
    ) {
      console.log('Resetting because is changed');
      form.resetForm({
        values: {
          date: new Date().toISOString(),
          uri: p.uri ?? [],
          type: p.type,
          name: p.name ?? [],
          resources: p.resources ?? [],
        },
      });
    }
  }, [p.resources, p.uri, p.name]);

  const handleUpload = useCallback((files: UploadedFile[]) => {
    if (!isEmpty(files)) {
      form.setFieldValue(
        'name',
        files.map((n) => n.name)
      );
    }
  }, []);

  return (
    <Dialog
      onClose={p.onClose}
      icon="cloud-upload"
      title={t('Upload File')}
      isOpen={p.isOpen}
    >
      <FormikProvider value={form}>
        <div className={Classes.DIALOG_BODY}>
          {isEmpty(form.values.uri) ? (
            <UploadFileDropzoneField
              name="uri"
              multiple={true}
              onUpload={handleUpload}
            />
          ) : (
            <>
              <FormGroup label="Name">
                <EditableList
                  name="name"
                  canAddItem={() => false}
                  canRemoveItem={() => false}
                  component={TextField}
                />
              </FormGroup>
              <FormGroup label="Document Type">
                <UploadTypeSuggesterField name="type" />
              </FormGroup>

              <FormGroup label="Date">
                <DateField minimal={false} name="date" />
              </FormGroup>
            </>
          )}
        </div>
        {!isEmpty(form.values.uri) && (
          <div className={Classes.DIALOG_FOOTER}>
            <Button
              fill
              loading={isLoading}
              disabled={!form.isValid || isLoading}
              intent={Intent.PRIMARY}
              icon="tick"
              onClick={form.submitForm}
              text={t('Add File')}
            />
          </div>
        )}
      </FormikProvider>
    </Dialog>
  );
}
