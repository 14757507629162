import React, { useContext, useEffect, useMemo } from 'react'
import { Executor, PreviewFunction } from "@keix/workflow-types";
import { Dictionary } from "lodash";
import { createContext, useCallback, useState } from "react";
import { set, unset } from 'lodash/fp';
import { useLocalExecutor } from 'src/hooks/workflow/useLocalExecutor';

export interface PreviewContext {
  previews: Dictionary<JSX.Element | string>;
  clearPreviews: () => void;
  clearPreview: (id: string, prefix: boolean) => void;
  setPreview: (id: string, preview: JSX.Element | string) => void;
}

export const PreviewContext = createContext<PreviewContext>({
  previews: {},
  clearPreviews: () => null,
  setPreview: () => null,
  clearPreview: () => null,
});

export function usePreviewer() {
  const { previews, setPreview } = useContext(PreviewContext);
  const preview = useCallback((id: string, previewFn: () => string | JSX.Element) => {
    const value = previews[id];
    if (value != null) {
      // console.log('[Cache] Hit: preview found in cache', id)
      return previews[id];
    } else {
      // console.log('[Cache] Miss: preview not found cache', id)
      const preview = previewFn()
      if (preview != null) {
        setPreview(id, preview)
      }
      return preview
    }
  }, [previews])
  return preview;
}

export function usePreviewContext() {
  return useContext(PreviewContext)
}

export function useLocalPreviewer(): PreviewContext {
  const [previews, setPreviews] = useState({});

  const clearPreview = (id: string, prefix: boolean = false) => {
    if (prefix === true) {
      const keys = Object.keys(previews).filter(f => f.startsWith(id));
      setPreviews((v) => keys.reduce((prev, next) => unset(next)(prev), v));
    } else {
      setPreviews(unset(id))
    }
  }

  return useMemo(() => ({
    previews,
    clearPreviews: () => {
      setPreviews({})
    },
    setPreview: (id: string, preview: JSX.Element | string) => setPreviews(set(id, preview)),
    clearPreview,
  }), [previews])

}
