import { Button, IconName, Intent } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import React from 'react';

export function BottomBar(p: {
  className?: string;
  children?: JSX.Element | JSX.Element[];
  items?: BarItem[];
}) {
  return (
    <div
      className={`flex items-center py-2 px-3 sticky bottom-0 bg-white border-top ${
        p.className ?? ''
      }`}
    >
      {p.items?.map((item, index) => (
        <BottomBarItem item={item} key={index} />
      )) ?? p.children}
    </div>
  );
}

function BottomBarItem(p: { item: BarItem }) {
  if ('expand' in p.item) {
    return <div className="flex-grow" />;
  } else if ('label' in p.item) {
    return <label className="secondary-text">{p.item.text}</label>;
  } else if (p.item.menu != null) {
    return (
      <Popover2 position="bottom-right" content={p.item.menu}>
        <Button {...p.item} />
      </Popover2>
    );
  } else {
    return <Button {...p.item} />;
  }
}

export interface ButtonItem {
  text: string;
  icon: IconName | JSX.Element;
  rightIcon?: IconName | JSX.Element;
  menu?: JSX.Element;
  intent?: Intent;
  disabled?: boolean;
  loading?: boolean;
  minimal?: boolean;
  onClick?: () => void;
}

export interface LabelItem {
  label: true;
  text: string;
}

export interface ExpandItem {
  expand: true;
}

export type BarItem = ButtonItem | ExpandItem | LabelItem;
