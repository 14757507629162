import React, { Suspense, useEffect } from 'react';
import { Route } from 'react-router-dom';
import routes from './routes';
import CenteredSpinner from 'src/components/CenteredSpinner';
import AppContext from 'src/context/AppContext';
import NavigationBar from 'src/components/NavigationBar';
import env from './env';
import csz from 'csz';
import { EnvironmentContextProvider } from 'src/context/EnvironmentContext';
import { AppRouter, AppContent } from 'src/components/AppRouter';
import { ChangelogDialog } from 'src/components/Changelog';
import {
  AppQuickLookDrawer,
  useQuickLookRepository,
} from 'src/components/QuickLookDrawer';
import { useOmnibarRepositoryContext } from 'src/context/OmnibarContext';

import { omnibarSourceByName } from 'src/pages/People';
import { omnibarSource as companiesOmnibarSource } from 'src/pages/Companies';
import { omnibarSource as quotationOmnibarSource } from 'src/pages/Quotations';
import { omnibarSource as projectsOmnibarSource } from 'src/pages/Projects';
import AppOmnibar from 'src/components/AppOmnibar';
import { surveysOmnibarSource } from 'src/pages/Survey/SurveyPage';

const className = csz`/styles/app.css`;

function AppSetup(p: { children: JSX.Element }) {
  const { registerQuickLookProvider } = useQuickLookRepository();
  const { registerOmnibarSource } = useOmnibarRepositoryContext();

  useEffect(() => {
    registerQuickLookProvider({
      kind: 'person',
      component: React.lazy(() => import('src/components/crm/PersonDetail')),
    });
    registerQuickLookProvider({
      kind: 'company',
      component: React.lazy(() => import('src/components/crm/CompanyDetail')),
    });
    registerQuickLookProvider({
      kind: 'project',
      component: React.lazy(
        () => import('src/components/project/ProjectDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'candidate',
      component: React.lazy(
        () => import('src/components/project/ProjectCandidateDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'quotation',
      size: '65%',
      component: React.lazy(
        () => import('src/components/quotation/QuotationDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'email',
      component: React.lazy(() => import('src/components/EmailDetail')),
    });
    registerQuickLookProvider({
      kind: 'tree',
      component: React.lazy(() => import('src/components/trees/TreeDetail')),
    });

    registerQuickLookProvider({
      kind: 'variable',
      component: React.lazy(() => import('src/components/VariableDetail')),
    });
    registerQuickLookProvider({
      kind: 'survey',
      component: React.lazy(
        () => import('src/components/surveys/SurveyDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'surveyShare',
      component: React.lazy(
        () => import('src/components/surveys/SurveyShareDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'projectAutomation',
      size: '65%',
      component: React.lazy(
        () => import('src/components/ProjectAutomationDetail')
      ),
    });
    registerQuickLookProvider({
      kind: 'wizardSubscription',
      component: React.lazy(
        () => import('src/components/surveyWizard/WizardSubscriptionDetail')
      ),
    });

    registerQuickLookProvider({
      kind: 'messageStoreMessage',
      component: React.lazy(() => import('src/components/MessageStoreDetail')),
    });
    registerOmnibarSource(omnibarSourceByName);
    // registerOmnibarSource(quotationOmnibarSource);
    registerOmnibarSource(companiesOmnibarSource);
    registerOmnibarSource(projectsOmnibarSource);
    registerOmnibarSource(surveysOmnibarSource);
  }, []);

  return p.children;
}

export default function App() {
  return (
    <EnvironmentContextProvider value={env}>
      <Suspense fallback={<CenteredSpinner />}>
        <div className={className}>
          <AppContext>
            <>
              <AppRouter>
                <NavigationBar />
                <ChangelogDialog />
                <AppOmnibar />
                <AppQuickLookDrawer />
                <AppSetup>
                  <AppContent routes={routes} />
                </AppSetup>
              </AppRouter>
            </>
          </AppContext>
        </div>
      </Suspense>
    </EnvironmentContextProvider>
  );
}
