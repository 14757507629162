import { compact, flatten } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Node } from 'react-flow-renderer';
import { Block } from '@keix/workflow-types';
import { getCollapsedBlocks } from 'src/hooks/workflow/utils';
import { BlockNodeIcon } from './BlockIcon';
import {
  getNodeById,
  useWorkflowContext,
  useWorkflowState,
  WorkflowAction,
} from './WorkflowContext';
import classNames from 'classnames';
import { BlockSettings } from './BlockSetting';
import { useHotkey } from 'src/context/HotkeysContext';
import { useHotkeys } from 'src/utils/useHotkeys';
import { BlockDrawerHeader } from './BlockDrawerHeader';
import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';

export default function BlockDrawer() {
  const { state, dispatch } = useWorkflowContext();
  const { selectedNodeIds } = state;
  const { isOpen, width } = state.drawer;

  useHotkey({
    combo: 'option+shift+right',
    label: '',
    onKeyDown: () =>
      dispatch({
        type: WorkflowAction.SET_DRAWER_STATE,
        drawer: { isOpen: false },
      }),
  });

  useHotkey({
    combo: 'option+shift+left',
    label: '',
    onKeyDown: () =>
      dispatch({
        type: WorkflowAction.SET_DRAWER_STATE,
        drawer: { isOpen: true },
      }),
  });

  const nodes = useMemo(() => {
    const entries = [...selectedNodeIds.values()];
    const selectedNodes = compact(entries.map((d) => getNodeById(state, d)));
    return flatten(
      selectedNodes.map((node) => [node, ...getCollapsedBlocks(node.id, state)])
    );
  }, [selectedNodeIds, state]);

  // Set the drag hook and define component movement based on gesture data
  const bind: any = useDrag(
    ({ down, first, offset: [mx, my] }) => {
      dispatch({
        type: WorkflowAction.SET_DRAWER_STATE,
        drawer: { width: -mx },
      });
    },
    {
      from: (event) => [-width, 0],
    }
  );

  return (
    <div
      className={classNames(
        'absolute bg-white flex flex-col z-10 shadow border-l top-0 bottom-0'
      )}
      style={{ right: isOpen ? 0 : -width, width }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        {...bind()}
        className="absolute top-0 bottom-0 -left-4 w-8 cursor-col-resize"
      />
      <BlocksSettings nodes={nodes} />
    </div>
  );
}

export function BlocksSettings(p: { nodes: Node<Block>[] }) {
  const { state, dispatch } = useWorkflowContext();
  const { subselectionNodeIndex } = state;
  const [debug, setDebug] = useState(false);

  const changeIndex = useCallback((index: number) => {
    dispatch({ type: WorkflowAction.CHANGE_SUB_SELECTION_NODE_INDEX, index });
  }, []);

  useEffect(() => {
    if (
      subselectionNodeIndex > p.nodes.length - 1 ||
      subselectionNodeIndex < 0
    ) {
      changeIndex(0);
    }
  }, [p.nodes]);

  useHotkey({
    combo: 'option+shift+up',
    label: '',
    onKeyDown: () => setDebug((d) => !d),
  });
  useHotkey({
    combo: 'right',
    label: '',
    onKeyDown: () =>
      dispatch({ type: WorkflowAction.NEXT_SUB_SELECTION_NODE_INDEX }),
  });
  useHotkey({
    combo: 'left',
    label: '',
    onKeyDown: () =>
      dispatch({ type: WorkflowAction.PREV_SUB_SELECTION_NODE_INDEX }),
  });

  if (debug) {
    return (
      <pre className="bg-gray-50 text-gray-800 p-4 text-sm">
        {JSON.stringify(state, null, '\t')}
      </pre>
    );
  }

  const node =
    subselectionNodeIndex != null ? p.nodes[subselectionNodeIndex] : null;
  return (
    <div className="flex flex-col flex-grow ">
      <BlockDrawerHeader
        node={node}
        nodes={p.nodes}
        onChangeIndex={changeIndex}
        selectedIndex={subselectionNodeIndex}
      />
      <div className="flex flex-col flex-grow relative ">
        <BlockSettings node={node} />
      </div>
    </div>
  );
}
