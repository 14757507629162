import {
  Button,
  ButtonProps,
  Expander,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import {
  Form,
  Formik,
  FormikProvider,
  useField,
  useFieldValue,
  useFormik,
  useFormikContext,
} from 'formik';
import { isEmpty, pick, set, update } from 'lodash';
import { languages } from 'monaco-editor';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  EffectType,
  Filter,
  FilterEffect,
  Survey,
  SurveyTarget,
} from 'src/apps/athena/gql-types';
import { MUTATIONS } from 'src/graphql/surveys';
import {
  SurveyQuestionData,
  useGetSurveyWithQuestions,
} from 'src/pages/Survey/SurveyPage';
import { applyChanges, diffableArray, useApplyChanges } from 'src/utils/diffV2';
import { useSendCommand } from 'src/utils/events';
import {
  FieldArrayHelperProps,
  useDiffableFieldArray,
  useDiffableFieldArrayHelpers,
} from 'src/utils/formik';
import { useGraphQLFetch } from 'src/utils/graphql';
import { v4 } from 'src/utils/uuid';
import { Accordion } from '../Accordion';
import {
  EditableTextField,
  HTMLSelectField,
  NumericField,
  NumericInputField,
  SelectField,
  TagInputField,
  TextField,
} from '../BlueprintFields';
import { BottomBar } from '../BottomBar';
import CenteredSpinner from '../CenteredSpinner';
import { DatePickerField } from '../DatePicker';
import EditableField from '../EditableField';
import EditableList from '../EditableList';
import { Flag } from '../Flag';
import SubmitButton from '../SubmitButton';

export default function SurveyOptions(p: { surveyId: string }) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  return (
    <div className="p-3">
      <SurveyQuotasSection {...p} />
      <div className="flex-grow flex space-x-2">
        <div className="w-1/2">
          <SurveyTargetSection {...p} />
        </div>
        <div className="w-1/2">
          <SurveyFrequencySection {...p} />
        </div>
      </div>
      <SurveyLanguagesSection {...p} />
    </div>
  );
}

function SurveyLanguagesSection(p: { surveyId: string }) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const { t } = useTranslation('survey');
  const queryClient = useQueryClient();
  const [{ isLoading }, setSurveyLanguages] = useSendCommand(
    MUTATIONS.SET_SURVEY_LANGUAGES,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_LANGUAGES_CHANGED') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Survey Languages Set'),
        });
      }
    },
    {
      onSettled: () => queryClient.invalidateQueries(['survey', p.surveyId]),
    }
  );

  const formik = useFormik({
    initialValues: { languages: data.survey?.languages ?? [] },
    enableReinitialize: true,
    onSubmit: ({ languages }) =>
      setSurveyLanguages({ id: p.surveyId, languages }),
  });

  return (
    <FormikProvider value={formik}>
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={<SubmitButton small minimal text="Save" />}
          title={t('Survey Languages')}
        >
          <div className="p-2">
            <EditableField
              label="Survey Languages"
              name="languages"
              componentProps={{
                minimal: true,
                tagProps: (value) => ({
                  minimal: true,
                  icon: <Flag countryCode={value as string} />,
                }),
              }}
              component={TagInputField}
            />
          </div>
        </Accordion>
      </Form>
    </FormikProvider>
  );
}

function SurveyTargetSection(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const [{ isLoading }, setSurveyTarget] = useSendCommand(
    MUTATIONS.SET_SURVEY_TARGET,
    (evt, ctx) => {
      if (evt.type === 'SURVEY_TARGET_SET') {
        ctx.complete();
        ctx.showSuccessNotification({
          message: t('Survey Target Set'),
        });
      }
    }
  );
  return (
    <Formik<SurveyTarget>
      initialValues={data.survey?.target ?? { amount: 0, deadlineDate: null }}
      enableReinitialize={true}
      onSubmit={(target) =>
        setSurveyTarget({
          id: p.surveyId,
          amount: target.amount,
          deadlineDate: target.deadlineDate,
        })
      }
    >
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={<SubmitButton small minimal text="Save" />}
          title={t('Survey Target')}
        >
          <div className="p-2">
            <EditableField
              label="Survey Target"
              name="amount"
              component={NumericField}
            />
            <EditableField
              label="Deadline"
              name="deadlineDate"
              component={DatePickerField}
            />
          </div>
        </Accordion>
      </Form>
    </Formik>
  );
}

function SurveyQuotasSection(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const graphqlFetch = useGraphQLFetch();
  const form = useFormik({
    initialValues: data.survey,
    enableReinitialize: true,
    onSubmit: async () => {
      const changes = middleware(form);
      console.log('C', changes);
      await applyChanges(graphqlFetch, changes, MUTATIONS);
    },
  });

  const middleware = diffableArray({
    name: 'quotas',
    prefixes: {
      add: 'SET',
      remove: 'REMOVE',
      update: 'SET',
    },
    data: { surveyId: p.surveyId },
    commandSuffix: 'SURVEY_QUOTA',
  });

  return (
    <FormikProvider value={form}>
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={<SubmitButton small minimal text="Save" />}
          title={t('Survey Quotas')}
        >
          <div className="p-2">
            <EditableList
              name="quotas"
              component={SurveyQuotaField}
              componentProps={p}
            />
          </div>
        </Accordion>
      </Form>
    </FormikProvider>
  );
}
function SurveyFrequencySection(p: { surveyId: string }) {
  const { t } = useTranslation('survey');
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const graphqlFetch = useGraphQLFetch();
  const form = useFormik({
    initialValues: data.survey,
    enableReinitialize: true,
    onSubmit: async () => {
      const changes = middleware(form);
      console.log('C', changes);
      await applyChanges(graphqlFetch, changes, MUTATIONS);
    },
  });

  const middleware = diffableArray({
    name: 'frequencies',
    prefixes: {
      add: 'SET',
      remove: 'REMOVE',
      update: 'SET',
    },
    data: { surveyId: p.surveyId },
    commandSuffix: 'SURVEY_FREQUENCY',
  });

  return (
    <FormikProvider value={form}>
      <Form className="shadow bg-white rounded mb-3">
        <Accordion
          initiallyOpened={true}
          kind="section"
          rightElement={<SubmitButton small minimal text="Save" />}
          title={t('Survey Frequency')}
        >
          <div className="p-2">
            <EditableList
              name="frequencies"
              component={SurveyFrequencyField}
              componentProps={p}
            />
          </div>
        </Accordion>
      </Form>
    </FormikProvider>
  );
}

function SurveyFrequencyField(p: { name: string; surveyId: string }) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  if (isEmpty(data?.survey?.questions)) {
    return <CenteredSpinner />;
  }
  const questions =
    data.survey?.questions
      ?.filter((f) => !isEmpty(f.options) || !f.settings?.scaleKind != null)
      .map((q, index) => ({
        value: q.id,
        label: `Q${index + 1}. ${q.body.slice(0, 25)}`,
      })) ?? [];

  return (
    <div className="flex items-center py-1">
      <SelectField
        multiple={true}
        name={`${p.name}.questions`}
        options={questions}
      />
      {/* <HTMLSelectField
        className="w-1/6 mr-2"
        name={`${p.name}.questionId`}
        options={questions}
      /> */}
    </div>
  );
}

function SurveyQuotaField(p: { name: string; surveyId: string }) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  if (isEmpty(data?.survey?.questions)) {
    return <CenteredSpinner />;
  }
  const questions = [
    { value: '', label: '-' },
    ...(data.survey?.questions
      ?.filter((f) => !isEmpty(f.options))
      .map((q, index) => ({
        value: q.id,
        label: `Q${index + 1}. ${q.body.slice(0, 25)}`,
      })) ?? []),
  ];

  return (
    <div className="flex items-center py-1">
      <HTMLSelectField
        className="w-1/6 mr-2"
        name={`${p.name}.questionId`}
        options={questions}
      />
      <SurveyQuestionQuota {...p} />
    </div>
  );
}

function SurveyQuestionQuota(p: { surveyId: string; name: string }) {
  const [field] = useField(`${p.name}.questionId`);
  const [_, __, helpers] = useField(`${p.name}.options`);

  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const question = data?.survey?.questions?.find((f) => f.id === field.value);

  useEffect(() => {
    if (question != null) {
      helpers.setValue(
        question.options?.map((q) => ({
          optionId: q.id,
          amount: Math.floor(100 / question.options?.length),
        }))
      );
    }
  }, [question]);

  if (question == null) {
    return <React.Fragment />;
  }
  return (
    <div className="flex-grow justify-items-center flex">
      {question.options?.map((option, index) => (
        <div className="flex-grow mx-2 text-center border rounded flex flex-col items-center shadow-sm">
          <div className="bg-gray-100 border-b font-semibold self-stretch text-sm py-0.5 text-blue-400">
            {option.body}
          </div>
          <div className="flew flex-grow justify-center">
            <div className="flex">
              <EditableTextField
                kind="number"
                name={`${p.name}.options.${index}.amount`}
              />
              <span className="text-gray-400">%</span>
            </div>
          </div>
          <SurveyQuestionOptionQuotaPreview
            {...p}
            name={`${p.name}.options.${index}.amount`}
          />
        </div>
      ))}
    </div>
  );
}

function SurveyQuestionOptionQuotaPreview(p: {
  name: string;
  surveyId: string;
}) {
  const { data } = useGetSurveyWithQuestions(p.surveyId);
  const [field] = useField(p.name);
  const targetAmount = data.survey?.target?.amount ?? 0;
  if (targetAmount == null) {
    return <React.Fragment />;
  }
  return (
    <div className="border-t bg-gray-50 text-gray-500 italic py-0.5 text-sm self-stretch">
      {targetAmount * (field.value / 100)}
    </div>
  );
}
