import {
  Expander,
  Button,
  Intent,
  MenuItem,
  Menu,
  Icon,
  MenuDivider,
  Tag,
  NonIdealState,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { useVirtualizer } from '@tanstack/react-virtual';
import css from 'csz';
import { FormikProvider, useFieldValue, useFormik } from 'formik';
import {
  compact,
  difference,
  flatten,
  groupBy,
  isEmpty,
  map,
  pick,
} from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { SortableHandle } from 'react-sortable-hoc';
import { Question, Survey, SurveyQuestion } from 'src/apps/athena/gql-types';
import { MUTATIONS, QUERY } from 'src/graphql/surveys';
import {
  SurveyWithQuestions,
  useGetSurveyWithQuestions,
} from 'src/pages/Survey/SurveyPage';
import {
  diffableArray,
  useApplyChanges,
  combine,
  waitForSubscription,
  DiffableForm,
  Result,
} from 'src/utils/diffV2';
import { useSubscribeForEvents, waitForIndex } from 'src/utils/events';
import { useDiffableFieldArray } from 'src/utils/formik';
import { useContextMenu } from 'src/utils/useContextMenu';
import { BottomBar } from '../BottomBar';
import CenteredSpinner from '../CenteredSpinner';
import { DebugView } from '../DebugView';
import { DiffDebug } from '../DiffDebug';
import { Flag } from '../Flag';
import { ValidationDebug } from '../ValidationDebug';
import { OpenGalleryFn, useAddQuestionGallery } from './AddQuestionGallery';
import { QuestionCard } from './QuestionCard';
import { useRef } from 'react';
import { useGraphQLFetch } from 'src/utils/graphql';

export function SurveyDesign(p: { surveyId: string }) {
  const queryClient = useQueryClient();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const { gallery, openGallery } = useAddQuestionGallery(() => {
    queryClient.invalidateQueries(['surveyQuestions', p.surveyId]);
  });

  const { t } = useTranslation('survey');
  const { data, isLoading, refetch } = useGetSurveyWithQuestions(p.surveyId);
  const applyChanges = useApplyChanges(MUTATIONS);
  const fetch = useGraphQLFetch();
  const subscribe = useSubscribeForEvents((evt, ctx) => {
    if (['SURVEY_QUESTIONS_CHANGED'].includes(evt.type)) {
      ctx.complete();
      ctx.showSuccessNotification({
        message: t('Survey Successfully Saved'),
      });
    }
  });
  const INITIAL_SURVEY = {
    questions_diffs: [],
    questions: [],
    name: '',
    revision: 0,
  };
  const compactMiddleware = useCallback(
    (changes: Result<{ index?: number }>[]) => {
      const [addChanges, updateChanges, otherChanges] = changes.reduce(
        (prev, next) => {
          if (next.command === 'ADD_SURVEY_QUESTION') {
            return [[...prev[0], next], prev[1], prev[2]];
          } else if (next.command === 'UPDATE_SURVEY_QUESTION') {
            return [prev[0], [...prev[1], next], prev[2]];
          } else {
            return [prev[0], prev[1], [...prev[2], next]];
          }
        },
        [[], [], []]
      );

      const addChangesByIndex: Result<{}>[] = map(
        groupBy(addChanges, 'data.index'),
        (
          change: Result<{
            index: number;
            surveyId: string;
            questions: unknown[];
          }>[]
        ) => {
          const questions = flatten(change.map((d) => d.data.questions));
          return {
            command: 'ADD_SURVEY_QUESTION',
            data: {
              ...change[0].data,
              questions,
            },
          } as Result<{}>;
        }
      );

      const compactedUpdatedQuestions = flatten(
        updateChanges.map((d) => d.data.record.questions)
      );

      const compactUpdatedChanges = !isEmpty(compactedUpdatedQuestions)
        ? {
            command: 'UPDATE_SURVEY_QUESTION',
            data: {
              surveyId: updateChanges[0].data.surveyId,
              questions: compactedUpdatedQuestions,
            },
          }
        : null;

      return compact([
        ...addChangesByIndex,
        compactUpdatedChanges,
        ...otherChanges,
      ]);
    },
    []
  );

  const runMiddlware = useCallback(
    (form: DiffableForm<Partial<SurveyWithQuestions>>) => {
      const questions = diffableArray({
        name: 'questions',
        commandSuffix: 'SURVEY_QUESTION',
        transform: (r: any) => ({
          ...r,
          record: null,
          questions: [pick(r.record ?? r, ['id', 'questionId', 'variables'])],
        }),
        data: { surveyId: p.surveyId },
      });

      const middleware = combine(questions, waitForSubscription(subscribe));
      return compactMiddleware(middleware(form));
    },
    []
  );

  const form = useFormik<Partial<SurveyWithQuestions>>({
    initialValues: INITIAL_SURVEY,
    onSubmit: async () => {
      const changes = runMiddlware(form);
      await applyChanges(changes);

      // Wait for the revision to be updated.
      const data = await waitForIndex<Survey>(
        fetch,
        QUERY.GET_SURVEY,
        { id: p.surveyId },
        (survey) => survey?.revision > form.values?.revision,
        {
          maxDelay: 5000,
        }
      );

      if (data) {
        setNeedsUpdate(true);
        queryClient.invalidateQueries(['survey', p.surveyId]);
      }
    },
  });

  const survey = form.values;
  const handleReset = useCallback(() => {
    const values = data?.survey ?? INITIAL_SURVEY;
    form.resetForm({ values, touched: null });
  }, []);

  const diff = useMemo(() => runMiddlware(form), [form]);

  const hasLoadedQuestions = (
    survey?: Partial<SurveyWithQuestions>
  ): boolean => {
    return (
      survey?.id != null &&
      (survey?.questions?.every((d) => !isEmpty(d.body)) ?? false)
    );
  };

  const hasLoadedSurveyQuestions = hasLoadedQuestions(data.survey);
  useEffect(() => {
    if (hasLoadedSurveyQuestions) {
      form.resetForm({ values: data.survey, touched: null });
    }
  }, [hasLoadedSurveyQuestions]);

  useEffect(() => {
    if (needsUpdate && hasLoadedQuestions(data.survey)) {
      setNeedsUpdate(false);
      form.resetForm({ values: data.survey, touched: null });
    }
  }, [data.survey?.revision]);

  // console.log('Values', form.values, data.survey);
  if (!hasLoadedSurveyQuestions) {
    return <CenteredSpinner />;
  }

  return (
    <FormikProvider value={form}>
      <div className={classNames(className, 'flex-grow flex flex-column')}>
        <DebugView>
          <div className="flex items-center">
            <span className="text-blue-300 font-semibold uppercase">
              Remote Revision
            </span>
            : {data?.survey?.revision}
          </div>
          <div className="flex items-center">
            <span className="text-blue-300 font-semibold uppercase">
              Local Revision
            </span>
            : {form.values.revision}
            <Button
              small
              className="mx-2"
              icon="refresh"
              minimal
              intent={needsUpdate ? Intent.WARNING : Intent.NONE}
              onClick={() => {
                setNeedsUpdate(true);
                queryClient.invalidateQueries(['survey', p.surveyId]);
              }}
            />
          </div>
          <DiffDebug diff={diff} />
          <ValidationDebug errors={form.errors} />
        </DebugView>
        <SortableQuestion openGallery={openGallery} />
        <BottomBar>
          <Button
            icon="plus"
            text={t('Add Question...')}
            minimal
            onClick={() => openGallery()}
          />
          <Expander />
          <Button
            minimal
            icon="reset"
            text={t('Reset')}
            disabled={!form.dirty}
            onClick={handleReset}
          />
          <Button
            icon="tick"
            text={t('Save')}
            loading={form.isSubmitting}
            onClick={form.submitForm}
            disabled={!form.isValid || !form.dirty}
            intent={Intent.PRIMARY}
            minimal
          />
        </BottomBar>
        {gallery}
      </div>
    </FormikProvider>
  );
}

const className = css`/styles/survey.css`;

type SortableContainerProps = {
  items: Question[];
  openGallery: OpenGalleryFn;
};
type SortableQuestionProps = {
  question: Question;
  questionIndex: number;
  onEditQuestion: () => void;
  onAddQuestion: (direction: 'top' | 'bottom') => void;
  isLastQuestion: boolean;
};

const QuestionRow = (props: SortableQuestionProps) => {
  const { t } = useTranslation('survey');

  const [_, __, helpers] = useDiffableFieldArray('questions');
  const [languages] = useFieldValue('languages');
  const isLoaded = 'body' in props.question;
  const { questionIndex } = props;
  const menuItems = (
    <>
      <MenuItem
        icon="edit"
        text={t('Edit Question')}
        onClick={() => props.onEditQuestion()}
      />
      <MenuDivider />
      <MenuItem
        icon="arrow-up"
        disabled={questionIndex === 0}
        text={t('Move Up')}
        onClick={() => helpers.move(questionIndex, questionIndex - 1)}
      />
      <MenuItem
        icon="arrow-down"
        disabled={props.isLastQuestion}
        text={t('Move Down')}
        onClick={() => helpers.move(questionIndex, questionIndex + 1)}
      />
      <MenuDivider />
      <MenuItem
        icon="trash"
        intent={Intent.DANGER}
        text={t('Remove')}
        onClick={() => helpers.remove(questionIndex)}
      />
    </>
  );
  const { isContextMenuOpen, ...contextMenu } = useContextMenu(menuItems);
  return (
    <div>
      <AddInlineQuestionButton onClick={() => props.onAddQuestion('top')} />

      <QuestionCard
        question={props.question}
        {...contextMenu}
        field={`questions.${questionIndex}`}
        code={`Q${questionIndex + 1}`}
        className={`${isContextMenuOpen ? 'context-menu-open' : ''} mx-3`}
        actionElement={
          <div className="flex gap-x-2 items-center">
            <MissingLanguageWarning
              question={props.question}
              surveyLanguages={languages}
            />
            <DragHandle />
            <Popover2 content={<Menu>{menuItems}</Menu>}>
              <Button minimal icon="chevron-down" />
            </Popover2>
          </div>
        }
      />
      {props.isLastQuestion && (
        <AddInlineQuestionButton
          onClick={() => props.onAddQuestion('bottom')}
        />
      )}
    </div>
  );
};

function AddInlineQuestionButton(p: { onClick: () => void }) {
  return (
    <div
      className="p-1 mx-3 hover:bg-gray-100 group flex flex-col items-center"
      onClick={p.onClick}
    >
      <Icon
        intent={Intent.PRIMARY}
        icon="plus"
        className="opacity-0 group-hover:opacity-100"
        iconSize={12}
      />
    </div>
  );
}

function MissingLanguageWarning(p: {
  surveyLanguages: string[];
  question: Question;
}) {
  const { t } = useTranslation('survey');
  const availableLaguages = compact([
    p.question.language,
    ...(p.question.availableLanguages ?? []),
  ]);
  const missingLanguages = difference(p.surveyLanguages, availableLaguages);

  if (!isEmpty(missingLanguages)) {
    return (
      <Popover2
        interactionKind="hover"
        content={
          <div className="p-3 flex flex-col items-center">
            <div className="italic font-medium mb-1">
              This question does not have these translations:
            </div>
            <div className="flex gap-x-2">
              {missingLanguages.map((l) => (
                <Flag countryCode={l} style={{ width: 24 }} />
              ))}
            </div>
          </div>
        }
      >
        <Tag
          className="mr-1 font-semibold uppercase"
          intent={Intent.WARNING}
          minimal
        >
          {t('Missing Translations')}
        </Tag>
      </Popover2>
    );
  }
  return <React.Fragment />;
}

const DragHandle = SortableHandle(() => (
  <Icon className="sortable-handle" icon="menu" />
));

// const SortableQuestionsContainer = SortableContainer(
//   (p: SortableContainerProps) => {
//     return (
//       <div>
//         {p.items.map((item, index, total) => (
//           <SortableQuestionCard
//             key={item.id}
//             index={index}
//             rowIndex={index}
//             onEditQuestion={() =>
//               p.openGallery({
//                 editingQuestion: item as unknown as SurveyQuestion,
//               })
//             }
//             onAddQuestion={(direction) =>
//               p.openGallery({ index: direction === 'top' ? index : index + 1 })
//             }
//             isLastQuestion={index === total.length - 1}
//             question={item}
//           />
//         ))}
//       </div>
//     );
//   }
// );

function SortableQuestion(p: { openGallery: OpenGalleryFn }) {
  const [field, _, helpers] = useDiffableFieldArray<Question>('questions');

  const questions = field.value ?? [];
  const parentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const virtual = useVirtualizer({
    count: questions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 120,
  });

  if (questions.length === 0) {
    return (
      <NonIdealState
        icon="data-connection"
        title={t('No Question Added')}
        description={t('Add a question to get started')}
        action={
          <Button
            icon="plus"
            text={t('Add Question')}
            intent={Intent.PRIMARY}
            onClick={() => p.openGallery()}
          />
        }
      />
    );
  }

  return (
    <div ref={parentRef} className="flex-grow overflow-auto">
      <div
        className="relative w-full"
        style={{
          height: `${virtual.getTotalSize()}px`,
        }}
      >
        {virtual.getVirtualItems().map((virtualRow) => (
          <div
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtual.measureElement}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            <QuestionRow
              question={questions[virtualRow.index]}
              isLastQuestion={virtualRow.index === questions.length - 1}
              questionIndex={virtualRow.index}
              key={questions[virtualRow.index].id}
              onEditQuestion={() =>
                p.openGallery({
                  editingQuestion: questions[
                    virtualRow.index
                  ] as unknown as SurveyQuestion,
                })
              }
              onAddQuestion={(direction) =>
                p.openGallery({
                  index:
                    direction === 'top'
                      ? virtualRow.index
                      : virtualRow.index + 1,
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}
