import React from 'react';
import { Button, ButtonProps, IconName, Intent } from '@blueprintjs/core';
import {
  useFormik,
  FormikContext,
  FormikContextType,
  FormikState,
  FormikHelpers,
  useIsSubmitting,
  useIsDirty,
  useSubmitForm,
  useResetForm,
  useField,
  useFormikContext,
} from 'formik';
import { useTranslation } from 'react-i18next';
export default function SubmitButton(p: Partial<ButtonProps>) {
  const { isValid } = useFormikContext();
  const isDirty = useIsDirty();
  const isSubmitting = useIsSubmitting();
  const { t } = useTranslation();
  return (
    <Button
      {...p}
      icon={p.icon ?? 'tick'}
      intent={isValid === false ? Intent.WARNING : Intent.PRIMARY}
      text={p.text ?? t('Save')}
      type="submit"
      loading={isSubmitting}
      disabled={isSubmitting || isValid === false}
      minimal={!isDirty || isValid === false}
    />
  );
}
