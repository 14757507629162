import React, { useState, useCallback, useEffect } from 'react';
import { EditableText, Intent } from '@blueprintjs/core';
import type { CountryCode } from 'libphonenumber-js';
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';
import { useField } from 'formik';
import type { PhoneNumber } from 'src/apps/athena/gql-types';
import { Flag } from './Flag';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useTranslation } from 'react-i18next';

interface P {
  name: string;
  defaultCountry?: CountryCode;
}

export default function PhoneNumberField(p: P) {
  const [number, setNumber] = useState('');
  const [field, meta, helpers] = useField<PhoneNumber>(p.name);
  const { t } = useTranslation();
  const handleChange = useCallback((value: string) => {
    setNumber(value);
  }, []);

  useEffect(() => {
    if (field.value != null && field.value.number != null) {
      setNumber(field.value.number);
    }
  }, [field.value?.id]);

  const handleConfirm = useCallback(() => {
    console.log('On confirm');
    try {
      const phoneNumber = parsePhoneNumber(number, p.defaultCountry ?? 'IT');
      console.log(phoneNumber, phoneNumber.isValid());
      if (phoneNumber.isValid()) {
        const number = phoneNumber.formatNational();
        setNumber(number);
        helpers.setTouched(true);
        helpers.setValue({
          ...field.value,
          number,
          countryCode: phoneNumber.country,
        });
      } else {
        helpers.setError(t('must be a valid number'));
        helpers.setTouched(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [field.value?.id, number, p.defaultCountry]);
  let countryCallingCode: any = '39';
  let countryCode = field.value?.countryCode ?? 'IT';
  try {
    countryCallingCode = getCountryCallingCode(countryCode as CountryCode);
  } catch {
    countryCode = 'IT';
  }

  return (
    <>
      <Tooltip2 content={`+${countryCallingCode}`}>
        <Flag countryCode={countryCode} className="mr-2" />
      </Tooltip2>
      <EditableText
        value={number}
        placeholder={t('Click to edit...')}
        className={`px-1 ${meta.error ? 'ring-2 ring-yellow-400 rounded' : ''}`}
        intent={meta.error != null ? Intent.WARNING : null}
        onChange={handleChange}
        onConfirm={handleConfirm}
      />
    </>
  );
}
