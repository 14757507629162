import React from 'react';
import {
  LibraryRepository,
  useLibraryContext,
} from 'src/hooks/workflow/LibraryContext';
import { FormikProvider, useFormik } from 'formik';
import { TextField } from 'src/components/BlueprintFields';
import { Button, Expander, Intent } from '@blueprintjs/core';
import { useFieldArray } from 'src/utils/formik';

export function BlockRepositories() {
  const { repositories } = useLibraryContext();
  const form = useFormik({
    initialValues: { repositories },
    onSubmit: () => null,
  });
  return (
    <FormikProvider value={form}>
      <div className="flex relative flex-grow flex-col">
        <div className="flex flex-col flex-grow  items-stretch p-4 gap-y-2">
          <div className="flex gap-x-2">
            <div className="w-1/4 px-0.5 font-medium text-primary">Name</div>
            <div className="w-3/4 px-0.5 font-medium text-primary">Url</div>
          </div>
          {form.values.repositories.map((repo, index) => (
            <RepositoryRow repository={repo} index={index} />
          ))}
          <div className="italic text-gray-500">
            Repositories are block libraries managed by different organizations
            and open source maintainers. Each repository provides a series of
            blocks that you can use inside your workflow.
          </div>
        </div>
        <BottomRows />
      </div>
    </FormikProvider>
  );
}

function BottomRows() {
  const [_, __, helpers] = useFieldArray('repositories');

  return (
    <div className="sticky bottom-0 flex  items-center px-3 py-2 left-0 right-0 bg-white border-t">
      <Button
        icon="plus"
        text="Add Repositorry"
        minimal
        onClick={() => helpers.push({ name: '', url: '' })}
      />
      <Expander />
      <Button icon="tick" intent={Intent.PRIMARY} text="Apply" minimal />
    </div>
  );
}

function RepositoryRow(p: { repository: LibraryRepository; index: number }) {
  const { index } = p;
  const [_, __, helpers] = useFieldArray('repositories');
  return (
    <div className="flex gap-x-2">
      <TextField className="w-1/4" name={`repositories.${index}.name`} />
      <div className="flex items-center w-3/4 gap-x-1">
        <TextField fill name={`repositories.${index}.url`} />
        <Button
          minimal
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => helpers.remove(index)}
        />
      </div>
    </div>
  );
}
